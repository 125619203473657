import React, { FC } from 'react'
import styled from 'styled-components'
import { typography } from 'styled-system'
import Box from './Box'
import { ReactComponent as Icon } from 'images/magnify.svg'
import InputComponent from './Input'

const Input = styled(InputComponent).attrs({
  as: 'input',
  bg: '#f6f7f9',
  border: '1px solid #CBCBD3',
  borderRadius: '8px',
  height: '40px',
  pl: '48px',
  pr: 0,
  fontFamily: 0,
  fontSize: 1,
  width: '100%',
  backgroundColor: '#fff',
})`
  ${typography}
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #A3A3AC;
    font-family: 'Source Sans Pro';
  }

  :-ms-input-placeholder {
    color: #A3A3AC;
  }
`

type Props = {
  onChange?: Function
}

const SearchInput: FC<Props> = ({ onChange }) => (
  <Box position='relative' width='100%'>
    <Input placeholder='Search' onChange={onChange} />

    <Box position='absolute' top='8px' left='16px'>
      <Icon stroke='#6d7589' height={20} width={20} />
    </Box>
  </Box>
)

export default SearchInput
