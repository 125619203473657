import React, { useMemo } from 'react'
import { mergeLeft } from 'ramda'

import { Select } from 'components/ui'
import Option from './SortOption'

const SortSelect = () => {
  const options = useMemo(
    () => [
      { label: 'Newest first', value: 'desc' },
      { label: 'Oldest first', value: 'asc' },
      { label: 'Wedding date', value: 'date' },
      { label: 'Wedding name', value: 'name' },
    ],
    [],
  )

  return (
    <Select
      variant='button'
      name='sort'
      isSearchable={false}
      value={options[0]}
      options={options}
      height='40px'
      styles={{
        singleValue: (styles: any) => ({
          ...styles,
          fontFamily: 'Source Sans Pro',
          color: '#73737B',
          fontWeight: 600,
        }),
        menu: mergeLeft({
          width: 196,
          border: '1px solid #CBCBD3',
          boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)',
          borderRadius: 6,
          marginTop: 13,
        }),
        menuList: mergeLeft({
          paddingTop: 'unset',
          paddingBottom: 'unset',
        }),
      }}
      components={{ Option }}
    />
  )
}

export default SortSelect
