import { PUSH_FILE } from '../actionTypes'

export const pushFile = (wuid: string, file: File) => ({
  type: PUSH_FILE,
  payload: {
    is_uploaded: 0,
    src_file: file.name,
    total_length: file.size,
    upload_length: 0,
    insert_date: new Date(),
    wedding_wuid: wuid,
    media_type: file.type.split('/')[0],
  },
})
