import { join } from 'ramda'

import { LOAD_ALBUM } from '../actionTypes'

export const loadAlbum = (album_id: string) => ({
  type: LOAD_ALBUM.name,
  api: {
    url: 'albums/info',
    data: {
      album_id,
      field_list: join(',', [
        'album_id',
        'album_title',
        'number_of_photos',
        'cover_image',
        'album_type',
        'system_type',
        'album_owner_uid',
        'album_owner_name',
        'album_owner_image',
        'shares',
        'insert_date',
      ]),
    },
  },
})
