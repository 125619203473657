import React, { useState, useCallback } from 'react'

import { Box, Text, Button } from 'components/ui'
import { Values } from './AppSections'
import NavIcon from '../NavIcon'
import IconsModal from './IconsModal'

type Props = {
  icon: string
  onChangeValues: (values: Partial<Values>) => void
}

const IconSelector = ({ icon, onChangeValues }: Props) => {
  const [isOpen, setOpen] = useState(false)

  const toggleModal = useCallback(() => setOpen((v) => !v), [])

  const onSaveIcon = useCallback((icon: string) => onChangeValues({ icon }), [
    onChangeValues,
  ])

  return (
    <>
      <IconsModal
        defaultIcon={icon}
        isOpen={isOpen}
        onClose={toggleModal}
        onSaveIcon={onSaveIcon}
      />

      <Box
        py="20px"
        borderBottom="1px dashed rgba(53, 59, 96, 0.4)"
        display="flex"
        alignItems="center"
      >
        <Box
          border="2px solid #E8E8ED"
          borderRadius="8px"
          width="96px"
          height="96px"
          color="#353B60"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <NavIcon name={icon} width={54} height={54} />
        </Box>

        <Box ml="12px">
          <Text
            fontWeight={600}
            fontSize="16px"
            lineHeight="20px"
            color="#353B60"
          >
            Selected Icon
          </Text>

          <Button
            fontFamily="0"
            px="0px"
            height="21px"
            color="#5458F7"
            fontWeight={600}
            fontSize="16px"
            mt="10px"
            onClick={toggleModal}
          >
            Change
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default IconSelector
