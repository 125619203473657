import { Selector, createSelector } from 'reselect'
import {
  pathOr,
  find,
  findLast,
  propEq,
  filter,
  map,
  assoc,
  compose,
  isNil,
  not,
  sortBy,
  sort,
  prop,
  equals,
  head,
} from 'ramda'
import { parseISO, isValid, compareDesc } from 'date-fns'

import { getFullName, getImageLink } from 'utils/guest'
import { State, User, Message, Room } from './reducer'

const getState: Selector<{ messages: State }, State> = state => state.messages

const getUsers: Selector<{ messages: State }, User[]> = createSelector(
  getState,
  pathOr([], ['users'])
)

export const getSelectedRoom: Selector<
  { messages: State },
  Room | null
> = createSelector(
  getState,
  getUsers,
  (state, users) => {
    const room = find(propEq('rid', state.selectedRoomId), state.rooms)

    return room
      ? assoc(
          'users',
          map(uid => find(propEq('uid', uid), users), room.userlist),
          room
        )
      : null
  }
)

const getMessages: Selector<{ messages: State }, Message[]> = createSelector(
  getState,
  pathOr([], ['messages'])
)

export const getChatMessages: Selector<
  { messages: State },
  Message[]
> = createSelector(
  getUsers,
  getMessages,
  getSelectedRoom,
  (users, messages, room) => {
    if (!room) return []

    return sortBy(
      prop('n_ord'),
      map(
        (message: Message) =>
          assoc(
            'author',
            find(propEq('uid', message.author_uid), users),
            message
          ),
        filter(propEq('rid', room.rid), messages)
      )
    )
  }
)

export const getChatIsOpen: Selector<
  { messages: State },
  boolean
> = createSelector(
  getSelectedRoom,
  compose(
    not,
    isNil
  )
)

export const getRooms: Selector<{ messages: State }, Room[]> = createSelector(
  getState,
  getUsers,
  getMessages,
  (state, users, messages) =>
    sort(
      (a, b) => {
        const prevDate = parseISO(pathOr('', ['lastMessage', 'insert_dt'], a))
        const currentDate = parseISO(
          pathOr('', ['lastMessage', 'insert_dt'], b)
        )

        if (!isValid(currentDate)) return -1
        if (!isValid(prevDate)) return 1

        const res = compareDesc(prevDate, currentDate)

        return res
      },
      map((room: Room) => {
        let res = room

        if (equals(room.room_type, 2)) {
          const user = find(propEq('uid', head(room.userlist)), users) || {
            image: '',
            first_name: '',
            last_name: '',
            status: 0,
          }

          res.image = getImageLink(user.image)
          res.name = getFullName([user.first_name, user.last_name])
          res.isOnline = equals(user.status, 1)
        }

        res.typingUser = equals(room.is_typing, 1)
          ? find(propEq('uid', room.uid), users)
          : undefined

        const lastMessage = findLast(
          propEq('rid', room.rid),
          sortBy(prop('n_ord'), messages)
        )

        if (lastMessage) {
          res.lastMessage = assoc(
            'author',
            find(propEq('uid', lastMessage.author_uid), users),
            lastMessage
          )
        }

        return res
      }, pathOr([], ['rooms'], state))
    )
)

export const getCreatedRoomId = (
  rid: string
): Selector<{ messages: State }, string> =>
  createSelector(
    getState,
    compose(
      pathOr('', ['room_id']),
      findLast(propEq('rid', rid)),
      pathOr([], ['history'])
    )
  )
