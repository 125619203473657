import { Reducer } from 'redux'

import { Type } from './actions'
import { itemsPayloadToStore, chartPayloadToStore } from './utils'

export type SChartGuest = {
  guest_id: string
  first_name: string
  last_name: string
  user?: {
    image: { slice: string; link: string }[]
    first_name: string
    last_name: string
  }
}

export type SChartItem = {
  schart_item_id: string
  schart_id: string
  type:
    | 'halfcircle'
    | 'rect'
    | 'circle'
    | 'square'
    | 'long'
    | 'round'
    | 'dancefloor'
    | 'stage'
  caption: string
  pos_x: number
  pos_y: number
  width: number
  height: number
  seats: (SChartGuest | null)[][]
  rotation: number
  isCaptionVisible?: boolean
  isChairsVisible?: boolean
}

export type Floorplan = {
  id: string
  url: string
  width: number
  height: number
  ext: string
  scale: number
}

export type SChart = {
  id: string
  wuid: string
  is_active: string
  caption: string
  schart_items: SChartItem[]
  schart_guests: SChartGuest[]
  height: number
  width: number
  x: number
  y: number
  zoom: number
  floorplan?: Floorplan
  svg_guests_image?: string
}

export type State = {
  scharts: SChart[]
  loadedWuids: string[]
  loadedIds: string[]
  isExpanded: boolean
}

const defaultState: State = {
  scharts: [],
  loadedWuids: [],
  loadedIds: [],
  isExpanded: false,
}

const uniq = (val: any, inx: number, arr: any[]) => arr.indexOf(val) === inx

const seatingReducer: Reducer = (state: State = defaultState, action) => {
  switch (action.type) {
    case Type.LOAD_CHARTS.FULFILLED:
      return {
        ...state,
        scharts: [
          ...state.scharts,
          ...action.payload.payload.scharts.map((schart: SChart) =>
            chartPayloadToStore({
              ...schart,
              wuid: action.payload.data.wuid,
            })
          ),
        ],
        loadedWuids: [...state.loadedWuids, action.payload.data.wuid].filter(
          uniq
        ),
      }
    case Type.ADD_CHART.FULFILLED:
      return {
        ...state,
        scharts: [
          ...state.scharts,
          chartPayloadToStore({
            ...action.payload.payload.schart,
            wuid: action.payload.data.wuid,
          }),
        ],
      }
    case Type.EDIT_CHART.FULFILLED:
    case Type.UPLOAD_FLOORPLAN.FULFILLED:
      return {
        ...state,
        scharts: state.scharts.map((schart) =>
          schart.id === action.payload.payload.schart.id
            ? chartPayloadToStore({
                ...schart,
                ...action.payload.payload.schart,
              })
            : schart
        ),
      }
    case Type.REMOVE_CHART.FULFILLED:
      return {
        ...state,
        scharts: state.scharts.filter(
          (schart) => schart.id !== action.payload.data.schart_id
        ),
      }
    case Type.REMOVE_FLOORPLAN.FULFILLED:
      return {
        ...state,
        scharts: state.scharts.map((schart) =>
          schart.id === action.payload.data.schart_id
            ? {
                ...schart,
                floorplan: undefined,
              }
            : schart
        ),
      }
    case Type.LOAD_ITEMS.FULFILLED:
      return {
        ...state,
        scharts: state.scharts.map((schart) =>
          schart.id === action.payload.data.schart_id
            ? { ...schart, ...itemsPayloadToStore(action.payload.payload) }
            : schart
        ),
        loadedIds: [...state.loadedIds, action.payload.data.schart_id].filter(
          uniq
        ),
      }
    case Type.ADD_ITEM.FULFILLED:
    case Type.EDIT_ITEM.FULFILLED:
      return {
        ...state,
        scharts: state.scharts.map((schart) =>
          schart.id === action.payload.data.schart_id
            ? { ...schart, ...itemsPayloadToStore(action.payload.payload) }
            : schart
        ),
      }
    case Type.EDIT_ITEM.PENDING:
      return {
        ...state,
        scharts: state.scharts.map((schart) =>
          schart.id === action.payload.schart_id
            ? {
                ...schart,
                schart_items: schart.schart_items.map((item) =>
                  item.schart_item_id === action.payload.schart_item_id
                    ? {
                        ...item,
                        ...action.payload,
                      }
                    : item
                ),
              }
            : schart
        ),
      }
    case Type.REMOVE_ITEM.FULFILLED:
      return {
        ...state,
        scharts: state.scharts.map((schart) =>
          schart.id === action.payload.data.schart_id
            ? {
                ...schart,
                schart_items: schart.schart_items.filter(
                  (item) =>
                    item.schart_item_id !== action.payload.data.schart_item_id
                ),
              }
            : schart
        ),
      }
    case Type.UPDATE_ITEMS.FULFILLED:
      return {
        ...state,
        scharts: state.scharts.map((schart) =>
          schart.id === action.payload.data.schart_id
            ? {
                ...schart,
                ...itemsPayloadToStore(action.payload.payload),
              }
            : schart
        ),
      }
    case Type.CHANGE_ITEM:
      return {
        ...state,
        scharts: state.scharts.map((schart) =>
          schart.id === action.payload.schart_id
            ? schart.schart_items.map((item) =>
                item.schart_item_id === action.payload.schart_item_id
                  ? {
                      ...item,
                      ...action.payload.data,
                    }
                  : item
              )
            : schart
        ),
      }
    case Type.EXPAND_COLLAPSE:
      return { ...state, isExpanded: !state.isExpanded }
    case Type.COLLAPSE:
      return { ...state, isExpanded: false }
    default:
      return state
  }
}

export default seatingReducer
