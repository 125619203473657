import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { map, reject, propEq } from 'ramda'
import styled from 'styled-components'

import { Button, Box, Text } from 'components/ui'
import { getListsIsLoaded, getAllLists } from 'store/modules/guests/selectors'
import { ReactComponent as ChevronIcon } from 'images/chevron-down.svg'
import Item from './Item'

type Props = {
  onClickItem: (type: string, itemId: string|null) => void
}

const Wrapper = styled.div`
  &:hover ${Box} {
    background-color: #F7F7FC;
    transition: .1s;
    cursor: pointer
  }
`

const GroupsSelect: FC<Props> = ({ onClickItem }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const isLoaded = useSelector(getListsIsLoaded)
  const items = useSelector(getAllLists)
  const filteredItems = reject(propEq('id', '0'), items)

  const handleClickButton = () => setMenuIsOpen(!menuIsOpen)
  const handleClickOutside = () => setMenuIsOpen(false)

  const handleEdit = useCallback((id: string) => {
    onClickItem('edit', id);
  }, [onClickItem])

  const handleCreate = useCallback(() => {
    onClickItem('create', null);
  }, [onClickItem])

  return (
    <>
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        <Box position="relative">
          <Button
            border="1px solid"
            borderColor="#CBCBD3"
            borderRadius="8px"
            fontFamily='Source Sans Pro'
            fontSize={1}
            fontWeight="600"
            pl="16px"
            pr="16px"
            height="40px"
            onClick={handleClickButton}
            disabled={!isLoaded}
            display="flex"
            alignItems="center"
          >
            <Box color="#353B60">
              Groups
            </Box>
            <Box ml={0}>
              <ChevronIcon />
            </Box>
          </Button>

          {menuIsOpen && (
            <Box
              position="absolute"
              display="flex"
              flexDirection="column"
              bg="white"
              border="1px solid"
              borderColor="#E8E8ED"
              borderRadius="10px"
              boxShadow={0}
              mt={0}
              zIndex={3}
            >
              {filteredItems.length > 0 && (
                  <Box
                    width="240px"
                    maxHeight="calc(100vh - 350px)"
                    overflowY="auto"
                    borderBottom="1px solid"
                    borderBottomColor="#E8E8ED"
                  >
                    {map(
                      (item) => (
                       <Wrapper key={item.name}>
                            <Item
                              {...item}
                              onClickEditButton={() => handleEdit(item.id)}
                            />
                       </Wrapper>
                      ),

                      filteredItems
                    )}
                  </Box>
              )}

              <Box
                px={1}
                py="10px"
                display="flex"
                alignItems="center"
                cursor="pointer"
                onClick={() => handleCreate()}
              >
                <Text fontFamily="Source Sans Pro" color="#5458F7" fontWeight="600" fontSize="14px" isTruncate lineHeight="20px">
                    Add Group
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </OutsideClickHandler>
    </>
  )
}

export default GroupsSelect
