import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Loading } from 'components/ui'
import { loadNav, addNav, loadNavItems } from 'store/modules/nav/actions'
import { getNavItems, getNavIsLoaded } from 'store/modules/nav/selectors'
import { PAGE_TYPES } from 'constants/wedding'
import Layout from './Layout'
import Setup from './Setup'

export type Item = { id: string; label: string; isLocked?: boolean }
export type Items = {
  tabNavigation: Item[]
  features: Item[]
  disabled: Item[]
}

type Props = {
  type: string
  wuid: string
}

const Sidebar: FC<Props> = ({ type, wuid }) => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const isLoaded = useSelector(getNavIsLoaded(wuid))
  const navItems = useSelector(getNavItems(wuid))

  const onLoadNav = useCallback(async () => dispatch(loadNav()), [dispatch])
  const onAddDefaultNav = useCallback(async () => dispatch(addNav('Default')), [
    dispatch,
  ])
  const onLoadNavItems = useCallback(async () => dispatch(loadNavItems()), [
    dispatch,
  ])

  useEffect(() => {
    const handleLoadNav = async () => {
      try {
        setLoading(true)

        const res: any = await onLoadNav()

        if (res.value.status === 'success' && !res.value.payload.navs.length) {
          // Create default nav
          await onAddDefaultNav()
        }

        await onLoadNavItems()
      } catch (error) {
        toast(error.message)
      } finally {
        setLoading(false)
      }
    }

    if (!isLoaded) {
      handleLoadNav()
    }
  }, [isLoaded, onLoadNav, onAddDefaultNav, onLoadNavItems])

  if (isLoading) {
    return <Loading />
  }

  switch (type) {
    case PAGE_TYPES[0]:
      return <Layout initialItems={navItems} />
    case PAGE_TYPES[1]:
      return <Layout initialItems={navItems} isPlanner />
    default:
      return <Setup />
  }
}

export default Sidebar
