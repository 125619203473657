import React, { FC, useCallback } from 'react'
import { path, includes } from 'ramda'

import { Guest } from 'store/modules/guests/reducer'
import { Box, Avatar, Text, CheckBox } from 'components/ui'
import { getFullName, getImage } from 'utils/guest'
import SearchInput from './SearchInput'

type Props = {
  guests: Guest[]
  selectedGuests: string[]
  isDisabled?: boolean
  onSetSearchValue: (v: string) => void
  onSelectGuest: (guestId: string) => void
}

const GuestsList: FC<Props> = ({
                                 guests = [],
                                 selectedGuests = [],
                                 isDisabled = false,
                                 onSetSearchValue,
                                 onSelectGuest,
                               }) => {
  const handleSearch = useCallback((e) => onSetSearchValue(e.target.value), [
    onSetSearchValue,
  ])

  return (
    <Box>
      <SearchInput onChange={handleSearch} />

      <Box
        display='flex'
        flexDirection='column'
        height='calc(80vh - 246px)'
        overflowY='auto'
        pt={0}
        px={2}
      >
        {guests.map((guest) => {
          const fullName = getFullName([guest.first_name, guest.last_name])
          const handleSelectGuest = () => onSelectGuest(guest.id)

          return (
            <Box key={guest.id} mb={2} display='flex'>
              <Box minWidth='40px'>
                <Avatar
                  image={getImage(path(['user', 'image'], guest))}
                  name={fullName}
                  fontSize={2}
                />
              </Box>

              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                width='100%'
                ml='12px'
              >
                <Box display='flex' flexDirection='column'>
                  <Text fontSize='15px' color='fullBlack' fontWeight={600}>
                    {fullName}
                  </Text>

                  <Text color='darkGray'>{guest.wedding_role}</Text>
                </Box>

                <CheckBox
                  checked={includes(guest.id, selectedGuests)}
                  disabled={isDisabled}
                  onChange={handleSelectGuest}
                />
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default GuestsList
