import { Selector, createSelector } from 'reselect'
import { path, pathOr, is } from 'ramda'

import { State, User, initialState } from './reducer'

const getState: Selector<{ auth: State }, State> = pathOr(initialState, [
  'auth',
])

export const getAuthToken = createSelector(
  getState,
  path<State['authToken']>(['authToken'])
)

export const getIsLoggedIn = createSelector(getAuthToken, is(String))

export const getAuthData = createSelector(getState, (state) => state)

export const getUser = createSelector(
  getState,
  path<User>(['user'])
)

export const getMyId = createSelector(
  getState,
  path<User['uuid']>(['user', 'uuid'])
)

export const getWuid = createSelector(
  getState,
  path<string>(['wuid'])
)
