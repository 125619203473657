import React, { FC, ReactNode, InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { path } from 'ramda'

import { ReactComponent as Chevron } from 'images/chevron-down.svg'
import Box from '../Box'

const Select = styled(Box).attrs({
  as: 'select',
  required: true,
  borderWidth: 2,
  borderColor: 'gray',
  borderRadius: 0,
  width: '100%',
  height: '38px',
  boxShadow: 'none',
  px: 0,
  bg: 'white',
})<InputHTMLAttributes<HTMLSelectElement>>`
  appearance: none;
  font-size: ${path(['theme', 'fontSizes', '1'])}px;
  font-family: ${path(['theme', 'fonts', '1'])};
  color: ${path(['theme', 'colors', 'black'])};

  &:hover {
    border-color: ${path(['theme', 'colors', 'primary'])};
  }

  &:invalid {
    color: ${path(['theme', 'colors', 'darkGray'])} !important;
  }
`

type Props = {
  value?: string | number
  placeholder: ReactNode
  onChange: (value: string) => void
}

const MobileSelect: FC<Props> = ({
  value,
  placeholder,
  children,
  onChange,
}) => (
  <Box position="relative">
    <Select
      value={value}
      onChange={(e: any) => onChange(e.currentTarget.value)}
    >
      <option value="" hidden>
        {placeholder}
      </option>
      {children}
    </Select>

    <Box position="absolute" right={0} top={0}>
      <Chevron fill="#7c8494" />
    </Box>
  </Box>
)

export default MobileSelect
