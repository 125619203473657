import React, { FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { Box, FormLabel, GeosuggestInput } from '../ui'

type Props = {
  name: string
  label?: string
  isShowIcon?: boolean
  placeholder?: string
  googleMaps: any
  disabled?: boolean
  hideValue?: boolean
  defaultValue?: string
}

const FormGeosuggestInput: FC<Props> = ({
                                          label,
                                          name,
                                          placeholder,
                                          defaultValue,
                                          isShowIcon,
                                          ...rest
                                        }) => {
  const renderGeosuggestInput = ({
                                   input,
                                   placeholder,
                                 }: FieldRenderProps<string>) => (
    <Box>
      {label && <FormLabel>{label}</FormLabel>}

      <GeosuggestInput
        {...input}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isShowIcon={isShowIcon}
        {...rest}
      />
    </Box>
  )

  return (
    <Field
      name={name}
      placeholder={placeholder}
      render={renderGeosuggestInput}
    />
  )
}

export default FormGeosuggestInput
