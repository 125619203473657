import React, {
  useRef,
  useState,
  useMemo,
  CSSProperties,
  useCallback,
} from 'react'
import { useDispatch } from 'react-redux'
import Moveable, { OnScaleStart, OnScale, OnScaleEnd } from 'react-moveable'

import { Floorplan as FloorplanProps } from 'store/modules/seatings/reducer'
import { editChart } from 'store/modules/seatings/actions'
import { Box } from 'components/ui'

type Props = {
  chartId: string
  onClick: VoidFunction
}

const Floorplan = ({
  chartId,
  onClick,
  width,
  height,
  scale: initScale,
  url,
}: FloorplanProps & Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [scale, setScale] = useState(initScale)
  const dispatch = useDispatch()

  const onSaveScale = useCallback(
    async (scale: number) =>
      dispatch(editChart(chartId, { floorplan_scale: scale })),
    [dispatch, chartId]
  )

  const onScaleStart = useCallback((e: OnScaleStart) => e.set([scale]), [scale])

  const onScale = useCallback((e: OnScale) => {
    const [scale] = e.scale

    setScale(scale)
  }, [])

  const onScaleEnd = useCallback(
    (e: OnScaleEnd) => {
      const [scale] = e.lastEvent.scale as number[]

      onSaveScale(parseFloat(scale.toFixed(2)) * 100)
    },
    [onSaveScale]
  )

  const style = useMemo<CSSProperties>(
    () => ({
      transform: `scale(${scale})`,
      backgroundImage: `url(${url})`,
    }),
    [scale, url]
  )

  return (
    <>
      <Box
        ref={ref}
        position="absolute"
        left="0px"
        top="0px"
        width={width}
        height={height}
        style={style}
        onClick={onClick}
      />

      <Moveable
        target={ref}
        scalable
        keepRatio
        throttleScale={0}
        renderDirections={['nw', 'ne', 'sw', 'se']}
        origin
        onScaleStart={onScaleStart}
        onScale={onScale}
        onScaleEnd={onScaleEnd}
      />
    </>
  )
}

export default Floorplan
