import React, { FC } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import validate from 'validate.js'

import { Modal, Box, Text, Button } from 'components/ui'
import { presenceFieldConstraint } from 'constants/constraints'
import GuestSelect from './GuestSelect'

type Props = {
  isOpen: boolean
  onClose: () => void
  onAddUser: (x: any) => void
}

const AddUserModal: FC<Props> = ({ isOpen, onClose, onAddUser }) => {
  const handleValidate = (values: object) =>
    validate(values, presenceFieldConstraint('guest'))

  const renderForm = ({ handleSubmit, submitting }: FormRenderProps) => (
    <Box as="form" onSubmit={handleSubmit}>
      <GuestSelect />

      <Box display="flex" justifyContent="flex-end" pt={3}>
        <Box mr={0}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>

        <Button variant="primary" type="submit" disabled={submitting}>
          Add
        </Button>
      </Box>
    </Box>
  )

  const style = { content: { maxWidth: 422, top: '20vh', overflow: 'none' } }

  return (
    <Modal style={style} isOpen={isOpen} onRequestClose={onClose}>
      <Box py={2} px={3} borderRadius={0} bg="white">
        <Box mb={1}>
          <Text fontFamily="1" fontSize={3} fontWeight={600}>
            Add Member
          </Text>
        </Box>

        <Form
          validate={handleValidate}
          render={renderForm}
          onSubmit={onAddUser}
        />
      </Box>
    </Modal>
  )
}

export default AddUserModal
