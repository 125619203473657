import { LOAD_MENU_GROUPS } from '../../actionTypes'
import { GetMenuGroupsNeedData } from '../../../../../routes/FoodMenu/tipes_interfaces'

export const load = (data: GetMenuGroupsNeedData) => {
  return {
    type: LOAD_MENU_GROUPS.name,
    api: {
      url: 'menu/list_groups',
      data,
    },
  }
}