export const injectFont = (fontFamily: string, src: string) => {
  const style = document.createElement('style')
  style.type = 'text/css'
  style.textContent = `
    @font-face {
      font-family: "${fontFamily}";
      src: url("${src}");
    }
  `

  document.head.appendChild(style)
}
