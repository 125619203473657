import { LOGIN } from '../actionTypes'

type Props = {
  email?: string
  phone?: string
  password: string
}

export const login = ({ email, phone, password }: Props) => ({
  type: LOGIN.name,
  api: {
    url: 'user/login',
    data: {
      email,
      phone,
      password,
      field_list: [
        'uuid',
        'email',
        'phone',
        'first_name',
        'last_name',
        'image',
        'contact_email',
        'contact_phone',
      ].join(','),
    },
  },
})
