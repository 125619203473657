import React, { FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { Props as SelectProps, OptionTypeBase } from 'react-select'
import { join } from 'ramda'

import { Box, FormLabel, Select, Text } from '../ui'

type Props = {
  name: string
  label?: string
  isRequired?: boolean
  placeholder?: string
  height?: string | number
}

const FormSelect: FC<SelectProps<OptionTypeBase> & Props> = ({
  name,
  label,
  isRequired = false,
  placeholder,
  ...rest
}) => {
  const renderSelect = ({
    input,
    meta,
  }: FieldRenderProps<{ label: string; value: string }>) => {

    return (
      <Box position="relative">
        {label && (
          <FormLabel isRequired={isRequired} isActive={meta.active}>
            {label}
          </FormLabel>
        )}

        <Select {...rest} {...input} value={rest.value || input.value} id={name} placeholder={placeholder} />

        {meta.submitFailed && meta.invalid && (
          <Box position="absolute" bottom="-18px">
            <Text color="red" fontFamily="1" fontSize="12px" isTruncate>
              {join('. ', meta.error)}
            </Text>
          </Box>
        )}
      </Box>
    )
  }

  return <Field name={name} render={renderSelect} />
}

export default FormSelect
