import React, { FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { CheckBox } from 'components/ui'

type Props = {
  name: string
}

const FormCheckBox: FC<Props> = ({ name }) => {
  const renderCheckBox = ({ input }: FieldRenderProps<boolean>) => (
    <CheckBox {...input} value="" />
  )

  return <Field id={name} name={name} type="checkbox" render={renderCheckBox} />
}

export default FormCheckBox
