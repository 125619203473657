import React, { FC, useContext } from 'react'
import { Context } from '../Context'
import { Box, Button } from 'components/ui'

// Icons
import { ReactComponent as StartDateArrow } from 'images/startDateArrow.svg'
import { ReactComponent as EndDateArrow } from 'images/endDateArrow.svg'

const StartEndDateButtons: FC = () => {
  const {
    handleSortByStartEndDate,
    activeTableType,
    sortedByDate,
  } = useContext<any>(Context)

  return (
    <Box ml='12px' mr='12px'>
      <Button
        border={`1px solid ${activeTableType !== 'By Week' ? '#E8E8ED !important' : '#CBCBD3 !important'}`}
        borderRadius='8px !important'
        height='40px'
        bg='transparent'
        color={activeTableType !== 'By Week' ? '#E8E8ED' : '#353B60'}
        fontWeight='600'
        fontSize='16px'
        fontFamily='Source Sans Pro !important'
        padding='8px 16px !important'
        disabled={activeTableType !== 'By Week'}
        cursor={activeTableType !== 'By Week' ? 'unset' : 'pointer'}
        onClick={() => handleSortByStartEndDate(sortedByDate === 'Start Date' ? 'Start Date' : sortedByDate === 'End Date' ? 'End Date' : 'Start Date')}
      >
        Start Date
        <Box ml={8}>
          {sortedByDate === 'Start Date' ? <StartDateArrow
            stroke={activeTableType !== 'By Week' ? '#E8E8ED' : '#353B60'} /> : sortedByDate === 'End Date' ?
            <EndDateArrow
              stroke={activeTableType !== 'By Week' ? '#E8E8ED' : '#353B60'} /> : <StartDateArrow
              stroke={activeTableType !== 'By Week' ? '#E8E8ED' : '#353B60'} />}
        </Box>
      </Button>
    </Box>
  )
}

export default StartEndDateButtons