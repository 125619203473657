import React, { FC, LabelHTMLAttributes } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import styled from 'styled-components'
import { typography, TypographyProps } from 'styled-system'

import { Box, Switch, Text } from '../ui'

const Label = styled(Text).attrs({
  as: 'label',
})<LabelHTMLAttributes<HTMLLabelElement> & TypographyProps>`
  margin: 0 0 0 14px;
  line-height: 1.43;

  ${typography}
`

type Props = {
  name: string
  disabled?: boolean
  value?: string
  label?: string
}

const FormSwitch: FC<Props> = ({ name, disabled, value, label }) => {
  const renderSwitch = ({ input, id }: FieldRenderProps<string>) => (
    <Box display="flex" alignItems="center">
      <Switch id={id} disabled={disabled} {...input} />

      {label && (
        <Label htmlFor={id} fontFamily="1" fontSize={0}>
          {label}
        </Label>
      )}
    </Box>
  )

  return (
    <Field
      id={name}
      name={name}
      value={value}
      type="checkbox"
      render={renderSwitch}
    />
  )
}

export default FormSwitch
