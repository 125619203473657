import React from 'react'
import Central from './modalBackgrounds/central'
import FromTheLeftSide from './modalBackgrounds/fromTheLeftSide'
import { Modal as ModalType } from '../tipes_interfaces'

interface PropType {
  setIsModalOpen: any;
  modalType: ModalType;
  menu_id: string;
  selectGroup: string;
}

const Modal = ({ setIsModalOpen, modalType, menu_id, selectGroup }: PropType) => {
  const { name, type, action } = modalType

  return (
    <>
      {
        type === 'central' ? <Central action={action} name={name} setIsModalOpen={setIsModalOpen} menu_id={menu_id}
                                      selectGroup={selectGroup} /> :
          <FromTheLeftSide name={name} action={action} setIsModalOpen={setIsModalOpen} menu_id={menu_id}
                           selectGroup={selectGroup} />
      }
    </>
  )
}
export default Modal