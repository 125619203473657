import React, { forwardRef } from 'react'
import { Box } from '../../index'
import { ReactComponent as CheckboxChecked } from 'images/checkbox-checked.svg'
import { ReactComponent as CheckboxUnchecked } from 'images/checkbox-unchecked.svg'
import { TableStyles } from 'react-data-table-component/dist/src/DataTable/types'

// Components
export const BootyCheckbox = forwardRef((props: any, ref: any) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' ref={ref} position='sticky' left='0'>
      {props['checked'] ? (
        <CheckboxChecked {...props} />
      ) : (
        <CheckboxUnchecked stroke='#73737B' {...props} />
      )}
    </Box>
  )
})

// types
export type Props = {
  tableProps: any,
}
export type ExpProps = {
  data: {
    sub_guest_list: [],
    guest_id: number | string
  }
}

// constants
export const getCustomStyles: Function = (sortById = null, isDesc = false): object => {
  const styles: TableStyles = {
    headRow: {
      style: {
        height: '44px !important',
        minHeight: '44px !important',
        border: 'unset',
        position: 'sticky',
        zIndex: 1,
        color: '#73737B',
      },
    },
    headCells: {
      style: {
        fontWeight: 600,
        fontSize: 14,
        backgroundColor: '#F7F7FC',
        opacity: 1,
        position: 'sticky',
        borderTop: '1px solid #E8E8ED',
        borderBottom: '1px solid #E8E8ED',
        '&:hover': {
          opacity: '1 !important',
          'svg': {
            fill: '#73737B',
          },
          'span': {
            opacity: '1 !important',
          },
          'div': {
            opacity: '1 !important',
          },
        },
        '&:nth-of-type(1)': {
          left: '0',
          zIndex: 1,
          borderTopLeftRadius: '8px',
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: '#E8E8ED',
        },
        '&:nth-of-type(2)': {
          left: '48px',
          paddingLeft: '2px',
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: '#E8E8ED',
          zIndex: 1,
        },
        'svg': {
          transform: 'rotate(0) !important',
          fill: '#73737B',
          opacity: 1,
        },
      },
    },
    cells: {
      style: {
        zIndex: 0,
        '&:nth-of-type(1)': {
          opacity: 1,
          position: 'sticky',
          minHeight: '68px',
          left: '0',
          backgroundColor: 'inherit',
          zIndex: 1,
          borderBottom: '1px solid #E8E8ED',
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: '#E8E8ED',
        },
        '&:nth-of-type(2)': {
          opacity: 1,
          position: 'sticky',
          minHeight: '68px',
          left: '48px',
          backgroundColor: 'inherit',
          zIndex: 1,
          borderBottom: '1px solid #E8E8ED',
          paddingLeft: '2px',
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: '#E8E8ED',
        },
      },
    },
    rows: {
      style: {
        height: '68px',
        '&:hover': {
          backgroundColor: '#F7F7FC !important',
        },
      },
    },
    table: {
      style: {
        height: 'calc(100% - 120px)',
        '.rdt_TableHead': {
          zIndex: 2,
        },
      },
    },
    expanderRow: {
      style: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: '#E8E8ED',
        
        'div': {
          overflow: 'unset !important',
          cursor: 'pointer',
        },
        cursor: 'pointer',
      },
    },
  }

  if (sortById && isDesc) {
    // @ts-ignore
    styles.headCells.style[`&:nth-of-type(${sortById + 1}) svg`] = {
      transform: 'rotate(180deg) !important',
    }
  }

  return styles
}

// functions
export const isExpanded = (row: any) => row.isExpanded