import React, { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { split, last, path } from 'ramda'

import { getIsLoggedIn } from 'store/modules/auth/selectors'
import { Box, Text, Button } from 'components/ui'
import { ReactComponent as Logo } from 'images/logo.svg'
import { ReactComponent as HeartsBackground } from 'images/hearts-background.svg'

type Props = {
  isWeddingPlanner: boolean
}

export const Wrapper: FC<Props> = ({ children, isWeddingPlanner }) => {
  const isLoggedIn = useSelector(getIsLoggedIn)
  const { push } = useHistory()
  const { pathname } = useLocation()

  const pageNumber = useMemo(() => {
    const lastPathname = last(split('/', pathname))
    const int = parseInt(lastPathname || '0', 10)

    return isNaN(int) ? 0 : int
  }, [pathname])

  const handleClickSkip = useCallback(() => {
    push({
      pathname: `/wedding/create/${
        pageNumber === 7 && !isLoggedIn ? 'signup' : `step/${pageNumber + 1}`
      }`,
      state: { transition: 'wizard-content-forward' },
    })
  }, [push, pageNumber, isLoggedIn])
  const handleClickLogIn = useCallback(
    () => push('/login', { step: pageNumber }),
    [push, pageNumber]
  )

  const pages = useMemo(
    () => [
      { label: 'Introduction', isSkippable: false },
      {
        label: isWeddingPlanner ? 'Newlyweds-To-Be' : 'Spouse-To-Be',
        isSkippable: false,
      },
      { label: 'Wedding Day', isSkippable: false },
      { label: 'Number of Guests', isSkippable: false },
      { label: 'RSVP', isSkippable: false },
      { label: 'The Venue', isSkippable: false },
      { label: 'Profile Picture', isSkippable: true },
      { label: 'Create a Theme', isSkippable: false },
      { label: 'Create a Theme 2', isSkippable: false },
      { label: 'Privacy Settings', isSkippable: false },
      { label: 'Invite your Fiancé' },
    ],
    [isWeddingPlanner]
  )

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={[1, '28px']}
        height="80px"
        borderBottom="1px solid"
        borderBottomColor="gray"
        position="relative"
      >
        <Box ml="-7px">
          <Logo width="140px" />
        </Box>

        <Box
          position="absolute"
          top="30px"
          left="0px"
          right="0px"
          display="flex"
          justifyContent={['flex-end', 'center']}
          px={1}
        >
          {pageNumber !== 0 && (
            <Text
              fontFamily="1"
              fontSize={1}
              fontWeight={600}
              color="secondary"
              lineHeight="22px"
            >
              {pageNumber !== pages.length
                ? `${pageNumber} of ${pages.length}`
                : 'Final step!'}
            </Text>
          )}

          {!!path(['isSkippable'], pages[pageNumber - 1]) && (
            <>
              <Text
                fontFamily="1"
                fontSize={1}
                fontWeight={400}
                color="secondary"
                lineHeight="22px"
              >
                &nbsp;|&nbsp;
              </Text>

              <Button p="0px" ml="2px" height="22px" onClick={handleClickSkip}>
                <Text
                  color="secondary"
                  fontFamily="1"
                  fontSize={1}
                  fontWeight={400}
                >
                  Skip for now
                </Text>
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Box
        position="relative"
        bg="#e8e8ed"
        height={
          isLoggedIn || pageNumber === 0
            ? 'calc(100vh - 80px)'
            : 'calc(100vh - 160px)'
        }
        width="100vw"
        overflowY="auto"
      >
        <Box
          position="absolute"
          top="29px"
          bottom="0px"
          left="0px"
          right="0px"
          px={0}
          mx="auto"
          width="100%"
          maxWidth="1216px"
          display="flex"
          justifyContent="space-between"
        >
          <Box mt="304px">
            <HeartsBackground style={{ transform: 'rotateY(180deg)' }} />
          </Box>

          <HeartsBackground />
        </Box>

        <Box
          position="absolute"
          top="67px"
          left={1}
          right={1}
          bottom="0px"
          zIndex={1}
        >
          {children}
        </Box>
      </Box>

      {!isLoggedIn && pageNumber !== 0 && (
        <Box
          position="fixed"
          bottom="0px"
          right="0px"
          left="0px"
          borderTop="1px solid"
          borderColor="gray"
          height="80px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontFamily="1" fontSize={1} color="secondary">
            Already have an account?
          </Text>

          <Button variant="link" fontWeight="bold" onClick={handleClickLogIn}>
            Log in
          </Button>
        </Box>
      )}
    </>
  )
}
