import React, { FC, useCallback, useMemo } from 'react'
import { map, toUpper } from 'ramda'

import { Box, Text } from 'components/ui'
import { getEmojis } from 'utils/messages'
import Menu from './Menu'
import EmojiButton from './EmojiButton'

type Props = {
  onSelectEmoji: (x: any) => void
}

const EmojiPicker: FC<Props> = ({ onSelectEmoji }) => {
  const categories = useMemo(
    () => [
      { name: 'people', label: 'People' },
      { name: 'nature', label: 'Nature' },
      { name: 'foods', label: 'Food' },
      { name: 'activity', label: 'Activities' },
      { name: 'symbols', label: 'Symbols' },
    ],
    []
  )

  const emojis = getEmojis()

  const handleClickEmojiButton = useCallback(
    (emoji) => () => onSelectEmoji(emoji),
    [onSelectEmoji]
  )

  return (
    <Menu>
      {map(
        (category) => (
          <Box key={category.name}>
            <Box
              px={1}
              pt={0}
              mb="4px"
              position="sticky"
              top="0px"
              bg="rgba(255,255,255,0.8)"
            >
              <Text fontSize="13px" color="#909499" fontWeight={600}>
                {toUpper(category.label)}
              </Text>
            </Box>

            <Box display="flex" flexWrap="wrap" px="12px">
              {map(
                ({ name, emoji }) => (
                  <EmojiButton
                    key={name}
                    onClick={handleClickEmojiButton(emoji)}
                  >
                    {emoji}
                  </EmojiButton>
                ),
                emojis[category.name]
              )}
            </Box>
          </Box>
        ),
        categories
      )}
    </Menu>
  )
}

export default EmojiPicker
