import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { map } from 'ramda'
import { toast } from 'react-toastify'
import { INPUT_MASK_GUEST_UPDATE_PHONE_NUMBER } from 'constants/masks'

// Utils
import {
  textInputStyles,
  StyledDiv,
  ParamsType,
  ComponentProps,
  submitProps,
  convertFormToPayload,
  handleValidate,
  countryOptions,
} from '../../../utils'
import {
  titleOptionsList,
  parseGuestForEdit,
  RSVPStatuses, NeedsHotelOptions, AgeRangeOptions,
} from 'utils/guest'

// Store tools
import { useDispatch, useSelector } from 'react-redux'
import { editGuest } from 'store/modules/guests/actions'
import { getAllLists } from 'store/modules/guests/selectors'

// Components
import Submit from '../../Submit'
import { Input, Select } from 'components/form'
import Box from 'components/ui/Box'
import { BorderLine } from 'routes/Agenda/utils'
import TabsRound from 'routes/Agenda/EditEventDrawer/TabsRound'
import { Text } from '../../../../ui'

// Icons
import { ReactComponent as RSVPIcon } from 'images/RSVP.svg'
import { ReactComponent as ContactIcon } from 'images/contact.svg'
import { ReactComponent as MenuIcon } from 'images/menuGuests.svg'
import { ReactComponent as NeedsHotelIcon } from 'images/needs-hotel.svg'
import { ReactComponent as AgeRangeIcon } from 'images/age-range.svg'
import { ReactComponent as GiftIcon } from 'images/gift-received.svg'
import { ReactComponent as CheckboxChecked } from 'images/checkbox-checked.svg'
import { ReactComponent as CheckboxUnchecked } from 'images/checkbox-unchecked-modal.svg'


const Information: FC<ComponentProps> = ({ guestForEdit, handleCloseEditForm, rsvpValue }) => {
  const dispatch = useDispatch()

  const params = useParams<ParamsType>()

  const [groupOptions, setGroupOptions] = useState<{ value: string; label: string | null }[]>([])
  const [guest, setGuest] = useState({ ...guestForEdit })
  const [activeTab, setActiveTab] = useState<string>(guest.rsvp_status)
  const [needsHotelActiveTab, setNeedsHotelActiveTab] = useState<string>(guest.is_need_hotel)
  const [ageRangeActiveTab, setAgeRangeActiveTab] = useState<string>(guest.age_range)
  const [isChecked, setIsChecked] = useState(false)

  const groups = useSelector(getAllLists)

  const onSubmit = useCallback(async (data: object) => {
    return dispatch(editGuest(data))
  }, [dispatch])

  const onSubmitForm = useCallback(async (data: submitProps) => {
    try {
      data.wuid = params.wuid
      data.guest_id = guestForEdit.guest_id
      data.rsvp_status = activeTab
      data.is_need_hotel = needsHotelActiveTab
      data.age_range = ageRangeActiveTab

      await onSubmit(convertFormToPayload(data))

      handleCloseEditForm()
    } catch (err) {
      toast(err.message)
    }
  }, [params.wuid, guestForEdit.guest_id, activeTab, needsHotelActiveTab, ageRangeActiveTab, onSubmit, handleCloseEditForm])

  useEffect(() => {
    if (groups.length) {
      setGroupOptions(map((group: any) => ({ value: group.id, label: group.name }), groups))
    }
  }, [groups])

  const handleChangeRsvp = (index: number) => {
    setActiveTab(`${index}`)
    setGuest({
      ...guest,
      rsvp_status: `${index}`,
    })
  }

  const handleChangeNeedsHotel = (index: number) => {
    setNeedsHotelActiveTab(`${index}`)
    setGuest({
      ...guest,
      is_need_hotel: `${index}`,
    })
  }

  const handleChangeAgeRange = (index: number) => {
    setAgeRangeActiveTab(`${index}`)
    setGuest({
      ...guest,
      age_range: `${index}`,
    })
  }

  return (
    <StyledDiv>
      <Form onSubmit={onSubmitForm} initialValues={parseGuestForEdit(guestForEdit, groups, countryOptions)}
            validate={handleValidate}>
        {(props: any) => {
          const { rsvp_status, list_id, honorific_id } = props.values

          props.form.change('rsvp_status', rsvpValue ? rsvpValue : rsvp_status)

          // delete honorific_id if empty
          if (honorific_id && honorific_id.value === '0') {
            props.form.change('honorific_id', null)
          }

          // delete list_id if empty
          if (list_id && list_id.value === '0') {
            props.form.change('list_id', null)
          }

          return (
            <>
              <Box marginBottom='21px' marginTop='25px'>
                <Select height={40} isSearchable={false} name='honorific_id' label='Title' placeholder='Select a Title'
                        value={honorific_id} options={titleOptionsList} />
              </Box>

              <Box marginBottom='21px'>
                <Input hideErrorText={true} name='first_name' style={textInputStyles} label='First Name'
                       placeholder='First Name' />
              </Box>

              <Box marginBottom='21px'>
                <Input hideErrorText={true} name='last_name' style={textInputStyles} label='Last Name'
                       placeholder='Last Name' />
              </Box>

              <Box marginBottom='24px'>
                <Select
                  name='list_id'
                  label='Group'
                  placeholder='Select a Group'
                  value={list_id && list_id.value === '0' ? null : list_id}
                  options={groupOptions}
                />
              </Box>

              <BorderLine />

              <Box marginTop='20px' marginBottom='24px'>
                <Box display='flex' alignItems='center' marginBottom='20px'>
                  <Box marginRight='8px'>
                    <RSVPIcon />
                  </Box>
                  <Text
                    fontFamily='Source Sans Pro'
                    fontWeight='700'
                    fontSize='20px'
                    lineHeight='24px'
                    color='#353B60'
                  >
                    RSVP
                  </Text>
                </Box>
                <Box>
                  <TabsRound
                    options={RSVPStatuses}
                    activeTab={activeTab}
                    handleClick={handleChangeRsvp}
                  />
                </Box>
              </Box>
              <BorderLine />
              <Box marginTop='20px' marginBottom='24px'>
                <Box display='flex' alignItems='center' marginBottom='20px'>
                  <Box marginRight='8px'>
                    <ContactIcon />
                  </Box>
                  <Text
                    fontFamily='Source Sans Pro'
                    fontWeight='700'
                    fontSize='20px'
                    lineHeight='24px'
                    color='#353B60'
                  >
                    Contact
                  </Text>
                </Box>
                <Box marginBottom='20px'>
                  <Input hideErrorText={true} name='email' label='Email' style={textInputStyles} placeholder='Email' />
                </Box>

                <Box>
                  <Input
                    hideErrorText={true}
                    name='phone'
                    label='Phone Number'
                    style={textInputStyles}
                    placeholder='Phone'
                    mask={INPUT_MASK_GUEST_UPDATE_PHONE_NUMBER}
                  />
                </Box>

              </Box>
              <BorderLine />
              <Box marginTop='20px' marginBottom='24px'>
                <Box display='flex' alignItems='center' marginBottom='20px'>
                  <Box marginRight='8px'>
                    <MenuIcon />
                  </Box>
                  <Text
                    fontFamily='Source Sans Pro'
                    fontWeight='700'
                    fontSize='20px'
                    lineHeight='24px'
                    color='#353B60'
                  >
                    Menu Choice
                  </Text>
                </Box>
                <Box marginBottom='24px'>
                  <Select
                    name='food'
                    label='Food Item'
                    placeholder='Select a Food'
                  />
                </Box>
              </Box>
              <BorderLine />
              <Box marginTop='20px' marginBottom='24px'>
                <Box display='flex' alignItems='center' marginBottom='20px'>
                  <Box marginRight='8px'>
                    <NeedsHotelIcon />
                  </Box>
                  <Text
                    fontFamily='Source Sans Pro'
                    fontWeight='700'
                    fontSize='20px'
                    lineHeight='24px'
                    color='#353B60'
                  >
                    Needs Hotel
                  </Text>
                </Box>
                <Box>
                  <TabsRound
                    options={NeedsHotelOptions}
                    activeTab={needsHotelActiveTab}
                    handleClick={handleChangeNeedsHotel}
                  />
                </Box>
              </Box>
              <BorderLine />
              <Box marginTop='20px' marginBottom='24px'>
                <Box display='flex' alignItems='center' marginBottom='20px'>
                  <Box marginRight='8px'>
                    <AgeRangeIcon />
                  </Box>
                  <Text
                    fontFamily='Source Sans Pro'
                    fontWeight='700'
                    fontSize='20px'
                    lineHeight='24px'
                    color='#353B60'
                  >
                    Age Range
                  </Text>
                </Box>
                <TabsRound
                  options={AgeRangeOptions}
                  activeTab={ageRangeActiveTab}
                  handleClick={handleChangeAgeRange}
                />
              </Box>
              <BorderLine />
              <Box marginTop='20px'>
                <Box display='flex' alignItems='center' marginBottom='20px'>
                  <Box marginRight='8px'>
                    <GiftIcon />
                  </Box>
                  <Text
                    fontFamily='Source Sans Pro'
                    fontWeight='700'
                    fontSize='20px'
                    lineHeight='24px'
                    color='#353B60'
                  >
                    Gifts Received
                  </Text>
                </Box>
                <Box marginBottom='8px'>
                  <Input
                    name='notes'
                    label='Description'
                    placeholder='Hand towels and $300 cash'
                    isTextarea={true}
                    style={{
                      minHeight: '122px',
                      maxHeight: '122px',
                      padding: '11px 12px 89px 12px',
                    }}
                  />
                </Box>
                <Box marginBottom='32px'>
                  <Box display='flex' alignItems='center'>
                    <Box marginRight='10px'>
                      {isChecked ? (
                        <CheckboxChecked onClick={() => setIsChecked(!isChecked)} />
                      ) : (
                        <CheckboxUnchecked
                          stroke='#353B60'
                          onClick={() => setIsChecked(!isChecked)}
                        />
                      )}
                    </Box>
                    <Box>
                      <Text
                        color='#353B60'
                        fontWeight='400'
                        fontSize='14px'
                        lineHeight='18px'
                      >
                        Thank you card sent
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>


              <Submit isInvalid={props.invalid} handleSubmit={props.form.submit} handleClose={handleCloseEditForm} />
            </>
          )
        }}
      </Form>
    </StyledDiv>
  )
}

export default Information