import React from 'react'

import { Button, Text } from 'components/ui'
import { ReactComponent as CollapseIcon } from './panel-collapse.svg'

type Props = {
  isExpanded: boolean
  onClickButton: VoidFunction
}

const GuestToolsButton = ({ isExpanded, onClickButton }: Props) => (
  <Button
    width="156px"
    height="40px"
    pl="16px"
    pr="20px"
    border="1px solid #CBCBD3"
    borderRadius="10px"
    justifyContent="space-between"
    onClick={onClickButton}
  >
    <Text fontWeight={600} fontSize="16px" color="#353B60">
      {isExpanded ? 'Hide' : 'Show'} Guests
    </Text>

    <CollapseIcon
      style={{ transform: isExpanded ? undefined : 'rotate(180deg)' }}
    />
  </Button>
)

export default GuestToolsButton
