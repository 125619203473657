import { pick } from 'ramda'

import { ZIP_START } from '../actionTypes'

export const zipStart = (data: object) => ({
  type: ZIP_START.name,
  api: {
    url: 'albums/zip_start',
    data: pick(['wuid', 'album_ids', 'photo_ids'], data),
  },
})
