import React, { FC } from 'react'
import styled from 'styled-components'
import { equals, path } from 'ramda'

import { Box, Avatar, Text, Button } from 'components/ui'
import { getFullName, getImageLink } from 'utils/guest'
import { ReactComponent as RemoveIcon } from 'images/close.svg'
import OnlineIndicator from '../../OnlineIndicator'

const RemoveButton = styled(Button).attrs({
  border: 'none',
  px: '4px',
  position: 'absolute',
  right: 0,
  top: 0,
  bg: 'transparent',
  opacity: 0,
})``

const Wrapper = styled(Box).attrs({
  display: 'inline-flex',
  alignItems: 'center',
  p: 0,
  borderRadius: 0,
  width: '100%',
  position: 'relative',
})`
  &:hover {
    background-color: ${path(['theme', 'colors', 'lightGray'])};

    ${RemoveButton} {
      opacity: 1;
    }
  }
`

type Props = {
  first_name: string
  last_name: string
  image?: string
  status: number
  hideRemoveButton: boolean
  onClickRemove: () => void
}

const Item: FC<Props> = ({
  first_name,
  last_name,
  image,
  status,
  hideRemoveButton,
  onClickRemove,
}) => {
  const fullName = getFullName([first_name, last_name])

  return (
    <Wrapper>
      {!hideRemoveButton && (
        <RemoveButton onClick={onClickRemove}>
          <RemoveIcon fill="#99a2ad" width={16} height={16} />
        </RemoveButton>
      )}

      <Box width="40px" mr="12px" position="relative">
        <Avatar
          name={fullName}
          image={getImageLink(image)}
          size="40px"
          fontSize={2}
        />

        {equals(status, 1) && <OnlineIndicator />}
      </Box>

      <Text fontWeight={600}>{fullName}</Text>
    </Wrapper>
  )
}

export default Item
