import React, { FC } from 'react'
import { useLocation, useHistory } from 'react-router'
import styled from 'styled-components'
import { equals } from 'ramda'

import { Box, Button as ButtonBase } from 'components/ui'
import { LOGIN, SIGNUP } from 'constants/paths'

type ButtonProps = { isActive: boolean }

const Button = styled(ButtonBase).attrs(({ isActive }: ButtonProps) => ({
  border: '1px solid',
  borderColor: 'gray',
  width: 1 / 2,
  height: 44,
  bg: isActive ? 'rgba(84, 88, 247, 0.08)' : 'white',
  color: isActive ? 'blue' : 'darkGray',
  fontFamily: 1,
  disabled: isActive,
}))<ButtonProps>`
  &:disabled {
    opacity: 1;
  }

  &:nth-child(odd) {
    border-right: none;
  }
`

const AuthNav: FC = () => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const isLogin = equals(pathname, LOGIN)

  const handleClick = () => push(isLogin ? SIGNUP : LOGIN)

  return (
    <Box display="flex">
      <Button
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        isActive={isLogin}
        onClick={handleClick}
      >
        Login
      </Button>

      <Button
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        isActive={!isLogin}
        onClick={handleClick}
      >
        Sign Up
      </Button>
    </Box>
  )
}

export default AuthNav
