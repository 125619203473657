import React, { FC, useCallback, useState, UIEvent, useMemo } from 'react'

import { ReactComponent as CloseIcon } from 'images/close.svg'
import Modal from '../Modal'
import Box from '../Box'
import Text from '../Text'
import Button from '../Button'
import FontItem from './FontItem'

type Props = {
  isSecondary?: boolean
  isOpen: boolean
  options: { label: string; value: string }[]
  weddingName: string
  onClose: () => void
  onScrollToBottom: () => void
  onChange: (value: any) => void
}

const FontSelectorModal: FC<Props> = ({
  isSecondary,
  isOpen,
  options,
  weddingName,
  onChange,
  onClose,
  onScrollToBottom,
}) => {
  const [selectedValue, setSelectedValue] = useState<{
    label: string
    value: string
  }>()

  const handleSelectValue = useCallback(
    (option) => () => setSelectedValue(option),
    []
  )

  const handleScroll = useCallback(
    ({ currentTarget }: UIEvent<HTMLDivElement>) => {
      if (
        currentTarget.scrollHeight - currentTarget.scrollTop ===
        currentTarget.clientHeight
      ) {
        onScrollToBottom()
      }
    },
    [onScrollToBottom]
  )

  const handleSave = useCallback(() => {
    onChange(selectedValue)

    onClose()
  }, [selectedValue, onChange, onClose])

  const modalStyle = useMemo(() => {
    if (isSecondary) {
      return { content: { maxWidth: 584, top: 169 } }
    }

    return { content: { maxWidth: 736, top: 157 } }
  }, [isSecondary])

  return (
    <Modal isOpen={isOpen} style={modalStyle} onRequestClose={onClose}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        height="60px"
        pl="24px"
        pr="14px"
        borderBottom="1px solid"
        borderBottomColor="gray"
      >
        <Text fontSize="20px" fontWeight={600} color="#353B60">
          Select a Font
        </Text>

        <Button onClick={onClose}>
          <CloseIcon fill="#353B60" />
        </Button>
      </Box>

      <Box
        pb="20px"
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
        bg="#F6F7F9"
      >
        <Box
          display="flex"
          flexWrap="wrap"
          pt="20px"
          px="24px"
          height="360px"
          overflowY="auto"
          onScroll={handleScroll}
        >
          {options.map((option) => (
            <Box
              key={option.label}
              width={isSecondary ? 1 : 1 / 2}
              px={!isSecondary ? '4px' : undefined}
              mb={isSecondary ? '20px' : '8px'}
            >
              <FontItem
                isSecondary={isSecondary}
                option={option}
                weddingName={weddingName}
                isActive={selectedValue?.label === option.label}
                onClick={handleSelectValue(option)}
              />
            </Box>
          ))}
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt="30px"
          px="24px"
        >
          <Box display="flex" alignItems="center">
            <Button bg="transparent" onClick={onClose}>
              <Text fontSize="16px" color="primary">
                Cancel
              </Text>
            </Button>

            <Box ml={3}>
              <Button
                borderRadius="6px"
                bg="primary"
                px="20px"
                height="40px"
                onClick={handleSave}
              >
                <Text fontSize="16px" fontWeight={600} color="white">
                  Save
                </Text>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default FontSelectorModal
