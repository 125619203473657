import React, { useCallback, MouseEvent } from 'react'

import { Button, Box, Text } from 'components/ui'
import { Types, TypeLabels } from 'routes/Seating'
import { ReactComponent as CircleIcon } from './circle.svg'
import { ReactComponent as HalfCircleIcon } from './half-circle.svg'
import { ReactComponent as RectangleIcon } from './rectangle.svg'
import { ReactComponent as SquareIcon } from './square.svg'
import { ReactComponent as DanceFloorIcon } from './dance-floor.svg'
import { ReactComponent as LongIcon } from './long.svg'
import { ReactComponent as RoundIcon } from './round.svg'
import { ReactComponent as StageIcon } from './stage.svg'

type Props = {
  activeType: Types
  onSetValue: (field: string, value: any) => void
}

const Type = ({ activeType, onSetValue }: Props) => {
  const onSelect = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const type = (e.currentTarget.value as unknown) as Types

      if (
        [
          Types.CIRCLE,
          Types.HALF_CIRCLE,
          Types.HALF_CIRCLE,
          Types.RECTANGLE,
          Types.SQUARE,
        ].includes(type)
      ) {
        onSetValue('type', type)
      }
    },
    [onSetValue]
  )

  const renderIcon = useCallback((type: Types) => {
    switch (type) {
      case Types.CIRCLE:
        return <CircleIcon width="48px" height="48px" />
      case Types.HALF_CIRCLE:
        return <HalfCircleIcon width="48px" height="48px" />
      case Types.RECTANGLE:
        return <RectangleIcon width="48px" height="48px" />
      case Types.LONG:
        return <LongIcon />
      case Types.ROUND:
        return <RoundIcon />
      case Types.DANCE_FLOOR:
        return <DanceFloorIcon />
      case Types.STAGE:
        return <StageIcon />
      default:
        return <SquareIcon width="48px" height="48px" />
    }
  }, [])

  return (
    <Box overflowY="scroll" width="350px" display="flex" mb="24px">
      {Object.values(Types).map((type) => (
        <Button
          key={type}
          as="button"
          width="98px"
          minWidth="98px"
          height="118px"
          flexDirection="column"
          justifyContent="space-between"
          border="2px solid"
          borderColor={type === activeType ? '#5458F7' : '#E8E8ED'}
          borderRadius="8px"
          py="16px"
          mr="16px"
          value={type}
          onClick={onSelect}
        >
          {renderIcon(type)}

          <Text
            fontWeight={600}
            fontSize="16px"
            lineHeight="20px"
            color={type === activeType ? '#5458F7' : '#414042'}
          >
            {TypeLabels[type]}
          </Text>
        </Button>
      ))}
    </Box>
  )
}

export default Type
