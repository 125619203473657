import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { path, pathOr, isNil, reject, propEq } from 'ramda'
import { format, parseISO } from 'date-fns'
import { toast } from 'react-toastify'
import styled from 'styled-components'

// Utils
import {
  getImage,
  getRSVPStatus,
  getVaccineStatus,
  checkUnique,
  getAddress,
  getFullName,
  ModalCrudTypes,
  getAgeRange,
  isNeedHotel,
  DataTableBoxStyle,
} from 'utils/guest'

// Store tools
import { Guest } from 'store/modules/guests/reducer'
import { listAdmin, removeGuest } from 'store/modules/guests/actions'
import {
  getAllLists,
  getListsIsLoaded,
  getAllGuests,
} from 'store/modules/guests/selectors'

// Components
import { Box, Text, Button, SearchInput, Avatar } from 'components/ui'
import RsvpTracking from 'components/ResponsesModal/rsvpTracking'
import DefaultSelect from 'components/ui/Select/DefaultSelect'
import DataTableComponent from 'components/ui/DataTable'
import GroupButton from 'components/Guests/GroupButton'
import DeleteModal from 'components/Modal/DeleteModal'
import GuestDetails from 'components/GuestDetails'
import GuestCreate from 'components/AddGuestDrawer'
import { GroupModal } from 'components/Guests'

// Icons
import { ReactComponent as ChevronIcon } from 'images/chevron-down.svg'
import { ReactComponent as ChevronRightIcon } from 'images/chevron-right.svg'
import { ReactComponent as PlusIcon } from 'images/plus.svg'
import { ReactComponent as DeleteIcon } from 'images/trash-2.svg'
import { ReactComponent as DownloadIcon } from 'images/download.svg'
import { ReactComponent as RsvpIcon } from 'images/rsvp-icon.svg'
import { ReactComponent as UploadIcon } from 'images/upload-dark.svg'
import { Title } from '../Agenda/utils'

// Styled Components
const GeneralContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
`

// Declaring Constants
const groupButtonData: [string, string] = ['By Guest', 'By Party']

// Declaring types
type modalStatus = {
  isOpen: boolean
  name: string
  type: ModalCrudTypes
  id: string
}

const defaultModalStatus: modalStatus = {
  isOpen: false,
  name: '',
  type: '',
  id: '',
}

const Guests: FC = () => {
  // Declaring tools
  const dispatch = useDispatch()

  // Initializing data
  // --- From Params
  // const { wuid } = useParams<{ wuid: string }>()

  // --- From Redux State
  const isLoaded: boolean = useSelector(getListsIsLoaded)
  const storeGuestData = useSelector(getAllGuests)
  const groups = useSelector(getAllLists)

  // --- Declaring Local State
  // > statuses
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
  const [modalStatus, setModalStatus] = useState<modalStatus>(
    defaultModalStatus,
  )
  // > data
  const [activeTableType, setActiveTableType] = useState<string>('By Guest')
  const [tableData, setTableData] = useState<Guest[]>(storeGuestData)
  const [selectedDataCount, setSelectedDataCount] = useState<number>(0)
  const [totalGuestsCount, setTotalGuestsCount] = useState<number>(
    storeGuestData.length,
  )
  const [guestForEdit, setGuestForEdit] = useState<Guest | null>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  // --- Declaring Refs for selected data
  const selectedRowsRef = useRef<Guest[]>([])
  const selectedExpandableRowsRef = useRef<{ [keys: string]: Array<Guest> }>({})

  // Declaring useMemos
  const groupsDropDownOptions: Array<any> = useMemo(
    () => reject(propEq('id', '0'), groups),
    [groups],
  )

  // --- Declaring Functions for dispatch
  const onRemoveGuests = useCallback(
    async (guestIds: string) => dispatch(removeGuest(guestIds)),
    [dispatch],
  )

  const onLoad = useCallback(async () => dispatch(listAdmin()), [dispatch])

  const handleLoad = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoad()
    } catch (error) {
      toast(error.message)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading, onLoad])

  const handleClickRemove = useCallback(
    async (guestIds: any) => {
      try {
        await onRemoveGuests(guestIds.join(','))
      } catch (error) {
        toast(error.message)
      }
    },
    [onRemoveGuests],
  )

  // Toggling statuses
  const handleCloseModal = useCallback((): void => {
    setModalStatus(defaultModalStatus)
  }, [])

  const getGroupName = useCallback(
    (listId: string): string => {
      const group = groups.find((i) => i.id === listId)
      return pathOr('', ['name'], group)
    },
    [groups],
  )

  const handleRowToggle = useCallback(
    (row: Guest): void => {
      setTableData((prev) => {
        return prev.map((guest) => {
          if (
            guest.guest_id === row.guest_id &&
            row.sub_guest_list.length > 0
          ) {
            guest.isExpanded = !row.isExpanded
          }
          return guest
        })
      })
    },
    [setTableData],
  )

  const handleChangeType = useCallback((type: string): void => {
    selectedRowsRef.current = []
    selectedExpandableRowsRef.current = {}
    setSelectedDataCount(0)
    setActiveTableType(type)
  }, [])

  // Guests CRUD
  const onGuestCreate = useCallback(() => {
    setModalStatus({
      isOpen: true,
      name: 'guest',
      type: 'create',
      id: '',
    })
  }, [])

  const deleteGuests = useCallback(() => {
    setDeleteModalIsOpen(selectedDataCount !== 0)
  }, [selectedDataCount])

  const confirmDeleteGuest = useCallback(() => {
    setIsLoading(true)
    const subGuestIds: object[] = []
    const deletedSubGuestIds: object[] = []
    const deletedGuestIds = selectedRowsRef.current.map((guest: any) => {
      if (guest.sub_guest_list.length > 0) {
        guest.sub_guest_list.map((subGuest: any) => {
          subGuestIds.push(subGuest.guest_id)
          return Number(subGuest.guest_id)
        })
      }
      return Number(guest.guest_id)
    })

    Object.keys(selectedExpandableRowsRef.current).map(
      (expandableRowKey: string) => {
        return selectedExpandableRowsRef.current[expandableRowKey].map(
          (row: any) => {
            deletedSubGuestIds.push(row.guest_id)
            return Number(row.guest_id)
          },
        )
      },
    )

    const deletedIds = [
      ...deletedGuestIds,
      ...subGuestIds,
      ...deletedSubGuestIds,
    ].filter(checkUnique)

    setDeleteModalIsOpen(false)
    handleClickRemove(deletedIds).then(() => {
      setSelectedDataCount(0)
      toast('The guests has been deleted successfully')
      selectedRowsRef.current = []
      selectedExpandableRowsRef.current = {}
      setIsLoading(false)
    })
  }, [handleClickRemove])

  // For Data Table
  const changeSelectedGuestCount = useCallback(() => {
    const expandableRowsCount = Object.values(
      selectedExpandableRowsRef.current,
    ).reduce((count: number, nextSubList: Guest[]) => {
      return count + nextSubList.length
    }, 0)

    const count = selectedRowsRef.current.length + expandableRowsCount

    setSelectedDataCount(count)
  }, [])

  const columnsWithFunctions = useCallback(() => {
    return [
      {
        name: 'NAME',
        selector: (row: Guest) => row.first_name,
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row: any) => row.isHover,
            style: {
              borderBottomWidth: '0',
            },
          },
        ],
        cell: (row: any) => {
          const value = getFullName([row.first_name, row.last_name])
          return (
            <Box
              width='100%'
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              onClick={() => setGuestForEdit(row)}
            >
              <Box display='flex' alignItems='center' cursor='pointer'>
                <Box minWidth='40px !important' mr='16px'>
                  <Avatar
                    image={getImage(path(['user', 'image'], row), 'big_icon')}
                    name={
                      value !== '' ? value : row.fake_name ? row.fake_name : ''
                    }
                    fontSize={18}
                    bg='#353B60'
                    color='#FFFFFF'
                  />
                </Box>
                <Text
                  fontSize='16px'
                  maxWidth={150}
                  color={'#414042'}
                  lineHeight='20px'
                  fontWeight={600}
                  isTruncate
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {value}
                </Text>
              </Box>
              {row.sub_guest_list?.length > 0 &&
              activeTableType !== 'By Guest' ? (
                <>
                  <Box
                    ml={1}
                    borderRadius='6px'
                    border='1px solid'
                    borderColor='#CBCBD3'
                    width='20px'
                    minWidth='20px'
                    height='20px'
                    minHeight='20px'
                    display='flex'
                    justifyContent='center'
                    backgroundColor='#fff'
                    alignItems='center'
                    onClick={(e) => {
                      e.stopPropagation()
                      handleRowToggle(row)
                    }}
                  >
                    {row.isExpanded ? <ChevronIcon /> : <ChevronRightIcon />}
                  </Box>
                  {row.isExpanded ? (
                    <Box
                      height='26px'
                      width='2px'
                      backgroundColor='#73737B'
                      position='absolute'
                      left='-24px'
                      top='43px'
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Box>
          )
        },
        width: '226px;',
      },
      {
        name: 'RSVP',
        selector: (row: Guest) => row.rsvp_status,
        sortable: true,
        width: '156px; padding-left: 32px',
        cell: (row: any) => {
          const { text, color, backgroundColor }: any = getRSVPStatus(
            row.rsvp_status,
          )

          return (
            <Box
              backgroundColor={backgroundColor}
              padding='4px 6px'
              borderRadius='3px'
              onClick={(e) => {
                e.stopPropagation()
                setGuestForEdit(row)
              }}
            >
              <Text color={color} fontWeight={600} isTruncate>
                {text}
              </Text>
            </Box>
          )
        },
      },
      {
        name: 'GROUP',
        selector: (row: Guest) => row.list_id,
        sortable: true,
        width: '156px',
        cell: (row: Guest) => {
          return (
            <Text color={'#414042'}>
              {getGroupName(row.list_id) === ''
                ? 'Unassigned'
                : getGroupName(row.list_id)}
            </Text>
          )
        },
      },
      {
        name: 'PLUS-ONES',
        selector: (row: Guest) => row.sub_guest_list,
        sortable: true,
        width: '156px',
        cell: (row: any) => {
          return (
            <Text color={'#414042'}>
              {row.sub_guest_list?.length > 0
                ? `${row.sub_guest_list.length} Guest${
                  row.sub_guest_list.length > 1 ? 's' : ''
                }`
                : '-'}
            </Text>
          )
        },
      },
      {
        name: 'TABLE NAME',
        selector: (row: Guest) => row.seat_info,
        width: '156px',
        sortable: true,
        cell: () => {
          return <Text color={'#414042'}>-</Text>
        },
      },
      {
        name: 'EMAIL',
        selector: (row: Guest) => row.email,
        sortable: true,
        width: '156px',
        cell: (row: Guest) => {
          return (
            // eslint-disable-next-line no-script-url
            <a
              href={row.email ? `mailto:${row.email}` : '#'}
              style={{ textDecoration: 'unset' }}
            >
              <Text
                color={row.email ? '#5458F7' : '#414042'}
                isUnderline={!!row.email}
              >
                {row.email || '-'}
              </Text>
            </a>
          )
        },
      },
      {
        name: 'ADDRESS',
        selector: (row: Guest) =>
          row.street !== ''
            ? row.street
            : row.city !== ''
              ? row.city
              : row.zip !== ''
                ? row.zip
                : row.country,
        sortable: true,
        width: '156px',
        cell: (row: any) => {
          return (
            <Text color={'#414042'}>
              {getAddress([
                row.street,
                row.city,
                row.state,
                row.zip,
                row.country,
              ]) || '-'}
            </Text>
          )
        },
      },
      {
        name: 'PHONE',
        selector: (row: Guest) => row.phone,
        sortable: true,
        width: '156px',
        cell: (row: Guest) => {
          return (
            <Text>
              {
                // @ts-ignore
                row.phone ? `${row.phone}`.match(/.{1,3}/g).join('-') : '-'
              }
            </Text>
          )
        },
      },
      {
        name: 'NOTES',
        selector: (row: Guest) => row.notes,
        sortable: true,
        width: '156px',
        cell: (row: Guest) => {
          return <Text color={'#414042'}>{row.notes || '-'}</Text>
        },
      },
      {
        name: 'AGE RANGE',
        selector: (row: Guest) => row.age_range,
        sortable: true,
        width: '156px',
        cell: (row: Guest) => getAgeRange(row.age_range) || '-',
      },
      {
        name: 'HOTEL',
        selector: (row: Guest) => row.is_need_hotel,
        sortable: true,
        width: '156px',
        cell: (row: Guest) => isNeedHotel(row.is_need_hotel) || '-',
      },
      {
        name: 'SIGNED IN',
        selector: (row: Guest) => row.is_joined,
        sortable: true,
        width: '186px',
        cell: (row: Guest) => {
          return (
            <Text color={'#414042'}>
              {row.is_joined === '1'
                ? format(
                  parseISO(pathOr('', ['joined_dt'], row)),
                  'MMM. dd, yyyy \'at\' hh:mm a',
                )
                : 'Not Signed In'}
            </Text>
          )
        },
      },
    ]
  }, [setGuestForEdit, getGroupName, handleRowToggle, activeTableType])

  const expandableColumnsWithFunction = useCallback(() => {
    return [
      {
        name: 'Name',
        selector: 'NAME',
        cell: (row: Guest) => {
          const value = getFullName([row.first_name, row.last_name])
          return (
            <Box
              display='flex'
              alignItems='center'
              cursor='pointer'
              onClick={() => setGuestForEdit(row)}
            >
              <Box minWidth='40px !important' mr='16px'>
                <Avatar
                  image={getImage(path(['user', 'image'], row), 'big_icon')}
                  name={
                    value !== '' ? value : row.fake_name ? row.fake_name : ''
                  }
                  fontSize={18}
                  bg='#353B60'
                  color='#FFFFFF'
                />
              </Box>
              <Text
                fontSize='16px'
                maxWidth={150}
                lineHeight='20px'
                fontWeight={600}
                isTruncate
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  whiteSpace: 'break-spaces',
                }}
              >
                {value}
              </Text>
            </Box>
          )
        },
        width:
          '226px; padding-left: 2px; z-index: 2;position: sticky;left: 48px;min-height:56px;background: #ffffff',
      },
      {
        name: 'RSVP',
        selector: (row: Guest) => row.rsvp_status,
        width: '156px; padding-left: 32px',
        cell: (row: Guest) => {
          const { text, color, backgroundColor }: any = getRSVPStatus(
            row.rsvp_status,
          )

          return (
            <Box
              backgroundColor={backgroundColor}
              padding='4px 6px'
              borderRadius='3px'
              cursor='pointer'
              onClick={(e) => {
                e.stopPropagation()
                setGuestForEdit(row)
              }}
            >
              <Text color={color} fontWeight={600} isTruncate>
                {text}
              </Text>
            </Box>
          )
        },
      },
      {
        name: 'GROUP',
        selector: 'list_id',
        width: '156px',
        cell: (row: Guest) => {
          return getGroupName(row.list_id) === ''
            ? 'Unassigned'
            : getGroupName(row.list_id)
        },
      },
      {
        name: 'PLUS ONES',
        selector: 'sub_guest_list',
        width: '156px',
        cell: (row: any) => {
          return row.sub_guest_list?.length > 0
            ? `${row.sub_guest_list.length} Guest${
              row.sub_guest_list.length > 1 ? 's' : ''
            }`
            : '-'
        },
      },
      {
        name: 'TABLE',
        selector: 'seat_info',
        width: '156px',
        cell: () => {
          return '-'
        },
      },
      {
        name: 'EMAIL',
        selector: (row: Guest) =>
          row.user?.email ? row.user.email : row.email,
        width: '156px',
        cell: (row: Guest) => {
          return (row.user?.email ? row.user.email : row.email) || '-'
        },
      },
      {
        name: 'ADDRESS',
        selector: (row: Guest) =>
          row.street !== ''
            ? row.street
            : row.city !== ''
              ? row.city
              : row.zip !== ''
                ? row.zip
                : row.country,
        width: '156px',
        cell: (row: any) => {
          return (
            getAddress([
              row.street ?? '',
              row.city ?? '',
              row.state ?? '',
              row.zip ?? '',
              row.country ?? '',
            ]) || '-'
          )
        },
      },
      {
        name: 'PHONE',
        selector: (row: Guest) => row.phone,
        sortable: true,
        width: '156px',
        cell: (row: Guest) => {
          // @ts-ignore
          return row.phone ? `${row.phone}`.match(/.{1,3}/g).join('-') : '-'
        },
      },
      {
        name: 'NOTES',
        selector: (row: Guest) => row.notes,
        width: '156px',
        cell: (row: Guest) => {
          return row.notes || '-'
        },
      },
      {
        name: 'VACCINE STATUS',
        selector: 'vaccine_status',
        width: '156px',
        cell: (row: Guest) => getVaccineStatus(row.vaccine_status) || '-',
      },
      {
        name: 'SIGNED IN',
        selector: 'joined_dt',
        sortable: true,
        width: '156px',
        cell: () => {
          return 'Not Signed In'
        },
      },
    ]
  }, [getGroupName, setGuestForEdit])

  const setSelectedExpandableRows = useCallback(
    ({ selectedRows, guestId }: { selectedRows: Guest[]; guestId: string }) => {
      selectedExpandableRowsRef.current[guestId] = selectedRows

      changeSelectedGuestCount()
    },
    [changeSelectedGuestCount, selectedExpandableRowsRef],
  )

  const setSelectedRows = useCallback(
    (data: Array<Guest>, allSelected: boolean) => {
      if (activeTableType === 'By Party') {
        const selectedGuestsIds: Array<string | number> = selectedRowsRef.current.map(({ guest_id }) => guest_id)

        data.forEach((guest: Guest) => {
          if (
            (!selectedGuestsIds.includes(guest.guest_id) &&
              guest.sub_guest_list.length > 0) ||
            allSelected
          ) {
            selectedExpandableRowsRef.current[guest.guest_id] =
              guest.sub_guest_list
          }
        })

        selectedGuestsIds.forEach((id: string | number) => {
          if (!data.find((guest: Guest) => guest.guest_id === id)) {
            delete selectedExpandableRowsRef.current[id]
          }
        })
      }

      selectedRowsRef.current = data
      changeSelectedGuestCount()
    },
    [
      activeTableType,
      selectedRowsRef,
      selectedExpandableRowsRef,
      changeSelectedGuestCount,
    ],
  )

  const tableProps = {
    activeTableType,
    columnsWithFunctions,
    expandableColumnsWithFunction,
    data:
      activeTableType === 'By Party'
        ? tableData.filter(
          (row: Guest) =>
            isNil(row.parent_guest_id) ||
            row.parent_guest_id === null ||
            row.parent_guest_id === '0',
        )
        : tableData,
    isLoading,
    isTableExpendable: activeTableType === 'By Party',
    setSelectedRows,
    setSelectedExpandableRows,
    selectedRows: [...selectedRowsRef.current],
    selectedExpandableRows: { ...selectedExpandableRowsRef.current },
    onRowClicked: setGuestForEdit,
    style: {
      paddingLeft: '16px',
    },
  }

  useEffect(() => {
    setTotalGuestsCount(storeGuestData.length)
    let dataCopy = [...storeGuestData]
    const rowGuests = dataCopy?.filter(
      (sub: any) => sub.first_name === '' && sub.last_name === '',
    )
    for (let i = 0; i < rowGuests?.length; i++) {
      if (rowGuests[i].first_name === '' && rowGuests[i].last_name === '') {
        rowGuests[i].fake_name = `+ ${JSON.stringify(i + 1)}`
      }
    }

    if (activeTableType === 'By Party') {
      const haveSubGuests: Guest[] = dataCopy?.filter(
        (guest: Guest) => guest.sub_guest_list?.length > 0,
      )
      for (let i = 0; i < haveSubGuests?.length; i++) {
        const subGuests: Guest[] = haveSubGuests[i].sub_guest_list.filter(
          (sub: Guest) => sub.first_name === '' && sub.last_name === '',
        )
        for (let j = 0; j < subGuests?.length; j++) {
          subGuests[j].fake_name = `+ ${JSON.stringify(j + 1)}`
        }
      }
    }

    dataCopy = dataCopy.map((guest: Guest) => {
      return {
        ...guest,
        isExpanded: false,
      }
    })

    setTableData([...dataCopy])
  }, [activeTableType, storeGuestData])

  useEffect(() => {
    if (!isError && !isLoaded && !isLoading) {
      handleLoad()
    }
  }, [isError, isLoading, isLoaded, handleLoad])

  const handleSearch = (event: any) => {
    const query = event.target.value

    let data = [...storeGuestData]


    data = data?.filter((item) => {
      return (
        item.first_name?.toLowerCase().includes(query.toLowerCase()) || item.last_name?.toLowerCase().includes(query.toLowerCase()) || (!item.list ? 'unassigned'.includes(query.toLowerCase()) : item.list?.name?.toLowerCase().includes(query.toLowerCase()))
      )
    })

    setTableData(data)
  }

  return (
    <>
      <GroupModal
        modalType={modalStatus.type}
        isOpen={modalStatus.isOpen && modalStatus.name === 'group'}
        groupId={modalStatus.id}
        onClose={handleCloseModal}
      />

      <DeleteModal
        message='Are you want to delete selected guests'
        isOpen={deleteModalIsOpen}
        onClose={() => setDeleteModalIsOpen(false)}
        onConfirm={confirmDeleteGuest}
      />

      <GuestCreate
        isOpen={modalStatus.isOpen && modalStatus.name === 'guest'}
        onClose={handleCloseModal}
      />

      <GeneralContainer>
        {!!guestForEdit && (
          <GuestDetails
            guestForEdit={guestForEdit}
            handleCloseEditForm={setGuestForEdit}
          />
        )}

        <Box
          display='flex'
          paddingTop='16px'
          paddingRight='16px'
          paddingLeft='16px'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box display='flex' alignItems='center'>
            <Box>
              <Text style={{
                fontFamily: 'Source Sans Pro',
                fontWeight: 700,
                fontSize: '32px',
                lineHeight: '40px',
                color: '#353B60',
                marginRight: '12px',
              }}>
                Guest List
              </Text>
            </Box>
            {totalGuestsCount > 0 && (
              <Box style={{ backgroundColor: '#ECF8F8', borderRadius: '16px', padding: '2px 10px', minWidth: '78px' }}>
                <Title>
                  {totalGuestsCount} {totalGuestsCount > 1 ? 'Guests' : 'Guest'}
                </Title>
              </Box>
            )}
          </Box>

          <Box display='flex'>
            <Box>
              <Box width='100%' mr='12px'>
                <Button
                  border='1px solid'
                  borderColor='#CBCBD3'
                  borderRadius='8px !important'
                  height='40px'
                  bg='transparent'
                  color='#353B60'
                  fontWeight='600'
                  fontSize='16px'
                  fontFamily='Source Sans Pro !important'
                  padding='8px 16px 8px 16px !important'
                >
                  <Box mr={8}>
                    <UploadIcon />
                  </Box>
                  Upload
                </Button>
              </Box>
            </Box>
            <Box>
              <Button
                variant='primary'
                height='40px'
                onClick={onGuestCreate}
                padding='10px 20px 10px 16px !important'
                borderRadius='8px !important'
                fontFamily='Source Sans Pro !important'
              >
                <Box mr={8}>
                  <PlusIcon fill='currentColor' />
                </Box>
                Add Guests
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          marginTop='16px'
          marginBottom='24px'
          display='flex'
          paddingRight='16px'
          paddingLeft='16px'
          justifyContent='space-between'
        >
          <Box display='flex'>
            <Box>
              <GroupButton
                active={activeTableType}
                handleChangeType={handleChangeType}
                labels={groupButtonData}
              />
            </Box>
            <Box display='flex' ml='12px'>
              <DefaultSelect
                label='Add Group'
                name='group'
                options={groupsDropDownOptions}
                dropDownPlaceholder={'Groups'}
                onClick={setModalStatus}
              />
            </Box>
            <Box width='100%' ml='12px'>
              <Button
                border='1px solid'
                borderColor='#CBCBD3'
                borderRadius='8px !important'
                height='40px'
                bg='transparent'
                color='#353B60'
                fontWeight='600'
                fontSize='16px'
                fontFamily='Source Sans Pro !important'
                padding='10px 16px 10px 16px !important'
                onClick={() => setIsOpenModal(!isOpenModal)}
              >
                Responses
                <Box ml={8}>
                  <RsvpIcon fill='currentColor' />
                </Box>
              </Button>
            </Box>
            <Box
              ml='12px'
              height='40px'
              width='1px'
              backgroundColor='#E8E8ED'
            />
            <Box ml='12px'>
              <Button
                border={`1px solid ${
                  selectedDataCount === 0
                    ? '#E8E8ED !important'
                    : '#CBCBD3 !important'
                }`}
                borderRadius='8px'
                p='7px !important'
                height='40px'
                cursor={selectedDataCount === 0 ? 'unset' : 'pointer'}
                backgroundColor='#ffffff !important'
              >
                <DownloadIcon
                  stroke={selectedDataCount === 0 ? '#E8E8ED' : '#353B60'}
                  height='24px'
                />
              </Button>
            </Box>
            <Box ml='12px'>
              <Button
                border={`1px solid ${
                  selectedDataCount === 0
                    ? '#E8E8ED !important'
                    : '#CBCBD3 !important'
                }`}
                borderRadius='8px'
                p='7px !important'
                height='40px'
                backgroundColor='#ffffff !important'
                cursor={selectedDataCount === 0 ? 'unset' : 'pointer'}
                onClick={deleteGuests}
              >
                <DeleteIcon
                  stroke={selectedDataCount === 0 ? '#E8E8ED' : '#353B60'}
                  height='24px'
                />
              </Button>
            </Box>
          </Box>
          <Box display='flex'>
            <Box width='320px'>
              <SearchInput onChange={handleSearch} />
            </Box>
          </Box>
        </Box>
        <DataTableBoxStyle>
          <DataTableComponent tableProps={tableProps} />
        </DataTableBoxStyle>
      </GeneralContainer>
      <RsvpTracking
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(!isOpenModal)}
        tableData={tableData}
      />
    </>
  )
}

export default Guests
