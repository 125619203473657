import React, { FC } from 'react'
import { Box } from 'components/ui'

import Button from 'components/Faq/Tabs/Button'
import '../fonts/fontFamilies/styles.css'

type Props = {
  submitting: boolean
  buttonAction: string
  setIsSubmitClicked: any
}
const CustonButton: FC<Props> = ({
  submitting,
  buttonAction,
  setIsSubmitClicked,
}) => {
  return (
    <Box display="flex" flexDirection="row">
      <Button
        onClick={() => setIsSubmitClicked(true)}
        type="submit"
        variant="primary"
        disabled={submitting}
        fullWidth
        height="44px"
        style={{
          background: 'linear-gradient(180deg, #A9A2FF 0%, #8379F2 90.62%)',
          borderRadius: '12px',
          height: '48px',
          fontFamily: 'Lato',
          fontWeight: '700',
          fontSize: '16px',
          width: '100%',
          lineHeight: '24px',
        }}
      >
        {buttonAction}
      </Button>
    </Box>
  )
}
export default CustonButton
