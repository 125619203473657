import React, { FC, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { equals, path } from 'ramda'
import { toast } from 'react-toastify'

import { Box, Loading } from 'components/ui'
import { getSelectedRoom } from 'store/modules/messages/selectors'
import { getGuestsIsLoaded, getGuest } from 'store/modules/guests/selectors'
import { list } from 'store/modules/guests/actions'
import HeadBlock from './HeadBlock'
import InfoBlock from './InfoBlock'
import Notifications from './Notifications'
import DeleteChat from './DeleteChat'
import Users from './Users'

type Props = {
  socket: WebSocket
}

const RoomInfo: FC<Props> = ({ socket }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const room = useSelector(getSelectedRoom)
  const isLoaded = useSelector(getGuestsIsLoaded)
  const user = useSelector(getGuest(path(['uid'], room)))
  const isPrivate = room ? equals(room.room_type, 2) : undefined

  const onList = useCallback(async () => dispatch(list()), [dispatch])
  const handleLoadGuests = useCallback(async () => {
    setIsLoading(true)

    try {
      await onList()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onList])

  useEffect(() => {
    if (isPrivate && !isLoaded && !isLoading) handleLoadGuests()
  }, [isPrivate, isLoaded, isLoading, handleLoadGuests])

  const renderContent = () => {
    if (isLoading) return <Loading />

    return (
      <>
        <HeadBlock
          image={path(['image'], room)}
          name={path(['name'], room)}
          isPrivate={isPrivate}
          role={path(['wedding_role'], user)}
          roomId={path(['rid'], room)}
          socket={socket}
        />

        <Notifications />

        {isPrivate ? (
          <InfoBlock
            email={path(['email'], user)}
            phone={path(['phone'], user)}
          />
        ) : (
          <Users
            users={path(['users'], room)}
            socket={socket}
            roomId={path(['rid'], room)}
          />
        )}

        <DeleteChat
          socket={socket}
          roomId={path(['rid'], room)}
          roomName={path(['name'], room)}
        />
      </>
    )
  }

  return (
    <Box p={1} position="relative" height="100%" overflowY="auto">
      {renderContent()}
    </Box>
  )
}

export default RoomInfo
