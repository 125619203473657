import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../Context'
import { Form } from 'react-final-form'
import { format } from 'date-fns'
import moment from 'moment-timezone'
import { map } from 'ramda'
// import { Map } from './ googleMap'
import { useJsApiLoader } from '@react-google-maps/api'
import { Autocomplete } from './autocomplete'
import { components } from 'react-select'
import TabsRound from './TabsRound'
import { enUS } from 'date-fns/locale'

// Utils
import {
  EditContainerWrapper,
  MainContainer,
} from 'components/ResponsesModal/utils'
import {
  BorderLine,
  EditScheduleContainer,
  DatesStyled,
  EventDetailContentStyled,
  ButtonsStyled,
  SubmitContainerStyled,
  CancelButtonStyled,
  SubmitButtonStyled,
  EventAccessOptions,
} from '../utils'

// Icons
import { ReactComponent as CloseIcon } from 'images/closeIconGrey.svg'
import { ReactComponent as LocationIcon } from 'images/location.svg'
import { ReactComponent as DressCodeIcon } from 'images/dress.svg'
import { ReactComponent as GuestAccessIcon } from 'images/guestAccess.svg'
import { ReactComponent as PlusIcon } from 'images/add-guest.svg'
import { ReactComponent as TimeIcon } from 'images/time.svg'

// Components
import { Box, Button, FormLabel, Select, Text } from 'components/ui'
import AddGuestsModal from 'components/Modal/addGuestsModal'
import {
  DayPickerInput,
  Input,
  TimePickerInput,
} from 'components/form'

// Store tools
import { useSelector } from 'react-redux'
import { getItem } from 'store/modules/agenda/selectors'


const MAP_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

declare type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]

const libraries: Libraries = ['places']

type Props = {
  googleMaps?: any
  defaultValue?: string
}

const EditEventModal: FC<Props> = () => {

  const {
    wuid,
    // isChecked,
    setIsChecked,
    selectedEvent,
    handleValidate,
    locationValue,
    setLocationValue,
    isOpenEditEventModal,
    setSelectedAccessMode,
    handleCloseEditEventModal,
    handleSubmitEditEventModal,
  } = useContext<any>(Context)

  const agendaItem = useSelector(getItem(selectedEvent.agenda_item_id))

  const formRef = useRef(null)

  const [, setCenter] = useState<any>()
  const [guestCount, setGuestCount] = useState<number | undefined>()
  const [timeZoneVal, setTimeZoneVal] = useState({ label: 'US/Central', value: 'US/Central' })
  const [agenda, setAgenda] = useState({ ...agendaItem })
  const [activeTab, setActiveTab] = useState<string | undefined>()
  const [isOpenAddGuestModal, setIsOpenAddGuestModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')

  const { isLoaded }: any = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${MAP_KEY}`,
    libraries,
  })

  const onPlaceSelect = useCallback((cordinates) => {
    setCenter(cordinates)
  }, [])

  let inputMaxLength: number
  let valueLength: number

  useEffect(() => {
    setLocationValue({
      location_address: agendaItem?.location_address,
      location_lat: agendaItem?.location_lat,
      location_lon: agendaItem?.location_lon,
      location_name: agendaItem?.location_name,
      location_zip: agendaItem?.location_zip,
      location_city: agendaItem?.location_city,
      location_state: agendaItem?.location_state,
    })
  }, [agendaItem, setLocationValue])

  useEffect(() => {
    let centerCopy: any = { lat: Number(agendaItem?.location_lat), lng: Number(agendaItem?.location_lon) }
    setCenter(centerCopy)
  }, [agendaItem])

  useEffect(() => {
    const selected = agendaItem?.location_address && agendaItem?.location_address.length > 0 ? agendaItem.location_address : agendaItem?.location_name
    // @ts-ignore
    setSelectedValue(selected)
  }, [agendaItem?.location_address, agendaItem?.location_name])

  useEffect(() => {
    setGuestCount(agendaItem?.guest_whitelist?.length)
  }, [agendaItem?.guest_whitelist?.length])

  useEffect(() => {
    if (locationValue.inputValue === '') {
      setIsChecked(false)
    }
  }, [locationValue, setIsChecked])

  useEffect(() => {
    setActiveTab(agendaItem?.access_mode)
  }, [agendaItem])

  useEffect(() => {
    if (selectedEvent.title) {
      selectedEvent.titleLength = 60 - selectedEvent.title.length
    } else {
      selectedEvent.titleLength = 60
    }
    if (selectedEvent.attire) {
      selectedEvent.attireLength = 60 - selectedEvent.attire.length
    } else {
      selectedEvent.attireLength = 60
    }
    if (selectedEvent.notes) {
      selectedEvent.descLength = 140 - selectedEvent.notes.length
    } else {
      selectedEvent.descLength = 140
    }
  }, [selectedEvent])

  const handleCountTextLength = (e: any, fieldName: string) => {
    valueLength = e.target.value.length
    if (fieldName === 'title' && valueLength <= 60) {
      inputMaxLength = 60
      selectedEvent.titleLength = 60 - valueLength
      if (valueLength === 0) {
        selectedEvent.titleLength = 60
      }
    } else if (fieldName === 'attire' && valueLength <= 60) {
      inputMaxLength = 60
      selectedEvent.attireLength = 60 - valueLength
      if (valueLength === 0) {
        selectedEvent.attireLength = 60
      }
    } else if (fieldName === 'description' && valueLength <= 140) {
      inputMaxLength = 140
      selectedEvent.descLength = 140 - valueLength
      if (valueLength === 0) {
        selectedEvent.descLength = 140
      }
    }
  }

  const afterSubmit = (props: any) => {
    props.form.submit()
    // @ts-ignore
    formRef.current.scrollTo(0, 0)
  }

  const Option = (props: any) => {
    const ref = useRef()

    useEffect(() => {
      // @ts-ignore
      props.isSelected && ref.current.scrollIntoView({ block: 'nearest', inline: 'start' })
    }, [props.isSelected])

    return (
      <components.Option {...props} innerRef={ref} />
    )
  }

  const handleClick = (index: number) => {
    setActiveTab(`${index}`)
    setAgenda({
      ...agenda,
      access_mode: `${index}`,
    })
    setSelectedAccessMode(`${index}`)
  }

  return (
    <>
      {isOpenEditEventModal ? (
          <EditContainerWrapper onClick={() => handleCloseEditEventModal()}>
            <EditScheduleContainer onClick={(e) => e.stopPropagation()}>
              <MainContainer>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <Box width='100%' height='100%'>
                    <Text
                      fontFamily='Source Sans Pro'
                      fontWeight='700'
                      fontSize='32px'
                      lineHeight='40px'
                      color='#353B60'
                    >
                      Event Details
                    </Text>
                  </Box>
                  <Box cursor='pointer' width='100%'>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='end'
                      onClick={() => handleCloseEditEventModal()}
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                </Box>
                <EventDetailContentStyled ref={formRef}>
                  <Form onSubmit={handleSubmitEditEventModal} initialValues={selectedEvent} validate={handleValidate}>
                    {(props: any) => {
                      const { agenda_item_id } = props.values
                      props.form.change('agenda_item_id', agenda_item_id)

                      return (
                        <>
                          <Box height='100%'>
                            <Box marginBottom='20px' marginTop='24px'>
                              <Input
                                name='title'
                                label='Title'
                                placeholder='Rehearsal'
                                maxLength={selectedEvent.titleLength}
                                id='inputTitle'
                                inputMaxLength={inputMaxLength}
                                showMaxLength={true}
                                style={{
                                  height: '40px',
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '18px',
                                  color: '#414042',
                                  padding: selectedEvent.titleLength < 50 ? '11px 30px 11px 12px' : '11px 12px 11px 12px',
                                }}
                                onInput={(e) => handleCountTextLength(e, 'title')}
                              />
                            </Box>
                            <Box marginBottom='24px'>
                              <Input
                                name='notes'
                                label='Description'
                                placeholder='We can’t wait to celebrate with you all!'
                                isTextarea={true}
                                showMaxLength={true}
                                inputMaxLength={inputMaxLength}
                                style={{
                                  minHeight: '122px',
                                  maxHeight: '122px',
                                  padding: '11px 12px 89px 12px',
                                }}
                                maxLength={selectedEvent.descLength}
                                onInput={(e) => handleCountTextLength(e, 'description')}
                              />
                            </Box>

                            <BorderLine />

                            <Box marginBottom='24px' marginTop='20px'>
                              <Box display='flex' alignItems='center' marginBottom='20px'>
                                <Box marginRight='8px'>
                                  <TimeIcon stroke='#353B60' />
                                </Box>
                                <Text
                                  fontFamily='Source Sans Pro'
                                  fontWeight='700'
                                  fontSize='20px'
                                  lineHeight='24px'
                                  color='#353B60'
                                >
                                  Date and Time
                                </Text>
                              </Box>
                              <Box>
                                <Box marginBottom='20px' width='100%'>

                                  <DatesStyled>
                                    <Box width='50%'>
                                      <FormLabel>Start Date</FormLabel>
                                      <DayPickerInput
                                        name='start_date'
                                        disabledDays={{ before: new Date() }}
                                        placeholder={format(new Date(), 'MMMM d, yyyy', { locale: enUS })}
                                        format='MMMM d, yyyy'
                                      />
                                    </Box>

                                    <Box width='50%'>
                                      <FormLabel>Start Time</FormLabel>
                                      <TimePickerInput name='start_time' />
                                    </Box>
                                  </DatesStyled>
                                </Box>

                                <Box width='100%'>

                                  <DatesStyled>
                                    <Box width='50%'>
                                      <FormLabel>End Date</FormLabel>
                                      <DayPickerInput
                                        name='end_date'
                                        placeholder={format(new Date(), 'MMMM d, yyyy', { locale: enUS })}
                                        format='MMMM d, yyyy'
                                        disabledDays={{ before: new Date(props.values.start_date) }}
                                      />
                                    </Box>
                                    <Box width='50%'>
                                      <FormLabel>End Time</FormLabel>
                                      <TimePickerInput name='end_time' />
                                    </Box>
                                  </DatesStyled>
                                </Box>
                              </Box>
                            </Box>

                            <BorderLine />

                            <Box marginBottom='24px' marginTop='20px'>
                              <Box display='flex' justifyContent='space-between'>
                                <Box display='flex' alignItems='center' marginBottom='20px'>
                                  <Box marginRight='8px'>
                                    <LocationIcon stroke='#353B60' />
                                  </Box>
                                  <Text
                                    fontFamily='Source Sans Pro'
                                    fontWeight='700'
                                    fontSize='20px'
                                    lineHeight='24px'
                                    color='#353B60'
                                  >
                                    Location
                                  </Text>
                                </Box>
                              </Box>
                              <Box marginBottom='20px'>
                                <Autocomplete
                                  selectedValue={selectedValue}
                                  isLoaded={isLoaded}
                                  onSelect={onPlaceSelect}
                                  setLocationValue={setLocationValue}
                                  locationValue={locationValue}
                                  name='location_address'
                                />
                                {/*{(center?.lat !== 0 && center?.lon !== 0) ? (*/}
                                {/*  <Box marginTop='8px'>*/}
                                {/*    <Box marginBottom='20px'>*/}
                                {/*      <Box display='flex' alignItems='center'>*/}
                                {/*        <Box marginRight='10px'>*/}
                                {/*          {isChecked ? (*/}
                                {/*            <CheckboxChecked onClick={() => setIsChecked(!isChecked)} />*/}
                                {/*          ) : (*/}
                                {/*            <CheckboxUnchecked*/}
                                {/*              stroke='#353B60'*/}
                                {/*              onClick={() => setIsChecked(!isChecked)}*/}
                                {/*            />*/}
                                {/*          )}*/}
                                {/*        </Box>*/}
                                {/*        <Box>*/}
                                {/*          <Text*/}
                                {/*            color='#353B60'*/}
                                {/*            fontWeight='400'*/}
                                {/*            fontSize='14px'*/}
                                {/*            lineHeight='18px'*/}
                                {/*          >*/}
                                {/*            Show map in mobile app*/}
                                {/*          </Text>*/}
                                {/*        </Box>*/}
                                {/*      </Box>*/}
                                {/*    </Box>*/}
                                {/*    {isChecked && isLoaded && <Map center={center} />}*/}
                                {/*  </Box>*/}
                                {/*) : null}*/}
                              </Box>
                              <Box>
                                <Select
                                  name='timezone'
                                  label='Time Zone'
                                  width='100%'
                                  placeholder='US/Central'
                                  onChange={(value: any) => setTimeZoneVal(value)}
                                  options={map((zone) => ({ value: zone, label: zone }), moment.tz.names())}
                                  value={timeZoneVal}
                                  maxMenuHeight={200}
                                  menuPlacement='auto'
                                  components={{
                                    IndicatorSeparator: () => null,
                                    Option,
                                  }}
                                />
                              </Box>
                            </Box>

                            <BorderLine />

                            <Box marginTop='20px' paddingBottom='24px'>
                              <Box display='flex' alignItems='center' marginBottom='20px'>
                                <Box marginRight='8px'>
                                  <GuestAccessIcon stroke='#353B60' />
                                </Box>
                                <Text
                                  fontFamily='Source Sans Pro'
                                  fontWeight='700'
                                  fontSize='20px'
                                  lineHeight='24px'
                                  color='#353B60'
                                >
                                  Guest Access
                                </Text>
                              </Box>
                              <Box>
                                <TabsRound
                                  activeTab={activeTab}
                                  handleClick={handleClick}
                                  options={EventAccessOptions}
                                />
                                <Box>
                                  {activeTab === '0' ? (
                                    <Box marginTop={20}>
                                      <Text
                                        fontFamily='Source Sans Pro'
                                        fontWeight={400}
                                        fontSize='14px'
                                        lineHeight='20px'
                                        color='#73737B'
                                      >
                                        All guests can see this event.
                                      </Text>
                                    </Box>
                                  ) : activeTab === '1' ? (
                                    <Box marginTop={20}>
                                      <Text
                                        fontFamily='Source Sans Pro'
                                        fontWeight={400}
                                        fontSize='14px'
                                        lineHeight='20px'
                                        color='#73737B'
                                      >
                                        Only selected guests can see this event.
                                      </Text>
                                      <Box
                                        border='1px solid #E8E8ED'
                                        borderRadius='8px'
                                        background='#FFFFFF'
                                        marginTop='20px'
                                        padding='8px 12px'
                                        onClick={() => setIsOpenAddGuestModal(true)}
                                      >
                                        <Button
                                          onClick={() => {
                                          }}
                                          fontFamily='Source Sans Pro !important'
                                          color='#5458F7'
                                          fontSize='16px'
                                          fontWeight={400}
                                          padding='0'
                                          style={{ height: '24px', padding: 0 }}
                                        >
                                          <Box mr={8}>
                                            <PlusIcon fill='currentColor' />
                                          </Box>
                                          {guestCount && guestCount > 0 ? `${guestCount} ${guestCount > 1 ? 'guests' : 'guest'} added` : 'Add Guests'}
                                        </Button>
                                      </Box>
                                      {isOpenAddGuestModal && (
                                        <AddGuestsModal
                                          setIsOpenAddGuestModal={setIsOpenAddGuestModal}
                                          wuid={wuid}
                                          agenda_item_id={agenda_item_id}
                                          isOpen={isOpenAddGuestModal}
                                          setIsOpen={setIsOpenAddGuestModal}
                                          setGuestCount={setGuestCount}
                                          whitelist={agendaItem?.guest_whitelist}
                                        />
                                      )}
                                    </Box>
                                  ) : (
                                    <Box>
                                      <Box marginTop={20}>
                                        <Text
                                          fontFamily='Source Sans Pro'
                                          fontWeight={400}
                                          fontSize='14px'
                                          lineHeight='20px'
                                          color='#73737B'
                                        >
                                          Only admins can see this event.
                                        </Text>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </Box>

                            <BorderLine />

                            <Box marginTop='20px' paddingBottom='24px'>
                              <Box display='flex' alignItems='center' marginBottom='20px'>
                                <Box marginRight='8px'>
                                  <DressCodeIcon stroke='#414042' />
                                </Box>
                                <Text
                                  fontFamily='Source Sans Pro'
                                  fontWeight='700'
                                  fontSize='20px'
                                  lineHeight='24px'
                                  color='#353B60'
                                >
                                  Dress Code
                                </Text>
                              </Box>
                              <Box>
                                <Input
                                  inputMaxLength={inputMaxLength}
                                  showMaxLength={true}
                                  style={{
                                    padding: selectedEvent.attireLength < 50 ? '11px 30px 11px 12px' : '11px 12px 11px 12px',
                                  }}
                                  maxLength={selectedEvent.attireLength}
                                  name='attire'
                                  label='Dress Code'
                                  placeholder='Semi-formal'
                                  onInput={(e) => handleCountTextLength(e, 'attire')}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <ButtonsStyled>
                            <SubmitContainerStyled>
                              <CancelButtonStyled
                                onClick={() => handleCloseEditEventModal()}
                              >
                                Cancel
                              </CancelButtonStyled>

                              <SubmitButtonStyled
                                onClick={() => afterSubmit(props)}
                              >
                                Update
                              </SubmitButtonStyled>
                            </SubmitContainerStyled>
                          </ButtonsStyled>
                        </>
                      )
                    }}
                  </Form>
                </EventDetailContentStyled>
              </MainContainer>
            </EditScheduleContainer>
          </EditContainerWrapper>
        ) :
        null
      }
    </>
  )
}

export default EditEventModal