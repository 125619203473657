import React, { FC } from 'react'
import { useLocation } from 'react-router'
import { parse } from 'query-string'
import { path } from 'ramda'

import { Wrapper } from 'components/Auth'
import ForgotPasswordForm from './ForgotPasswordForm'
import ResetPasswordForm from './ResetPasswordForm'

const ResetPassword: FC = () => {
  const { search } = useLocation()
  const resetCode = path<string>(['id'], parse(search))

  return (
    <Wrapper>
      {resetCode ? (
        <ResetPasswordForm resetCode={resetCode} />
      ) : (
        <ForgotPasswordForm />
      )}
    </Wrapper>
  )
}

export default ResetPassword
