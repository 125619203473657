import { Reducer } from 'redux'
import { path, filter, update, findIndex, propEq, assoc, append } from 'ramda'

import {
  SET_IS_GRID,
  WEDDING_LIST,
  REMOVE,
  UPDATE,
  LOAD_WEDDING,
} from './actionTypes'
import { LOGOUT } from 'store/modules/auth/actionTypes'

export const initialState = {
  isGrid: true,
  isLoaded: false,
  weddings: [],
}

type Image = {
  slice: string
  link: string
}

export type Wedding = {
  wuid: string
  wedding_image: Image[]
  wedding_name: string
  theme_font?: string
  theme_color?: string
  theme_color2?: string
  wedding_date: string
  approx_date_month?: number
  approx_date_year?: number
  location_name: string
  location_address: string
  location_city: string
  admins: { uuid: string }[]
  n_guests: number
  wedding_role: string
  is_active: string
  spouse1_first_name?: string
  spouse1_last_name?: string
  spouse1_image: { slice: string; link: string }[]
  spouse1_uuid?: string
  spouse2_first_name?: string
  spouse2_last_name?: string
  spouse2_image: { slice: string; link: string }[]
  spouse2_uuid?: string
  notifications: {
    n_total: number
    n_unread: number
  }
}

export type State = {
  isGrid: boolean
  isLoaded: boolean
  weddings: Wedding[]
}

const weddingReducer: Reducer = (state: State = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState
    case SET_IS_GRID:
      return assoc('isGrid', action.payload, state)
    case WEDDING_LIST.FULFILLED:
      return {
        ...state,
        isLoaded: true,
        weddings: path(['payload', 'payload', 'weddings'], action),
      }
    case REMOVE.FULFILLED:
      return {
        ...state,
        weddings: filter(
          (v) => v.wuid !== path(['payload', 'data', 'wuid'], action),
          state.weddings
        ),
      }
    case UPDATE.FULFILLED:
      return {
        ...state,
        weddings: update(
          findIndex(
            propEq(
              'wuid',
              path(['payload', 'wedding', 'wuid'], action.payload)
            ),
            state.weddings
          ),
          path(['payload', 'wedding'], action.payload),
          state.weddings
        ),
      }
    case LOAD_WEDDING.FULFILLED:
      return assoc(
        'weddings',
        append(path(['payload', 'wedding'], action.payload), state.weddings),
        state
      )
    default:
      return state
  }
}

export default weddingReducer
