import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

import Box from './Box'

const Radio = styled(Box).attrs({
  as: 'input',
  type: 'radio',
  width: 20,
  height: 20,
})<InputHTMLAttributes<HTMLInputElement>>`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colors.darkGray};
    background: ${props => props.theme.colors.white};
  }

  &:checked {
    &:after {
      border: 6px solid ${props => props.theme.colors.primary};
    }
  }
`
export default Radio
