import React, { useCallback, MouseEvent } from 'react'
import { useParams, useHistory } from 'react-router'

import { Box } from 'components/ui'
import { PAGE_TYPES } from 'constants/wedding'
import {
  WEDDING_LAYOUT,
  MESSAGES,
  GUESTS,
  SEATING,
  AGENDA,
  ALBUMS,
  FAQ, FOOD_MENU,
} from 'constants/paths'
import PlannerTab from './PlannerTab'
import Tab from './Tab'

const Tabs = () => {
  const { wuid } = useParams()
  const { push } = useHistory()

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      switch (e.currentTarget.value) {
        case PAGE_TYPES[0]:
          return push(WEDDING_LAYOUT(wuid))
        case PAGE_TYPES[2]:
          return push(MESSAGES(wuid))
        case 'guestList':
          return push(GUESTS(wuid))
        case 'seatingChart':
          return push(SEATING(wuid))
        case 'schedule':
          return push(AGENDA(wuid))
        case 'moments':
          return push(ALBUMS(wuid))
        case 'faq':
          return push(FAQ(wuid))
        case 'menu':
          return push(FOOD_MENU(wuid))
        default:
          return null
      }
    },
    [wuid, push],
  )

  return (
    <Box display='flex' ml='40px'>
      <Tab value='home' label='Home' onClick={onClick} />

      <PlannerTab onClick={onClick} />

      <Tab value={PAGE_TYPES[0]} label='Theme' onClick={onClick} />

      <Tab value={PAGE_TYPES[2]} label='Messaging' onClick={onClick} />
    </Box>
  )
}

export default Tabs
