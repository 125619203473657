import styled from 'styled-components'
import { path } from 'ramda'

import Button from '../Button'

const FormatButton = styled(Button).attrs({
  border: 'none',
  width: '18px',
  height: '18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
})<{ isActive?: boolean }>`
  color: ${props =>
    path(
      ['theme.primary', 'colors', props.isActive ? 'primary' : 'darkGray'],
      props
    )};
`

export default FormatButton
