import React from 'react'
import { Box, Switch } from 'components/ui'
import { AddDishWrapper } from './utils'
import addDishInputIcon from '../../images/addDishInputIcon.svg'

const AddDish = ({
                   id,
                   error,
                   isSelected,
                   title,
                   description,
                   handleCourseChange,
                 }: { id: string, isSelected: boolean; handleCourseChange: any, title: string; description: string; error: any }) => {
  return (
    <AddDishWrapper>
      <Box display='flex' justifyContent='space-between' width='100%' padding='4px 0'>
      </Box>
      <Box className='dishInputsWrapper'>
        <Box width='max-content' className='label-box' marginLeft='16px'>
          <label htmlFor='dish' className='label-text'>Dish {id}</label>
        </Box>
        <Box className='titleInputBlock'>
          <Box className='iconInput'>
            <Box width='max-content'>
              <img src={addDishInputIcon} alt='...Loading' />
            </Box>
            <input
              id={`${id}`}
              name='title'
              placeholder='Title'
              value={title}
              onChange={handleCourseChange}
              style={{ borderColor: error ? 'red' : '#E8E8ED' }}
            />
          </Box>
          {error && <Box style={{ width: '84%', color: 'red' }}> {error}</Box>}
        </Box>
        <Box className='area'>
          <input
            id={`${id}`}
            name='comment'
            type='text-area'
            placeholder='Description'
            value={description}
            onChange={handleCourseChange}
          />
        </Box>
      </Box>
      <Box className='SwitchWrapper'>
        <Box className='wrapper'>
          <Switch
            id={`${id}`}
            checked={isSelected}
            onChange={handleCourseChange}
          />
          <Box>Allow guest selection</Box>
        </Box>
      </Box>
    </AddDishWrapper>
  )
}

export default AddDish