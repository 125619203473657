import React, { FC } from 'react'
import { useHistory } from 'react-router'

import { Text, Box, Button } from 'components/ui'
import imageSrc from 'images/check-success.png'
import { LOGIN } from 'constants/paths'

const ResetPasswordSuccess: FC = () => {
  const { push } = useHistory()

  const handleClickReturn = () => push(LOGIN)

  return (
    <>
      <Box as="img" src={imageSrc} alt="Verification" mb={2} />

      <Text fontSize={5} fontWeight={600}>
        Password Reset Successful
      </Text>

      <Box
        mt={2}
        mb={1}
        maxWidth="400px"
        display="flex"
        justifyContent="center"
      >
        <Text textAlign="center" fontSize="18px">
          You've successfully reset your password.
        </Text>
      </Box>

      <Box display="inline-flex" alignItems="baseline">
        <Text fontSize="18px">Please</Text>

        <Button
          border="none"
          px="0px"
          pb="0px"
          color="primary"
          fontFamily="0"
          fontSize="18px"
          mx={0}
          onClick={handleClickReturn}
        >
          return to the app
        </Button>

        <Text fontSize="18px">to continue.</Text>
      </Box>
    </>
  )
}

export default ResetPasswordSuccess
