import React, { FC, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { map, head, path } from 'ramda'
import { toast } from 'react-toastify'

import { Post } from 'store/modules/posts/reducer'
import { Box, Button, LoadingDots } from 'components/ui'
import { ReactComponent as CommentsIcon } from 'images/comments.svg'
import { loadComments } from 'store/modules/comments/actions'
import { getIsLoaded, getComments } from 'store/modules/comments/selectors'
import Comment from './Comment'

type Props = {
  postId: Post['post_id']
} & Post['comments']

const PostComments: FC<Props> = ({
  postId,
  comment_count = 0,
  recent = [],
}) => {
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isLoaded = useSelector(getIsLoaded(postId))
  const comments = useSelector(getComments(postId))
  const comment = head(recent)

  const onLoadComments = useCallback(
    async () => dispatch(loadComments({ post_id: postId })),
    [dispatch, postId]
  )
  const handleClickViewMoreButton = useCallback(async () => {
    try {
      if (!isLoaded) {
        setIsLoading(true)
        await onLoadComments()
      }
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
      setIsExpanded(true)
    }
  }, [onLoadComments, setIsExpanded, isLoaded, setIsLoading])

  return (
    <Box display="inline-flex">
      <Box minWidth="20px" mr={0}>
        <CommentsIcon stroke="#8991a0" width="20px" height="20px" />
      </Box>

      <Box>
        {isExpanded && isLoaded ? (
          map(
            (comment) => (
              <Comment
                key={comment.comment_id}
                firstName={path(['owner', 'first_name'], comment)}
                lastName={path(['owner', 'last_name'], comment)}
                caption={path(['caption'], comment)}
              />
            ),
            comments
          )
        ) : (
          <Comment
            firstName={path(['first_name'], comment)}
            lastName={path(['last_name'], comment)}
            caption={path(['comment'], comment)}
          />
        )}

        {!isExpanded && comment_count > 1 && !isLoading && (
          <Button
            border="none"
            px="0px"
            py="0px"
            height="26px"
            fontFamily="0"
            color="darkGray"
            disabled={isLoading}
            onClick={handleClickViewMoreButton}
          >
            View {comment_count - 1} more comment
            {comment_count - 1 > 1 ? 's' : ''}
          </Button>
        )}

        {isLoading && <LoadingDots bg="primary" />}
      </Box>
    </Box>
  )
}

export default PostComments
