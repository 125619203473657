import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Button } from 'components/ui'
import { toggleAppSections } from 'store/modules/nav/actions'
import { ReactComponent as Icon } from './shevron.svg'

const MinimizeButton = () => {
  const dispatch = useDispatch()

  const onClick = useCallback(() => dispatch(toggleAppSections()), [dispatch])

  return (
    <Button
      width="20px"
      height="20px"
      p="0px"
      bg="#FFFFFF"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.15)"
      borderRadius="10px"
      position="absolute"
      top="20px"
      right="-10px"
      onClick={onClick}
    >
      <Icon />
    </Button>
  )
}

export default MinimizeButton
