import { parseISO } from 'date-fns'

import { NavItem } from './reducer'

export const transformNavItemToState = (navItem: any = {}): NavItem => ({
  nav_item_id: navItem.nav_item_id || '',
  nav_id: navItem.nav_id || '',
  parent_nav_item_id:
    navItem.parent_nav_item_id !== '0' ? navItem.parent_nav_item_id : undefined,
  wuid: navItem.wuid || '',
  type: navItem.type ? parseInt(navItem.type) : 0,
  caption: navItem.caption || '',
  icon: navItem.icon || '',
  access_level: navItem.access_level ? parseInt(navItem.access_level) : 0,
  link: navItem.link || '',
  content: navItem.content || '',
  is_active: navItem.is_active === '1',
  is_featured: navItem.is_featured === '1',
  is_fixed: navItem.is_fixed === '1',
  ord: navItem.ord ? parseInt(navItem.ord) : 0,
  insert_dt: navItem.insert_dt ? parseISO(navItem.insert_dt) : new Date(),
  update_dt: navItem.update_dt ? parseISO(navItem.update_dt) : new Date(),
  subitems: [],
})
