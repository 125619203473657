import React, { FC } from 'react'
import { OptionTypeBase } from 'react-select'
import { path } from 'ramda'

import { Box, Avatar, Text } from 'components/ui'
import { getFullName } from 'utils/guest'

const Option: FC<OptionTypeBase> = ({ innerProps, data }) => {
  const fullName = getFullName([
    path(['user', 'first_name'], data),
    path(['user', 'last_name'], data),
  ])

  return (
    <Box
      {...innerProps}
      py={0}
      px={1}
      display="flex"
      alignItems="center"
      cursor="pointer"
    >
      <Box mr="12px">
        <Avatar image={path(['user', 'image'], data)} name={fullName} />
      </Box>

      <Box display="flex" flexDirection="column">
        <Text fontWeight={600}>{fullName}</Text>

        <Text color="darkGray">{path(['user', 'contact_email'], data)}</Text>
      </Box>
    </Box>
  )
}

export default Option
