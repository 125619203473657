export const NAV_ITEM_TYPES: { [type: number]: string } = {
  0: 'None',
  1: 'Activity',
  2: 'Notifications',
  3: 'Guest List',
  4: 'Schedule',
  5: 'Moments',
  6: 'Questions',
  7: 'Messages',
  8: 'Seating Chart',
  9: 'Leaderboard',
  12: 'Food Menu',
  100: 'Folder',
  101: 'Website',
  102: 'Information Page',
}
