import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { OptionTypeBase } from 'react-select'
import { mergeLeft } from 'ramda'

import { Box, Text, Button } from 'components/ui'
import { Select } from 'components/form'
import { ReactComponent as UsersIcon } from 'images/users.svg'
import { ReactComponent as Icon } from 'images/ww-step4.svg'
import { N_GUESTS_LABELS_OPTIONS } from 'constants/wedding'

const OptionWrapper = styled(Box).attrs({
  alignItems: 'center',
})<{ isSelected: boolean }>`
  display: flex !important;
  height: 48px !important;
  padding-left: 21px !important;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${props.theme.colors.primary} !important;
      color: #ffffff !important;
    `}
`

type Props = {
  isValid: boolean
  onBack: VoidFunction
  onContinue: VoidFunction
}

const Step4: FC<Props> = ({ isValid, onBack, onContinue }) => {
  const options = N_GUESTS_LABELS_OPTIONS.map((label, value) => ({
    label,
    value,
  }))

  const Option = ({
    innerProps,
    label,
    isSelected,
    getStyles,
    ...props
  }: OptionTypeBase) => {
    return (
      <OptionWrapper
        {...innerProps}
        style={getStyles('option', props)}
        isSelected={isSelected}
      >
        <UsersIcon
          stroke={isSelected ? '#ffffff' : '#7c8494'}
          width={25}
          height={25}
        />

        <Box ml="21px">
          <Text
            fontFamily="1"
            fontSize={1}
            lineHeight={1}
            color={isSelected ? 'white' : 'black'}
          >
            {label}
          </Text>
        </Box>
      </OptionWrapper>
    )
  }

  return (
    <Box
      width="100%"
      maxWidth="740px"
      boxShadow="0px 8px 24px rgba(0, 0, 0, 0.15)"
      bg="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      pt="70px"
      pb="32px"
      px="26px"
    >
      <Box mt="-98px" mb="29px">
        <Icon />
      </Box>

      <Text
        fontSize={['28px', 5]}
        fontWeight="bold"
        color="secondary"
        lineHeight={['normal', '45px']}
        textAlign="center"
      >
        Let’s get this party started!
      </Text>

      <Box mt="8px" />

      <Text
        fontSize="24px"
        fontWeight="bold"
        color="secondary"
        lineHeight={['normal', '30px']}
        textAlign="center"
      >
        How many guests are you inviting?
      </Text>

      <Box mt="16px" maxWidth="600px" width="100%" display="flex">
        <Text
          fontWeight={300}
          fontSize="20px"
          lineHeight="28px"
          color="secondary"
          textAlign="center"
        >
          This might be tough at first, but it’s important to think about your
          budget and the length of the train following you in the conga line.
        </Text>
      </Box>

      <Box
        width="100%"
        maxWidth="400px"
        display="flex"
        flexDirection="column"
        mt={['32px', '40px']}
      >
        <Box width="100%">
          <Text
            fontSize="20px"
            lineHeight="25px"
            fontWeight={600}
            color="secondary"
          >
            Estimated count:
          </Text>

          <Box mt="8px" />

          <Select
            name="nGuests"
            placeholder="Select count"
            components={{ Option }}
            options={options}
            maxMenuHeight={224}
            height="40px"
            styles={{
              option: (style, { isFocused }) =>
                mergeLeft(
                  {
                    backgroundColor: isFocused ? '#f6f7ff' : 'transparent',
                  },
                  style
                ),
            }}
          />
        </Box>

        <Box mt="89px" display="flex" justifyContent="space-between">
          <Button variant="secondary" height="40px" onClick={onBack}>
            Back
          </Button>

          <Button
            variant="primary"
            disabled={!isValid}
            height="40px"
            onClick={onContinue}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Step4
