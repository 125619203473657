import React, { FC } from 'react'

import { Box, Button } from 'components/ui'
import { ReactComponent as RemoveIcon } from 'images/close.svg'

type Props = {
  image: string
  onClickRemove: () => void
}

const PhotoPreview: FC<Props> = ({ image, onClickRemove }) => (
  <Box
    width="38px"
    height="38px"
    borderRadius={0}
    backgroundImage={`url("${image}")`}
    backgroundPosition="center"
    backgroundSize="cover"
    backgroundRepeat="no-repeat"
    mr={0}
    position="relative"
  >
    <Button
      onClick={onClickRemove}
      bg="gray"
      border="none"
      borderRadius="50%"
      height="20px"
      width="20px"
      p="0px"
      position="absolute"
      right="-6px"
      top="-6px"
    >
      <RemoveIcon width="10px" height="10px" />
    </Button>
  </Box>
)

export default PhotoPreview
