import React, { CSSProperties } from 'react'
import { MoveableManagerInterface } from 'react-moveable'

import { Box, Button } from 'components/ui'
import { ReactComponent as EditIcon } from './edit.svg'
import { ReactComponent as SeatsIcon } from './seats.svg'

type Props = {
  edit: boolean
  height: number
  style: CSSProperties
  isSeatsButtonHidden: boolean
  onSetEditModalAsSettings: VoidFunction
  onOpenPanel: VoidFunction
}

const EditChart = {
  name: 'edit',
  props: {
    edit: Boolean,
    height: Number,
    style: Object,
    isSeatsButtonHidden: Boolean,
    onSetEditModalAsSettings: Function,
    onOpenPanel: Function,
  },
  events: {},
  render(moveable: MoveableManagerInterface<Props>) {
    const rect = moveable.getRect()
    const { pos2 } = moveable.state

    const EditableViewer = moveable.useCSS(
      'div',
      `{ position: absolute; left: 0px; top: 0px; will-change: transform; transform-origin: 0px 0px; }`
    )

    return (
      <EditableViewer
        key="editable-viewer"
        className="moveable-editable"
        style={{
          transform: `translate(${pos2[0]}px, ${pos2[1]}px) rotate(${rect.rotation}deg) translate(10px)`,
        }}
      >
        <Box
          position="absolute"
          right="-76px"
          top="0px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: moveable.props.height }}
        >
          <Button
            p="0px"
            bg="#FFFFFF"
            width="36px"
            height="36px"
            border="1px solid #A3A3AC"
            borderRadius="18px"
            style={moveable.props.style}
            onClick={moveable.props.onSetEditModalAsSettings}
          >
            <EditIcon />
          </Button>

          {!moveable.props.isSeatsButtonHidden && (
            <Button
              p="0px"
              bg="#FFFFFF"
              width="36px"
              height="36px"
              border="1px solid #A3A3AC"
              borderRadius="18px"
              mt="12px"
              style={moveable.props.style}
              onClick={moveable.props.onOpenPanel}
            >
              <SeatsIcon />
            </Button>
          )}
        </Box>
      </EditableViewer>
    )
  },
}

export default EditChart
