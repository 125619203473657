import React, { FC } from 'react'
import { slice, map, mergeLeft } from 'ramda'
import { useTheme } from 'styled-components'

import { Select } from 'components/form'
import { ACCESS_TYPE_VAlUES, ACCESS_TYPE_LABELS } from 'constants/albums'

const AccessTypeSelector: FC = () => {
  const theme: any = useTheme()

  const options = map(
    (value) => ({ value, label: ACCESS_TYPE_LABELS[value] }),
    slice(1, 3, ACCESS_TYPE_VAlUES)
  )

  const styles = {
    control: mergeLeft({
      boxShadow: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      '>div:first-of-type': {
        padding: '0px 6px 0px 0px',
        justifyContent: 'flex-end',
      },
    }),
    singleValue: mergeLeft({
      color: theme.colors.darkGray,
      fontFamily: theme.fonts[0],
      fontSize: theme.fontSizes[0],
    }),
    indicatorsContainer: mergeLeft({
      '>div': {
        padding: '0px 8px 0px 0px',
      },
    }),
  }

  return (
    <Select
      isSearchable={false}
      name="accessType"
      options={options}
      styles={styles}
    />
  )
}

export default AccessTypeSelector
