import React, { FC, ReactComponentElement, useCallback, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Button, Box, Text } from 'components/ui'
import { ReactComponent as ChevronIcon } from 'images/chevron-down.svg'
import { ReactComponent as PlusIcon } from 'images/plus-circle.svg'
import Item from 'components/Guests/GroupsSelect/Item'
import { ModalCrudTypes } from 'utils/guest'
import styled from 'styled-components'

const Wrapper = styled.div`
  &:hover ${Box} {
    background-color: #F7F7FC;
    transition: .1s;
    cursor: pointer
  }
`

const AddButtonContainer = styled(Text)`
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: 20px;
  color: #5458F7;
  display: inline-block;
  font-size: 14px;
`

type Props = {
  onClick: (props: { isOpen: boolean, name: string, type: ModalCrudTypes, id: string }) => void,
  label: string,
  dropDownPlaceholder: string,
  name: string,
  isLoaded?: boolean,
  addItem?: boolean,
  options: { label: string; value: string, id: string }[],
  icon?: ReactComponentElement<any>
  addIcon?: ReactComponentElement<any>
  showAddIcon?: boolean
}

const DefaultSelect: FC<Props> = (props) => {
  const {
    onClick,
    label = '',
    dropDownPlaceholder = '',
    name = '',
    isLoaded = false,
    options = [],
    addItem = true,
    icon = null,
    addIcon = null,
    showAddIcon = false,
  } = props
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

  const handleClick = useCallback((type: ModalCrudTypes, id: string) => {
    onClick({
      isOpen: true,
      name,
      type,
      id,
    })
  }, [onClick, name])

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setMenuIsOpen(false)}>
        <Box position='relative'>
          <Button
            border='1px solid'
            borderColor='#CBCBD3'
            borderRadius='8px'
            fontFamily='Source Sans Pro'
            fontSize={1}
            fontWeight='600'
            pl='16px'
            pr='16px'
            height='40px'
            onClick={() => setMenuIsOpen(!menuIsOpen)}
            disabled={isLoaded}
            display='flex'
            alignItems='center'
          >
            <Box color='#353B60'>
              {`${dropDownPlaceholder}`}
            </Box>
            <Box ml={0}>
              {icon ? icon : <ChevronIcon />}
            </Box>
          </Button>

          {menuIsOpen && (
            <Box
              position='absolute'
              display='flex'
              flexDirection='column'
              bg='white'
              border='1px solid'
              borderColor='#E8E8ED'
              borderRadius='10px'
              boxShadow={0}
              mt={0}
              zIndex={3}
              width={240}
            >
              {
                options.length > 0 ? options.map(option => {
                  return (
                    <Box
                      width='240px'
                      maxHeight='calc(100vh - 350px)'
                      overflowY='auto'
                      borderBottomColor='#E8E8ED'
                      key={option.id}
                    >
                      <Wrapper>
                        <Item
                          {...option}
                          onClickEditButton={() => handleClick('edit', option.id)}
                        />
                      </Wrapper>
                    </Box>
                  )
                }) : <Box p='5px 20px' color='#5b5b68'>No options</Box>}

              {
                addItem && (
                  <Box
                    px={1}
                    py='10px'
                    display='flex'
                    alignItems='center'
                    cursor='pointer'
                    color='#5458F7'
                    borderTop='1px solid #E8E8ED'
                    onClick={() => handleClick('create', '')}
                  >
                    {showAddIcon ? addIcon ? addIcon : <PlusIcon height='14px' width='14px' /> : null}
                    <AddButtonContainer isTruncate>
                      {label}
                    </AddButtonContainer>
                  </Box>
                )
              }
            </Box>
          )}
        </Box>
      </OutsideClickHandler>
    </>
  )
}

export default DefaultSelect
