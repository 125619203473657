import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { mergeLeft } from 'ramda'
import styled from 'styled-components'

const TabBoxStyled = styled.div`
  padding: 2px 5px;
  cursor: pointer;
  margin-bottom: -3px;
  color: #73737B;
`

const TabTextStyled = styled.span`
  font-size: 16px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 600;
`

const TabsContainerStyled = styled.div`
  display: flex;
  justify-content: start;
  gap: 12px;
  border-bottom: 1px solid #E8E8ED;
`

const activeTabStyles = {
  borderBottom: '3px solid #5458F7',
  paddingBottom: '9px',
  borderRadius: '1px',
  color: '#5458F7',
  zIndex: 5,
}

type Props = {
  handleChange: Function;
  options: { name: string; title: string }[];
  styles?: {
    container?: CSSProperties;
    tabBox?: CSSProperties;
    tabText?: CSSProperties;
  }
}

const Tabs: FC<Props> = ({ handleChange, options, styles = {} }) => {
  const [activeTab, setActiveTab] = useState(options[0].name)

  const handleClick = useCallback((tab) => {
    setActiveTab(tab)
  }, [])

  useEffect(() => {
    handleChange(activeTab)
  }, [activeTab, handleChange])

  return (
    <TabsContainerStyled style={styles.container || {}}>
      {
        options.map(option => {
          return (
            <TabBoxStyled
              key={option.title}
              style={mergeLeft(activeTab === option.name ? activeTabStyles : {}, styles.tabBox || {})}
              onClick={() => handleClick(option.name)}
            >
              <TabTextStyled style={styles.tabText || {}}>
                {option.title}
              </TabTextStyled>
            </TabBoxStyled>
          )
        })
      }
    </TabsContainerStyled>
  )
}

export default Tabs