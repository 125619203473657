import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
// prettier-ignore
import {isValid, isPast, parseISO, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns'

import { Box, Text } from 'components/ui'

const Wrapper = styled(Box).attrs({
  position: 'absolute',
  zIndex: 2,
  bg: '#FFFFFF',
  boxShadow: '0px 2.60754px 4.56319px rgba(215, 215, 215, 0.25)',
  borderRadius: '32px',
  height: '40px',
  width: '200px',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  px: '22px',
  pt: '5px',
  mt: '-19px',
})`
  margin-left: 8px;

  @media screen and (min-height: 840px) {
    margin-left: 35px;
  }
`

type Props = {
  date: string
  color?: string
}

const Countdown = ({ date, color = '#18CBC1' }: Props) => {
  const parsed = parseISO(date)

  if (!isValid(parsed)) return null

  if (isPast(parsed)) {
    return (
      <Wrapper>
        {['days', 'hours', 'mins', 'secs'].map((label) => (
          <Box
            key={label}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Text
              fontWeight={600}
              fontSize="16px"
              lineHeight="20px"
              color={color}
            >
              0
            </Text>

            <Text isUppercase fontSize="7px" lineHeight="9px" color="#97A5BA">
              {label}
            </Text>
          </Box>
        ))}
      </Wrapper>
    )
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    setTimeout(() => {
      setNow(new Date())
    }, 1000)
  })

  const values: { [k: string]: number } = useMemo(() => {
    const days = differenceInDays(parsed, now)
    const hours = differenceInHours(parsed, now) - days * 24
    const mins = differenceInMinutes(parsed, now) - days * 24 * 60 - hours * 60
    const secs =
      differenceInSeconds(parsed, now) -
      days * 24 * 60 * 60 -
      hours * 60 * 60 -
      mins * 60

    return { days, hours, mins, secs }
  }, [parsed, now])
  /* eslint-enable */

  return (
    <Wrapper>
      {Object.keys(values).map((value) => (
        <Box
          key={value}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Text
            fontWeight={600}
            fontSize="16px"
            lineHeight="20px"
            color={color}
          >
            {values[value]}
          </Text>

          <Text isUppercase fontSize="7px" lineHeight="9px" color="#97A5BA">
            {value}
          </Text>
        </Box>
      ))}
    </Wrapper>
  )
}

export default Countdown
