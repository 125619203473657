import React, { FC, useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { path, map, mergeLeft } from 'ramda'
import { toast } from 'react-toastify'

import { loadFonts, setFontIsLoaded } from 'store/modules/fonts/actions'
import { getIsLoaded, getFonts } from 'store/modules/fonts/selectors'
import { injectFont } from 'utils/fonts'
import Select from '../Select'
import Option from './Option'
import Modal from './Modal'

type Props = {
  label?: string
  placeholder?: string
  value?: any
  variant?: 'modal' | 'selector'
  weddingName?: string
  height?: string | number
  onChange: (x: any) => void
}

const usePrevious = (value: any): any => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const FontSelector: FC<Props> = ({
  variant = 'selector',
  weddingName = 'Thede & Cary',
  height = 38,
  onChange,
  ...props
}) => {
  const dispatch = useDispatch()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const isLoaded = useSelector(getIsLoaded)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const loadedPage = usePrevious(page)
  const fonts = useSelector(getFonts(page * 8))
  const options = map(
    (font) => ({ label: font.family, value: font.files?.regular }),
    fonts
  )

  const onLoadFonts = useCallback(async () => dispatch(loadFonts()), [dispatch])
  const onSetFontIsLoaded = useCallback(
    (fontFamily: string) => dispatch(setFontIsLoaded(fontFamily)),
    [dispatch]
  )

  const handleLoadMore = useCallback(() => setPage(page + 1), [page])
  const handleInjectFonts = useCallback(
    () =>
      fonts.forEach((font) => {
        if (!font.isLoaded) {
          onSetFontIsLoaded(font.family)
          injectFont(font.family, font.files.regular)
        }
      }),
    [fonts, onSetFontIsLoaded]
  )

  const handleClick = useCallback(() => setModalIsOpen(true), [])
  const handleCloseMenu = useCallback(() => setModalIsOpen(false), [])

  // Load fonts initially
  useEffect(() => {
    const handleLoadFonts = async () => {
      setIsLoading(true)

      try {
        await onLoadFonts()
      } catch (error) {
        toast(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    if (!isLoaded && !isLoading) {
      handleLoadFonts()
    }
  }, [isLoaded, isLoading, onLoadFonts])

  // Add scripts
  useEffect(() => {
    if ((page === 1 && fonts.length > 0) || loadedPage < page) {
      handleInjectFonts()
    }
  }, [fonts, page, loadedPage, handleInjectFonts])

  return (
    <>
      <Modal
        isOpen={variant === 'modal' && modalIsOpen}
        options={options}
        weddingName={weddingName}
        onClose={handleCloseMenu}
        onChange={onChange}
        onScrollToBottom={handleLoadMore}
      />

      <Select
        options={options}
        components={{ Option }}
        label={props.label}
        placeholder={props.placeholder}
        isLoading={isLoading}
        styles={{
          singleValue: (styles) => {
            const fontFamily = path(['value', 'label'], props)

            return {
              ...styles,
              fontFamily: fontFamily ? `${fontFamily} !important` : undefined,
            }
          },
          indicatorsContainer:
            variant === 'modal'
              ? mergeLeft({ transform: 'rotate(-90deg)' })
              : undefined,
          control: (props, { isFocused }) => ({
            ...props,
            fontFamily: 'Work Sans',
            borderWidth: 2,
            borderColor: isFocused ? '#3f40f0' : '#e3e7ed',
            fontSize: 16,
            borderRadius: 6,
            width: '100%',
            height,
            boxShadow: 'none',
            '&:hover': {
              borderColor: '#3f40f0',
            },
          }),
        }}
        onMenuScrollToBottom={handleLoadMore}
        onChange={onChange}
        onClick={handleClick}
        menuIsOpen={variant === 'modal' ? false : undefined}
        disableMobile
        {...props}
      />
    </>
  )
}

export default FontSelector
