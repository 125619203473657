import React, { useMemo } from 'react'

import { Box, Avatar } from 'components/ui'
import { Wedding } from 'store/modules/wedding/reducer'
import { getFullName, getImage } from 'utils/guest'

const Avatars = ({
  spouse1_first_name,
  spouse1_last_name,
  spouse1_image,
  spouse2_first_name,
  spouse2_last_name,
  spouse2_image,
}: Partial<Wedding>) => {
  const spouse1Name = useMemo(
    () => getFullName([spouse1_first_name, spouse1_last_name]),
    [spouse1_first_name, spouse1_last_name]
  )
  const spouse1Image = useMemo(() => getImage(spouse1_image), [spouse1_image])

  const spouse2Name = useMemo(
    () => getFullName([spouse2_first_name, spouse2_last_name]),
    [spouse2_first_name, spouse2_last_name]
  )
  const spouse2Image = useMemo(() => getImage(spouse2_image), [spouse2_image])

  return (
    <Box
      position="absolute"
      display="flex"
      justifyContent="center"
      left="0px"
      right="0px"
      mt="-48px"
    >
      <Avatar
        name={spouse1Name}
        image={spouse1Image}
        size={64}
        bg="#CBCBD3"
        border="1px solid #fff"
        fontSize={32}
        color="white"
      />

      <Avatar
        name={spouse2Name}
        image={spouse2Image}
        size={64}
        bg="#CBCBD3"
        border="1px solid #fff"
        fontSize={32}
        color="white"
      />
    </Box>
  )
}

export default Avatars
