import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import promiseMiddleware from 'redux-promise-middleware'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as reducers from './modules/reducers'
import { apiMiddleware, locationMiddleware } from './middlewares'

export const history = createBrowserHistory()

const persistConfig = {
  key: 'hitchhero',
  storage,
  whitelist: ['auth'],
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ...reducers,
    router: connectRouter(history),
  })
)

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(
    applyMiddleware(
      apiMiddleware,
      promiseMiddleware,
      routerMiddleware(history),
      locationMiddleware
    )
  )
)

export const persistor = persistStore(store)

export default store
