import React, { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, Redirect } from 'react-router'
import { useDispatch } from 'react-redux'
import { parse } from 'query-string'
import { path } from 'ramda'
import { toast } from 'react-toastify'

import { Loading } from 'components/ui'
import { ROOT_PATH } from 'constants/paths'
import { loginOauth, info } from 'store/modules/auth/actions'

const OauthSuccess: FC = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { c: ccode, i: identifier } = parse(search)
  const [isLoading, setIsLoading] = useState(true)

  const onLogin = useCallback(
    async () => dispatch(loginOauth({ ccode, identifier })),
    [dispatch, ccode, identifier]
  )
  const onInfo = useCallback(async (uuid) => dispatch(info(uuid)), [dispatch])

  useEffect(() => {
    const handleLogin = async () => {
      try {
        const res = await onLogin()

        await onInfo(path(['value', 'payload', 'uuid'], res))
      } catch (error) {
        toast(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    handleLogin()
  }, [onLogin, onInfo])

  if (isLoading) return <Loading />

  return <Redirect to={ROOT_PATH} />
}

export default OauthSuccess
