import { Reducer } from 'redux'
import {
  path,
  pathOr,
  assoc,
  merge,
  uniq,
  append,
  concat,
  map,
  uniqBy,
  propOr,
  reject,
  propEq,
  findIndex,
  adjust,
  prop,
} from 'ramda'

import {
  LOAD,
  SET_FAQ_ITEMS,
  ADD_QUESTION,
  UPDATE_QUESTION,
  REMOVE_QUESTION,
  FAQ_LIKES,
} from './actionTypes'

const initialState = {
  faqItems: [],
  loadedWuids: [],
}

export type FaqItem = {
  faq_item_id: string
  wuid: string
  ord: string
  ans_user?: {
    uuid: string
  }
  ask_user?: {
    uuid: string
  }
  question: string
  answer: string
  is_asked: string
  is_anon: string
  like_count: string
  is_like: string
}

export type State = {
  faqItems: FaqItem[]
  loadedWuids: string[]
}

const faqReducer: Reducer = (state: State = initialState, action) => {
  switch (action.type) {
    case SET_FAQ_ITEMS:
    case LOAD.FULFILLED:
      return {
        ...state,
        loadedWuids: append(
          path(['data', 'wuid'], action.payload),
          state.loadedWuids,
        ),
        faqItems: uniqBy(
          prop('faq_item_id'),
          concat(
            state.faqItems,
            map(
              assoc('wuid', path(['data', 'wuid'], action.payload)),
              pathOr(
                [],
                ['payload', 'faq_items'],
                action.payload,
              ),
            ),
          ),
        ),
      }
    case ADD_QUESTION.FULFILLED:
    case UPDATE_QUESTION.FULFILLED:
      return merge(
        assoc(
          'faqItems',
          uniqBy(
            propOr(null, 'faq_item_id'),
            concat(
              map(
                assoc('wuid', path(['data', 'wuid'], action.payload)),
                pathOr([], ['payload', 'faq_items'], action.payload),
              ),
              state.faqItems,
            ),
          ),
          state,
        ),
        {
          loadedWuids: uniq(
            append(path(['data', 'wuid'], action.payload), state.loadedWuids),
          ),
        },
      )
    case REMOVE_QUESTION.FULFILLED:
      return assoc(
        'faqItems',
        reject(
          propEq('faq_item_id', path(['data', 'faq_item_id'], action.payload)),
          state.faqItems,
        ),
        state,
      )
    case FAQ_LIKES.FULFILLED: {
      const faqIndex = findIndex(
        propEq('faq_item_id', path(['data', 'faq_item_id'], action.payload)),
        state.faqItems,
      )

      const isLike = path(['data', 'score'], action.payload)

      return assoc(
        'faqItems',
        adjust(
          faqIndex,
          (faq) => ({
            ...faq,
            like_count: `${Number(faq.like_count) + (isLike ? 1 : -1)}`,
            is_like: isLike ? '1' : '0',
          }),
          state.faqItems,
        ),
        state,
      )
    }
    default:
      return state
  }
}

export default faqReducer
