import styled from 'styled-components'
import { Guest } from 'store/modules/guests/reducer'
import React from 'react'


// Icons
import { ReactComponent as AttendingIcon } from 'images/attendingIcon.svg'
import { ReactComponent as NotSureIcon } from 'images/notSureIcon.svg'
import { ReactComponent as NoReplyIcon } from 'images/noReplyIcon.svg'
import { ReactComponent as DeclinedIcon } from 'images/declinedIcon.svg'

// Styled Components
export const EditContainerWrapper = styled.div`
  width: calc(100vw - 32px);
  margin: 0 auto 0 auto;
  border-radius: 10px 12px 10px 10px;
  position: absolute;
  background-color: rgba(115, 115, 123, 0.6);
  z-index: 3;

  //height: 100vh;
`
export const EditContainer = styled.div`
  background-color: #F7F7FC;
  position: relative;
  border-radius: 10px 10px 10px 0;
  max-width: 452px;
  margin-left: auto;
  height: calc(100vh - 120px);
  transition: 0.4s;
  animation-name: opening;
  animation-duration: .3s;
  animation-iteration-count: 1;
  @keyframes opening {
    0% {
      width: 0
    }
    100% {
      width: 452px
    }
  }
`
export const MainContainer = styled.div`
  max-height: 100%;
  height: 100%;
  padding: 16px;
`
export const CircleProgressBarStyled = styled.div`
  width: 174px;
  height: 174px;
  border-radius: 50%;
  mask: radial-gradient(transparent, transparent 35%, #000 36%, #000 0) !important;
`
export const SpeedBox = styled.div`
  height: 85px;
  position: relative;
  width: 170px;
`
export const SpeedBoxScore = styled.div`
  background-position: center center, center center;
  background-repeat: no-repeat;
  background-size: 100% 100%, 100% 100%;
  border-radius: 50%;
  border-style: none;
  height: 170px;
  position: relative;
  transform: rotate(-90deg);
  width: 100%;
`
export const SpeedBoxGroove = styled.div`
  ::after {
    background-image: radial-gradient(transparent 0, transparent 50%, #fff 50%, #fff 100%);
    clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0% 100%);
    content: '';
    height: 26px;
    position: absolute;
    width: 26px;
    bottom: 0;
    right: -2px;
  }

  ::before {
    left: -2px;
    bottom: 0;
    background-image: radial-gradient(transparent 0, transparent 50%, #fff 50%, #fff 100%);
    clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0% 100%);
    content: '';
    height: 26px;
    position: absolute;
    width: 26px;
    bottom: 0;
    right: -2px;
`
export const SpeedBoxDown = styled.div`
  font-family: 'Source Sans Pro';
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #414042;
  top: 55px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
`
export const ProgressBar = styled.div`
  @keyframes progressBar {
    from {
      width: 0;
    }
  }
`
export const CircleBar = styled.div`
  @keyframes circleBar {
    0% {
      transform: rotate(360deg);
      opacity: 0;

      50% {
        transform: rotate(180deg);
        opacity: 0.5;
      }

      100% {
        transform: rotate(0deg);
        opacity: 1;
      }
    }
`
export const SpeedometerBar = styled.div`
  @keyframes speedometerBar {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
export const DrawerContent = styled.div`
  max-height: calc(100vh - 120px - 32px - 18px - 24px - 66px - 24px);
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`
export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
`
export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
`
export const CloseText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5458F7;
  margin-left: 4px;
`

// Types
export type Props = {
  isOpen: boolean,
  onClose: () => void,
  tableData: any,
}

// Component data
export const ageData = (tableData: Guest[]) => ([
  {
    rangeName: 'Adults',
    color: '#353B60',
    ageRangeCount: tableData.filter((guest: Guest) => Number(guest.age_range) === 3).length,
    ageRangePercentage: tableData.filter((guest: Guest) => Number(guest.age_range) === 3).length * 100 / tableData.length,
  },
  {
    rangeName: 'Minors',
    color: '#AAB2F2',
    ageRangeCount: tableData.filter((guest: Guest) => Number(guest.age_range) === 2).length,
    ageRangePercentage: tableData.filter((guest: Guest) => Number(guest.age_range) === 2).length * 100 / tableData.length,
  },
  {
    rangeName: 'Infants',
    color: '#A3A3AC',
    ageRangeCount: tableData.filter((guest: Guest) => Number(guest.age_range) === 1).length,
    ageRangePercentage: tableData.filter((guest: Guest) => Number(guest.age_range) === 1).length * 100 / tableData.length,
  },
  {
    rangeName: 'Unknown',
    color: '#CBCBD3',
    ageRangeCount: tableData.filter((guest: Guest) => !Number(guest.age_range)).length,
    ageRangePercentage: tableData.filter((guest: Guest) => !Number(guest.age_range)).length * 100 / tableData.length,
  },
])

export const hotelNeedsData = (tableData: Guest[]) => {
  return [
    {
      isNeed: 'Needs Hotel',
      isNeedCount: tableData.filter((guest: Guest) => Number(guest.is_need_hotel) === 1).length,
      color: '#63C7C9',
      hotelNeedsPercentage: tableData.filter((guest: Guest) => Number(guest.is_need_hotel) === 1).length * 100 / tableData.length,
    },
    {
      isNeed: 'Not Required',
      isNeedCount: tableData.filter((guest: Guest) => Number(guest.is_need_hotel) === 0).length,
      color: '#E8E8ED',
      hotelNeedsPercentage: tableData.filter((guest: Guest) => Number(guest.is_need_hotel) === 0).length * 100 / tableData.length,
    },
  ]
}

export const rsvpStatusData = (tableData: Guest[]) => {
  return [
    {
      status: 'Attending',
      background: '#ECF8F8',
      color: '#63C7C9',
      icon: <AttendingIcon />,
      guestCount: tableData.filter((guest: Guest) => Number(guest.rsvp_status) === 0).length,
    },
    {
      status: 'Not Sure Yet',
      background: '#FFF9E8',
      color: '#FDCA40',
      icon: <NotSureIcon />,
      guestCount: tableData.filter((guest: Guest) => Number(guest.rsvp_status) === 1).length,
    },
    {
      status: 'No Reply',
      background: '#E8E8ED',
      color: '#73737B',
      icon: <NoReplyIcon />,
      guestCount: tableData.filter((guest: Guest) => Number(guest.rsvp_status) === 3).length,
    },
    {
      status: 'Declined',
      background: '#FEE8E8',
      color: '#F44343',
      icon: <DeclinedIcon />,
      guestCount: tableData.filter((guest: Guest) => Number(guest.rsvp_status) === 2).length,
    },
  ]
}