import React from 'react'

// Components
import { Box } from 'components/ui'

// Utils
import { BorderSetting } from '../utils'
import Questions from '../Questions'
import Settings from '../Settings'

const AllQuestions = () => {

  return (
    <Box width='100%' display='flex' justifyContent='space-between'>
      <Questions />
      <BorderSetting />
      <Settings />
    </Box>
  )
}

export default AllQuestions