import React, { FC, useRef, useState, KeyboardEvent } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Field, FieldRenderProps } from 'react-final-form'
import styled from 'styled-components'
import { insert, join } from 'ramda'

import { Box, Button } from 'components/ui'
import { ReactComponent as SmileIcon } from 'images/smile.svg'
import EmojiPicker from './EmojiPicker'

const Component = styled(Box).attrs({
  as: 'input',
  height: '36px',
  border: '1px solid',
  borderColor: 'gray',
  borderRadius: '100px',
  bg: 'lightGray',
  px: '14px',
  width: '100%',
})`
  font-family: ${(props) => props.theme.fonts[0]};
  font-size: ${(props) => props.theme.fontSizes[0]}px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }
`

type Props = {
  name: string
  onPressKey: (e: KeyboardEvent<HTMLInputElement>) => void
}

const Input: FC<Props> = ({ name, onPressKey }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const inputRef = useRef<any>(null)

  const handleClickButton = () => {
    if (!menuIsOpen) inputRef.current.focus()

    setMenuIsOpen(!menuIsOpen)
  }
  const handleClickOutside = () => setMenuIsOpen(false)

  const renderInput = ({ input }: FieldRenderProps<any[]>) => {
    const handleSelectEmoji = (emoji: any) => {
      input.onChange(
        join('', insert(inputRef.current.selectionStart, emoji, input.value))
      )

      inputRef.current.focus()
    }

    return (
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          px={2}
          pb="12px"
          pt="6px"
        >
          {menuIsOpen && <EmojiPicker onSelectEmoji={handleSelectEmoji} />}

          <Box width="498px" mr={1}>
            <Component
              {...input}
              ref={inputRef}
              placeholder="Write message"
              onKeyPress={onPressKey}
            />
          </Box>

          <Button border="none" px="0px" onClick={handleClickButton}>
            <SmileIcon stroke="#5458f7" />
          </Button>
        </Box>
      </OutsideClickHandler>
    )
  }

  return <Field name={name} render={renderInput} />
}

export default Input
