import React, { FC, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Button } from 'components/ui'
import { like } from 'store/modules/posts/actions'
import { ReactComponent as HeartIcon } from 'images/heart.svg'

type Props = {
  postId: string
  isLiked: boolean
}

const LikeButton: FC<Props> = ({ postId, isLiked }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const onLike = useCallback(
    async () =>
      dispatch(
        like({
          post_id: postId,
          score: isLiked ? 0 : 1,
        })
      ),
    [dispatch, postId, isLiked]
  )

  const handleClickButton = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLike()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onLike])

  return (
    <Button px="0px" onClick={handleClickButton} disabled={isLoading}>
      {isLiked ? (
        <HeartIcon fill="#f44343" stroke="#f44343" />
      ) : (
        <HeartIcon stroke="#8991a0" />
      )}
    </Button>
  )
}

export default LikeButton
