import React, { FC } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import GlobalStyle from './GlobalStyle'

type Props = {
  transition?: any
  pageKey: any
}

const Transitions: FC<Props> = ({ transition, pageKey, children }) => {
  const childFactoryCreator = (props: any) => (child: any) =>
    React.cloneElement(child, props)

  return (
    <>
      <GlobalStyle />

      <TransitionGroup
        childFactory={childFactoryCreator({
          classNames: transition,
          timeout: 600,
        })}
      >
        <CSSTransition key={pageKey} timeout={600}>
          {children}
        </CSSTransition>
      </TransitionGroup>
    </>
  )
}

export default Transitions
