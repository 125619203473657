import React, { createElement, useState, useCallback, MouseEvent, useEffect } from 'react' // prettier-ignore

import { Modal, Box, Text, Button } from 'components/ui'
import { ReactComponent as CloseIcon } from 'images/close.svg'
import { ReactComponent as CalendarIcon } from './navigation-calendar-45.svg'
import icons from '../NavIcon/icons'

type Props = {
  defaultIcon: string
  isOpen: boolean
  onClose: VoidFunction
  onSaveIcon: (icon: string) => void
}

const IconsModal = ({ defaultIcon, isOpen, onClose, onSaveIcon }: Props) => {
  const [activeIcon, setActiveIcon] = useState<typeof defaultIcon>()

  const onSelectIcon = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => setActiveIcon(e.currentTarget.name),
    []
  )

  const onSave = useCallback(() => {
    if (activeIcon) {
      onSaveIcon(activeIcon)
    }

    onClose()
  }, [activeIcon, onSaveIcon, onClose])

  useEffect(() => {
    if (defaultIcon) {
      setActiveIcon(defaultIcon)
    }
  }, [defaultIcon])

  return (
    <Modal
      style={{ content: { maxWidth: 584 } }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Box
        height="60px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="24px"
        bg="white"
        borderTopLeftRadius="10px"
        borderTopRightRadius="10px"
        borderBottom="1px solid #E1E3E6"
      >
        <Text fontWeight="bold" fontSize="20px" color="#353B60">
          Select an Icon
        </Text>

        <Button px="0px" onClick={onClose}>
          <CloseIcon width={24} height={24} fill="#353B60" />
        </Button>
      </Box>

      <Box
        bg="#F6F7F9"
        borderBottomLeftRadius="10px"
        borderBottomRightRadius="10px"
      >
        <Box
          height="300px"
          overflowY="auto"
          display="flex"
          flexWrap="wrap"
          px="12px"
        >
          {icons.map(({ name, icon }) => (
            <Button
              as="button"
              key={name}
              name={name}
              width="80px"
              height="80px"
              border="2px solid"
              borderColor={name === activeIcon ? '#5458F7' : '#E0E4EA'}
              borderRadius="6px"
              bg="white"
              mt="20px"
              mx="12px"
              justifyContent="center"
              alignItems="center"
              onClick={onSelectIcon}
            >
              {name === 'navigation-calendar' ? (
                <CalendarIcon />
              ) : (
                createElement(icon, { width: 45, height: 45, strokeWidth: 1.5 })
              )}
            </Button>
          ))}
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          pt="30px"
          pb="20px"
          px="24px"
        >
          <Button
            color="#5458F7"
            fontSize="16px"
            fontFamily="0"
            fontWeight={600}
            bg="transparent"
            mr="24px"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            bg="#5458F7"
            height="40px"
            px="20px"
            color="#FFFFFF"
            fontSize="16px"
            fontWeight={600}
            fontFamily="0"
            borderRadius="6px"
            disabled={!activeIcon}
            onClick={onSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default IconsModal
