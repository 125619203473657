import React, { FC, useMemo } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router'
import { useSelector } from 'react-redux'

import {
  JOIN_TO_WEDDING,
  WEDDING_LIST,
  WEDDING,
  WEDDING_LAYOUT,
  AGENDA,
  AGENDA_CREATE,
  AGENDA_EDIT,
  GUESTS,
  GUESTS_CREATE,
  GUESTS_EDIT,
  ALBUMS,
  ALBUM_PHOTOS,
  FAQ,
  MESSAGES,
  SEATING,
  FOOD_MENU,
} from 'constants/paths'
import JoinToWedding from 'routes/JoinToWedding'
import WeddingList from 'routes/WeddingList'
import WeddingLayout from 'routes/Layout'
import Home from 'routes/Home'
import Agenda from 'routes/Agenda'
import AgendaDetails from 'routes/AgendaDetails'
import Guests from 'routes/Guests'
import GuestDetails from 'routes/GuestDetails'
import Albums from 'routes/Albums'
import AlbumPhotos from 'routes/AlbumPhotos'
import Faq from 'routes/Faq'
import Messages from 'routes/Messages'
import Seating from 'routes/Seating'
import FoodMenu from 'routes/FoodMenu'
import { Wrapper } from 'components/Wedding'
import { getWuid } from 'store/modules/auth/selectors'

const LoggedIn: FC = () => {
  const wuid = useSelector(getWuid)
  const location = useLocation()
  const to = useMemo(() => (wuid ? location.pathname : WEDDING_LIST), [location.pathname, wuid])

  return (
    <>
      <Route path={JOIN_TO_WEDDING} component={JoinToWedding} />
      <Route path={WEDDING_LIST} component={WeddingList} />

      <Route path={WEDDING()}>
        <Switch>
          <Wrapper>
            <Route path={WEDDING()} exact component={Home} />
            <Route path={WEDDING_LAYOUT()} exact component={WeddingLayout} />
            <Route path={AGENDA()} exact component={Agenda} />
            <Route path={AGENDA_CREATE()} exact component={AgendaDetails} />
            <Route path={AGENDA_EDIT()} exact component={AgendaDetails} />
            <Route path={GUESTS()} exact component={Guests} />
            <Route path={GUESTS_CREATE()} exact component={GuestDetails} />
            <Route path={GUESTS_EDIT()} exact component={GuestDetails} />
            <Route path={ALBUMS()} exact component={Albums} />
            <Route path={ALBUM_PHOTOS()} exact component={AlbumPhotos} />
            <Route path={FAQ()} exact component={Faq} />
            <Route path={MESSAGES()} exact component={Messages} />
            <Route path={SEATING()} exact component={Seating} />
            <Route path={FOOD_MENU()} exact component={FoodMenu} />
          </Wrapper>
        </Switch>
      </Route>

      <Redirect to={to} />
    </>
  )
}

export default LoggedIn
