import { Reducer } from 'redux'
import {
  path,
  pathOr,
  assoc,
  append,
  prepend,
  reject,
  propEq,
  update,
  findIndex,
  concat,
  map,
  merge,
  inc,
  dec,
  uniqBy,
  prop,
} from 'ramda'
import { parseISO } from 'date-fns'

import {
  LOAD_ALBUMS,
  LOAD_ALBUM,
  REMOVE_ALBUM,
  UPDATE_ALBUM,
  LOAD_PHOTOS,
  LOAD_ALBUM_PHOTOS,
  PUSH_UPLOADED_PHOTO,
  REMOVE_PHOTO,
  PHOTO_SET_FAVORITE,
  INFO,
} from './actionTypes'

export const initialState = {
  loadedWuids: {},
  albums: [],
  photos: [],
  loadedAlbumIds: [],
  albumPhotos: [],
}

type CoverImage = {
  id: string
  url: string
}

export type Album = {
  wuid: string
  album_id: string
  album_type: string
  system_type: string
  number_of_photos: string
  album_owner_image: string
  cover_image?: CoverImage
  album_title: string
  shares: any[]
}

export type Photo = {
  photo_id: string
  wedding_wuid: string
  image_big_icon: string
  image_original: string
  image_fw_480: string
  update_date: Date
  insert_date: Date
  media_type: '0' | '1'
  is_favorite: '0' | '1'
  original: string
}

type Pagination = {
  start: number
  length: number
  total: string
}

export type State = {
  loadedWuids: {
    [wuid: string]: Pagination
  }
  albums: Album[]
  photos: Photo[]
  loadedAlbumIds: string[]
  albumPhotos: any[]
}

const albumsReducer: Reducer = (state: State = initialState, action) => {
  switch (action.type) {
    case LOAD_ALBUMS.FULFILLED:
      return {
        ...state,
        albums: uniqBy(
          prop('album_id'),
          concat(
            state.albums,
            map(
              assoc('wuid', path(['data', 'wuid'], action.payload)),
              path(['payload', 'albums'], action.payload) as Album[],
            ),
          ),
        ),
      }
    case LOAD_ALBUM.FULFILLED: {
      const album = assoc(
        'wuid',
        pathOr('', ['data', 'wuid'], action.payload),
        path(['payload', 'album'], action.payload),
      )
      const albumIndex = findIndex(
        propEq('album_id', path(['album_id'], album)),
        state.albums,
      )

      return assoc(
        'albums',
        albumIndex > -1
          ? update(albumIndex, album, state.albums)
          : append(album, state.albums),
        state,
      )
    }
    case REMOVE_ALBUM.FULFILLED:
      return assoc(
        'albums',
        reject(
          propEq('album_id', path(['data', 'album_id'], action.payload)),
          state.albums,
        ),
        state,
      )
    case UPDATE_ALBUM.FULFILLED: {
      const album = assoc(
        'wuid',
        pathOr('', ['data', 'wuid'], action.payload),
        path(['payload', 'album'], action.payload),
      )

      return assoc(
        'albums',
        update(
          findIndex(
            propEq('album_id', path(['album_id'], album)),
            state.albums,
          ),
          album,
          state.albums,
        ),
        state,
      )
    }
    case LOAD_PHOTOS.FULFILLED:
      return {
        ...state,
        photos: uniqBy(prop('photo_id'), [
          ...state.photos,
          ...action.payload.payload.photos
            .map((photo: { [key: string]: string }) => ({
              ...photo,
              insert_date: parseISO(photo.insert_date),
              update_date: parseISO(photo.update_date),
            })),
        ]),
        loadedWuids: {
          ...state.loadedWuids,
          [action.payload.data.wuid]: action.payload.payload.pagination,
        },
      }
    case LOAD_ALBUM_PHOTOS.FULFILLED:
      return {
        ...state,
        albumPhotos: [
          ...action.payload.payload.album_photos
            .map((photo: { [key: string]: string }) => ({
              ...photo,
              insert_date: parseISO(photo.insert_date),
              update_date: parseISO(photo.update_date),
            })),
        ],
        loadedWuids: {
          ...state.loadedWuids,
          [action.payload.data.wuid]: action.payload.payload.pagination,
        },
      }
    case PUSH_UPLOADED_PHOTO:
      return {
        ...state,
        photos: prepend(path(['photo'], action.payload), state.photos),
        albums: map(
          (album) =>
            propEq(
              'album_id',
              path(['photo', 'album_id'], action.payload),
              album,
            )
              ? merge(album, {
                number_of_photos: inc(parseInt(album.number_of_photos, 10)),
              })
              : album,
          state.albums,
        ),
      }
    case INFO.FULFILLED: {
      const loadedWuids =
        state.loadedWuids[action.payload.payload.photo.wedding_wuid]

      return {
        ...state,
        photos: prepend(
          {
            ...action.payload.payload.photo,
            insert_date: parseISO(action.payload.payload.photo.insert_date),
            update_date: parseISO(action.payload.payload.photo.update_date),
          },
          state.photos,
        ),
        loadedWuids: {
          ...state.loadedWuids,
          ...(loadedWuids
            ? {
              [action.payload.payload.photo.wedding_wuid]: {
                ...loadedWuids,
                total: (parseInt(loadedWuids.total) + 1).toString(),
              },
            }
            : undefined),
        },
      }
    }
    case REMOVE_PHOTO.FULFILLED:
      return {
        ...state,
        photos: reject(
          propEq('photo_id', path(['data', 'photo_id'], action.payload)),
          state.photos,
        ),
        albums: map(
          (album) =>
            propEq(
              'album_id',
              path(['data', 'album_id'], action.payload),
              album,
            )
              ? merge(album, {
                number_of_photos: dec(parseInt(album.number_of_photos, 10)),
              })
              : album,
          state.albums,
        ),
        loadedWuids: {
          ...state.loadedWuids,
          [action.payload.data.wuid]: {
            ...state.loadedWuids[action.payload.data.wuid],
            total: (
              parseInt(state.loadedWuids[action.payload.data.wuid].total) - 1
            ).toString(),
          },
        },
      }
    case PHOTO_SET_FAVORITE.FULFILLED:
      const isFavorite = action.payload.payload.photo.is_favorite

      return {
        ...state,
        photos: state.photos.map((photo) =>
          photo.photo_id === action.payload.data.photo_id
            ? {
              ...photo,
              is_favorite: isFavorite,
            }
            : photo,
        ),
        albums: state.albums.map((album) =>
          album.album_title === 'Favorites'
            ? {
              ...album,
              number_of_photos: `${Number(album.number_of_photos) + (isFavorite === '1' ? 1 : -1)}`,
            }
            : album,
        ),
      }
    default:
      return state
  }
}

export default albumsReducer
