import React, { FC, useCallback, useEffect, useState } from 'react'
import TabsRound from 'routes/Agenda/EditEventDrawer/TabsRound'
import { Form } from 'react-final-form'

// Redux Tools
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { addGuestBulk } from 'store/modules/guests/actions'

// Components
import { Box, Text } from 'components/ui'
import { Input } from 'components/form'

// Utils
import {
  getInitialFormData,
  handleValidate,
  TabOptions,
  GuestFormStyled,
  RemoveButtonStyled,
  plusOneText,
  PlusOneBtn,
  PlusOneBtnBox,
  DrawerContent,
  EventDetailContentStyled,
} from './utils'
import { textInputStyles } from '../GuestDetails/utils'
import { EditContainerWrapper, MainContainer } from '../ResponsesModal/utils'
import {
  ButtonsStyled,
  CancelButtonStyled,
  EditScheduleContainer,
  SubmitButtonStyled,
  SubmitContainerStyled,
  BorderLine,
} from 'routes/Agenda/utils'

// Icons
import { ReactComponent as CloseIcon } from 'images/closeIconGrey.svg'
import { ReactComponent as PlusOneIcon } from 'images/plus-one-user.svg'
import { ReactComponent as PlusOnePurpleIcon } from 'images/plus-one-purple.svg'
import { ReactComponent as RemoveIcon } from 'images/removeIcon.svg'

type Props = {
  isOpen: boolean,
  onClose: () => void,
}

//@ts-ignore
const { structuredClone } = window

const GuestCreate: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const { wuid } = useParams<{ wuid: string }>()

  const [activeTab, setActiveTab] = useState<string>(TabOptions[0].name)
  const [forms, setForms] = useState<any>({})

  const onSubmitGuestBulk = useCallback((data) => dispatch(addGuestBulk(data)), [dispatch])

  useEffect(() => {
    setForms({})
  }, [activeTab])

  useEffect(() => {
    if (activeTab === '1') {
      const id = Object.keys(forms).length
      setForms({
        [id]: {
          first_name_sub: '',
          last_name_sub: '',
          email_sub: '',
          checked: false,
        },
      })
    }

    if (activeTab === '2') {
      const id = Object.keys(forms).length
      const id2 = Object.keys(forms).length + 1
      setForms({
        [id]: {
          first_name_sub: '',
          last_name_sub: '',
          email_sub: '',
          checked: false,
        },
        [id2]: {
          first_name_sub: '',
          last_name_sub: '',
          email_sub: '',
          checked: false,
        },
      })
    }
  }, [activeTab])

  const onBulkSubmitForm = async (data: any) => {
    const coupleSubGuestCount = ['1', '2'].includes(activeTab) ? 1 : 0

    const guests: any = {
      wuid,
      guests: [],
    }

    const guestList: any = {
      gid: -1,
      first_name: data.first_name,
      last_name: data.last_name,
      parent_guest_id: 0,
      set_invite_code: 1,
      max_subguests: Object.keys(forms).length + coupleSubGuestCount,
      subguests: [],
    }

    if (Object.keys(forms).length > 0) {
      guestList.subguests = Object.keys(forms).map((form) => {
        return {
          first_name: data[`first_name_${form}`],
          last_name: data[`last_name_${form}`],
          email: data[`email_${form}`],
          gid: form,
        }
      })
    }

    guests.guests.push(guestList)

    guests.guests = JSON.stringify(guests.guests)

    try {
      onSubmitGuestBulk(guests)
      onClose()
    } catch (e) {
      console.log(`Error during add guest ${e.message}`)
    }
  }

  const addGuestRow = () => {
    const id = Object.keys(forms).length
    setForms({
      ...forms,
      [id]: {
        first_name: '',
        last_name: '',
        email: '',
        checked: false,
      },
    })
  }

  const removeGuestRow = (key: any, props: any) => {
    props.form.change(`first_name_${key}`, '')
    props.form.change(`last_name_${key}`, '')
    props.form.change(`email_${key}`, '')
    const formsCopy = structuredClone(forms)
    delete formsCopy[key]
    setForms(formsCopy)
    if (Object.keys(forms).length === 1) {
      setActiveTab('0')
    } else if (Object.keys(forms).length === 2) {
      setActiveTab('1')
    }
  }

  const onCloseModal = () => {
    setForms({})
    onClose()
  }

  const handleChangeTab = (index: string) => {
    setActiveTab(`${index}`)
  }

  return (
    <>
      {isOpen && (
        <EditContainerWrapper onClick={() => onCloseModal()}>
          <EditScheduleContainer onClick={(e) => e.stopPropagation()}>
            <MainContainer>
              <Box>
                <Box display='flex' justifyContent='space-between' marginBottom='24px' width='100%'>
                  <Box width='100%' height='100%'>
                    <Text
                      fontFamily='Source Sans Pro'
                      fontWeight='700'
                      fontSize='32px'
                      lineHeight='40px'
                      color='#353B60'
                    >
                      Add Guests
                    </Text>
                  </Box>
                  <Box cursor='pointer' width='100%'>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='end'
                      onClick={() => onCloseModal()}
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                </Box>
                <TabsRound
                  activeTab={activeTab}
                  handleClick={handleChangeTab}
                  options={TabOptions}
                />
              </Box>
              <EventDetailContentStyled>
                <Form
                  onSubmit={onBulkSubmitForm}
                  initialValues={getInitialFormData(activeTab)}
                  validate={(value) => handleValidate(value, Object.keys(forms), ['couple', 'family'].includes(activeTab))}
                >
                  {(props: any) => {

                    return (
                      <>
                        <DrawerContent>
                          <Box marginTop='24px'>
                            <GuestFormStyled>
                              <Box display='flex' justifyContent='space-between'
                                   style={{ gap: 16 }}>
                                <Box marginBottom='20px' width='100%'>
                                  <Input
                                    hideErrorText={true}
                                    style={textInputStyles}
                                    name='first_name'
                                    label='First Name'
                                    placeholder='First Name' />
                                </Box>

                                <Box marginBottom='20px' width='100%'>
                                  <Input
                                    hideErrorText={true}
                                    style={textInputStyles}
                                    name={`last_name`}
                                    label='Last Name'
                                    placeholder='Last Name' />
                                </Box>
                              </Box>

                              <Box marginBottom='24px'>
                                <Input
                                  hideErrorText={true}
                                  name='email'
                                  style={textInputStyles}
                                  label='Email'
                                  placeholder='Email' />
                              </Box>

                              {
                                Object.keys(forms).length <= 0 ? null : Object.keys(forms).map((key: string) => (
                                  <Box key={key} marginTop='20px'>
                                    <BorderLine />
                                    <Box
                                      paddingBottom={24}
                                      display='flex'
                                      justifyContent='space-between'
                                      marginTop='20px'
                                      alignItems='center'
                                    >
                                      <Box display='flex' alignItems='center'>
                                        <PlusOneIcon />
                                        <Box>
                                          <Text style={plusOneText}>Plus-One</Text>
                                        </Box>
                                      </Box>
                                      <RemoveButtonStyled onClick={() => removeGuestRow(key, props)}>
                                        <RemoveIcon />
                                      </RemoveButtonStyled>
                                    </Box>
                                    <Box>
                                      <Box display='flex' justifyContent='space-between'
                                           style={{ gap: 16 }}>
                                        <Box width='100%' marginBottom='20px'>
                                          <Input
                                            hideErrorText={true} style={textInputStyles}
                                            name={`first_name_${key}`}
                                            label='First Name'
                                            placeholder='First Name'
                                          />
                                        </Box>

                                        <Box width='100%' marginBottom='20px'>
                                          <Input
                                            hideErrorText={true} style={textInputStyles}
                                            name={`last_name_${key}`}
                                            label='Last Name'
                                            placeholder='Last Name'
                                          />
                                        </Box>
                                      </Box>

                                      <Box marginBottom='24px'>
                                        <Input
                                          hideErrorText={true}
                                          name={`email_${key}`}
                                          style={textInputStyles}
                                          label='Email'
                                          placeholder='Email'
                                        />
                                      </Box>
                                    </Box>

                                  </Box>
                                ))
                              }
                            </GuestFormStyled>
                            <Box marginTop='-4px' paddingBottom='32px'>
                              <PlusOneBtnBox>
                                <PlusOneBtn
                                  type='submit'
                                  onClick={addGuestRow}
                                >
                                  <PlusOnePurpleIcon />
                                  <Text style={{
                                    color: '#5458F7',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '20px',
                                    fontFamily: 'Source Sans Pro',
                                    marginLeft: '8px',
                                  }}
                                  >
                                    Add Plus-One
                                  </Text>
                                </PlusOneBtn>
                              </PlusOneBtnBox>
                            </Box>
                          </Box>
                        </DrawerContent>
                        <ButtonsStyled>
                          <SubmitContainerStyled>
                            <CancelButtonStyled
                              onClick={() => onClose()}
                            >
                              Cancel
                            </CancelButtonStyled>

                            <SubmitButtonStyled
                              onClick={props.handleSubmit}
                            >
                              Save
                            </SubmitButtonStyled>
                          </SubmitContainerStyled>
                        </ButtonsStyled>
                      </>
                    )
                  }}
                </Form>
              </EventDetailContentStyled>
            </MainContainer>
          </EditScheduleContainer>
        </EditContainerWrapper>
      )}
    </>
  )
}

export default GuestCreate