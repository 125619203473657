import { path, pathOr, merge } from 'ramda'
import { Middleware } from 'redux'

import api from 'utils/api'
import { getWuid } from 'utils/router'
import { logout } from 'store/modules/auth/actions'

const apiMiddleware: Middleware = (store) => (next) => (action: {
  type: string
  api?: any
}) => {
  if (action.api) {
    const { auth, router } = store.getState()
    const wuid = getWuid(pathOr('', ['location', 'pathname'], router))
    const uuid = path(['user', 'uuid'], auth)
    const authToken = path(['authToken'], auth)

    return next({
      type: action.type,
      payload: {
        data: action.api.data,
        promise: api({
          ...action.api,
          authToken: authToken,
          onLogout: () => store.dispatch(logout()),
          data: [
            'multipart/form-data',
            'application/offset+octet-stream',
          ].includes(action.api.contentType)
            ? action.api.data
            : merge({ wuid, uuid }, pathOr({}, ['api', 'data'], action)),
        }),
      },
    })
  }

  return next(action)
}

export default apiMiddleware
