import React from 'react'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'

import { MainBox, Title, Subtitle } from 'components/WeddingWizard'
import { Box, Text, Button } from 'components/ui'
import { Input, CheckBox } from 'components/form'
import { ReactComponent as Icon } from 'images/ww-step11.svg'
import { getIsLoggedIn } from 'store/modules/auth/selectors'
import { WEDDING_CREATE } from 'constants/paths'

type Props = {
  isValid: boolean
  isSubmitting: boolean
  onBack: VoidFunction
  onContinue: VoidFunction
}

const Step11 = ({ isValid, isSubmitting, onBack, onContinue }: Props) => {
  const isLoggedIn = useSelector(getIsLoggedIn)

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{ pathname: `${WEDDING_CREATE}/signup`, state: { step: 11 } }}
      />
    )
  }

  return (
    <MainBox pt="70px" pb="28px">
      <Box mt="-105px" mb="20px">
        <Icon />
      </Box>

      <Title>Invite your fiancé to collaborate</Title>

      <Box mt="8px" maxWidth="500px" width="100%" display="flex">
        <Subtitle>
          By inviting your fiancé or wedding coordinator to HitchHero, you’ll be
          able to share wedding details instantly.
        </Subtitle>
      </Box>

      <Box
        width="100%"
        maxWidth="400px"
        display="flex"
        flexDirection="column"
        mt="40px"
      >
        <Text
          fontSize="20px"
          fontWeight={600}
          color="secondary"
          lineHeight="25px"
        >
          Their email:
        </Text>

        <Box mt="8px" />

        <Input
          name="yourSpouseEmail"
          placeholder="Email address"
          height="40px"
          type="email"
          hideErrorMessages
          isAlwaysBaseVariant
        />

        <Box mt="22px" display="flex" alignItems="center">
          <CheckBox name="skipInviteSpouse" />

          <Box ml="10px" mt="-4px">
            <Text fontFamily="1">I’ll invite them another time</Text>
          </Box>
        </Box>

        <Box mt="98px" display="flex" justifyContent="space-between">
          <Button variant="secondary" height="40px" onClick={onBack}>
            Back
          </Button>

          <Button
            variant="primary"
            height="40px"
            disabled={isSubmitting || !isValid}
            onClick={onContinue}
          >
            Next
          </Button>
        </Box>
      </Box>
    </MainBox>
  )
}

export default Step11
