import React, { FC } from 'react'
import { format } from 'date-fns'

import Box from '../Box'
import Text from '../Text'
import Button from '../Button'
import { ReactComponent as UpIcon } from 'images/chevron-up.svg'
import { ReactComponent as DownIcon } from 'images/time-chevron.svg'

type Props = {
  date: Date
  onClickChevron: (x1: boolean, x2: boolean) => () => void
  onClickAmPm: (x: boolean) => () => void
}

const Menu: FC<Props> = ({ date, onClickChevron, onClickAmPm }) => {
  const amPm = format(date, 'aaa')

  return (
    <Box
      position='absolute'
      py={0}
      px={1}
      bg='white'
      borderRadius={0}
      border='1px solid'
      borderColor='gray'
      mt={0}
      display='flex'
      alignItems='center'
      zIndex={1}
    >
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Button border='none' onClick={onClickChevron(true, true)}>
          <UpIcon fill='#353B60' />
        </Button>

        <Text fontSize={1} lineHeight={1} color='#353B60' fontFamily='Source Sans Pro'>
          {format(date, 'hh')}
        </Text>

        <Button border='none' onClick={onClickChevron(true, false)}>
          <DownIcon stroke='#353B60' />
        </Button>
      </Box>

      <Box mx={0}>
        <Text fontSize={1} color='#353B60' fontFamily='Source Sans Pro'>:</Text>
      </Box>

      <Box display='flex' flexDirection='column' alignItems='center'>
        <Button border='none' onClick={onClickChevron(false, true)}>
          <UpIcon fill='#353B60' />
        </Button>

        <Text fontSize={1} lineHeight={1} color='#353B60' fontFamily='Source Sans Pro'>
          {format(date, 'mm')}
        </Text>

        <Button border='none' onClick={onClickChevron(false, false)}>
          <DownIcon stroke='#353B60' />
        </Button>
      </Box>

      <Box display='flex' flexDirection='column' alignItems='center'>
        <Button border='none' onClick={onClickAmPm(amPm === 'AM')}>
          <UpIcon fill='#353B60' />
        </Button>

        <Text fontSize={1} lineHeight={1} color='#353B60' fontFamily='Source Sans Pro'>
          {amPm}
        </Text>

        <Button border='none' onClick={onClickAmPm(amPm === 'AM')}>
          <DownIcon stroke='#353B60' />
        </Button>
      </Box>
    </Box>
  )
}

export default Menu
