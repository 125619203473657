import React, {
  useState,
  useCallback,
  useMemo,
  ChangeEvent,
  MouseEvent,
  FormEvent,
} from 'react'

import { SChartItem } from 'store/modules/seatings/reducer'
import { Modal, Box, Text, Button, Input } from 'components/ui'
import { ReactComponent as CloseIcon } from './close.svg'
import Type from './Type'
import TypeModal from './TypeModal'
import Chairs from './Chairs'

export enum Types {
  RECTANGLE = 'rect',
  SQUARE = 'square',
  CIRCLE = 'circle',
  HALF_CIRCLE = 'halfcircle',
  LONG = 'long',
  ROUND = 'round',
  DANCE_FLOOR = 'dancefloor',
  STAGE = 'stage',
}

export const TypeLabels = {
  [Types.CIRCLE]: 'Round',
  [Types.HALF_CIRCLE]: 'Half Round',
  [Types.RECTANGLE]: 'Rectangle',
  [Types.SQUARE]: 'Square',
  [Types.LONG]: 'Long',
  [Types.ROUND]: 'Round',
  [Types.DANCE_FLOOR]: 'Dance Floor',
  [Types.STAGE]: 'Stage',
}

type Values = {
  caption: string
  type: Types
  top: number
  bottom: number
  left: number
  right: number
  around: number
  arcSide: number
  flatSide: number
}

const initialValues: Values = {
  caption: '',
  type: Types.RECTANGLE,
  top: 4,
  bottom: 4,
  left: 4,
  right: 4,
  around: 4,
  arcSide: 4,
  flatSide: 4,
}

type Props = {
  isOpen: boolean
  onCloseModal: VoidFunction
  onAddItem: (payload: Partial<SChartItem>) => void
}

const AddTableModal = ({ isOpen, onCloseModal, onAddItem }: Props) => {
  const [values, setValues] = useState(initialValues)
  const [variant, setVariant] = useState('')
  const [isTypeModalOpen, setTypeModalOpen] = useState(false)

  const onToggleTypeModal = useCallback(
    () => setTypeModalOpen((isOpen) => !isOpen),
    []
  )

  const onSetValue = useCallback(
    (field: string, value: any) =>
      setValues((values) => ({ ...values, [field]: value })),
    []
  )

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>) => {
      const { name, value } = e.currentTarget

      if (name === 'caption') {
        setVariant('')
      }

      onSetValue(name, value)
    },
    [onSetValue]
  )

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      if (!values.caption) {
        return setVariant('error')
      }

      setVariant('')

      onAddItem({
        type: values.type,
        caption: values.caption,
        seats:
          values.type === Types.HALF_CIRCLE
            ? [
                Array.from(new Array(values.arcSide)),
                Array.from(new Array(values.flatSide)),
              ]
            : values.type === Types.CIRCLE
            ? [Array.from(new Array(values.around))]
            : [
                Array.from(new Array(values.top)),
                Array.from(new Array(values.right)),
                Array.from(new Array(values.bottom)),
                Array.from(new Array(values.left)),
              ],
      })

      onCloseModal()
    },
    [values, onAddItem, onCloseModal]
  )

  const modalStyles = useMemo(
    () => ({
      content: { maxWidth: isTypeModalOpen ? 528 : 414, top: 108 },
    }),
    [isTypeModalOpen]
  )

  const renderContent = useCallback(() => {
    if (isTypeModalOpen) {
      return (
        <TypeModal
          activeType={values.type}
          onSetValue={onSetValue}
          onClose={onCloseModal}
          onCancel={onToggleTypeModal}
        />
      )
    }

    return (
      <Box
        bg="#F7F7FC"
        borderRadius="10px"
        boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
      >
        <Box
          bg="#FFFFFF"
          boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
          borderRadius="10px"
          height="60px"
          px="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight={600} fontSize="20px" color="#353B60">
            Add a Table
          </Text>

          <Button p="0px" width="24px" height="24px" onClick={onCloseModal}>
            <CloseIcon />
          </Button>
        </Box>

        <Box as="form" pt="20px" px="24px" pb="24px" onSubmit={onSubmit}>
          <Box display="flex" flexDirection="column">
            <Box pb="2px" pl="4px">
              <Text fontSize="14px" lineHeight="18px" color="#73737B">
                Name*
              </Text>
            </Box>

            <Input
              name="caption"
              placeholder="Sweethearts"
              border="2px solid #E8E8ED"
              borderRadius="10px"
              isFullWidth
              height="40px"
              variant={variant}
              onChange={onChange}
            />

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt="24px"
              mb="12px"
            >
              <Text fontWeight={600} fontSize="16px" color="#353B60">
                Types
              </Text>

              <Button
                px="0px"
                fontWeight={600}
                fontFamily="0"
                fontSize="16px"
                lineHeight="20px"
                color="#5458F7"
                height="20px"
                bg="transparent"
                onClick={onToggleTypeModal}
              >
                View all
              </Button>
            </Box>

            <Type activeType={values.type} onSetValue={onSetValue} />

            <Chairs
              type={values.type}
              top={values.top}
              bottom={values.bottom}
              left={values.left}
              right={values.right}
              around={values.around}
              arcSide={values.arcSide}
              flatSide={values.flatSide}
              onSetValue={onSetValue}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" pt="32px">
            <Button
              fontWeight={600}
              fontSize="16px"
              color="#5458F7"
              px="0px"
              bg="transparent"
              mr="40px"
              fontFamily="0"
              onClick={onCloseModal}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              bg="#5458F7"
              borderRadius="10px"
              height="40px"
              width="80px"
              fontWeight={600}
              fontSize="16px"
              color="white"
              fontFamily="0"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }, [
    isTypeModalOpen,
    onCloseModal,
    onSubmit,
    onSetValue,
    onToggleTypeModal,
    onChange,
    variant,
    values,
  ])

  return (
    <Modal isOpen={isOpen} style={modalStyles} onRequestClose={onCloseModal}>
      {renderContent()}
    </Modal>
  )
}

export default AddTableModal
