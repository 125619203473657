import React, { FC } from 'react'

import { Box } from 'components/ui'

const OnlineIndicator: FC = () => (
  <Box
    position="absolute"
    right="0px"
    bottom="0px"
    width="14px"
    height="14px"
    bg="#7bb865"
    border="2.5px solid"
    borderColor="white"
    borderRadius="50%"
  />
)

export default OnlineIndicator
