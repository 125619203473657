import React, { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { path } from 'ramda'

import { joinToWedding } from 'store/modules/wedding/actions'
import { WEDDING, WEDDING_LIST } from 'constants/paths'
import { Loading } from 'components/ui'

const JoinToWedding: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { inviteCode } = useParams<{ inviteCode: string }>()
  const dispatch = useDispatch()
  const { push } = useHistory()

  const onJoinToWedding = useCallback(
    async () => dispatch(joinToWedding(inviteCode)),
    [dispatch, inviteCode]
  )
  const handleJoinToWedding = useCallback(async () => {
    setIsLoading(true)

    try {
      const res = await onJoinToWedding()

      toast('You joined the wedding successfully')
      push(WEDDING(path(['value', 'payload', 'wuid'], res)))
    } catch (error) {
      toast(error.message)

      push(WEDDING_LIST)
    }
  }, [onJoinToWedding, push])

  useEffect(() => {
    handleJoinToWedding()
  }, [handleJoinToWedding])

  if (isLoading) return <Loading />

  return <div />
}

export default JoinToWedding
