import { DraggableLocation } from 'react-beautiful-dnd'

import { NavItem } from 'store/modules/nav/reducer'

export const reorderItems = (
  list: any[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const reorderSubitems = (
  items: { [type: string]: any[] },
  type: string,
  parent: any,
  sourceIndex: number,
  destIndex: number
) => ({
  ...items,
  [type]: items[type].map((item) =>
    item.nav_item_id === parent.nav_item_id
      ? {
          ...parent,
          subitems: reorderItems(parent.subitems, sourceIndex, destIndex),
        }
      : item
  ),
})

export const dropToFolder = (
  items: { [type: string]: NavItem[] },
  srcType: string,
  src: NavItem,
  destType: string,
  destIndex: number,
  dest: NavItem,
  srcIndex: number,
  srcParentId?: NavItem['nav_item_id']
) => {
  const res = { ...items }

  if (srcParentId) {
    const index = res[srcType].findIndex(
      ({ nav_item_id }) => nav_item_id === srcParentId
    )

    if (index > -1) {
      res[srcType][index].subitems.splice(srcIndex, 1)
    }
  }

  res[srcType] = res[srcType].filter(
    ({ nav_item_id }) => nav_item_id !== src.nav_item_id
  )

  res[destType] = res[destType].map((item) => {
    const subitems = Array.from(item.subitems)

    if (item.nav_item_id === dest.nav_item_id) {
      subitems.splice(destIndex, 0, src)
    }

    return {
      ...item,
      subitems,
    }
  })

  return res
}

export const dropFromFolder = (
  items: { [type: string]: NavItem[] },
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const res = { ...items }

  const type = Object.keys(items).find((type) =>
    items[type].some((item) => item.nav_item_id === droppableSource.droppableId)
  )

  if (!type) {
    return res
  }

  const parent = items[type].find(
    (item) => item.nav_item_id === droppableSource.droppableId
  )

  if (!parent) {
    return res
  }

  const parentIndex = res[type].findIndex(
    ({ nav_item_id }) => nav_item_id === droppableSource.droppableId
  )

  const src = parent.subitems[droppableSource.index]

  res[type][parentIndex].subitems.splice(droppableSource.index, 1)
  res[droppableDestination.droppableId].splice(
    droppableDestination.index,
    0,
    src
  )

  return res
}

export const dropFromFolderToFeatured = (
  items: { [type: string]: NavItem[] },
  srcType: string,
  parentId: string,
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const res = { ...items }

  const parentIndex = res[srcType].findIndex(
    ({ nav_item_id }) => nav_item_id === parentId
  )

  if (parentIndex) {
    const [srcItem] = res[srcType][parentIndex].subitems.splice(
      droppableSource.index,
      1
    )
    const [destItem] = res[droppableDestination.droppableId].splice(
      droppableDestination.index,
      1
    )

    res[srcType][parentIndex].subitems.splice(
      droppableSource.index,
      0,
      destItem
    )
    res[droppableDestination.droppableId].splice(
      droppableDestination.index,
      0,
      srcItem
    )
  }

  return res
}

export const replaceItems = (
  source: any[],
  destination: any[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const sourceItem = sourceClone[droppableSource.index]
  const destItem = destination[droppableDestination.index]

  if (destItem) {
    sourceClone[droppableSource.index] = destItem
    destClone[droppableDestination.index] = sourceItem
  }

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  }
}

export const moveItems = (
  source: any[],
  destination: any[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  }
}
