import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  KeyboardEvent,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { Form, FormRenderProps } from 'react-final-form'
import { equals, forEach, map, join, path } from 'ramda'
import validate from 'validate.js'
import { toShort } from 'emoji-toolkit'

import { Box, Loading } from 'components/ui'
import { presenceFieldConstraint } from 'constants/constraints'
import { getCreatedRoomId } from 'store/modules/messages/selectors'
import { selectRoom } from 'store/modules/messages/actions'
import GuestsSelector from './GuestsSelector'
import Input from '../ChatInput'

type Props = {
  socket: WebSocket
  onSuccess: () => void
}

type Values = {
  guests: { value: string }[]
  message: string
}

const NewChat: FC<Props> = ({ socket, onSuccess }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [rid] = useState(Math.random().toString(36))
  const [submittedValues, setSubmittedValues] = useState<Values>()
  const roomId = useSelector(getCreatedRoomId(rid))
  const { wuid } = useParams<{ wuid: string }>()

  const handleSelectRoom = useCallback(
    (roomId) => dispatch(selectRoom(roomId)),
    [dispatch]
  )
  const handleValidate = (values: object) =>
    validate(values, {
      ...presenceFieldConstraint('guests'),
    })
  const handleSubmit = (values: Values) => {
    setIsLoading(true)
    setSubmittedValues(values)
    const isPrivate = equals(values.guests.length, 1)

    socket.send(
      JSON.stringify({
        c: isPrivate ? 'p' : 'g',
        ...(isPrivate
          ? {
              uid: path([0, 'value'], values.guests),
            }
          : {
              title: `Group: ${join(
                ', ',
                map(path(['user', 'first_name']), values.guests)
              )}`,
            }),
        wuid,
        rid,
      })
    )
  }

  useEffect(() => {
    if (roomId && submittedValues) {
      if (submittedValues.guests && !equals(submittedValues.guests.length, 1)) {
        forEach((guest) => {
          socket.send(
            JSON.stringify({
              c: 'j',
              uid: guest.value,
              wuid,
              room_id: roomId,
            })
          )
        }, submittedValues.guests)
      }

      socket.send(
        JSON.stringify({
          c: 'z',
          msg: toShort(submittedValues.message),
          room_id: roomId,
        })
      )

      setIsLoading(false)
      handleSelectRoom(roomId)
      onSuccess()
    }
  }, [roomId, submittedValues, socket, wuid, handleSelectRoom, onSuccess])

  const renderForm = ({ dirty, handleSubmit }: FormRenderProps<Values>) => {
    const handlePressKey = (e: KeyboardEvent<HTMLInputElement>) => {
      if (!isLoading && dirty && equals(e.key, 'Enter')) handleSubmit()
    }

    return (
      <Box position="relative" height="100%">
        <GuestsSelector />

        {isLoading && <Loading />}

        <Box
          height="54px"
          position="absolute"
          bottom="2px"
          left="0px"
          right="0px"
        >
          <Input name="message" onPressKey={handlePressKey} />
        </Box>
      </Box>
    )
  }

  return (
    <Box height="100%" borderRight="1px solid" borderColor="gray">
      <Form
        render={renderForm}
        validate={handleValidate}
        onSubmit={handleSubmit}
      />
    </Box>
  )
}

export default NewChat
