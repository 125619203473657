import React, { FC, useState, useCallback } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { Box, Button, Text } from 'components/ui'
import { ReactComponent as SettingsIcon } from 'images/settings.svg'
import { ReactComponent as ChevronIcon } from './chevron-down.svg'
import { ReactComponent as TrashIcon } from './trash.svg'
import { ReactComponent as UserPlusIcon } from './user.svg'

type Props = {
  onOpenModal: (x: string) => void
}

const ItemMenu: FC<Props> = ({ onOpenModal }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleClickButton = useCallback(() => setMenuIsOpen(!menuIsOpen), [
    menuIsOpen,
  ])
  const handleClickOutside = useCallback(() => setMenuIsOpen(false), [])

  return (
    <>
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        <Box position="relative">
          <Button
            border="1px solid"
            borderColor="gray"
            borderRadius={0}
            pr="12px"
            pl={0}
            height="40px"
            width="64px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            onClick={handleClickButton}
          >
            <SettingsIcon stroke="#8991a0" width={20} height={20} />

            <Box ml="6px" display="flex" justifyContent="center">
              <ChevronIcon />
            </Box>
          </Button>

          {menuIsOpen && (
            <Box
              position="absolute"
              display="flex"
              flexDirection="column"
              width="196px"
              bg="white"
              border="1px solid"
              borderColor="gray"
              borderRadius={0}
              boxShadow={0}
              top="52px"
              right="0px"
            >
              <Box
                cursor="pointer"
                display="flex"
                alignItems="center"
                height="40px"
                onClick={() => onOpenModal('manageAdmins')}
              >
                <Box minWidth="52px" display="flex" justifyContent="center">
                  <UserPlusIcon />
                </Box>

                <Box
                  height="100%"
                  width="100%"
                  display="flex"
                  alignItems="center"
                  borderBottom="0.5px solid #E1EAEF"
                >
                  <Text isTruncate fontSize="16px" color="#73737B">
                    Add admin
                  </Text>
                </Box>
              </Box>

              <Box
                cursor="pointer"
                display="flex"
                alignItems="center"
                height="40px"
                onClick={() => onOpenModal('remove')}
              >
                <Box minWidth="52px" display="flex" justifyContent="center">
                  <TrashIcon />
                </Box>

                <Box width="100%">
                  <Text fontSize="16" color="#F44343">
                    Delete
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </OutsideClickHandler>
    </>
  )
}

export default ItemMenu
