import create from 'utils/createReduxPromiseActionType'

const NAME = 'nav'

export const LOAD_NAV = create([NAME, 'LOAD_NAV'].join('/'))
export const ADD_NAV = create([NAME, 'ADD_NAV'].join('/'))

export const LOAD_NAV_ITEMS = create([NAME, 'LOAD_NAV_ITEMS'].join('/'))
export const ADD_NAV_ITEM = create([NAME, 'ADD_NAV_ITEM'].join('/'))
export const EDIT_NAV_ITEM = create([NAME, 'EDIT_NAV_ITEM'].join('/'))
export const DELETE_NAV_ITEM = create([NAME, 'DELETE_NAV_ITEM'].join('/'))
export const SWAP_NAV_ITEMS = create([NAME, 'SWAP_NAV_ITEMS'].join('/'))

export const TOGGLE_APP_SECTIONS = [NAME, 'TOGGLE_APP_SECTIONS'].join('/')
