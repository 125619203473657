import React, { FC, useMemo } from 'react'

import Box from './Box'
import Text from './Text'

type Props = {
  isRequired?: boolean
  isActive?: boolean
  isError?: boolean
}

const FormLabel: FC<Props> = ({
                                isRequired = false,
                                isError = false,
                                children,
                              }) => {
  const color = useMemo(
    () => (isError ? '#F44343' : '#73737B'),
    [isError],
  )

  return (
    <Box mb='4px' ml='4px' height={18}>
      <Text
        fontSize='14px'
        lineHeight='18px'
        fontWeight={isError ? '600' : 'normal'}
        color={color}
      >
        {children}
        {isRequired && (
          <Text fontSize='inherit' color='inherit'>
            *
          </Text>
        )}
      </Text>
    </Box>
  )
}

export default FormLabel
