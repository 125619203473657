import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'

import { Box, Text, Button } from 'components/ui'
import { FontSelector } from 'components/form'
import {
  MainBox,
  Title,
  Subtitle,
  AppPreview,
  WeddingNameInput,
} from 'components/WeddingWizard'
import { ReactComponent as Icon } from 'images/ww-step8.svg'
import { getIsLoggedIn } from 'store/modules/auth/selectors'
import { WEDDING_CREATE } from 'constants/paths'

type Props = {
  isValid: boolean
  color?: { value: string; label: string }
  color2?: { value: string; label: string }
  weddingName?: string
  font?: { value: string; label: string }
  image?: string
  onBack: VoidFunction
  onContinue: VoidFunction
}

const Step9 = ({
  color,
  color2,
  weddingName,
  font,
  image,
  isValid,
  onBack,
  onContinue,
}: Props) => {
  const isLoggedIn = useSelector(getIsLoggedIn)

  if (!isLoggedIn) {
    return <Redirect to={`${WEDDING_CREATE}/signup`} />
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems={['flex-start', 'center']}
      minHeight={['calc(100vh - 96px)', 'calc(100vh - 180px)']}
    >
      <MainBox py="71px" pb="27px">
        <Box mt="-95px" mb="30px">
          <Icon />
        </Box>

        <Title>Modern, elegant, or whimsical?</Title>

        <Box mt="8px" maxWidth="380px" width="100%" display="flex">
          <Subtitle>
            The smaller details in your theme will highlight your personality as
            a couple.
          </Subtitle>
        </Box>

        <Box maxWidth="400px" width="100%" mt="40px">
          <Text
            fontWeight={600}
            fontSize="20px"
            lineHeight="25px"
            color="secondary"
          >
            Header:
          </Text>

          <Box mt="8px" />

          <WeddingNameInput height="40px" />

          <Box mt="20px" />

          <Text
            fontWeight={600}
            fontSize="20px"
            lineHeight="25px"
            color="secondary"
          >
            Typeface:
          </Text>

          <Box mt="8px" />

          <FontSelector
            weddingName={weddingName}
            name="font"
            variant="modal"
            placeholder="Select a Font"
            height="40px"
          />

          <Box display="flex" justifyContent="space-between" mt="46px">
            <Button variant="secondary" height="40px" onClick={onBack}>
              Back
            </Button>

            <Button
              variant="primary"
              disabled={!isValid}
              height="40px"
              onClick={onContinue}
            >
              Next
            </Button>
          </Box>
        </Box>
      </MainBox>

      <Box position="absolute" bottom="0px" right="0px" left="0px" zIndex={1}>
        <AppPreview
          color={color}
          color2={color2}
          title={weddingName}
          font={font}
          image={image}
        />
      </Box>
    </Box>
  )
}

export default Step9
