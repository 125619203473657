import styled from 'styled-components'
import { Button } from '../ui'

export const ModalContainer = styled.div`
  overflow: hidden;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 4px 15px rgba(53, 59, 96, 0.05);
  border-radius: 10px;
  transition: 0.4s;
  animation-name: opening1;
  animation-duration: .2s;
  animation-iteration-count: 1;
  @keyframes opening1 {
    0% {
      opacity: .1;
    }
    100% {
      opacity: 1;
    }
  }
`
export const FormWrapper = styled.div`
  position: relative;
  padding: 12px 24px 24px 24px;
  min-height: 370px;
  max-height: 660px;
`
export const SubmitContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: end;
  margin-left: auto;
  bottom: 0;
  padding: 24px 0 0 0;
`
export const CancelButton = styled(Button)`
  font-family: 'Source Sans Pro', normal, serif;
  color: #5458F7;
  line-height: 20px;
  font-style: normal;
  background-color: #F7F7FC;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px;
  margin-right: 20px;
`
export const SubmitButton = styled(Button)`
  font-family: 'Source Sans Pro', normal, serif;
  text-transform: capitalize;
  color: #FFFFFF;
  line-height: 20px;
  font-style: normal;
  background-color: #5458F7;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px;
`
export const HeaderTag = styled.div`
  background: #FFFFFF;
  border-radius: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
`
export const HeaderText = styled.div`
  font-family: 'Source Sans Pro';
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #353B60;
`
export const ModalContent = styled.div`
  border-top: 1px solid #E8E8ED;
  border-bottom: 1px solid #E8E8ED;
  background-color: white;
  padding: 8px 0;
  max-height: 400px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`
