import React, { FC, useState, useMemo, useCallback, useEffect } from 'react'

import { Box, Text, Button } from 'components/ui'
import { ReactComponent as Logo } from 'images/logo.svg'
import { PersonalizedSchedules } from './PersonalizedSchedules'
import SeatingChart from './SeatingChart/SeatingChart'
import Notifications from './NotificationsAndReminders/Notifications'
import MealChoices from './MealChoices/MealChoices'

const Features: FC = () => {
  const [selectedItem, selectItem] = useState(0)

  const items = useMemo(
    () => [
      {
        id: 0,
        title: 'Personalized Schedules',
        text: `Easily manage your wedding schedule while keeping guests informed and on time. Organize your big day and customize it for any guest.`,
        component: <PersonalizedSchedules />,
      },
      {
        id: 1,
        title: 'Interactive Seating Chart',
        text: `Assign guests to tables directly from your guest list, and build your seating chart in real time with your partner or planner.`,
        component: <SeatingChart />,
      },
      {
        id: 2,
        title: 'Notifications and Reminders',
        text: `Get notified when guests respond to your invitation and send reminders to guests about important wedding news.`,
        component: <Notifications />,
      },
      {
        id: 3,
        title: 'Track RSVPs and Meal Choices',
        text: `If it's yes, no, or maybe - keep count of RSVPs for your guests, plus-ones, and their food preferences at your reception.`,
        component: <MealChoices />,
      },
    ],
    []
  )

  const handleSelectItem = useCallback(
    (item: number) => () => selectItem(item),
    []
  )

  useEffect(() => {
    const interval = setInterval(() => {
      selectItem((item: number) => (items.length - 1 === item ? 0 : item + 1))
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Box
      display={['none', 'none', 'none', 'flex']}
      flexDirection="column"
      alignItems="center"
      width="50%"
      bg="#F7F7FC"
      order="2"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box pb="42px" pt="40px">
          <Logo height="34px" width="155px" />
        </Box>

        {items[selectedItem].component}

        <Box display="flex" flexDirection="column" alignItems="center">
          {items.slice(selectedItem, selectedItem + 1).map((item) => (
            <Box
              key={item.id}
              display="flex"
              flexDirection="column"
              alignItems="center"
              maxWidth="560px"
              pb="40px"
              pt="42px"
            >
              <Text
                color="#353B60"
                fontFamily="Lora"
                fontWeight="600"
                fontSize="28px"
                lineHeight="36px"
                textAlign="center"
              >
                {item.title}
              </Text>

              <Box mb="8px" />

              <Text
                color="#73737B"
                fontFamily="Lato"
                fontSize="16px"
                fontWeight="400"
                lineHeight="24px"
                textAlign="center"
              >
                {item.text}
              </Text>
            </Box>
          ))}

          <Box
            display="flex"
            justifyContent="space-between"
            width="178px"
            mb="44px"
          >
            {items.map(({ id }) => (
              <Button
                key={id}
                width="40px"
                height="13px"
                p="0px"
                onClick={handleSelectItem(id)}
                bg="#F7F7FC"
              >
                <Box
                  key={id}
                  height="3px"
                  borderRadius="2px"
                  width="100%"
                  bg={selectedItem === id ? '#5458F7' : '#E8E8ED'}
                />
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Features
