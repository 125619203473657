import React, { FC } from 'react'

import { Box } from 'components/ui'
import { ReactComponent as Logo } from './logo.svg'
import Tabs from './Tabs'
import Menu from './Menu'

type Props = {
  isHidden: boolean
}

const WeddingListHeader: FC<Props> = ({ isHidden }) => (
  <Box
    mt="16px"
    mx="16px"
    bg="#353B60"
    boxShadow="0px 4px 20px rgba(53, 59, 96, 0.2)"
    borderRadius="10px"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    height="72px"
    position="fixed"
    left="0px"
    right="0px"
    px="16px"
    zIndex={4}
    style={{
      visibility: isHidden ? 'hidden' : 'visible',
      opacity: isHidden ? 0 : 1,
      transition: 'visibility 0s linear 0.2s, opacity 0.2s ease 0s',
    }}
  >
    <Box display="flex" alignItems="center">
      <Box display="flex" width="108px">
        <Logo height="24px" />
      </Box>

      <Tabs />
    </Box>

    <Menu />
  </Box>
)

export default WeddingListHeader
