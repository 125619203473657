import styled, { css } from 'styled-components'

import Box from '../Box'

const ColorSelectorIcon = styled(Box).attrs({
  position: 'absolute',
  bottom: '8px',
  right: '10px',
  borderRadius: '50%',
  zIndex: 1,
  width: '20px',
  height: '20px',
})<{ value?: string }>`
  ${(props) =>
    props.value
      ? css`
          background-color: ${props.value};
        `
      : css`
          background-image: conic-gradient(
            #fd5c5c 9%,
            #fe77c4 20%,
            #c47eff 33%,
            #508dfa 45%,
            #5fe3ff 57%,
            #50e98e 69%,
            #ffed63 82%,
            #ffb063 95%,
            #fd5c5c 9%
          );
        `}
`

export default ColorSelectorIcon
