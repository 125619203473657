import React, { FC, useCallback } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { path } from 'ramda'
import Submit from '../../Submit'

// Utils
import {
  StyledDiv,
  ParamsType,
  ComponentProps,
  convertFormToPayload,
  handleValidate,
  countryOptions,
  PlusOneButton,
  plusOneText,
  ButtonContent,
  SubGuestBox,
  AvatarBox,
  SubGuestName,
  ButtonBoxWithIcon,
  btnText, joinedWeddingTxt, JoinedWeddingBox,
} from '../../../utils'
import { BorderLine } from 'routes/Agenda/utils'

import {
  getImage, getRSVPStatus,
  parseGuestForEdit,
} from 'utils/guest'

// Store tools
import { useDispatch, useSelector } from 'react-redux'
import { editGuest } from 'store/modules/guests/actions'
import { getAllLists } from 'store/modules/guests/selectors'

// Components
import { Avatar, Box, Text, Button } from 'components/ui'

// Icons
import { ReactComponent as PlusIcon } from 'images/plus-one.svg'
import { ReactComponent as RightIcon } from 'images/right-arrow.svg'
import { ReactComponent as SendIcon } from 'images/mail.svg'
import { ReactComponent as InvitedUserIcon } from 'images/invitedUser.svg'


const PlusOnes: FC<ComponentProps> = ({ guestForEdit, handleCloseEditForm }) => {
  const dispatch = useDispatch()

  const params = useParams<ParamsType>()

  const groups = useSelector(getAllLists)

  const onSubmit = useCallback(async (data: object) => {
    return dispatch(editGuest(data))
  }, [dispatch])

  const onSubmitForm = useCallback(async (data) => {

    try {
      data.wuid = params.wuid
      data.guest_id = guestForEdit.guest_id

      await onSubmit(convertFormToPayload(data))

      handleCloseEditForm()
    } catch (err) {
      toast(err.message)
    }
  }, [params.wuid, guestForEdit.guest_id, onSubmit, handleCloseEditForm])

  return (
    <StyledDiv>
      <Form onSubmit={onSubmitForm} initialValues={parseGuestForEdit(guestForEdit, groups, countryOptions)}
            validate={handleValidate}>
        {(props: any) => {

          return (
            <>
              <Box marginBottom='20px' marginTop='25px'>
                <ButtonContent>
                  <PlusOneButton>
                    <PlusIcon />
                    <Text style={plusOneText}>
                      Add Plus One
                    </Text>
                  </PlusOneButton>
                  <Box>
                    <RightIcon />
                  </Box>
                </ButtonContent>
              </Box>
              <BorderLine />

              {guestForEdit?.sub_guest_list?.length > 0 && (
                <Box marginTop='20px'>
                  {guestForEdit?.sub_guest_list.map((subGuest: any) => {
                    const { text, color, backgroundColor }: any = getRSVPStatus(subGuest?.rsvp_status)

                    return (
                      <SubGuestBox>
                        <Box display='flex' justifyContent='space-between' flexDirection='column'>
                          <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <AvatarBox>
                              <Box>
                                <Avatar
                                  image={getImage(path(['user', 'image'], subGuest), 'big_icon')}
                                  name={`${subGuest?.first_name.slice(0, 1).toUpperCase()}${subGuest?.last_name.slice(0, 1).toUpperCase()}`}
                                  fontSize={12}
                                  fontWeight={700}
                                  bg='#353B60'
                                  color='#FFFFFF'
                                  size={26}
                                />
                              </Box>
                              <SubGuestName>{`${subGuest?.first_name} ${subGuest?.last_name}`}</SubGuestName>
                            </AvatarBox>
                            <Box
                              style={{
                                backgroundColor: backgroundColor,
                                borderRadius: '3px',
                                width: '71px',
                                height: '26px',
                                padding: '4px 6px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: 'Source Sans Pro',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  lineHeight: '18px',
                                  color: color,
                                }}
                              >
                                {text}
                              </Text>
                            </Box>
                          </Box>
                          <Box display='flex' justifyContent='space-between' alignItems='center' marginTop='24px'>
                            <Box>
                              {subGuest.joined_dt === '0000-00-00 00:00:00.000000' ? (
                                <Button style={{ padding: 0, height: 20 }}>
                                  <ButtonBoxWithIcon>
                                    <SendIcon />
                                    <Text style={btnText}>
                                      Send Invite
                                    </Text>
                                  </ButtonBoxWithIcon>
                                </Button>
                              ) : (
                                <JoinedWeddingBox>
                                  <InvitedUserIcon />
                                  <Text style={joinedWeddingTxt}>
                                    Joined Wedding - {subGuest.is_joined}
                                  </Text>
                                </JoinedWeddingBox>
                              )}
                            </Box>
                            <Box>
                              <Button
                                style={{
                                  fontFamily: 'Source Sans Pro',
                                  fontWeight: 400,
                                  fontSize: 14,
                                  lineHeight: '20px',
                                  color: '#73737B',
                                  height: 20,
                                }}
                              >
                                Remove from Guest
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </SubGuestBox>
                    )
                  })}
                </Box>
              )
              }
              <Submit isInvalid={props.invalid} handleSubmit={props.form.submit} handleClose={handleCloseEditForm} />
            </>
          )
        }}
      </Form>
    </StyledDiv>
  )
}

export default PlusOnes