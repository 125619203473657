import React, { FC, useState, useCallback } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Form, FormRenderProps } from 'react-final-form'
import validate from 'validate.js'

import { urlConstraint } from 'constants/constraints'
import { ReactComponent as LinkIcon } from 'images/link.svg'
import { Input } from 'components/form'
import Box from '../Box'
import Button from '../Button'
import FormatButton from './FormatButton'

const AddLinkButton: FC<{ onAddLink: (x: any) => void }> = ({ onAddLink }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleOpenMenu = useCallback(() => setMenuIsOpen(true), [])
  const handleClickOutside = useCallback(() => setMenuIsOpen(false), [])
  const handleValidate = (values: any) => validate(values, urlConstraint('url'))

  const handleSubmit = useCallback(
    values => {
      handleClickOutside()

      onAddLink(values.url)
    },
    [onAddLink, handleClickOutside]
  )

  const renderForm = ({ form, invalid }: FormRenderProps) => (
    <Box display="flex" alignItems="flex-end">
      <Box mr={0} width="200px">
        <Input name="url" placeholder="Link label" />
      </Box>

      <Button
        variant="primary"
        height="38px"
        disabled={invalid}
        onClick={form.submit}
      >
        Add
      </Button>
    </Box>
  )

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Box position="relative">
        <FormatButton onClick={handleOpenMenu}>
          <LinkIcon height={18} width={18} fill="currentColor" />
        </FormatButton>

        {menuIsOpen && (
          <Box
            position="absolute"
            mt={0}
            ml="-1rem"
            py={1}
            px={0}
            bg="white"
            border="1px solid"
            borderColor="gray"
            borderRadius={0}
            boxShadow={0}
            zIndex={2}
          >
            <Form
              render={renderForm}
              validate={handleValidate}
              onSubmit={handleSubmit}
            />
          </Box>
        )}
      </Box>
    </OutsideClickHandler>
  )
}

export default AddLinkButton
