import React, { useCallback, useMemo, CSSProperties, FC } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import md5 from 'md5'

// Store tools
import {
  addPhoto,
  changeFileOffset,
  pushFile,
} from 'store/modules/photos/actions'
import { addAlbumPhoto, info } from 'store/modules/albums/actions'

// Components
import { Box, Button, Text } from 'components/ui'

// Utils
import { readImage, fileToArrayBody } from 'utils/images'

// Icons
import { ReactComponent as UploadIcon } from './upload-cloud.svg'

type Props = {
  selectedFilter?: string
  albumId?: string
}

const Uploader: FC<Props> = ({ selectedFilter, albumId }) => {
  const dispatch = useDispatch()

  const { wuid } = useParams()

  const onPushFile = useCallback(
    (wuid: string, file: File) => dispatch(pushFile(wuid, file)),
    [dispatch],
  )

  const onUploadPhoto = useCallback(async (data) => dispatch(addPhoto(data)), [
    dispatch,
  ])

  const onAddPhoto = useCallback(async (data) => dispatch(addAlbumPhoto(data)), [
    dispatch,
  ])

  const onChangeFileOffset = useCallback(
    async (fileName: string, offset: number) =>
      dispatch(changeFileOffset(fileName, offset)),
    [dispatch],
  )

  const getPhotoInfo = useCallback(
    async (photoId: string) => dispatch(info(photoId)),
    [dispatch],
  )

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!wuid) return

      acceptedFiles.forEach((file) => onPushFile(wuid, file))

      return Promise.all(
        acceptedFiles.map(async (file) => {
          const res: any = await onUploadPhoto({
            wuid,
            image_type: file.type.split('/')[1],
            hash: md5(await readImage(file)),
            src_file: file.name,
            total_length: file.size,
            mod_time: file.lastModified,
          })
          const thusId = res.value.payload.photo.tus_id
          const photoId = res.value.payload.photo.photo_id

          if (selectedFilter !== 'View all') {
            await onAddPhoto({
              wuid,
              album_id: albumId,
              photo_id: photoId,
            })
          }

          const { headers } = await axios.head(thusId, {
            headers: { 'Tus-Resumable': '1.0.0' },
          })

          const initOffset = parseInt(headers['upload-offset'])
          onChangeFileOffset(file.name, initOffset)
          // const CHUNK_MAX_SIZE = 1000

          if (initOffset < file.size) {
            // const blockSize = Math.min(file.size - offset, CHUNK_MAX_SIZE)
            const fileBlob = new Blob(
              [new Uint8Array(await fileToArrayBody(file))],
              { type: file.type },
            )

            const uploadChunk = async (offset: number) => {
              const { headers } = await axios.patch(thusId, fileBlob, {
                headers: {
                  'Content-Type': 'application/offset+octet-stream',
                  'Upload-Offset': offset,
                  'Tus-Resumable': '1.0.0',
                },
                onUploadProgress: (progressEvent) =>
                  onChangeFileOffset(file.name, progressEvent.loaded),
              })

              const newOffset = parseInt(headers['upload-offset'])
              onChangeFileOffset(file.name, newOffset)

              if (newOffset < file.size) {
                await uploadChunk(newOffset)
              }

              return newOffset
            }

            await uploadChunk(initOffset)

            getPhotoInfo(photoId)
          }
        }),
      )
    },
    [wuid, onPushFile, onUploadPhoto, selectedFilter, onChangeFileOffset, onAddPhoto, getPhotoInfo],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      'image/jpg,image/jpeg,image/png,video/avi,video/mpg,video/mpeg,video/mp4,video/mov,video/mkv',
    multiple: true,
  })

  const rootProps = getRootProps()

  const wrapperStyle = useMemo<CSSProperties>(
    () => ({
      backgroundColor: isDragActive ? 'rgba(221, 222, 253, 0.2)' : '#FFFFFF',
    }),
    [isDragActive],
  )

  return (
    <Box
      width='100%'
      height='126px'
      border='1px solid #E8E8ED'
      borderRadius='8px'
      mt='24px'
      mb='16px'
      py='16px'
      display='flex'
      flexDirection='column'
      alignItems='center'
      {...rootProps}
      style={wrapperStyle}
      cursor='pointer'
      onClick={rootProps.onClick}
    >
      <input {...getInputProps()} />

      <Box
        width='40px'
        height='40px'
        bg='#F7F7FC'
        borderRadius='28px'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <UploadIcon />
      </Box>

      <Box display='flex' mt='12px' mb='4px'>
        <Button
          width='100px'
          height='20px'
          p='0px'
          mr='4px'
        >
          <Text fontSize='16px' color='#5458F7'>
            Click to upload
          </Text>
        </Button>

        <Text fontSize='16px' lineHeight='20px' color='#73737B'>
          or drag and drop
        </Text>
      </Box>

      <Text fontSize='14px' lineHeight='18px' color='#73737B'>
        PNG, JPG, JPEG or MP4 (max. 800x400px)
      </Text>
    </Box>
  )
}

export default Uploader
