import React from 'react'
import styled from 'styled-components'

import { Box } from 'components/ui'
import { ReactComponent as SearchIcon } from './search.svg'

const Input = styled(Box)`
  font-family: Source Sans Pro;
  font-size: 16px;

  &::placeholder {
    font-family: Source Sans Pro;
    color: #73737b;
    font-size: 16px;
  }
`

const SearchInput = () => (
  <Box width="291px" position="relative">
    <Box position="absolute" left="14px" top="8px">
      <SearchIcon />
    </Box>

    <Input
      as="input"
      height="40px"
      width="100%"
      border="1px solid #E0E4EA"
      borderRadius="6px"
      pl="44px"
      placeholder="Search"
    />
  </Box>
)

export default SearchInput
