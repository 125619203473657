import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import { forEach, path, map } from 'ramda'
import validate from 'validate.js'

import { getShares } from 'store/modules/albums/selectors'
import { shareAlbum, loadAlbum } from 'store/modules/albums/actions'
import { Modal, Box, Text, Button } from 'components/ui'
import { ReactComponent as CloseIcon } from 'images/close.svg'
import { ACCESS_TYPE_VAlUES, ACCESS_TYPE_LABELS } from 'constants/albums'
import { presenceFieldConstraint } from 'constants/constraints'
import InviteForm from './Form'
import ListItem from './ListItem'

type Props = {
  albumId?: string
  isOpen: boolean
  onClose: () => void
}

export type ValuesProps = {
  accessType: {
    label: string
    value: number
  }
  guests?: {
    label: string
    value: string
  }[]
  album_id?: string
}

const ShareAlbumModal: FC<Props> = ({ albumId, isOpen, onClose }) => {
  const dispatch = useDispatch()
  const shares = useSelector(getShares(albumId))

  const onInvite = useCallback(async (data) => dispatch(shareAlbum(data)), [
    dispatch,
  ])
  const onLoadAlbum = useCallback(
    async (albumId) => dispatch(loadAlbum(albumId)),
    [dispatch]
  )

  const handleClose = () => onClose()
  const handleValidate = (values: any) =>
    validate(values, {
      ...presenceFieldConstraint('guests'),
      ...presenceFieldConstraint('accessType'),
    })
  const handleSubmit = async (values: ValuesProps) => {
    try {
      if (values.guests) {
        forEach(async (guest) => {
          await onInvite({
            album_id: albumId,
            uuid: guest.value,
            access_type: path(['accessType', 'value'], values),
          })

          await onLoadAlbum(albumId)
        }, values.guests)
      }
    } catch (error) {
      toast(error.message)
    }
  }

  const modalStyle = {
    content: { maxWidth: 452, top: '25vh', overflow: 'none' },
  }
  const initialValues: ValuesProps = {
    accessType: {
      value: ACCESS_TYPE_VAlUES[2],
      label: ACCESS_TYPE_LABELS[ACCESS_TYPE_VAlUES[2]],
    },
    album_id: albumId,
  }

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={handleClose}>
      <Box borderRadius={0} bg="white">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid"
          borderBottomColor="gray"
          p={2}
        >
          <Text fontFamily="1" fontSize={3} fontWeight={600}>
            Share Album
          </Text>

          <Button border="none" px="0px" onClick={handleClose}>
            <CloseIcon fill="#7c8494" />
          </Button>
        </Box>

        <Box p={2} borderBottom="1px solid" borderBottomColor="gray">
          <Form
            component={InviteForm}
            validate={handleValidate}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          />

          <Box mt={1}>
            {map(
              (share) => (
                <ListItem key={share.uuid} {...share} />
              ),
              shares
            )}
          </Box>
        </Box>

        <Box p={2} />
      </Box>
    </Modal>
  )
}

export default ShareAlbumModal
