import React, { FC } from 'react'

import { Box, Text } from 'components/ui'

const Title: FC = ({ children }) => (
  <Box bg="#F7F7FC" pl="20px" height="26px" display="flex" alignItems="center">
    <Text
      color="#73737B"
      fontSize="14px"
      fontWeight={600}
      isUppercase
      fontFamily="1"
    >
      {children}
    </Text>
  </Box>
)

export default Title
