import React, { FC, useState, useCallback, useMemo } from 'react'
import { slice } from 'ramda'

import { getColors } from 'utils/colors'
import Select from '../Select'
import Box from '../Box'
import Modal from './Modal'
import Icon from './Icon'

type Props = {
  name: string
  placeholder?: string
  label?: string
  value?: {
    label: string
    value: string
  }
  onSelectChange: (...args: any) => void
}

const ColorSelector: FC<Props> = ({
  value,
  name,
  placeholder,
  label,
  onSelectChange,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [page, setPage] = useState(1)
  const options = useMemo(getColors, [])

  const handleClick = useCallback(() => setModalIsOpen(true), [])
  const handleClose = useCallback(() => setModalIsOpen(false), [])
  const handleLoadMore = useCallback(() => setPage(page + 1), [page])
  const handleSelect = useCallback(
    (value?: { label: string; value: string }) => onSelectChange(value),
    [onSelectChange]
  )

  return (
    <>
      <Modal
        options={slice(0, page * 9, options)}
        isOpen={modalIsOpen}
        onClose={handleClose}
        onLoadMore={handleLoadMore}
        onSelect={handleSelect}
      />

      <Box position="relative">
        <Icon value={value?.value} />

        <Select
          value={value}
          name={name}
          placeholder={placeholder}
          label={label}
          menuIsOpen={false}
          disableMobile
          onChange={onSelectChange}
          onClick={handleClick}
        />
      </Box>
    </>
  )
}

export default ColorSelector
