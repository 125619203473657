import { Reducer } from 'redux'
import {
  LOAD_MENU,
  ADD_MENU,
  DELETE_MENU,
  LOAD_MENU_GROUPS,
  ADD_MENU_GROUP,
  UPDATE_MENU_GROUP,
  ADD_MENU_ITEM,
  DELETE_MENU_GROUP,
  UPDATE_MENU_ITEM,
  GROUP_ITEMS_ADD,
} from './actionTypes'

import {
  Menus,
  Menu,
  MenuGroups,
  MenuGroupItem,
} from '../../../routes/FoodMenu/types_interfaces/storObjects'

export const initialState = { menus: [], menu_groups: [], groupItems: [] }

export type State = {
  menus: Menus;
  menu_groups: MenuGroups;
  groupItems: any;
}

const foodMenuReducer: Reducer = (state: State = initialState, action) => {
  switch (action.type) {
    case LOAD_MENU.FULFILLED:

      return { ...state, menus: action?.payload?.payload?.menus || state.menus }
    case LOAD_MENU_GROUPS.FULFILLED:

      return { ...state, menu_groups: action?.payload?.payload?.menu_groups || state.menu_groups }
    case DELETE_MENU.FULFILLED:
      const menusWithoutDeletedMenu = state.menus.filter((menu: Menu) => menu.menu_id !== action.payload.data.menu_id)
      return { ...state, menus: menusWithoutDeletedMenu }
    case ADD_MENU_GROUP.FULFILLED:

      return {
        ...state,
        menu_groups: [
          ...state.menu_groups,
          action.payload.payload.menu_group,
        ],
      }
    case DELETE_MENU_GROUP.FULFILLED:
      const deleteGroupIndex = state.menu_groups.findIndex((item: MenuGroupItem) => item.menugroup_id === action.payload.data.menugroup_id)
      const menu_groups = [...state.menu_groups]
      menu_groups.splice(deleteGroupIndex, 1)

      return { ...state, menu_groups }
    case ADD_MENU.FULFILLED:

      return {
        ...state,
        menus: [
          ...state.menus,
          action?.payload?.payload?.menu,
        ],
      }
    case ADD_MENU_ITEM.FULFILLED:
      const menuIndex = state.menus.findIndex((menu: Menu) => menu.menu_id === action.payload.payload.menu.menu_id)
      const menus = [...state.menus]
      menus.splice(menuIndex, 1, action.payload.payload.menu)

      return { ...state, menus }
    case UPDATE_MENU_ITEM.FULFILLED:
      const editMenuIndex = state.menus.findIndex((menu: Menu) => menu.menu_id === action.payload.payload.menu.menu_id)
      const editMenus = [...state.menus]
      editMenus.splice(editMenuIndex, 1, action.payload.payload.menu)
      return { ...state, menus: editMenus }
    case GROUP_ITEMS_ADD:

      return { ...state, groupItems: action.payload }
    case UPDATE_MENU_GROUP.FULFILLED:
      const updateIndex = state.menu_groups.findIndex((item: MenuGroupItem) => item.menugroup_id === action.payload.payload.menu_group.menugroup_id)
      const updateGroups = [...state.menu_groups]
      updateGroups.splice(updateIndex, 1, action.payload.payload.menu_group)
      return { ...state, menu_groups: updateGroups }
    default:
      return state
  }
}

export default foodMenuReducer