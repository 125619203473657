import React, { ChangeEvent } from 'react'

import { Box, Input } from 'components/ui'
import { ReactComponent as Icon } from './search.svg'

type Props = {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void
}

const SearchInput = ({ onSearch }: Props) => (
  <Box position="relative">
    <Box position="absolute" left="16px" top="8px">
      <Icon />
    </Box>

    <Input
      placeholder="Search"
      border="2px solid #E8E8ED"
      borderRadius="10px"
      height="40px"
      px="0px"
      pl="40px"
      onChange={onSearch}
    />
  </Box>
)

export default SearchInput
