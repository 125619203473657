import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, Button, Text } from 'components/ui'
import styled from 'styled-components'

// Icons
import { ReactComponent as CheckIcon } from 'images/check-blue.svg'
import { ReactComponent as FilterIcon } from 'images/filter-lines.svg'

type Props = {
  menuItems?: { id: string, name: string }[],
  handleClick?: Function | undefined,
  event?: any,
  selectedFilter?: string,
}

export const main = {
  position: 'absolute',
  display: 'inline-block',
  height: 0,
}

export const dropdownContent = {
  position: 'relative',
  zIndex: 3,
  background: '#FFFFFF',
  border: '1px solid #E8E8ED',
  borderRadius: '8px',
  width: '156px',
  top: '14px',
}

export const textStyle = {
  fontFamily: 'Source Sans Pro',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  color: '#414042',
}

export const deleteText = {
  fontFamily: 'Source Sans Pro',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  color: '#F44343',
}

export const MenuItem = styled(Box)`
  height: 40px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #F7F7FC;
  }

  .addToCalendar {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #414042;
    text-decoration: unset;
  }
`

export const DeleteItem = styled(Box)`
  height: 40px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #F7F7FC;
  }
`

const ViewAll: FC<Props> = ({ menuItems, handleClick, selectedFilter }) => {
  const [isShowMenu, setIsShowMenu] = useState(false)

  const ref = useRef()

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (!ref?.current?.contains(event.target)) {
        setIsShowMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
  }, [ref])

  return (
    <Box>
      <Button
        border='1px solid #CBCBD3'
        borderRadius='8px !important'
        height='40px'
        bg='transparent'
        color='#353B60'
        fontWeight='600'
        fontSize='16px'
        fontFamily='Source Sans Pro !important'
        padding='10px 16px !important'
        cursor='pointer'
        onClick={() => setIsShowMenu(!isShowMenu)}
      >
        <Box mr={8}>
          <FilterIcon />
        </Box>
        {selectedFilter}
      </Button>
      <Box style={main} ref={ref}>
        {isShowMenu && (
          <Box style={dropdownContent}>
            {menuItems?.map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  onClick={() => handleClick(item.id)}
                  style={{
                    borderBottom: item.id === '0' ? '1px solid #E8E8ED' : '',
                    borderTopLeftRadius: item.id === '0' ? '8px' : '0',
                    borderTopRightRadius: item.id === '0' ? '8px' : '0',
                    borderBottomLeftRadius: item.id === '2' ? '8px' : '0',
                    borderBottomRightRadius: item.id === '2' ? '8px' : '0',
                    backgroundColor: selectedFilter === item.name ? '#F7F7FC' : '',
                  }}
                >
                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box>
                      <Text
                        style={textStyle}
                      >
                        {item.name}
                      </Text>
                    </Box>
                    {selectedFilter === item.name && (
                      <Box>
                        <CheckIcon />
                      </Box>
                    )}
                  </Box>
                </MenuItem>
              )
            })}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ViewAll