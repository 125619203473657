import React, { FC, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { toast } from 'react-toastify'

import { Button, Box, Text, LoadingDots } from 'components/ui'
import { removePost, pinPost } from 'store/modules/posts/actions'
import { ReactComponent as ChevronIcon } from 'images/chevron-down.svg'
import { ReactComponent as DeleteIcon } from 'images/trash-2.svg'
import { ReactComponent as PinIcon } from 'images/pin-outline.svg'
import { ReactComponent as UnpinIcon } from 'images/pin-off-outline.svg'

type Props = {
  postId: string
  isPinned: boolean
}

const MenuButton: FC<Props> = ({ postId, isPinned }) => {
  const dispatch = useDispatch()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [isPinning, setIsPinning] = useState(false)

  const onRemovePost = useCallback(async () => dispatch(removePost(postId)), [
    postId,
    dispatch,
  ])
  const onPinPost = useCallback(
    async () => dispatch(pinPost(postId, !isPinned)),
    [dispatch, postId, isPinned]
  )

  const handleClickButton = useCallback(() => setMenuIsOpen(!menuIsOpen), [
    menuIsOpen,
  ])
  const handleClickOutside = useCallback(() => setMenuIsOpen(false), [])
  const handleClickRemoveButton = useCallback(async () => {
    setIsRemoving(true)

    try {
      await onRemovePost()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsRemoving(false)
    }
  }, [onRemovePost])
  const handleClickPinButton = useCallback(async () => {
    setIsPinning(true)

    try {
      await onPinPost()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsPinning(false)
    }
  }, [onPinPost])

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Box position="relative">
        <Button
          border="1px solid"
          borderColor="gray"
          borderRadius={0}
          p="6px"
          height="36px"
          onClick={handleClickButton}
        >
          <ChevronIcon fill="#7c8494" />
        </Button>

        {menuIsOpen && (
          <Box
            position="absolute"
            right="0px"
            bg="white"
            borderRadius="4px"
            border="1px solid"
            borderColor="gray"
            boxShadow={0}
            mt={0}
            py={0}
            width="196px"
            zIndex={1}
          >
            <Button
              px={1}
              py="10px"
              disabled={isRemoving}
              onClick={handleClickRemoveButton}
            >
              <Box mr={1} mt="-4px">
                <DeleteIcon stroke="#8991a0" width="24px" height="24px" />
              </Box>

              <Text fontFamily="1">Delete Post</Text>

              {isRemoving && (
                <Box ml={0}>
                  <LoadingDots bg="primary" />
                </Box>
              )}
            </Button>

            <Button
              px={1}
              py="10px"
              disabled={isPinning}
              onClick={handleClickPinButton}
            >
              <Box mr={1}>
                {isPinned ? (
                  <UnpinIcon fill="#8991a0" width="24px" height="24px" />
                ) : (
                  <PinIcon fill="#8991a0" width="24px" height="24px" />
                )}
              </Box>

              <Text fontFamily="1">{isPinned ? 'Unpin Post' : 'Pin Post'}</Text>

              {isPinning && (
                <Box ml={0}>
                  <LoadingDots bg="primary" />
                </Box>
              )}
            </Button>
          </Box>
        )}
      </Box>
    </OutsideClickHandler>
  )
}

export default MenuButton
