import React, { MouseEvent, useMemo } from 'react'

import { Box, Text, Button } from 'components/ui'

const LABELS: { [label: string]: string } = {
  top: 'Top',
  bottom: 'Bottom',
  left: 'Left',
  right: 'Right',
  around: 'Around',
  arcSide: 'Arc Side',
  flatSide: 'Flat Side',
}

type Props = {
  label: string
  value: number
  onClickButton: (e: MouseEvent<HTMLButtonElement>) => void
}

const ChairsField = ({ label, value, onClickButton }: Props) => {
  const color = useMemo(() => (value < 1 ? '#E8E8ED' : '#5458F7'), [value])

  return (
    <Box display="flex" flexDirection="column" mb="16px">
      <Text fontSize="14px" lineHeight="18px" color="#73737B">
        {LABELS[label]}
      </Text>

      <Box
        mt="4px"
        height="40px"
        width="104px"
        border="2px solid #E8E8ED"
        borderRadius="6px"
        px="10px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          as="button"
          name="dec"
          width="20px"
          height="20px"
          borderRadius="50%"
          border="1.5px solid"
          borderColor={color}
          fontSize="18px"
          lineHeight="14px"
          alignItems="start"
          color={color}
          p="0px"
          value={value}
          onClick={onClickButton}
        >
          -
        </Button>

        <Text
          fontWeight={600}
          lineHeight="25px"
          fontSize="20px"
          color="#353B60"
        >
          {value}
        </Text>

        <Button
          as="button"
          name="inc"
          width="20px"
          height="20px"
          borderRadius="50%"
          bg="#5458F7"
          color="white"
          fontSize="18px"
          lineHeight="18px"
          p="0px"
          value={value}
          onClick={onClickButton}
        >
          +
        </Button>
      </Box>
    </Box>
  )
}

export default ChairsField
