import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  MouseEvent,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { toast } from 'react-toastify'
import ShareModal from './ShareModal'
import PhotosAlbumsTab from './Filter/PhotosAlbumsTab'

// Store tools
import { loadAlbums, loadPhotos, loadAlbumPhotos } from 'store/modules/albums/actions'
import {
  getAlbums,
  selectPhotosByWuid,
  selectLoadedWuids,
  getAlbumPhotos,
} from 'store/modules/albums/selectors'
import { selectFiles } from 'store/modules/photos/selectors'

// Utils
import { moveFavoritesToHead } from 'utils/albums'
import { AlbumCardStyle, MediasFilter, AlbumsFilter } from './utils'

// Icons
import { ReactComponent as PlusIcon } from 'images/plus.svg'
import { ReactComponent as ExportIcon } from 'images/export.svg'
import { ReactComponent as RemoveIcon } from 'images/remove.svg'

// Components
import {
  AlbumCard,
  CreateModal,
  RemoveModal,
  UpdateModal,
  ExportModal,
  RemovePhotosModal,
  PhotoViewer,
  UploadMedia,
  PhotoCard,
} from 'components/Albums'
import { Box, Text, Loading, Button } from 'components/ui'
import styled from 'styled-components'
import Tabs from 'components/GuestDetails/FormComponents/Tabs'

// Buttons
import ViewAll from './Buttons/ViewAll'

const ExportRemoveButton = styled(Button)`
  color: #353b60;

  &:disabled {
    background-color: #fff !important;
    color: #e8e8ed;
    border-color: #e8e8ed;
  }
`

const Albums: FC<RouteComponentProps<{ wuid: string }>> = ({
                                                             match,
                                                           }) => {
  const dispatch = useDispatch()

  const loadedWuids = useSelector(selectLoadedWuids)
  const photos = useSelector(selectPhotosByWuid(match.params.wuid))

  const albums = moveFavoritesToHead(useSelector(getAlbums))
  const albumPhotos = useSelector(getAlbumPhotos)
  const files = useSelector(selectFiles)

  const [, setIsLoadingAlbums] = useState(false)
  const [, setIsLoadingPhotos] = useState(false)
  const [isLoadingMorePhotos, setIsLoadingMorePhotos] = useState(false)
  const [modalIsOpenFor, setModalIsOpenFor] = useState<string>()
  const [activeAlbumId, setActiveAlbumId] = useState<string>()
  const [selectedPhotoIds, setSelectedPhotoIds] = useState<string[]>([])
  const [mediaType, setMediaType] = useState<string>('2')
  const [defaultPhotoId, setDefaultPhotoId] = useState<string>()
  const [selectedFilter, setSelectedFilter] = useState<string>('View all')
  const [filteredAlbumPhotos, setFilteredAlbumPhotos] = useState(photos)
  const [filterItems, setFilterItems] = useState<any[]>()
  const [isAlbumRemoved, setIsAlbumRemoved] = useState<boolean>()
  const [isAlbumAdded, setIsAlbumAdded] = useState<boolean>()
  const [isAlbumRenamed, setIsAlbumRenamed] = useState<boolean>()
  const [isSetFavorite, setIsSetFavorite] = useState<boolean>()
  const [isMore, setIsMore] = useState<boolean>(true)
  const [filteredAlbums, setFilteredAlbums] = useState<any[]>(albums)
  const [albumType, setAlbumType] = useState<string>('all albums')

  const pagination = useMemo(() => loadedWuids[match.params.wuid], [
    loadedWuids,
    match.params.wuid,
  ])

  const isLoaded = !!pagination

  const isAllPhotosSelected = useMemo(
    () => photos.every((photo) => selectedPhotoIds.includes(photo.photo_id)),
    [photos, selectedPhotoIds],
  )

  useEffect(() => {
    setFilteredAlbums(albums)
  }, [isSetFavorite])

  useEffect(() => {
    setFilteredAlbums(albums)
  }, [albums.length])

  useEffect(() => {
    setFilteredAlbumPhotos(photos)
  }, [photos.length])

  useEffect(() => {
    files?.map((file: any) => {
      return (
        file.photo_id && filteredAlbumPhotos?.push(file)
      )
    })
  }, [files, filteredAlbumPhotos])

  useEffect(() => {
    const allAlbums = [
      { id: '0', name: 'View all' },
    ]

    albums?.map((album) => {
      return (
        allAlbums.push({ id: album.album_id, name: album.album_title })
      )
    })

    setFilterItems(allAlbums)
  }, [isAlbumRemoved, isAlbumAdded, isAlbumRenamed, albums.length])

  const onLoad = useCallback(async () => dispatch(loadAlbums()), [dispatch])
  const onLoadAlbumPhotos = useCallback(async (data) => dispatch(loadAlbumPhotos(data)), [dispatch])

  const handleAlbumPhotos = (albumId: string) => {
    const data = {
      album_id: albumId,
      wuid: match.params.wuid,
    }

    onLoadAlbumPhotos(data)
  }

  const handleOpenModal = useCallback(
    (modalName, albumId?: string) => () => {
      setModalIsOpenFor(modalName)
      setActiveAlbumId(albumId)

      if (albumId) {
        if (albumPhotos.length > 0) {
          const albumsPhotos = albumPhotos?.map((photo) => photo.photo_id)
          setSelectedPhotoIds(albumsPhotos)
        }
      }
    },
    [albumPhotos],
  )

  const handleClickItem = useCallback(
    (albumId, albumName) => {
      setSelectedPhotoIds([])
      setActiveAlbumId(albumId)
      setMediaType('2')
      setIsMore(true)

      handleAlbumPhotos(albumId)
      setSelectedFilter(albumName)
    },
    [handleAlbumPhotos],
  )

  useEffect(() => {
    if (albumPhotos.length > 0 && selectedFilter !== 'View all' && isMore) {
      setFilteredAlbumPhotos(albumPhotos)
    } else if (albumPhotos.length === 0 && selectedFilter !== 'View all' && isMore) {
      setFilteredAlbumPhotos([])
    } else if (selectedFilter === 'View all') {
      setFilteredAlbumPhotos(photos)
    }
  }, [albumPhotos, isMore, selectedFilter, photos.length])

  useEffect(() => {
    if (isSetFavorite) {
      const FavAlbum = albums.find((album) => album.album_title === 'Favorites')

      if (FavAlbum) {
        handleAlbumPhotos(FavAlbum.album_id)

        const sortedAlbumPhotos = albumPhotos.sort(function(a, b) {
          return a.insert_date - b.insert_date
        })

        FavAlbum.cover_image = {
          id: sortedAlbumPhotos[sortedAlbumPhotos.length - 1]?.photo_id,
          url: sortedAlbumPhotos[sortedAlbumPhotos.length - 1]?.original,
        }
      }
    }

  }, [albums.length, albumPhotos.length, isSetFavorite])

  const handleCloseModal = () => {
    setModalIsOpenFor(undefined)
    setActiveAlbumId(undefined)
  }

  const handleLoad = useCallback(async () => {
    setIsLoadingAlbums(true)

    try {
      await onLoad()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoadingAlbums(false)
    }
  }, [onLoad])

  const onLoadPhotos = useCallback(
    async (pageNum: number) => dispatch(loadPhotos(pageNum)),
    [dispatch],
  )

  const handleLoadPhotos = useCallback(async () => {
    try {
      setIsLoadingPhotos(true)

      await onLoadPhotos(1)
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoadingPhotos(false)
    }
  }, [onLoadPhotos])

  const handleLoadMorePhotos = useCallback(async () => {
    if (!pagination || Number.parseInt(pagination.total) <= pagination.start)
      return

    try {
      setIsLoadingMorePhotos(true)
      const pageNum = Math.ceil(pagination.start / pagination.length) + 2

      await onLoadPhotos(pageNum)
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoadingMorePhotos(false)
    }
  }, [onLoadPhotos, pagination])

  const handleScroll = useCallback(
    (e) => {
      if (
        e.target.scrollTop + e.target.offsetHeight >=
        e.target.scrollHeight - 1
      ) {
        handleLoadMorePhotos()
      }
    },
    [handleLoadMorePhotos],
  )

  const onClickTypeButton = useCallback(
    (id) => {
      setSelectedPhotoIds([])
      setActiveAlbumId(id)

      if (id !== '0') {
        handleAlbumPhotos(id)
      }

      setMediaType('2')
      const itemsName = filterItems?.find((item) => item.id === id).name
      setSelectedFilter(itemsName)
    },
    [filterItems, handleAlbumPhotos],
  )

  const onSelectAll = useCallback(
    () =>
      setSelectedPhotoIds(
        isAllPhotosSelected ? [] : filteredAlbumPhotos.map((photo) => photo.photo_id),
      ),
    [filteredAlbumPhotos, isAllPhotosSelected],
  )

  const onSelectPhoto = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const photoId = e.currentTarget.value

    setSelectedPhotoIds((selectedPhotoIds) =>
      selectedPhotoIds.includes(photoId)
        ? selectedPhotoIds.filter(
          (selectedPhotoId) => selectedPhotoId !== photoId,
        )
        : [...selectedPhotoIds, photoId],
    )
  }, [])

  const onClickPhoto = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setDefaultPhotoId(e.currentTarget.value)
    setModalIsOpenFor('view')
  }, [])

  useEffect(() => {
    if (!isLoaded) {
      handleLoad()
      handleLoadPhotos()
    }
  }, [isLoaded, handleLoad, handleLoadPhotos])

  const handleFilterMedias = (index: string) => {
    setMediaType(index)
    if (selectedFilter === 'View all') {
      onFilterMedias(index, photos)
    } else if (selectedFilter !== 'View all') {
      onFilterMedias(index, albumPhotos)
    }
  }

  const onFilterMedias = (index: string, medias: any[]) => {
    switch (index) {
      case '0':
        return setFilteredAlbumPhotos(medias.filter((photo) => photo.media_type === '0'))
      case '1':
        return setFilteredAlbumPhotos(medias.filter((photo) => photo.media_type === '1'))
      default:
        return setFilteredAlbumPhotos(medias)
    }
  }

  const handleFilterAlbum = useCallback((filterName: string) => {
    setAlbumType(filterName)

    switch (filterName) {
      case 'public':
        return setFilteredAlbums(albums?.filter((album) => album.album_type === '2'))
      case 'shared':
        return setFilteredAlbums(albums?.filter((album) => album.album_type === '1'))
      case 'private':
        return setFilteredAlbums(albums?.filter((album) => album.album_type === '0'))
      default:
        return setFilteredAlbums(albums)
    }
  }, [isAlbumAdded, isAlbumRemoved, isAlbumRenamed, albums.length])

  return (
    <Box
      width='100%'
      borderRadius='10px'
      bg='white'
      onScroll={handleScroll}
    >
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Box minWidth='392px' maxWidth='392px' height='100%' padding='16px'>
          <Box width='100%' display='flex' justifyContent='space-between' alignItems='center' marginBottom='32px'>
            <Box>
              <Text
                style={{
                  fontFamily: 'Source Sans Pro',
                  fontWeight: 700,
                  fontSize: '32px',
                  lineHeight: '40px',
                  color: '#353B60',
                }}
              >
                Moments
              </Text>
            </Box>
            <Box>
              <Button
                bg='#5458F7'
                borderRadius='8px'
                padding='0px 20px 0px 16px'
                height='40px'
                onClick={handleOpenModal('create')}
              >
                <Box mr='8px'>
                  <PlusIcon fill='currentColor' />
                </Box>

                <Text
                  fontWeight={600}
                  fontSize='16px'
                  lineHeight='18px'
                  color='white'
                >
                  Album
                </Text>
              </Button>
            </Box>
          </Box>

          <Tabs
            handleChange={handleFilterAlbum}
            options={AlbumsFilter}
          />

          <AlbumCardStyle>
            {filteredAlbums.length > 0 ? (
              filteredAlbums?.map((album) => (
                <AlbumCard
                  key={album.album_id}
                  {...album}
                  wuid={match.params.wuid}
                  setIsMore={setIsMore}
                  onClickItem={() => handleClickItem(album.album_id, album.album_title)}
                  onClickRemove={handleOpenModal('albumRemove', album.album_id)}
                  onClickUpdate={handleOpenModal('update', album.album_id)}
                  onClickShare={handleOpenModal('share', album.album_id)}
                  onClickExport={handleOpenModal('export', album.album_id)}
                />
              ))
            ) : (
              <Box style={{
                padding: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <Text style={{
                  fontFamily: 'Source Sans Pro',
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#353B60',
                  textTransform: 'capitalize',
                }}
                >
                  No {albumType} Albums
                </Text>
              </Box>
            )}
          </AlbumCardStyle>

        </Box>
        <Box
          minWidth='calc(100% - 392px)'
          maxWidth='calc(100% - 392px)'
          height='100%'
          padding='16px 16px 16px 28px'
          borderLeft='1px solid rgba(203, 203, 211, 0.4)'
        >
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box style={{ display: 'flex', gap: 12 }}>
              <ViewAll menuItems={filterItems} handleClick={onClickTypeButton} selectedFilter={selectedFilter} />
              <PhotosAlbumsTab options={MediasFilter} activeTab={mediaType} handleClick={handleFilterMedias} />
            </Box>
            <Box style={{ display: 'flex', gap: 12 }}>
              <Button
                bg='white'
                border='1px solid #CBCBD3'
                borderRadius='10px'
                px='16px'
                height='40px'
                onClick={onSelectAll}
              >
                <Text color='#353B60' fontWeight={600} fontSize='16px'>
                  {isAllPhotosSelected ? 'Unselect all' : 'Select all'}
                </Text>
              </Button>
              <ExportRemoveButton
                width='40px'
                height='40px'
                bg='white'
                border='1px solid #CBCBD3'
                borderRadius='10px'
                p='0px'
                disabled={!selectedPhotoIds.length}
                onClick={handleOpenModal('export')}
              >
                <ExportIcon />
              </ExportRemoveButton>

              <ExportRemoveButton
                width='40px'
                height='40px'
                bg='white'
                border='1px solid #CBCBD3'
                borderRadius='10px'
                p='0px'
                disabled={!selectedPhotoIds.length}
                onClick={handleOpenModal('remove')}
              >
                <RemoveIcon />
              </ExportRemoveButton>
              <Box
                style={{
                  borderRight: '1px solid #E8E8ED',
                }}
              />
              <UploadMedia
                isBtnBox={false}
                selectedFilter={selectedFilter}
                albumId={activeAlbumId}
              />
            </Box>

          </Box>
          <Box borderBottom='1px solid rgba(203, 203, 211, 0.4)' width='100%' marginTop='16px' />
          <Box display='flex' flexWrap='wrap' justifyContent='flex-start' marginTop='24px' width='100%'
               maxHeight='calc(100% - 84px)'>
            <Box
              style={{
                width: '100%',
                margin: '0 auto',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(max(160px, 160px), 1fr))',
                gridAutoRows: '0.1fr',
                gap: '12px',
                maxHeight: 'calc(100vh - 216px)',
                overflowY: 'auto',
                paddingBottom: '24px',
              }}
            >
              {filteredAlbumPhotos?.length > 0 && (
                filteredAlbumPhotos?.map((photo) => {
                  const isSelected = selectedPhotoIds.includes(photo.photo_id)

                  return (
                    <PhotoCard
                      photos={photos}
                      key={photo.photo_id}
                      {...photo}
                      isSelected={isSelected}
                      onSelectPhoto={onSelectPhoto}
                      onClickPhoto={onClickPhoto}
                      isSetFavorite={isSetFavorite ?? false}
                      setIsSetFavorite={setIsSetFavorite}
                    />
                  )
                })
              )}

              {isLoadingMorePhotos && (
                <Box width='200px' height='200px' m='6px' position='relative'>
                  <Loading />
                </Box>
              )}

              <UploadMedia
                selectedFilter={selectedFilter}
                isBtnBox={true}
                albumId={activeAlbumId}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {modalIsOpenFor === 'create' ? (
        <CreateModal
          isOpen={modalIsOpenFor === 'create'}
          onClose={handleCloseModal}
          isAlbumAdded={isAlbumAdded ?? false}
          setIsAlbumAdded={setIsAlbumAdded}
        />
      ) : null}

      {modalIsOpenFor === 'albumRemove' ? (
        <RemoveModal
          isOpen={modalIsOpenFor !== 'albumRemove'}
          albumId={activeAlbumId}
          onClose={handleCloseModal}
          isAlbumRemoved={isAlbumRemoved ?? false}
          setIsAlbumRemoved={setIsAlbumRemoved}
        />
      ) : null}

      {modalIsOpenFor === 'update' ? (
        <UpdateModal
          isOpen={modalIsOpenFor === 'update'}
          albumId={activeAlbumId}
          isAlbumRenamed={isAlbumRenamed ?? false}
          setIsAlbumRenamed={setIsAlbumRenamed}
          onClose={handleCloseModal}
        />
      ) : null}

      {modalIsOpenFor === 'share' ? (
        <ShareModal
          isOpen={modalIsOpenFor === 'share'}
          albumId={activeAlbumId ?? ''}
          onClose={handleCloseModal}
        />
      ) : null}

      {modalIsOpenFor === 'export' ? (
        <ExportModal
          isOpen={modalIsOpenFor === 'export'}
          photoIds={selectedPhotoIds}
          onClose={handleCloseModal}
        />
      ) : null}

      {modalIsOpenFor === 'remove' ? (
        <RemovePhotosModal
          isOpen={modalIsOpenFor === 'remove'}
          photoIds={selectedPhotoIds}
          onSuccess={() => setSelectedPhotoIds([])}
          onClose={handleCloseModal}
          filteredAlbumPhotos={filteredAlbumPhotos}
          setFilteredAlbumPhotos={setFilteredAlbumPhotos}
        />
      ) : null}

      {modalIsOpenFor === 'view' ? (
        <PhotoViewer
          isOpen={modalIsOpenFor === 'view'}
          photos={filteredAlbumPhotos}
          defaultPhotoId={defaultPhotoId}
          isFavorites={false}
          onClose={handleCloseModal}
          setFilteredAlbumPhotos={setFilteredAlbumPhotos}
        />
      ) : null}

    </Box>
  )
}

export default Albums
