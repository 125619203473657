import React, { FC } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { path } from 'ramda'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ReactComponent as CloseIcon } from 'images/close.svg'
import rotateIcon from 'components/Seating/ChartItem/rotate.svg'
import bgSrc from 'components/Seating/Grid/bg.png'

const CloseButton = styled(CloseIcon).attrs({
  width: 14,
  height: 14,
  fill: '#99a2ad',
})`
  margin-top: -6px;
  margin-right: -6px;
`

const CloseButtonComponent: FC<{ closeToast?: any }> = ({ closeToast }) => (
  <CloseButton onClick={closeToast} />
)

toast.configure({
  hideProgressBar: true,
  toastClassName: 'toast',
  className: 'toast-container',
  bodyClassName: 'toast-body',
  closeButton: <CloseButtonComponent />,
})

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: ${path(['theme', 'fonts', 0])}, sans-serif;
    background-color: #F7F7FC;
  }

  a {
    color: ${path(['theme', 'colors', 'primary'])};
  }

  strong {
    font-weight: 600;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  table {
    border-collapse: unset;
    border-spacing: unset;
  }

  th {
    text-align: inherit;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f2f0fd inset !important;
  }

  .toast {
    background-color: ${path(['theme', 'colors', 'white'])};
    padding: 14px ${path(['theme', 'space', 1])}px;
    border: 1px solid ${path(['theme', 'colors', 'gray'])};
    border-radius: ${path(['theme', 'radii', 0])}px;
    box-shadow: ${path(['theme', 'shadows', 1])};

    &-container {
      padding: 0;
      top: 110px;
      right: 30px;
    }

    &-body {
      font-family: ${path(['theme', 'fonts', 1])}, sans-serif;
      font-size: 15px;
      font-weight: normal;
      color: ${path(['theme', 'colors', 'black'])};
    }
  }

  .chrome-picker {
    background: transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  .moveable {
    &-control {
      &-box {
        --moveable-color: transparent !important;
      }
    }

    &-direction {
      width: 16px !important;
      height: 16px !important;
      border: none !important;
      border-radius: 0px !important;
      background: transparent !important;
    }

    &-nw, &-ne, &-se, &-sw {
      background: #5458F7 !important;
      width: 8px !important;
      height: 8px !important;
      border-radius: 4px !important;
      margin-top: -4px !important;
      margin-left: -4px !important;
    }


    &-rotation {
      &-control {
        background-color: transparent !important;
        background-image: url(${rotateIcon}) !important;
        width: 24px !important;
        height: 24px !important;
        border-radius: 0 !important;
        border: none !important;
        margin-left: -11px !important;
      }
    }

    &-origin {
      display: none !important;
    }
  }

  .infinite-viewer {
    width: 100%;
    height: 100%;

    &-wrapper {
      background-image: url(${bgSrc});
      background-position: center;
      position: relative !important;
      overflow: hidden !important;
    }
  }

  .viewport {
    position: absolute !important;
    z-index: 1;
  }
`

const theme = {
  fonts: ['Source Sans Pro', 'Work Sans', 'Dancing Script'],
  fontSizes: [14, 16, 20, 24, 32, 36],
  radii: [6, 8],
  space: [8, 16, 20, 24, 32],
  colors: {
    black: '#1e2027',
    fullBlack: '#000000',
    darkened: 'rgba(0, 0, 0, 0.4)',
    white: '#ffffff',
    gray: '#e3e6ee',
    darkGray: '#7c8494',
    lightGray: '#f6f7f9',
    bg: 'rgb(247, 247, 252)',
    placeholder: '#aab1c2',
    primary: '#5458f7',
    secondary: '#353b60',
    blue: '#5f53ff',
    red: '#f44343',
  },
  shadows: [
    '0 4px 13px 0 rgba(0, 0, 0, 0.05)',
    '0 4px 16px 0 rgba(0, 0, 0, 0.08)',
    '0 1px 2px 0px rgba(0, 0, 0, 0.08)',
    '0px 4px 13px rgba(0, 0, 0, 0.02)',
  ],
}

export default theme
