import React, { FC } from 'react'
import { OptionProps, OptionTypeBase } from 'react-select'
import styled, { css } from 'styled-components'
import { path, pathOr } from 'ramda'

const OptionWrapper = styled.div<{
  value: any
  isSelected: boolean
}>`
  font-family: ${path(['value'])} !important;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${pathOr('', [
        'theme',
        'colors',
        'primary',
      ])} !important;
      color: ${path(['theme', 'colors', 'white'])} !important;
    `}
`

const Option: FC<OptionProps<OptionTypeBase, false>> = ({
  innerProps,
  data,
  label,
  isSelected,
  cx,
  getStyles,
  ...props
}) => (
  <OptionWrapper
    {...innerProps}
    style={getStyles('option', props)}
    value={path(['label'], data)}
    isSelected={isSelected}
  >
    {label}
  </OptionWrapper>
)

export default Option
