import create from 'utils/createReduxPromiseActionType'

export const LOAD = create('faq/LOAD')
export const LOAD_DEFAULT_QUESTIONS = create('faq/LOAD_DEFAULT_QUESTIONS')
export const SET_FAQ_ITEMS = 'faq/SET_FAQ_ITEMS'

export const ADD_QUESTION = create('faq/ADD_QUESTION')
export const UPDATE_QUESTION = create('faq/UPDATE_QUESTION')
export const UPDATE_QUESTION_ORDER = create('faq/UPDATE_QUESTION_ORDER')
export const REMOVE_QUESTION = create('faq/REMOVE_QUESTION')

export const FAQ_LIKES = create('faq/FAQ_LIKES')
