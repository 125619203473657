import React, { FC } from 'react'

import { Wedding } from 'store/modules/wedding/reducer'
import { Box, Text } from 'components/ui'
import { getDate } from 'utils/wedding'
import { ReactComponent as AddressIcon } from 'images/map-pin.svg'
import { ReactComponent as DateIcon } from 'images/calendar.svg'

type Props = {
  location_name: Wedding['location_name']
  wedding_date: Wedding['wedding_date']
  approx_date_month: Wedding['approx_date_month']
  approx_date_year: Wedding['approx_date_year']
}

const WeddingDetailsFooter: FC<Props> = ({
  location_name,
  wedding_date,
  approx_date_month,
  approx_date_year,
}) => {
  return (
    <Box px="17px" py={2} display="flex" flexDirection="column">
      {location_name && (
        <Box display="inline-flex" alignItems="center" mb={1}>
          <Box mr={0}>
            <AddressIcon stroke="#7c8494" />
          </Box>

          <Text fontSize={1}>{location_name}</Text>
        </Box>
      )}

      <Box display="inline-flex" alignItems="center">
        <Box mr={0}>
          <DateIcon stroke="#7c8494" />
        </Box>

        <Text fontSize={1}>
          {getDate({
            wedding_date,
            approx_date_month,
            approx_date_year,
          }) || 'Date TBD'}
        </Text>
      </Box>
    </Box>
  )
}

export default WeddingDetailsFooter
