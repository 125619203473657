import { createSelector, Selector } from 'reselect'
import {
  compose,
  pathOr,
  filter,
  propEq,
  includes,
  findIndex,
  equals,
  not,
  find,
} from 'ramda'

import { State, AgendaItem, initialState } from './reducer'

const getState: Selector<{ agenda: State }, State> = pathOr(initialState, [
  'agenda',
])

export const getIsLoaded = (wuid?: string) =>
  createSelector(getState, compose(includes(wuid), pathOr([], ['loadedWuids'])))

export const getIsItemLoaded = (agendaItemId?: string) =>
  createSelector(
    getState,
    compose(
      not,
      equals(-1),
      findIndex(propEq('agenda_item_id', agendaItemId)),
      pathOr([], ['agendaItems']),
    ),
  )

export const getItems = (wuid?: string) =>
  createSelector(
    getState,
    compose(
      filter(propEq('wuid', wuid)),
      pathOr<AgendaItem[]>([], ['agendaItems']),
    ),
  )

export const getItem = (agendaItemId?: string) =>
  createSelector(
    getState,
    compose(
      find<AgendaItem>(propEq('agenda_item_id', agendaItemId)),
      pathOr<AgendaItem[]>([], ['agendaItems']),
    ),
  )
