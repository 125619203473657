import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { Box } from 'components/ui'
import { getWedding } from 'store/modules/wedding/selectors'
import { EditWeddingModal } from 'components/WeddingList'
import Header from './Header'
import Info from './Info'
import Footer from './Footer'

const WeddingDetails: FC = () => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const { wuid } = useParams<{ wuid: string }>()
  const wedding = useSelector(getWedding(wuid))

  if (!wedding) return null

  const handleOpenEditModal = () => setEditModalIsOpen(true)
  const onCloseEditModal = () => setEditModalIsOpen(false)

  return (
    <>
      <EditWeddingModal
        wuid={wuid}
        isOpen={editModalIsOpen}
        onClose={onCloseEditModal}
      />

      <Box border="1px solid" borderColor="gray" borderRadius={0}>
        <Header onEditClick={handleOpenEditModal} />

        <Info
          wedding_image={wedding.wedding_image}
          wedding_name={wedding.wedding_name}
          theme_font={wedding.theme_font}
          wedding_date={wedding.wedding_date}
          approx_date_year={wedding.approx_date_year}
          approx_date_month={wedding.approx_date_month}
        />

        <Footer
          location_name={wedding.location_name}
          wedding_date={wedding.wedding_date}
          approx_date_year={wedding.approx_date_year}
          approx_date_month={wedding.approx_date_month}
        />
      </Box>
    </>
  )
}

export default WeddingDetails
