import styled from 'styled-components'
import { path } from 'ramda'

import Box from '../Box'

const SuggestItem = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  px: '23px',
  cursor: 'pointer',
})`
  &:hover {
    background: ${path(['theme', 'colors', 'lightGray'])};
  }
`

export default SuggestItem
