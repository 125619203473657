import React, { ButtonHTMLAttributes } from 'react'
import { createGlobalStyle } from 'styled-components'

import { Button, Text } from 'components/ui'
import BybyFont from './Byby_Font.otf'

const Style = createGlobalStyle`
  @font-face {
    font-family: "Byby";
    src: local("Byby"), url(${BybyFont}) format("opentype");
    font-weight: normal;
  }
`

export const GetStartedButton = ({
  onClick,
}: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <>
    <Style />

    <Button as="button" height="53px" onClick={onClick}>
      <Text fontFamily="Byby" fontSize="32px" color="primary" lineHeight="53px">
        Let’s Get Hitched
      </Text>
    </Button>
  </>
)
