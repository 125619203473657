import React, { FC } from 'react'
import { equals } from 'ramda'

import { Room } from 'store/modules/messages/reducer'
import { Box, Text } from 'components/ui'

type Props = {
  name: Room['name']
  isOnline?: boolean
  room_type: Room['room_type']
  userlist: Room['userlist']
}

const Info: FC<Props> = ({ name, isOnline = false, room_type, userlist }) => {
  const isPrivate = equals(room_type, 2)

  return (
    <Box display="flex" alignItems="center" px={2} height="100%" pt="10px">
      <Box display="flex" alignItems="baseline">
        <Text fontWeight={600}>{name}</Text>

        <Box ml="6px">
          <Text color="darkGray">
            {isPrivate
              ? isOnline
                ? 'is online'
                : ''
              : `${userlist.length} member${
                  equals(userlist.length, 1) ? '' : 's'
                }`}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default Info
