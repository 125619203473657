import React, { FC } from 'react'
import styled from 'styled-components'
import { path } from 'ramda'

import { Guest } from 'store/modules/guests/reducer'
import { Box, Text, Avatar, Button } from 'components/ui'
import { getFullName, getImage } from 'utils/guest'
import { ReactComponent as RemoveIcon } from 'images/close.svg'

const RemoveButton = styled(Button)`
  display: none;
`

const Item = styled(Box)`
  &:hover {
    background-color: #f2f3f5;

    ${RemoveButton} {
      display: flex;
    }
  }
`

type Props = {
  admins: Guest[]
  isDisabled?: boolean
  onRemoveAdmin: (guestId: string) => void
}

const AdminsList: FC<Props> = ({
  admins = [],
  isDisabled = false,
  onRemoveAdmin,
}) => (
  <>
    <Box height="46px" display="flex" alignItems="center" pl={0}>
      <Text fontSize="15px" color="darkGray" fontWeight={600}>
        Admins
      </Text>
    </Box>

    {admins.map((admin) => {
      const fullName = getFullName([admin.first_name, admin.last_name])
      const handleRemoveAdmin = () => onRemoveAdmin(admin.id)

      return (
        <Item
          key={admin.id}
          display="flex"
          alignItems="center"
          height="48px"
          px={0}
          borderRadius={0}
        >
          <Box minWidth="32px" mr={0}>
            <Avatar
              image={getImage(path(['user', 'image'], admin))}
              name={fullName}
              fontSize={1}
              size={32}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Text>{fullName}</Text>

            <RemoveButton
              bg="transparent"
              height="20px"
              width="20px"
              p="0px"
              disabled={isDisabled}
              onClick={handleRemoveAdmin}
            >
              <RemoveIcon width={15} height={15} fill="#99a2ad" />
            </RemoveButton>
          </Box>
        </Item>
      )
    })}
  </>
)

export default AdminsList
