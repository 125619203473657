import create from 'utils/createReduxPromiseActionType'

export const LOAD_ALBUMS = create('albums/LOAD_ALBUMS')
export const LOAD_ALBUM = create('albums/LOAD_ALBUM')
export const ADD_ALBUM = create('albums/ADD_ALBUM')
export const REMOVE_ALBUM = create('albums/REMOVE_ALBUM')
export const UPDATE_ALBUM = create('albums/UPDATE_ALBUM')
export const SHARE_ALBUM = create('albums/SHARE_ALBUM')
export const INFO = create('albums/INFO')

export const PUSH_UPLOADED_PHOTO = 'albums/PUSH_UPLOADED_PHOTO'
export const LOAD_PHOTOS = create('albums/LOAD_PHOTOS')
export const ADD_PHOTO = create('albums/ADD_PHOTO')
export const REMOVE_PHOTO = create('albums/REMOVE_PHOTO')
export const PHOTO_SET_FAVORITE = create('albums/PHOTO_SET_FAVORITE')
export const LOAD_ALBUM_PHOTOS = create('albums/LOAD_ALBUM_PHOTOS')
