import React, { FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import styled from 'styled-components'
import { divide, toString } from 'ramda'

import { Box } from 'components/ui'
import { ReactComponent as EditIcon } from 'images/edit-2.svg'

const Input = styled(Box).attrs({
  as: 'input',
  border: 'none',
})<{ value: string }>`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  font-weight: bold;
  text-align: center;
  width: ${(props) =>
    parseFloat(toString(divide(props.value.length, 1.5))).toFixed(2)}em;
`

const NameInput: FC = () => {
  const renderNameInput = ({ input }: FieldRenderProps<string>) => (
    <Box display="flex" alignItems="center">
      <Input {...input} />

      <EditIcon width={20} height={20} stroke="#7c8494" />
    </Box>
  )

  return <Field name="wedding_name" render={renderNameInput} />
}

export default NameInput
