import {
  compose,
  filter,
  not,
  isEmpty,
  trim,
  join,
  path,
  pathOr,
  replace,
  find,
  propEq,
  pick,
  merge,
  has,
  is,
} from 'ramda'
import { getName } from 'country-list'

import { ROLE_LABELS } from 'constants/wedding'
import { RSVP_STATUS_OPTIONS, PLUS_ONE_COUNT_OPTIONS } from 'constants/guests'
import { Guest, List } from 'store/modules/guests/reducer'
import { transformPhoneToForm } from './phone'
import styled from 'styled-components'
import { Box } from 'components/ui'


export const DataTableBoxStyle = styled(Box)`
  margin-left: 16px;

  .boZGoU:hover {
    opacity: 1;
  }

  .boZGoU:hover span {
    opacity: 1;
  }

  .boZGoU:focus {
    opacity: 1;
  }

  .boZGoU:hover span.__rdt_custom_sort_icon__ * {
    opacity: 1;
  }

  .jpHBzI:hover {
    opacity: 1;
  }

  .jpHBzI:hover span {
    opacity: 1;
  }

  .jpHBzI:hover span.__rdt_custom_sort_icon__ * {
    opacity: 1;
  }
`

// Functions
export const getVaccineStatus = (status: string) => {
  switch (Number(status)) {
    case 0:
      return 'Unknown'
    case 1:
      return 'None'
    case 2:
      return 'Partially'
    case 3:
      return 'Fully'
    default:
      return 'Unknown'
  }
}
export const getAgeRange = (range: string) => {
  switch (Number(range)) {
    case 0:
      return '-'
    case 1:
      return 'Infant'
    case 2:
      return 'Minor'
    case 3:
      return 'Adult'
    default:
      return 'Unknown'
  }
}
export const isNeedHotel = (isNeed: string) => {
  switch (Number(isNeed)) {
    case 0:
      return 'Not Required'
    case 1:
      return 'Needs Hotel'
    default:
      return '-'
  }
}
export const getRSVPStatus = (status: string) => {
  switch (Number(status)) {
    case 0:
      return {
        text: 'No Reply',
        color: '#73737B',
        backgroundColor: '#F3F3F6',
      }
    case 1:
      return {
        text: 'Not Sure',
        color: '#FDCA40',
        backgroundColor: '#FFF9E8',
      }
    case 2:
      return {
        text: 'Accepted',
        color: '#63C7C9',
        backgroundColor: '#ECF8F8',
      }
    case 3:
      return {
        text: 'Declined',
        color: '#F44343',
        backgroundColor: '#FEE8E8',
      }
    default:
      return {
        text: '',
        color: '',
      }
  }
}

export const parseGuestForEdit = (guest: Guest, groups?: any, countries?: any, statesOpt?: any) => {
  const group = guest.list_id ? groups.find((({ id }: any) => id === guest.list_id)) : groups.find((({ id }: any) => id === '0'))
  let honorific_id = (guest.honorific_id !== '0' || !guest.honorific_id) ? titleOptionsList.find(({ value }) => value === guest.honorific_id) : null
  const country = countries.find(({ value }: any) => value === guest.country)
  const state = statesOpt?.find(({ value }: any) => value === guest.state)

  const data = {
    list_id: {
      label: group.name,
      value: group.id,
    },
    honorific_id,
    first_name: guest.first_name,
    last_name: guest.last_name,
    rsvp_status: guest.rsvp_status,
    vaccine_status: guest.vaccine_status,
    email: guest.email || '',
    phone: guest.phone,
    street: guest.street,
    city: guest.city,
    zip: guest.zip,
    country,
    state,
  }

  if (!data.email) {
    delete data.email
  }

  return data
}

export const checkUnique = (value: any, index: any, self: any) => {
  return self.indexOf(value) === index
}

export const transformToRequest = (values: object) => ({
  ...pick(
    [
      'guest_id',
      'first_name',
      'last_name',
      'email',
      'city',
      'state',
      'street',
      'zip',
      'comment',
    ],
    values,
  ),
  list_id: path(['list_id', 'value'], values),
  country: path(['country', 'value'], values),
  phone: replace(/\D/g, '', pathOr('', ['phone'], values)),
  rsvp_status: path(['rsvp_status', 'value'], values),
  max_subguests: path(['invitesCount', 'value'], values),
})

export const transformToForm = (guest = {}, lists: List[] = []) => {
  return merge(
    pick(
      [
        'first_name',
        'last_name',
        'wedding_role',
        'list_id',
        'street',
        'city',
        'state',
        'country',
        'comment',
      ],
      guest,
    ),
    {
      guest_id: path(['id'], guest),
      ...(has('wedding_role', guest)
        ? {
          role: {
            label:
              path([pathOr('', ['wedding_role'], guest)], ROLE_LABELS) ||
              path(['wedding_role'], guest),
            value: path(['wedding_role'], guest),
          },
        }
        : undefined),
      ...(has('country', guest)
        ? {
          country: {
            value: path(['country'], guest),
            label: getName(pathOr('', ['country'], guest)),
          },
        }
        : undefined),
      ...(path(['user', 'image'], guest)
        ? {
          photo: path(['user', 'image'], guest),
        }
        : undefined),
      ...(!isEmpty(path(['zip'], guest)) ? pick(['zip'], guest) : undefined),
      ...(!isEmpty(path(['email'], guest))
        ? pick(['email'], guest)
        : undefined),
      rsvp_status: !isEmpty(path(['rsvp_status'], guest))
        ? find(
          propEq('value', path(['rsvp_status'], guest)),
          RSVP_STATUS_OPTIONS,
        )
        : RSVP_STATUS_OPTIONS[0],
      phone: transformPhoneToForm(path(['phone'], guest)),
      invitesCount: find(
        propEq('value', pathOr('0', ['max_sub_guests'], guest)),
        PLUS_ONE_COUNT_OPTIONS,
      ),
      list_id: has('list_id', guest)
        ? {
          value: path(['list_id'], guest),
          label: path(
            ['name'],
            find(propEq('id', path(['list_id'], guest)), lists),
          ),
        }
        : { value: '0', label: 'Unassigned' },
    },
  )
}

export const getImageLink = (image: string | undefined) => {
  return image ? `https://hhweddingphotos.s3.amazonaws.com/avatars/original/${image}` : undefined
}

export const getImage = (images: { slice: string; link: string }[] = [], slice = 'icon'): string | undefined => {
  const image = find(propEq('slice', slice), images)

  return path(['link'], image)
}

// Constants
export const getFullName = compose(
  join(' '),
  (values: string[]) => filter(compose(not, isEmpty, trim))(values),
  (values: any[]) => filter(is(String))(values),
)

export const getAddress = compose(
  join(', '),
  (values: string[]) => filter(compose(not, isEmpty, trim))(values),
  (values: string[]) => filter(compose(not, isEmpty, trim))(values),
  (values: string[]) => filter(compose(not, isEmpty, trim))(values),
)

export const vaccineOptionsList: { title: string, value: string }[] = [
  {
    title: 'Unknown',
    value: '0',
  },
  {
    title: 'None',
    value: '1',
  },
  {
    title: 'Partially',
    value: '2',
  },
  {
    title: 'Fully',
    value: '3',
  },
]

export const rsvpOptionsList: { label: string, value: string }[] = [
  {
    label: 'Attending',
    value: '0',
  },
  {
    label: 'Not Sure',
    value: '1',
  },
  {
    label: 'Declined',
    value: '2',
  },
  {
    label: 'No Reply',
    value: '3',
  },
]

export type tabTypes = '0' | '1' | '2' | '3';

export const RSVPStatuses: { name: tabTypes, title: string }[] = [
  {
    title: 'Attending',
    name: '0',
  },
  {
    title: 'Not Sure',
    name: '1',
  },
  {
    title: 'Declined',
    name: '2',
  },
  {
    title: 'No Reply',
    name: '3',
  },
]
export const NeedsHotelOptions: { name: tabTypes, title: string }[] = [
  {
    title: 'Unknown',
    name: '0',
  },
  {
    title: 'Yes',
    name: '1',
  },
  {
    title: 'No',
    name: '2',
  },
]
export const AgeRangeOptions: { name: tabTypes, title: string }[] = [
  {
    title: 'Unknown',
    name: '0',
  },
  {
    title: 'Baby',
    name: '1',
  },
  {
    title: 'Child',
    name: '2',
  },
  {
    title: 'Adult',
    name: '3',
  },
]

export const titleOptionsList: Array<{ label: string, value: string | null }> = [
  {
    label: '',
    value: '0',
  },
  {
    label: 'Mr',
    value: '1',
  },
  {
    label: 'Ms',
    value: '2',
  },
  {
    label: 'Miss',
    value: '3',
  },
  {
    label: 'Mrs',
    value: '4',
  },
  {
    label: 'Dr.',
    value: '5',
  },
]

// Types
export type ModalCrudTypes = 'create' | 'edit' | 'delete' | ''