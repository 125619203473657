import styled from 'styled-components'

import { Box } from 'components/ui'

const Menu = styled(Box).attrs({
  position: 'absolute',
  top: '0px',
  right: '0px',
  borderRadius: 1,
  border: '1px solid',
  borderColor: 'gray',
  boxShadow: 0,
  bg: 'white',
  height: '340px',
  width: '344px',
  mt: '-336px',
  mr: '-249px',
  overflowY: 'auto',
  zIndex: 1,
})``

export default Menu
