import { REMOVE_LIST } from '../actionTypes'

export const removeList = (
  list_id?: string | number,
  destListId?: string | number
) => ({
  type: REMOVE_LIST.name,
  api: {
    url: 'guests/remove_list',
    data: { list_id, dest_list_id: destListId },
  },
})
