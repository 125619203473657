import React, { useContext } from 'react'
import { Context } from '../Context'

// Components
import ModalHeader from 'components/Modal/ModalHeader'
import { Box, Button, Modal, Text } from 'components/ui'
import { ModalContainer, ModalContent } from 'components/Modal/utils'


const RemoveModal = () => {
  const {
    isOpenRemoveModal,
    handleDelete,
    handleClickRemoveModal,
  } = useContext<any>(Context)

  return (
    <>
      <Modal isOpen={isOpenRemoveModal} onRequestClose={() => handleClickRemoveModal('close')}>
        <ModalContainer>
          <ModalHeader
            title={'Remove question'}
            onClose={() => handleClickRemoveModal('close')} />
          <ModalContent>
            <Box padding='24px'>
              <Text style={{
                fontFamily: 'Source Sans Pro',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#353B60',
              }}
              >
                Are you sure you want to delete this question ?
              </Text>
            </Box>
          </ModalContent>
          <Box padding='16px' borderRadius={0} bg='white'>
            <Box display='flex' justifyContent='flex-end'>
              <Box>
                <Button
                  onClick={() => handleClickRemoveModal('close')}
                  height='40px'
                  style={{
                    border: '1px solid #CBCBD3',
                    borderRadius: '8px',
                    color: '#5458F7',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    padding: '10px 16px',
                    fontFamily: 'Source Sans Pro',
                  }}>
                  Cancel
                </Button>
              </Box>
              <Box marginLeft='16px'>
                <Button
                  onClick={handleDelete}
                  height='40px'
                  style={{
                    backgroundColor: '#5458F7',
                    borderRadius: '8px',
                    padding: '10px 18px',
                    color: '#FFFFFF',
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    fontFamily: 'Source Sans Pro',
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default RemoveModal