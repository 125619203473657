import React, { FC, useState, MouseEvent } from 'react'

import { Box } from 'components/ui'
import { ReactComponent as CheckIcon } from 'images/check.svg'
import SelectButton from './SelectButton'

type Props = {
  image_big_icon: string
  isSelected: boolean
  onClickSelectButton: (e: any) => void
  onClickPhoto: (e: MouseEvent<HTMLDivElement>) => void
}

const Photo: FC<Props> = ({
  image_big_icon,
  isSelected,
  onClickSelectButton,
  onClickPhoto,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleMouseOver = () => setIsFocused(true)
  const handleMouseOut = () => setIsFocused(false)

  return (
    <Box
      width="100%"
      height="252px"
      bg="#f4f4f9"
      position="relative"
      p={isSelected ? '28px' : undefined}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
    >
      {!isSelected && isFocused && (
        <SelectButton bg="darkened" onClick={onClickSelectButton} />
      )}

      {isSelected && (
        <SelectButton bg="primary" onClick={onClickSelectButton}>
          <CheckIcon fill="#ffffff" width="13px" height="13px" />
        </SelectButton>
      )}

      <Box
        width="100%"
        height="100%"
        backgroundImage={`url("${image_big_icon}")`}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        cursor="pointer"
        onClick={onClickPhoto}
      />
    </Box>
  )
}

export default Photo
