import React, { MouseEvent, useMemo } from 'react'

import { Box, Text, Button, Avatar } from 'components/ui'
import { getFullName, getImage } from 'utils/guest'
import { SChartGuest } from 'store/modules/seatings/reducer'
import { ReactComponent as DeleteIcon } from './delete-guest.svg'

type Props = {
  index: number
  guest: SChartGuest | null
  onRemoveItem: (e: MouseEvent<HTMLButtonElement>) => void
}

const GuestItem = ({ index, guest, onRemoveItem }: Props) => {
  const name = useMemo(
    () => (!guest ? 'Empty' : getFullName([guest.first_name, guest.last_name])),
    [guest]
  )
  const image = useMemo(() => getImage(guest?.user?.image), [guest])

  if (guest) {
    return (
      <Box
        height="56px"
        display="flex"
        alignItems="center"
        position="relative"
        bg="white"
        border={!guest ? '2px dashed #E8E8ED' : '2px solid #E8E8ED'}
        borderRadius="8px"
        width="244px"
        pl="12px"
        pr="40px"
        mt="8px"
      >
        <Avatar
          name={name}
          image={image}
          size={36}
          bg="#353B60"
          color="#FFFFFF"
          fontSize={18}
        />

        <Box ml="12px" display="flex" flexDirection="column">
          <Text
            fontWeight={600}
            fontSize="16px"
            lineHeight="20px"
            color="#353B60"
          >
            {name}
          </Text>

          <Box mb="1px" />

          <Text fontSize="11px" lineHeight="14px" color="#A3A3AC">
            Seat {index}
          </Text>
        </Box>

        <Box
          position="absolute"
          right="10px"
          top="16px"
          display="flex"
          alignItems="center"
        >
          <Button
            as="button"
            px="0px"
            width="20px"
            height="20px"
            bg="transparent"
            value={guest.guest_id}
            onClick={onRemoveItem}
          >
            <DeleteIcon />
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      height="56px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="white"
      border="2px dashed #E8E8ED"
      borderRadius="8px"
      width="244px"
      mt="8px"
    >
      <Text fontWeight={600} fontSize="16px" color="#CBCBD3">
        Seat {index}
      </Text>
    </Box>
  )
}

export default GuestItem
