import { Reducer } from 'redux'
import { assoc, mergeAll, append } from 'ramda'

import { getConcatedData } from 'utils/messages'
import { LOGOUT } from 'store/modules/auth/actionTypes'
import { SELECT_ROOM, PUSH_DATA, PUSH_HISTORY } from './actionTypes'

const initialState = { users: [], rooms: [], messages: [], history: [] }

export type User = {
  uid: string
  image?: string
  first_name?: string
  last_name?: string
  status?: number
}
export type Room = {
  rid: string
  userlist: string[]
  room_type: number
  image?: string
  name?: string
  isOnline?: boolean
  is_typing?: number
  uid?: string
  typingUser?: User
  lastMessage?: Message
  last_ord?: number
}
export type Message = {
  gid: string
  author_uid: string
  rid: string
  n_ord: number
  author?: User
  txt: string
  insert_dt: string
}
type HistoryItem = {
  rid: string
  room_id?: string
}

export type State = {
  selectedRoomId?: string
  users: User[]
  rooms: Room[]
  messages: Message[]
  history: HistoryItem[]
}

const messagesReducer: Reducer = (state: State = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState
    case SELECT_ROOM:
      return assoc('selectedRoomId', action.payload, state)
    case PUSH_DATA:
      return mergeAll([
        state,
        { users: getConcatedData('user', action.payload, state.users) },
        { rooms: getConcatedData('room_ext', action.payload, state.rooms) },
        { messages: getConcatedData('msg', action.payload, state.messages) },
      ])
    case PUSH_HISTORY:
      return assoc('history', append(action.payload, state.history), state)
    default:
      return state
  }
}

export default messagesReducer
