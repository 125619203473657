import create from 'utils/createReduxPromiseActionType'

export const LOGOUT = 'auth/LOGOUT'
export const LOGIN = create('auth/LOGIN')
export const LOGIN_OAUTH = create('auth/LOGIN_OAUTH')
export const INFO = create('auth/INFO')
export const SIGNUP = create('auth/SIGNUP')
export const VERIFY = create('auth/VERIFY')
export const VERIFY_RESEND = create('auth/VERIFY_RESEND')
export const FORGOT_PASSWORD = create('auth/FORGOT_PASSWORD')
export const RESET_PASSWORD = create('auth/RESET_PASSWORD')
export const CHECK_STATUS = create('auth/CHECK_STATUS')
export const UPDATE_PROFILE = create('auth/UPDATE_PROFILE')

export const CHANGE_WUID = 'auth/CHANGE_WUID'
