import React from 'react'
import { ChromePicker, ColorChangeHandler } from 'react-color'

import { Box, Button } from 'components/ui'

type Props = {
  color?: string
  onChange: ColorChangeHandler
  onClose: VoidFunction
  onSave: VoidFunction
}

const Picker = ({ color, onClose, onSave, ...rest }: Props) => (
  <Box width="225px" bg="white" boxShadow="0px 8px 24px rgba(0, 0, 0, 0.15)">
    <ChromePicker color={color} disableAlpha {...rest} />

    <Box px="16px" pb="12px" display="flex">
      <Box width={1 / 2} pr={0} display="flex" justifyContent="center">
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Box>

      <Box width={1 / 2} pl={0}>
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      </Box>
    </Box>
  </Box>
)

export default Picker
