import React, { FC, useCallback } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { Box, FormLabel, FontSelector } from '../ui'

type Props = {
  name: string
  label?: string
  placeholder?: string
  value?: any
  variant?: 'modal' | 'selector'
  menuPlacement?: 'auto' | 'top' | 'bottom'
  weddingName?: string
  height?: string | number
}

const FormFontSelector: FC<Props> = ({ name, label, placeholder, ...rest }) => {
  const renderFontSelector = useCallback(
    ({ input, placeholder }: FieldRenderProps<string>) => {
      return (
        <Box>
          {label && <FormLabel>{label}</FormLabel>}

          <FontSelector {...input} placeholder={placeholder} {...rest} />
        </Box>
      )
    },
    [label, rest]
  )

  return (
    <Field
      id={name}
      name={name}
      placeholder={placeholder}
      render={renderFontSelector}
    />
  )
}

export default FormFontSelector
