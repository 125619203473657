import React, { FC, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { map, filter, hasPath, pathEq, mergeLeft } from 'ramda'
import { withTheme } from 'styled-components'
import { toast } from 'react-toastify'

import { Box, Text } from 'components/ui'
import { Select } from 'components/form'
import { getAllGuests, getGuestsIsLoaded } from 'store/modules/guests/selectors'
import { getMyId } from 'store/modules/auth/selectors'
import { list } from 'store/modules/guests/actions'
import { getFullName } from 'utils/guest'
import { Guest } from 'store/modules/guests/reducer'
import Option from './Option'

const GuestsSelector: FC<{ theme: any }> = ({ theme }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const myUuid = useSelector(getMyId)
  const guests = useSelector(getAllGuests)
  const isLoaded = useSelector(getGuestsIsLoaded)

  const guestToOption = (guest: Guest) => ({
    label: getFullName([guest.user.first_name, guest.user.last_name]),
    value: guest.user.uuid,
    user: guest.user,
  })
  const filteredGuests = filter(
    (v) => hasPath(['user', 'uuid'], v) && !pathEq(['user', 'uuid'], myUuid, v),
    guests
  )

  const handleLoadGuests = useCallback(async () => dispatch(list()), [dispatch])
  const handleLoad = useCallback(async () => {
    setIsLoading(true)

    try {
      await handleLoadGuests()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading, handleLoadGuests])

  useEffect(() => {
    if (!isLoaded && !isLoading) handleLoad()
  }, [isLoaded, isLoading, handleLoad])

  const styles = {
    indicatorsContainer: () => ({ display: 'none' }),
    control: mergeLeft({
      boxShadow: 'none',
      border: 'none',
      height: 59,
      paddingLeft: theme.space[2],
      fontFamily: theme.fonts[1],
      fontSize: theme.fontSizes[1],
    }),
    multiValue: mergeLeft({
      backgroundColor: theme.colors.white,
      borderRadius: 3,
      border: `1px solid ${theme.colors.gray}`,
      fontFamily: theme.fonts[0],
      fontSize: theme.fontSizes[0],
      height: 32,
      alignItems: 'center',
    }),
    multiValueLabel: mergeLeft({
      color: theme.colors.darkGray,
      paddingLeft: 8,
    }),
    multiValueRemove: mergeLeft({
      color: theme.colors.darkGray,
      paddingRight: 6,
      cursor: 'pointer',
      ':hover': {
        color: theme.colors.black,
      },
    }),
  }

  return (
    <Box borderBottom="1px solid" borderColor="gray" px={2} position="relative">
      <Box position="absolute" left={2} top="17px" zIndex={1}>
        <Text color="#aab1c2" fontFamily="1">
          To:
        </Text>
      </Box>

      <Select
        isClearable={false}
        name="guests"
        isLoading={isLoading}
        placeholder=""
        options={map(guestToOption, filteredGuests)}
        components={{ Option }}
        styles={styles}
      />
    </Box>
  )
}

export default withTheme(GuestsSelector)
