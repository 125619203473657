import { createSelector, Selector } from 'reselect'
import {
  path,
  pathOr,
  find,
  propEq,
  filter,
  includes,
  split,
  reject,
  isEmpty,
  sort,
  compose,
  both,
  uniqBy,
  prop,
} from 'ramda'
import { isAfter } from 'date-fns'

import { getWuid as getWuidUtil } from 'utils/router'
import { initialState, State, Photo, Album } from './reducer'

const getState: Selector<{ albums: State }, State> = pathOr(initialState, [
  'albums',
])

const getAlbumId = compose(
  pathOr('', [3]),
  reject(isEmpty),
  split('/'),
  pathOr('', ['router', 'location', 'pathname']),
)

const getWuid = compose(
  getWuidUtil,
  pathOr('', ['router', 'location', 'pathname']),
)

export const getIsAlbumLoaded = createSelector(
  getState,
  getAlbumId,
  (state, albumId) => includes(albumId, pathOr([], ['loadedAlbumIds'], state)),
)

export const getAlbums = createSelector(getState, getWuid, (state, wuid) =>
  filter<Album>(propEq('wuid', wuid), pathOr([], ['albums'], state)),
)

export const getAlbumById = (
  albumId?: string,
): Selector<{ albums: State }, Album | undefined> =>
  createSelector(
    getState,
    compose(
      (albums: Album[]) => find(propEq('album_id', albumId), albums),
      pathOr([], ['albums']),
    ),
  )

export const getAlbum = createSelector(getState, getAlbumId, (state, albumId) =>
  find(propEq('album_id', albumId), pathOr([], ['albums'], state)),
)

export const getPhotos = createSelector(
  getState,
  getAlbumId,
  compose(
    sort((a: Photo, b: Photo) =>
      isAfter(a.update_date, b.update_date) ? -1 : 1,
    ),
    uniqBy(prop('photo_id')),
    (state: State, albumId: string) =>
      filter(propEq('album_id', albumId))(pathOr([], ['photos'], state)),
  ),
)

export const getAlbumPhotos = createSelector(
  getState,
  (state) => {
    return state.albumPhotos
  },
)

export const selectPhotosByWuid = (wuid: string) =>
  createSelector(getState, (state) =>
    state.photos.filter((photo) => photo.wedding_wuid === wuid),
  )

export const selectLoadedWuids = createSelector(
  getState,
  (state) => state.loadedWuids,
)

export const getFavoritesAlbumId = createSelector(
  getState,
  getWuid,
  compose(path(['album_id']), (state: State, wuid: string | undefined) =>
    find(
      both(propEq('album_title', 'Favorites'), propEq('wuid', wuid)),
      pathOr([], ['albums'], state),
    ),
  ),
)

export const getShares = (
  albumId?: string,
): Selector<{ albums: State }, Album['shares']> =>
  createSelector(
    getState,
    getWuid,
    compose(
      pathOr([], ['shares']),
      (albums: Album[]) => find(propEq('album_id', albumId), albums),
      pathOr([], ['albums']),
    ),
  )
