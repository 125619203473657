import React, { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { FormRenderProps } from 'react-final-form'
import { renderToString } from 'react-dom/server'
import { Box, Button } from 'components/ui'
import { Input } from 'components/form'
import { ErrorAlert } from 'components/Auth'
import { RESET_PASSWORD } from 'constants/paths'
import CustonButton from 'utils/Button'
import { ReactComponent as EyeShow } from 'images/eyeShow.svg'
import { ReactComponent as EyeHidden } from 'images/eyeHidden.svg'
import './../../fonts/fontFamilies/styles.css'

const Form: FC<FormRenderProps<{ email: string; password: string }>> = ({
  submitting,
  handleSubmit,
  submitErrors,
  values,
  hasValidationErrors,
}) => {
  const { push } = useHistory<{ step?: number }>()
  const [isShown, setIsShown] = useState<boolean>(false)
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const handleClickForgotPassword = useCallback(
    () => push(RESET_PASSWORD),
    [push]
  )
  const getEyeIcon = () => {
    if (isShown) {
      return renderToString(<EyeShow />)
    } else {
      return renderToString(<EyeHidden />)
    }
  }

  const eyeIconSrc = `data:image/svg+xml;base64,${btoa(getEyeIcon())}`
  return (
    <Box as="form" width="100%" onSubmit={handleSubmit}>
      {submitErrors && <ErrorAlert messages="Invalid email or password" />}

      {(values.email === undefined || values.password === undefined) &&
        isSubmitClicked && <ErrorAlert messages="Fill in required fields" />}
      <Box mb={2}>
        <label
          style={{
            fontFamily: 'Lato',
            fontSize: '12px',
            marginBottom: '10px',
            fontWeight: 400,
            lineHeight: '18px',
            width: '29px',
            height: '18px',
            color: '#414042',
          }}
        >
          Email
        </label>
        <Input
          name="email"
          placeholder="Enter email"
          height="48px"
          hideErrorMessages
          style={{ marginTop: '4px' }}
        />
      </Box>

      <Box style={{ position: 'relative' }}>
        <label
          style={{
            fontFamily: 'Lato',
            fontSize: '12px',
            marginBottom: '20px',
            fontWeight: 400,
            lineHeight: '18px',
            width: '29px',
            height: '18px',
            color: '#414042',
          }}
        >
          Password
        </label>
        <Input
          placeholder="Enter password"
          name="password"
          type={isShown ? 'text' : 'password'}
          height="48px"
          hideErrorMessages
          style={{ marginTop: '4px' }}
        />
        <button
          type="button"
          onClick={() => setIsShown(!isShown)}
          style={{
            position: 'absolute',
            top: '70%',
            transform: 'translateY(-50%)',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            right: '2.5%',
          }}
        >
          <img
            src={eyeIconSrc}
            alt={isShown ? 'Hide password' : 'Show password'}
          />
        </button>
      </Box>

      <Box display="flex" justifyContent="end" mt="16px" mb="5px" mr="-2px">
        <Button
          border="none"
          onClick={handleClickForgotPassword}
          fontFamily="LatoBold"
          fontSize={0}
          fontWeight={700}
          lineHeight="14px"
          color="primary"
          height="16px"
          marginBottom="16px"
        >
          Forgot password?
        </Button>
      </Box>

      <CustonButton
        submitting={submitting}
        buttonAction={'Sign in'}
        setIsSubmitClicked={setIsSubmitClicked}
      />
    </Box>
  )
}

export default Form
