import TimePicker from 'react-time-picker'
import styled from 'styled-components'
import { path } from 'ramda'

import InputComponent from '../Input'

const Input = styled(InputComponent).attrs({
  as: TimePicker,
  disableClock: true,
  clearIcon: null,
  format: 'hh:mm a',
  py: '0px',
})`
  .react-time-picker__wrapper {
    border: none;
  }

  input::placeholder {
    color: ${path(['theme', 'colors', 'placeholder'])};
  }

  select {
    appearance: none;
  }

  input:focus-visible {
    outline-color: #5458F7;
  }

  select:focus-visible {
    outline-color: #5458F7;
  }
`

export default Input
