import { mergeLeft } from 'ramda'
import styled from 'styled-components'
import React, { CSSProperties, FC } from 'react'

const TabBoxStyled = styled.div`
  cursor: pointer;
  color: #353B60;
  padding: 10px 16px;
  width: 100%;
`

const TabTextStyled = styled.span`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 400;
  color: #353B60;
  white-space: nowrap;
`

const TabsContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  border: 1px solid #E8E8ED;
  border-radius: 8px;
`

const activeTabStyles = {
  border: '1px solid #5458F7',
  borderRadius: '6px',
  color: '#5458F7 !important',
  backgroundColor: '#F7F7FC',
  boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
}

type Props = {
  options: { name: string; title: string }[];
  styles?: {
    container?: CSSProperties;
    tabBox?: CSSProperties;
    tabText?: CSSProperties;
  }
  setSelectedAccessMode?: Function;
  handleClick?: Function;
  activeTab?: string
}

const TabsRound: FC<Props> = ({
                                options, styles = {}, handleClick = () => {
  }, activeTab,
                              }) => {

  return (
    <TabsContainerStyled style={styles.container || {}}>
      {
        options.map((option, index) => {
          return (
            <TabBoxStyled
              key={option.title}
              style={mergeLeft(activeTab === option.name ? activeTabStyles : {}, styles.tabBox || {})}
              onClick={() => handleClick(index)}
            >
              <TabTextStyled
                style={activeTab === option.name ? { color: '#5458F7', fontWeight: '600' } : styles.tabText}>
                {option.title}
              </TabTextStyled>
            </TabBoxStyled>
          )
        })
      }
    </TabsContainerStyled>
  )
}

export default TabsRound