import styled from 'styled-components'
import { assoc, map, pathOr, replace } from 'ramda'
import { emailConstraint, presenceFieldConstraint } from 'constants/constraints'
import validate from 'validate.js'
import { getData as getCountryList } from 'country-list'

export const SectionHeaderText = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #353B60;
`

export const textInputStyles = {
  borderWidth: '1px',
  height: '40px',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '18px',
  color: '#414042',
}

export const SubGuestName = styled.div`
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #414042;
`

export const ButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: 1px solid #E8E8ED;
  border-radius: 8px;
  padding: 8px 12px;
`

export const PlusOneButton = styled.div`
  color: #5458F7;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`

export const ButtonBoxWithIcon = styled.div`
  color: #5458F7;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

export const JoinedWeddingBox = styled.div`
  color: #5458F7;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const btnText = {
  fontFamily: 'Source Sans Pro',
  color: '#5458F7',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '20px',
}

export const joinedWeddingTxt = {
  fontFamily: 'Source Sans Pro',
  color: '#414042',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '20px',
}

export const plusOneText = {
  fontFamily: 'Source Sans Pro',
  color: '#5458F7',
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '18px',
}

export const StyledDiv = styled.div`
  max-height: calc(100% - 151px);
  height: 100%;
  overflow: hidden auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`

export const SubGuestBox = styled.div`
  border: 1px solid #E8E8ED;
  border-radius: 8px;
  background: #FFFFFF;
  padding: 16px;
  margin-bottom: 20px;
`

export const AvatarBox = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

export const EditContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px 12px 10px 10px;
  position: absolute;
  background-color: rgba(115, 115, 123, 0.6);
  z-index: 3;
  padding-bottom: 50px;
`

export const EditContainer = styled.div`
  background-color: #ffffff;
  position: relative;
  border-radius: 10px;
  max-width: 452px;
  margin-left: auto;
  height: calc(100vh - 120px);
  transition: 0.4s;
  animation-name: opening;
  animation-duration: .3s;
  animation-iteration-count: 1;
  @keyframes opening {
    0% {
      width: 0
    }
    100% {
      width: 452px
    }
  }
`

export const MainContainer = styled.div`
  max-height: 100%;
  height: 100%;
  padding: 16px;
`

// Types
export type ParamsType = {
  wuid: string;
}

export type optionType = {
  label: string;
  value: string;
}

export type ComponentProps = {
  guestForEdit: any;
  rsvpValue: any;
  handleCloseEditForm: Function;
}

export type payloadObjTypes = {
  city: string;
  country: string;
  email: string;
  first_name: string;
  last_name: string;
  list_id: string;
  phone: string;
  rsvp_status: string;
  state: string;
  street: string;
  vaccine_status: string;
  honorific_id: string;
  zip: string;
  wuid: string;
  // uuid: string;
  guest_id: string;
  is_need_hotel: string;
  age_range: string;
}

export type submitProps = {
  city: string;
  country: { value: string, label: string };
  email: string;
  first_name: string;
  last_name: string;
  list_id: { value: string, label: string };
  phone: string;
  rsvp_status: string;
  state: { value: string, label: string };
  street: string;
  vaccine_status: string;
  honorific_id: { label: string, value: string };
  zip: string;
  wuid: string;
  uuid: string;
  guest_id: string;
  is_need_hotel: string;
  age_range: string;
}

// Functions
export const convertFormToPayload = (data: submitProps): payloadObjTypes => {
  return {
    city: data.city || '',
    country: data.country ? data.country.value : '',
    email: data.email || '',
    first_name: data.first_name || '',
    last_name: data.last_name || '',
    list_id: data.list_id === null ? '0' : data.list_id.value,
    phone: data.phone ? replace(/\D/g, '', pathOr('', ['phone'], data)) : '',
    rsvp_status: data.rsvp_status || '',
    state: data.state ? data.state.value : '',
    street: data.street || '',
    vaccine_status: data.vaccine_status || '',
    zip: data.zip || '',
    honorific_id: data.honorific_id ? data.honorific_id.value : '0',
    wuid: data.wuid,
    guest_id: data.guest_id,
    is_need_hotel: data.is_need_hotel,
    age_range: data.age_range,
  }
}

export const handleValidate = (values: any) => {
  let schema = {
    ...presenceFieldConstraint('first_name'),
    ...presenceFieldConstraint('last_name'),
    ...emailConstraint('email', false),
  }

  if (values.phone) {
    schema = assoc(
      'phone',
      {
        phoneNumber: {
          presence: true,
          message: 'is invalid',
        },
      },
      schema,
    )
  }

  return validate(values, schema)
}

// Constants
export const countryOptions = map(
  (country: { code: string; name: string; }) => ({
    value: country.code,
    label: country.name,
  }),
  getCountryList(),
)
