import React, { useMemo, useState, FC } from 'react'
import { Guest } from 'store/modules/guests/reducer'
import OutsideClickHandler from 'react-outside-click-handler'

// Form Components
import Tabs from './FormComponents/Tabs'
import Information from './FormComponents/TabsComponents/Information/index'
import Header from './FormComponents/Header'
import PlusOnes from './FormComponents/TabsComponents/PlusOnes'
import MailingAddress from './FormComponents/TabsComponents/MailingAddress'

// Utils
import {
  EditContainerWrapper,
  EditContainer,
  MainContainer,
} from './utils'

const TabOptions: { name: string; title: string }[] = [
  {
    name: 'information',
    title: 'Information',
  },
  {
    name: 'plusOnes',
    title: ' Plus-Ones',
  },
  {
    name: 'mailingAddress',
    title: 'Mailing Address',
  },
]

type Props = {
  handleCloseEditForm: Function,
  guestForEdit: Guest,
}

const GuestDetails: FC<Props> = ({ handleCloseEditForm, guestForEdit }) => {
  const [selectedTab, setSelectedTab] = useState()
  const [guest] = useState({ ...guestForEdit })


  const tabsComponents = useMemo(() => {
    return [
      {
        name: 'information',
        Component: Information,
      },
      {
        name: 'plusOnes',
        Component: PlusOnes,
      },
      {
        name: 'mailingAddress',
        Component: MailingAddress,
      },
    ]
  }, [])

  return (
    <EditContainerWrapper onClick={() => handleCloseEditForm(null)}>
      <OutsideClickHandler onOutsideClick={() => handleCloseEditForm(null)}>
        <EditContainer onClick={(e) => e.stopPropagation()}>
          <MainContainer>
            <Header onClick={() => handleCloseEditForm(null)} />

            <Tabs handleChange={setSelectedTab} options={TabOptions} />
            {
              tabsComponents.map((({ name, Component }) => {
                return name === selectedTab ? (
                  <Component
                    rsvpValue={guest.rsvp_status}
                    key={name}
                    guestForEdit={guestForEdit}
                    handleCloseEditForm={handleCloseEditForm}
                  />
                ) : null
              }))
            }
          </MainContainer>
        </EditContainer>
      </OutsideClickHandler>
    </EditContainerWrapper>
  )
}

export default GuestDetails