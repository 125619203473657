import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import validate from 'validate.js'

import { updateAlbum } from 'store/modules/albums/actions'
import { getAlbumById } from 'store/modules/albums/selectors'
import { Modal, Box, Text } from 'components/ui'
import { presenceFieldConstraint } from 'constants/constraints'
import { transformToRequest } from 'utils/albums'
import UpdateAlbumForm from './Form'

type Props = {
  albumId?: string
  isOpen: boolean
  onClose: () => void
  isAlbumRenamed: boolean
  setIsAlbumRenamed: Function
}

const UpdateModal: FC<Props> = ({ albumId, isOpen, onClose, isAlbumRenamed, setIsAlbumRenamed }) => {
  const dispatch = useDispatch()
  const album = useSelector(getAlbumById(albumId))

  const onUpdateAlbum = useCallback(
    async (albumId, data) => dispatch(updateAlbum(albumId, data)),
    [dispatch],
  )

  const handleClose = () => onClose()
  const handleSubmit = async (values: object) => {
    if (album) {
      await onUpdateAlbum(album.album_id, transformToRequest(values))
      setIsAlbumRenamed(!isAlbumRenamed)
    }

    return handleClose()
  }

  const handleValidate = (values: any) =>
    validate(values, presenceFieldConstraint('name'))

  const modalStyle = { content: { maxWidth: 422, top: '40vh' } }

  if (!album) return null

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={handleClose}>
      <Box py={3} px={4} borderRadius={0} bg='white'>
        <Box mb={1}>
          <Text fontFamily='1' fontSize={3} fontWeight={600}>
            Rename Album
          </Text>
        </Box>

        <Form
          render={(props) => (
            <UpdateAlbumForm {...props} onClose={handleClose} />
          )}
          initialValues={{ name: album.album_title }}
          validate={handleValidate}
          onSubmit={handleSubmit}
        />
      </Box>
    </Modal>
  )
}

export default UpdateModal
