import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { map } from 'ramda'

import { getAllLists } from 'store/modules/guests/selectors'
import { Select } from 'components/form'

type Props = {
  styles?: object
}

const GroupSelect: FC<Props> = ({ styles }) => {
  const lists = useSelector(getAllLists)

  return (
    <Select
      name="list_id"
      label="Group"
      placeholder="Select a Group"
      styles={styles}
      options={map((list) => ({ value: list.id, label: list.name }), lists)}
    />
  )
}

export default GroupSelect
