import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
  ChangeEvent,
} from 'react'
import Moveable, { OnDragStart, OnDrag } from 'react-moveable'

import {
  Box,
  Text,
  Button,
  Input,
  FormLabel,
  Slider,
  CheckBox,
} from 'components/ui'
import { SChartItem } from 'store/modules/seatings/reducer'
import { Types } from 'routes/Seating'
import { ReactComponent as CloneIcon } from './clone.svg'
import { ReactComponent as DeleteIcon } from './delete.svg'
import { ReactComponent as CloseIcon } from './close.svg'
import { ReactComponent as VisibleIcon } from './visible.svg'
import { ReactComponent as HiddenIcon } from './hidden.svg'
import Chairs from '../Panel/Chairs'

const getChairsSideIndex = (side: string) => {
  switch (side) {
    case 'right':
    case 'flatSide':
      return 1
    case 'bottom':
      return 2
    case 'left':
      return 3
    default:
      return 0
  }
}

type Props = {
  item: SChartItem
  onChangeItem: (payload: Partial<SChartItem>, isSync: boolean) => void
  onToggleEditModal: VoidFunction
  onAddItem: (payload: Partial<SChartItem>) => void
  onRemoveItem: (itemId: SChartItem['schart_item_id']) => void
}

const EditChartModal = ({
  item,
  onChangeItem,
  onToggleEditModal,
  onAddItem,
  onRemoveItem,
}: Props) => {
  const ref = useRef(null)
  const [draggable, setDraggable] = useState(false)
  const [pos, setPos] = useState({ x: 20, y: 20 })
  const [prevData, setPrevData] = useState<SChartItem>()

  const onMouseOver = useCallback(() => setDraggable(true), [])
  const onMouseLeave = useCallback(() => setDraggable(false), [])
  const onDragStart = useCallback((e: OnDragStart) => e.set([pos.x, pos.y]), [
    pos,
  ])
  const onDrag = useCallback(({ beforeTranslate }: OnDrag) => {
    const [x, y] = beforeTranslate

    setPos({ x, y })
  }, [])

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, type } = e.currentTarget
      let value: number | string | boolean = e.currentTarget.value

      if (type === 'checkbox') {
        value = e.currentTarget.checked
      } else {
        if (name === 'rotation' || name === 'width' || name === 'height') {
          value = parseInt(value)

          if (Number.isNaN(value)) {
            value = 0
          }
        }
      }

      onChangeItem(
        {
          schart_item_id: item.schart_item_id,
          [name]: value,
        },
        true
      )
    },
    [item.schart_item_id, onChangeItem]
  )

  const onChangeChairs = useCallback(
    (field: string, value: number) => {
      const index = getChairsSideIndex(field)
      const seats = [...item.seats]

      if (value === 0) {
        seats[index] = []
      } else if (value < seats[index].length) {
        seats[index] = item.seats[index].slice(0, value)
      } else {
        seats[index] = [...seats[index], null]
      }

      onChangeItem(
        {
          schart_item_id: item.schart_item_id,
          seats,
        },
        true
      )
    },
    [item.schart_item_id, item.seats, onChangeItem]
  )

  const onToggleVisible = useCallback(
    () =>
      onChangeItem(
        {
          schart_item_id: item.schart_item_id,
          isCaptionVisible: !item.isCaptionVisible,
        },
        true
      ),
    [item.schart_item_id, item.isCaptionVisible, onChangeItem]
  )

  const onSave = useCallback(() => {
    onChangeItem(item, false)
    onToggleEditModal()
  }, [item, onChangeItem, onToggleEditModal])

  const onCancel = useCallback(() => {
    onChangeItem(prevData || {}, true)
    onToggleEditModal()
  }, [prevData, onChangeItem, onToggleEditModal])

  const onCopy = useCallback(
    () =>
      onAddItem({
        ...item,
        pos_x: item.pos_x + item.width + 70,
        pos_y: item.pos_y + 50,
        seats: item.seats.map((side) => side.map(() => null)),
      }),
    [onAddItem, item]
  )

  const onDelete = useCallback(() => {
    onRemoveItem(item.schart_item_id)
    onSave()
  }, [item.schart_item_id, onRemoveItem, onSave])

  const chairsType = useMemo(() => {
    switch (item.type) {
      case 'circle':
        return Types.CIRCLE
      case 'halfcircle':
        return Types.HALF_CIRCLE
      case 'rect':
        return Types.RECTANGLE
      default:
        return Types.SQUARE
    }
  }, [item.type])

  const style = useMemo(() => ({ left: pos.x, top: pos.y }), [pos])

  useEffect(() => {
    if (item) {
      setPrevData(item)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        ref={ref}
        width="276px"
        borderRadius="10px"
        boxShadow="-10px 10px 20px rgba(53, 59, 96, 0.25)"
        bg="white"
        position="absolute"
        zIndex={3}
        style={style}
      >
        {/* Header */}
        <Box
          width="100%"
          height="52px"
          bg="#353B60"
          borderRadius="10px 10px 0px 0px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px="16px"
          position="relative"
        >
          <Text fontWeight={600} fontSize="17px" color="white">
            Edit Table
          </Text>

          <Box display="flex" zIndex={1}>
            <Button
              p="0px"
              width="20px"
              height="20px"
              bg="transparent"
              mr="12px"
              onClick={onCopy}
            >
              <CloneIcon />
            </Button>

            <Button
              p="0px"
              width="20px"
              height="20px"
              bg="transparent"
              mr="12px"
              onClick={onDelete}
            >
              <DeleteIcon />
            </Button>

            <Button
              p="0px"
              width="20px"
              height="20px"
              bg="transparent"
              onClick={onCancel}
            >
              <CloseIcon />
            </Button>
          </Box>

          <Box
            position="absolute"
            left="0px"
            top="0px"
            right="0px"
            bottom="0px"
            cursor="move"
            zIndex={0}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
          />
        </Box>

        <Box pt="16px" px="20px">
          <FormLabel>Name</FormLabel>
          <Box position="relative" mb="12px">
            <Input
              name="caption"
              defaultValue={prevData?.caption}
              onChange={onChange}
            />

            <Button
              position="absolute"
              px="0px"
              bg="transparent"
              width="20px"
              height="20px"
              top="10px"
              right="10px"
              onClick={onToggleVisible}
            >
              {item.isCaptionVisible ? <VisibleIcon /> : <HiddenIcon />}
            </Button>
          </Box>

          <FormLabel>Rotation</FormLabel>
          <Box
            width="100%"
            height="40px"
            border="2px solid #E8E8ED"
            borderRadius="8px"
            display="flex"
            alignItems="center"
            pl="12px"
            pr="16px"
            mb="12px"
          >
            <Box
              width="31px"
              height="21px"
              mr="8px"
              display="flex"
              justifyContent="center"
            >
              <Text fontWeight={600} fontSize="16px" color="#353B60">
                {item.rotation}°
              </Text>
            </Box>

            <Slider
              variant="small"
              name="rotation"
              defaultValue={item.rotation}
              min={-180}
              max={180}
              onChange={onChange}
            />
          </Box>

          <Box display="flex" justifyContent="space-between" mb="20px">
            <Box>
              <FormLabel>
                {item.type === 'square'
                  ? 'Width/Height'
                  : item.type === 'rect'
                  ? 'Width'
                  : 'Diameter'}
              </FormLabel>
              <Input
                type="number"
                name="width"
                defaultValue={item.width}
                width="104px"
                onChange={onChange}
              />
            </Box>

            {item.type === 'rect' && (
              <Box>
                <FormLabel>Height</FormLabel>
                <Input
                  type="number"
                  name="height"
                  defaultValue={item.height}
                  width="104px"
                  onChange={onChange}
                />
              </Box>
            )}
          </Box>

          <Box display="flex" alignItems="center" mb="14px">
            <CheckBox
              name="isChairsVisible"
              variant="small"
              defaultChecked={item.isChairsVisible}
              onChange={onChange}
            />

            <Box mr="6px" />

            <Text
              fontWeight={600}
              fontSize="16px"
              lineHeight="20px"
              color="#353B60"
            >
              Show Chairs
            </Text>
          </Box>

          <Chairs
            type={chairsType}
            top={item.seats[0]?.length || 0}
            right={item.seats[1]?.length || 0}
            bottom={item.seats[2]?.length || 0}
            left={item.seats[3]?.length || 0}
            around={item.seats[0]?.length || 0}
            arcSide={item.seats[0]?.length || 0}
            flatSide={item.seats[1]?.length || 0}
            onSetValue={onChangeChairs}
          />
        </Box>

        <Box display="flex" justifyContent="end" p="16px">
          <Button
            height="40px"
            px="0px"
            mr="24px"
            fontFamily="0"
            fontWeight={600}
            fontSize="16px"
            color="#5458F7"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            width="73px"
            height="40px"
            bg="#5458F7"
            borderRadius="10px"
            color="#FFFFFF"
            fontFamily="0"
            fontWeight={600}
            fontSize="16px"
            onClick={onSave}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Box
        position="fixed"
        top="0px"
        right="0px"
        bottom="0px"
        left="0px"
        zIndex={2}
      />

      <Moveable
        target={ref}
        draggable={draggable}
        throttleDrag={0}
        onDragStart={onDragStart}
        onDrag={onDrag}
      />
    </>
  )
}

export default EditChartModal
