import { createGlobalStyle } from 'styled-components'
import { path } from 'ramda'

import { AppPreviewWrapper } from '../AppPreview'

const GlobalStyle = createGlobalStyle`
  .wizard-content {
    &-forward {
      &-enter {
        opacity: 0;
        transform: translateX(100%);
        position: absolute;
        right: ${path(['theme', 'space', 1])}px;
        left: ${path(['theme', 'space', 1])}px;

        &-active {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 300ms, transform 0.6s;
          z-index: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transform: translateX(-100%);
          transition: opacity 300ms, transform 0.6s;
        }
      }
    }

    &-backward {
      &-enter {
        opacity: 0;
        transform: translateX(-100%);
        position: absolute;
        right: ${path(['theme', 'space', 1])}px;
        left: ${path(['theme', 'space', 1])}px;

        &-active {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 300ms, transform 0.6s;
          z-index: 1;
        }
      }

      &-exit {
        opacity: 1;

        ${AppPreviewWrapper} {
          display: none;
        }

        &-active {
          opacity: 0;
          transform: translateX(100%);
          transition: opacity 300ms, transform 0.6s;
        }
      }
    }
  }
`

export default GlobalStyle
