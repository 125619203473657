import React, { FC, useMemo } from 'react'

import Box from './Box'
import Text from './Text'
import { getInitials } from 'utils/wedding'
import { hex2rgba } from 'utils/colors'

type Props = {
  image?: string
  name: string
  size?: number | string
  fontSize?: number
  color?: string
  bg?: string
  border?: string
  fontWeight?: number
}

const Avatar: FC<Props> = ({
                             image,
                             name,
                             size = 40,
                             fontWeight = 600,
                             fontSize = 4,
                             color = '#42a2ff',
                             bg,
                             border,
                           }) => {
  const backgroundColor = useMemo(() => bg || hex2rgba(color, 0.2), [bg, color])

  return (
    <Box
      width={size}
      minWidth={size}
      height={size}
      minHeight={size}
      backgroundColor={backgroundColor}
      backgroundImage={image ? `url("${image}")` : undefined}
      backgroundPosition='center'
      backgroundSize='cover'
      backgroundRepeat='no-repeat'
      borderRadius='50%'
      border={border}
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      {!image && (
        <Text color={color} fontSize={fontSize} fontWeight={fontWeight} lineHeight={1}>
          {getInitials(name)}
        </Text>
      )}
    </Box>
  )
}

export default Avatar
