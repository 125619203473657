import { FAQ_LIKES } from '../actionTypes'
import { pick } from 'ramda'

export const faqLikes = (data: object) => ({
  type: FAQ_LIKES.name,
  api: {
    url: 'faq/like',
    data: pick(['faq_item_id', 'score'], data),
  },
})
