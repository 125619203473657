import { pick } from 'ramda'

import { ADD } from '../actionTypes'

export const addPost = (data: object) => ({
  type: ADD.name,
  api: {
    url: 'posts/add',
    data: pick(
      [
        'wuid',
        'uuid',
        'title',
        'caption',
        'media',
        'agenda_item_id',
        'location_name',
        'location_address',
        'location_city',
        'location_state',
        'location_zip',
        'location_lat',
        'location_lon',
      ],
      data
    ),
  },
})
