import React, { useCallback, useMemo, MouseEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { Box, Text, Button } from 'components/ui'
import { update } from 'store/modules/wedding/actions'
import { ReactComponent as PlusIcon } from './plus.svg'
import { ReactComponent as CheckIcon } from './check.svg'

const Wrapper = styled(Box)`
  padding: 18px 22px;
  margin-top: 25px;

  @media screen and (min-height: 840px) {
    padding: 22px 24px;
    margin-top: 50px;
  }
`

const Colors = styled(Box)`
  margin-top: 16px;

  & > button {
    width: 45px;
    height: 45px;
  }

  @media screen and (min-height: 840px) {
    margin-top: 20px;

    & > button {
      width: 56px;
      height: 56px;
    }
  }
`

const DEFAULT_COLORS = ['#18CBC1', '#536FF1', '#7E52EC']

type Props = {
  type: 'secondary' | 'primary'
  color?: string
}

const ColorSelector = ({ type, color }: Props) => {
  const dispatch = useDispatch()
  const [isUpdating, setIsUpdating] = useState(false)

  const colors = useMemo(() => {
    const isDefaultColor = color && DEFAULT_COLORS.includes(color.toUpperCase())
    if (isDefaultColor) return DEFAULT_COLORS

    return [color || DEFAULT_COLORS[0], ...DEFAULT_COLORS.slice(1)]
  }, [color])

  const onUpdate = useCallback(
    async (color: string) =>
      dispatch(
        update({ [type === 'primary' ? 'theme_color' : 'theme_color2']: color })
      ),
    [dispatch, type]
  )

  const onClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      const { value } = e.currentTarget

      if (isUpdating || color?.toUpperCase() === value) return

      setIsUpdating(true)

      try {
        await onUpdate(value)

        toast('The color has been updated successfully')
      } catch (error) {
        toast(error.message)
      } finally {
        setIsUpdating(false)
      }
    },
    [color, isUpdating, onUpdate]
  )

  return (
    <Wrapper
      width="100%"
      bg="#FFFFFF"
      boxShadow="0px 12px 38px rgba(53, 59, 96, 0.19)"
      borderRadius="6px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Text
          color="#353B60"
          fontWeight="bold"
          fontSize="20px"
          lineHeight="26px"
          isCapitalize
        >
          {type} color
        </Text>

        <Button p="0px" width="20px" height="20px">
          <PlusIcon />
        </Button>
      </Box>

      <Colors
        height="80px"
        px="12px"
        border="1px solid #E8E8ED"
        boxShadow="0px 4px 13px rgba(0, 0, 0, 0.05)"
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {colors.map((value) => (
          <Button
            key={value}
            as="button"
            value={value}
            borderRadius="50%"
            bg={value}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onClick}
          >
            {value === color && <CheckIcon />}
          </Button>
        ))}
      </Colors>
    </Wrapper>
  )
}

export default ColorSelector
