import React, { FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { Button } from 'components/ui'

type Props = {
  name: string
  value?: string
  label: string
  height?: string | number
}

const WeddingWizardRadio: FC<Props> = ({ name, value, label, height }) => {
  const renderRadio = ({ input, label }: FieldRenderProps<string>) => {
    const handleClick = (e: any) => input.onChange(e.currentTarget.value)

    return (
      <Button
        border="2px solid"
        borderColor={input.checked ? 'secondary' : 'gray'}
        borderRadius={1}
        fullWidth
        py="6px"
        bg={input.checked ? 'secondary' : 'white'}
        value={input.value}
        fontFamily="1"
        fontSize="15px"
        fontWeight={input.checked ? 600 : 'normal'}
        color={input.checked ? 'white' : 'black'}
        height={height}
        onClick={handleClick}
      >
        {label}
      </Button>
    )
  }

  return (
    <Field
      name={name}
      value={value}
      label={label}
      type="radio"
      render={renderRadio}
    />
  )
}

export default WeddingWizardRadio
