import React, { FC } from 'react'

import { Box, Avatar, Text } from 'components/ui'
import { getFullName } from 'utils/guest'
import AccessTypeSelector from './AccessTypeSelector'

type Props = {
  album_id: string
  uuid: string
  first_name: string
  last_name: string
  image?: string
  contact_email?: string
  access_type: string
}

const ListItem: FC<Props> = ({
  album_id,
  uuid,
  first_name,
  last_name,
  image,
  contact_email,
  access_type,
}) => {
  const fullName = getFullName([first_name, last_name])

  return (
    <Box
      py={0}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <Box mr="12px">
          <Avatar image={image} name={fullName} />
        </Box>

        <Box display="flex" flexDirection="column">
          <Text fontWeight={600}>{fullName}</Text>

          <Text color="darkGray">{contact_email}</Text>
        </Box>
      </Box>

      <AccessTypeSelector albumId={album_id} uuid={uuid} value={access_type} />
    </Box>
  )
}

export default ListItem
