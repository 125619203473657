import React, { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import Button from '../Button'
import { ReactComponent as Icon } from './chevron.svg'

const Wrapper = styled(Button)`
  &:first-child {
    margin-right: 22px;

    & > svg {
      transform: rotate(180deg);
    }
  }
`

const Chevron = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Wrapper
    as="button"
    {...props}
    color={props.disabled ? '#B8C1CC' : '#5458F7'}
  >
    <Icon />
  </Wrapper>
)

export default Chevron
