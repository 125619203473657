import { pick } from 'ramda'

import { LOGIN_OAUTH } from '../actionTypes'

export const loginOauth = (data: object) => ({
  type: LOGIN_OAUTH.name,
  api: {
    url: 'user/login_oauth',
    data: pick(['identifier', 'ccode'], data),
  },
})
