import React, { FC } from 'react'
import styled from 'styled-components'

import { Box, Button as ButtonBase } from 'components/ui'
import { ReactComponent as CreatePostIcon } from 'images/edit.svg'
import { ReactComponent as PushMessageIcon } from 'images/push-messages.svg'

type Props = { isFirst?: boolean; isActive?: boolean }

const Button = styled(ButtonBase).attrs((props: Props) => ({
  border: '1px solid',
  borderColor: 'gray',
  borderTopLeftRadius: props.isFirst ? 0 : undefined,
  borderBottomLeftRadius: props.isFirst ? 0 : undefined,
  borderTopRightRadius: !props.isFirst ? 0 : undefined,
  borderBottomRightRadius: !props.isFirst ? 0 : undefined,
  borderRight: props.isFirst ? 'none' : undefined,
  fontSize: 1,
  fontWeight: 600,
  px: 2,
  color: props.isActive ? 'primary' : 'darkGray',
  bg: props.isActive ? 'rgba(84, 88, 247, 0.08)' : undefined,
  fontFamily: 0,
}))<Props>``

const Tabs: FC = () => (
  <Box display="inline-flex">
    <Button isFirst isActive>
      <Box mr={0}>
        <CreatePostIcon stroke="#3f40f0" height={20} width={20} />
      </Box>
      Create Post
    </Button>

    <Button>
      <Box mr={0}>
        <PushMessageIcon stroke="#3f40f0" height={20} width={20} />
      </Box>
      Push Message
    </Button>
  </Box>
)

export default Tabs
