import { isValid, format, parseISO } from 'date-fns'
// prettier-ignore
import {
  path, pathOr, compose, not, isEmpty, filter, split, map, head, join,
  toUpper, values, trim, test, slice, find, propEq
} from 'ramda'

import notFoundSrc from 'images/not-found.png'
import { SEASON_LABELS } from 'constants/wedding'

type Image = {
  slice: string
  link: string
}

export const getImage = (images: Image[], slice = 'large') => {
  const image = find(propEq('slice', slice), images) || head(images)

  return pathOr(notFoundSrc, ['link'], image)
}

type GetDateProps = {
  wedding_date: string
  approx_date_month?: number
  approx_date_year?: number
}

export const getDate = ({
  wedding_date,
  approx_date_month,
  approx_date_year,
}: GetDateProps) => {
  if (isValid(new Date(wedding_date))) {
    return format(new Date(wedding_date), 'MMMM d, yyyy')
  }

  if (
    approx_date_year &&
    approx_date_year > 0 &&
    approx_date_month &&
    approx_date_month > 0
  ) {
    return `${SEASON_LABELS[approx_date_month]} ${approx_date_year}`
  }

  return null
}

const emojiRegEx = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g

export const getInitials = compose(
  toUpper,
  join(''),
  slice(0, 2),
  map((v: string) => head(v)),
  filter((v: string) => not(isEmpty(v)) && not(test(emojiRegEx, v))),
  split(' ')
)

export const transformToForm = (values = {}): any => {
  const weddingDate = parseISO(pathOr('', ['wedding_date'], values))

  return {
    ...values,
    wedding_date: isValid(weddingDate) ? weddingDate : undefined,
    address: {
      place: path(['location_name'], values),
    },
    theme_font: path(['theme_font'], values)
      ? {
          label: path(['theme_font'], values),
          value: path(['theme_font'], values),
        }
      : undefined,
    theme_color: path(['theme_color'], values)
      ? {
          label: path(['theme_color'], values),
          value: path(['theme_color'], values),
        }
      : undefined,
  }
}

export const transformToRequest = (values = {}) => ({
  name: path(['wedding_name'], values),
  ...(path(['theme_color'], values)
    ? {
        theme_color: path(['theme_color', 'value'], values),
        theme_color2: path(['theme_color', 'value'], values),
      }
    : undefined),
  ...(path(['theme_font'], values)
    ? {
        theme_font: path(['theme_font', 'label'], values),
        theme_font_url: path(['theme_font', 'value'], values),
      }
    : undefined),
  ...(path(['wedding_date'], values)
    ? {
        wedding_date: format(
          pathOr(new Date(), ['wedding_date'], values),
          'yyyy-MM-dd'
        ),
      }
    : undefined),
  ...(path(['address', 'place'], values)
    ? {
        location_name: path(['address', 'place'], values),
        location_address: path(['address', 'address', 'address'], values),
        location_city: path(['address', 'address', 'city'], values),
        location_state: path(['address', 'address', 'state'], values),
        location_zip: path(['address', 'address', 'zip'], values),
        location_lat: path(['address', 'location', 'lat'], values),
        location_lon: path(['address', 'location', 'lng'], values),
      }
    : undefined),
})

type GetDefaultWeddingNameProps = {
  spouse1FirstName?: string
  yourSpouseFirstName?: string
}

export const getDefaultWeddingName = ({
  spouse1FirstName,
  yourSpouseFirstName,
}: GetDefaultWeddingNameProps) =>
  join(
    ' & ',
    filter((v) => !!v, [spouse1FirstName, yourSpouseFirstName])
  )

export const getFullAddress = compose(
  join(', '),
  (values: string[]) => filter((v) => !isEmpty(trim(v || '')), values),
  values,
  pathOr({}, ['address'])
)

export const initialValues = {
  theme_color: { value: '#353B60', label: '#353B60' },
  invite_type: 0,
  rsvpDeadline: new Date(),
  nav_preset: 'default',
}
