import React from 'react'

import { Box, Button, Text } from 'components/ui'
import NavIcon from '../NavIcon'
import { Values } from './AppSections'

type CustomType = {
  type: Values['type']
  label: string
  icon: string
}

export const CUSTOM_TYPES: CustomType[] = [
  { type: 101, label: 'Website', icon: 'navigation-internet' },
  { type: 102, label: 'Information Page', icon: 'navigation-info' },
  { type: 100, label: 'Folder', icon: 'navigation-folder' },
]

type Props = {
  activeType: Values['type']
  onSelectType: (type: Values['type']) => VoidFunction
}

const CustomTypes = ({ activeType, onSelectType }: Props) => {
  return (
    <Box py="20px" borderBottom="1px dashed rgba(53, 59, 96, 0.4)">
      {CUSTOM_TYPES.map(({ type, label, icon }) => (
        <Button
          key={type}
          bg={activeType === type ? '#353B60' : '#FFFFFF'}
          color={activeType === type ? '#FFFFFF' : '#353B60'}
          boxShadow="0px 4px 10px rgba(53, 59, 96, 0.1)"
          borderRadius="10px"
          height="50px"
          mt="12px"
          justifyContent="flex-start"
          pl="16px"
          fullWidth
          onClick={onSelectType(type)}
        >
          <Box mr="12px">
            <NavIcon name={icon} strokeWidth={3} />
          </Box>

          <Text fontWeight={600} fontSize="16px" color="inherit">
            {label}
          </Text>
        </Button>
      ))}
    </Box>
  )
}

export default CustomTypes
