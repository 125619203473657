import React, { FC } from 'react'
import { FormRenderProps } from 'react-final-form'

import { Box, Button } from 'components/ui'
import Selector from './Selector'
import { ValuesProps } from './Modal'

const InviteForm: FC<FormRenderProps<ValuesProps>> = ({
  handleSubmit,
  submitting,
}) => {
  return (
    <Box
      as="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      display="flex"
      alignItems="center"
    >
      <Box width="100%" mr="12px">
        <Selector />
      </Box>

      <Button type="submit" variant="primary" disabled={submitting}>
        Invite
      </Button>
    </Box>
  )
}

export default InviteForm
