import React, { FC } from 'react'
import { FormRenderProps } from 'react-final-form'
import { getData as getCountryList } from 'country-list'
import { map } from 'ramda'

import { Input, Select } from 'components/form'
import Box from '../Box'
import Button from '../Button'

type Props = { onClose: VoidFunction }

const LocationForm: FC<FormRenderProps & Props> = ({
  handleSubmit,
  onClose,
}) => {
  const countryToOption = (country: { code: string; name: string }) => ({
    value: country.code,
    label: country.name,
  })

  return (
    <Box
      as="form"
      autoComplete="off"
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}
      px="24px"
      py="20px"
      onSubmit={handleSubmit}
    >
      <Box mb={2}>
        <Input
          name="name"
          autoComplete="disabled"
          placeholder="Name"
          height="40px"
        />
      </Box>

      <Box mb={2}>
        <Input
          name="street"
          autoComplete="disabled"
          placeholder="Address"
          height="40px"
        />
      </Box>

      <Box display="flex" mb="28px">
        <Box width={1 / 2} pr="10px">
          <Box mb={2}>
            <Input
              name="city"
              autoComplete="disabled"
              placeholder="City"
              height="40px"
            />
          </Box>

          <Input
            name="postalCode"
            autoComplete="disabled"
            placeholder="Zip Code"
            height="40px"
          />
        </Box>

        <Box width={1 / 2} pl="10px">
          <Box mb={2}>
            <Input
              name="state"
              autoComplete="disabled"
              placeholder="State"
              height="40px"
            />
          </Box>

          <Select
            name="country"
            autoComplete="disabled"
            placeholder="Country"
            options={map(countryToOption, getCountryList())}
            height="40px"
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          onClick={onClose}
          color="primary"
          fontSize="16px"
          fontFamily="1"
          border="none"
          bg="transparent"
          mr="22px"
          height="40px"
        >
          Cancel
        </Button>

        <Button type="submit" variant="primary" height="40px">
          Save
        </Button>
      </Box>
    </Box>
  )
}

export default LocationForm
