import React, { FC } from 'react'
import ModalBase, { Props } from 'react-modal'
import { mergeDeepRight } from 'ramda'

ModalBase.setAppElement('#root')

const Modal: FC<Props> = ({ style = {}, ...rest }) => {
  const defaultStyle = {
    overlay: {
      zIndex: 999,
      background: 'rgba(0, 0, 0, 0.24)',
    },
    content: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      margin: 'auto',
      maxWidth: 552,
      height: 'fit-content',
      background: 'transparent',
      border: 'none',
      padding: '0 20px',
      borderRadius: 0,
      inset: 'inherit',
    },
  }

  return <ModalBase {...rest} style={mergeDeepRight(defaultStyle, style)} />
}

export default Modal
