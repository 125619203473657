import styled, { keyframes } from 'styled-components'

import { ReactComponent as Icon } from './loading.svg'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const UploadingIndicator = styled(Icon)`
  animation: ${rotate} 1.5s linear infinite;
`

export default UploadingIndicator
