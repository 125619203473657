import { LOAD_MENU } from '../../actionTypes'
import { GetMenuNeedData } from '../../../../../routes/FoodMenu/tipes_interfaces'

export const load = (data: GetMenuNeedData) => {
  return {
    type: LOAD_MENU.name,
    api: {
      url: 'menu/list',
      data: {
        ...data,
        field_list: 'all_fields',
      },
    },
  }
}