import React, { FC } from 'react'
import ReactGoogleMap from 'react-google-map'
import styled from 'styled-components'
import { path } from 'ramda'

import HeartIcon from 'images/iconfinder-love.svg'
import Box from './Box'

const Wrapper = styled(Box).attrs({
  width: '100%',
})`
  & > div {
    border-radius: ${path(['theme', 'radii', 0])}px;
  }
`

type Props = {
  googleMaps: any
  height?: string
  title?: string
  position?: {
    lat: number
    lng: number
  }
  hideMarker?: boolean
}

const GMap: FC<Props> = ({
  googleMaps,
  height = '246px',
  title,
  position,
  hideMarker = false,
}) => (
  <Wrapper height={height}>
    <ReactGoogleMap
      mapTypeControl={false}
      autoFitBounds={true}
      googleMaps={googleMaps}
      zoom={8}
      center={position}
      coordinates={[
        {
          title,
          position,
          onLoaded: (googleMaps: any, _: any, marker: any): void =>
            marker.setIcon({
              url: hideMarker ? '' : HeartIcon,
              scaledSize: new googleMaps.Size(50, 50),
            }),
        },
      ]}
      onLoaded={(_: any, map: any): void =>
        map.setOptions({ disableDefaultUI: true, gestureHandling: 'none' })
      }
    />
  </Wrapper>
)

export default GMap
