import React from 'react'
import { List } from './utils'
import { Box, Text } from 'components/ui'
import ButtonsGroup from './buttonsGroup'

import { Group } from './types_interfaces/storObjects'

interface PropType {
  group: Group;
  setModalIsOpen: any;
  setSelectGroup: any;
  updateGroupsByOrder: any
}

const ListItem = ({ group, setModalIsOpen, setSelectGroup, updateGroupsByOrder }: PropType) => {
  const { groupName, groupItems, group_id } = group

  const setSelectGroupId = () => {
    setSelectGroup(group_id)
  }

  return (
    <List>
      <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='20px'>
        <Text className='listHeading'>{groupName}</Text>
        <ButtonsGroup updateGroupsByOrder={updateGroupsByOrder} menugroup_id={group_id} setModalIsOpen={setModalIsOpen}
                      setSelectGroupId={setSelectGroupId} />
      </Box>
      <Box className='groupItems'>
        {
          groupItems.length &&
          groupItems.map((dish: any) => {
            return (
              <Box key={dish.item_id} display='flex' flexDirection='column' className='textContent'>
                <Text className='subTitle'>{dish.title}</Text>
                <Text className='title'>{dish.comment}</Text>
              </Box>
            )
          })
        }
      </Box>
    </List>
  )
}

export default ListItem