import React, { FC, FormEvent } from 'react'
import styled from 'styled-components'
import { typography } from 'styled-system'

import { Box } from 'components/ui'
import { ReactComponent as Icon } from 'images/magnify.svg'

const Input = styled(Box).attrs({
  as: 'input',
  bg: 'lightGray',
  border: 'none',
  borderRadius: 1,
  height: '32px',
  pl: 4,
  pr: 0,
  fontFamily: 1,
  fontSize: 0,
  width: '100%',
})`
  ${typography}
`

type Props = {
  onChange: (e: FormEvent<HTMLInputElement>) => void
}

const SearchInput: FC<Props> = ({ onChange }) => (
  <Box position="relative" width="100%">
    <Input placeholder="Search" onChange={onChange} />

    <Box position="absolute" top="2px" left={0}>
      <Icon stroke="#6d7589" height={16} width={16} />
    </Box>
  </Box>
)

export default SearchInput
