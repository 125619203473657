import React, { FC, useMemo, useState, useEffect } from 'react'

import { Box, Text } from 'components/ui'
import Indicator from './Indicator'

type Props = {
  password: string
}

const PasswordStrengthIndicator: FC<Props> = ({ password = '' }) => {
  const [level, setLevel] = useState(0)

  const DESCRIPTIONS = useMemo(
    () => [
      'Think of at least 8 characters',
      'Replace one or two letters 1 upper-cased letter, and 1 special character',
      `Great! This password is strong enough.`,
    ],
    []
  )
  useEffect(() => {
    if (password.length >= 8) {
      if (
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/.test(
          password
        )
      ) {
        setLevel(3) // Strong password
      } else {
        setLevel(2) // Medium password
      }
    } else if (password.length > 0) {
      setLevel(1) // Weak password
    } else {
      setLevel(0) // Empty password
    }
  }, [password])

  return (
    <Box display="flex" flexDirection="column">
      <Indicator level={level} />

      <Text
        textAlign="center"
        fontFamily="1"
        fontSize="14px"
        color="#6D7589"
        lineHeight="16px"
        isTruncate
      >
        {DESCRIPTIONS[level]}
      </Text>
    </Box>
  )
}

export default PasswordStrengthIndicator
