import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import validate from 'validate.js'
import { path, pathOr, assoc } from 'ramda'

import { addList, removeList, updateList } from 'store/modules/guests/actions'
import {  getListById } from 'store/modules/guests/selectors'
import { presenceFieldConstraint } from 'constants/constraints'

import GroupForm from './GroupForm'
import DeleteBlock from './DeleteBlock'
import { Modal, Box, Text } from 'components/ui'

type Props = {
  groupId: string|null
  isOpen: boolean
  onClose: () => void
  modalType: 'edit'|'create'|'delete'|''
}

const modalStyle = {
  content: { maxWidth: 422, top: '20vh', overflow: 'none' },
}

const GroupModal: FC<Props> = ({ groupId, isOpen, onClose, modalType }) => {
  const dispatch = useDispatch()
  const group = useSelector(getListById(groupId))

  const onUpdateList = useCallback(async (data) => dispatch(updateList(data)), [dispatch])
  const onAddList = useCallback(async (data) => dispatch(addList(data)), [dispatch])
  const onDeleteList = useCallback(async (listId) => dispatch(removeList(listId)), [dispatch])

  const handleClickOnDelete = useCallback(async () => {
    try {
      await onDeleteList(groupId)
      onClose()
    } catch (error) {
      toast(error.message)
    }
  },[groupId, onClose, onDeleteList])
  const handleSubmit = useCallback(async (values: object) => {
    try {
      switch (modalType) {
        case 'edit':
          await onUpdateList(assoc('list_id', groupId, values))
          break;
        case 'create':
          await onAddList(values)
          break;
        default: break;
      }

      onClose()
    } catch (error) {
      toast(error.message)
    }
  }, [groupId, modalType, onUpdateList, onAddList, onClose])
  const handleValidate = useCallback((values: object) => validate(values, presenceFieldConstraint('name')), [])

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={() => onClose()}>
      <Box py={3} px={4} borderRadius={0} bg="white">
        <Box mb={1}>
          <Text fontFamily="1" fontSize={3} fontWeight={600} isCapitalize>
            {modalType} Group
          </Text>
        </Box>

        {
          modalType === 'delete' ? (
            <DeleteBlock
              listId={path(['id'], group)}
              name={path(['name'], group)}
              onClose={onClose}
            />
          ) : (
            <Form
              render={(props) => (
                <GroupForm
                  {...props}
                  isEdit={!!group}
                  onClose={onClose}
                  onDelete={handleClickOnDelete}
                />
              )}
              initialValues={{ name: pathOr('', ['name'], group) }}
              validate={handleValidate}
              onSubmit={handleSubmit}
            />
          )
        }
      </Box>
    </Modal>
  )
}

export default GroupModal
