import validate from 'validate.js'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

validate.validators.presence.message = `can't be empty`

validate.validators.phoneNumber = (value?: string, options?: any) => {
  if (!value && !options.presence) return undefined

  if (!value) return options.message

  const phoneNumber = parsePhoneNumberFromString(value, 'US')
  if (!phoneNumber) return options.message

  return phoneNumber.isValid() ? undefined : options.message
}

validate.validators.hex = (value?: string, options?: any) => {
  if (!value && !options.presence) return undefined

  if (!value) return options.message

  return /^#[0-9A-F]{6}$/i.test(value) ? undefined : options.message
}

export const emailConstraint = (field = 'email', presence = true) => ({
  [field]: {
    presence,
    email: {
      message: 'should be correct',
    },
  },
})

export const passwordConstraint = (field = 'password', presence = true) => ({
  [field]: {
    presence,
    length: {
      minimum: 8,
      message: 'must be at least 8 characters',
    },
    format: {
      pattern: /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}/,
      message: 'must contain at least 1 uppercase letter and 1 special character',
    },
  },
})


export const passwordConfirmationConstraint = (
  field = 'passwordConfirmation',
  equality = 'password',
  presence = true,
) => ({
  [field]: {
    presence,
    equality,
  },
})

export const presenceFieldConstraint = (field: string, presence = true) => ({
  [field]: { presence },
})

export const timeConstraint = (field: string, presence = true) => ({
  [field]: { presence, format: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/ },
})

export const urlConstraint = (field = 'url', presence = true) => ({
  [field]: { presence, url: true },
})

export const phoneNumberConstraint = (field: string, presence = true) => ({
  [field]: {
    phoneNumber: {
      presence,
      message: 'is invalid',
    },
  },
})

export const zipConstraint = (field = 'zip', presence = false) => ({
  [field]: {
    presence,
    format: /\d{5}(-\d{4})?/,
  },
})

export const hexConstraint = (field: string, presence = true) => ({
  [field]: {
    hex: {
      presence,
      message: 'is invalid',
    },
  },
})

export const intConstraint = (field: string, greaterThan: number = 0) => ({
  [field]: {
    numericality: {
      onlyInteger: true,
      greaterThan,
    },
  },
})
