import { REMOVE } from '../actionTypes'

export const remove = (wuid?: string) => ({
  type: REMOVE.name,
  api: {
    url: 'wedding/remove',
    method: 'post',
    data: { wuid },
  },
})
