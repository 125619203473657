import React, { useMemo, CSSProperties } from 'react'
import UploadingIndicator from './UploadingIndicator'

// Components
import { Box, Text, Button } from 'components/ui'

// Icons
import { ReactComponent as VideoIcon } from './video.svg'
import { ReactComponent as PhotoIcon } from './photo.svg'
import { ReactComponent as CheckIcon } from './check.svg'
import { ReactComponent as DeleteIcon } from './delete.svg'

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

type Props = {
  name: string
  type: 'image' | 'video'
  size: number
  uploaded: number
  isError?: boolean
}

const File = ({ type, name, size, uploaded, isError }: Props) => {
  const formatedSize = useMemo(() => formatBytes(size), [size])
  const progress = useMemo(() => Math.round((uploaded / size) * 100), [
    size,
    uploaded,
  ])
  const progressFillStyle = useMemo<CSSProperties>(
    () => ({ width: progress < 100 ? `${progress}%` : 'unset' }),
    [progress],
  )

  return (
    <Box
      width='100%'
      minHeight='72px'
      maxHeight='116px'
      mb='12px'
      border='1px solid'
      borderColor={isError ? '#F44343' : '#E8E8ED'}
      borderRadius='8px'
    >
      <Box
        padding='14px'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box
          minWidth='32px'
          maxWidth='32px'
          width='100%'
          height='32px'
          bg={isError ? '#FEE8E8' : '#DDDEFD'}
          borderRadius='28px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          color={isError ? '#F44343' : '#353B60'}
        >
          {type === 'video' ? <VideoIcon /> : <PhotoIcon />}
        </Box>

        <Box marginLeft='14px' marginRight='auto' py='2px' display='flex' flexDirection='column'>
          <Text
            fontWeight={isError ? 600 : 400}
            fontSize='16px'
            lineHeight='20px'
            color={isError ? '#F44343' : '#353B60'}
            style={{ lineBreak: 'anywhere' }}
          >
            {isError ? 'Upload failed, please try again' : name}
          </Text>

          <Text fontSize='14px' lineHeight='20px' color='#73737B'>
            {isError ? name : `${formatedSize} – ${progress}% uploaded`}
          </Text>
        </Box>
        <Box marginLeft='14px'>
          {isError ? (
            <Button width='20px' height='20px' p='0px'>
              <DeleteIcon />
            </Button>
          ) : progress < 100 ? (
            <UploadingIndicator />
          ) : (
            <Box
              width='16px'
              height='16px'
              bg='#5458F7'
              borderRadius='8px'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <CheckIcon />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        style={progressFillStyle}
        bg='rgba(221, 222, 253, 0.2)'
        position='absolute'
        left='0px'
        top='0px'
        bottom='0px'
      />
    </Box>
  )
}

export default File
