import React, { FC } from 'react'

import { Box, Text } from 'components/ui'
import { ReactComponent as Logo } from 'images/logo.svg'
import Menu from './Menu'

type Props = {
  title?: string
  fontFamily?: string
  activeTab?: string
}

const WeddingListHeader: FC<Props> = ({ title, fontFamily }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    height="80px"
    bg="white"
    boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)"
    position="fixed"
    left="0px"
    right="0px"
    zIndex={2}
  >
    <Box display="flex" width="126px" ml="24px">
      <Logo height="28px" />
    </Box>

    <Box display="flex" alignItems="center" maxWidth="50%" mr="26px">
      {title && (
        <Text fontSize={4} fontWeight={600} fontFamily={fontFamily} isTruncate>
          {title}
        </Text>
      )}

      <Box mr={1} />

      <Menu />
    </Box>
  </Box>
)

export default WeddingListHeader
