import React, { FC, useEffect, useCallback, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useField } from 'react-final-form'
import throttle from 'lodash.throttle'

import { checkCode } from 'store/modules/wedding/actions'
import { Box, Input, Loading, Button } from 'components/ui'
import { ReactComponent as RefreshIcon } from 'images/refresh.svg'
import { CodeStatusIndicator, CodeStatus } from './CodeStatusIndicator'

type Props = {
  isLoading?: boolean
  errorMessage?: string
  onSetErrorMessage: (error?: string) => void
  onClickRefresh: VoidFunction
}

const CodeInput: FC<Props> = ({
  isLoading = false,
  errorMessage,
  onSetErrorMessage,
  onClickRefresh,
}) => {
  const dispatch = useDispatch()
  const [isStatusVisible, setStatusVisible] = useState(false)
  const [progress, setProgress] = useState(1)
  const { input, meta } = useField('common_code')
  const { value, onChange } = input
  const { valid } = meta

  const handleOnFocusPassword = useCallback(() => setStatusVisible(true), [])
  const handleOnBlurPassword = useCallback(() => setStatusVisible(false), [])

  const onCheckCode = useCallback(
    async (code: string) => dispatch(checkCode(code)),
    [dispatch]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCheckCode = useCallback(
    throttle(async (code: string) => {
      try {
        await onCheckCode(code)
      } catch (error) {
        onSetErrorMessage(error.message)
      }
    }, 700),
    [onCheckCode, onSetErrorMessage]
  )

  const handleChange = useCallback(
    (e) => {
      onChange(e.currentTarget.value)
      onSetErrorMessage(undefined)
    },
    [onChange, onSetErrorMessage]
  )

  const status = useMemo(
    () =>
      errorMessage
        ? CodeStatus.NOT_AVAILABLE
        : !meta.valid
        ? CodeStatus.NOT_VALID
        : CodeStatus.AVAILABLE,
    [errorMessage, meta]
  )

  useEffect(() => {
    if (valid && value.length > 0) {
      handleCheckCode(value)
    }
  }, [value, valid, handleCheckCode])

  useEffect(() => {
    if (errorMessage || meta.invalid) {
      setProgress(1 / 4)
    } else {
      setProgress(3 / 4)
    }
  }, [errorMessage, meta])

  return (
    <>
      <Box position="relative">
        <Input
          {...input}
          height="40px"
          onChange={handleChange}
          onFocus={handleOnFocusPassword}
          onBlur={handleOnBlurPassword}
        />

        {isLoading ? (
          <Loading size="25px" m="0px" bottom="7px" left="unset" right="12px" />
        ) : (
          <Button
            border="none"
            bg="transparent"
            pr="12px"
            pl="0px"
            position="absolute"
            right="0px"
            bottom="0px"
            onClick={onClickRefresh}
          >
            <RefreshIcon />
          </Button>
        )}

        {isStatusVisible && (
          <CodeStatusIndicator progress={progress} status={status} />
        )}
      </Box>
    </>
  )
}

export default CodeInput
