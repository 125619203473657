import React, { FC } from 'react'
import { Box } from 'components/ui'

import GoogleLogin from 'images/google-left.png'
import FacebookLogin from 'images/fb-left.png'

const FbGoogle: FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="space-between"
      alignItems="space-between"
    >
      <Box mb="30px">
        <img
          src={GoogleLogin}
          style={{ width: '192px', height: '48px', marginTop: '18px' }}
        />
      </Box>
      <Box mb="30px">
        <img
          src={FacebookLogin}
          style={{ width: '192px', height: '48px', marginTop: '18px' }}
        />
      </Box>
    </Box>
  )
}
export default FbGoogle
