import React, { FC, useCallback } from 'react'
import styled from 'styled-components'

import { Box, Text } from 'components/ui'
import { ReactComponent as CheckIcon } from 'images/check.svg'
import { ACCESS_TYPE_LABELS } from 'constants/albums'

const Wrapper = styled(Box).attrs({
  py: 1,
  pl: '56px',
  cursor: 'pointer',
  position: 'relative',
})`
  &:hover {
    background-color: ${(props) => props.theme.colors.lightGray};
  }
`

type Props = {
  isSelected: boolean
  value: number
  onSelect: (v: number) => void
}

const MenuItem: FC<Props> = ({ isSelected, value, onSelect }) => {
  const handleClick = useCallback(() => {
    if (isSelected) {
      onSelect(value)
    }
  }, [isSelected, onSelect, value])

  return (
    <Wrapper onClick={handleClick}>
      {isSelected && (
        <Box position="absolute" left={1} top={1}>
          <CheckIcon fill="#5458f7" />
        </Box>
      )}

      <Text fontFamily="1" fontSize={1}>
        {ACCESS_TYPE_LABELS[value]}
      </Text>
    </Wrapper>
  )
}

export default MenuItem
