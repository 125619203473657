import React, { FC } from 'react'

import { ReactComponent as Icon } from 'images/check.svg'
import Box from '../Box'
import Text from '../Text'

type Props = {
  isSecondary?: boolean
  option: { label: string; value: string }
  weddingName: string
  isActive: boolean
  onClick: () => void
}

const FontItem: FC<Props> = ({
  isSecondary,
  option,
  weddingName,
  isActive,
  onClick,
}) => (
  <Box
    display="flex"
    position="relative"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    border={isSecondary ? '2px solid' : '1px solid'}
    borderColor={isActive ? (isSecondary ? '#5458F7' : '#A3A3AC') : 'gray'}
    borderRadius="6px"
    height="100px"
    cursor="pointer"
    px={0}
    bg="white"
    onClick={onClick}
  >
    {isActive && !isSecondary && (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="primary"
        borderRadius="50%"
        border="2px solid #fff"
        boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.08)"
        width="23px"
        height="23px"
        position="absolute"
        left="10px"
        top="11px"
        color="white"
      >
        <Icon stroke="white" width="13px" />
      </Box>
    )}

    <Text
      color="#353B60"
      fontFamily={option.label}
      fontSize="32px"
      textAlign="center"
      isTruncate
      isFullWidth
    >
      {weddingName}
    </Text>

    {!isSecondary && (
      <Text color="darkGray" fontSize={1}>
        {option.label}
      </Text>
    )}
  </Box>
)

export default FontItem
