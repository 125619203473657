import React from 'react'

import { Box } from 'components/ui'
import { ReactComponent as Background } from './pattern.svg'

const Pattern = () => (
  <Box>
    <Box
      height="132px"
      bg="#DDDEFD"
      width="100%"
      overflow="hidden"
      display="flex"
      alignItems="flex-end"
    >
      <Background />
    </Box>
  </Box>
)

export default Pattern
