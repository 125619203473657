import {
  Groups as GroupStyled,
  ButtonsStyled,
  CancelButtonStyled,
  CourseInputWrapper,
  SubmitButtonStyled,
  SubmitContainerStyled,
} from '../../utils'
import { Box } from '../../../../components/ui'

import Dishes from '../../../../images/Dishes.svg'
import PlusIcon from '../../../../images/addDish.svg'
import CloseIcon from '../../../../images/closeIconGrey.svg'

import { Group, Groups, MenuItems } from '../../types_interfaces/storObjects'

import AddDish from '../../addDish'
import { initialDishObject } from '../../constants'

import { useParams } from 'react-router'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupItems } from '../../../../store/modules/foodMenu/selector'

import { AddMenuItemNeedData } from '../../tipes_interfaces'
import { add as addMenuItem } from '../../../../store/modules/foodMenu/actions/menu/menuItem/add'
import { update as updateItem } from '../../../../store/modules/foodMenu/actions/menu/menuItem/update'
import { add as addNewMenuGroup } from '../../../../store/modules/foodMenu/actions/menuGroup/add'
import { update as updateGroup } from '../../../../store/modules/foodMenu/actions/menuGroup/update'
import { initialGroupsErrors, setGroupsErrors, SetInitialGroupsErrors } from '../../validation/initealErrors'

interface PropType {
  setIsModalOpen: any;
  menu_id: string | null,
  action: string;
  selectGroupId: string
}

const AddCourse = ({ setIsModalOpen, menu_id, action, selectGroupId }: PropType) => {

  const { wuid } = useParams()
  const dispatch = useDispatch()

  const dishCount = useRef(1)
  const modalRef = useRef<HTMLDivElement>(null)
  const isGroupNameChanged = useRef(false)

  const purpose = action === 'add'
  const groupItems: Groups = useSelector(getGroupItems)
  const [selectedGroup, setSelectedGroup] = useState<any>({
    id: Math.random(),
    groupName: '',
    groupItems: [{ item_id: `${dishCount.current}`, ...initialDishObject }],
  })
  const [errors, setErrors] = useState<any>(initialGroupsErrors)

  const handleGroupChange = (e: any) => {
    if (e.target.name === 'groupName') {
      setSelectedGroup({ ...selectedGroup, groupName: e.target.value })
      isGroupNameChanged.current = true
      return
    }
    const changedGroupItemIndex = selectedGroup.groupItems.findIndex((item: MenuItems) => item.item_id === e.target.id)
    const itemAfterChanges = { ...selectedGroup.groupItems[changedGroupItemIndex] }
    if (e.target.name === 'is_selected') {
      itemAfterChanges[e.target.name] = itemAfterChanges.is_selected === '0' ? '1' : '0'
      const groupItems = [...selectedGroup.groupItems]
      groupItems.splice(changedGroupItemIndex, 1, itemAfterChanges)
      setSelectedGroup({ ...selectedGroup, groupItems })
    } else {
      itemAfterChanges[e.target.name] = e.target.value
      const groupItems = [...selectedGroup.groupItems]
      groupItems.splice(changedGroupItemIndex, 1, itemAfterChanges)

      setSelectedGroup({ ...selectedGroup, groupItems })
    }
  }

  useEffect(() => {
    if (selectedGroup && action === 'edit') {
      const selectItem = groupItems.find((item: Group) => item.group_id === selectGroupId)
      if (selectItem?.groupItems?.length) {
        setErrors(setGroupsErrors(selectItem))
      }
      setSelectedGroup(selectItem)
    }
  }, [groupItems])

  const closeModal = (e: any) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsModalOpen({ type: null, name: null })
    }
  }
  useEffect(() => {
    document.addEventListener('click', closeModal)
    return () => document.removeEventListener('click', closeModal)
  }, [])


  const handleAddDish = () => {
    ++dishCount.current

    setSelectedGroup({
      ...selectedGroup,
      groupItems: [
        ...selectedGroup.groupItems,
        { item_id: `${dishCount.current}`, ...initialDishObject },
      ],
    })
    const err = SetInitialGroupsErrors(null, `${dishCount.current}`)
    setErrors({
      ...errors,
      groupItems: {
        ...errors.groupItems,
        ...err,
      },
    })
  }

  const handleSaveMenuItems = async (data: AddMenuItemNeedData) => {
    try {
      const validationResult = setGroupsErrors(selectedGroup)
      if (validationResult.isErr) {
        setErrors(validationResult)
      } else {
        setErrors(setGroupsErrors(selectedGroup))
        if (wuid && menu_id) {
          if (action === 'add') {
            const response = await dispatch(addNewMenuGroup({ wuid, title: selectedGroup.groupName }))
            const { value: { payload: { menu_group: { menugroup_id } } } }: any = response

            dispatch(addMenuItem({
              wuid,
              menu_id,
              title: selectedGroup.groupItems[0].title,
              comment: selectedGroup.groupItems[0].comment,
              step_n: menugroup_id,
              menugroup_id,
            }))
          } else if (action === 'edit') {
            if (isGroupNameChanged) {
              await dispatch(updateGroup({
                wuid,
                menugroup_id: selectedGroup.group_id,
                title: selectedGroup.groupName,
              }))
            }
            await dispatch(updateItem({
              menu_id, menu_item_id: selectedGroup.groupItems[0].item_id,
              title: selectedGroup.groupItems[0].title,
              comment: selectedGroup.groupItems[0].comment,
            }))
          }
        }

        setIsModalOpen({ type: null, name: null })
      }
    } catch (e) {
      setIsModalOpen({ type: null, name: null })
    }
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' width='100%' marginBottom={24}>
        <Box className='pageTitle'>Course Details</Box>
        <Box onClick={() => setIsModalOpen({ type: null, name: null })}>
          <img style={{ width: '20px' }} src={CloseIcon} alt='...Loading' />
        </Box>
      </Box>
      <CourseInputWrapper>
        <label htmlFor='groupName'>Course</label>
        <input
          style={{
            backgroundColor: '#FFFFFF',
            border: '1px solid',
            borderColor: errors.groupName ? 'red' : '#E8E8ED',
            borderRadius: '8px',
            width: '100%',
            height: '40px',
            padding: '4px 8px',
          }}
          id='groupName'
          value={selectedGroup?.groupName}
          name='groupName'
          placeholder='  Main Course'
          onChange={handleGroupChange}
        />
        {errors.groupName &&
        <Box style={{ color: 'red', width: '80%', marginLeft: '8px' }}>{errors.groupName}</Box>}
      </CourseInputWrapper>
      <Box style={{ borderBottom: '1px solid #CBCBD3', width: '100%', marginBottom: '20px', opacity: 0.4 }} />
      <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
        <Box className='iconGroup'>
          <img src={Dishes} alt='...Loading' />
          <Box className='title'>Dishes</Box>
        </Box>
        {purpose &&
        <Box onClick={handleAddDish} className='iconGroup'>
          <Box className='title-add'>Add Dish</Box>
          <img src={PlusIcon} alt='...Loading' />
        </Box>
        }
      </Box>
      <GroupStyled>
        {selectedGroup.groupItems.length &&
        selectedGroup.groupItems.map((item: MenuItems) => <AddDish
          error={errors.groupItems?.[item.item_id]?.title}
          isSelected={item.is_selected === '1'}
          id={item.item_id} key={item.item_id}
          handleCourseChange={handleGroupChange}
          title={item.title}
          description={item.comment}
        />)
        }
      </GroupStyled>
      <ButtonsStyled>
        <SubmitContainerStyled>
          <CancelButtonStyled onClick={() => setIsModalOpen({ type: null, name: null })}>
            Cancel
          </CancelButtonStyled>

          <SubmitButtonStyled onClick={handleSaveMenuItems}>
            Save
          </SubmitButtonStyled>
        </SubmitContainerStyled>
      </ButtonsStyled>
    </>
  )
}

export default AddCourse