import React from 'react'

import { Box } from 'components/ui'

import Pattern from './Pattern'
import User from './User'
import Title from './Title'
import Links from './Links'
import CreateButton from './CreateButton'

const Nav = () => {
  return (
    <>
      <Pattern />

      <User />

      <Title>Suggested links</Title>
      <Box mt="5px" />
      <Links />

      <Box mt="24px" />
      <Title>Wedding actions</Title>

      <Box mt="24px" pl="20px" pr="16px">
        <CreateButton />
      </Box>
    </>
  )
}

export default Nav
