import React, {
  MouseEvent,
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react'
import { useSelector } from 'react-redux'
import InfiniteViewer from 'react-infinite-viewer'

import { SChartItem } from 'store/modules/seatings/reducer'
import { selectIsExpanded, getSChart } from 'store/modules/seatings/selectors'
import { Box, Text, Button } from 'components/ui'
import GuestToolsButton from './GuestToolsButton'
import { ReactComponent as PlusIcon } from './plus-2.svg'
import Zoom from './Zoom'
import Title from './Title'
import ExportFloorplan from './ExportFloorplan'
import Floorplan from '../Floorplan'
import UploadFloorplan from '../UploadFloorplan'
import ChartItem from '../ChartItem'
import AddTableModal from '../AddTableModal'
import EditChartModal from '../EditChartModal'

type Props = {
  chartId?: string
  caption?: string
  width: number
  height: number
  zoom?: number
  items: SChartItem[]
  selectedItemId?: string
  onOpenCreateModal: VoidFunction
  onChangeActiveChartId: (chartId: string) => void
  onAddItem: (payload: Partial<SChartItem>) => void
  onChangeItem: (payload: Partial<SChartItem>) => void
  onRemoveItem: (itemId: SChartItem['schart_item_id']) => void
  onClickEditButton: (chartId: string) => void
  onSelectItem: (itemId?: string) => void
  onResetSelectedItemId: VoidFunction
  onZoom: (zoom?: number) => void
  panelIsExpanded: boolean
  setPanelIsExpanded: Dispatch<SetStateAction<boolean>>
}

const Grid = ({
  chartId,
  caption,
  zoom = 1,
  items,
  selectedItemId,
  onOpenCreateModal,
  onChangeActiveChartId,
  onAddItem,
  onChangeItem,
  onRemoveItem,
  onClickEditButton,
  onSelectItem,
  onResetSelectedItemId,
  onZoom,
  panelIsExpanded,
  setPanelIsExpanded,
}: Props) => {
  // const dispatch = useDispatch()
  // const [isLoading, setLoading] = useState(false)
  const viewerRef = useRef<InfiniteViewer>(null)
  const [isAddModalOpen, setAddModalOpen] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const { floorplan } = useSelector(getSChart(chartId)) || {}

  // Scroll
  const [isScrolling, setIsScrolling] = useState(false)
  const [clientScroll, setClientScroll] = useState({ x: 0, y: 0 })
  const [scroll, setScroll] = useState({ x: 0, y: 0 })
  const [isItemDragging, setItemIsDragging] = useState(false)
  const isSeatingChartExpanded = useSelector(selectIsExpanded)

  const onSetEditModalAsSettings = useCallback(() => setEditModalOpen(true), [])
  const onCloseEditModal = useCallback(() => setEditModalOpen(false), [])

  const onOpenAddTableModal = useCallback(() => setAddModalOpen(true), [])
  const onCloseAddModal = useCallback(() => setAddModalOpen(false), [])

  const onOpenPanel = useCallback(() => setPanelIsExpanded(true), [
    setPanelIsExpanded,
  ])
  const onClickGuestToolsButton = useCallback(
    () => setPanelIsExpanded((isExpanded) => !isExpanded),
    [setPanelIsExpanded]
  )

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!isItemDragging) {
        setIsScrolling(true)
        setClientScroll({ x: e.clientX, y: e.clientY })
      }
    },
    [isItemDragging]
  )

  const onMouseUp = useCallback(() => {
    setIsScrolling(false)
  }, [])

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isScrolling && viewerRef.current) {
        const x = scroll.x - e.clientX + clientScroll.x
        const y = scroll.y - e.clientY + clientScroll.y

        viewerRef.current.scrollTo(x, y)

        setScroll({ x, y })
        setClientScroll({ x: e.clientX, y: e.clientY })
      }
    },
    [scroll, clientScroll, isScrolling]
  )

  const scrollCenter = useCallback(() => {
    if (viewerRef.current) {
      if (floorplan?.id) {
        return viewerRef.current.scrollTo(-100, -80)
      }

      return viewerRef.current.scrollCenter()
    }
  }, [viewerRef, floorplan?.id])

  const selectedItem = useMemo(
    () => items.find((item) => item.schart_item_id === selectedItemId),
    [items, selectedItemId]
  )

  useEffect(() => {
    if (viewerRef.current) {
      setScroll({
        x: viewerRef.current.getScrollLeft(),
        y: viewerRef.current.getScrollTop(),
      })

      viewerRef.current
        .getWrapper()
        .setAttribute('style', `background-size: ${Math.round(19 * zoom)}px;`)
    }
  }, [zoom, viewerRef])

  useEffect(() => {
    scrollCenter()
  }, [scrollCenter, chartId])

  return (
    <Box
      bg="white"
      borderRadius="10px"
      boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
      position="absolute"
      bottom="16px"
      left="16px"
      style={{
        top: isSeatingChartExpanded ? 16 : 104,
        right: panelIsExpanded ? 308 : 16,
        transition: 'top 0.2s ease-in-out 0s, right 0.1s ease-in-out 0s',
      }}
    >
      <AddTableModal
        isOpen={isAddModalOpen}
        onCloseModal={onCloseAddModal}
        onAddItem={onAddItem}
      />

      <Box
        height="72px"
        width="100%"
        bg="white"
        borderTopLeftRadius="10px"
        borderTopRightRadius="10px"
        px="16px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" width="100%" alignItems="center">
          <Title
            caption={caption}
            onOpenCreateModal={onOpenCreateModal}
            setActiveChartId={onChangeActiveChartId}
            onClickEditButton={onClickEditButton}
          />

          <Button
            height="40px"
            px="20px"
            bg="#5458F7"
            borderRadius="10px"
            ml="24px"
            onClick={onOpenAddTableModal}
          >
            <PlusIcon />

            <Box mr="8px" />

            <Text fontWeight={600} fontSize="16px" color="white">
              Add Table
            </Text>
          </Button>
        </Box>

        <Box display="flex" alignItems="center">
          {chartId && (
            <UploadFloorplan chartId={chartId} floorplan={floorplan} />
          )}

          <Box height="40px" width="1px" bg="#E8E8ED" mx="16px" />

          <ExportFloorplan chartId={chartId} />

          <Box height="40px" width="1px" bg="#E8E8ED" mx="16px" />

          <GuestToolsButton
            isExpanded={panelIsExpanded}
            onClickButton={onClickGuestToolsButton}
          />
        </Box>
      </Box>

      <Box
        position="absolute"
        width="100%"
        height="calc(100% - 72px)"
        borderTop="1px solid #E8E8ED"
      >
        <Zoom onZoom={onZoom} scrollCenter={scrollCenter} />

        {selectedItem && isEditModalOpen && (
          <EditChartModal
            item={selectedItem}
            onChangeItem={onChangeItem}
            onToggleEditModal={onCloseEditModal}
            onAddItem={onAddItem}
            onRemoveItem={onRemoveItem}
          />
        )}

        <Box width="100%" height="100%" style={{ userSelect: 'none' }}>
          <InfiniteViewer
            ref={viewerRef}
            className="infinite-viewer"
            zoom={zoom}
            displayHorizontalScroll={false}
            displayVerticalScroll={false}
          >
            <div className="viewport">
              {items.map((item) => (
                <ChartItem
                  key={item.schart_item_id}
                  {...item}
                  selectedItemId={selectedItemId}
                  onChangeItem={onChangeItem}
                  onSelectItem={onSelectItem}
                  onSetEditModalAsSettings={onSetEditModalAsSettings}
                  onOpenPanel={onOpenPanel}
                  setItemIsDragging={setItemIsDragging}
                />
              ))}

              {chartId && floorplan?.id && (
                <Floorplan
                  chartId={chartId}
                  onClick={onResetSelectedItemId}
                  {...floorplan}
                />
              )}
            </div>

            <Box
              position="sticky"
              width="100%"
              height="100%"
              top="0px"
              right="0px"
              bottom="0px"
              left="0px"
              cursor="grab"
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
              onMouseLeave={onMouseUp}
              onMouseMove={onMouseMove}
              onClick={onResetSelectedItemId}
            />
          </InfiniteViewer>
        </Box>
      </Box>
    </Box>
  )
}

export default Grid
