import styled from 'styled-components'
import { Box, Text } from 'components/ui'

export const MainBox = styled(Box).attrs({
  width: '100%',
  maxWidth: '740px',
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
  bg: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 1,
})``

export const Title = styled(Text).attrs({
  fontSize: ['28px', 5],
  fontWeight: 'bold',
  color: 'secondary',
  lineHeight: ['normal', '45px'],
  textAlign: 'center',
})``

export const Subtitle = styled(Text).attrs({
  fontWeight: 300,
  fontSize: '20px',
  lineHeight: '28px',
  color: 'secondary',
  textAlign: 'center',
})``
