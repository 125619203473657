import React, { useContext } from 'react'
import { FC } from 'react'
import { Box, Button } from 'components/ui'
import { Context } from '../Context'

// Icons
import { ReactComponent as DownloadIcon } from 'images/download.svg'
import { ReactComponent as ShareIcon } from 'images/shareIcon.svg'

const ImportDeleteButtons: FC = () => {
  const {
    selectedSchedules,
    selectedCalendarEvents,
  } = useContext<any>(Context)

  return (
    <Box display='flex' alignItems='center'>
      <Box>
        <Button
          border={`1px solid ${selectedSchedules.length || selectedCalendarEvents.length ? '#CBCBD3 !important' : '#E8E8ED !important'}`}
          borderRadius='8px'
          p='8px !important'
          height='40px'
          cursor={selectedSchedules.length || selectedCalendarEvents.length ? 'pointer' : 'unset'}
          backgroundColor='#ffffff !important'
          disabled={!(selectedSchedules.length || selectedCalendarEvents.length)}
        >
          <DownloadIcon stroke={selectedSchedules.length || selectedCalendarEvents.length ? '#353B60' : '#E8E8ED'}
                        height='24px' />
        </Button>
      </Box>

      <Box ml='12px'>
        <Button
          border='1px solid #CBCBD3'
          borderRadius='10px'
          height='40px'
          bg='transparent'
          color='#353B60'
          fontWeight='600'
          fontSize='16px'
          fontFamily='Source Sans Pro !important'
          padding='8px 16px !important'
          cursor='pointer'
          onClick={() => {
          }}
        >
          <Box mr='8px'>
            <ShareIcon />
          </Box>
          Share
        </Button>
      </Box>
    </Box>
  )
}

export default ImportDeleteButtons