import React, { useMemo, useCallback, CSSProperties } from 'react'

import { SChartItem, SChartGuest } from 'store/modules/seatings/reducer'
import { getImage, getFullName } from 'utils/guest'
import { getInitials } from 'utils/wedding'

type Props = {
  index: number
  guest: SChartGuest | null
  cx: number
  cy: number
  itemId: SChartItem['schart_item_id']
  rotation: SChartItem['rotation']
}

const Seat = ({ index, guest, cx, cy, itemId, rotation }: Props) => {
  const href = useMemo(() => getImage(guest?.user?.image), [guest])
  const initials = useMemo(
    () => getInitials(getFullName([guest?.first_name, guest?.last_name])),
    [guest]
  )

  const style = useMemo<CSSProperties>(
    () => ({
      transform: `rotate(${-rotation}deg)`,
      transformOrigin: 'center center',
      transformBox: 'fill-box',
    }),
    [rotation]
  )

  const textStyle = useMemo<CSSProperties>(
    () => ({
      ...style,
      fontSize: 18,
      fill: '#A3A3AC',
      fontWeight: 400,
      fontFamily: 'Source Sans Pro',
      textAnchor: 'middle',
      alignmentBaseline: 'central',
      dominantBaseline: 'middle',
    }),
    [style]
  )

  const initialsTextStyle = useMemo<CSSProperties>(
    () => ({
      ...textStyle,
      fill: '#fff',
      fontWeight: 600,
    }),
    [textStyle]
  )

  const renderContent = useCallback(() => {
    if (href) {
      return (
        <defs>
          <pattern
            id={`image${itemId}`}
            patternUnits="objectBoundingBox"
            height="1"
            width="1"
          >
            <image href={href} width="35" height="35" />
          </pattern>
        </defs>
      )
    }

    if (guest) {
      return (
        <text x={cx} y={cy} style={initialsTextStyle}>
          {initials}
        </text>
      )
    }

    return (
      <text x={cx} y={cy} style={textStyle}>
        {index}
      </text>
    )
  }, [
    itemId,
    href,
    guest,
    cx,
    cy,
    index,
    initials,
    initialsTextStyle,
    textStyle,
  ])

  return (
    <g
      data-seatnum={index}
      data-guestid={guest?.guest_id}
      data-firstname={guest?.first_name}
      data-lastname={guest?.last_name}
      data-image={href}
    >
      <circle
        cx={cx}
        cy={cy}
        r="17.5"
        fill={href ? `url(#image${itemId})` : guest ? '#353B60' : 'white'}
        stroke={!guest ? '#A3A3AC' : undefined}
        strokeDasharray={!guest ? '4 4' : undefined}
        style={style}
      />

      {renderContent()}
    </g>
  )
}

export default Seat
