import React, { FC, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { head } from 'ramda'

import { Box, Text, Button } from 'components/ui'
import { readImage } from 'utils/images'
import { ReactComponent as Icon } from 'images/upload-cloud.svg'
import Preview from './Preview'

type Props = {
  image?: string
  onChange: (value: any) => void
  onClickZoomButton: VoidFunction
  onClickDeleteButton: VoidFunction
}

const Uploader: FC<Props> = ({
  image,
  onChange,
  onClickZoomButton,
  onClickDeleteButton,
}) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = head(acceptedFiles)

      if (file) {
        onChange(await readImage(file))
      }
    },
    [onChange]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'image/*',
    multiple: false,
  })

  if (image) {
    return (
      <>
        <input {...getInputProps()} />

        <Preview
          image={image}
          onClickChange={open}
          onClickZoomButton={onClickZoomButton}
          onClickDeleteButton={onClickDeleteButton}
        />
      </>
    )
  }

  return (
    <Box
      width="100%"
      height="225px"
      borderRadius={1}
      border="1px dashed #CBCBD3"
      bg="#FDFDFD"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <Icon />

      <Box mt="15px">
        <Text fontFamily="1" fontSize="16px" lineHeight="20px" color="#414042">
          Drag & Drop
        </Text>
      </Box>

      <Box mt="6px" mb="9px">
        <Text fontFamily="1" fontSize="14px" lineHeight="18px" color="#414042">
          or
        </Text>
      </Box>

      <Button
        bg="primary"
        borderRadius={0}
        height="40px"
        width="135px"
        onClick={open}
      >
        <Text fontFamily="1" fontWeight={600} fontSize="16px" color="white">
          Browse Files
        </Text>
      </Button>
    </Box>
  )
}

export default Uploader
