import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router'
import { Form } from 'react-final-form'
import { mergeAll, path } from 'ramda'
import validate from 'validate.js'
import { toast } from 'react-toastify'

import { Box, Text, Button } from 'components/ui'
import { ReactComponent as Logo } from 'images/hitchhero-logo.svg'
import { WEDDING_CREATE } from 'constants/paths'
import {
  AuthForm,
  MainBox,
  Title,
  Subtitle,
  AppPreview,
} from 'components/WeddingWizard'
import { passwordConstraint, emailConstraint } from 'constants/constraints'
import { signup, SignupData, login } from 'store/modules/auth/actions'
import { getIsLoggedIn } from 'store/modules/auth/selectors'

type Props = {
  firstName?: string
  lastName?: string
  color?: { value: string; label: string }
  color2?: { value: string; label: string }
  title?: string
  font?: { value: string; label: string }
  image?: string
}

const Signup = ({ firstName, lastName, ...props }: Props) => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const isLoggedIn = useSelector(getIsLoggedIn)

  const handleClickLogin = useCallback(
    () =>
      push({
        pathname: `${WEDDING_CREATE}/login`,
        state: { transition: 'wizard-content-forward' },
      }),
    [push]
  )

  const handleValidate = useCallback(
    (values: object) =>
      validate(values, mergeAll([passwordConstraint(), emailConstraint()])),
    []
  )

  const onSignup = useCallback(
    async (data: SignupData) => dispatch(signup(data)),
    [dispatch]
  )

  const onLogin = useCallback(async (data) => dispatch(login(data)), [dispatch])

  const handleSubmit = useCallback(
    async (
      values: { email: string; password: string },
      _: any,
      complete?: (x: any) => void
    ) => {
      try {
        if (firstName && lastName) {
          const res = await onSignup({
            ...values,
            first_name: firstName,
            last_name: lastName,
          })

          if (path(['value', 'status'], res) === 'success') {
            await onLogin(values)
          } else {
            throw new Error('Something goes wrong')
          }
        } else {
          toast('Insert your name please')

          push({
            pathname: `${WEDDING_CREATE}/step/1`,
            state: {
              transition: 'wizard-content-forward',
            },
          })
        }
      } catch (error) {
        if (complete) {
          complete([error.message])
        }
      }
    },
    [onSignup, onLogin, firstName, lastName, push]
  )

  if (isLoggedIn) {
    return <Redirect to={`${WEDDING_CREATE}/step/8`} />
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems={['flex-start', 'center']}
      minHeight={['calc(100vh - 96px)', 'calc(100vh - 180px)']}
    >
      <MainBox pt="70px" pb="45px">
        <Box mt="-92px" mb="30px">
          <Logo />
        </Box>

        <Title>You’re almost there!</Title>

        <Box mt="8px" />

        <Subtitle>
          Create an account so we can save your wedding details.
        </Subtitle>

        <Box display="flex" alignItems="center" mb="40px">
          <Subtitle>Already have an account?</Subtitle>

          <Button
            color="primary"
            fontSize="20px"
            fontWeight="600"
            height="20px"
            onClick={handleClickLogin}
          >
            Log in
          </Button>
        </Box>

        <Box
          maxWidth="400px"
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Form
            validate={handleValidate}
            onSubmit={handleSubmit}
            component={AuthForm}
          />

          <Box mt="20px" />

          <Text
            color="darkGray"
            fontFamily="1"
            fontSize={0}
            lineHeight="24px"
            textAlign="center"
          >
            By creating a new account, you agree to the HitchHero{' '}
            <a href={`${process.env.REACT_APP_WEBSITE_URI || ''}/terms`}>
              Terms of Use
            </a>{' '}
            and{' '}
            <a href={`${process.env.REACT_APP_WEBSITE_URI || ''}/privacy`}>
              Privacy Policy
            </a>
          </Text>
        </Box>
      </MainBox>

      <Box position="absolute" bottom="0px" right="0px" left="0px" zIndex={1}>
        <AppPreview {...props} />
      </Box>
    </Box>
  )
}

export default Signup
