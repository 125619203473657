import React, { FC } from 'react'

import { ReactComponent as ChervonIcon } from 'images/chevron-left.svg'
import { Button } from 'components/ui'

type Props = {
  onClickButton: (e: any) => void
}

const PhotosBackButton: FC<Props> = ({ onClickButton }) => (
  <Button
    pr={1}
    height="44px"
    border="none"
    fontFamily="1"
    fontSize={0}
    color="primary"
    ml="-38px"
    onClick={onClickButton}
  >
    <ChervonIcon
      width="32px"
      height="32px"
      viewBox="0 0 18 24"
      fill="#5458f7"
    />
    Back
  </Button>
)

export default PhotosBackButton
