import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getUser } from 'store/modules/auth/selectors'
import { getFullName, getImage } from 'utils/guest'
import { Box, Avatar, Text } from 'components/ui'
import ProfileDetails from '../ProfileDetails'

const User = () => {
  const user = useSelector(getUser)

  const fullName = useMemo(
    () => getFullName([user?.first_name, user?.last_name]),
    [user]
  )

  const image = useMemo(() => getImage(user?.image, 'big_icon'), [user])

  return (
    <Box pl="24px" pr="20px" mt="-21px" pb="24px">
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        mb="12px"
      >
        <Avatar
          name={fullName}
          image={image}
          size={72}
          fontSize={36}
          color="white"
          bg="#353B60"
        />

        <ProfileDetails {...user} />
      </Box>

      <Text
        fontWeight={700}
        fontSize="20px"
        color="secondary"
        lineHeight="25px"
      >
        {fullName}
      </Text>
    </Box>
  )
}

export default User
