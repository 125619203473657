import React, { FC, useCallback } from 'react'
import { FormRenderProps } from 'react-final-form'
import { path } from 'ramda'
import { format } from 'date-fns'

import { withGoogleMaps } from 'utils/gmaps'
import { Box, FormLabel, Button, GMap } from 'components/ui'
import { ReactComponent as TrashIcon } from 'images/trash.svg'
import {
  Input,
  TimePickerInput,
  DayPickerInput,
  GeosuggestInput,
  NoteInput,
} from 'components/form'

type Props = {
  googleMaps?: any
  onCancel: VoidFunction
}

const NewAgendaForm: FC<FormRenderProps<any> & Props> = ({
  googleMaps,
  handleSubmit,
  submitting,
  values,
  form,
  invalid,
  onCancel,
}) => {
  const handleClickClearLocation = useCallback(
    () => form.change('location', null),
    [form]
  )
  const handleChangeStartDate = useCallback(
    (value) => {
      if (!values.start_date && !values.end_date) {
        form.change('end_date', value)
      }
    },
    [form, values]
  )

  return (
    <Box as="form" autoComplete="off" onSubmit={handleSubmit}>
      <Box mb={1}>
        <Input name="name" label="Name" placeholder="Name" isRequired />
      </Box>

      <Box display="flex" mb={1}>
        <Box width={1 / 2} pr="12px">
          <FormLabel isRequired>Starts</FormLabel>

          <Box display="flex">
            <Box width="186px" pr="6px">
              <TimePickerInput name="start_time" />
            </Box>

            <Box width="100%" pl="6px">
              <DayPickerInput
                name="start_date"
                placeholder={format(new Date(), 'MMMM d, yyyy')}
                format="MMMM d, yyyy"
                disabledDays={{ after: values.end_date }}
                onChange={handleChangeStartDate}
              />
            </Box>
          </Box>
        </Box>

        <Box width={1 / 2} pl="12px">
          <FormLabel isRequired>Ends</FormLabel>

          <Box display="flex">
            <Box width="186px" pr="6px">
              <TimePickerInput name="end_time" />
            </Box>

            <Box width="100%" pl="6px">
              <DayPickerInput
                name="end_date"
                placeholder={format(new Date(), 'MMMM d, yyyy')}
                format="MMMM d, yyyy"
                disabledDays={{ before: values.start_date }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mb={1}>
        <GeosuggestInput
          googleMaps={googleMaps}
          name="location"
          label="Location"
          placeholder="Location"
        />

        {path(['location'], values.location) && (
          <Box mt={1} width="334px" position="relative">
            <Button
              zIndex={1}
              position="absolute"
              right="12px"
              top="12px"
              bg="darkened"
              color="white"
              width="40px"
              height="40px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="none"
              borderRadius={0}
              onClick={handleClickClearLocation}
            >
              <TrashIcon stroke="currentColor" />
            </Button>

            <GMap
              googleMaps={googleMaps}
              position={path(['location'], values.location)}
              hideMarker
            />
          </Box>
        )}
      </Box>

      <Box mb={1}>
        <Box width="334px">
          <Input name="dress" label="Dress" placeholder="Dress" />
        </Box>
      </Box>

      <Box mb={1}>
        <Input name="url" label="URL" placeholder="URL" />
      </Box>

      <Box mb={4}>
        <NoteInput name="notes" label="Notes" placeholder="Notes" />
      </Box>

      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <Button
            variant="primary"
            type="submit"
            disabled={submitting || invalid}
          >
            Save
          </Button>
        </Box>

        <Button onClick={onCancel} fontSize="18px" color="blue">
          Cancel
        </Button>
      </Box>
    </Box>
  )
}

export default withGoogleMaps(NewAgendaForm)
