import React, { FC } from 'react'
import { FormRenderProps } from 'react-final-form'

import { Box, Button } from 'components/ui'
import { Input } from 'components/form'

type Props = {
  isEdit: boolean
  onClose: () => void
  onDelete: () => void
}

const GroupForm: FC<FormRenderProps & Props> = ({
  isEdit,
  handleSubmit,
  onClose,
  submitting,
  onDelete,
}) => {
  return (
    <Box as="form" autoComplete="off" onSubmit={handleSubmit}>
      <Box mb={3}>
        <Input name="name" label="Name" placeholder="Name" />
      </Box>

      <Box
        display="flex"
        justifyContent={isEdit ? 'space-between' : 'flex-end'}
        width="100%"
      >
        {isEdit && (
          <Button
            border="none"
            px="0px"
            py={0}
            fontFamily="1"
            fontSize="18px"
            color="red"
            onClick={onDelete}
          >
            Delete
          </Button>
        )}

        <Box display="flex">
          <Box mr={0}>
            <Button
              border="none"
              px={2}
              py={0}
              fontFamily="1"
              fontSize="18px"
              color="blue"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>

          <Button type="submit" variant="primary" disabled={submitting}>
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default GroupForm
