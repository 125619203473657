import React, { FC } from 'react'
import { FormRenderProps } from 'react-final-form'

import { Box, Button } from 'components/ui'
import { Input } from 'components/form'
import { ErrorAlert } from 'components/Auth'

type Props = {
  isLogin?: boolean
}

const AuthForm: FC<
  FormRenderProps<{ email: string; password: string }> & Props
> = ({ submitting, submitErrors, form: { submit }, isLogin = false }) => (
  <>
    <Box mb={2}>
      <Input name="email" placeholder="Email" height="40px" hideErrorMessages />
    </Box>

    <Box mb={3}>
      <Input
        placeholder="Password"
        name="password"
        type="password"
        height="40px"
        hideErrorMessages
      />
    </Box>

    {submitErrors && <ErrorAlert messages={submitErrors} />}

    <Button
      variant="primary"
      disabled={submitting}
      fullWidth
      height="40px"
      onClick={submit}
    >
      {isLogin ? 'Login' : 'Sign Up'}
    </Button>
  </>
)

export default AuthForm
