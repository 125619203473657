import React, { useCallback, ChangeEvent } from 'react'

import { Box, Text, Switch } from 'components/ui'
import { Values } from './AppSections'

type Props = {
  isChecked?: boolean
  isPublic?: boolean
  onChangeValues: (values: Partial<Values>) => void
}

const AdminVisibleSwitch = ({
  isChecked = false,
  isPublic,
  onChangeValues,
}: Props) => {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      onChangeValues({ access_level: e.currentTarget.checked ? 1 : 0 }),
    [onChangeValues]
  )

  return (
    <Box pt="24px" display="flex" justifyContent="space-between">
      <Text
        fontWeight={600}
        fontSize="16px"
        color={isPublic ? '#E5E5E5' : '#353B60'}
      >
        Visible to Admins Only
      </Text>

      <Switch checked={isChecked} disabled={isPublic} onChange={onChange} />
    </Box>
  )
}

export default AdminVisibleSwitch
