import React, { FC, useState, useEffect } from 'react'
import {
  isValid,
  isPast,
  parseISO,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns'

import { Box, Text } from 'components/ui'

const CountDown: FC<{ date: string }> = ({ date }) => {
  const parsed = parseISO(date)

  if (!isValid(parsed) || isPast(parsed)) return null

  /* eslint-disable react-hooks/rules-of-hooks */
  const [now, setNow] = useState(new Date())
  useEffect(() => {
    setTimeout(() => {
      setNow(new Date())
    }, 1000)
  })
  /* eslint-enable */

  const days = differenceInDays(parsed, now)
  const hours = differenceInHours(parsed, now) - days * 24
  const minutes = differenceInMinutes(parsed, now) - days * 24 * 60 - hours * 60
  const seconds =
    differenceInSeconds(parsed, now) -
    days * 24 * 60 * 60 -
    hours * 60 * 60 -
    minutes * 60

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      px="82px"
      flexWrap="wrap"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth="65px"
      >
        <Text fontSize="28px" fontWeight={600} color="white" lineHeight={1.29}>
          {days}
        </Text>

        <Text color="white" lineHeight={1}>
          Days
        </Text>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth="65px"
      >
        <Text fontSize="28px" fontWeight={600} color="white" lineHeight={1.29}>
          {hours}
        </Text>

        <Text color="white" lineHeight={1}>
          Hours
        </Text>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth="65px"
      >
        <Text fontSize="28px" fontWeight={600} color="white" lineHeight={1.29}>
          {minutes}
        </Text>

        <Text color="white" lineHeight={1}>
          Min.
        </Text>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth="65px"
      >
        <Text fontSize="28px" fontWeight={600} color="white" lineHeight={1.29}>
          {seconds}
        </Text>

        <Text color="white" lineHeight={1}>
          Sec.
        </Text>
      </Box>
    </Box>
  )
}

export default CountDown
