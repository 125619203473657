import { UPDATE_MENU_GROUP } from '../../actionTypes'
import { MenuGroupUpdate } from '../../../../../routes/FoodMenu/tipes_interfaces'

export const update = (data: MenuGroupUpdate) => {
  return {
    type: UPDATE_MENU_GROUP.name,
    api: {
      url: 'menu/update_group',
      data,
    },
  }
}