import { pick } from 'ramda'

import { ADD } from '../actionTypes'

export const addComment = (data: object) => ({
  type: ADD.name,
  api: {
    url: 'comments/add',
    data: pick(['post_id', 'caption'], data),
  },
})
