import React, { FC } from 'react'

import { Box, Text, Switch } from 'components/ui'
import { ReactComponent as NotificationsIcon } from 'images/bell.svg'

const Notifications: FC = () => (
  <Box px="10px" py={2} borderBottom="1px solid" borderColor="gray">
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="inline-flex" alignItems="center">
        <Box mr={3}>
          <NotificationsIcon stroke="#858ea2" />
        </Box>

        <Text fontSize="15px" color="fullBlack">
          Notifications
        </Text>
      </Box>

      <Switch />
    </Box>
  </Box>
)

export default Notifications
