import React, {
  useCallback,
  useState,
  useMemo,
  CSSProperties,
  MouseEvent,
} from 'react'
import { ValueType, OptionTypeBase } from 'react-select'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'

import { SChartItem } from 'store/modules/seatings/reducer'
import { Box, Select, Text } from 'components/ui'
import GuestItem from './GuestItem'

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

type Props = {
  items: SChartItem[]
  onChangeItem: (payload: Partial<SChartItem>) => void
}

const Tables = ({ items, onChangeItem }: Props) => {
  const tables = useMemo(
    () =>
      items
        .filter((item) => item.seats.flat().length)
        .map((item) => ({
          id: item.schart_item_id,
          caption: item.caption,
          seats: item.seats
            .reduce((prev, value) => [...prev, ...value], [])
            .map((guest) => ({
              id: guest?.guest_id || Math.random().toString(),
              guest,
            })),
        })),
    [items]
  )

  const allTablesOption = useMemo(
    () => ({ label: 'All Tables', value: null }),
    []
  )
  const [selectedTable, setTable] = useState<OptionTypeBase>(allTablesOption)

  const options = useMemo(
    () => [
      allTablesOption,
      ...tables.map((table) => ({
        label: table.caption,
        value: table.id,
      })),
    ],
    [allTablesOption, tables]
  )

  const styles = useMemo(
    () => ({
      control: (base: CSSProperties) => ({
        ...base,
        boxShadow: 'none',
        borderWidth: 2,
        borderColor: '#E8E8ED',
        borderRadius: 8,
        marginBottom: 16,
        ':hover': {
          borderColor: '#E8E8ED',
        },
      }),
      singleValue: (base: CSSProperties) => ({
        ...base,
        fontFamily: 'Source Sans Pro',
        fontSize: 16,
        color: '#73737B',
      }),
      valueContainer: (base: CSSProperties) => ({
        ...base,
        padding: '2px 0 2px 12px',
      }),
      menu: (base: CSSProperties) => ({
        ...base,
        border: '2px solid #E8E8ED',
        borderRadius: 8,
        padding: '0 12px',
        boxShadow: 'none',
      }),
      menuList: (base: CSSProperties) => ({
        ...base,
        padding: 0,
      }),
      option: (base: CSSProperties) => ({
        ...base,
        height: 40,
        fontFamily: 'Source Sans Pro',
        fontSize: 16,
        color: '#73737B',
        backgroundColor: '#fff',
        padding: '9px 0',
        '&:hover': {
          backgroundColor: '#fff',
        },
        '&:not(:last-child)': {
          borderBottom: '1px solid #E8E8ED',
        },
      }),
      dropdownIndicator: (base: CSSProperties) => ({
        ...base,
        color: '#73737B',
        padding: '8px 12px',
        ':hover': {
          color: '#73737B',
        },
      }),
    }),
    []
  )

  const selectedItem = useMemo(
    () => tables.find((table) => table.id === selectedTable.value),
    [tables, selectedTable]
  )

  const onChange = useCallback((value: ValueType<OptionTypeBase, false>) => {
    if (value) {
      setTable(value)
    }
  }, [])

  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination) {
        return
      }

      const sourceTable = tables.find((item) => item.id === source.droppableId)
      const sourceItem = items.find(
        (item) => item.schart_item_id === source.droppableId
      )

      if (!sourceTable || !sourceItem) {
        return
      }

      if (source.droppableId === destination.droppableId) {
        const nextGuests = reorder(
          sourceTable.seats,
          source.index,
          destination.index
        )

        const nextSeats = sourceItem.seats.map((side, sideIdx) => {
          if (sideIdx === 0) {
            return nextGuests.map(({ guest }) => guest).slice(0, side.length)
          }

          const prevLength = sourceItem.seats
            .slice(0, sideIdx)
            .reduce((prev, value) => prev + value.length, 0)

          return nextGuests
            .map(({ guest }) => guest)
            .slice(prevLength, prevLength + side.length)
        })

        return onChangeItem({
          schart_item_id: source.droppableId,
          seats: nextSeats,
        })
      }

      if (source.droppableId !== destination.droppableId) {
        const destTable = tables.find(
          (item) => item.id === destination.droppableId
        )
        const destItem = items.find(
          (item) => item.schart_item_id === destination.droppableId
        )

        if (!destTable || !destItem) {
          return
        }

        onChangeItem({
          schart_item_id: sourceTable.id,
          seats: sourceItem.seats.map((side) =>
            side.map((guest) =>
              guest?.guest_id === sourceTable.seats[source.index].id
                ? null
                : guest
            )
          ),
        })

        const nextGuests = Array.from(destTable.seats)
        nextGuests.splice(destination.index, 0, sourceTable.seats[source.index])

        const nextSeats = destItem.seats.map((side, sideIdx) => {
          if (sideIdx === 0) {
            return nextGuests.map(({ guest }) => guest).slice(0, side.length)
          }

          const prevLength = destItem.seats
            .slice(0, sideIdx)
            .reduce((prev, value) => prev + value.length, 0)

          return nextGuests
            .map(({ guest }) => guest)
            .slice(prevLength, prevLength + side.length)
        })

        return onChangeItem({
          schart_item_id: destination.droppableId,
          seats: nextSeats,
        })
      }
    },
    [tables, items, onChangeItem]
  )

  const renderTable = useCallback(
    (table: typeof tables[0]) => {
      const onRemoveItem = (e: MouseEvent<HTMLButtonElement>) => {
        const { seats } =
          items.find((item) => item.schart_item_id === table.id) || {}

        if (seats) {
          onChangeItem({
            schart_item_id: table.id,
            seats: seats.map((side) =>
              side.map((guest) =>
                guest?.guest_id === e.currentTarget.value ? null : guest
              )
            ),
          })
        }
      }

      return (
        <Box key={table.id} mb="8px">
          <Box
            height="24px"
            width="244px"
            bg="#F7F7FC"
            borderRadius="8px"
            pl="12px"
            display="flex"
            alignItems="center"
          >
            <Text fontWeight={600} fontSize="14px" color="#353B60">
              {table.caption}
            </Text>
          </Box>

          <Droppable droppableId={table.id}>
            {(provided) => (
              <div ref={provided.innerRef}>
                {table.seats.map((seat, index) => (
                  <Draggable
                    key={seat.id}
                    draggableId={seat.id}
                    index={index}
                    isDragDisabled={!seat.guest}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <GuestItem
                          index={index + 1}
                          guest={seat.guest}
                          onRemoveItem={onRemoveItem}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Box>
      )
    },
    [items, onChangeItem]
  )

  return (
    <>
      <Select
        options={options}
        value={selectedTable}
        onChange={onChange}
        styles={styles}
      />

      {!items.length && (
        <Box display="flex" justifyContent="center" mt="178px">
          <Text fontSize="16px" color="#353B60">
            Add a table to get started.
          </Text>
        </Box>
      )}

      <Box overflowY="auto" height="calc(100% - 144px)" mr="-16px">
        <DragDropContext onDragEnd={onDragEnd}>
          {selectedItem ? renderTable(selectedItem) : tables.map(renderTable)}
        </DragDropContext>
      </Box>
    </>
  )
}

export default Tables
