import { pathOr, prop, sort, compose } from 'ramda'
import { parseISO, isAfter, isValid } from 'date-fns'

import { Post } from 'store/modules/posts/reducer'

export const transformToRequest = (values: object) => ({
  caption: pathOr('', ['caption'], values),
  media: pathOr([], ['photos'], values).map(prop('photo_uid')).join(','),
})

export const sortByPinnedDate = compose<Post[], Post[]>(
  sort((a, b) => {
    const pinnedDateA = parseISO(a.pinned_date)
    const isValidPinnedDateA = isValid(pinnedDateA)
    const pinnedDateB = parseISO(b.pinned_date)
    const isValidPinnedDateB = isValid(pinnedDateB)

    if (!isValidPinnedDateA && isValidPinnedDateB) return 1

    if (isValidPinnedDateA && !isValidPinnedDateB) return -1

    if (!isValidPinnedDateA && !isValidPinnedDateB)
      return isAfter(parseISO(a.insert_date), parseISO(b.insert_date)) ? -1 : 1

    return isAfter(parseISO(a.pinned_date), parseISO(b.pinned_date)) ? -1 : 1
  })
  // defaultTo([])
)
