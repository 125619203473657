export const initialGroupsErrors = {
  isErr: false,
  groupName: false,
  groupItems: {
    1: { title: false },
  },
}

export const SetInitialGroupsErrors = (courses: any, item_id?: string) => {
  if (courses) {
    const groupItems: any = {}
    courses.groupItems.forEach((item: any) => {
      groupItems[item.item_id as keyof typeof groupItems] = { title: false }
    })

    return {
      isErr: false,
      groupName: false,
      groupItems,
    }
  } else if (item_id) {
    return {
      [item_id]: { title: false },
    }
  }
}

export const setGroupsErrors = (courses: any) => {
  let isErr = false
  const groupItems: any = {}
  courses.groupItems.forEach((item: any) => {
    !item.title && (isErr = true)

    groupItems[item.item_id as keyof typeof groupItems] = { title: item.title ? false : 'Title is Required' }
  })
  !courses.groupName && (isErr = true)
  return {
    isErr,
    groupName: courses.groupName ? false : 'Group name is required',
    groupItems,
  }
}