import { PIN } from '../actionTypes'

export const pinPost = (postId?: string, isPin: boolean = false) => ({
  type: PIN.name,
  api: {
    url: 'posts/pin',
    data: {
      post_id: postId,
      is_pin: isPin ? 1 : 0,
    },
  },
})
