import { Box } from 'components/ui'
import { buttonsGroupData } from './constants'
import { BtnGroup } from './utils'
import React from 'react'

const ButtonsGroup = ({ setModalIsOpen, setSelectGroupId, menugroup_id, updateGroupsByOrder }: any) => {

  const handleOpenModal = (action: any) => {
    return () => {
      if (action.action === 'edit' || action.action === 'group') {
        setSelectGroupId()
      }
      setModalIsOpen(action)
    }
  }


  return (
    <BtnGroup>
      {
        buttonsGroupData.map(({ id, icon, action, name }) => {
          return (
            <Box
              key={id}
              onClick={action ? handleOpenModal(action) : () => updateGroupsByOrder(menugroup_id, name)}
              height='40px'
              style={{ cursor: 'pointer' }}
            >
              <img src={icon} alt='...Loading' />
            </Box>)
        })
      }
    </BtnGroup>
  )
}

export default ButtonsGroup