import React, { FC, useCallback, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import states from 'states-us'
import Submit from '../../Submit'

// Utils
import {
  StyledDiv,
  ParamsType,
  ComponentProps,
  convertFormToPayload,
  handleValidate,
  countryOptions,
  textInputStyles,
} from '../../../utils'

import {
  parseGuestForEdit,
} from 'utils/guest'

// Store tools
import { useDispatch, useSelector } from 'react-redux'
import { editGuest } from 'store/modules/guests/actions'
import { getAllLists } from 'store/modules/guests/selectors'

// Components
import { Box } from 'components/ui'
import { Input, Select } from 'components/form'


const MailingAddress: FC<ComponentProps> = ({ guestForEdit, handleCloseEditForm }) => {
  const dispatch = useDispatch()

  const [statesOpt, setStateOpt] = useState([])

  const params = useParams<ParamsType>()

  const groups = useSelector(getAllLists)

  const onSubmit = useCallback(async (data: object) => {
    return dispatch(editGuest(data))
  }, [dispatch])

  useEffect(() => {
    const stateOptions: any = []
    states.forEach((state: any) => {
      stateOptions.push({
        value: state.abbreviation,
        label: state.name,
      })
    })
    stateOptions[0] = {
      value: ' ',
      label: ' ',
    }
    setStateOpt(stateOptions)
  }, [])

  const onSubmitForm = useCallback(async (data) => {
    try {
      data.wuid = params.wuid
      data.guest_id = guestForEdit.guest_id

      await onSubmit(convertFormToPayload(data))

      handleCloseEditForm()
    } catch (err) {
      toast(err.message)
    }
  }, [params.wuid, guestForEdit.guest_id, onSubmit, handleCloseEditForm])

  return (
    <StyledDiv>
      <Form onSubmit={onSubmitForm} initialValues={parseGuestForEdit(guestForEdit, groups, countryOptions, statesOpt)}
            validate={handleValidate}>
        {(props: any) => {
          const { country, state } = props.values

          return (
            <>
              <Box marginBottom='20px' marginTop='25px'>
                <Input
                  name='location_address'
                  label='Address'
                  placeholder='Address'
                  value={props.values.address}
                  style={textInputStyles}
                  hideErrorMessages={true}
                />
              </Box>

              <Box marginBottom='20px'>
                <Input
                  name='city'
                  label='City'
                  placeholder='City'
                  value={props.values.city}
                  style={textInputStyles}
                  hideErrorMessages={true}
                />
              </Box>

              <Box marginBottom='20px'>
                <Select
                  name='state'
                  label='State'
                  marginBottom='20px'
                  placeholder='Select a State'
                  menuPlacement='top'
                  value={state}
                  options={statesOpt}
                  hideErrorMessages={true}
                />

              </Box>

              <Box marginBottom='20px'>
                <Input
                  name='zip'
                  hideErrorMessages={true}
                  label='Zip Code'
                  style={textInputStyles}
                  placeholder='Zip Code'
                />
              </Box>

              <Select
                name='country'
                label='Country'
                marginBottom='20px'
                placeholder='Select a Country'
                menuPlacement='top'
                value={country}
                options={countryOptions}
                hideErrorMessages={true}
              />

              <Submit isInvalid={props.invalid} handleSubmit={props.form.submit} handleClose={handleCloseEditForm} />
            </>
          )
        }}
      </Form>
    </StyledDiv>
  )
}

export default MailingAddress