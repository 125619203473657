import React from 'react'

export const linkStrategy = (
  contentBlock: any,
  callback: any,
  contentState: any
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    )
  }, callback)
}

export const linkComponent = (props: any) => {
  const { contentState, entityKey } = props
  const { url } = contentState.getEntity(entityKey).getData()

  return (
    <a href={url} rel="noopener noreferrer" target="_blank" aria-label={url}>
      {props.children}
    </a>
  )
}

export const linkPlugin = {
  strategy: linkStrategy,
  component: linkComponent,
}
