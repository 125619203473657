import { curry, map, uniqBy, prop, replace, toUpper } from 'ramda'
import { all } from 'colornames'

export const hex2rgba = (hex = '', alpha = 1) => {
  const rgb = hex.match(/\w\w/g)

  if (!rgb) return 'rgba(0, 0, 0, 0)'

  const [r, g, b] = rgb.map((x) => parseInt(x, 16))

  return `rgba(${r},${g},${b},${alpha})`
}

export const shuffle = curry((list) => {
  let idx = -1
  let len = list.length
  let position
  let result: any[] = []

  while (++idx < len) {
    position = Math.floor((idx + 1) * Math.random())
    result[idx] = result[position]
    result[position] = list[idx]
  }

  return result
})

export const getColors = () =>
  shuffle(
    uniqBy(
      prop('value'),
      map(
        (color) => ({
          value: color.value,
          label: replace(/^./, toUpper, color.name),
        }),
        all()
      )
    )
  )

export const isDark = (hex: string) => {
  const r = parseInt(hex.substr(1, 2), 16),
    g = parseInt(hex.substr(3, 2), 16),
    b = parseInt(hex.substr(5, 2), 16),
    yiq = Math.round((r * 299 + g * 587 + b * 114) / 1000)

  return yiq <= 180
}
