import React, { FC, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

// Store tools
import { removeAlbum } from 'store/modules/albums/actions'
import { getAlbumById } from 'store/modules/albums/selectors'

// Components
import { Modal, Box, Text, Button } from 'components/ui'

type Props = {
  albumId?: string
  isOpen: boolean
  onClose: () => void
  isAlbumRemoved: boolean
  setIsAlbumRemoved: Function
}

const RemoveModal: FC<Props> = ({ albumId, isOpen, onClose, setIsAlbumRemoved, isAlbumRemoved }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const album = useSelector(getAlbumById(albumId))

  const onRemoveAlbum = useCallback(
    async (albumId) => dispatch(removeAlbum(albumId)),
    [dispatch],
  )

  const handleClose = useCallback(() => onClose(), [onClose])

  const handleDelete = useCallback(async () => {
    if (album) {
      setIsLoading(true)

      try {
        await onRemoveAlbum(album.album_id)
        setIsAlbumRemoved(!isAlbumRemoved)
      } catch (error) {
        toast(error.message)
      } finally {
        setIsLoading(false)
        handleClose()
      }
    }
  }, [onRemoveAlbum, handleClose, album])

  const modalStyle = { content: { maxWidth: 360, top: '40vh' } }

  if (!album) return null

  return (
    <Modal style={modalStyle} isOpen={!isOpen} onRequestClose={handleClose}>
      <Box py={3} px={4} borderRadius={0} bg='white'>
        <Box mb='6px'>
          <Text fontFamily='1' fontSize={3} fontWeight={600}>
            Delete Album
          </Text>
        </Box>

        <Text fontFamily='1'>
          Are you sure you want to delete the album, “{album.album_title}”?
        </Text>

        <Box display='flex' justifyContent='flex-end' mt={1}>
          <Box mr={0}>
            <Button
              border='none'
              px={2}
              py={0}
              fontFamily='1'
              fontWeight={600}
              fontSize='18px'
              color='blue'
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>

          <Button
            border='none'
            px={2}
            py={0}
            fontFamily='1'
            fontSize='18px'
            color='red'
            disabled={isLoading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default RemoveModal
