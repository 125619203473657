import styled from 'styled-components'

import { Button } from 'components/ui'

const SelectButton = styled(Button).attrs({
  position: 'absolute',
  top: 1,
  left: 1,
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  border: '2px solid',
  borderColor: 'white',
  p: '0px',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.08);',
})``

export default SelectButton
