import React, { FC, useState, useCallback, useEffect } from 'react'
import { FormRenderProps } from 'react-final-form'
import { renderToString } from 'react-dom/server'
import { Box, Button, Text } from 'components/ui'
import { Input } from 'components/form'
import { ErrorAlert } from 'components/Auth'
import CustonButton from 'utils/Button'
import FbGoogle from 'routes/Login/FbGoogle'
import { ReactComponent as EyeShow } from 'images/eyeShow.svg'
import { ReactComponent as EyeHidden } from 'images/eyeHidden.svg'

const Form: FC<FormRenderProps> = ({
  submitting,
  handleSubmit,
  values,
  hasValidationErrors,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [isShown, setIsShown] = useState<boolean>(false)

  const handleOnFocusPassword = useCallback(
    () => setIsPasswordVisible(true),
    []
  )
  const handleOnBlurPassword = useCallback(
    () => setIsPasswordVisible(false),
    []
  )
  const getEyeIcon = () => {
    if (isShown) {
      return renderToString(<EyeShow />)
    } else {
      return renderToString(<EyeHidden />)
    }
  }
  const eyeIconSrc = `data:image/svg+xml;base64,${btoa(getEyeIcon())}`

  return (
    <Box
      as="form"
      autoComplete="off"
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit}
    >
      {isSubmitClicked &&
        !/^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}/.test(
          values.password
        ) &&
        values.password && (
          <ErrorAlert messages="Complete all required password conditions!" />
        )}
      {hasValidationErrors && isSubmitClicked && !values.password && (
        <ErrorAlert messages="Fill in required fields" />
      )}

      <Box display="flex" flexDirection="row">
        <Box width="50%">
          <label
            style={{
              fontFamily: 'Lato',
              fontSize: '12px',
              marginBottom: '20px',
              fontWeight: 400,
              lineHeight: '18px',
              width: '29px',
              height: '18px',
              color: '#414042',
            }}
          >
            First name
          </label>
          <Input
            autoComplete="disabled"
            name="first_name"
            placeholder="First name"
            height="48px"
            width="192px"
            hideErrorMessages
            style={{ marginTop: '4px' }}
          />
        </Box>

        <Box width="50%" ml={1}>
          <label
            style={{
              fontFamily: 'Lato',
              fontSize: '12px',
              marginBottom: '20px',
              fontWeight: 400,
              lineHeight: '18px',
              width: '29px',
              height: '18px',
              color: '#414042',
            }}
          >
            Last name
          </label>
          <Input
            autoComplete="disabled"
            name="last_name"
            placeholder="Last name"
            height="48px"
            width="192px"
            hideErrorMessages
            style={{ marginTop: '4px' }}
          />
        </Box>
      </Box>
      <Box mt="16px">
        <label
          style={{
            fontFamily: 'Lato',
            fontSize: '12px',
            marginBottom: '20px',
            fontWeight: 400,
            lineHeight: '18px',
            width: '29px',
            height: '18px',
            color: '#414042',
          }}
        >
          Email
        </label>
        <Input
          autoComplete="disabled"
          placeholder="Enter email"
          name="email"
          type="email"
          height="48px"
          hideErrorMessages
          style={{ marginTop: '4px' }}
        />
      </Box>

      <Box style={{ position: 'relative' }} mt="16px">
        <label
          style={{
            fontFamily: 'Lato',
            fontSize: '12px',
            marginBottom: '20px',
            fontWeight: 400,
            lineHeight: '18px',
            width: '29px',
            height: '18px',
            color: '#414042',
          }}
        >
          Password
        </label>
        <Input
          autoComplete="disabled"
          placeholder="Create password"
          name="password"
          type={isShown ? 'text' : 'password'}
          height="48px"
          hideErrorMessages
          onFocus={handleOnFocusPassword}
          onBlur={handleOnBlurPassword}
          style={{ marginTop: '4px' }}
        />

        <button
          type="button"
          onClick={() => setIsShown(!isShown)}
          style={{
            position: 'absolute',
            top: '70%',
            transform: 'translateY(-50%)',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            right: '2.5%',
          }}
        >
          <img
            src={eyeIconSrc}
            alt={isShown ? 'Hide password' : 'Show password'}
          />
        </button>
      </Box>

      <Text
        color=" #A3A3AC"
        fontFamily="Lato"
        fontSize="12px"
        lineHeight="18px"
        fontWeight="400"
        style={{ marginBottom: '24px', marginTop: '4px' }}
      >
        At least 8 characters, 1 upper-cased letter, and 1 special character
      </Text>
      <Box>
        <CustonButton
          submitting={submitting}
          buttonAction={'Register'}
          setIsSubmitClicked={setIsSubmitClicked}
        />
      </Box>
      <Box display="flex" flexDirection="row" width="100%" mt="12px">
        <Box
          borderTop="1px solid"
          borderColor="gray"
          width="33%"
          mt="25px"
          mb="20px"
        />
        <Text
          fontWeight={600}
          fontFamily="Lato"
          fontSize="14px"
          lineHeight="20px"
          style={{
            margin: '16px 8.9px 8px 9px',
            color: '#73737B',
            fontWeight: '400',
          }}
        >
          Or continue with
        </Text>
        <Box
          borderTop="1px solid"
          borderColor="gray"
          width="36.6%"
          mt="25px"
          mb="20px"
        />
      </Box>
      <FbGoogle />

      <Text
        color="darkGray"
        fontFamily="Lato"
        lineHeight="20px"
        textAlign="center"
        style={{ fontSize: '14px', marginRight: '36px' }}
      >
        By clicking Register, you agree to the HitchHero <br />
        <a
          href={`${process.env.REACT_APP_WEBSITE_URI || ''}/terms`}
          style={{
            textDecoration: 'none',
            color: '#5458F7',
            fontWeight: 'bold',
          }}
        >
          Terms of Use
        </a>{' '}
        and{' '}
        <a
          href={`${process.env.REACT_APP_WEBSITE_URI || ''}/privacy`}
          style={{
            textDecoration: 'none',
            color: '#5458F7',
            fontWeight: 'bold',
          }}
        >
          Privacy Policy
        </a>
      </Text>
    </Box>
  )
}

export default Form
