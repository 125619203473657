import React, { FC } from 'react'
import EasyCrop from 'react-easy-crop'
import styled from 'styled-components'

import { Box, Slider } from 'components/ui'
import { ReactComponent as MinusIcon } from 'images/minus.svg'
import { ReactComponent as PlusIcon } from 'images/plus.svg'

const CropContainer = styled(Box).attrs({
  position: 'relative',
  width: '100%',
})`
  & > div {
    border-radius: ${(props) => props.theme.radii[0]}px;
  }
`

type Props = {
  image: string
  crop: any
  zoom?: number
  containerHeight?: string | number
  onCropChange: (x: any) => void
  onCropComplete: (x1: any, x2: any) => void
  onZoomChange: (zoom: number) => void
}

const Cropper: FC<Props> = ({
  image,
  crop,
  zoom,
  containerHeight = '225px',
  onCropChange,
  onCropComplete,
  onZoomChange,
}) => (
  <Box width="100%" display="flex" flexDirection="column" alignItems="center">
    <CropContainer height={containerHeight}>
      <EasyCrop
        image={image}
        crop={crop}
        showGrid={false}
        zoom={zoom}
        aspect={4 / 3}
        onCropChange={onCropChange}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
      />
    </CropContainer>

    <Box
      mt="12px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <MinusIcon fill="#73737B" width={27} height={27} />

      <Box width="220px" mx="18px" mt="-5px">
        <Slider
          variant="medium"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={(e: any) => onZoomChange(e.currentTarget.value)}
        />
      </Box>

      <PlusIcon fill="#73737B" width={27} height={27} />
    </Box>
  </Box>
)

export default Cropper
