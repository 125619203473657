import React, { FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { Box, FormLabel, NoteInput } from '../ui'

type Props = {
  name: string
  label?: string
  isRequired?: boolean
  placeholder?: string
}

const FormNoteInput: FC<Props> = ({ name, label, isRequired, placeholder }) => {
  const renderNoteInput = ({ input }: FieldRenderProps<string>) => (
    <Box>
      {label && <FormLabel isRequired={isRequired}>{label}</FormLabel>}

      <NoteInput {...input} placeholder={placeholder} />
    </Box>
  )

  return <Field name={name} render={renderNoteInput} />
}

export default FormNoteInput
