import React, { FC, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { map, filter, hasPath } from 'ramda'
import { toast } from 'react-toastify'

import { Select } from 'components/form'
import { getGuests, getListsIsLoaded } from 'store/modules/guests/selectors'
import { Guest } from 'store/modules/guests/reducer'
import { listAdmin } from 'store/modules/guests/actions'
import { getFullName } from 'utils/guest'
import Option from './Option'

const GuestSelect: FC = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const guests = useSelector(getGuests)
  const isLoaded = useSelector(getListsIsLoaded)

  const guestToOption = (guest: Guest) => ({
    label: getFullName([guest.user.first_name, guest.user.last_name]),
    value: guest.user.uuid,
    user: guest.user,
  })

  const handleLoadGuests = useCallback(async () => dispatch(listAdmin()), [
    dispatch,
  ])
  const handleLoad = useCallback(async () => {
    setIsLoading(true)

    try {
      await handleLoadGuests()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading, handleLoadGuests])

  useEffect(() => {
    if (!isLoaded) handleLoad()
  }, [isLoaded, handleLoad])

  return (
    <Select
      name="guest"
      isClearable={false}
      isLoading={isLoading}
      placeholder="Select User"
      options={map(guestToOption, filter(hasPath(['user', 'uuid']), guests))}
      components={{ Option }}
    />
  )
}

export default GuestSelect
