import React, { FC } from 'react'

import { Wedding } from 'store/modules/wedding/reducer'
import { Box, Text } from 'components/ui'
import { getDate, getImage } from 'utils/wedding'
import CountDown from './CountDown'

type Props = {
  wedding_image: Wedding['wedding_image']
  wedding_name: Wedding['wedding_name']
  theme_font: Wedding['theme_font']
  wedding_date: Wedding['wedding_date']
  approx_date_month: Wedding['approx_date_month']
  approx_date_year: Wedding['approx_date_year']
}

const WeddingDetailsInfo: FC<Props> = ({
  wedding_image,
  wedding_name,
  theme_font,
  wedding_date,
  approx_date_month,
  approx_date_year,
}) => {
  const date = getDate({
    wedding_date,
    approx_date_month,
    approx_date_year,
  })

  return (
    <Box
      height="324px"
      width="100%"
      backgroundImage={`url(${getImage(wedding_image, 'large')})`}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      pt="44px"
      pb="24px"
      boxShadow="inset 0 0 0 324px rgba(0, 0, 0, 0.2)"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Text
          fontFamily={theme_font}
          fontSize="48px"
          color="white"
          fontWeight={600}
          lineHeight={1}
        >
          {wedding_name}
        </Text>

        <Text fontSize="15px" fontWeight={600} color="white" lineHeight={1.33}>
          {date || 'Date TBD'}
        </Text>
      </Box>

      <CountDown date={wedding_date} />
    </Box>
  )
}

export default WeddingDetailsInfo
