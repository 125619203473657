import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router'
import { Form } from 'react-final-form'
import { mergeAll } from 'ramda'
import validate from 'validate.js'

import { Box, Button } from 'components/ui'
import { ReactComponent as Logo } from 'images/hitchhero-logo.svg'
import { passwordConstraint, emailConstraint } from 'constants/constraints'
import { RESET_PASSWORD, WEDDING_CREATE } from 'constants/paths'
import { login } from 'store/modules/auth/actions'
import {
  AuthForm,
  MainBox,
  Title,
  Subtitle,
  AppPreview,
} from 'components/WeddingWizard'
import { getIsLoggedIn } from 'store/modules/auth/selectors'

type Props = {
  color?: { value: string; label: string }
  color2?: { value: string; label: string }
  title?: string
  font?: { value: string; label: string }
  image?: string
}

const Login = (props: Props) => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const isLoggedIn = useSelector(getIsLoggedIn)

  const onLogin = useCallback(async (data) => dispatch(login(data)), [dispatch])

  const handleClickSignup = useCallback(
    () =>
      push({
        pathname: `${WEDDING_CREATE}/signup`,
        state: { transition: 'wizard-content-backward' },
      }),
    [push]
  )

  const handleClickForgotPassword = useCallback(() => push(RESET_PASSWORD), [
    push,
  ])

  const handleValidate = useCallback(
    (values: object) =>
      validate(values, mergeAll([passwordConstraint(), emailConstraint()])),
    []
  )

  const handleSubmit = useCallback(
    async (
      values: { email: string; password: string },
      _: any,
      complete?: (x: any) => void
    ) => {
      try {
        await onLogin(values)

        push(`${WEDDING_CREATE}/step/8`)
      } catch (error) {
        if (complete) {
          complete([error.message])
        }
      }
    },
    [onLogin, push]
  )

  if (isLoggedIn) {
    return <Redirect to={`${WEDDING_CREATE}/step/8`} />
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems={['flex-start', 'center']}
      minHeight={['calc(100vh - 96px)', 'calc(100vh - 180px)']}
    >
      <MainBox pt="70px" pb="77px">
        <Box mt="-92px" mb="30px">
          <Logo />
        </Box>

        <Title>Login to your account</Title>

        <Box mt="8px" />

        <Subtitle>Please login so we can save your wedding details.</Subtitle>

        <Box display="flex" alignItems="center" mb="40px">
          <Subtitle>Not a member?</Subtitle>

          <Button
            color="primary"
            fontSize="20px"
            fontWeight="600"
            height="20px"
            onClick={handleClickSignup}
          >
            Join now!
          </Button>
        </Box>

        <Box
          maxWidth="400px"
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Form
            validate={handleValidate}
            onSubmit={handleSubmit}
            component={AuthForm}
            isLogin
          />
        </Box>

        <Button
          border="none"
          onClick={handleClickForgotPassword}
          fontFamily="1"
          fontSize={0}
          fontWeight={600}
          lineHeight="14px"
          color="primary"
          height="16px"
          mt="20px"
        >
          Forgot Password?
        </Button>
      </MainBox>

      <Box position="absolute" bottom="0px" right="0px" left="0px" zIndex={1}>
        <AppPreview {...props} />
      </Box>
    </Box>
  )
}

export default Login
