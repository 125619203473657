import React, { FC, UIEvent, useCallback, useState } from 'react'

import { ReactComponent as CheckIcon } from 'images/check.svg'
import { isDark } from 'utils/colors'
import Modal from '../Modal'
import Box from '../Box'
import Text from '../Text'
import Button from '../Button'

type Props = {
  isOpen: boolean
  options: { label: string; value: string }[]
  onClose: VoidFunction
  onLoadMore: VoidFunction
  onSelect: (value?: { label: string; value: string }) => void
}

const ColorSelectorModal: FC<Props> = ({
  isOpen,
  options,
  onClose,
  onLoadMore,
  onSelect,
}) => {
  const [selectedValue, setSelectedValue] = useState<{
    label: string
    value: string
  }>()

  const modalStyle = { content: { maxWidth: 596, top: 180 } }

  const handleScroll = useCallback(
    ({ currentTarget }: UIEvent<HTMLDivElement>) => {
      if (
        currentTarget.scrollHeight - currentTarget.scrollTop ===
        currentTarget.clientHeight
      ) {
        onLoadMore()
      }
    },
    [onLoadMore]
  )
  const handleSelect = useCallback(
    (value: { label: string; value: string }) => () => setSelectedValue(value),
    []
  )
  const handleSave = useCallback(() => {
    if (selectedValue) {
      onSelect(selectedValue)
      onClose()
    }
  }, [onSelect, selectedValue, onClose])

  return (
    <Modal isOpen={isOpen} style={modalStyle} onRequestClose={onClose}>
      <Box pt={1} px="28px" pb="35px" borderRadius={0} bg="white">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text fontFamily="1" fontSize="22px" fontWeight={600}>
            Select a Color
          </Text>

          <Box display="flex" alignItems="center">
            <Button fontSize="18px" color="primary" onClick={onClose}>
              Cancel
            </Button>

            <Box ml={3}>
              <Button
                variant="primary"
                disabled={!selectedValue}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexWrap="wrap"
          mx="-4px"
          mt="14px"
          pt={0}
          height="432px"
          overflowY="auto"
          boxShadow="inset 0px 4px 4px -2px rgba(0, 0, 0, 0.08), inset 0px -4px 4px -2px rgba(0, 0, 0, 0.08)"
          onScroll={handleScroll}
        >
          {options.map((option) => (
            <Box key={option.value} width={1 / 3} mb={0} px="4px">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                py={2}
                border="1px solid"
                borderColor={
                  selectedValue?.value === option.value ? 'darkGray' : 'gray'
                }
                borderRadius={0}
                cursor="pointer"
                onClick={handleSelect(option)}
              >
                <Box
                  width="80px"
                  height="80px"
                  borderRadius="50%"
                  bg={option.value}
                  mb={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {selectedValue?.value === option.value && (
                    <CheckIcon
                      fill={isDark(option.value) ? '#ffffff' : '#1e2027'}
                      width="30px"
                      height="30px"
                    />
                  )}
                </Box>

                <Text fontFamily="1" color="darkGray">
                  {option.label}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}

export default ColorSelectorModal
