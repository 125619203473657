import React, { useState, useCallback, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { types } from 'routes/Albums/utils'
import AddPhotoButton from '../AddPhotoButton'

// Components
import { Button, Box, Text } from 'components/ui'

// Store tools
import { Album } from 'store/modules/albums/reducer'
import { getUser } from 'store/modules/auth/selectors'
import { loadAlbumPhotos } from 'store/modules/albums/actions'

// Icons
import { ReactComponent as PhotosIcon } from 'images/photos.svg'
import { ReactComponent as MoreIcon } from 'images/more.svg'
import { ReactComponent as SharedIcon } from './shared.svg'
import albumImage1 from 'images/album-image-1.svg'
import albumImage2 from 'images/album-image-2.svg'
import albumImage3 from 'images/album-image-3.svg'
import albumImage4 from 'images/album-image-4.svg'
import noCoverSrc from 'images/overlay.png'

const Cover = styled(Box).attrs({
  width: '100%',
  height: '100%',
})`

  & > #albumCardMenu {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    & > #albumCardMenu {
      opacity: 1;
      visibility: visible;
    }
  }
`

const MenuItem = styled(Button).attrs({
  as: 'button',
  width: '100%',
  height: '40px',
  bg: 'white',
  justifyContent: 'flex-start',
  pl: '16px',
})`
  &:hover {
    background: #f7f7fc;
  }

  border-bottom: 1px solid #e8e8ed;
  border-right: 1px solid #e8e8ed;
  border-left: 1px solid #e8e8ed;

  &:first-child {
    border-left: 1px solid #e8e8ed;
    border-top: 1px solid #e8e8ed;
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-right: 1px solid #e8e8ed;
    border-radius: 0 0 8px 8px;
  }

  &:only-child {
    border: 1px solid #e8e8ed;
    border-radius: 8px;
  }
`

const BackgroundImageStyled = styled(Box)<{ isCoverImageExists: boolean }>`
  height: 218px;
  border-radius: 8px;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 12px;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${(props) => (props?.isCoverImageExists ? 'rgba(0, 0, 0, .3)' : null)};
    border-radius: 8px;
  }
`

const AlbumWhiteBoxStyled = styled(Box)`
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  z-index: 2;
  bottom: -2px;
  border-bottom: 1px solid #E8E8ED;
  max-height: 60px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`

type Props = {
  onClickItem: (e: MouseEvent<HTMLButtonElement>) => void
  onClickRemove: VoidFunction
  onClickUpdate: VoidFunction
  onClickShare: VoidFunction
  onClickExport: VoidFunction
  wuid: string
  album_id: string
  setIsMore: Function
}

const albumImages = [
  {
    src: albumImage1,
  },
  {
    src: albumImage2,
  },
  {
    src: albumImage3,
  },
  {
    src: albumImage4,
  },
]

const AlbumCard = ({
                     album_title,
                     number_of_photos,
                     cover_image,
                     album_type,
                     system_type,
                     onClickItem,
                     onClickRemove,
                     onClickUpdate,
                     onClickShare,
                     onClickExport,
                     album_owner_image,
                     album_id,
                     wuid,
                     setIsMore,
                   }: Album & Props) => {

  const dispatch = useDispatch()

  const user = useSelector(getUser)

  const [isMenuOpen, setMenuOpen] = useState(false)

  const onLoadAlbumPhotos = useCallback(async (data) => dispatch(loadAlbumPhotos(data)), [dispatch])

  const onClickMenuButton = useCallback(
    () => {
      setMenuOpen((isOpen) => !isOpen)
      setIsMore(false)

      if (album_id) {
        const data = {
          album_id: album_id,
          wuid: wuid,
        }
        onLoadAlbumPhotos(data)
      }
    },
    [album_id, onLoadAlbumPhotos, setIsMore, wuid],
  )

  return (
    <Box
      width='100%'
      height='218px'
      border='1px solid #E8E8ED'
      borderRadius='8px'
      marginBottom='24px'
    >
      <Box maxHeight='158px' height='100%' position='relative'>
        <Cover>
          <Box
            position='absolute'
            right='0px'
            top='0px'
            width='100%'
            height='100%'
            backgroundImage='transparent'
            display='flex'
            flexDirection='row-reverse'
            borderTopLeftRadius='8px'
            borderTopRightRadius='8px'
            borderRadius='8px'
          >
            <Button
              style={{
                height: '38px',
                backgroundColor: 'transparent',
                padding: 0,
                marginTop: '12px',
                marginRight: '12px',
                zIndex: 3,
              }}
              onClick={onClickMenuButton}
            >
              <MoreIcon />
            </Button>

            <Button
              as='button'
              style={{ zIndex: 2 }}
              position='absolute'
              left='0px'
              top='0px'
              width='100%'
              height='100%'
              bg='transparent'
              onClick={onClickItem}
            />
          </Box>

          {isMenuOpen && (
            <Box
              id='albumCardMenu'
              width='120px'
              position='absolute'
              right='12px'
              top='50px'
              zIndex={4}
            >

              {Number(number_of_photos) > 0 &&
              <MenuItem onClick={onClickExport}>
                <Text fontSize='14px' color='#353B60'>
                  Export
                </Text>
              </MenuItem>
              }

              {system_type === '0' && (
                <>
                  <MenuItem onClick={onClickShare}>
                    <Text fontSize='14px' color='#353B60'>
                      Share
                    </Text>
                  </MenuItem>
                  <AddPhotoButton wuid={wuid} albumId={album_id} />
                  <MenuItem onClick={onClickUpdate}>
                    <Text fontSize='14px' color='#353B60'>
                      Rename
                    </Text>
                  </MenuItem>

                  <MenuItem onClick={onClickRemove}>
                    <Text fontSize='14px' color='#353B60'>
                      Delete
                    </Text>
                  </MenuItem>
                </>
              )}
            </Box>
          )}

          {album_title !== '' && (
            <Box position='absolute' left='16px' bottom='16px' zIndex={3}>
              <Text
                style={{
                  fontFamily: 'Source Sans Pro',
                  fontWeight: 700,
                  fontSize: '32px',
                  lineHeight: '40px',
                  color: '#FFFFFF',
                }}
              >
                {album_title}
              </Text>
            </Box>
          )}

          {album_type === '1' && (
            <Box
              width='44px'
              height='44px'
              bg='#353B60'
              border='1px solid #FFFFFF'
              borderRadius='8px 0px'
              p='0px'
              display='flex'
              justifyContent='center'
              alignItems='center'
              position='absolute'
              top='157px'
              right='-1px'
              zIndex={3}
            >
              <SharedIcon />
            </Box>
          )}

          <BackgroundImageStyled
            isCoverImageExists={cover_image?.url ? true : false}
            style={{ backgroundImage: `url(${cover_image?.url ?? noCoverSrc})` }}
          />
        </Cover>
      </Box>
      <AlbumWhiteBoxStyled>
        <Box display='flex' justifyContent='space-between' alignItems='center' style={{ gap: 16 }}>
          <Box display='flex' alignItems='center'>
            <PhotosIcon />

            <Box mr='4px' />

            <Text
              fontWeight='400'
              fontSize='14px'
              lineHeight='18px'
              color='#A3A3AC'
            >
              {number_of_photos} Photos
            </Text>
          </Box>
          <Box display='flex' alignItems='center'>
            {types[Number(album_type)]?.icon}

            <Box mr='4px' />

            <Text
              fontWeight='400'
              fontSize='14px'
              lineHeight='18px'
              color='#A3A3AC'
            >
              {types[Number(album_type)]?.name}
            </Text>
          </Box>
        </Box>
        <Box height='24px'>
          {album_type === '2' || album_type === '3' || album_type === '0' ? (
            album_owner_image !== undefined ? (
              <Box
                as='img'
                src={album_owner_image}
                alt='images'
                style={{ width: '24px', height: '24px', borderRadius: '50%', objectFit: 'cover' }}
              />
            ) : (
              <Box
                width='24px'
                height='24px'
                backgroundColor='#353B60'
                borderRadius='50%'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <Text
                  fontWeight='700'
                  fontSize='12px'
                  lineHeight='15px'
                  color='#FFFFFF'
                >
                  {user?.first_name.slice(0, 1).toUpperCase()}{user?.last_name.slice(0, 1).toUpperCase()}
                </Text>
              </Box>
            )
          ) : (
            albumImages.length > 0 && (
              <Box display='flex' justifyContent='flex' alignItems='center' marginRight='-12px'>
                {albumImages.map((img: { src: string }, index) => {
                  return (
                    <Box key={index}>
                      {index <= 2 && (
                        <Box
                          as='img'
                          src={img.src}
                          alt='images'
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            objectFit: 'cover',
                            position: 'relative',
                            left: index === 1 ? '-4px' : index === 2 ? '-8px' : 'unset',
                          }}
                        />
                      )}
                    </Box>
                  )
                })}
                {albumImages.length >= 3 && (
                  <Box
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      backgroundColor: '#FFFFFF',
                      position: 'relative',
                      left: '-12px',
                      textAlign: 'center',
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'Source Sans Pro',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#73737B',
                      }}
                    >
                      +{albumImages.length - 3}
                    </Text>
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
      </AlbumWhiteBoxStyled>
    </Box>
  )
}

export default AlbumCard
