import { Reducer } from 'redux'
import { parseISO } from 'date-fns'

import { PUSH_FILE, ADD, CHANGE_FILE_OFFSET, CLEAR_FILES } from './actionTypes'

export const initialState = {
  files: [],
}

type MediaFile = {
  hash: string
  tus_id: string
  is_uploaded: -1 | 0 | 1
  original: string
  photo_id: string
  src_file: string
  total_length: number
  upload_length: number
  insert_date: Date
  wedding_wuid: string
  media_type: 'image' | 'video'
}

export type State = {
  files: MediaFile[]
}

const photosReducer: Reducer = (state: State = initialState, action) => {
  switch (action.type) {
    case PUSH_FILE:
      return { ...state, files: [...state.files, action.payload] }
    case ADD.FULFILLED: {
      const { photo } = action.payload.payload

      return {
        ...state,
        files: state.files.map((file) =>
          file.src_file === photo.src_file
            ? {
                ...photo,
                is_uploaded: parseInt(photo.is_uploaded),
                total_length: parseInt(photo.total_length),
                upload_length: parseInt(photo.upload_length),
                insert_date: parseISO(photo.insert_date),
                media_type: photo.media_type === '0' ? 'image' : 'video',
              }
            : file
        ),
      }
    }
    case CHANGE_FILE_OFFSET: {
      return {
        ...state,
        files: state.files.map((file) =>
          file.src_file === action.payload.src_file
            ? { ...file, ...action.payload }
            : file
        ),
      }
    }
    case CLEAR_FILES:
      return initialState
    default:
      return state
  }
}

export default photosReducer
