import { LOAD_PHOTOS } from '../actionTypes'

export const loadPhotos = (page_num: number = 1) => ({
  type: LOAD_PHOTOS.name,
  api: {
    url: 'photos/list',
    data: {
      page_num,
      per_page: 24,
      is_public: 1,
      uuid: null,
      field_list: 'all_fields',
      is_uploaded: 1,
    },
  },
})
