import React from 'react'
import { FC, useContext } from 'react'
import StartEndDateButtons from './StartEndDateButtons'
import ImportDeleteButtons from './ImportDeleteButtons'
import { Context } from '../Context'

// Components
import { Box, SearchInput } from 'components/ui'

// Utils
import { filterMenuItems, SearchBox } from '../utils'
import EventsFilter from './EventsFilter'

const ButtonsGroup: FC = () => {
  const {
    selectedFilter,
    activeTableType,
    handleFilterEvents,
    handleSearch,
  } = useContext<any>(Context)

  return (
    <Box marginTop='16px' marginBottom={activeTableType === 'By Week' ? '24px' : '4px'}>
      <Box display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <Box display='flex' alignItems='center' flexWrap='wrap'>
          <EventsFilter menuItems={filterMenuItems} handleClick={handleFilterEvents} selectedFilter={selectedFilter} />
          <StartEndDateButtons />
          <Box mr='12px' height='40px' width='1px' backgroundColor='#E8E8ED' />
          <ImportDeleteButtons />
        </Box>
        <Box display='flex' alignItems='center' flexWrap='wrap'>
          <SearchBox>
            <SearchInput onChange={handleSearch} />
          </SearchBox>
        </Box>
      </Box>
    </Box>
  )
}

export default ButtonsGroup
