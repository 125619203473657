import React, { FC, MouseEvent } from 'react'
import styled from 'styled-components'
import { typography } from 'styled-system'

import { Box } from 'components/ui'
import { ReactComponent as Icon } from 'images/magnify.svg'

const Input = styled(Box).attrs({
  as: 'input',
  border: 'none',
  borderRadius: '0px',
  height: '100%',
  pl: '48px',
  pr: 0,
  fontFamily: 0,
  fontSize: 0,
  width: '100%',
})`
  ${typography}
`

type Props = {
  onChange: (e: MouseEvent<HTMLInputElement>) => void
}

const SearchInput: FC<Props> = ({ onChange }) => (
  <Box
    position="relative"
    width="100%"
    height="46px"
    borderBottom="1px solid"
    borderBottomColor="gray"
  >
    <Input placeholder="Search by name" onChange={onChange} />

    <Box position="absolute" top="12px" left={1}>
      <Icon stroke="#6d7589" height={16} width={16} />
    </Box>
  </Box>
)

export default SearchInput
