import React, { useState, useCallback, MouseEvent } from 'react'
import { useSelector } from 'react-redux'

import { getSChart } from 'store/modules/seatings/selectors'
import { SChartItem } from 'store/modules/seatings/reducer'
import { selectIsExpanded } from 'store/modules/seatings/selectors'
import { Box } from 'components/ui'
import Tabs from './Tabs'
import Tables from './Tables'
import Guests from './Guests'

export enum TabName {
  GUESTS = 'Not Seated',
  TABLES = 'Seated',
}

type Props = {
  chartId: string
  items: SChartItem[]
  isHidden: boolean
  onChangeItem: (payload: Partial<SChartItem>) => void
}

const Panel = ({ chartId, items, isHidden, onChangeItem }: Props) => {
  const schart = useSelector(getSChart(chartId))
  const [activeTab, setActiveTab] = useState<TabName>(TabName.GUESTS)
  const isSeatingChartExpanded = useSelector(selectIsExpanded)

  const toggleTab = useCallback(
    (e: MouseEvent<HTMLButtonElement>) =>
      setActiveTab((e.currentTarget.value as unknown) as TabName),
    []
  )

  return (
    <Box
      minWidth="276px"
      width="276px"
      bg="#FFFFFF"
      boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
      borderRadius="10px"
      p="16px"
      position="absolute"
      right="16px"
      bottom="16px"
      style={{
        visibility: isHidden ? 'hidden' : 'visible',
        opacity: isHidden ? 0 : 1,
        top: isSeatingChartExpanded ? 16 : 104,
        transition:
          'visibility 0s linear 0.2s, opacity 0.2s ease 0s, top 0.2s ease-in-out 0s',
      }}
    >
      <Tabs activeTab={activeTab} onClick={toggleTab} />

      <Box
        my="24px"
        width="100%"
        borderBottom="1px dashed rgba(53, 59, 96, 0.4)"
      />

      {activeTab === TabName.TABLES ? (
        <Tables items={items} onChangeItem={onChangeItem} />
      ) : (
        <Guests sChart={schart} items={items} />
      )}
    </Box>
  )
}

export default Panel
