export const N_GUESTS_LABELS = ['50', '100', '150', '200', '300', '300+']
export const N_GUESTS_LABELS_OPTIONS = [
  '0-50',
  '50-100',
  '150-200',
  '200-250',
  '250-300',
  '300+',
]

export const SEASON_VALUES = [13, 14, 15, 16]
export const SEASON_LABELS = {
  [SEASON_VALUES[0]]: 'Spring',
  [SEASON_VALUES[1]]: 'Summer',
  [SEASON_VALUES[2]]: 'Fall',
  [SEASON_VALUES[3]]: 'Winter',
}

export const DEFAULT_COLORS = [
  '#f83b2b',
  '#ff457b',
  '#b93bde',
  '#7e52ec',
  '#536ff1',
  '#469df4',
]

export const ROLE_VALUES = [
  'bride',
  'groom',
  'weddingPlanner',
  'bridesmaid',
  'groomsman',
  'bridesFamily',
  'bridesFriends',
  'groomsFamily',
  'groomsFriends',
]
export const ROLE_LABELS = {
  [ROLE_VALUES[0]]: 'Bride',
  [ROLE_VALUES[1]]: 'Groom',
  [ROLE_VALUES[2]]: 'Wedding Planner',
  [ROLE_VALUES[3]]: 'Bridesmaid',
  [ROLE_VALUES[4]]: 'Groomsman',
  [ROLE_VALUES[5]]: 'Bride’s Family',
  [ROLE_VALUES[6]]: 'Bride’s Friends',
  [ROLE_VALUES[7]]: 'Groom’s Family',
  [ROLE_VALUES[8]]: 'Groom’s Friends',
}

export const PAGE_TYPES = ['layout', 'planner', 'messaging']
export const PAGE_TYPE_LABELS = {
  [PAGE_TYPES[0]]: 'Layout',
  [PAGE_TYPES[1]]: 'Planner',
  [PAGE_TYPES[2]]: 'Messaging',
}
