import React, { FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

import Box from './Box'

export const Label = styled(Box).attrs({
  as: 'label',
  width: '100%',
  height: '20px',
  background: '#ebebff',
  borderRadius: '12px',
  position: 'absolute',
})`
  text-indent: -9999px;
  transition: all ease 0.2s;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    background: #949cb0;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    transition: all ease 0.2s;
    top: 0;
    bottom: 0;
    margin: auto 2px;
  }
`

export const Input = styled(Box).attrs({
  as: 'input',
  type: 'checkbox',
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  margin: '0px',
})<InputHTMLAttributes<HTMLInputElement>>`
  opacity: 0;
  cursor: pointer;

  &:checked + ${Label} {
    :after {
      left: 100%;
      transform: translateX(-100%);
      background: ${(props) => props.theme.colors.white};
      margin: auto -2px;
    }

    background: #63C7C9;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled + ${Label} {
    background: #e8e8ed;

    :after {
      background: #e5e5e5;
    }
  }
`

type Props = {
  id?: string
  checked?: boolean
  disabled?: boolean
  onChange?: (x: any) => any
}

const Switch: FC<Props> = ({ id, checked = false, disabled, onChange }) => (
  <Box display='flex' alignItems='center'>
    <Box
      position='relative'
      width='36px'
      height='20px'
      display='flex'
      alignItems='center'
    >
      <Input
        id={id}
        name='is_selected'
        checked={checked}
        disabled={disabled}
        readOnly={!onChange}
        onChange={onChange}
      />

      <Label />
    </Box>
  </Box>
)

export default Switch
