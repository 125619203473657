import create from 'utils/createReduxPromiseActionType'

export const ADD_LIST = create('guests/ADD_LIST')
export const UPDATE_LIST = create('guests/UPDATE_LIST')
export const REMOVE_LIST = create('guests/REMOVE_LIST')
export const ADD_GUEST = create('guests/ADD_GUEST')
export const ADD_GUEST_BULK = create('guests/ADD_GUEST_BULK')
export const EDIT_GUEST = create('guests/EDIT_GUEST')
export const REMOVE_GUEST = create('guests/REMOVE_GUEST')
export const SEND_INVITE = create('guests/SEND_INVITE')
export const ADD_USER = create('guests/ADD_USER')
export const LIST_ADMIN = create('guests/LIST_ADMIN')
export const LIST_ADMIN_SEARCH = create('guests/LIST_ADMIN_SEARCH')
export const LIST = create('guests/LIST')
