import React, { FC } from 'react'
import { parseISO, formatDistanceToNow } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

import { User } from 'store/modules/posts/reducer'
import { Box, Text, Avatar } from 'components/ui'
import { getFullName, getImage } from 'utils/guest'

const PostUser: FC<User> = ({ first_name, last_name, image, date }) => {
  const fullName = getFullName([first_name, last_name])

  return (
    <Box display="flex">
      <Avatar fontSize={2} name={fullName} image={getImage(image)} />

      <Box ml={1} display="flex" flexDirection="column">
        <Text fontSize={1} fontWeight={600}>
          {fullName}
        </Text>

        <Text color="darkGray">
          {formatDistanceToNow(
            zonedTimeToUtc(parseISO(date), 'America/Chicago')
          )}
        </Text>
      </Box>
    </Box>
  )
}

export default PostUser
