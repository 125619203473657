import { createSelector, Selector } from 'reselect'
import {
  compose,
  pathOr,
  includes,
  filter,
  propEq,
  isNil,
  both,
  sort,
  ascend,
  prop,
  propOr,
  find,
  pathEq,
  reject,
  length,
} from 'ramda'

import { getWuid as getWuidUtil } from 'utils/router'
import { getMyId } from 'store/modules/auth/selectors'
import { State, FaqItem } from './reducer'

const getState: Selector<{ faq: State }, State> = (state) => state.faq

const getWuid = compose(
  getWuidUtil,
  pathOr('', ['router', 'location', 'pathname'])
)

export const getIsLoaded: Selector<
  { faq: State },
  boolean
> = createSelector(getState, getWuid, (state, wuid) =>
  includes(wuid, pathOr([], ['loadedWuids'], state))
)

export const getFaqItems: Selector<
  { faq: State },
  FaqItem[]
> = createSelector(getState, getWuid, (state, wuid) =>
  filter(propEq('wuid', wuid), pathOr<FaqItem[]>([], ['faqItems'], state))
)

export const getMyQuestions: Selector<
  { faq: State },
  FaqItem[]
> = createSelector(
  getState,
  getWuid,
  getMyId,
  compose(
    sort(ascend(prop('ord'))),
    (state: State, wuid: string | undefined, uuid: string | undefined) =>
      filter(
        both(propEq('wuid', wuid), pathEq(['ask_user', 'uuid'], uuid)),
        pathOr([{ faq_item_id: '', wuid: '', ord: '' }], ['faqItems'], state)
      )
  )
)

export const getSubmittedQuestions: Selector<
  { faq: State },
  FaqItem[]
> = createSelector(
  getState,
  getWuid,
  getMyId,
  compose(
    sort(ascend(prop('ord'))),
    (state: State, wuid: string | undefined, uuid: string | undefined) =>
      reject(
        pathEq(['ask_user', 'uuid'], uuid),
        filter(
          propEq('wuid', wuid),
          pathOr([{ faq_item_id: '', wuid: '', ord: '' }], ['faqItems'], state)
        )
      )
  )
)

export const getSubmittedQuestionsCount: Selector<
  { faq: State },
  number
> = createSelector(
  getSubmittedQuestions,
  compose(length, (questions: FaqItem[]) =>
    filter((question: FaqItem) => isNil(propOr(null, 'ans_user')(question)))(
      questions
    )
  )
)

export const getQuestionById = (faqItemId?: string) =>
  createSelector(getFaqItems, find<FaqItem>(propEq('faq_item_id', faqItemId)))
