import React, { useContext } from 'react'
import { Box, Switch, Text, Button } from 'components/ui'
import { Context } from '../Context'

// Icons
import { ReactComponent as PlusIcon } from 'images/plus-one.svg'
import { ReactComponent as QuestionIcon } from 'images/wedding-questions.svg'
import { ReactComponent as SettingIcon } from 'images/faq-settings.svg'

const Settings = () => {
  const {
    handleAddQuestion,
    askedQuestions,
    wedding,
    handleSwitchUpVoting,
  } = useContext<any>(Context)

  return (
    <Box width='31%' marginTop='20px' maxHeight='calc(100% - 1px)' overflowY='auto'>
      <Box marginBottom='24px'>
        <Box display='flex' alignItems='center'>
          <Box marginRight='8px'>
            <SettingIcon />
          </Box>
          <Box>
            <Text
              style={{
                fontFamily: 'Source Sans Pro',
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '25px',
                color: '#353B60',
              }}
            >
              FAQ Settings
            </Text>
          </Box>
        </Box>
        <Box marginTop='20px'>
          <Box backgroundColor='#F7F7FC' borderRadius='8px' padding='16px'>
            <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='8px'>
              <Box>
                <Text
                  style={{
                    fontFamily: 'Source Sans Pro',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#353B60',
                  }}
                >
                  Guests can ask questions
                </Text>
              </Box>
              <Box>
                <Switch
                  checked={wedding?.is_faq_readonly !== '0'}
                  onChange={(e) => handleSwitchUpVoting(e, 'ask-question')}
                />
              </Box>
            </Box>
            <Box height='18px'>
              <Text
                style={{
                  fontFamily: 'Source Sans Pro',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#353B60',
                }}
              >
                Allow guests to ask questions for the organizers to reply.
              </Text>
            </Box>
          </Box>
          <Box marginTop='12px' backgroundColor='#F7F7FC' borderRadius='8px' padding='16px'>
            <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='8px'>
              <Box>
                <Text
                  style={{
                    fontFamily: 'Source Sans Pro',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#353B60',
                  }}
                >
                  Allow up-voting for questions
                </Text>
              </Box>
              <Box>
                <Switch
                  checked={wedding?.is_faq_likes !== '0'}
                  onChange={(e) => handleSwitchUpVoting(e, 'up-voting')}
                />
              </Box>
            </Box>
            <Box height='18px'>
              <Text
                style={{
                  fontFamily: 'Source Sans Pro',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#353B60',
                }}
              >
                Guest can up-vote questions to indicate their favorite.
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display='flex' alignItems='center'>
          <Box marginRight='8px'>
            <QuestionIcon />
          </Box>
          <Box>
            <Text
              style={{
                fontFamily: 'Source Sans Pro',
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '25px',
                color: '#353B60',
              }}
            >
              Wedding Questions
            </Text>
          </Box>
        </Box>
        <Box marginTop='20px'>
          {askedQuestions?.map((item: { id: string, question: string }) => {
            return (
              <Box key={item.id} marginBottom='12px' backgroundColor='#F7F7FC' borderRadius='8px' padding='14px 16px'>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Box>
                    <Text
                      style={{
                        fontFamily: 'Source Sans Pro',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#353B60',
                      }}
                    >
                      {item.question}
                    </Text>
                  </Box>
                  <Box>
                    <Button style={{ height: '24px', backgroundColor: 'unset', padding: 0 }}
                            onClick={() => handleAddQuestion({ question: item.question })}>
                      <PlusIcon />
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default Settings