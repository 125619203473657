import styled from 'styled-components'

import { Button as ButtonBase } from 'components/ui'

type Props = { isActive: boolean }

const Button = styled(ButtonBase).attrs((props: Props) => ({
  border: '1px solid',
  borderColor: 'gray',
  height: '44px',
  color: props.isActive ? 'blue' : 'darkGray',
  fontWeight: 600,
  fontFamily: 0,
  fontSize: 1,
  px: 2,
}))<Props>`
  &:nth-child(odd) {
    border-right: none;
  }

  &:first-child {
    border-radius: ${(props) =>
      `${props.theme.radii[0]}px 0 0 ${props.theme.radii[0]}px`};
  }

  &:last-child {
    border-radius: ${(props) =>
      `0 ${props.theme.radii[0]}px ${props.theme.radii[0]}px 0`};
  }
`

export default Button
