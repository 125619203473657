import React, { FC, LabelHTMLAttributes } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import styled from 'styled-components'
import { typography, TypographyProps } from 'styled-system'

import { Box, Radio, Text, Button } from '../ui'

const Label = styled(Text).attrs({
  as: 'label',
})<LabelHTMLAttributes<HTMLLabelElement> & TypographyProps>`
  margin: 0 0 0 ${props => props.theme.space[0]}px;

  ${typography}
`

type Props = {
  variant?: 'radio' | 'button'
  name: string
  value: string
  label?: string
  disabled?: boolean
}

const FormRadio: FC<Props> = ({
  variant = 'radio',
  name,
  value,
  label,
  disabled = false,
}) => {
  const renderRadio = ({ input, id }: FieldRenderProps<string>) => {
    const handleChangeButtonVariant = (e: any) =>
      input.onChange(input.checked ? undefined : e.currentTarget.value)

    return variant === 'button' ? (
      <Button
        border="2px solid"
        borderColor={input.checked ? 'primary' : 'gray'}
        borderRadius={1}
        fullWidth
        py="6px"
        bg="#f7f8fa"
        value={input.value}
        fontFamily="1"
        fontSize={1}
        fontWeight={input.checked ? 600 : 'normal'}
        color={input.checked ? 'primary' : 'placeholder'}
        disabled={disabled}
        onClick={handleChangeButtonVariant}
      >
        {label}
      </Button>
    ) : (
      <Box display="flex" alignItems="center">
        <Radio id={id} {...input} disabled={disabled} />

        {label && (
          <Label htmlFor={id} fontFamily="1" fontSize="13px">
            {label}
          </Label>
        )}
      </Box>
    )
  }

  return (
    <Field
      id={`${name}-${value}`}
      name={name}
      value={value}
      type="radio"
      render={renderRadio}
    />
  )
}

export default FormRadio
