import { createSelector } from 'reselect'

import { State } from './reducer'

const getSeatingsState = (store: { seatings: State }) => store.seatings

export const getSChart = (id?: string) =>
  createSelector(getSeatingsState, (state) =>
    id ? state.scharts.find((schart) => schart.id === id) : undefined
  )

export const getSChartsIsLoaded = (wuid: string) =>
  createSelector(getSeatingsState, (state) => state.loadedWuids.includes(wuid))

export const getSChartItemsLoaded = (id?: string) =>
  createSelector(getSeatingsState, (state) =>
    id ? state.loadedIds.includes(id) : false
  )

export const getSCharts = (wuid: string) =>
  createSelector(getSeatingsState, (state) =>
    state.scharts.filter((schart) => schart.wuid === wuid)
  )

export const selectIsExpanded = createSelector(
  getSeatingsState,
  (state) => state.isExpanded
)
