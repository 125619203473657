import { assoc } from 'ramda'

import { ADD_QUESTION } from '../actionTypes'

export const addQuestion = (data: object) => ({
  type: ADD_QUESTION.name,
  api: {
    url: 'faq/add',
    data: assoc('is_asked', 0, data),
  },
})
