import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import md5 from 'md5'
import { path } from 'ramda'

import { addPhoto } from 'store/modules/photos/actions'
import { getFileFromUrl } from './images'

const useUploader = () => {
  const dispatch = useDispatch()

  const onCreateImageRecord = useCallback(
    async (data) => dispatch(addPhoto(data)),
    [dispatch]
  )

  const onUpload = useCallback(
    async (imageSrc: string, wuid?: string) => {
      const file = await getFileFromUrl(imageSrc, 'wedding-image')

      const imageRes = await onCreateImageRecord({
        wuid,
        image_type: file.type,
        hash: md5(imageSrc),
        src_file: file.name,
        total_length: file.size,
        mod_time: new Date().getTime(),
      })

      const photoUid = path<string>(
        ['value', 'payload', 'photo', 'photo_uid'],
        imageRes
      )
      const photoId = path<string>(
        ['value', 'payload', 'photo', 'photo_id'],
        imageRes
      )
      const tusId = path<string>(
        ['value', 'payload', 'photo', 'tus_id'],
        imageRes
      )

      if (tusId) {
        const { headers } = await fetch(tusId, {
          method: 'HEAD',
          headers: {
            'Tus-Resumable': '1.0.0',
          },
        })

        if (parseInt(headers.get('Upload-Offset') || '0', 10) < file.size) {
          await fetch(tusId, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/offset+octet-stream',
              'Upload-Offset': '0',
              'Tus-Resumable': '1.0.0',
            },
            body: file,
          })
        }

        return { photoUid, photoId }
      }

      return undefined
    },
    [onCreateImageRecord]
  )

  return { onUpload }
}

export default useUploader
