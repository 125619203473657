import React, { FC } from 'react'
import { Switch, Route } from 'react-router'

import WeddingWizard from 'routes/WeddingWizard'
import JoinToWeddingGuest from 'routes/JoinToWeddingGuest'
import VerifyConfirmation from 'routes/Verify/Confirmation'
import {
  WEDDING_CREATE,
  JOIN_TO_WEDDING_GUEST,
  VERIFY_CONFIRM,
} from 'constants/paths'
import Routes from './Routes'

const Base: FC = () => (
  <Switch>
    <Route path={WEDDING_CREATE} component={WeddingWizard} />
    <Route path={JOIN_TO_WEDDING_GUEST} component={JoinToWeddingGuest} />
    <Route path={VERIFY_CONFIRM} component={VerifyConfirmation} />

    <Routes />
  </Switch>
)

export default Base
