import styled from 'styled-components'
import { path } from 'ramda'

import Box from '../Box'

export default styled(Box).attrs({
  type: 'date',
  position: 'absolute',
  left: '-16px',
  top: '0px',
  width: '640px',
  height: '100%',
  opacity: 0,
  zIndex: 99,
})`
  font-size: ${path(['theme', 'fontSizes', 1])}px;

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    opacity: 0;
    bottom: 0;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`
