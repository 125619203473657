import { ADD_MENU_GROUP } from '../../actionTypes'
import { AddMenuGroupNeedData } from '../../../../../routes/FoodMenu/tipes_interfaces'

export const add = (data: AddMenuGroupNeedData) => {
  console.log('action loadFoodMenu working')
  return {
    type: ADD_MENU_GROUP.name,
    api: {
      url: 'menu/add_group',
      data,
    },
  }
}