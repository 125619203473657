import { Reducer } from 'redux'
import {
  assoc,
  append,
  prepend,
  path,
  pathOr,
  concat,
  findIndex,
  propEq,
  adjust,
  reject,
  update,
} from 'ramda'

import { ADD as ADD_COMMENT } from 'store/modules/comments/actionTypes'
import { ADD, LOAD, LIKE, REMOVE, PIN } from './actionTypes'

export const initialState = { posts: [], loadedWuids: {} }

export type RecentMessage = {
  comment_id: string
  uuid: string
  first_name: string
  last_name: string
  comment: string
}
export type RecentLike = {
  like_id: string
  uuid: string
  first_name: string
  last_name: string
}
export type Photo = {
  id: string
  photo_id: string
  image_small: string
}
export type User = {
  first_name: string
  last_name: string
  image?: { slice: string; link: string }[]
  date: string
}

export type Post = {
  wuid: string
  wedding_wuid: string
  post_id: string
  comments: {
    comment_count: number
    recent: RecentMessage[]
  }
  likes: {
    like_count: number
    recent: RecentLike[]
  }
  pinned_date: string
  insert_date: string
  media: Photo[]
  owner: User
  caption: string
}

export type State = {
  posts: Post[]
  loadedWuids: {
    [wuid: string]: {
      totalItems: number
      pageNum: number
      perPage: number
    }
  }
}

const postsReducer: Reducer = (
  state: State = initialState,
  { type, payload }
) => {
  switch (type) {
    case ADD.FULFILLED:
      return assoc(
        'posts',
        prepend(path(['payload', 'post'], payload), state.posts),
        state
      )
    case LOAD.FULFILLED:
      return {
        ...state,
        posts: concat(state.posts, pathOr([], ['payload', 'posts'], payload)),
        loadedWuids: assoc(
          pathOr('', ['data', 'wuid'], payload),
          {
            totalItems: path(['payload', 'total_items'], payload),
            pageNum: path(['data', 'page_num'], payload),
            perPage: 10,
          },
          state.loadedWuids
        ),
      }
    case ADD_COMMENT.FULFILLED: {
      const postIndex = findIndex(
        propEq('post_id', path(['data', 'post_id'], payload)),
        state.posts
      )
      const comment = path(['payload', 'comment'], payload)

      return assoc(
        'posts',
        adjust(
          postIndex,
          (post) => ({
            ...post,
            comments: {
              comment_count: post.comments.comment_count + 1,
              recent: prepend(
                {
                  comment_id: pathOr('', ['comment_id'], comment),
                  uuid: pathOr('', ['owner', 'uuid'], comment),
                  first_name: pathOr('', ['owner', 'first_name'], comment),
                  last_name: pathOr('', ['owner', 'last_name'], comment),
                  comment: pathOr('', ['caption'], comment),
                },
                post.comments.recent
              ),
            },
          }),
          state.posts
        ),
        state
      )
    }
    case LIKE.FULFILLED: {
      const postIndex = findIndex(
        propEq('post_id', path(['data', 'post_id'], payload)),
        state.posts
      )
      const isLike = path(['data', 'score'], payload) === 1

      return assoc(
        'posts',
        adjust(
          postIndex,
          (post) => ({
            ...post,
            likes: {
              like_count: post.likes.like_count + (isLike ? 1 : -1),
              recent: isLike
                ? append(
                    pathOr(
                      { like_id: '', uuid: '', first_name: '', last_name: '' },
                      ['payload', 'like'],
                      payload
                    ),
                    post.likes.recent
                  )
                : reject(
                    propEq(
                      'like_id',
                      pathOr('', ['payload', 'like', 'like_id'], payload)
                    ),
                    post.likes.recent
                  ),
            },
          }),
          state.posts
        ),
        state
      )
    }
    case REMOVE.FULFILLED:
      return assoc(
        'posts',
        reject(
          propEq('post_id', path(['data', 'post_id'], payload)),
          state.posts
        ),
        state
      )
    case PIN.FULFILLED:
      return assoc(
        'posts',
        update(
          findIndex(
            propEq('post_id', path(['data', 'post_id'], payload)),
            state.posts
          ),
          path(['payload', 'post'], payload),
          state.posts
        ),
        state
      )

    default:
      return state
  }
}

export default postsReducer
