import React, { FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { join } from 'ramda'

import { Box, FormLabel, TimePickerInput, Text } from '../ui'

type Props = {
  name: string
  label?: string
  value?: string
  isShowMenu?: boolean
  placeholder?: string
  minutePlaceholder?: string | undefined
  hourPlaceholder?: number
}

const FormTimePickerInput: FC<Props> = ({
                                          label,
                                          isShowMenu = false,
                                          name,
                                          placeholder,
                                          minutePlaceholder,
                                          hourPlaceholder,
                                          ...rest
                                        }) => {
  const renderTimePickerInput = ({ input, meta }: FieldRenderProps<string>) => (
    <Box position='relative'>
      {label && <FormLabel>{label}</FormLabel>}

      <TimePickerInput
        {...input}
        {...rest}
        isShowMenu={isShowMenu}
        hourPlaceholder={hourPlaceholder}
        minutePlaceholder={minutePlaceholder}
      />

      {meta.submitFailed && meta.invalid && (
        <Box position='absolute' bottom='-18px'>
          <Text color='red' fontFamily='1' fontSize='12px' isTruncate>
            {join('. ', meta.error)}
          </Text>
        </Box>
      )}
    </Box>
  )

  return <Field name={name} placeholder={placeholder} render={renderTimePickerInput} />
}

export default FormTimePickerInput
