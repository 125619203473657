import React, { FC, useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Form } from 'react-final-form'
import validate from 'validate.js'
import { toast } from 'react-toastify'

import { listAdmin } from 'store/modules/guests/actions'
import {
  getGuestById,
  getAllLists,
  getListsIsLoaded,
} from 'store/modules/guests/selectors'
import { addGuest, editGuest } from 'store/modules/guests/actions'
import { Box, Text, Button, Loading } from 'components/ui'
import { ReactComponent as BackIcon } from 'images/chevron-left.svg'
import {
  presenceFieldConstraint,
  emailConstraint,
  phoneNumberConstraint,
  zipConstraint,
} from 'constants/constraints'
import { GUESTS } from 'constants/paths'
import { transformToRequest, transformToForm } from 'utils/guest'
import NewGuestForm from './Form'

const GuestDetails: FC = () => {
  const dispatch = useDispatch()
  const isLoaded = useSelector(getListsIsLoaded)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const { wuid, guestId } = useParams<{ wuid: string; guestId?: string }>()

  const onAddGuest = useCallback(async (data) => dispatch(addGuest(data)), [
    dispatch,
  ])
  const onEditGuest = useCallback(async (data) => dispatch(editGuest(data)), [
    dispatch,
  ])
  const guest = useSelector(getGuestById(guestId))
  const lists = useSelector(getAllLists)

  const handleValidate = (values: any) =>
    validate(values, {
      ...presenceFieldConstraint('first_name'),
      ...presenceFieldConstraint('last_name'),
      ...emailConstraint('email', false),
      ...presenceFieldConstraint('list_id'),
      ...phoneNumberConstraint('phone', false),
      ...zipConstraint('zip', false),
    })
  const handleClickBack = () => history.push(GUESTS(wuid))

  const handleSubmit = async (values: object) => {
    try {
      if (guest) {
        await onEditGuest(transformToRequest(values))
      } else {
        await onAddGuest(transformToRequest(values))
      }

      handleClickBack()
    } catch (error) {
      toast(error.message)
    }
  }

  const onLoad = useCallback(async () => dispatch(listAdmin()), [dispatch])
  const handleLoad = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoad()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onLoad])

  useEffect(() => {
    if (!isLoaded) {
      handleLoad()
    }
  }, [isLoaded, handleLoad])

  if (isLoading) return <Loading />

  return (
    <Box ml="56px" mb={1} mt={0} maxWidth="692px">
      <Button height="44px" ml="-36px" border="none" onClick={handleClickBack}>
        <BackIcon fill="#5458f7" width={30} height={30} />

        <Text fontFamily="1" color="blue" lineHeight="1.29">
          Back
        </Text>
      </Button>

      <Box mb={3}>
        <Text fontFamily="1" fontSize={4} fontWeight={600}>
          {guest ? 'Edit Guest' : 'Add Guest'}
        </Text>
      </Box>

      <Form
        render={(props) => {
          return (
            <NewGuestForm
              {...props}
              isEdit={!!guest}
              onCancel={handleClickBack}
            />
          )
        }}
        validate={handleValidate}
        initialValues={{
          wuid,
          ...transformToForm(guest, lists),
          is_spouse: 0,
        }}
        onSubmit={handleSubmit}
      />
    </Box>
  )
}

export default GuestDetails
