import styled from 'styled-components'

import Box from '../Box'

const ContainerWrapper = styled(Box).attrs({
  border: '1px solid',
  borderColor: 'gray',
  borderRadius: 0,
  boxShadow: 0,
  mt: 0,
  maxHeight: '256px',
  overflowY: 'auto',
  position: 'absolute',
  width: '100%',
  bg: 'white',
  zIndex: 2,
})``

export default ContainerWrapper
