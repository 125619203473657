import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import validate from 'validate.js'
import { path } from 'ramda'
import { presenceFieldConstraint } from 'constants/constraints'
import CreateAlbumForm from './Form'

// Components
import { Modal, Box, Text } from 'components/ui'

// Utils
import { transformToRequest } from 'utils/albums'

// Store tools
import { addAlbum, loadAlbum } from 'store/modules/albums/actions'

type Props = {
  isOpen: boolean
  onClose: () => void
  isAlbumAdded: boolean
  setIsAlbumAdded: Function
}

const CreateModal: FC<Props> = ({ isOpen, onClose, setIsAlbumAdded, isAlbumAdded }) => {
  const dispatch = useDispatch()

  const onAddAlbum = useCallback(async (data) => dispatch(addAlbum(data)), [
    dispatch,
  ])

  const onLoadAlbum = useCallback(
    async (albumId) => dispatch(loadAlbum(albumId)),
    [dispatch],
  )

  const handleClose = () => onClose()

  const handleSubmit = async (values: object) => {
    const album = await onAddAlbum(transformToRequest(values))

    await onLoadAlbum(path(['value', 'payload', 'album_id'], album))

    setIsAlbumAdded(!isAlbumAdded)

    return handleClose()
  }

  const handleValidate = (values: any) =>
    validate(values, presenceFieldConstraint('name'))

  const modalStyle = { content: { maxWidth: 422, top: '40vh' } }

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={handleClose}>
      <Box py={3} px={4} borderRadius={0} bg='white'>
        <Box mb={1}>
          <Text fontFamily='1' fontSize={3} fontWeight={600}>
            Create Album
          </Text>
        </Box>

        <Form
          render={(props) => (
            <CreateAlbumForm {...props} onClose={handleClose} />
          )}
          validate={handleValidate}
          onSubmit={handleSubmit}
        />
      </Box>
    </Modal>
  )
}

export default CreateModal
