import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Route } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'styled-components'

import store, { history, persistor } from 'store'
import { Loading } from 'components/ui'
import Base from 'routes/Base'
import theme, { GlobalStyle } from './theme'

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <GlobalStyle />

          <Route path="/" component={Base} />
        </ConnectedRouter>
      </PersistGate>
    </ThemeProvider>
  </Provider>
)

export default App
