import React, { FC, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Field, FieldRenderProps } from 'react-final-form'
import { head } from 'ramda'

import { Box, Button, Text } from 'components/ui'
import { getImage } from 'utils/wedding'
import { readImage } from 'utils/images'

type Image = {
  slice: string
  link: string
}

const ImageUploaderComponent: FC<FieldRenderProps<string | Image[]>> = ({
  input,
  meta,
}) => {
  const { onChange } = input
  const { pristine } = meta

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = head(acceptedFiles)

      if (file) {
        onChange(await readImage(file))
      }
    },
    [onChange]
  )

  const { getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noDrag: true,
    accept: 'image/*',
    multiple: false,
  })

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="160px"
      borderRadius={0}
      backgroundImage={`url("${
        pristine && typeof input.value !== 'string'
          ? getImage(input.value)
          : input.value
      }")`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <input {...getInputProps()} />

      <Button
        borderRadius={0}
        border="none"
        px={1}
        py="10px"
        bg="darkened"
        onClick={open}
      >
        <Text fontFamily="1" fontWeight={600} color="white">
          Change Image
        </Text>
      </Button>
    </Box>
  )
}

const ImageUploader = () => (
  <Field name="wedding_image" component={ImageUploaderComponent} />
)

export default ImageUploader
