import React, { FC, KeyboardEvent, InputHTMLAttributes } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { join } from 'ramda'

import { Box, FormLabel, Input, Text } from '../ui'

type Props = {
  name: string;
  label?: string;
  isRequired?: boolean;
  text?: string;
  mask?: any;
  isTextarea?: boolean;
  border?: any;
  borderRadius?: string | number;
  px?: any;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  hideErrorMessages?: boolean;
  hideErrorText?: boolean;
  isAlwaysBaseVariant?: boolean;
  maxLength?: number
  inputMaxLength?: number
  showMaxLength?: boolean
  borderColor?: string
  emailNotFound?: boolean | any
}

const FormInput: FC<InputHTMLAttributes<HTMLInputElement> & Props> = ({
                                                                        name,
                                                                        label,
                                                                        isRequired = false,
                                                                        placeholder,
                                                                        text,
                                                                        mask,
                                                                        hideErrorMessages = false,
                                                                        hideErrorText = false,
                                                                        isAlwaysBaseVariant,
                                                                        maxLength = 0,
                                                                        isTextarea,
                                                                        inputMaxLength,
                                                                        showMaxLength,
                                                                        borderColor,
                                                                        emailNotFound,
                                                                        value,
                                                                        onChange,
                                                                        ...rest
                                                                      }) => {
  const renderInput = ({ input, meta }: FieldRenderProps<HTMLInputElement>) => {
    const isError = meta.submitFailed && meta.invalid

    return (
      <Box position='relative'>
        {label && (
          <FormLabel
            isRequired={isRequired}
            isActive={meta.active}
            isError={isError && !hideErrorMessages}
          >
            {label}
          </FormLabel>
        )}
        <Input
          {...input}
          placeholder={placeholder}
          mask={mask}
          borderColor={isError || emailNotFound ? '#F44343 !important' : '#E8E8ED !important'}
          borderRadius={8}
          maxLength={inputMaxLength}
          variant={
            !hideErrorMessages && isError && !isAlwaysBaseVariant
              ? 'error'
              : undefined
          }
          {...rest}
        />

        {!hideErrorMessages && !hideErrorText && isError && (
          <Box position='absolute' bottom='-18px'>
            <Text color='red' fontFamily='1' fontSize='12px' isTruncate>
              {join('. ', meta.error)}
            </Text>
          </Box>
        )}

        {text && (
          <Box mt='6px'>
            <Text fontFamily='1' color='darkGray' fontSize='13px'>
              {text}
            </Text>
          </Box>
        )}
        {showMaxLength && maxLength >= 0 ? (
          <Box
            position='absolute'
            right='12px'
            top={isTextarea ? 'unset' : '31px'}
            bottom={isTextarea ? '12px' : 'unset'}
          >
            <Text fontFamily='Source Sans Pro' color={maxLength !== 0 ? '#CBCBD3' : '#F44343'} fontSize='16px'
                  fontWeight='400'
                  lineHeight='18px'>
              {maxLength}
            </Text>
          </Box>
        ) : null}
      </Box>
    )
  }

  return <Field name={name} render={renderInput} />
}

export default FormInput
