import React, { FC } from 'react'
import { OptionTypeBase } from 'react-select'
import { pathOr, path } from 'ramda'

import { Box, Avatar, Text } from 'components/ui'
import { getFullName } from 'utils/guest'
import { getImage } from 'utils/wedding'

const Option: FC<OptionTypeBase> = ({ innerProps, data }) => {
  const fullName = path(['user'], data)
    ? getFullName([
        path(['user', 'first_name'], data),
        path(['user', 'last_name'], data),
      ])
    : 'X'

  return (
    <Box
      {...innerProps}
      py={0}
      px={1}
      display="flex"
      alignItems="center"
      cursor="pointer"
    >
      <Box mr="12px">
        <Avatar
          image={getImage(pathOr([], ['user', 'image'], data), 'icon')}
          name={fullName}
          fontSize={3}
        />
      </Box>

      <Box display="flex" flexDirection="column">
        <Text fontWeight={600}>{fullName}</Text>

        <Text color="darkGray">
          {pathOr('', ['user', 'contact_email'], data)}
        </Text>
      </Box>
    </Box>
  )
}

export default Option
