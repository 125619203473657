import { SChart, Floorplan } from './reducer'

const ALPHANUMERIC = /[^a-z0-9]/gi

const parseSeats = (value: string) => {
  const seats = JSON.parse(value)

  if (!Array.isArray(seats)) return [[]]

  return seats.map((value) => (!Array.isArray(value) ? [] : value))
}

const parseRotation = (value: string) => {
  let rotation = parseInt(value)

  return rotation < -180 || rotation > 180 ? 0 : rotation
}

export const itemsPayloadToStore = (payload: SChart): SChart => ({
  ...payload,
  schart_guests: payload.schart_guests.map((guest) => ({
    ...guest,
    first_name: (guest.first_name || guest.user?.first_name || '').replace(
      ALPHANUMERIC,
      ''
    ),
    last_name: (guest.last_name || guest.user?.last_name || '').replace(
      ALPHANUMERIC,
      ''
    ),
  })),
  schart_items: payload.schart_items.map((item) => ({
    ...item,
    pos_x: parseInt((item.pos_x as unknown) as string),
    pos_y: parseInt((item.pos_y as unknown) as string),
    width: parseInt((item.width as unknown) as string),
    height: parseInt((item.height as unknown) as string),
    seats: parseSeats((item.seats as unknown) as string),
    rotation: parseRotation((item.rotation as unknown) as string),
    isCaptionVisible: true,
    isChairsVisible: true,
  })),
})

const parseFloorplan = (floorplan?: {
  [key: string]: string
}): Floorplan | undefined =>
  floorplan
    ? {
        id: floorplan.id,
        url: floorplan.url,
        ext: floorplan.ext,
        width: parseInt(floorplan.width),
        height: parseInt(floorplan.height),
        scale: parseInt(floorplan.scale) / 100,
      }
    : undefined

export const chartPayloadToStore = (payload: SChart): SChart => ({
  ...payload,
  height: parseInt((payload.height as unknown) as string),
  width: parseInt((payload.width as unknown) as string),
  x: parseInt((payload.x as unknown) as string),
  y: parseInt((payload.y as unknown) as string),
  zoom: parseFloat((payload.zoom as unknown) as string),
  floorplan: parseFloorplan(payload.floorplan as {}),
})
