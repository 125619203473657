import React, { MouseEvent } from 'react'
import styled, { css } from 'styled-components'

import { Box, Button } from 'components/ui'
import { TabName } from './Panel'

type TabProps = {
  isActive?: boolean
}

const Tab = styled(Button).attrs<TabProps>((props) => ({
  border: props.isActive ? '2px solid #5458F7' : '1px solid #CBCBD3',
  bg: props.isActive ? '#DDDEFD' : 'transparent',
  color: props.isActive ? '#5458F7' : '#CBCBD3',
}))<TabProps>`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Source Sans Pro;

  ${(props) =>
    props.isActive
      ? css`
          font-weight: 600;
        `
      : css`
          :first-child {
            border-right: none;
          }

          :last-child {
            border-left: none;
          }
        `}

  :first-child {
    border-radius: 10px 0px 0px 10px;
  }

  :last-child {
    border-radius: 0px 10px 10px 0px;
  }
`

type Props = {
  activeTab: TabName
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const Tabs = ({ activeTab, onClick }: Props) => (
  <Box width="100%" height="40px" display="flex">
    {Object.values(TabName).map((tabName) => (
      <Tab
        key={tabName}
        as="button"
        value={tabName}
        isActive={tabName === activeTab}
        onClick={onClick}
      >
        {tabName}
      </Tab>
    ))}
  </Box>
)

export default Tabs
