import React from 'react'
import { Box } from 'components/ui'
import frame from './frame.svg'

export const MealChoices = () => (
  <Box
    width="620px"
    height="365px"
    display="flex"
    justifyContent="center"
    position="relative"
    backgroundImage={`url(${frame})`}
    backgroundRepeat="no-repeat"
    backgroundPosition="0 -20px"
  ></Box>
)

export default MealChoices
