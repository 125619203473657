import React, { FC } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { equals, map } from 'ramda'

import { Box, Text } from 'components/ui'
import { getMyId } from 'store/modules/auth/selectors'
import AddUser from './AddUser'
import Item from './Item'

type Props = {
  socket: WebSocket
  users?: {
    uid: string
    first_name: string
    last_name: string
    status: number
    image?: string
  }[]
  roomId?: string
}

const Users: FC<Props> = ({ socket, users = [], roomId }) => {
  const { wuid } = useParams<{ wuid: string }>()
  const myUid = useSelector(getMyId)

  const handleAddUser = (uid: string) =>
    socket.send(
      JSON.stringify({
        c: 'j',
        uid,
        wuid,
        room_id: roomId,
      })
    )
  const handleRemoveUser = (uid: string) => () =>
    socket.send(
      JSON.stringify({
        c: 'l',
        uid,
        wuid,
        room_id: roomId,
      })
    )

  return (
    <Box py={1} borderBottom="1px solid" borderColor="gray">
      <Box ml={0}>
        <Text fontSize={1} fontWeight={600}>
          {users.length} member{!equals(users.length, 1) && 's'}
        </Text>
      </Box>

      <Box mt="10px">
        <AddUser onAddUser={handleAddUser} />

        {map(
          (user) => (
            <Item
              key={user.uid}
              {...user}
              hideRemoveButton={equals(myUid, user.uid)}
              onClickRemove={handleRemoveUser(user.uid)}
            />
          ),
          users
        )}
      </Box>
    </Box>
  )
}

export default Users
