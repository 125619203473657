import React, { useMemo, useCallback, MouseEvent } from 'react'

import { Box } from 'components/ui'
import { Types } from 'routes/Seating'
import ChairsField from './ChairsField'

type Props = {
  type: Types
  top: number
  bottom: number
  left: number
  right: number
  around: number
  arcSide: number
  flatSide: number
  onSetValue: (field: string, value: any) => void
}

const Chairs = ({ type, onSetValue, ...values }: Props) => {
  const fieldNames = useMemo(() => {
    switch (type) {
      case Types.RECTANGLE:
      case Types.SQUARE:
        return ['top', 'bottom', 'left', 'right']
      case Types.CIRCLE:
        return ['around']
      case Types.HALF_CIRCLE:
        return ['arcSide', 'flatSide']
      default:
        return []
    }
  }, [type])

  const onClickButton = useCallback(
    (field: string) => (e: MouseEvent<HTMLButtonElement>) => {
      const { name, value } = e.currentTarget

      const nextValue = parseInt(value) + 1 * (name === 'inc' ? 1 : -1)

      onSetValue(field, nextValue < 1 ? 0 : nextValue)
    },
    [onSetValue]
  )

  return (
    <Box
      width="100%"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      {fieldNames.map((fieldName) => (
        <ChairsField
          key={fieldName}
          label={fieldName}
          value={values[fieldName as keyof typeof values]}
          onClickButton={onClickButton(fieldName)}
        />
      ))}
    </Box>
  )
}

export default Chairs
