import React, { FC, useCallback, memo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ReactComponent as SortIcon } from 'images/chevron-up.svg'
import DataTableRowComponent from './DataTableRowComponent'
import { Box } from 'components/ui'
import { equals } from 'ramda'
import {
  BootyCheckbox,
  Props,
  ExpProps,
  getCustomStyles,
  isExpanded,
} from './utils/tableComponent'
import { SortOrder, TableColumn } from 'react-data-table-component/dist/src/DataTable/types'

const initialStyles = getCustomStyles()

const DataTableComponent: FC<Props> = ({ tableProps }) => {
  const {
    data: tableData,
    columnsWithFunctions,
    expandableColumnsWithFunction,
    noHeader = false,
    isLoading,
    toggleClearRows = false,
    isTableExpendable = false,
    selectedRows,
    setSelectedRows,
    selectedExpandableRows,
    setSelectedExpandableRows,
    onRowClicked,
    style,
  } = tableProps

  const [customStyles, setCustomStyles] = useState(initialStyles)

  const setExpandable = useCallback(({ selectedRows, guestId, guest }: any) => {
    const allSelected = selectedRows.length === guest.sub_guest_list.length

    setSelectedExpandableRows({ selectedRows, guestId, guest, allSelected })
  }, [setSelectedExpandableRows])

  const handleSort = (selectedColumn: TableColumn<any>, sortDirection: SortOrder): void => {
    setCustomStyles(getCustomStyles(selectedColumn.id, sortDirection === 'desc'))
  }

  const handleRowSelect = ({ selectedRows: newSelectedRows, allSelected }: any) => {

    if (!equals(selectedRows, newSelectedRows)) {
      setSelectedRows(newSelectedRows, allSelected)
    }
  }

  const rowSelectCriteria = useCallback((row: any) => {
    return !!selectedRows.find((selectedRow: any) => selectedRow.guest_id === row.guest_id)
  }, [selectedRows])

  const conditionalRowStyles: any = [
    {
      when: (row: any) => row.isExpanded,
      style: {
        borderBottomWidth: '0',
      },
    },
    {
      when: (row: any) => !row.isExpanded,
      style: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#E8E8ED',
      },
    },
    {
      when: (row: any) => rowSelectCriteria(row),
      style: {
        backgroundColor: '#F7F7FC',
      },
    },
  ]

  const ExpandableRowComponent: FC<ExpProps> = useCallback((props) => {
    const expandTableProps = {
      noTableHead: true,
      isLoading,
      columnsWithFunctions: expandableColumnsWithFunction,
      pagination: false,
      header: true,
      isTableExpendable: false,
      guestId: props.data.guest_id,
      data: props.data.sub_guest_list,
      guest: props.data,
      selectedExpandableTableData: selectedExpandableRows[props.data.guest_id] || [],
      setSelectedExpandableTableData: setExpandable,
      onRowClicked,
    }

    return (
      <Box>
        <DataTableRowComponent tableProps={expandTableProps} />
      </Box>
    )
  }, [isLoading, expandableColumnsWithFunction, selectedExpandableRows, setExpandable, onRowClicked])

  return (
    <DataTable
      style={style}
      expandableRowsHideExpander
      noHeader={noHeader}
      selectableRows
      onSelectedRowsChange={handleRowSelect}
      clearSelectedRows={toggleClearRows}
      responsive
      onSort={handleSort}
      columns={columnsWithFunctions()}
      data-tag='allowRowEvents'
      sortIcon={<SortIcon />}
      pointerOnHover
      data={tableData}
      expandableRowExpanded={isExpanded}
      expandableRows={isTableExpendable}
      expandableRowsComponent={ExpandableRowComponent}
      progressPending={isLoading}
      progressComponent={
        <div className='d-flex justify-content-center my-1'>Loading...</div>
      }
      onRowClicked={onRowClicked}
      customStyles={customStyles}
      conditionalRowStyles={conditionalRowStyles}
      selectableRowsComponent={BootyCheckbox}
      fixedHeader
      fixedHeaderScrollHeight={'calc(100% - 136px); height: calc(100% - 136px); border-radius: 0; ' +
      '::-webkit-scrollbar {height: 5px; width: 5px !important}; ::-webkit-scrollbar-track {\n' +
      '  background: #f1f1f1; \n' +
      '}  }; ::-webkit-scrollbar-thumb {\n' +
      '  background: #CBCBD3; \n' +
      '}'}
      selectableRowSelected={rowSelectCriteria}
      highlightOnHover={true}
    />
  )
}

export default memo(DataTableComponent)
