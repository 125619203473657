import { DELETE_MENU_GROUP, GROUP_ITEMS_ADD } from '../../actionTypes'
import { AddGroupItems, DeleteMenuGroupNeedData } from '../../../../../routes/FoodMenu/tipes_interfaces'

export const remove = (data: any) => {
  console.log('action loadFoodMenu working')
  return {
    type: DELETE_MENU_GROUP.name,
    api: {
      url: 'menu/remove_group',
      data,
    },
  }
}