import { createSelector } from 'reselect'

import { State } from './reducer'

const getNavState = (store: { nav: State }) => store.nav

export const getNavIsLoaded = (wuid: string) =>
  createSelector(
    getNavState,
    (state) => state.navs.findIndex((v) => v.wuid === wuid) !== -1
  )

export const getNavItems = (wuid: string) =>
  createSelector(getNavState, (state) => {
    const nav = state.navs.find((v) => v.wuid === wuid)

    if (!nav) {
      return []
    }

    return state.items.filter(({ nav_id }) => nav_id === nav.id)
  })

export const appSectionsExpanded = createSelector(
  getNavState,
  (state) => state.appSectionsExpanded
)

export const getNavItem = (navItemId?: string) =>
  createSelector(getNavState, (state) =>
    navItemId
      ? state.items.find(({ nav_item_id }) => nav_item_id === navItemId)
      : null
  )
