import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { findIndex, propEq } from 'ramda'
import { isValid, parseISO } from 'date-fns'

import { Post as Props } from 'store/modules/posts/reducer'
import { Box } from 'components/ui'
import { getMyId } from 'store/modules/auth/selectors'
import User from './User'
import MenuButton from './MenuButton'
import Content from './Content'
import Likes from './Likes'
import Comments from './Comments'
import CommentForm from './CommentForm'
import LikeButton from './LikeButton'

const Post: FC<Props> = ({
  post_id,
  owner,
  insert_date,
  pinned_date,
  caption,
  media,
  comments,
  likes,
}) => {
  const uuid = useSelector(getMyId)
  const withLikes = likes.like_count > 0
  const withComments = comments.comment_count > 0
  const isPinned = isValid(parseISO(pinned_date))

  return (
    <Box
      pt="18px"
      border="1px solid"
      borderColor="gray"
      borderRadius={0}
      mb={1}
    >
      <Box display="flex" justifyContent="space-between" px={2} mb={1}>
        <User {...owner} date={insert_date} />

        <MenuButton postId={post_id} isPinned={isPinned} />
      </Box>

      <Content caption={caption} media={media} />

      {(withLikes || withComments) && (
        <Box px={2}>
          <Box
            borderBottom="1px solid"
            borderColor="gray"
            py={1}
            display="flex"
            flexDirection="column"
          >
            {withLikes && (
              <Box mb={withComments ? 0 : '0px'}>
                <Likes {...likes} />
              </Box>
            )}
            {withComments && <Comments postId={post_id} {...comments} />}
          </Box>
        </Box>
      )}

      <Box
        px={2}
        py={0}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="100%">
          <CommentForm postId={post_id} />
        </Box>

        <LikeButton
          postId={post_id}
          isLiked={findIndex(propEq('uuid', uuid), likes.recent) !== -1}
        />
      </Box>
    </Box>
  )
}

export default Post
