import React, { FC, useState, useCallback, useEffect, useMemo } from 'react'
import { useParams, useHistory, useLocation, matchPath } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { head } from 'ramda'
import { toast } from 'react-toastify'

import { getIsLoaded, getWedding } from 'store/modules/wedding/selectors'
import {
  loadWedding,
  update as updateWedding,
} from 'store/modules/wedding/actions'
import {
  getIsLoaded as getFontsIsLoaded,
  getFont,
  getFonts,
} from 'store/modules/fonts/selectors'
import { loadFonts, setFontIsLoaded } from 'store/modules/fonts/actions'
import { appSectionsExpanded } from 'store/modules/nav/selectors'
import { selectIsExpanded } from 'store/modules/seatings/selectors'
import { injectFont } from 'utils/fonts'
import { Box, Loading } from 'components/ui'
import { WEDDING_LIST, WEDDING_LAYOUT, MESSAGES } from 'constants/paths'
import { PAGE_TYPES } from 'constants/wedding'
import bgSrc from './background.svg'
import Header from '../Header'
import Sidebar from '../Sidebar'
import AppSections from '../AppSections'

const Wrapper: FC = ({ children }) => {
  const { pathname } = useLocation()
  const type = useMemo(
    () =>
      matchPath(pathname, { path: WEDDING_LAYOUT() })
        ? PAGE_TYPES[0]
        : matchPath(pathname, { path: MESSAGES() })
        ? PAGE_TYPES[2]
        : PAGE_TYPES[1],
    [pathname]
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const { wuid } = useParams<{ wuid: string }>()
  const [isLoading, setIsLoading] = useState(false)
  const wedding = useSelector(getWedding(wuid))
  const isLoaded = useSelector(getIsLoaded)
  const fontsIsLoaded = useSelector(getFontsIsLoaded)
  const [fontsIsLoading, setFontsIsLoading] = useState(false)
  const font = useSelector(getFont(wedding?.theme_font))
  const defaultFont = head(useSelector(getFonts(1)))
  const isAppSectionsExpanded = useSelector(appSectionsExpanded)
  const isSeatingChartExpanded = useSelector(selectIsExpanded)
  const navItemId = useMemo(
    () =>
      typeof isAppSectionsExpanded === 'string'
        ? isAppSectionsExpanded
        : undefined,
    [isAppSectionsExpanded]
  )

  const onLoadWedding = useCallback(async () => dispatch(loadWedding(wuid)), [
    wuid,
    dispatch,
  ])
  const handleLoadWedding = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoadWedding()
    } catch (error) {
      history.push(WEDDING_LIST)
    } finally {
      setIsLoading(false)
    }
  }, [history, onLoadWedding])

  const onLoadFonts = useCallback(async () => dispatch(loadFonts()), [dispatch])
  const handleLoadFonts = useCallback(async () => {
    setFontsIsLoading(true)

    try {
      await onLoadFonts()
    } catch (error) {
      toast(error.message)
    } finally {
      setFontsIsLoading(false)
    }
  }, [onLoadFonts])
  const onSetFontIsLoaded = useCallback(
    (fontFamily: string) => dispatch(setFontIsLoaded(fontFamily)),
    [dispatch]
  )

  const onUpdateWedding = useCallback(
    async (data) => dispatch(updateWedding(data)),
    [dispatch]
  )
  const handleSetDefaultWeddingFont = useCallback(async () => {
    try {
      await onUpdateWedding({
        theme_font: defaultFont?.family,
        theme_font_url: defaultFont?.files?.regular,
      })

      toast(`Wedding theme font has been changed to "${defaultFont?.family}"`)
    } catch (error) {
      toast(error.message)
    }
  }, [onUpdateWedding, defaultFont])

  const renderSlideBar = useCallback(() => {
    if (type !== PAGE_TYPES[3]) {
      return (
        <Box width="270px" height="100%" position="relative">
          <Sidebar type={type} wuid={wuid} />
        </Box>
      )
    }
  }, [type, wuid])

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <Loading />
    }

    if (type === PAGE_TYPES[1] || type === PAGE_TYPES[2]) {
      return children
    }

    return (
      <>
        {renderSlideBar()}
        <Box
          width={`calc(100% - ${type !== PAGE_TYPES[3] ? 250 : 0}px)`}
          height="100%"
          overflowY="auto"
          position="relative"
          bg="white"
          boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
          borderRadius="10px"
          display="flex"
        >
          {[PAGE_TYPES[0], PAGE_TYPES[1]].includes(type) &&
            isAppSectionsExpanded && <AppSections navItemId={navItemId} />}

          {children}
        </Box>
      </>
    )
  }, [
    isLoading,
    type,
    navItemId,
    children,
    isAppSectionsExpanded,
    renderSlideBar,
  ])

  useEffect(() => {
    if (wuid && !isLoaded && !wedding) {
      handleLoadWedding()
    }
  }, [wuid, isLoaded, wedding, handleLoadWedding])

  // Load fonts
  useEffect(() => {
    if (wuid && !fontsIsLoaded && !fontsIsLoading) {
      handleLoadFonts()
    }
  }, [wuid, fontsIsLoaded, fontsIsLoading, handleLoadFonts])

  // Inject or change wedding font
  useEffect(() => {
    if (font && !font.isLoaded) {
      onSetFontIsLoaded(font.family)
      injectFont(font.family, font.files.regular)
    }

    if (wedding && wedding.theme_font && fontsIsLoaded && !font) {
      handleSetDefaultWeddingFont()
    }
  }, [
    wedding,
    font,
    fontsIsLoaded,
    onSetFontIsLoaded,
    handleSetDefaultWeddingFont,
  ])

  return (
    <>
      <Header isHidden={isSeatingChartExpanded} />

      <Box
        display="flex"
        width="100%"
        height="100vh"
        px="16px"
        pb="16px"
        backgroundImage={`url(${bgSrc})`}
        backgroundRepeat="no-repeat"
        backgroundPosition="100px bottom"
        style={{
          paddingTop: isSeatingChartExpanded ? 16 : 104,
          transition: 'all 0.5s ease-out 0s',
        }}
      >
        {renderContent()}
      </Box>
    </>
  )
}

export default Wrapper
