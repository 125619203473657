import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
  CSSProperties,
} from 'react'
import Moveable, { OnRotate, OnDrag, OnResize } from 'react-moveable'
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'

import { SChartItem } from 'store/modules/seatings/reducer'
import { Box } from 'components/ui'
import { getFullName, getImage } from 'utils/guest'
import Table from './Table'
import Guest from './Guest'
import EditChart from './EditChart'

const PADDING = 36

const thetaValues = {
  halfcircle: [2, 2, 0.85, 0.73, 1.095, 1.46, 1.82, 2.19, 2.55, 2.91],
  circle: [1, 1, 0.106, 0.5, 0.635, 0.8, 0.97, 1.1, 1.27, 1.44],
}

type Props = {
  selectedItemId?: SChartItem['schart_item_id']
  onChangeItem: (payload: Partial<SChartItem>) => void
  onSelectItem: (itemId?: string) => void
  onSetEditModalAsSettings: VoidFunction
  onOpenPanel: VoidFunction
  setItemIsDragging: Dispatch<SetStateAction<boolean>>
}

const ChartItem = ({
  schart_item_id,
  width,
  height,
  pos_x,
  pos_y,
  rotation,
  selectedItemId,
  seats,
  type,
  caption,
  isCaptionVisible,
  isChairsVisible,
  onChangeItem,
  onSelectItem,
  onSetEditModalAsSettings,
  onOpenPanel,
  setItemIsDragging,
}: SChartItem & Props) => {
  const chartItemRef = useRef<HTMLDivElement>(null)
  const moveableRef = useRef<Moveable>(null)

  const [item, setItem] = useState({
    width,
    height,
    pos_x,
    pos_y,
    rotation,
  })

  const isSelected = useMemo(
    () => (selectedItemId ? selectedItemId === schart_item_id : false),
    [selectedItemId, schart_item_id]
  )

  const isNoSeats = useMemo(
    () => !isChairsVisible || seats.every((side) => side.length === 0),
    [isChairsVisible, seats]
  )

  const renderSeats = useCallback(
    (style: CSSProperties) => {
      if (type === 'halfcircle') {
        const [arcSideData] = seats
        const flatSideData = [...seats[1]].reverse()

        const radius = item.width / 2 + 12

        const arcSideStep = 3.15 / (arcSideData.length + 1)
        const arcSideTopChairKey = Number.isInteger(arcSideData.length / 2)
          ? null
          : Math.round(arcSideData.length / 2) - 1
        const flatSideStep =
          (item.width - flatSideData.length * 36) / (flatSideData.length + 1)

        const theta = arcSideData.map((_, index) => arcSideStep * (index + 1))

        return (
          <>
            {arcSideData.map((guest, key) => {
              const offset = arcSideTopChairKey
                ? key === arcSideTopChairKey
                  ? 0
                  : key < arcSideTopChairKey
                  ? -0.1
                  : 0.1
                : arcSideData.length === 1
                ? 0
                : key < arcSideData.length / 2
                ? arcSideData.length > 2
                  ? -0.05
                  : -0.1
                : arcSideData.length > 2
                ? 0.05
                : 0.1

              const posx = Math.round(radius * Math.cos(theta[key] + offset))
              const posy = Math.round(radius * Math.sin(theta[key] + offset))

              return (
                <Guest
                  key={key}
                  top={radius - posy - 30}
                  left={radius - posx - 30}
                  isEmpty={!guest}
                  style={style}
                  name={
                    getFullName([guest?.first_name, guest?.last_name]) ||
                    `${key + 1}`
                  }
                  image={getImage(guest?.user?.image)}
                />
              )
            })}

            {flatSideData.map((guest, key) => (
              <Guest
                key={key}
                bottom={-30}
                left={Math.round(flatSideStep * (key + 1) + key * 36)}
                isEmpty={!guest}
                style={style}
                name={
                  getFullName([guest?.first_name, guest?.last_name]) ||
                  `${arcSideData.length + flatSideData.length - key}`
                }
                image={getImage(guest?.user?.image)}
              />
            ))}
          </>
        )
      }

      if (type === 'circle') {
        const [data] = seats

        const radius = item.width / 2 + 12

        const theta = data.map(
          (_, index) => index / thetaValues['circle'][data.length]
        )

        return (
          <>
            {data.map((guest, key) => {
              const posx = Math.round(radius * Math.cos(theta[key]))
              const posy = Math.round(radius * Math.sin(theta[key]))

              return (
                <Guest
                  key={key}
                  top={radius - posy - 30}
                  left={radius - posx - 30}
                  isEmpty={!guest}
                  style={style}
                  name={
                    getFullName([guest?.first_name, guest?.last_name]) ||
                    `${key + 1}`
                  }
                  image={getImage(guest?.user?.image)}
                />
              )
            })}
          </>
        )
      }

      const data = [
        [...seats[0]].reverse(),
        seats[1],
        seats[2],
        [...seats[3]].reverse(),
      ]

      return data.map((side, type) => {
        const isVertical = type === 0 || type === 2
        const step =
          ((isVertical ? item.height : item.width) - side.length * 36) /
          (side.length + 1)

        return side.map((guest, key) => {
          const offset = Math.round(step * (key + 1) + key * 36)

          return (
            <Guest
              key={key}
              top={type === 1 ? -30 : isVertical ? offset : undefined}
              bottom={type === 3 ? -30 : undefined}
              left={!isVertical ? offset : type === 0 ? -30 : undefined}
              right={type === 2 ? -30 : undefined}
              isEmpty={!guest}
              style={style}
              name={`${
                getFullName([guest?.first_name, guest?.last_name]) ||
                (type === 1 || type === 2
                  ? data
                      .map((side) => side.length)
                      .slice(0, type)
                      .reduce((a, b) => a + b, 0) +
                    key +
                    1
                  : data
                      .map((side) => side.length)
                      .slice(0, type + 1)
                      .reduce((a, b) => a + b, 0) - key)
              }`}
              image={getImage(guest?.user?.image)}
            />
          )
        })
      })
    },
    [seats, type, item.width, item.height]
  )

  const onClickItem = useCallback(() => onSelectItem(schart_item_id), [
    schart_item_id,
    onSelectItem,
  ])

  const onRotate = useCallback(({ beforeRotate }: OnRotate) => {
    setItem((item) => ({
      ...item,
      rotation: Math.round(
        beforeRotate > 180
          ? -180 + (beforeRotate - 180)
          : beforeRotate < -180
          ? 180 + (beforeRotate + 180)
          : beforeRotate
      ),
    }))
  }, [])
  const onRotateStart = useCallback(
    (e) => {
      setItemIsDragging(true)

      e.set(item.rotation)
    },
    [setItemIsDragging, item.rotation]
  )
  const onRotateEnd = useCallback(() => {
    setItemIsDragging(false)

    onChangeItem({ schart_item_id, rotation: item.rotation })
  }, [setItemIsDragging, item.rotation, schart_item_id, onChangeItem])

  const onDragStart = useCallback(
    (e) => {
      setItemIsDragging(true)

      e.set([item.pos_x, item.pos_y])
    },
    [setItemIsDragging, item.pos_x, item.pos_y]
  )
  const onDrag = useCallback(({ beforeTranslate }: OnDrag) => {
    const [pos_x, pos_y] = beforeTranslate

    setItem((item) => ({ ...item, pos_x, pos_y }))
  }, [])
  const onDragEnd = useCallback(() => {
    setItemIsDragging(false)

    if (item.pos_x !== pos_x || item.pos_y !== pos_y) {
      onChangeItem({ schart_item_id, pos_x: item.pos_x, pos_y: item.pos_y })
    }
  }, [
    setItemIsDragging,
    item.pos_x,
    item.pos_y,
    pos_x,
    pos_y,
    schart_item_id,
    onChangeItem,
  ])

  const onResizeStart = useCallback(
    (e) => {
      setItemIsDragging(true)
      e.setOrigin(['%', '%'])

      if (e.dragStart) {
        e.dragStart.set([item.pos_x, item.pos_y])
      }
    },
    [setItemIsDragging, item.pos_x, item.pos_y]
  )
  const onResize = useCallback((e: OnResize) => {
    const [pos_x, pos_y] = e.drag.beforeTranslate

    setItem((item) => ({
      ...item,
      pos_x,
      pos_y,
      width: e.width,
      height: e.height,
    }))
  }, [])
  const onResizeEnd = useCallback(() => {
    setItemIsDragging(false)

    onChangeItem({
      schart_item_id,
      pos_x: item.pos_x,
      pos_y: item.pos_y,
      width: item.width,
      height: item.height,
    })
  }, [
    setItemIsDragging,
    schart_item_id,
    item.pos_x,
    item.pos_y,
    item.width,
    item.height,
    onChangeItem,
  ])

  useEffect(() => {
    if (moveableRef.current) {
      setItem((item) => ({
        ...item,
        rotation,
        width,
        height:
          type === 'circle' || type === 'square'
            ? width
            : type === 'halfcircle'
            ? Math.round(width / 2)
            : height,
      }))

      window.setTimeout(() => moveableRef?.current?.updateRect(), 50)
    }
  }, [rotation, width, height, type, moveableRef])

  const style = useMemo<CSSProperties>(
    () => ({
      transform: `translate(${item.pos_x}px, ${item.pos_y}px) rotate(${item.rotation}deg)`,
      width: item.width,
      height: item.height,
    }),
    [item]
  )

  const labelStyle = useMemo<CSSProperties>(
    () => ({
      transform: `rotate(${-item.rotation}deg)`,
    }),
    [item.rotation]
  )

  const [selectWidth, selectHeight] = useMemo(
    () => [item.width + PADDING * 2, item.height + PADDING * 2],
    [item.width, item.height]
  )

  const renderDropableChildren = useCallback(
    (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
      <Box ref={provided.innerRef} width="100%" height="100%">
        <Table
          isHover={snapshot.isDraggingOver}
          isSelected={isSelected}
          type={type}
          height={height}
          caption={isCaptionVisible ? caption : ''}
          style={labelStyle}
        />

        {provided.placeholder}
      </Box>
    ),
    [type, height, isSelected, isCaptionVisible, caption, labelStyle]
  )

  return (
    <>
      <Box
        ref={chartItemRef}
        bg="transparent"
        border="none"
        style={style}
        onClick={onClickItem}
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
        px="0px"
        cursor="pointer"
        zIndex={1}
      >
        {isChairsVisible && renderSeats(labelStyle)}

        <Droppable
          droppableId={schart_item_id}
          children={renderDropableChildren}
        />

        {isSelected && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={selectWidth}
            height={selectHeight}
            viewBox={`0 0 ${selectWidth} ${selectHeight}`}
            style={{ position: 'absolute' }}
          >
            <rect
              width="100%"
              height="100%"
              fill="none"
              stroke="#A3A3AC"
              strokeWidth="2"
              strokeDasharray="4,4"
            />
          </svg>
        )}
      </Box>

      <Moveable
        ref={moveableRef}
        target={chartItemRef}
        padding={{
          left: PADDING,
          top: PADDING,
          right: PADDING,
          bottom: PADDING,
        }}
        // Rotatable
        rotatable={isSelected}
        throttleRotate={1}
        rotationPosition="top"
        zoom={1}
        origin
        onRotateStart={onRotateStart}
        onRotate={onRotate}
        onRotateEnd={onRotateEnd}
        // Draggable
        draggable
        throttleDrag={0}
        startDragRotate={0}
        throttleDragRotate={0}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        // Resizable
        resizable={isSelected}
        keepRatio={type !== 'rect'}
        throttleResize={0}
        renderDirections={['nw', 'ne', 'sw', 'se']}
        edge={false}
        onResizeStart={onResizeStart}
        onResize={onResize}
        onResizeEnd={onResizeEnd}
        props={{
          edit: isSelected,
          height: item.height,
          style: labelStyle,
          isSeatsButtonHidden: isNoSeats,
          onSetEditModalAsSettings,
          onOpenPanel,
        }}
        ables={[EditChart]}
      />
    </>
  )
}

export default ChartItem
