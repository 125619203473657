import React, { FC } from 'react'

import { Box, Text, Button } from 'components/ui'
import { ReactComponent as EditIcon } from 'images/edit-4.svg'

type Props = {
  name?: string
  onClickEditButton: () => void
}

const Item: FC<Props> = ({ name, onClickEditButton }) => (
  <Box padding="10px 16px" display="flex" alignItems="center" justifyContent="space-between" height="40px">

    <Box>
      <Text fontFamily="Source Sans Pro" isTruncate lineHeight="20px" color="#353B60" fontSize="14px">
        {name}
      </Text>
    </Box>

    <Button padding="unset" border="none" onClick={onClickEditButton}>
      <Box borderRadius="50%" display="flex" justifyContent="center" alignItems="center" >
         <EditIcon stroke="#353B60" />
      </Box>
    </Button>
  </Box>
)

export default Item
