import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { Context } from '../Context'

// Components
import { Input } from 'components/form'
import { Box, Text } from 'components/ui'
import { EditContainerWrapper, MainContainer } from 'components/ResponsesModal/utils'

// Icons
import { ReactComponent as CloseIcon } from 'images/closeIconGrey.svg'

// Utils
import {
  ButtonsStyled,
  CancelButtonStyled,
  EditScheduleContainer,
  EventDetailContentStyled,
  SubmitButtonStyled,
  SubmitContainerStyled,
} from '../../Agenda/utils'


const AddQuestionDrawer = () => {
  const {
    isOpenAddQuestionDrawer,
    handleAddQuestion,
    handleCloseDrawer,
  } = useContext<any>(Context)


  let maxLengthQuestion = 140
  let maxLengthAnswer = 600
  let inputMaxLength: number
  let valueLength: number

  const handleCountTextLength = (e: any, fieldName: string) => {
    valueLength = e.target.value.length
    if (fieldName === 'question' && valueLength !== 0 && valueLength <= 140) {
      inputMaxLength = 140
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      maxLengthQuestion = 140 - valueLength
    } else if (fieldName === 'answer' && valueLength !== 0 && valueLength <= 600) {
      inputMaxLength = 600
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      maxLengthAnswer = 600 - valueLength
    }
  }

  return (
    <>
      {isOpenAddQuestionDrawer ? (
          <EditContainerWrapper onClick={() => handleCloseDrawer()}>
            <EditScheduleContainer onClick={(e) => e.stopPropagation()}>
              <MainContainer>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <Box width='100%' height='100%'>
                    <Text
                      fontFamily='Source Sans Pro'
                      fontWeight='700'
                      fontSize='32px'
                      lineHeight='40px'
                      color='#353B60'
                    >
                      Add Question
                    </Text>
                  </Box>
                  <Box cursor='pointer' width='100%'>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='end'
                      onClick={() => handleCloseDrawer()}
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                </Box>
                <EventDetailContentStyled>
                  <Form onSubmit={handleAddQuestion}>
                    {(props: any) => {

                      return (
                        <>
                          <Box height='100%' marginTop='24px'>
                            <Box marginBottom='20px'>
                              <Input
                                name='question'
                                label='Question'
                                placeholder='What’s your question?'
                                id='inputTitle'
                                maxLength={maxLengthQuestion}
                                showMaxLength={true}
                                inputMaxLength={inputMaxLength}
                                onInput={(e) => handleCountTextLength(e, 'question')}
                              />
                            </Box>
                            <Box marginBottom='20px'>
                              <Input
                                name='answer'
                                label='Answer'
                                placeholder='Add an answer to your question.'
                                isTextarea={true}
                                showMaxLength={true}
                                inputMaxLength={inputMaxLength}
                                style={{
                                  minHeight: '122px',
                                  maxHeight: '122px',
                                  padding: '11px 12px 89px 12px',
                                }}
                                maxLength={maxLengthAnswer}
                                onInput={(e) => handleCountTextLength(e, 'answer')}
                              />
                            </Box>

                          </Box>
                          <ButtonsStyled>
                            <SubmitContainerStyled>
                              <CancelButtonStyled
                                onClick={() => handleCloseDrawer()}
                              >
                                Cancel
                              </CancelButtonStyled>

                              <SubmitButtonStyled
                                onClick={props.handleSubmit}
                              >
                                Save
                              </SubmitButtonStyled>
                            </SubmitContainerStyled>
                          </ButtonsStyled>
                        </>
                      )
                    }}
                  </Form>
                </EventDetailContentStyled>
              </MainContainer>
            </EditScheduleContainer>
          </EditContainerWrapper>
        ) :
        null
      }
    </>
  )
}

export default AddQuestionDrawer