import React, { useEffect, useRef } from 'react'
import { Box } from '../../../../components/ui'
import { EditScheduleContainer, Wrapper } from '../../utils'
import { EditContainerWrapper } from '../../../../components/ResponsesModal/utils'
import AddCourse from '../Childrens/addCourse'

interface PropType {
  name: string;
  action: any;
  setIsModalOpen: any;
  menu_id: string;
  selectGroup: string;
}

const FromTheLeftSide = ({ name, action, setIsModalOpen, menu_id, selectGroup }: PropType) => {

  const modalRef = useRef<HTMLDivElement>(null)

  const closeModal = (e: any) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsModalOpen({ type: null, name: null })
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeModal)
    return () => document.removeEventListener('click', closeModal)
  }, [])

  return (
    <EditContainerWrapper>
      <EditScheduleContainer>
        <Box ref={modalRef} style={{ height: '100%' }}>
          <Wrapper>
            {
              name === 'Course' ? <AddCourse action={action} setIsModalOpen={setIsModalOpen} menu_id={menu_id}
                                             selectGroupId={selectGroup} /> :
                name === 'editGroupAndItems' ? <Box>Edit grops and Items</Box> : <Box>Add dish</Box>
            }
          </Wrapper>
        </Box>
      </EditScheduleContainer>
    </EditContainerWrapper>
  )
}

export default FromTheLeftSide