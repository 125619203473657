import React, {
  useEffect,
  useCallback,
  useState,
  ChangeEvent,
  useMemo,
} from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { Box, Text, Button } from 'components/ui'
import { SChart, SChartGuest, SChartItem } from 'store/modules/seatings/reducer'
import { ReactComponent as CloseIcon } from './close-message.svg'
import SearchInput from './SearchInput'
import Guest from './Guest'

type GruppedGuest = SChartGuest & { index: number }
type GuestGroup = {
  char: string
  guests: GruppedGuest[]
}

const getChar = (str: string) => str.trimStart().substr(0, 1).toLowerCase()

const groupGuests = (guests: SChart['schart_guests']) =>
  guests
    .reduce<GuestGroup[]>((groups, guest, index) => {
      const char = getChar(guest.first_name)
      const groupIndex = groups.findIndex((group) => group.char === char)
      const gruppedGuest = { ...guest, index }

      if (index === 0 || groupIndex === -1) {
        groups.push({
          char,
          guests: [gruppedGuest],
        })
      } else {
        groups[groupIndex].guests.push(gruppedGuest)
      }

      return groups
    }, [])
    .sort((a, b) => a.char.localeCompare(b.char))

type Props = {
  sChart?: SChart
  items: SChartItem[]
}

const Guests = ({ sChart, items }: Props) => {
  const [isMessageVisible, setMessageVisible] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [guestGroups, setGuestGroups] = useState<GuestGroup[]>([])
  const guests = useMemo(() => {
    if (!sChart?.schart_guests) {
      return []
    }

    return sChart.schart_guests.filter(
      (guest) =>
        !items.some((item) =>
          item.seats.some((side) =>
            side.some((chair) => chair?.guest_id === guest.guest_id)
          )
        ) || false
    )
  }, [sChart?.schart_guests, items])

  const onHideMessage = useCallback(() => setMessageVisible(false), [])

  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
  }, [])

  const renderContent = useCallback(() => {
    if (!guestGroups.length) {
      return (
        <Box mt="219px" display="flex" justifyContent="center">
          <Text fontSize="16px" color="#353B60">
            There are no guests to seat.
          </Text>
        </Box>
      )
    }

    return (
      <Droppable droppableId="disabled" isDropDisabled>
        {(provided) => (
          <div ref={provided.innerRef}>
            {guestGroups.map((group) => (
              <Box key={group.char}>
                <Box
                  height="24px"
                  width="244px"
                  borderRadius="8px"
                  bg="#F7F7FC"
                  pl="8px"
                  pt="1px"
                  mb="8px"
                >
                  <Text
                    isUppercase
                    fontWeight={600}
                    fontSize="14px"
                    lineHeight="18px"
                    color="#353B60"
                  >
                    {group.char}
                  </Text>
                </Box>

                {group.guests.map((guest) => (
                  <Draggable
                    draggableId={guest.guest_id}
                    index={guest.index}
                    key={guest.guest_id}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Guest
                          first_name={guest.first_name}
                          last_name={guest.last_name}
                          image={guest.user?.image}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </Box>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }, [guestGroups])

  const style = useMemo(
    () => ({
      height: isMessageVisible ? 'calc(100% - 200px)' : 'calc(100% - 144px)',
    }),
    [isMessageVisible]
  )

  useEffect(() => {
    if (searchValue.length === 0) {
      return setGuestGroups(groupGuests(guests))
    }

    return setGuestGroups(
      groupGuests(
        guests.filter((guest) =>
          [guest.first_name, guest.last_name]
            .join('')
            .toLowerCase()
            .replace(' ', '')
            .includes(searchValue.toLowerCase().replace(' ', ''))
        )
      )
    )
  }, [searchValue, guests])

  return (
    <>
      <SearchInput onSearch={onSearch} />

      <Box mb="16px" />

      {isMessageVisible && (
        <Box
          width="100%"
          height="40px"
          bg="#ECF8F8"
          border="1px solid #63C7C9"
          borderRadius="8px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px="12px"
          mb="16px"
        >
          <Text fontWeight={600} fontSize="14px" color="#63C7C9">
            Drag guests to tables
          </Text>

          <Button
            p="0px"
            width="16px"
            height="16px"
            bg="transparent"
            onClick={onHideMessage}
          >
            <CloseIcon />
          </Button>
        </Box>
      )}

      <Box overflowY="auto" mr="-16px" style={style}>
        {renderContent()}
      </Box>
    </>
  )
}

export default Guests
