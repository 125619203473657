import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router'

import { Box, Text } from 'components/ui'
import { WEDDING_CREATE } from 'constants/paths'
import { ReactComponent as Icon } from 'images/ww-intro.svg'
import { MainBox, Title, GetStartedButton } from 'components/WeddingWizard'

const Intro: FC = () => {
  const { push } = useHistory()

  const onGetStarted = useCallback(
    () =>
      push(`${WEDDING_CREATE}/step/1`, {
        transition: 'wizard-content-forward',
      }),
    [push]
  )

  return (
    <MainBox pt="70px" pb="31px">
      <Box mt="-100px" mb="28px">
        <Icon />
      </Box>

      <Box
        maxWidth="476px"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb="56px"
      >
        <Title>Welcome and congratulations  on your engagement!</Title>

        <Box maxWidth="338px" width="100%" display="flex" my="40px">
          <Text
            fontWeight={300}
            fontSize="24px"
            lineHeight="30px"
            color="secondary"
            textAlign="center"
          >
            Every good relationship starts with getting to know one another.
          </Text>
        </Box>

        <Text
          fontWeight={300}
          fontSize="24px"
          lineHeight="30px"
          color="secondary"
          textAlign="center"
        >
          Let’s begin by answering a few questions about  you, your partner, and
          your special day.
        </Text>
      </Box>

      <GetStartedButton onClick={onGetStarted} />
    </MainBox>
  )
}

export default Intro
