import React, { FC } from 'react'
import { join, map, slice } from 'ramda'

import { RecentLike } from 'store/modules/posts/reducer'
import { Box, Text } from 'components/ui'
import { ReactComponent as LikeIcon } from 'images/heart.svg'
import { getFullName } from 'utils/guest'

type Props = {
  like_count?: number
  recent?: RecentLike[]
}

const PostLikes: FC<Props> = ({ like_count = 0, recent = [] }) => {
  const otherCount = like_count - 3

  return (
    <Box display="inline-flex">
      <Box minWidth="20px" mr={0}>
        <LikeIcon stroke="#8991a0" width="19px" height="19px" />
      </Box>

      <Box mt="2px">
        <Text fontSize={1} fontWeight={600} lineHeight={1.13}>
          {join(
            ', ',
            map(
              (like) => getFullName([like.first_name, like.last_name]),
              slice(0, 3, recent)
            )
          )}
          {otherCount > 0 &&
            ` & ${otherCount} other${otherCount !== 1 ? 's' : ''}`}
        </Text>
      </Box>
    </Box>
  )
}

export default PostLikes
