import React, { useCallback, CSSProperties, useMemo } from 'react'

import { SChartItem } from 'store/modules/seatings/reducer'
import Seat from './Seat'
import Caption from './Caption'

const thetaValues = {
  halfcircle: [2, 2, 0.85, 0.73, 1.095, 1.46, 1.82, 2.19, 2.55, 2.91],
  circle: [1, 1, 0.106, 0.5, 0.635, 0.8, 0.97, 1.1, 1.27, 1.44],
}

const Item = ({
  schart_item_id,
  type,
  width,
  height,
  pos_x,
  pos_y,
  rotation,
  isChairsVisible,
  isCaptionVisible,
  caption,
  seats,
}: SChartItem) => {
  const renderItem = useCallback(() => {
    if (type === 'circle') {
      const [data] = seats

      const radius = width / 2 + 12

      const theta = data.map(
        (_, index) => index / thetaValues['circle'][data.length]
      )

      return (
        <>
          <circle r={width / 2} fill="#F3F3F6" stroke="#A3A3AC" />

          {isChairsVisible &&
            data.map((guest, key) => {
              const cx = Math.round(radius * Math.cos(theta[key]) * -1)
              const cy = Math.round(radius * Math.sin(theta[key]) * -1)

              return (
                <Seat
                  key={key}
                  index={key + 1}
                  guest={guest}
                  itemId={schart_item_id}
                  cx={cx}
                  cy={cy}
                  rotation={rotation}
                />
              )
            })}
        </>
      )
    }

    if (type === 'halfcircle') {
      const [arcSideData] = seats
      const flatSideData = [...seats[1]].reverse()

      const radius = width / 2 + 12
      const height = Math.round(width / 2)

      const arcSideStep = 3.15 / (arcSideData.length + 1)
      const arcSideTopChairKey = Number.isInteger(arcSideData.length / 2)
        ? null
        : Math.round(arcSideData.length / 2) - 1
      const flatSideStep =
        (width - flatSideData.length * 36) / (flatSideData.length + 1)

      const theta = arcSideData.map((_, index) => arcSideStep * (index + 1))

      return (
        <>
          <path
            d={`M 0 ${height} A ${height} ${height} 0 0 1 ${width} ${height} L 0 ${height} Z`}
            fill="#F3F3F6"
            stroke="#A3A3AC"
          />

          {arcSideData.map((guest, key) => {
            const offset = arcSideTopChairKey
              ? key === arcSideTopChairKey
                ? 0
                : key < arcSideTopChairKey
                ? -0.1
                : 0.1
              : arcSideData.length === 1
              ? 0
              : key < arcSideData.length / 2
              ? arcSideData.length > 2
                ? -0.05
                : -0.1
              : arcSideData.length > 2
              ? 0.05
              : 0.1

            const posx = Math.round(radius * Math.cos(theta[key] + offset))
            const posy = Math.round(radius * Math.sin(theta[key] + offset))

            return (
              <Seat
                key={key}
                index={key + 1}
                cy={radius - posy - 10}
                cx={radius - posx - 10}
                guest={guest}
                itemId={schart_item_id}
                rotation={rotation}
              />
            )
          })}

          {flatSideData.map((guest, key) => (
            <Seat
              key={key}
              index={arcSideData.length + flatSideData.length - key}
              cy={height + 10}
              cx={Math.round(flatSideStep * (key + 1) + key * 36) + 20}
              guest={guest}
              itemId={schart_item_id}
              rotation={rotation}
            />
          ))}
        </>
      )
    }

    const data = [
      [...seats[0]].reverse(),
      seats[1],
      seats[2],
      [...seats[3]].reverse(),
    ]

    return (
      <>
        <rect width={width} height={height} fill="#F3F3F6" stroke="#A3A3AC" />

        {data.map((side, type) => {
          const isVertical = type === 0 || type === 2
          const step =
            ((isVertical ? height : width) - side.length * 36) /
            (side.length + 1)

          return side.map((guest, key) => {
            const offset = Math.round(step * (key + 1) + key * 36)

            return (
              <Seat
                key={key}
                cy={
                  (type === 1
                    ? -30
                    : isVertical
                    ? offset
                    : type === 3
                    ? height - 10
                    : 0) + 20
                }
                cx={
                  (!isVertical
                    ? offset
                    : type === 0
                    ? -30
                    : type === 2
                    ? width - 10
                    : 0) + 20
                }
                index={
                  type === 1 || type === 2
                    ? data
                        .map((side) => side.length)
                        .slice(0, type)
                        .reduce((a, b) => a + b, 0) +
                      key +
                      1
                    : data
                        .map((side) => side.length)
                        .slice(0, type + 1)
                        .reduce((a, b) => a + b, 0) - key
                }
                guest={guest}
                itemId={schart_item_id}
                rotation={rotation}
              />
            )
          })
        })}
      </>
    )
  }, [schart_item_id, width, height, seats, rotation, isChairsVisible, type])

  const style = useMemo<CSSProperties>(
    () => ({
      transform: `translate(${pos_x}px, ${pos_y}px) rotate(${rotation}deg)`,
      ...(type !== 'circle'
        ? {
            transformOrigin: 'center center',
            transformBox: 'fill-box',
          }
        : undefined),
    }),
    [pos_x, pos_y, rotation, type]
  )

  return (
    <g
      style={style}
      data-tableid={schart_item_id}
      data-title={isCaptionVisible ? caption : undefined}
    >
      {renderItem()}

      {isCaptionVisible && (
        <Caption
          type={type}
          width={width}
          height={height}
          caption={caption}
          rotation={rotation}
        />
      )}
    </g>
  )
}

export default Item
