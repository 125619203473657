import React, { FC, useState, useCallback } from 'react'
import { FormRenderProps } from 'react-final-form'
import { prepend, reject, propEq } from 'ramda'

import { Photo } from 'store/modules/posts/reducer'
import { Box, Button, Select } from 'components/ui'
import { Input } from 'components/form'
import OptionsButton from './OptionsButton'
import PhotoPreview from './PhotoPreview'

const CreatePostForm: FC<FormRenderProps<{ photos: Photo[] }>> = ({
  handleSubmit,
  invalid,
  submitting,
  form: { change },
  values: { photos },
}) => {
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false)

  const handlePushPhoto = useCallback(
    (photo) => change('photos', prepend(photo, photos)),
    [change, photos]
  )
  const handleRemovePhoto = useCallback(
    (photoId) => change('photos', reject(propEq('photo_id', photoId), photos)),
    [change, photos]
  )
  const handleScheduleButtonClick = useCallback(
    () => setScheduleIsOpen(true),
    []
  )
  const handleClickScheduleDoneButton = useCallback(
    () => setScheduleIsOpen(false),
    []
  )

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Box mt={2}>
        <Input
          isTextarea
          name="caption"
          placeholder="Share something special with your guests..."
        />
      </Box>

      {photos.length > 0 && (
        <Box display="flex" flexWrap="wrap" mt={0}>
          {photos.map((photo) => (
            <PhotoPreview
              key={photo.photo_id}
              image={photo.image_small}
              onClickRemove={() => handleRemovePhoto(photo.photo_id)}
            />
          ))}
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" mt={1}>
        <OptionsButton onPushPhoto={handlePushPhoto} />

        <Box display="inline-flex">
          <Button
            border="none"
            mr={3}
            color="primary"
            disabled={scheduleIsOpen}
            onClick={handleScheduleButtonClick}
          >
            Schedule
          </Button>

          <Button
            variant="primary"
            type="submit"
            disabled={invalid || scheduleIsOpen || submitting}
          >
            Send
          </Button>
        </Box>
      </Box>

      {scheduleIsOpen && (
        <Box borderTop="1px solid" borderColor="gray" mt={1} pt={1}>
          <Box display="inline-flex" width="100%" alignItems="center" mb={1}>
            <Box width="120px">Date/Time</Box>

            <Box width="100%">
              <Select />
            </Box>
          </Box>

          <Box display="inline-flex" width="100%" alignItems="center" mb={1}>
            <Box width="120px">Time Zone</Box>

            <Box width="100%">
              <Select />
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="primary"
              type="submit"
              onClick={handleClickScheduleDoneButton}
            >
              Done
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CreatePostForm
