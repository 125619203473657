import { join } from 'ramda'

import { WEDDING_LIST } from '../actionTypes'

export const list = () => ({
  type: WEDDING_LIST.name,
  api: {
    url: 'wedding/list',
    data: {
      field_list: join(',', [
        'wuid',
        'wedding_name',
        'wedding_image',
        'spouse1_first_name',
        'spouse1_last_name',
        'spouse1_role',
        'spouse1_image',
        'spouse1_uuid',
        'spouse2_first_name',
        'spouse2_last_name',
        'spouse2_role',
        'spouse2_image',
        'spouse2_uuid',
        'wedding_date',
        'approx_date_year',
        'approx_date_month',
        'engagement_date',
        'location_name',
        'location_address',
        'location_city',
        'n_guests',
        'rsvp_status',
        'wedding_role',
        'theme_color',
        'theme_color2',
        'theme_font',
        'admins',
        'is_active',
        'notifications',
      ]),
    },
  },
})
