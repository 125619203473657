import React, { useEffect, useCallback, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd'
import { toast } from 'react-toastify'

import {
  loadCharts,
  loadItems,
  addItem,
  editItem,
  removeItem,
  editChart,
} from 'store/modules/seatings/actions'
import { SChart, SChartItem } from 'store/modules/seatings/reducer'
import {
  getSCharts,
  getSChartsIsLoaded,
  getSChartItemsLoaded,
} from 'store/modules/seatings/selectors'
import { Loading } from 'components/ui'
import { Panel, Grid, ChartModal, SvgChart } from 'components/Seating'

export enum Types {
  RECTANGLE = 'rect',
  SQUARE = 'square',
  CIRCLE = 'circle',
  HALF_CIRCLE = 'halfcircle',
  LONG = 'long',
  ROUND = 'round',
  DANCE_FLOOR = 'dancefloor',
  STAGE = 'stage',
}

export const TypeLabels = {
  [Types.CIRCLE]: 'Round',
  [Types.HALF_CIRCLE]: 'Half Round',
  [Types.RECTANGLE]: 'Rectangle',
  [Types.SQUARE]: 'Square',
  [Types.LONG]: 'Long',
  [Types.ROUND]: 'Round',
  [Types.DANCE_FLOOR]: 'Dance Floor',
  [Types.STAGE]: 'Stage',
}

enum ChartModalState {
  CLOSED,
  CREATE,
  EDIT,
}

const getDefaultWidthHeight = (
  type: SChartItem['type'],
  width?: number,
  height?: number
) => {
  switch (type) {
    case 'halfcircle':
      return { width: width || 200, height: height || 100 }
    case 'circle':
      return { width: width || 200, height: height || 200 }
    case 'rect':
      return { width: width || 300, height: height || 150 }
    default:
      return { width: width || 150, height: height || 150 }
  }
}

const itemToRequest = (item: SChartItem) => ({
  schart_id: item.schart_id,
  schart_item_id: item.schart_item_id,
  type: item.type,
  caption: item.caption,
  pos_x: item.pos_x,
  pos_y: item.pos_y,
  width: item.width,
  height: item.height,
  seats: JSON.stringify(item.seats),
  rotation: item.rotation,
})

const Seating = () => {
  const { wuid } = useParams<{ wuid: string }>()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const sChartsLoaded = useSelector(getSChartsIsLoaded(wuid))
  const sCharts = useSelector(getSCharts(wuid))
  const [activeChartId, setActiveChartId] = useState<string>()
  const [selectedItemId, selectItemId] = useState<string>()
  const [items, setItems] = useState<SChartItem[]>([])
  const sChartItemsLoaded = useSelector(getSChartItemsLoaded(activeChartId))
  const [chartModalState, setModalState] = useState(ChartModalState.CLOSED)
  const [modalChartId, setModalChartId] = useState<string>()
  const [activeChart, setActiveChart] = useState<SChart>()
  const [panelIsExpanded, setPanelIsExpanded] = useState(false)

  const onSaveChart = useCallback(
    async (chartId: string, zoom: number) =>
      dispatch(
        editChart(chartId, {
          zoom,
        })
      ),
    [dispatch]
  )

  const onSaveSvg = useCallback(async () => {
    if (!activeChart?.id) return

    const svg_guests_image = renderToString(
      <SvgChart items={items} images={[]} floorplan={activeChart?.floorplan} />
    )

    dispatch(
      editChart(activeChart.id, {
        svg_guests_image,
      })
    )
  }, [dispatch, items, activeChart?.id, activeChart?.floorplan])

  const onSaveItem = useCallback(
    async (item: SChartItem) => dispatch(addItem(itemToRequest(item))),
    [dispatch]
  )

  const onUpdateItem = useCallback(
    async (item: SChartItem) => dispatch(editItem(itemToRequest(item))),
    [dispatch]
  )

  const onRemoveSavedItem = useCallback(
    (itemId: string) => {
      if (activeChartId) {
        dispatch(removeItem(activeChartId, itemId))
      }
    },
    [activeChartId, dispatch]
  )

  const onChangeActiveChartId = useCallback(
    (chartId: string) => {
      const activeChart = sCharts.find((schart) => schart.id === chartId)
      if (!activeChart) {
        return
      }

      setActiveChartId(chartId)
      setActiveChart(activeChart)

      const items =
        sCharts.find((schart) => schart.id === chartId)?.schart_items || []

      setItems(
        items.map((item) => ({
          ...item,
          ...getDefaultWidthHeight(item.type, item.width, item.height),
        }))
      )
    },
    [sCharts]
  )

  const onChangeActiveChart = useCallback(
    (data: Partial<SChart>) =>
      setActiveChart((activeChart) =>
        activeChart ? { ...activeChart, ...data } : undefined
      ),
    []
  )

  const onSelectItem = useCallback(
    (itemId?: string) =>
      selectItemId((prevItemId) =>
        prevItemId === itemId ? undefined : itemId
      ),
    []
  )

  const onResetSelectedItemId = useCallback(() => selectItemId(undefined), [])

  const onZoom = useCallback(
    (value?: number) => {
      setActiveChart((chart) => {
        if (!chart) return undefined

        const zoom = value ? chart.zoom + value : 1

        onSaveChart(chart.id, zoom)

        return {
          ...chart,
          zoom,
        }
      })
    },
    [onSaveChart]
  )

  const onAddItem = useCallback(
    async (payload: Partial<SChartItem>) => {
      if (activeChartId) {
        const itemId = `new_${Math.floor(Math.random() * 1000)}`
        const item = {
          schart_item_id: itemId,
          schart_id: activeChartId,
          type: payload.type || 'square',
          caption: payload.caption || '',
          pos_x: payload.pos_x || 150,
          pos_y: payload.pos_y || 50,
          seats: payload.seats || [],
          isChairsVisible: true,
          isCaptionVisible: true,
          rotation: payload.rotation || 0,
          ...getDefaultWidthHeight(
            payload.type || 'square',
            payload.width,
            payload.height
          ),
        }

        setItems((items) => [...items, item])

        const res: any = await onSaveItem(item)
        const newId: number | undefined = res?.value?.payload?.schart_item_id

        if (newId) {
          setItems((items) =>
            items.map((item) =>
              item.schart_item_id === itemId
                ? { ...item, schart_item_id: newId.toString() }
                : item
            )
          )
        }

        onSaveSvg()
      }
    },
    [activeChartId, onSaveItem, onSaveSvg]
  )

  const onRemoveItem = useCallback(
    (itemId: SChartItem['schart_item_id']) => {
      setItems((items) =>
        items.filter((item) => item.schart_item_id !== itemId)
      )

      onRemoveSavedItem(itemId)
      onSaveSvg()
    },
    [onRemoveSavedItem, onSaveSvg]
  )

  const onChangeItem = useCallback(
    (payload: Partial<SChartItem>, isSync: boolean = false) => {
      let item: SChartItem | undefined = undefined

      setItems((items) => {
        const arr = Array.from(items)
        const index = arr.findIndex(
          (item) => item.schart_item_id === payload.schart_item_id
        )

        if (index !== -1) {
          arr[index] = { ...arr[index], ...payload }
          item = arr[index]
        }

        return arr
      })

      if (!isSync && item) {
        onUpdateItem(item)
        onSaveSvg()
      }
    },
    [onUpdateItem, onSaveSvg]
  )

  const onGuestDragEnd = useCallback<OnDragEndResponder>(
    ({ source, destination, draggableId }) => {
      if (!activeChart || !destination || !source) {
        return
      }

      let updatedItem: SChartItem | undefined
      setItems((items) => {
        const nextGuest = activeChart.schart_guests.find(
          (guest) => guest.guest_id === draggableId
        )
        const destItem = items.find(
          (v) => v.schart_item_id === destination.droppableId
        )

        if (
          !nextGuest ||
          !destItem ||
          destItem.seats.some((side) =>
            side.some((guest) => guest?.guest_id === nextGuest.guest_id)
          )
        ) {
          return items
        }

        let complited = false
        return items.map((item) => {
          if (item.schart_item_id === destItem.schart_item_id) {
            updatedItem = {
              ...item,
              seats: item.seats.map((side) =>
                side.map((guest) => {
                  if (!complited && !guest) {
                    complited = true

                    return nextGuest
                  }

                  return guest
                })
              ),
            }

            return updatedItem
          }

          return item
        })
      })

      if (updatedItem) {
        onUpdateItem(updatedItem)
      }
    },
    [activeChart, onUpdateItem]
  )

  const onCloseModal = useCallback(() => {
    setModalState(ChartModalState.CLOSED)
    setModalChartId(undefined)
  }, [])

  const onOpenCreateModal = useCallback(() => {
    setModalState(ChartModalState.CREATE)
    setModalChartId(undefined)
  }, [])

  const onOpenEditModal = useCallback((chartId: string) => {
    setModalState(ChartModalState.EDIT)
    setModalChartId(chartId)
  }, [])

  const onLoadCharts = useCallback(async () => dispatch(loadCharts()), [
    dispatch,
  ])

  const onLoadChartItems = useCallback(
    async (id: string) => dispatch(loadItems(id)),
    [dispatch]
  )

  useEffect(() => {
    const handleLoad = async () => {
      try {
        setIsLoading(true)

        await onLoadCharts()
      } catch (error) {
        toast(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    if (!isLoading && !sChartsLoaded) {
      handleLoad()
    }
  }, [isLoading, sChartsLoaded, onLoadCharts])

  useEffect(() => {
    if (!activeChartId && sChartsLoaded && sCharts) {
      onChangeActiveChartId(sCharts[0]?.id)
    }

    if (activeChartId) {
      if (sCharts.findIndex((schart) => schart.id === activeChartId) === -1) {
        onChangeActiveChartId(sCharts[0]?.id)
      }
    }
  }, [sCharts, sChartsLoaded, activeChartId, onChangeActiveChartId])

  useEffect(() => {
    if (activeChartId && !sChartItemsLoaded) {
      onLoadChartItems(activeChartId)
    }
  }, [activeChartId, sChartItemsLoaded, onLoadChartItems])

  useEffect(() => {
    if (activeChartId && sChartItemsLoaded) {
      onChangeActiveChartId(activeChartId)
    }
  }, [sChartItemsLoaded, activeChartId]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Loading />
  }

  return (
    <DragDropContext onDragEnd={onGuestDragEnd}>
      <ChartModal
        isOpen={chartModalState !== ChartModalState.CLOSED}
        isActiveChart={modalChartId === activeChartId}
        chartId={modalChartId}
        onCloseModal={onCloseModal}
        onChangeActiveChart={onChangeActiveChart}
      />

      {activeChart && (
        <Grid
          chartId={activeChart.id}
          caption={activeChart.caption}
          width={activeChart.width}
          height={activeChart.height}
          zoom={activeChart.zoom}
          items={items}
          selectedItemId={selectedItemId}
          onOpenCreateModal={onOpenCreateModal}
          onChangeActiveChartId={onChangeActiveChartId}
          onAddItem={onAddItem}
          onChangeItem={onChangeItem}
          onRemoveItem={onRemoveItem}
          onClickEditButton={onOpenEditModal}
          onSelectItem={onSelectItem}
          onResetSelectedItemId={onResetSelectedItemId}
          onZoom={onZoom}
          panelIsExpanded={panelIsExpanded}
          setPanelIsExpanded={setPanelIsExpanded}
        />
      )}

      {activeChartId && (
        <Panel
          items={items}
          chartId={activeChartId}
          isHidden={!panelIsExpanded}
          onChangeItem={onChangeItem}
        />
      )}
    </DragDropContext>
  )
}

export default Seating
