import { validate } from 'validate.js'
import { emailConstraint, presenceFieldConstraint } from 'constants/constraints'
import styled from 'styled-components'
import { Box, Button } from '../ui'

export type tabTypes = '0' | '1' | '2';

export const TabOptions: { name: tabTypes, title: string }[] = [
  {
    name: '0',
    title: 'Single',
  },
  {
    name: '1',
    title: 'Couple',
  },
  {
    name: '2',
    title: 'Family',
  },
]

export function getInitialFormData(tab: string) {
  switch (tab) {
    case '0':
      return {
        // first_name: '',
        // last_name: '',
        // email: '',
      }
    default:
      return {}
  }
}

export const handleValidate = (values: any, keys: any = [], isSubExist = false) => {
  let schema = {
    ...presenceFieldConstraint('first_name'),
    ...presenceFieldConstraint('last_name'),
    ...emailConstraint('email', false),
  }

  if (isSubExist) {
    schema = {
      ...schema,
      ...presenceFieldConstraint('first_name_sub'),
      ...presenceFieldConstraint('last_name_sub'),
      ...emailConstraint('email_sub', false),
    }
  }

  let customSchema = {}
  keys.forEach((key: string | number) => {
    customSchema = {
      ...customSchema,
      ...emailConstraint(`email_${key}`, false),
    }

  })

  schema = {
    ...schema,
    ...customSchema,
  }


  return validate(values, schema)
}

export const GuestFormStyled = styled.div`
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`

export const RemoveButtonStyled = styled.div`
  cursor: pointer;
`

export const label = {
  color: '#73737B',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  marginLeft: 10,
}

export const PlusOneBtn = styled(Button)`
  height: 40px;
  width: 156px;
  background-color: #FFFFFF;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #CBCBD3;
  outline: 0;
  padding: 10px 16px;
`

export const plusOneText = {
  fontFamily: 'Source Sans Pro',
  color: '#353B60',
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '25px',
  marginLeft: '8px',
}

export const DrawerContent = styled(Box)`
  ::-webkit-scrollbar {
    width: 0;
  }
`

export const PlusOneBtnBox = styled(Box)`
  display: inline-flex;
  align-items: center;

  ::before {
    content: " ";
    width: 126px;
    height: 1px;
    margin-right: 8px;
    vertical-align: super;
    background-color: #E8E8ED;
    display: inline-block;
  }

  ::after {
    content: " ";
    width: 126px;
    height: 1px;
    margin-left: 8px;
    vertical-align: super;
    background-color: #E8E8ED;
    display: inline-block;
  }
`

export const EventDetailContentStyled = styled.div`
  max-height: calc(100% - 165px);
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .DayPicker-wrapper {
    svg {
      color: #353B60;
    }
  }
`