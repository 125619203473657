import React, { FC } from 'react'

import { Box, Text, Button } from 'components/ui'
import { ReactComponent as InfoIcon } from 'images/info-circle.svg'
import { ReactComponent as CloseIcon } from 'images/close.svg'

type Props = {
  text: string
  onClose: (x: any) => any
}

const Alert: FC<Props> = ({ text, onClose }) => (
  <Box
    border="1px solid"
    borderColor="#fde8c8"
    borderRadius={0}
    bg="#fff7eb"
    py="14px"
    pl="60px"
    pr="56px"
    position="relative"
  >
    <Box position="absolute" left={2} top={2}>
      <InfoIcon stroke="#dfaf66" />
    </Box>

    <Box width="100%">
      <Text fontFamily="1" fontSize="15px" lineHeight="1.47">
        {text}
      </Text>
    </Box>

    {onClose && (
      <Box position="absolute" right={0} top={1}>
        <Button height="24px" border="none" bg="transparent" onClick={onClose}>
          <CloseIcon fill="#858ea2" />
        </Button>
      </Box>
    )}
  </Box>
)

export default Alert
