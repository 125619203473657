import React, { FC } from 'react'

import { Box } from 'components/ui'

const SetupSidebar: FC = () => {
  return (
    <Box pr="21px" height="100%">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        pt={1}
        width="100%"
        height="100%"
        borderRadius="10px"
        boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
        bg="#FFFFFF"
      />
    </Box>
  )
}

export default SetupSidebar
