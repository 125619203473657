import React, { useCallback } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { ColorPickerInput } from './Input'

type Props = { name: string; isDisabled?: boolean }

export const ColorPicker = ({ name, isDisabled }: Props) => {
  const renderColorSelector = useCallback(
    ({ input }: FieldRenderProps<{ label: string; value: string }>) => (
      <ColorPickerInput
        value={input.value?.value}
        isDisabled={isDisabled}
        onChange={input.onChange}
      />
    ),
    [isDisabled]
  )

  return <Field id={name} name={name} render={renderColorSelector} />
}
