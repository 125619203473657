import { Reducer } from 'redux'

import {
  LOAD_NAV,
  ADD_NAV,
  LOAD_NAV_ITEMS,
  TOGGLE_APP_SECTIONS,
  ADD_NAV_ITEM,
  EDIT_NAV_ITEM,
  DELETE_NAV_ITEM,
  SWAP_NAV_ITEMS,
} from './actionTypes'
import { transformNavItemToState } from './utils'

type Nav = {
  id: string
  wuid: string
}

export type NavItem = {
  nav_item_id: string
  nav_id: string
  parent_nav_item_id: string
  wuid: string
  type: number
  caption: string
  icon: string
  access_level: number
  link: string
  content: string
  is_active: boolean
  is_featured: boolean
  is_fixed: boolean
  ord: number
  insert_dt: Date
  update_dt: Date
  subitems: NavItem[]
}

export type State = {
  appSectionsExpanded?: boolean | NavItem['nav_item_id']
  navs: Nav[]
  items: NavItem[]
}

const initState: State = {
  appSectionsExpanded: false,
  navs: [],
  items: [],
}

const reducer: Reducer<State> = (state = initState, action) => {
  switch (action.type) {
    case LOAD_NAV.PENDING:
      return { ...state, appSectionsExpanded: false }
    case LOAD_NAV.FULFILLED:
      return {
        ...state,
        navs: state.navs.concat(
          action.payload.payload.navs.map((nav: Nav) => ({
            ...nav,
            wuid: action.payload.data.wuid,
          }))
        ),
      }
    case ADD_NAV.FULFILLED:
      return {
        ...state,
        navs: state.navs.concat({
          ...action.payload.payload.nav,
          wuid: action.payload.data.wuid,
        }),
      }
    case LOAD_NAV_ITEMS.FULFILLED:
    case SWAP_NAV_ITEMS.FULFILLED:
    case EDIT_NAV_ITEM.FULFILLED: {
      const nav = state.navs.find((v) => v.wuid === action.payload.data.wuid)

      return {
        ...state,
        items: [
          ...state.items.filter((v) => v.nav_id !== nav?.id),
          ...action.payload.payload.nav_items
            .filter((v: NavItem) => v.nav_item_id)
            .map(transformNavItemToState),
        ],
      }
    }

    case ADD_NAV_ITEM.FULFILLED:
      return {
        ...state,
        items: state.items.concat(
          transformNavItemToState(
            action.payload.payload.nav_items.find(
              (v: NavItem) =>
                v.nav_item_id == action.payload.payload.nav_item_id // eslint-disable-line eqeqeq
            )
          )
        ),
      }
    case DELETE_NAV_ITEM.FULFILLED:
      return {
        ...state,
        appSectionsExpanded: false,
        items: state.items.filter(
          (v) => v.nav_item_id !== action.payload?.data?.nav_item_id
        ),
      }
    case TOGGLE_APP_SECTIONS:
      return {
        ...state,
        appSectionsExpanded: action.payload || !state.appSectionsExpanded,
      }
    default:
      return state
  }
}

export default reducer
