import React, { MouseEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toast } from 'react-toastify'

// Components
import { Box, Button } from 'components/ui'

// Store tools
import { Photo } from 'store/modules/albums/reducer'
import { setFavorite } from 'store/modules/albums/actions'

// Icons
import { ReactComponent as CheckIcon } from './check.svg'
import { ReactComponent as VideoIcon } from './video.svg'
import { ReactComponent as FavoriteIcon } from './favorite.svg'
import { ReactComponent as NotFavoriteIcon } from './not-favorite.svg'

const Card = styled(Box)<{ isSelected: boolean }>`
  & > button:first-child {
    visibility: ${(props) => (props.isSelected ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.isSelected ? '1' : '0')};
  }

  #photoCardSelectButton {
    visibility: ${(props) => (props.isSelected ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.isSelected ? '1' : '0')};
  }

  #photoCardFavoriteButton {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    & > button:first-child, & > #photoCardSelectButton, & > #photoCardFavoriteButton {
      opacity: 1;
      visibility: visible;
    }
  }
`

const FavoriteButton = styled(Button)`
  &:disabled {
    background-color: transparent !important;
    opacity: 0.6;
  }
`

type Props = {
  photos: any[]
  isSelected: boolean
  onSelectPhoto: (e: MouseEvent<HTMLButtonElement>) => void
  onClickPhoto: (e: MouseEvent<HTMLButtonElement>) => void
  setIsSetFavorite: Function
  isSetFavorite: boolean
}

const PhotoCard = ({
                     image_big_icon,
                     isSelected,
                     photo_id,
                     media_type,
                     onSelectPhoto,
                     onClickPhoto,
                     photos,
                     setIsSetFavorite,
                     isSetFavorite,
                     original,
                   }: Photo & Props) => {

  const dispatch = useDispatch()

  const [isDisabled, setIsDisabled] = useState(false)
  const [isFav, setIsFav] = useState()

  useEffect(() => {
    const photo: any = photos?.find((photo: any) => photo.photo_id === photo_id)

    setIsFav(photo?.is_favorite)
  }, [photo_id, photos])

  const onSetFavorite = useCallback(
    async (photoId: string, is_favorite: string) => {
      return dispatch(setFavorite(photoId, is_favorite === '0' ? '1' : '0'))
    },
    [dispatch],
  )

  const onClickFavoriteButton = useCallback(
    async (photo_id) => {
      try {
        setIsDisabled(true)

        const photo: any = photos.find((photo: any) => photo.photo_id === photo_id)

        await onSetFavorite(photo?.photo_id, photo?.is_favorite)
        setIsSetFavorite(!isSetFavorite)
      } catch (error) {
        toast(error.message)
      } finally {
        setIsDisabled(false)
      }
    },
    [onSetFavorite, photos],
  )

  return (
    <Card
      style={{ aspectRatio: '1 / 1' }}
      position='relative'
      isSelected={isSelected}
    >
      <Button
        as='button'
        value={photo_id}
        borderRadius='8px'
        width='100%'
        height='100%'
        position='absolute'
        bg='rgba(163, 163, 172, 0.6)'
        zIndex={1}
        onClick={onClickPhoto}
      />

      <Button
        as='button'
        id='photoCardSelectButton'
        value={photo_id}
        position='absolute'
        zIndex={2}
        top='14px'
        right='14px'
        width='24px'
        height='24px'
        p='0px'
        border='2px solid #FFFFFF'
        borderRadius='50%'
        bg={isSelected ? '#5458F7' : 'transparent'}
        onClick={onSelectPhoto}
      >
        {isSelected && <CheckIcon />}
      </Button>

      {media_type === '1' && (
        <Box position='absolute' zIndex={2} right='12px' bottom='12px'>
          <VideoIcon />
        </Box>
      )}

      <FavoriteButton
        as='button'
        id={isFav === '0' ? 'photoCardFavoriteButton' : undefined}
        value={photo_id}
        position='absolute'
        width='24px'
        height='24px'
        left='12px'
        bottom='12px'
        p='0px'
        zIndex={2}
        bg='transparent'
        disabled={isDisabled}
        onClick={() => onClickFavoriteButton(photo_id)}
      >
        {isFav === '1' ? <FavoriteIcon /> : <NotFavoriteIcon />}
      </FavoriteButton>

      <Box
        borderRadius='8px'
        width='100%'
        height='100%'
        position='absolute'
        backgroundImage={`url(${image_big_icon ? image_big_icon : original})`}
        backgroundSize='cover'
      />
    </Card>
  )
}

export default PhotoCard
