import React from 'react'
import { Box } from 'components/ui'
import frame from './frame.svg'

export const SeatingChart = () => (
  <Box
    width="570px"
    height="492px"
    display="flex"
    justifyContent="center"
    mb="-126px"
    position="relative"
    backgroundImage={`url(${frame})`}
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
    backgroundPosition="0 -100px"
  ></Box>
)

export default SeatingChart
