import { REMOVE_GUEST } from '../actionTypes'

export const removeGuest = (guestId: string) => ({
  type: REMOVE_GUEST.name,
  api: {
    url: 'guests/remove_guest',
    data: {
      guest_id: guestId,
      remove_type: '3'
    },
  },
})
