import React, { FC } from 'react'
import { OptionProps } from 'react-select'
import { mergeLeft } from 'ramda'

import { Select } from 'components/form'
import { Box, Text } from 'components/ui'
import { RSVP_STATUS_OPTIONS } from 'constants/guests'

const RSVPSelector: FC<{ bg?: string }> = ({ bg }) => {
  const Option = ({ innerProps, label, data }: OptionProps<any, false>) => (
    <Box
      {...innerProps}
      display="inline-flex"
      alignItems="center"
      pl={3}
      py={1}
      cursor="pointer"
      width="100%"
    >
      <Box
        borderRadius="50%"
        height="12px"
        width="12px"
        mr={2}
        bg={data.color}
      />

      <Text fontFamily="1">{label}</Text>
    </Box>
  )

  return (
    <Box position="relative">
      <Box
        position="absolute"
        width="12px"
        height="12px"
        borderRadius="50%"
        bg={bg}
        zIndex={1}
        bottom="12px"
        left={1}
      />

      <Select
        label="RSVP"
        name="rsvp_status"
        options={RSVP_STATUS_OPTIONS}
        components={{ Option }}
        styles={{
          singleValue: mergeLeft({
            fontFamily: 'Work Sans',
            color: '#1e2027',
            marginLeft: 30,
          }),
        }}
        isSearchable={false}
      />
    </Box>
  )
}

export default RSVPSelector
