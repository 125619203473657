import { createSelector, Selector } from 'reselect'
import { pathOr, find, propEq, filter, findIndex } from 'ramda'

import { getMyId } from 'store/modules/auth/selectors'
import { State, Wedding, initialState } from './reducer'

const getState: Selector<{ wedding: State }, State> = pathOr(initialState, [
  'wedding',
])

export const getIsLoaded = createSelector(
  getState,
  pathOr<boolean>(false, ['isLoaded'])
)

export const getWeddings = createSelector(getState, getMyId, (state, uuid) => {
  const weddings = pathOr<Wedding[]>([], ['weddings'], state)

  return filter(
    ({ admins }) => findIndex(propEq('uuid', uuid), admins) !== -1,
    weddings
  )
})

export const getWedding = (wuid?: string) =>
  createSelector(getWeddings, (weddings) =>
    find(propEq('wuid', wuid), weddings)
  )

export const getIsGrid = createSelector(
  getState,
  pathOr<boolean>(false, ['isGrid'])
)
