import React, { useState, useCallback, useEffect } from 'react'
import { ColorChangeHandler } from 'react-color'
import OutsideClickHandler from 'react-outside-click-handler'
import { prepend } from 'ramda'

import Picker from './Picker'
import { Box, Button } from 'components/ui'
import { ReactComponent as PlusIcon } from 'images/plus.svg'
import { ReactComponent as CheckIcon } from 'images/check.svg'

// prettier-ignore
const defaultColors = [
  '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3',
  '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39',
]

type Props = {
  value?: string
  isDisabled?: boolean
  onChange: (value: { label: string; value: string }) => void
}

export const ColorPickerInput = ({ value, isDisabled, onChange }: Props) => {
  const [hex, setHex] = useState<string | undefined>(value)
  const [isOpen, setOpenState] = useState(false)
  const [colors, setColors] = useState(defaultColors)

  const handleClickAddIconButton = useCallback(() => setOpenState(!isOpen), [
    isOpen,
  ])

  const handleClose = useCallback(() => setOpenState(false), [])

  const handleSave = useCallback(() => {
    if (hex) {
      onChange({ label: hex, value: hex })
      setOpenState(false)
    }
  }, [onChange, hex])

  const handleChangeColor = useCallback<ColorChangeHandler>(
    ({ hex }) => setHex(hex),
    []
  )

  const handleSelectColor = useCallback(
    (color: string) => () => onChange({ label: color, value: color }),
    [onChange]
  )

  useEffect(() => {
    if (value && !colors.includes(value)) {
      setColors(prepend(value, colors).slice(0, defaultColors.length))
    }
  }, [value, colors])

  return (
    <Box
      width="100%"
      height="140px"
      mt="8px"
      border="1px solid #E3E3E3"
      borderRadius="6px"
      p="6px"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <OutsideClickHandler onOutsideClick={handleClose}>
        <Button
          width="52px"
          height="52px"
          borderRadius="50%"
          mx="6px"
          border="2px solid #E3E7ED"
          disabled={isDisabled}
          onClick={handleClickAddIconButton}
        >
          <PlusIcon width={28} height={28} fill="#7c8494" />
        </Button>

        {isOpen && (
          <Box position="absolute" top="40px" left="40px" zIndex={1}>
            <Picker
              color={hex}
              onChange={handleChangeColor}
              onClose={handleClose}
              onSave={handleSave}
            />
          </Box>
        )}
      </OutsideClickHandler>

      {colors.map((color) => (
        <Button
          key={color}
          width="52px"
          height="52px"
          mx="6px"
          my="6px"
          borderRadius="50%"
          bg={color}
          color="white"
          disabled={isDisabled}
          onClick={color ? handleSelectColor(color) : undefined}
        >
          {value === color && <CheckIcon width={22} height={22} />}
        </Button>
      ))}
    </Box>
  )
}
