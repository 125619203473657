import styled from 'styled-components'

import { Button } from 'components/ui'

const EmojiButton = styled(Button).attrs({
  border: 'none',
  borderRadius: 1,
  px: '0px',
  width: 1 / 8,
  height: '36px',
  fontSize: '30px',
  lineHeight: '1',
})`
  &:hover {
    background-color: #f2f3f5;
  }
`

export default EmojiButton
