import React, { FC } from 'react'

import { Button, Box } from 'components/ui'
import { ReactComponent as Icon } from 'images/google.svg'

const GoogleButton: FC = () => {
  const handleClick = () => {
    window.location.href = 'https://api.hitchhero.com/oauth/google/login'
  }

  return (
    <Button
      fullWidth
      border="2px solid"
      borderColor="#4c8bf5"
      borderRadius={0}
      bg="#4c8bf5"
      fontFamily="1"
      fontWeight={600}
      color="white"
      position="relative"
      onClick={handleClick}
    >
      <Box position="absolute" left={0}>
        <Icon height={25} />
      </Box>
      Sign in with Google
    </Button>
  )
}

export default GoogleButton
