import { createSelector, Selector } from 'reselect'
import { pathOr, compose, includes, filter, propEq } from 'ramda'

import { State, Comment, initialState } from './reducer'

const getState: Selector<{ comments: State }, State> = pathOr(initialState, [
  'comments',
])

export const getIsLoaded = (postId: string) =>
  createSelector(
    getState,
    compose(includes(postId), pathOr([], ['loadedPostIds']))
  )

export const getComments = (postId: string) =>
  createSelector(
    getState,
    compose(
      filter(propEq('post_id', postId)),
      pathOr<Comment[]>([], ['comments'])
    )
  )
