import gemoji from 'gemoji'
import {
  concat,
  filter,
  propEq,
  uniqBy,
  prop,
  map,
  groupBy,
  values,
  isEmpty,
  find,
  merge,
  either,
  findIndex,
  equals,
  mapObjIndexed,
} from 'ramda'
import { parseISO, differenceInMinutes } from 'date-fns'

const uniqKeys = {
  user: 'uid',
  room_ext: 'rid',
  msg: 'gid',
}

type OProps = 'user' | 'room_ext' | 'msg'
type TProps = 'f' | 'p' | 'c' | 'd'

export const getConcatedData = (
  o: OProps,
  data: {
    d: {
      o: OProps
      t: TProps
      u?: string
    }[]
  },
  initialData: any[]
) => {
  if (isEmpty(data.d)) return initialData

  const filteredItems = filter(propEq('o', o), data.d)
  const newItems = filter(propEq('t', 'f'), filteredItems)
  const updateItems = filter(
    either(propEq('t', 'p'), propEq('t', 'c')),
    filteredItems
  )
  const deletedItems = filter(propEq('t', 'd'), filteredItems)

  const uniqKey = uniqKeys[o]
  let res = uniqBy<any, any>(prop(uniqKey), concat(initialData, newItems))

  if (!isEmpty(deletedItems)) {
    res = filter(
      (item) =>
        equals(findIndex(propEq(uniqKey, item[uniqKey]), deletedItems), -1),
      res
    )
  }

  if (!isEmpty(updateItems)) {
    res = map((item) => {
      const updates = find(propEq(uniqKey, item[uniqKey]), updateItems)

      return updates ? merge(item, updates) : item
    }, res)
  }

  return res
}

type Gemoji = {
  category: string
  description: string
  emoji: string
  name: string
  names: string[]
  tags: string[]
}

export const getEmojis = () => {
  const emojiValues = groupBy(
    prop('category'),
    values(gemoji.name as { [name: string]: Gemoji })
  )

  return mapObjIndexed(uniqBy(prop('name')), emojiValues)
}

export const getDateDistance = (date: string) => {
  const difference = differenceInMinutes(new Date(), parseISO(date))

  if (difference < 1) {
    return 'NOW'
  }

  if (difference < 60) {
    return `${difference}m`
  }

  if (difference < 24 * 60) {
    return `${Math.floor(difference / 60)}h`
  }

  return `${Math.floor(difference / (24 * 60))}d`
}
