import React, { FC } from 'react'
import { FormRenderProps } from 'react-final-form'
import { getData as getCountryList } from 'country-list'
import { map, path } from 'ramda'

import { Box, Button, Text } from 'components/ui'
import { Select, Input, NoteInput } from 'components/form'
import { PhotoUploader, GroupSelect, RSVPSelector } from 'components/Guests'
import { PHONE_MASK } from 'constants/masks'
import { PLUS_ONE_COUNT_OPTIONS } from 'constants/guests'
import { getFullName } from 'utils/guest'

type Props = {
  isEdit: boolean
  onCancel: () => void
}

const GuestDetailsForm: FC<FormRenderProps<any> & Props> = ({
  isEdit,
  handleSubmit,
  onCancel,
  submitting,
  values,
  invalid,
}) => {

  return (
    <Box as="form" autoComplete="off" onSubmit={handleSubmit}>
      {isEdit && (
        <PhotoUploader
          userName={getFullName([values.first_name, values.last_name])}
        />
      )}

      <Box display="flex" mt={1}>
        <Box width={1 / 2} pr="12px">
          <Box mb={1}>
            <Input
              name="first_name"
              label="First Name"
              isRequired
              placeholder="First Name"
            />
          </Box>

          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
          />
        </Box>

        <Box width={1 / 2} pl="12px">
          <Box mb={1}>
            <Input
              name="last_name"
              label="Last Name"
              isRequired
              placeholder="Last Name"
            />
          </Box>

          <Input
            name="phone"
            label="Phone"
            placeholder="Phone"
            mask={PHONE_MASK}
          />
        </Box>
      </Box>

      <Box mt={3}>
        <Text fontFamily="1" fontSize={2} fontWeight={600}>
          Wedding Details
        </Text>
      </Box>

      <Box mt={0} display="flex">
        {isEdit ? (
          <>
            <Box width={1 / 2} pr="12px">
              <Box mb={1}>
                <GroupSelect />
              </Box>

              <Select
                name="invitesCount"
                label="+1 Count"
                placeholder="Select Count"
                options={PLUS_ONE_COUNT_OPTIONS}
              />
            </Box>

            <Box width={1 / 2} pl="12px">
              <RSVPSelector bg={path(['rsvp_status', 'color'], values)} />
            </Box>
          </>
        ) : (
          <>
            <Box width={1 / 2} pr="12px">
              <GroupSelect />
            </Box>

            <Box width={1 / 2} pl="12px">
              <Select
                name="invitesCount"
                label="+1 Count"
                placeholder="Select Count"
                options={PLUS_ONE_COUNT_OPTIONS}
              />
            </Box>
          </>
        )}
      </Box>

      <Box mt={3}>
        <Text fontFamily="1" fontSize={2} fontWeight={600}>
          Address Information
        </Text>
      </Box>

      <Box mt={0}>
        <Input name="street" label="Street" placeholder="Street" />
      </Box>

      <Box display="flex">
        <Box width={1 / 2} pr="12px">
          <Box mt={1}>
            <Input name="city" label="City" placeholder="City" />
          </Box>

          <Box mt={1}>
            <Input name="zip" label="Postal Code" placeholder="Postal Code" />
          </Box>
        </Box>

        <Box width={1 / 2} pl="12px">
          <Box mt={1}>
            <Input name="state" label="State" placeholder="State" />
          </Box>

          <Box mt={1}>
            <Select
              name="country"
              label="Country"
              placeholder="Country"
              options={map(
                (country) => ({
                  value: country.code,
                  label: country.name,
                }),
                getCountryList()
              )}
            />
          </Box>
        </Box>
      </Box>

      <Box mt={1}>
        <NoteInput name="comment" label="Notes" placeholder="Notes" />
      </Box>

      <Box mt={2} display="flex" alignItems="center">
        <Box mr={1}>
          <Button
            variant="primary"
            type="submit"
            disabled={submitting || invalid}
          >
            Save
          </Button>
        </Box>

        <Button onClick={onCancel} fontSize="18px" color="blue">
          Cancel
        </Button>
      </Box>
    </Box>
  )
}

export default GuestDetailsForm
