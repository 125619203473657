import { DELETE_MENU } from '../../actionTypes'
import { DeleteMenuNeedData } from '../../../../../routes/FoodMenu/tipes_interfaces'

export const remove = (data: DeleteMenuNeedData) => {
  console.log('action loadFoodMenu working')
  return {
    type: DELETE_MENU.name,
    api: {
      url: 'menu/remove',
      data,
    },
  }
}