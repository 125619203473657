import React, { FC, useState, useCallback } from 'react'

import { Box, Text, Button } from 'components/ui'
import { Uploader, Cropper } from 'components/WeddingWizard'
import { cropImage } from 'utils/images'
import { ReactComponent as Icon } from 'images/ww-step7.svg'

type Props = {
  croppedImageSrc?: string
  imageSrc?: string
  onChange: (name: string, value: any) => void
  isValid: boolean
  onBack: VoidFunction
  onContinue: VoidFunction
}

type PixelCropProps = {
  x: number
  y: number
  width: number
  height: number
}

const Step7: FC<Props> = ({
  croppedImageSrc,
  imageSrc,
  onChange,
  isValid,
  onBack,
  onContinue,
}) => {
  const [showCropper, setShowCropper] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCropProps>()

  const handleClickZoomButton = () => setShowCropper(true)
  const handleClickDeleteButton = useCallback(() => {
    onChange('imageSrc', null)
    onChange('croppedImageSrc', null)
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setCroppedAreaPixels(undefined)
  }, [onChange])
  const handleFileChange = useCallback(
    (imageDataUrl) => {
      handleClickDeleteButton()
      onChange('imageSrc', imageDataUrl)
    },
    [handleClickDeleteButton, onChange]
  )
  const handleCropChange = useCallback((crop) => setCrop(crop), [])
  const handleCropComplete = useCallback(
    (_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels),
    []
  )
  const handleZoomChange = useCallback((zoom) => setZoom(zoom), [])
  const handleClickCropperBack = useCallback(() => setShowCropper(false), [])
  const handleClickCrop = useCallback(
    async (event) => {
      event.preventDefault()

      if (imageSrc && croppedAreaPixels) {
        const croppedImageSrc = await cropImage(imageSrc, croppedAreaPixels)

        onChange('croppedImageSrc', croppedImageSrc)
        handleClickCropperBack()
      }
    },
    [onChange, handleClickCropperBack, imageSrc, croppedAreaPixels]
  )

  return (
    <Box
      width="100%"
      maxWidth="740px"
      boxShadow="0px 8px 24px rgba(0, 0, 0, 0.15)"
      bg="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      pt="70px"
      pb="28px"
      px="26px"
    >
      <Box mt="-102px" mb="28px">
        <Icon />
      </Box>

      <Text
        fontSize={['28px', 5]}
        fontWeight="bold"
        color="secondary"
        lineHeight={['normal', '45px']}
        textAlign="center"
      >
        A picture is worth a thousand words.
      </Text>

      <Box mt="8px" />

      <Text
        fontWeight={300}
        fontSize="20px"
        lineHeight="28px"
        color="secondary"
        textAlign="center"
      >
        Upload your favorite engagement photo or snap a selfie!
      </Text>

      <Box
        width="100%"
        maxWidth="400px"
        display="flex"
        flexDirection="column"
        mt="20px"
      >
        <Box width="100%">
          {showCropper && imageSrc ? (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              onCropChange={handleCropChange}
              onCropComplete={handleCropComplete}
              onZoomChange={handleZoomChange}
            />
          ) : (
            <Uploader
              image={croppedImageSrc || imageSrc}
              onClickZoomButton={handleClickZoomButton}
              onClickDeleteButton={handleClickDeleteButton}
              onChange={handleFileChange}
            />
          )}
        </Box>

        <Box
          mt={showCropper && imageSrc ? '26px' : '29px'}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="secondary"
            height="40px"
            onClick={showCropper ? handleClickCropperBack : onBack}
          >
            Back
          </Button>

          <Button
            variant="primary"
            disabled={!isValid}
            height="40px"
            onClick={showCropper ? handleClickCrop : onContinue}
          >
            {showCropper ? 'Done' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Step7
