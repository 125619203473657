import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import validate from 'validate.js'
import { toast } from 'react-toastify'

import { Box } from 'components/ui'
import { presenceFieldConstraint } from 'constants/constraints'
import { addPost } from 'store/modules/posts/actions'
import { transformToRequest } from 'utils/posts'
import Tabs from './Tabs'
import CreatePostForm from './Form'

const CreatePost: FC = () => {
  const dispatch = useDispatch()

  const onAddPost = useCallback(async (data) => dispatch(addPost(data)), [
    dispatch,
  ])

  const handleValidate = (values: any) =>
    validate(
      values,
      presenceFieldConstraint('caption', values.photos.length < 1)
    )
  const handleSubmit = async (values: object, { reset }: any) => {
    try {
      await onAddPost(transformToRequest(values))

      setTimeout(() => reset(), 300)
    } catch (error) {
      toast(error.message)
    }
  }

  return (
    <Box border="1px solid" borderColor="gray" borderRadius={0} px={2} py={1}>
      <Tabs />

      <Form
        validate={handleValidate}
        initialValues={{ photos: [] }}
        onSubmit={handleSubmit}
        component={CreatePostForm}
      />
    </Box>
  )
}

export default CreatePost
