import React, { CSSProperties, useMemo } from 'react'
import styled from 'styled-components'

import { Box, Text } from 'components/ui'
import { SChartItem } from 'store/modules/seatings/reducer'

const hoverBorder = '2px solid #5458f7'

const Wrapper = styled(Box).attrs({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})`
  &:hover {
    border: ${hoverBorder};
  }
`

const Caption = styled(Text).attrs({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: '#73737B',
  textAlign: 'center',
  isSelectable: false,
})`
  overflow-wrap: anywhere;
`

type Props = {
  type: SChartItem['type']
  isHover: boolean
  isSelected: boolean
  height?: CSSProperties['height']
  style: CSSProperties
  caption?: SChartItem['caption']
}

const Table = ({
  type,
  height,
  isHover,
  isSelected,
  caption,
  style,
}: Props) => {
  const [bg, border] = useMemo(
    () => [
      isHover ? '#DDDEFD' : '#F3F3F6',
      isHover || isSelected ? hoverBorder : '1px solid #A3A3AC',
    ],
    [isHover, isSelected]
  )

  switch (type) {
    case 'halfcircle':
      return (
        <Wrapper
          bg={bg}
          border={border}
          style={{
            borderTopLeftRadius: height,
            borderTopRightRadius: height,
          }}
        >
          <Caption style={style}>{caption}</Caption>
        </Wrapper>
      )
    case 'circle':
      return (
        <Wrapper borderRadius="50%" bg={bg} border={border}>
          <Caption style={style}>{caption}</Caption>
        </Wrapper>
      )
    default:
      return (
        <Wrapper bg={bg} border={border}>
          <Caption style={style}>{caption}</Caption>
        </Wrapper>
      )
  }
}

export default Table
