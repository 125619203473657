import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { listAdmin } from 'store/modules/guests/actions'
import { getGuestsIsLoaded, getGuests } from 'store/modules/guests/selectors'
import { Box } from 'components/ui'
import GuestsSelector from './GuestsSelector'
import AccessTypeSelector from './AccessTypeSelector'

const Selector = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const isLoaded = useSelector(getGuestsIsLoaded)
  const guests = useSelector(getGuests)

  const onLoad = useCallback(async () => dispatch(listAdmin({})), [dispatch])

  const handleLoad = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoad()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading, onLoad])

  useEffect(() => {
    if (!isLoaded) handleLoad()
  }, [isLoaded, handleLoad])

  return (
    <Box position="relative" width="100%">
      <GuestsSelector isLoading={isLoading} guests={guests} />

      <Box position="absolute" width="100px" right="0px" top="0px">
        <AccessTypeSelector />
      </Box>
    </Box>
  )
}

export default Selector
