import React, { FC, useContext, useEffect, useState } from 'react'
import { format, parse, parseISO } from 'date-fns'
import { Context } from '../Context'
import { Box, Text } from 'components/ui'
import { AgendaItem } from 'store/modules/agenda/reducer'
import {
  Table,
  TableRows,
  TableRow,
  RowContent,
  RowItems,
  ItemsStyled,
  DottedText,
  TitleText,
  EventAccessOptions,
  menuItems,
} from '../utils'

// Icons
import { ReactComponent as AddressIcon } from 'images/location.svg'
import { ReactComponent as DressIcon } from 'images/dress.svg'
import { ReactComponent as GuestAccessIcon } from 'images/guestAccess.svg'
import { ReactComponent as NoteIcon } from 'images/note.svg'
import ThreeDotMenu from 'components/ui/ThreeDotMenu'

const ScheduleTable: FC = () => {
  const {
    sortedItems,
    filteredItems,
    sortedByDate,
    selectedSchedules,
    handleOpenEditModal,
    handleClickMenuItem,
  } = useContext<any>(Context)

  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    if (filteredItems !== undefined) {
      setFiltered(filteredItems)
    } else {
      setFiltered(sortedItems)
    }
  }, [filteredItems, sortedItems])

  return (
    <Table>
      {
        Object.keys(filteredItems !== undefined ? filteredItems : sortedItems)?.sort((a: any, b: any) => sortedByDate === 'Start Date' ? new Date(a) - new Date(b) : sortedByDate === 'End Date' ? new Date(b) - new Date(a) : '').map((item: string, index: number) => (
          <Box key={item}>
            <Box marginTop={index !== 0 ? '20px' : ''} marginBottom='20px'>
              <Text
                fontFamily='Source Sans Pro'
                fontWeight='700'
                fontSize='20px'
                lineHeight='25px'
                color='#353B60'
              >
                {format(
                  parse(item, 'yyyy-MM-dd', new Date()),
                  'EEEE, LLLL d, yyyy',
                )}
              </Text>
            </Box>
            {filtered[item]?.sort((a: any, b: any) => a.title.localeCompare(b.title)).map((agenda: AgendaItem) => {
              return (
                <Box
                  display='flex'
                  alignItems='center'
                  marginBottom='16px'
                  marginLeft='8px'
                  key={agenda.agenda_item_id}
                >
                  <Box minWidth='60px' maxWidth='60px' marginRight='24px'>
                    <Box
                      marginBottom='8px'
                      style={{
                        fontFamily: 'Source Sans Pro',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#414042',
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {format(parse(agenda.start_time, 'HH:mm:ss', new Date()), 'h:mm a')}
                    </Box>
                    <Box
                      style={{
                        fontFamily: 'Source Sans Pro',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#A3A3AC',
                        textAlign: 'center',
                      }}
                    >
                      {format(parse(agenda.end_time, 'HH:mm:ss', new Date()), 'h:mm a')}
                    </Box>
                  </Box>
                  <TableRows
                    key={agenda.agenda_item_id}
                    background={selectedSchedules.includes(Number(agenda.agenda_item_id)) ? '#F7F7FC' : '#FFFFFF'}
                  >
                    <TableRow>
                      <Box
                        backgroundColor='#DDDEFD'
                        width='8px'
                        height='100%'
                        borderRadius='20px 0px 0px 20px'
                        position='absolute'
                        top='0px'
                        left='0px'
                      />
                      <RowContent>
                        <RowItems>
                          <ItemsStyled
                            onClick={() => handleOpenEditModal(agenda.agenda_item_id)}
                            cursor='pointer'
                          >
                            <Box
                              overflow='hidden'
                              minWidth={200}
                              maxWidth={200}
                            >
                              <Box>
                                <TitleText>
                                  {agenda.title ? agenda.title : '-'}
                                </TitleText>
                              </Box>
                            </Box>
                            <Box
                              overflow='hidden'
                              minWidth={156}
                            >
                              <Box display='flex' alignItems='center'>
                                <Box marginRight='4px'>
                                  <AddressIcon stroke='#414042' />
                                </Box>
                                <Box minWidth={156} maxWidth={156}>
                                  <DottedText>
                                    {!agenda.location_name && !agenda.location_address ? <Text
                                      color='#A3A3AC'> Add
                                      Location</Text> : agenda.location_name
                                    }
                                  </DottedText>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              overflow='hidden'
                              minWidth={156}
                            >
                              <Box display='flex' alignItems='center'>
                                <Box marginRight='4px'>
                                  <GuestAccessIcon stroke='#323232' />
                                </Box>
                                <Box minWidth={156} maxWidth={156}>
                                  <Text
                                    fontFamily='Source Sans Pro'
                                    fontWeight='400'
                                    fontSize='14px'
                                    lineHeight='18px'
                                    color='#414042'
                                  >
                                    {agenda?.access_mode ? EventAccessOptions[Number(agenda?.access_mode)].title : '-'}
                                  </Text>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              overflow='hidden'
                              minWidth={156}
                            >
                              <Box display='flex' alignItems='center'>
                                <Box marginRight='4px'>
                                  <DressIcon stroke='#414042' />
                                </Box>
                                <Box minWidth={156} maxWidth={156}>
                                  <DottedText>
                                    {agenda.attire ? agenda.attire : <Text color='#A3A3AC'>Add Dress Code</Text>}
                                  </DottedText>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              overflow='hidden'
                              minWidth={156}
                            >
                              <Box display='flex' alignItems='center'>
                                <Box marginRight='4px'>
                                  <NoteIcon stroke='#414042' />
                                </Box>
                                <Box minWidth={156} maxWidth={156}>
                                  <DottedText>
                                    {agenda.notes ? agenda.notes : <Text color='#A3A3AC'>Add Description</Text>}
                                  </DottedText>
                                </Box>
                              </Box>
                            </Box>
                          </ItemsStyled>
                          <Box display='flex' alignItems='center' width='26px' marginLeft='234px'>
                            <ThreeDotMenu
                              menuItems={menuItems}
                              handleClick={(eventName: string) => handleClickMenuItem(eventName, agenda.agenda_item_id)}
                              event={{
                                title: agenda.title,
                                startTime: format(parseISO(new Date(`${agenda.start_date} ${agenda.start_time}`).toISOString()), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
                                endTime: format(parseISO(new Date(`${agenda.end_date} ${agenda.end_time}`).toISOString()), 'yyyy-MM-dd\'T\'HH:mm:ssXXX'),
                                location: agenda.location_address,
                                description: agenda.notes,
                              }}
                            />
                          </Box>
                        </RowItems>
                      </RowContent>
                    </TableRow>
                  </TableRows>
                </Box>
              )
            })}
          </Box>
        ))
      }
    </Table>
  )
}

export default ScheduleTable