export const PHONE_MASK = [
  '+',
  '1',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const INPUT_MASK_GUEST_UPDATE_PHONE_NUMBER = [
  /[1-9]/,
  /[1-9]/,
  /[1-9]/,
  '-',
  /[1-9]/,
  /[1-9]/,
  /[1-9]/,
  '-',
  /[1-9]/,
  /[1-9]/,
  /[1-9]/,
  /[1-9]/,
]

export const TIME_MASK = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/]
