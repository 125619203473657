import React, { FC } from 'react'

import { Button } from 'components/ui'
import { ReactComponent as LeftIcon } from './arrow-left.svg'
import { ReactComponent as RightIcon } from './arrow-right.svg'

type Props = {
  isPrev?: boolean
  onClickButton: () => void
}

const NavButton: FC<Props> = ({ isPrev = false, onClickButton }) => (
  <Button
    border="none"
    borderRadius="50%"
    width="56px"
    height="56px"
    bg="rgba(50, 50, 50, 0.8)"
    position="absolute"
    top="calc(50% - 56px)"
    zIndex={1}
    right={isPrev ? undefined : '20px'}
    left={isPrev ? '20px' : undefined}
    onClick={onClickButton}
  >
    {isPrev ? <LeftIcon /> : <RightIcon />}
  </Button>
)

export default NavButton
