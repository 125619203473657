import styled from '@emotion/styled'
import { Box, Button } from '../../components/ui'

export const FoodMenuWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #FFFFFF;
  border-radius: 8px;
`
export const FoodList = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 60%;
  border-right: 1px solid #CBCBD3;

  .buttonsWrapper {
    //padding: 16px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    padding: 7px 0px 8px;
    //padding: 7px 16px 8px; // todo 
    align-items: center;
    //margin-bottom: 16px;

    .plus-btn-box {
      height: 20px;
      cursor: pointer;
    }
  }

  .groupsWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 31px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 8px 16px;

    ::-webkit-scrollbar {
      width: 0;
    }
  }
`

export const TitleAndAddButton = styled(Box)`
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  //padding: 16px 16px 23px 16px;
  padding: 16px;

  .title {
    color: #353B60;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  .addBtn {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
  }

  //border: 2px solid black;
  //background-color: #4a90e2;
`

export const AddButton = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px 0 16px;
  gap: 8px;
  width: 142px;
  height: 40px;
  background: #5458F7;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`
export const Selections = styled(Box)`
  width: 40%;
  padding: 20px;

  .heading {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #353B60;
  }
`

export const BtnGroup = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 12px;
`

export const List = styled(Box)`
  display: flex;
  flex-direction: column;
  //padding: 10px 0;

  .listHeading {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #353B60;
    //padding: 6px 0;
  }

  .groupItems {
    display: flex;
    flex-direction: column;
    row-gap: 23px;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .subTitle {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #414042;
    //padding: 4px 0;
  }

  .title {
    font-family: 'Source Sans Pro';
    //max-width: 907px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #73737B;
    //padding: 4px 0;
    //padding: 4px 0;
  }

`

export const ReceptionDinnerBtn = styled(Box)`
  width: max-content;
  height: 40px;
  //padding: 8px 16px 11px 16px;
  //background: #F7F7FC;
  background-color: #F7F7FC;
  //border-bottom: 3px solid #5458F7;
  //background-color: red;

  .btnTitle {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5458F7;
    background-color: #F7F7FC;

    padding: 8px 16px 11px 16px;
  }
`

export const Input = styled(Box)`
  width: 50%;
  height: 40px;
  border-radius: 10px;
`

export const MenuModal = styled(Box)`
  width: 24%;
  height: 100%;
  background-color: #ffffff;
  position: absolute;
  right: 0;
  border-radius: 10px 10px 10px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
`

export const ModalWrapper = styled(Box)`
  width: 100%;
  min-height: 100vh;
  height: max-content;
  background-color: rgba(115, 115, 123, 0.6);
  position: absolute;
  z-index: 1000;
  top: 0;
  border-radius: 10px 10px 10px 0;
`

export const AddBtn = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px 0px 16px;
  width: 100px;
  height: 40px;
  background: #5458F7;
  border-radius: 8px;

  .btnTitle {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
`

export const ModalBackground = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: rgba(115, 115, 123, 0.6);
  position: absolute;
  z-index: 999999999;
  top: 0;
  border-radius: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Modal = styled(Box)`
  //width: 400px;
  //height: 140px;
  //background-color: rgba(115, 115, 123, 0.6);
  //position: absolute;
  //z-index: 1000;
  //border-radius: 10px;
  //background-color: #ffffff;
  //padding: 10px;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  //align-items: center;

  .modalMassage {
    font-size: 20px;
    font-weight: normal;
  }

  .buttonsWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 10px;

    .confirmBtn {
      border-radius: 10px;
      color: white;
      padding: 4px 8px;
    }

    .cancelBtn {
      background-color: #8b9898;

      &:hover {
        background-color: darkseagreen;
      }
    }

    .deleteBtn {
      background-color: #8b9898;

      &:hover {
        background-color: red;
      }
    }
  }
`
export const Wrapper = styled(Box)`
  border-radius: 10px 10px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  max-height: calc(100vh - 200px);
  height: 100%;
  overflow-y: auto;

  label {
    margin-bottom: 4px !important;
    margin-left: 4px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    color: #73737B;
  }

  input {
    &:focus {
      border-color: #5458F7 !important;
      outline: none;
      box-shadow: none;
    }
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  .pageTitle {
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    width: 100%;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #353B60;
  }

  .iconGroup {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 8px;

    .title {
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      display: flex;
      align-items: center;
      color: #353B60;
    }

    .title-add {
      font-family: 'Source Sans Pro';
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
      color: #5458F7;
    }
  }
`

export const CourseInputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin-bottom: 24px;
`

export const AddDishWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(203, 203, 211, 0.4);
  padding: 12px 0 12px 0;

  .iconGroup {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 8px;
  }

  .dishInputsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;;

    .label-text {
      margin-bottom: 4px !important;
      margin-left: 12px !important;
      font-size: 14px !important;
      line-height: 18px !important;
      font-family: Source Sans Pro !important;
      color: #73737B !important;
    }

    .titleInputBlock {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .iconInput {
        width: 100%;
        align-items: center;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        column-gap: 16px;

        & > input {
          background-color: #FFFFFF;
          border: 1px solid #E8E8ED;
          border-radius: 8px;
          width: 100%;
          height: 40px;
          padding: 4px 8px;
        }
      }
    }

    .area {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;

      & > input {
        background-color: #FFFFFF;
        border: 1px solid #E8E8ED;
        border-radius: 8px;
        width: 95%;
        height: 100px;
        padding: 4px 8px;
      }
    }
  }

  .SwitchWrapper {
    padding: 12px 0;
    width: 100%;
    display: flex;
    justify-content: end;

    .wrapper {
      display: flex;
      width: 95%;
      justify-content: space-between;
      align-items: center;
    }
  }
`


export const ButtonsStyled = styled.div`
  padding: 16px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-content: center;
  justify-content: end;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #E8E8ED;
  background-color: #FFFFFF;
`

export const SubmitContainerStyled = styled.div`
  display: flex;
  align-content: center;
  justify-content: end;
`

export const CancelButtonStyled = styled(Button)`
  font-family: 'Source Sans Pro', normal, serif;
  color: #5458F7;
  line-height: 20px;
  font-style: normal;
  background-color: #FFFFFF;
  padding: 19px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  margin-right: 16px;
  border: 1px solid #CBCBD3;
`

export const SubmitButtonStyled = styled(Button)`
  font-family: 'Source Sans Pro', normal, serif;
  color: #FFFFFF;
  line-height: 20px;
  font-style: normal;
  background-color: #5458F7;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
`

export const EditScheduleContainer = styled.div`
  background-color: #FFFFFF;
  position: relative;
  border-radius: 10px 10px 10px 0;
  max-width: 452px;
  margin-left: auto;
  height: calc(100vh - 120px);
  transition: 0.4s;
  animation-name: opening;
  animation-duration: .3s;
  animation-iteration-count: 1;
  @keyframes opening {
    0% {
      width: 0
    }
    100% {
      width: 452px
    }
  }
`
export const Groups = styled.div`
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`

export const CreateMenuPopup = styled.div`
  width: 380px;
  height: max-content;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
  background-color: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;

  input {
    &:focus {
      border-color: #5458F7 !important;
      outline: none;
      box-shadow: none;
    }
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //row-gap: 20px;

    .input {
      //font-family: Source Sans Pro;
      //font-size: 16px;
      //border: 1px solid #E8E8ED;
      //border-radius: 8px;
      //resize: none;
      //height: 40px;
      //width: 100%;

      padding-left: 14px;
      padding-right: 14px;
      margin-top: 4px;
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #E8E8ED;
      box-sizing: border-box;
    }

    .title {
      font-family: Work Sans;
      font-size: 24px;
      font-weight: 600;
      color: #1e2027;
      width: 100%;
      margin-bottom: 16px;
    }

    .btnWrapper {
      width: 100%;
      margin-top: 24px;

      .btn {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: Work Sans;
        font-size: 18px;
        width: 80px;
        border-radius: 8px;
        color: white;
        background-color: #5f53ff;
      }
    }
  }


`