import React, { FC } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { equals, pathOr, path } from 'ramda'
import { shortnameToUnicode } from 'emoji-toolkit'

import { Room as RoomProps } from 'store/modules/messages/reducer'
import { Box, Avatar, Text, LoadingDots } from 'components/ui'
import { getDateDistance } from 'utils/messages'
import { getMyId } from 'store/modules/auth/selectors'
import UnreadMessages from './UnreadMessages'
import OnlineIndicator from '../OnlineIndicator'

const Time = styled(Text)`
  white-space: nowrap;

  &::before {
    content: '·';
    margin-right: 2px;
  }
`

type Props = {
  isActive: boolean
  onSelectRoom: VoidFunction
}

const Room: FC<RoomProps & Props> = ({
  name,
  last_ord = 0,
  room_type,
  image,
  lastMessage,
  isOnline,
  typingUser,
  isActive,
  onSelectRoom,
}) => {
  const unreadCount = lastMessage ? lastMessage.n_ord - last_ord : 0
  const myId = useSelector(getMyId)
  const isPrivate = equals(room_type, 2)

  const renderText = () => {
    if (typingUser) {
      return (
        <Box display="flex" alignItems="center">
          <Text color="blue">{typingUser.first_name} is typing</Text>

          <Box ml="4px">
            <LoadingDots bg="primary" />
          </Box>
        </Box>
      )
    }

    if (lastMessage) {
      return (
        <Box display="flex" alignItems="baseline">
          <Text color="#99a2ad">
            {equals(pathOr('', ['author', 'uid'], lastMessage), myId) ? (
              <Box mr="4px">You:</Box>
            ) : isPrivate ? (
              ''
            ) : (
              <Box mr="4px">{path(['author', 'first_name'], lastMessage)}:</Box>
            )}
          </Text>

          <Box mr="4px">
            <Text color="darkGray" isTruncate>
              {shortnameToUnicode(lastMessage.txt)}
            </Text>
          </Box>

          <Time fontSize="12px" color="#99a2ad">
            {getDateDistance(lastMessage.insert_dt)}
          </Time>
        </Box>
      )
    }

    return ''
  }

  return (
    <Box
      py="11px"
      px="12px"
      display="inline-flex"
      cursor="pointer"
      bg={isActive ? 'lightGray' : 'white'}
      borderRadius={1}
      position="relative"
      onClick={onSelectRoom}
    >
      {unreadCount > 0 && <UnreadMessages value={unreadCount} />}

      <Box position="relative">
        <Avatar image={image} name={name || 'X'} size="44px" fontSize={2} />

        {isOnline && <OnlineIndicator />}
      </Box>

      <Box ml="12px" width="220px">
        <Text fontWeight={600} fontSize={1}>
          {name}
        </Text>

        {renderText()}
      </Box>
    </Box>
  )
}

export default Room
