import React, { FC, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { any, propEq } from 'ramda'
import { toast } from 'react-toastify'

import { list } from 'store/modules/wedding/actions'
import {
  getIsGrid,
  getIsLoaded,
  getWeddings,
} from 'store/modules/wedding/selectors'
import { getMyId } from 'store/modules/auth/selectors'
import { loadFonts, setFontIsLoaded } from 'store/modules/fonts/actions'
import {
  getIsLoaded as getFontsIsLoaded,
  getFonts,
} from 'store/modules/fonts/selectors'
import { injectFont } from 'utils/fonts'
import { Box, Text, Loading } from 'components/ui'
import { Header } from 'components/WeddingList'
import {
  RemoveWeddingModal,
  ManageAdminsModal,
  Nav,
  NavBar,
  GridItem,
} from 'components/WeddingList'
import ListItem from './ListItem'

const WeddingList: FC = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const isGrid = useSelector(getIsGrid)
  const isLoaded = useSelector(getIsLoaded)
  const weddings = useSelector(getWeddings)
  const myUuid = useSelector(getMyId)
  const [modalIsOpen, setModalIsOpen] = useState<string>()
  const [selectedWuid, setSelectedWuid] = useState<string>()
  const fontsIsLoaded = useSelector(getFontsIsLoaded)
  const fonts = useSelector(getFonts())

  const handleOpenModal = useCallback((modalName: string, wuid: string) => {
    setModalIsOpen(modalName)
    setSelectedWuid(wuid)
  }, [])
  const handleCloseModal = useCallback(() => {
    setModalIsOpen(undefined)
    setSelectedWuid(undefined)
  }, [])

  const onLoad = useCallback(async () => dispatch(list()), [dispatch])
  const handleLoad = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoad()
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading, setError, onLoad])
  const onLoadFonts = useCallback(async () => dispatch(loadFonts()), [dispatch])
  const onSetFontIsLoaded = useCallback(
    (fontFamily: string) => dispatch(setFontIsLoaded(fontFamily)),
    [dispatch]
  )

  useEffect(() => {
    if (!error && !isLoaded && !isLoading) {
      handleLoad()
    }
  }, [error, isLoaded, isLoading, handleLoad])

  useEffect(() => {
    const handleLoadFonts = async () => {
      setIsLoading(true)

      try {
        await onLoadFonts()
      } catch (error) {
        toast(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    if (!fontsIsLoaded) {
      handleLoadFonts()
    }
  }, [fontsIsLoaded, onLoadFonts])

  useEffect(() => {
    if (fontsIsLoaded && isLoaded) {
      const weddingsFonts = weddings
        .map(({ theme_font }) => theme_font)
        .filter((v, i, a) => !!v && a.indexOf(v) === i)

      weddingsFonts.forEach((family) => {
        const font = fonts.find((v) => v.family === family && !v.isLoaded)

        if (font) {
          onSetFontIsLoaded(font.family)
          injectFont(font.family, font.files.regular)
        }
      })
    }
  }, [fontsIsLoaded, isLoaded, fonts, weddings, onSetFontIsLoaded])

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box position="relative">
          <Loading />
        </Box>
      )
    }

    if (error) return <Text color="red">{error}</Text>

    return isGrid ? (
      <Box display="flex" flexWrap="wrap" mt="25px" ml="-20px">
        {weddings.map((wedding) => (
          <Box key={wedding.wuid} width={[1, 1, 1 / 2, 1 / 2]}>
            <Box pl="20px" pb="20px">
              <GridItem {...wedding} onOpenModal={handleOpenModal} />
            </Box>
          </Box>
        ))}
      </Box>
    ) : (
      <Box display="flex" flexDirection="column" mt={2}>
        {weddings.map((wedding, i) => (
          <Box key={wedding.wuid} width="100%" mb={2}>
            <ListItem
              {...wedding}
              zIndex={weddings.length - i}
              onOpenModal={handleOpenModal}
              isAdmin={any(propEq('uuid', myUuid), wedding.admins)}
            />
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <>
      <RemoveWeddingModal
        wuid={selectedWuid}
        isOpen={modalIsOpen === 'remove'}
        onClose={handleCloseModal}
      />
      <ManageAdminsModal
        wuid={selectedWuid}
        isOpen={modalIsOpen === 'manageAdmins'}
        onClose={handleCloseModal}
      />

      <Header />

      <Box display="flex">
        <Box
          width="284px"
          height="100vh"
          position="fixed"
          pt="80px"
          boxShadow="0px 2px 3px #D9DEE3"
        >
          <Nav />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          ml="284px"
          pt="104px"
          width="100%"
          minHeight="100vh"
          bg="#F7F7FC"
          pl={[1, '66px']}
          pr={[1, '70px']}
        >
          <NavBar />

          {renderContent()}
        </Box>
      </Box>
    </>
  )
}

export default WeddingList
