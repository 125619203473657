import editIcon from '../../images/edit-icon.svg'
import deleteIcon from '../../images/delete-icon.svg'
import downBird from '../../images/birdDown.svg'
import upBird from '../../images/birdUp.svg'
import { deleteGroup, deleteMenu, editCourse } from './modals/actions'

export const buttonsGroupData = [
  {
    id: 1,
    name: 'editBtn',
    icon: editIcon,
    action: editCourse,
  },
  {
    id: 2,
    name: 'up',
    icon: upBird,
    action: null,
  },
  {
    id: 3,
    name: 'down',
    icon: downBird,
    action: null,
  },
  {
    id: 4,
    name: 'deleteMenuBtn',
    icon: deleteIcon,
    action: deleteGroup,
  },
]

export const initialDishObject = {
  title: '',
  comment: '',
  is_selected: '0',
}
