import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AddEvent } from './index'
import ButtonsGroup from './ButtonsGroup'
import ScheduleTable from './ScheduleTable/ScheduleByWeek'
import { Context } from './Context'
import { equals } from 'ramda'
import AddEventModal from './AddEventDrawer'
import EditEventModal from './EditEventDrawer'
import { format, parseISO } from 'date-fns'
import validate from 'validate.js'
import { presenceFieldConstraint } from 'constants/constraints'

// Store Tools
import { useDispatch, useSelector } from 'react-redux'
import {
  addAgendaItem,
  editAgendaItem,
  loadAgendaItem,
  loadAgendaItems,
  removeAgendaItem,
} from 'store/modules/agenda/actions'
import { listAdmin } from 'store/modules/guests/actions'
import { AgendaItem } from 'store/modules/agenda/reducer'
import { getIsLoaded, getItems } from 'store/modules/agenda/selectors'

// Components
import { Box } from 'components/ui'
import DeleteModal from 'components/Modal/DeleteModal'

// Utils
import { transformNewAgendaToRequest } from 'utils/agenda'
import { withGoogleMaps } from 'utils/gmaps'
import { toast } from 'react-toastify'

const groupButtonData: [string, string] = ['By Week', 'By Day']

type Props = {
  googleMaps?: any
  defaultValue?: string
  isNotSureAboutVenue: boolean
}

const Agenda: FC<Props> = ({
                             googleMaps,
                             defaultValue,
                           }) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const { wuid } = useParams<{ wuid: string }>()

  const isLoaded = useSelector(getIsLoaded(wuid))
  const agendaItems = useSelector(getItems(wuid))

  const calendarRef = useRef<any>(null)
  const isDoneRef = useRef<any>(false)

  const [, setIsLoading] = useState(false)
  const [, setError] = useState(null)
  const [selectedSchedules, setSelectedSchedules] = useState<number[]>([])
  const [selectedCalendarEvents, setSelectedCalendarEvents] = useState<number[]>([])
  const [items, setItems] = useState<AgendaItem[]>([])
  const [sortedItems, setSortedItems] = useState({})
  const [sortedByDate, setSortedByDate] = useState<string>('')
  const [activeTableType, setActiveTableType] = useState<string>('By Week')
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [isOpenAddEventModal, setIsOpenAddEventModal] = useState(false)
  const [isOpenEditEventModal, setIsOpenEditEventModal] = useState(false)
  const [isExistNextEvent, setIsExistNextEvent] = useState(true)
  const [isExistPrevEvent, setIsExistPrevEvent] = useState(false)
  const [isAllEventsSelected, setIsAllEventsSelected] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<object>({})
  const [selectedAgendaId, setSelectedAgendaId] = useState<any>(null)
  const [selectedAccessMode, setSelectedAccessMode] = useState('1')
  const [locationValue, setLocationValue] = useState({
    location_address: '',
    location_lat: '',
    location_lon: '',
    location_name: '',
    location_zip: '',
    location_city: '',
    location_state: '',
  })
  const [isChecked, setIsChecked] = useState(false)
  const [center, setCenter] = useState()
  const [defaultStartTime, setDefaultStartTime] = useState<string>()
  const [defaultEndTime, setDefaultEndTime] = useState<string>()
  const [defaultDate, setDefaultDate] = useState()
  const [selectedFilter, setSelectedFilter] = useState('All Events')
  const [selectedFilterIndex, setSelectedFilterIndex] = useState('3')
  const [filteredItems, setFilteredItems] = useState()
  const [isShowMenu, setIsShowMenu] = useState(false)

  const handleLoadGuests = useCallback(async () => dispatch(listAdmin()), [
    dispatch,
  ])

  useEffect(() => {
    handleLoadGuests()
  }, [handleLoadGuests])

  useEffect(() => {
    let filtered: any = {}
    const sorted = Object.keys(sortedItems).map((item: string) => {
      // @ts-ignore
      return sortedItems[item].filter((filteredItem) => filteredItem.access_mode === selectedFilterIndex || selectedFilterIndex === '3')
    })
    sorted.forEach((item) => {
      item.forEach((i: any) => {
        const currentItems = filtered[i.start_date]
        if (filtered[i.start_date]) {
          currentItems.push(i)
          filtered[i.start_date] = currentItems
        } else {
          filtered[i.start_date] = [i]
        }
      })
    })
    setFilteredItems(filtered)
  }, [selectedFilterIndex, sortedItems])

  useEffect(() => {
    if (activeTableType === 'By Day') {
      setSelectedSchedules([])
    } else {
      setSelectedCalendarEvents([])
    }
  }, [activeTableType])

  const onRemoveAgendaItem = useCallback(
    async (agendaItemIds: any) => dispatch(removeAgendaItem({ wuid, agenda_item_id: agendaItemIds })),
    [dispatch, wuid],
  )

  const onAdd = useCallback(async (data) => dispatch(addAgendaItem(data)), [
    dispatch,
  ])

  const onEdit = useCallback(async (data) => dispatch(editAgendaItem(data)), [
    dispatch,
  ])

  function getNearestHourTimeString(hours: number = 1) {
    const date = new Date()
    date.setHours(date.getHours() + hours)

    return date.getHours() + ':00'
  }

  const handleOpenAddEventModal = () => {
    const newDate = new Date()
    const startTime = getNearestHourTimeString()
    const endTime = getNearestHourTimeString(2)
    setIsOpenAddEventModal(true)
    setDefaultStartTime(startTime)
    setDefaultEndTime(endTime)
    // @ts-ignore
    setDefaultDate(newDate)
  }

  const handleSubmit = async (values: any) => {

    let data = {
      title: values.values.title,
      attire: values.values.attire,
      notes: values.values.notes,
      end_date: values.values.end_date ?? defaultDate,
      start_date: values.values.start_date ?? defaultDate,
      end_time: values.values.end_time ?? defaultEndTime,
      start_time: values.values.start_time ?? defaultStartTime,
      location_address: locationValue.location_address,
      location_name: locationValue.location_name,
      location_state: locationValue.location_state,
      location_city: locationValue.location_city,
      location_zip: locationValue.location_zip,
      location_lat: locationValue.location_lat !== '' ? locationValue.location_lat : '',
      location_lon: locationValue.location_lon !== '' ? locationValue.location_lon : '',
      access_mode: selectedAccessMode,
    }

    try {
      // @ts-ignore
      await onAdd(transformNewAgendaToRequest(wuid, data))
      setIsOpenAddEventModal(false)
      toast('The event has been added successfully')
    } catch (error) {
      setError(error.message)
    }
  }

  const handleClickDelete = async (agendaItemIds: any) => {
    setIsLoading(true)
    try {
      await onRemoveAgendaItem(agendaItemIds)
      setSelectedSchedules([])
      setDeleteModalIsOpen(false)
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
      setDeleteModalIsOpen(false)
    }
  }

  const sortItemsFunction = useCallback((itemsData: AgendaItem[] = agendaItems, isSort: boolean = true) => {
    isDoneRef.current = false
    if (!isDoneRef.current) {
      setSortedByDate('Start Date')
      isDoneRef.current = true
    }
    const filteredItems: any = {}
    // @ts-ignore
    // eslint-disable-next-line array-callback-return
    const itemsCopy = itemsData.sort((a: any, b: any) => {
      if (sortedByDate === 'Start Date' && isSort) {
        setSortedByDate('End Date')
        // @ts-ignore
        return new Date(b.start_date) - new Date(a.start_date)
      } else if (sortedByDate === 'End Date' && isSort) {
        setSortedByDate('Start Date')
        // @ts-ignore
        return new Date(a.start_date) - new Date(b.start_date)
      }
    })
    itemsCopy.forEach((item) => {
      const currentItems = filteredItems[item.start_date]
      if (filteredItems[item.start_date]) {
        currentItems.push(item)
        filteredItems[item.start_date] = currentItems
      } else {
        filteredItems[item.start_date] = [item]
      }
    })
    setSortedItems(filteredItems)
  }, [sortedByDate, agendaItems])

  const handleFilterEvents = (type: string, id: string) => {
    setSelectedFilter(type)
    setSelectedFilterIndex(id)
  }

  const changeSelectedSchedules = (schedule: AgendaItem) => {
    const selectedSchedulesCopy = [...selectedSchedules]
    const agendaItemId = Number(schedule.agenda_item_id)
    if (selectedSchedules.includes(agendaItemId)) {
      const scheduleIndex = selectedSchedulesCopy.findIndex((selectedScheduleId: number) => agendaItemId === selectedScheduleId)
      selectedSchedulesCopy.splice(scheduleIndex, 1)
    } else {
      selectedSchedulesCopy.push(agendaItemId)
    }
    setSelectedSchedules(selectedSchedulesCopy)
  }

  const changeSelectedCalendarEvents = (schedule: AgendaItem) => {
    const selectedCalendarEventsCopy = [...selectedCalendarEvents]
    const agendaItemId = Number(schedule.agenda_item_id)
    if (selectedCalendarEvents.includes(agendaItemId)) {
      const scheduleIndex = selectedCalendarEventsCopy.findIndex((selectedEventId: number) => agendaItemId === selectedEventId)
      selectedCalendarEventsCopy.splice(scheduleIndex, 1)
      setIsAllEventsSelected(false)
    } else {
      selectedCalendarEventsCopy.push(agendaItemId)
    }
    setSelectedCalendarEvents(selectedCalendarEventsCopy)
    const itemsCopy = [...items]
    if (selectedCalendarEventsCopy.length) {
      const filteredItems: any[] = []
      selectedCalendarEventsCopy.map((evn: any) => {
        const filteredItem = itemsCopy.find((itm) => Number(itm.agenda_item_id) === evn)
        if (!filteredItems.includes(filteredItem)) {
          filteredItems.push(filteredItem)
        }
        return evn
      })

      if (filteredItems.length) {
        filteredItems.map((filtered: any) => {
          const eventsSameDay = itemsCopy.filter((item: any) => item?.start_date === filtered?.start_date)

          if (eventsSameDay.length === selectedCalendarEventsCopy.length) {
            setIsAllEventsSelected(!isAllEventsSelected)
          } else {
            setIsAllEventsSelected(false)
          }
          return filtered
        })
      }
    }
  }

  const handleSelectAll = () => {
    if (activeTableType === 'By Week') {
      let selectedSchedulesCopy: number[] = []

      if (selectedSchedules.length !== agendaItems.length) {
        selectedSchedulesCopy = agendaItems.map((item: any) => {
          return Number(item.agenda_item_id)
        })
      }

      setSelectedSchedules(selectedSchedulesCopy)
    } else {
      if (calendarRef.current) {
        const api = calendarRef.current.getApi()
        const currentDate = api.getDate().toLocaleDateString('en-EN')

        const itemsCopy = [...items]

        let selectedCalendarEventsCopy: any[] = []

        if (selectedCalendarEvents.length !== agendaItems.length) {
          selectedCalendarEventsCopy = itemsCopy.map((item: any) => {
            if (format(new Date(item.start_date), 'MM/dd/yyyy').replace(/\b0/g, '') === currentDate) {
              return Number(item.agenda_item_id)
            }
            return item
          })
        }
        setSelectedCalendarEvents(selectedCalendarEventsCopy)
      }
    }
  }

  const handleSortByStartEndDate = (sortDateState: string) => {
    setSortedByDate(sortDateState)
    sortItemsFunction()
  }

  const handleClickNewItem = () =>
    history.push(`/wedding/${wuid}/agenda/create`)

  const onLoadAgendaItems = useCallback(
    async () => dispatch(loadAgendaItems()),
    [dispatch],
  )

  useEffect(() => {
    const isEqual = equals(agendaItems, items)
    if (!isEqual && !isDoneRef.current) {
      isDoneRef.current = true
      setItems(agendaItems)
      sortItemsFunction(agendaItems, false)
    } else {
      isDoneRef.current = false
    }
  }, [agendaItems, items, sortItemsFunction])

  const handleLoad = useCallback(async () => {
    setIsLoading(true)
    try {
      await onLoadAgendaItems()
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onLoadAgendaItems])

  useEffect(() => {
    if (!isLoaded) {
      handleLoad()
    }
  }, [isLoaded, handleLoad])

  const confirmDeleteSchedule = async () => {
    let agendaItemIds
    if (activeTableType === 'By Week') {
      agendaItemIds = [...selectedSchedules]
    } else {
      agendaItemIds = [...selectedCalendarEvents]
    }
    await handleClickDelete(agendaItemIds)
  }

  const handleClickMenuItem = (eventName: string, agendaId: string) => {
    if (eventName === 'Edit Event') {
      handleOpenEditModal(agendaId)
    } else if (eventName === 'Delete') {
      let agendaIds: number[] = []
      agendaIds.push(Number(agendaId))
      setSelectedSchedules(agendaIds)
      setSelectedCalendarEvents(agendaIds)
      setDeleteModalIsOpen(true)
    }
  }

  const handleChangeType = useCallback((type: string): void => {
    setActiveTableType(type)
  }, [])

  const handleCloseAddEventModal = () => {
    setIsOpenAddEventModal(false)
    // @ts-ignore
    setCenter()
  }

  const handleSubmitAddEventModal = () => {
    setIsOpenAddEventModal(false)
  }

  const handleCloseEditEventModal = () => {
    setIsOpenEditEventModal(false)
    setIsChecked(false)
  }

  const handleSubmitEditEventModal = async (values: any) => {

    if (typeof values.end_date === 'string') {
      values.end_date = parseISO(values.end_date)
    }
    if (typeof values.start_date === 'string') {
      values.start_date = parseISO(values.start_date)
    }

    let data = {
      agenda_item_id: values.agenda_item_id,
      title: values.title,
      attire: values.attire,
      notes: values.notes,
      end_date: values.end_date,
      start_date: values.start_date,
      end_time: values.end_time.slice(0, 5),
      start_time: values.start_time.slice(0, 5),
      location_address: locationValue.location_address,
      location_name: locationValue.location_name,
      location_state: locationValue.location_state,
      location_city: locationValue.location_city,
      location_zip: locationValue.location_zip,
      location_lat: locationValue.location_lat !== '' ? locationValue.location_lat : '',
      location_lon: locationValue.location_lon !== '' ? locationValue.location_lon : '',
      access_mode: selectedAccessMode,
    }

    await onEdit(transformNewAgendaToRequest(wuid, data))
    toast('The event has been updated successfully')
    setIsOpenEditEventModal(false)
  }

  const handleValidate = (values: any) =>
    validate(values, {
      ...presenceFieldConstraint('title'),
    })


  const handleOpenEditModal = (eventId: any) => {
    setIsOpenEditEventModal(true)
    const event: any = agendaItems.find((item) => item.agenda_item_id === eventId)
    setSelectedEvent(event)
    dispatch(loadAgendaItem(eventId))
    setSelectedAgendaId(eventId)
  }

  const handleChangeCalendarDay = (isNext: any) => {
    if (isNext === 'next') {
      setSelectedCalendarEvents([])
      if (calendarRef.current) {
        const api = calendarRef.current.getApi()
        const currentDate = api.getDate().toLocaleDateString('en-EN')
        const events = api.getEventSources()[0].internalEventSource.meta

        const ind = events.find((item: any) => item.start_date > currentDate)
        if (ind?.start_date === events[events.length - 1].start_date) {
          setIsExistPrevEvent(false)
          setIsExistNextEvent(true)
        } else {
          setIsExistPrevEvent(false)
          setIsExistNextEvent(false)
        }

        if (ind) {
          if (api) {
            api.gotoDate(ind.start)
          }
        }
      }
    } else {
      setSelectedCalendarEvents([])
      if (calendarRef.current) {
        const api = calendarRef.current.getApi()
        const currentDate = api.getDate().toLocaleDateString('en-EN')
        const events = api.getEventSources()[0].internalEventSource.meta

        const filteredInd = events.filter((item: any) => item.start_date < currentDate)
        const ind = filteredInd[filteredInd.length - 1]

        if (ind?.start_date === events[0].start_date) {
          setIsExistNextEvent(false)
          setIsExistPrevEvent(true)
        } else {
          setIsExistPrevEvent(false)
          setIsExistNextEvent(false)
        }
        if (ind) {
          if (api) {
            api.gotoDate(ind.start)
          }
        }
      }
    }
  }

  const handleSearch = (event: any) => {
    const query = event.target.value

    let filtered: any = {}

    let data = [...agendaItems]

    data = data?.filter((item) => {
      return item.title?.toLowerCase().includes(query.toLowerCase()) || item.location_name?.toLowerCase().includes(query.toLowerCase())
    })

    data?.forEach((i) => {
      const currentItems = filtered[i.start_date]
      if (filtered[i.start_date]) {
        currentItems.push(i)
        filtered[i.start_date] = currentItems
      } else {
        filtered[i.start_date] = [i]
      }
    })

    setSortedItems(filtered)
  }

  const Schedule = {
    wuid,
    items,
    isDoneRef,
    calendarRef,
    sortedItems,
    setSortedItems,
    sortedByDate,
    activeTableType,
    groupButtonData,
    handleChangeType,
    handleFilterEvents,
    selectedSchedules,
    setActiveTableType,
    setDeleteModalIsOpen,
    setSelectedSchedules,
    deleteModalIsOpen,
    isOpenAddEventModal,
    setIsOpenAddEventModal,
    isOpenEditEventModal,
    setIsOpenEditEventModal,
    isExistNextEvent,
    isExistPrevEvent,
    selectedCalendarEvents,
    isAllEventsSelected,
    setIsExistPrevEvent,
    setIsExistNextEvent,
    agendaItems,
    defaultStartTime,
    defaultEndTime,
    defaultDate,
    selectedAgendaId,
    selectedEvent,
    selectedAccessMode,
    setSelectedAccessMode,
    locationValue,
    setLocationValue,
    center,
    setCenter,
    isChecked,
    setIsChecked,
    isShowMenu,
    setIsShowMenu,
    selectedFilter,
    filteredItems,
    handleValidate,
    handleOpenAddEventModal,
    handleSubmit,
    handleSelectAll,
    handleClickDelete,
    handleClickNewItem,
    handleOpenEditModal,
    changeSelectedSchedules,
    changeSelectedCalendarEvents,
    handleChangeCalendarDay,
    handleCloseAddEventModal,
    handleSortByStartEndDate,
    handleSubmitAddEventModal,
    handleCloseEditEventModal,
    handleSubmitEditEventModal,
    handleClickMenuItem,
    handleSearch,
  }

  return (
    <Context.Provider value={Schedule}>
      <Box width='100%' backgroundColor='#FFFFFF' borderRadius='8px' padding='16px'>
        <AddEvent />
        <ButtonsGroup />
        <ScheduleTable />
      </Box>
      <DeleteModal
        message='Do you want to delete selected schedule ?'
        isOpen={deleteModalIsOpen}
        onClose={() => setDeleteModalIsOpen(false)}
        onConfirm={confirmDeleteSchedule}
      />
      <AddEventModal />
      <EditEventModal googleMaps={googleMaps} defaultValue={defaultValue} />
    </Context.Provider>
  )
}

export default withGoogleMaps(Agenda)
