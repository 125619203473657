import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { isEmpty } from 'ramda'

import { Wedding } from 'store/modules/wedding/reducer'
import { Box, Text } from 'components/ui'
import { ItemMenu } from 'components/WeddingList'
import { N_GUESTS_LABELS } from 'constants/wedding'
import { WEDDING_LAYOUT } from 'constants/paths'
import { getDate, getImage } from 'utils/wedding'
import Avatars from './Avatars'
import { ReactComponent as GuestsIcon } from './guests.svg'
import { ReactComponent as MapMarkerIcon } from './map-marker.svg'
import { ReactComponent as PhotosIcon } from './photos.svg'
import { ReactComponent as BellIcon } from './bell.svg'

const Container = styled(Box).attrs({
  border: '1px solid #CBCBD3',
  borderRadius: '8px',
  height: '400px',
  overflowY: 'hidden',
  cursor: 'pointer',
  bg: 'white',
  position: 'relative',
})``

type Props = {
  onOpenModal: (modalName: string, wuid: string) => void
}

const GridItem: FC<Wedding & Props> = ({
  wuid,
  wedding_name,
  wedding_image,
  wedding_date,
  approx_date_month,
  approx_date_year,
  n_guests,
  location_name,
  onOpenModal,
  is_active,
  spouse1_first_name,
  spouse1_last_name,
  spouse1_image,
  spouse2_first_name,
  spouse2_last_name,
  spouse2_image,
  theme_font,
  notifications,
}) => {
  const history = useHistory()

  const handleClickWrapper = useCallback(
    () => history.push(WEDDING_LAYOUT(wuid)),
    [history, wuid]
  )
  const handleOpenModal = useCallback(
    (modalName: string) => onOpenModal(modalName, wuid),
    [onOpenModal, wuid]
  )

  return (
    <Box position="relative">
      <Box
        position="absolute"
        left="12px"
        top="12px"
        display="flex"
        alignItems="center"
        zIndex={1}
      >
        {notifications.n_unread > 0 && (
          <Box
            bg="#F44343"
            borderRadius="6px"
            mr={0}
            display="flex"
            alignItems="center"
            width="64px"
            height="40px"
          >
            <Box width="36px" pl="8px">
              <BellIcon />
            </Box>

            <Box width="100%" display="flex" justifyContent="center" pr="3px">
              <Text fontWeight={600} fontSize="14px" color="white">
                {notifications.n_unread}
              </Text>
            </Box>
          </Box>
        )}

        {is_active !== '1' && (
          <Box px="16px" py="9px" bg="#F44343" borderRadius="6px">
            <Text
              color="white"
              fontWeight={600}
              fontSize="16px"
              lineHeight="22px"
            >
              Not Verified
            </Text>
          </Box>
        )}
      </Box>

      <Box position="absolute" zIndex={1} display="flex" right={1} top="11px">
        <ItemMenu onOpenModal={handleOpenModal} />
      </Box>

      <Container onClick={handleClickWrapper}>
        <Box
          width="100%"
          height="244px"
          backgroundImage={`url(${getImage(wedding_image)})`}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
        />

        <Avatars
          {...{
            spouse1_first_name,
            spouse1_last_name,
            spouse1_image,
            spouse2_first_name,
            spouse2_last_name,
            spouse2_image,
          }}
        />

        <Box display="flex" flexDirection="column" alignItems="center">
          <Box pt="24px" pb="8px">
            <Text
              fontFamily={theme_font}
              fontSize="36px"
              lineHeight="43px"
              fontWeight="bold"
              isTruncate
            >
              {wedding_name}
            </Text>
          </Box>

          <Text isTruncate fontSize="18px" lineHeight="23px" color="#414042">
            {getDate({ wedding_date, approx_date_month, approx_date_year }) ||
              'Date TBD'}
          </Text>

          <Box
            mt="10px"
            height="18px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <GuestsIcon />

            <Box mr="4px" />

            <Text isTruncate isCapitalize fontSize="16px" color="#73737B">
              {N_GUESTS_LABELS[n_guests]} Guests
            </Text>

            {!isEmpty(location_name) && (
              <>
                <Box ml="7px" mr="4px">
                  <Text fontSize="16px" color="#73737B">
                    ·
                  </Text>
                </Box>

                <MapMarkerIcon />

                <Box mr="4px" />

                <Text isTruncate isCapitalize fontSize="16px" color="#73737B">
                  {location_name}
                </Text>
              </>
            )}

            <Box ml="7px" mr="4px">
              <Text fontSize="16px" color="#73737B">
                ·
              </Text>
            </Box>

            <PhotosIcon />

            <Box mr="4px" />

            <Text isTruncate fontSize="16px" color="#73737B">
              25 Photos
            </Text>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default GridItem
