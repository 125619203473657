import { Reducer } from 'redux'
import { mergeLeft, pathOr, map, assoc, findIndex, propEq, adjust } from 'ramda'

import { LOAD, SET_FONT_IS_LOADED } from './actionTypes'

export type Font = {
  kind: string
  family: string
  category: string
  variants: string[]
  subsets: string[]
  files: {
    regular: string
  }
  isLoaded: boolean
}

export type State = {
  isLoaded: boolean
  fonts: Font[]
}

export const initialState = {
  isLoaded: false,
  fonts: [],
}

const fontsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case LOAD.FULFILLED:
      return mergeLeft(
        {
          isLoaded: true,
          fonts: map(
            (font) =>
              mergeLeft(
                {
                  isLoaded: false,
                  files: {
                    regular: font.files.regular.replace(
                      /^http:\/\//i,
                      'https://'
                    ),
                  },
                },
                font
              ),
            pathOr<Font[]>([], ['payload'], action)
          ),
        },
        state
      )
    case SET_FONT_IS_LOADED: {
      const index = findIndex(
        propEq('family', action.payload?.fontFamily),
        state.fonts
      )

      return assoc(
        'fonts',
        adjust(index, assoc('isLoaded', true), state.fonts),
        state
      )
    }
    default:
      return state
  }
}

export default fontsReducer
