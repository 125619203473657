import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getMyId } from 'store/modules/auth/selectors'
import { info } from 'store/modules/auth/actions'
import LoggedIn from './LoggedIn'
import NotLoggedIn from './NotLoggedIn'

const Routes = () => {
  const dispatch = useDispatch()
  const uuid = useSelector(getMyId)

  const onInfo = useCallback(async (uuid: string) => dispatch(info(uuid)), [
    dispatch,
  ])

  useEffect(() => {
    if (uuid) {
      onInfo(uuid)
    }
  }, [uuid, onInfo])

  return uuid ? <LoggedIn /> : <NotLoggedIn />
}

export default Routes
