import React, { useMemo, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Box, Avatar, Button, Text, Loading } from 'components/ui'
import { User } from 'store/modules/auth/reducer'
import { updateProfile } from 'store/modules/auth/actions'
import { getFullName, getImage } from 'utils/guest'
import useUploader from 'utils/useUploader'
import { readImage } from 'utils/images'

const PhotoUploader = ({ image, first_name, last_name }: Partial<User>) => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { onUpload } = useUploader()

  const name = useMemo(() => getFullName([first_name, last_name]), [
    first_name,
    last_name,
  ])
  const avatar = useMemo(() => getImage(image, 'big_icon'), [image])

  const onUpdatePhoto = useCallback(
    async (photoId: string) => dispatch(updateProfile({ photo_id: photoId })),
    [dispatch]
  )

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setLoading(true)

      try {
        const [file] = acceptedFiles

        const { photoId } = (await onUpload(await readImage(file))) || {}

        if (photoId) {
          await onUpdatePhoto(photoId)
        }
      } catch (error) {
        toast(error.message)
      } finally {
        setLoading(false)
      }
    },
    [onUpdatePhoto, onUpload]
  )

  const { getInputProps, open } = useDropzone({
    onDrop,
    noDrag: true,
    accept: 'image/*',
    multiple: false,
    disabled: isLoading,
  })

  return (
    <Box py="20px" display="flex" alignItems="center" position="relative">
      {isLoading && <Loading fill="#CBCBD3" m="0px" left="10px" />}

      <Avatar name={name} image={avatar} size={72} bg="#353B60" color="white" />

      <Button
        flexDirection="column"
        alignItems="start"
        ml="16px"
        onClick={open}
      >
        <input {...getInputProps()} />

        <Text
          fontWeight={600}
          fontSize="16px"
          lineHeight="18px"
          color="#4040FF"
        >
          Change Photo
        </Text>

        <Box mt="8px" />

        <Text fontSize="14px" lineHeight="17px" color="#73737B">
          JPEG or PNG image format
        </Text>
      </Button>
    </Box>
  )
}

export default PhotoUploader
