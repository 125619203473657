import axios from 'axios'
import { stringify } from 'query-string'
import { filter, propEq, both, hasPath } from 'ramda'

import { LOAD } from '../actionTypes'

export const loadFonts = () => ({
  type: LOAD.name,
  payload: {
    promise: async () => {
      try {
        const params = stringify({
          key: process.env.REACT_APP_GMAP_KEY,
          sort: 'popularity',
        })

        const res = await axios({
          url: `https://www.googleapis.com/webfonts/v1/webfonts?${params}`,
        })

        if (res.status !== 200) {
          throw new Error('Unexpected error')
        }

        return filter(
          both(
            propEq('category', 'handwriting'),
            hasPath(['files', 'regular'])
          ),
          res.data?.items || []
        )
      } catch (error) {
        throw new Error(error.message)
      }
    },
  },
})
