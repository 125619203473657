import React, { FC, useCallback } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { useDropzone } from 'react-dropzone'
import { head } from 'ramda'

import { Box, Text, Avatar } from 'components/ui'
import { readImage } from 'utils/images'
import { getImage } from 'utils/guest'

const PhotoUploadeComponent: FC<
  FieldRenderProps<{ slice: string; link: string }[]> & { userName: string }
> = ({ userName, input: { value, onChange } }) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = head(acceptedFiles)

      if (file) {
        onChange(await readImage(file))
      }
    },
    [onChange]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  })

  return (
    <Box
      display="flex"
      alignItems="center"
      cursor="pointer"
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <Avatar image={getImage(value)} name={userName} size={64} />

      <Box ml={1}>
        <Text fontFamily="1" fontSize="13px" color="darkGray">
          Upload Photo
        </Text>
      </Box>
    </Box>
  )
}

const PhotoUploader = (props: any) => (
  <Field {...props} name="photo" component={PhotoUploadeComponent} />
)

export default PhotoUploader
