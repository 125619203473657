import create from '../../../utils/createReduxPromiseActionType'

export const LOAD_MENU = create('foodMenu/LOAD_MENU')
export const ADD_MENU = create('foodMenu/ADD_MENU')
export const ADD_MENU_GROUP = create('foodMenu/ADD_MENU_GROUP')
export const LOAD_MENU_GROUPS = create('foodMenu/LOAD_MENU_GROUPS')
export const DELETE_MENU = create('foodMenu/DELETE_MENU')
export const ADD_MENU_ITEM = create('foodMenu/ADD_MENU_ITEM')
export const UPDATE_MENU_ITEM = create('foodMenu/UPDATE_MENU_ITEM')
export const REMOVE_MENU_ITEM = create('foodMenu/REMOVE_MENU_ITEM')
export const DELETE_MENU_GROUP = create('foodMenu/DELETE_MENU_GROUP')
export const UPDATE_MENU_GROUP = create('foodMenu/UPDATE_MENU_GROUP')
export const GROUP_ITEMS_ADD = 'GROUP_ITEMS_ADD'