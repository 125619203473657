import React, { useState, useCallback, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import Uploader from './Uploader'
import File from './File'
import { Box, Button, Text } from 'components/ui'

// Store tools
import { selectFiles } from 'store/modules/photos/selectors'
import { clearFiles } from 'store/modules/photos/actions'

// Icons
import { ReactComponent as UploadIcon } from './upload.svg'
import { ReactComponent as CloseIcon } from './close.svg'
import { ReactComponent as AddPhotoIcon } from '../../../images/add-photo.svg'


type Props = {
  isBtnBox: boolean
  selectedFilter?: string
  albumId?: string
}
const UploadMedia: FC<Props> = ({ isBtnBox, selectedFilter, albumId }) => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  const files = useSelector(selectFiles)

  const onClearFiles = useCallback(() => dispatch(clearFiles), [dispatch])
  const onToggle = useCallback(() => {
    onClearFiles()

    setIsOpen((isOpen) => !isOpen)
  }, [onClearFiles])

  return (
    <>
      <Box
        bg='rgba(115, 115, 123, 0.6)'
        borderRadius='8px'
        position='absolute'
        top='104px'
        right='16px'
        bottom='16px'
        left='16px'
        zIndex={3}
        display='flex'
        justifyContent='end'
        overflowX='hidden'
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          opacity: isOpen ? 1 : 0,
        }}
      >
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <Box
            width='452px'
            height='100%'
            bg='white'
            borderRadius='8px 8px 8px 0px'
            style={{
              transform: `translateX(${isOpen ? '0px' : '452px'})`,
              transition: '0.3s',
            }}
          >
            <Box
              overflowY='auto'
              width='100%'
              height='calc(100% - 73px)'
              p='16px'
              position='relative'
            >
              <Button
                width='20px'
                height='20px'
                p='0px'
                position='absolute'
                top='16px'
                right='16px'
                onClick={onToggle}
              >
                <CloseIcon />
              </Button>

              <Text
                fontWeight={700}
                fontSize='32px'
                lineHeight='38px'
                color='#353B60'
              >
                Upload Photos
              </Text>

              <Box mb='4px' />

              <Text fontSize='16px' lineHeight='24px' color='#73737B'>
                Add photos to keep all your wedding memories together.
              </Text>

              <Uploader selectedFilter={selectedFilter} albumId={albumId} />

              {files.map((file) => (
                <File
                  key={file.src_file}
                  name={file.src_file}
                  type={file.media_type}
                  size={file.total_length}
                  uploaded={file.upload_length}
                  isError={file.is_uploaded === -1}
                />
              ))}
            </Box>

            <Box
              width='100%'
              height='73px'
              borderTop='1px solid #E8E8ED'
              p='16px'
              display='flex'
              justifyContent='end'
            >
              <Button
                border='1px solid #CBCBD3'
                borderRadius='8px'
                width='86px'
                height='40px'
                onClick={onToggle}
              >
                <Text fontWeight={600} fontSize='16px' color='#5458F7'>
                  Cancel
                </Text>
              </Button>

              <Button
                borderRadius='8px'
                width='103px'
                height='40px'
                bg='#5458F7'
                ml='16px'
                onClick={onToggle}
              >
                <Text fontWeight={600} fontSize='16px' color='white'>
                  Continue
                </Text>
              </Button>
            </Box>
          </Box>
        </OutsideClickHandler>
      </Box>

      {isBtnBox ? (
        <Button
          style={{
            backgroundColor: '#F7F7FC',
            borderRadius: '6px',
            border: '1px solid #E8E8ED',
            width: '100%',
            height: '100%',
            padding: 0,
            aspectRatio: '1 / 1',
            position: 'relative',
          }}
          onClick={onToggle}
        >
          <AddPhotoIcon />
        </Button>
      ) : (
        <Button
          height='40px'
          padding='0px 16px'
          justifyContent='space-between'
          bg='#5458F7'
          borderRadius='8px'
          onClick={onToggle}
        >
          <UploadIcon />

          <Box marginLeft='8px'>
            <Text fontWeight={600} fontSize='16px' color='white'>
              Upload
            </Text>
          </Box>
        </Button>
      )}
    </>
  )
}

export default UploadMedia
