import { Middleware } from 'redux'
import { LOCATION_CHANGE } from 'connected-react-router'
import { mergeDeepRight } from 'ramda'

import { getWuid } from 'utils/router'
import { changeWuid } from 'store/modules/auth/actions'

const locationMiddleware: Middleware = (store) => (next) => (action: {
  type: string
  payload: any
}) => {
  if (action.type === LOCATION_CHANGE) {
    const { router } = store.getState()
    const wuid = getWuid(action.payload.location.pathname)
    const prevWuid = router.location.wuid

    if (!action.payload.isFirstRendering) {
      store.dispatch(changeWuid(wuid))
    }

    return next(
      mergeDeepRight(action, {
        payload: { location: { wuid: wuid || prevWuid } },
      })
    )
  }

  return next(action)
}

export default locationMiddleware
