import React from 'react'
import { Box, Button, Text } from 'components/ui'
import { Modal } from '../../utils'
import { defaultAction } from '../actions'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { remove as deleteMenu } from '../../../../store/modules/foodMenu/actions/menu/delete'
import { remove as deleteGroup } from '../../../../store/modules/foodMenu/actions/menuGroup/delete'

const DeleteMenuModal = ({
                           setModalIsOpen,
                           menugroup_id,
                           menu_id,
                           action,
                         }: { setModalIsOpen: any; menugroup_id: string; menu_id: string; action: string }) => {
  const { wuid } = useParams()
  const dispatch = useDispatch()

  const handleDeleteMenu = async () => {
    if (wuid && menu_id) {
      dispatch(deleteMenu({ wuid, menu_id }))
      setModalIsOpen(defaultAction)
    }
  }

  const handleDeleteGroup = async () => {
    if (wuid && menu_id) {
      dispatch(deleteGroup({ wuid, menugroup_id }))
      setModalIsOpen(defaultAction)
    }
  }

  const modalStyle = { content: { maxWidth: 360, top: '40vh' } }

  return (
    <Modal style={modalStyle} onRequestClose={() => setModalIsOpen({ type: null, name: null })}>
      <Box py={3} px={4} borderRadius={0} bg='white'>
        <Box mb='6px'>
          <Text fontFamily='1' fontSize={3} fontWeight={600}>
            Delete {action === 'menu' ? 'Menu' : 'Group'}
          </Text>
        </Box>

        <Text fontFamily='1'>
          Are you sure you want to delete this {action === 'menu' ? 'menu' : 'group'} ?
        </Text>

        <Box display='flex' justifyContent='flex-end' mt={1}>
          <Box mr={0}>
            <Button
              border='none'
              px={2}
              py={0}
              fontFamily='1'
              fontWeight={600}
              fontSize='18px'
              color='blue'
              onClick={() => setModalIsOpen({ type: null, name: null })}
            >
              Cancel
            </Button>
          </Box>

          <Button
            border='none'
            px={2}
            py={0}
            fontFamily='1'
            fontSize='18px'
            color='red'
            onClick={action === 'menu' ? handleDeleteMenu : action === 'group' ? handleDeleteGroup : () => 'default action'}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteMenuModal