import React, { FC, useCallback } from 'react'
import { useField } from 'react-final-form'
import { DayPickerInputProps, DayPickerProps } from 'react-day-picker'

import { Box, FormLabel, DayPickerInput } from '../ui'

type Props = {
  name: string
  label?: string
  disabledDays?: DayPickerProps['disabledDays']
  disabled?: boolean
  overlayPosition?: 'right' | 'bottom'
  hideValue?: boolean
  height?: string | number
  onChange?: (value?: Date) => void
}

const FormDayPicker: FC<DayPickerInputProps & Props> = ({
                                                          label,
                                                          name,
                                                          onChange,
                                                          ...rest
                                                        }) => {
  const { input } = useField<Date>(name)

  const handleDateChange = useCallback(
    (date: Date) => {
      if (onChange) {
        onChange(date)
      }

      input.onChange(date)
    },
    [input, onChange],
  )

  return (
    <Box>
      {label && <FormLabel>{label}</FormLabel>}

      <DayPickerInput {...input} {...rest} onDateChange={handleDateChange} />
    </Box>
  )
}

export default FormDayPicker
