import { move, findIndex, propEq, pathOr } from 'ramda'

import { Album } from 'store/modules/albums/reducer'

export const transformToRequest = (values: object) => ({
  album_title: pathOr('Album', ['name'], values),
})

export const moveFavoritesToHead = (albums: Album[] = []) =>
  move(findIndex(propEq('album_title', 'Favorites'), albums), 0, albums)
