import React, { FC, useEffect, useRef, useState } from 'react'
import Box from './Box'
import { Button, Text } from './index'
import { ReactComponent as DotsIcon } from 'images/dots-horizontal.svg'
import styled from 'styled-components'
import ICalendarLink from 'react-icalendar-link'

type Props = {
  menuItems: { id: string, name: string }[],
  handleClick: Function,
  event?: any
}

export const main = {
  position: 'absolute',
  display: 'inline-block',
  height: 0,
}

export const dropdownContent = {
  position: 'relative',
  zIndex: 1,
  background: '#FFFFFF',
  border: '1px solid #E8E8ED',
  borderRadius: '8px',
  width: '126px',
  right: '87px',
  top: '14px',
}

export const textStyle = {
  fontFamily: 'Source Sans Pro',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  color: '#414042',
}

export const deleteText = {
  fontFamily: 'Source Sans Pro',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  color: '#F44343',
}

export const MenuItem = styled(Box)`
  height: 40px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #F7F7FC;
  }

  .addToCalendar {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #414042;
    text-decoration: unset;
  }
`

export const DeleteItem = styled(Box)`
  height: 40px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #F7F7FC;
  }
`

const ThreeDotMenu: FC<Props> = ({ menuItems, handleClick, event }) => {
  const [isShowMenu, setIsShowMenu] = useState(false)

  const ref = useRef()

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (!ref?.current?.contains(event.target)) {
        setIsShowMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
  }, [ref])

  return (
    <Box style={main} ref={ref}>
      <Box>
        <Button
          style={{ height: 'auto', padding: 0 }}
          onClick={() => setIsShowMenu(!isShowMenu)}
        >
          <DotsIcon />
        </Button>
      </Box>
      {isShowMenu && (
        <Box style={dropdownContent}>
          {menuItems.map((item) => {
            return (
              <MenuItem
                key={item.id}
                onClick={() => handleClick(item.name)}
                style={{
                  borderBottom: item.id !== `${menuItems.length - 1}` ? '1px solid #E8E8ED' : '',
                  borderTopLeftRadius: item.id === '0' ? '8px' : '0',
                  borderTopRightRadius: item.id === '0' ? '8px' : '0',
                  borderBottomLeftRadius: item.id === `${menuItems.length - 1}` ? '8px' : '0',
                  borderBottomRightRadius: item.id === `${menuItems.length - 1}` ? '8px' : '0',
                }}
              >
                {item.id !== '1' ? (
                  <Text
                    style={item.name !== 'Delete' ? textStyle : deleteText}
                  >
                    {item.name}
                  </Text>
                ) : (
                  <Text
                    style={item.name !== 'Delete' ? textStyle : deleteText}
                  > <ICalendarLink className='addToCalendar' event={event}>{item.name}</ICalendarLink>
                  </Text>
                )}
              </MenuItem>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default ThreeDotMenu