import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { Box, Text } from 'components/ui'
import { isDark } from 'utils/colors'
import { getNavItems } from 'store/modules/nav/selectors'
import NavIcon from 'components/Wedding/NavIcon'
import MenuItem from './MenuItem'
import { ReactComponent as MenuIcon } from './menu.svg'

const Wrapper = styled(Box)`
  width: 218px;

  & > div:first-child {
    height: 255px;
  }

  @media screen and (min-height: 840px) {
    width: 270px;

    & > div:first-child {
      height: 328px;
    }
  }
`

enum Types {
  FEATURED = 'featured',
  MENU = 'menu',
  ADMIN = 'admin',
}

type Props = {
  bg?: string
}

const Menu = ({ bg = '#18CBC1' }: Props) => {
  const { wuid } = useParams<{ wuid: string }>()
  const navItems = useSelector(getNavItems(wuid))

  const color = useMemo(() => (isDark(bg) ? 'white' : 'black'), [bg])

  const items = useMemo(
    () => ({
      [Types.FEATURED]: navItems.filter(
        (item) =>
          !item.parent_nav_item_id &&
          item.is_active &&
          item.is_featured &&
          item.access_level !== 1
      ),
      [Types.MENU]: navItems.filter(
        (item) =>
          !item.parent_nav_item_id &&
          item.is_active &&
          !item.is_featured &&
          item.access_level !== 1
      ),
      [Types.ADMIN]: navItems.filter(
        (item) =>
          !item.parent_nav_item_id && item.is_active && item.access_level === 1
      ),
    }),
    [navItems]
  )

  const renderItems = useCallback(
    (type: Types) => {
      const data = items[type]

      if (!data?.length) {
        return null
      }

      return (
        <Box
          key={type}
          bg="#FFFFFF"
          borderRadius="5px"
          boxShadow="0px 2.60754px 4.56319px rgba(215, 215, 215, 0.25)"
          mb="20px"
        >
          {data.map((item) => (
            <MenuItem
              key={item.nav_item_id}
              caption={item.caption}
              icon={item.icon}
              bg={bg}
              color={color}
            />
          ))}
        </Box>
      )
    },
    [items, bg, color]
  )

  return (
    <Wrapper position="absolute" zIndex={1}>
      <Box width="100%" bg="#F6F8FB" px="13px" pt="32px" overflowY="auto">
        {Object.values(Types).slice(1).map(renderItems)}
      </Box>

      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        pt="8px"
        px="19px"
        borderTop="1px solid #E9E9E9"
      >
        {items[Types.FEATURED].map((item) => (
          <Box
            key={item.nav_item_id}
            display="flex"
            flexDirection="column"
            alignItems="Center"
            color="#97A5BA"
          >
            <NavIcon name={item.icon} width={18} height={18} />

            <Text
              fontSize="7px"
              fontWeight={600}
              color="#97A5BA"
              lineHeight="12px"
            >
              {item.caption}
            </Text>
          </Box>
        ))}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="Center"
          color={bg}
        >
          <MenuIcon />

          <Text fontSize="7px" fontWeight={600} color={bg} lineHeight="12px">
            Menu
          </Text>
        </Box>
      </Box>
    </Wrapper>
  )
}

export default Menu
