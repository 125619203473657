export const ROOT_PATH = '/'

export const LOGIN = `${ROOT_PATH}login`
export const SIGNUP = `${ROOT_PATH}signup`
export const RESET_PASSWORD = `${ROOT_PATH}reset-password`
export const VERIFY = `${ROOT_PATH}verify`
export const VERIFY_CONFIRM = `${VERIFY}/:verificationCode/:uuid`
export const OAUTH_SUCCESS = `${ROOT_PATH}oauth-success`

export const JOIN_TO_WEDDING = `${ROOT_PATH}join_admin/:inviteCode`
export const JOIN_TO_WEDDING_GUEST = `${ROOT_PATH}invite`
export const WEDDING = (wuid?: string) =>
  `${ROOT_PATH}wedding/${wuid || ':wuid'}`
export const WEDDING_LIST = `${ROOT_PATH}list`
export const WEDDING_CREATE = WEDDING('create')

export const AGENDA = (wuid?: string) => `${WEDDING(wuid)}/agenda`
export const AGENDA_CREATE = (wuid?: string) => `${AGENDA(wuid)}/create`
export const AGENDA_EDIT = (wuid?: string, agendaId?: string) =>
  `${AGENDA(wuid)}/edit/${agendaId || ':agendaId'}`

export const GUESTS = (wuid?: string) => `${WEDDING(wuid)}/guests`
export const GUESTS_CREATE = (wuid?: string) => `${GUESTS(wuid)}/create`
export const GUESTS_EDIT = (wuid?: string, guestId?: string) =>
  `${GUESTS(wuid)}/edit/${guestId || ':guestId'}`

export const ALBUMS = (wuid?: string) => `${WEDDING(wuid)}/albums`
export const ALBUM_PHOTOS = (wuid?: string, albumId?: string) =>
  `${ALBUMS(wuid)}/${albumId || ':albumId'}`

export const FAQ = (wuid?: string) => `${WEDDING(wuid)}/faq`

export const MESSAGES = (wuid?: string) => `${WEDDING(wuid)}/messages`

export const SEATING = (wuid?: string) => `${WEDDING(wuid)}/seating`

export const WEDDING_LAYOUT = (wuid?: string) => `${WEDDING(wuid)}/layout`

export const WEDDING_SETUP = (wuid?: string) => `${WEDDING(wuid)}/guests`

export const FOOD_MENU = (wuid?: string) => `${WEDDING(wuid)}/food-menu`

