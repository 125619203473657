import React, { useState, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import validate from 'validate.js'
import { toast } from 'react-toastify'

import { Box, Button, Text, Modal } from 'components/ui'
import { User } from 'store/modules/auth/reducer'
import { updateProfile } from 'store/modules/auth/actions'
import {
  emailConstraint,
  phoneNumberConstraint,
  presenceFieldConstraint,
} from 'constants/constraints'
import Pattern from './Pattern'
import PhotoUploader from './PhotoUploader'
import ProfileForm from './Form'
import { ReactComponent as EditIcon } from './edit.svg'

const ProfileDetails = ({
  image,
  first_name,
  last_name,
  contact_email,
  contact_phone,
}: Partial<User>) => {
  const [isOpen, openModal] = useState(false)
  const dispatch = useDispatch()

  const initialValues = useMemo(
    () => ({ image, first_name, last_name, contact_email, contact_phone }),
    [image, first_name, last_name, contact_email, contact_phone]
  )

  const handleValidate = useCallback(
    (values: any) =>
      validate(values, {
        ...presenceFieldConstraint('first_name'),
        ...presenceFieldConstraint('last_name'),
        ...(values.contact_email
          ? emailConstraint('contact_email', false)
          : undefined),
        ...phoneNumberConstraint('contact_phone', false),
      }),
    []
  )

  const onUpdate = useCallback(
    async (data: Partial<typeof initialValues>) =>
      dispatch(updateProfile(data)),
    [dispatch]
  )

  const onOpenModal = useCallback(() => openModal(true), [])
  const onCloseModal = useCallback(() => openModal(false), [])
  const onSubmit = useCallback(
    async (values: Partial<typeof initialValues>) => {
      try {
        await onUpdate(values)

        toast('Profile has been updated successfully')
        onCloseModal()
      } catch (error) {
        toast(error.message)
      }
    },
    [onUpdate, onCloseModal]
  )

  const modalStyle = { content: { maxWidth: 636, top: 115 } }

  return (
    <>
      <Modal isOpen={isOpen} style={modalStyle} onRequestClose={onCloseModal}>
        <Pattern />

        <Box
          px="24px"
          bg="white"
          pb="16px"
          borderBottomLeftRadius="6px"
          borderBottomRightRadius="6px"
        >
          <PhotoUploader {...{ image, first_name, last_name }} />

          <Form
            render={(props) => (
              <ProfileForm {...props} onCancel={onCloseModal} />
            )}
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={onSubmit}
          />
        </Box>
      </Modal>

      <Button
        border="1px solid #CBCBD3"
        borderRadius="6px"
        height="40px"
        pl="18px"
        pr="16px"
        onClick={onOpenModal}
      >
        <EditIcon />

        <Box mr="10px" />

        <Text fontWeight={600} fontSize="16px" color="secondary">
          Edit
        </Text>
      </Button>
    </>
  )
}

export default ProfileDetails
