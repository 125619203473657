import React, { FC } from 'react'
import { HeaderTag, HeaderText } from './utils'

// Icons
import { ReactComponent as CloseIcon } from 'images/close.svg'

type Props = {
  title: string,
  onClose: Function
}

const Header: FC<Props> = ({ title, onClose }) => {
  return (
    <HeaderTag>
      <HeaderText> {title} </HeaderText>
      <CloseIcon height='24px' width='24px' cursor='pointer' fill='#353B60' onClick={() => onClose()} />
    </HeaderTag>
  )
}

export default Header