import React, { FC, useState, KeyboardEvent } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { equals, pathOr } from 'ramda'

import { Box, Avatar, Text, Button } from 'components/ui'
import { Input } from 'components/form'
import { ROLE_LABELS } from 'constants/wedding'
import { ReactComponent as EditIcon } from 'images/edit-2.svg'

type Props = {
  socket: WebSocket
  roomId?: string
  image?: string
  name?: string
  role?: string
  isPrivate?: boolean
}

const HeadBlock: FC<Props> = ({
  socket,
  roomId,
  image,
  name,
  role,
  isPrivate,
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  const handleClickEditButton = () => setIsFormOpen(!isFormOpen)
  const handleSubmit = ({ title }: { title: string }) => {
    socket.send(
      JSON.stringify({
        c: 'u',
        title,
        room_id: roomId,
      })
    )

    setIsFormOpen(false)
  }

  const renderForm = ({
    dirty,
    handleSubmit,
  }: FormRenderProps<{ title: string }>) => {
    const handlePressKey = (e: KeyboardEvent<HTMLInputElement>) => {
      if (dirty && equals(e.key, 'Enter')) handleSubmit()
    }

    return (
      <Input
        autoFocus
        name="title"
        onBlur={handleSubmit}
        onKeyPress={handlePressKey}
      />
    )
  }

  return (
    <Box
      display="inline-flex"
      position="relative"
      alignItems="center"
      pb={1}
      borderBottom="1px solid"
      borderColor="gray"
      width="100%"
    >
      {!isPrivate && (
        <Button
          border="none"
          px="0px"
          position="absolute"
          right={1}
          top="12px"
          onClick={handleClickEditButton}
        >
          <EditIcon stroke="#858ea2" />
        </Button>
      )}

      <Box width="64px">
        <Avatar image={image} name={name || 'X'} size="64px" />
      </Box>

      <Box display="flex" flexDirection="column" ml={1}>
        {isFormOpen ? (
          <Form
            render={renderForm}
            initialValues={{ title: name || '' }}
            onSubmit={handleSubmit}
          />
        ) : (
          <Text fontSize="18px" fontWeight={600}>
            {name}
          </Text>
        )}

        {isPrivate && role && (
          <Text color="darkGray">{pathOr(role, [role], ROLE_LABELS)}</Text>
        )}
      </Box>
    </Box>
  )
}

export default HeadBlock
