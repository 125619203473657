import { pick } from 'ramda'

import { LIKE } from '../actionTypes'

export const like = (data: object) => ({
  type: LIKE.name,
  api: {
    url: 'posts/like',
    data: pick(['post_id', 'score'], data),
  },
})
