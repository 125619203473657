import React, {
  FC,
  useState,
  useCallback,
  KeyboardEvent,
  FormEvent,
} from 'react'
import ReactGooglePlacesSuggest from 'react-google-places-suggest'
import { join } from 'ramda'

import { ReactComponent as MapMarker } from 'images/map-pin.svg'
import { ReactComponent as PlusIcon } from 'images/plus-square.svg'
import { ReactComponent as SearchIcon } from 'images/search.svg'
import { parseSuggestion } from 'utils/gmaps'
import Box from '../Box'
import Text from '../Text'
import Input from '../Input'
import SuggestItem from './SuggestItem'
import ContainerWrapper from './ContainerWrapper'
import Modal from './Modal'

type Props = {
  googleMaps: any
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  hideValue?: boolean
  isShowIcon?: boolean
  onChange: (x: object) => any
}

const GeosuggestInput: FC<Props> = ({
                                      googleMaps,
                                      defaultValue,
                                      placeholder,
                                      disabled = false,
                                      hideValue = false,
                                      isShowIcon = true,
                                      onChange,
                                    }) => {
  const [search, setSearch] = useState('')
  const [value, setValue] = useState(defaultValue)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const handleOpenModal = () => setModalIsOpen(true)
  const handleCloseModal = () => setModalIsOpen(false)
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    // Prevent form submission after clicking Enter in input field
    if (e.which === 13) {
      e.preventDefault()
    }
  }, [])

  const handleInputChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
    setValue(e.currentTarget.value)
  }, [])

  const handleAddLocation = useCallback(
    (values) => {
      setSearch('')
      setValue(values.name)

      onChange({
        place: values.name,
        address: {
          address: join(', ', [
            values.name,
            values.state,
            values.country.value,
          ]),
          city: values.city,
          state: values.state,
          zip: values.postalCode,
        },
        location: null,
      })
    },
    [onChange],
  )

  const handleSelectSuggest = useCallback(
    (suggest, place) => {
      const address = parseSuggestion(suggest)

      const location = {
        lat: suggest.geometry.location.lat(),
        lng: suggest.geometry.location.lng(),
      }

      setSearch('')
      setValue(place.description)

      onChange({
        place: place.structured_formatting.main_text,
        address,
        location,
      })
    },
    [onChange],
  )

  const handleClickSuggest = useCallback(
    (suggest) => () => {
      const geocoder = new googleMaps.Geocoder()

      geocoder.geocode(
        { placeId: suggest.place_id },
        (results: any, status: any) => {
          if (status === googleMaps.GeocoderStatus.OK && results.length > 0) {
            handleSelectSuggest(results[0], suggest)
          }
        },
      )
    },
    [handleSelectSuggest, googleMaps],
  )

  const renderSuggest = (suggest: any) => (
    <SuggestItem
      key={suggest.place_id}
      py={0}
      onClick={handleClickSuggest(suggest)}
    >
      <MapMarker width={25} height={25} stroke='#73737B' />

      <Box display='flex' flexDirection='column' ml='23px'>
        <Text fontFamily='1' fontSize={1} lineHeight='20px' color='#414042'>
          {suggest.structured_formatting.main_text}
        </Text>

        <Text fontFamily='1' lineHeight='18px' color='#6d7589'>
          {suggest.structured_formatting.secondary_text}
        </Text>
      </Box>
    </SuggestItem>
  )

  const renderContainer = (items: any) => (
    <ContainerWrapper>
      <SuggestItem pb={1} pt={2} onClick={handleOpenModal}>
        <PlusIcon />

        <Box ml='23px'>
          <Text fontFamily='1' fontSize={1} fontWeight={600} color='secondary'>
            Add Venue
          </Text>
        </Box>
      </SuggestItem>

      {items.map(renderSuggest)}
    </ContainerWrapper>
  )

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        onChange={handleAddLocation}
      />

      <ReactGooglePlacesSuggest
        googleMaps={googleMaps}
        autocompletionRequest={{ input: search }}
        customContainerRender={renderContainer}
        displayPoweredByGoogle={false}
      >
        <Input
          value={hideValue ? '' : value || search}
          placeholder={placeholder}
          autoComplete='off'
          disabled={disabled}
          height='40px'
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
        />

        {isShowIcon && (
          <Box position='absolute' right='12px' top='8px' bg='white'>
            <SearchIcon />
          </Box>
        )}
      </ReactGooglePlacesSuggest>
    </>
  )
}

export default GeosuggestInput
