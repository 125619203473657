import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button } from 'components/ui'
import { expandCollapse, collapse } from 'store/modules/seatings/actions'
import { selectIsExpanded } from 'store/modules/seatings/selectors'
import { ReactComponent as MinusIcon } from './minus.svg'
import { ReactComponent as PlusIcon } from './plus.svg'
import { ReactComponent as TargetSelectIcon } from './target-select.svg'
import { ReactComponent as ExpandIcon } from './expand.svg'
import { ReactComponent as CollapseIcon } from './collapse.svg'

type Props = {
  onZoom: (zoom?: number) => void
  scrollCenter: VoidFunction
}

const Zoom = ({ onZoom, scrollCenter }: Props) => {
  const dispatch = useDispatch()
  const isExpanded = useSelector(selectIsExpanded)

  const onZoomOut = useCallback(() => onZoom(-0.1), [onZoom])

  const onZoomIn = useCallback(() => onZoom(0.1), [onZoom])

  const onResetZoom = useCallback(() => {
    onZoom()

    scrollCenter()
  }, [onZoom, scrollCenter])

  const onExpandCollapse = useCallback(() => dispatch(expandCollapse()), [
    dispatch,
  ])
  const onCollapse = useCallback<VoidFunction>(() => dispatch(collapse()), [
    dispatch,
  ])

  useEffect(() => onCollapse, [onCollapse])

  return (
    <Box
      position="fixed"
      bottom="36px"
      left="33px"
      height="40px"
      display="flex"
      zIndex={2}
    >
      <Box
        width="104px"
        height="100%"
        bg="white"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        border="1px solid #CBCBD3"
        borderRadius="8px"
        px="14px"
        py="8px"
        mr="12px"
      >
        <Button width="24px" height="24px" px="0px" onClick={onZoomOut}>
          <MinusIcon />
        </Button>

        <Box height="100%" borderRight="1px solid #E8E8ED" />

        <Button width="24px" height="24px" px="0px" onClick={onZoomIn}>
          <PlusIcon />
        </Button>
      </Box>

      <Button
        height="100%"
        width="40px"
        px="0px"
        bg="white"
        border="1px solid #CBCBD3"
        borderRadius="8px"
        mr="12px"
        onClick={onResetZoom}
      >
        <TargetSelectIcon />
      </Button>

      <Button
        height="100%"
        width="40px"
        px="0px"
        bg="white"
        border="1px solid #CBCBD3"
        borderRadius="8px"
        onClick={onExpandCollapse}
      >
        {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
      </Button>
    </Box>
  )
}

export default Zoom
