import React, { FC } from 'react'

import { Box, Text } from 'components/ui'

const EmailConfirmed: FC = () => (
  <>
    <Text fontSize={5} fontWeight={600}>
      Email Confirmed
    </Text>

    <Box
      mt={2}
      mb={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Text textAlign="center" fontSize="18px">
        You've successfully confirmed your email.
      </Text>
      <Text fontSize="18px">Please return to the app to continue.</Text>
    </Box>
  </>
)

export default EmailConfirmed
