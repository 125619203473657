import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { path, pathOr, map, prop, join } from 'ramda'

import { Box, Button } from 'components/ui'
import { ReactComponent as ExportIcon } from 'images/exportIcon.svg'
import { zipStart, zipResult } from 'store/modules/photos/actions'
import { Photo } from 'store/modules/albums/reducer'

const ExportPhotosButton: FC<{ selectedPhotos: Photo[] }> = ({
                                                               selectedPhotos = [],
                                                             }) => {
  const dispatch = useDispatch()
  const [ident, setIdent] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const onZipStart = useCallback(
    async () =>
      dispatch(
        zipStart({
          photo_ids: join(',', map(prop('photo_id'), selectedPhotos)),
        }),
      ),
    [dispatch, selectedPhotos],
  )
  const onZipResult = useCallback(async () => dispatch(zipResult({ ident })), [
    dispatch,
    ident,
  ])

  const handleZipStart = useCallback(async () => {
    if (selectedPhotos.length === 1) {
      return window.open(path([0, 'image_original'], selectedPhotos))
    }

    setIsLoading(true)

    try {
      setIdent(path(['value', 'payload', 'ident'], await onZipStart()))
    } catch (error) {
      toast(error.message)
      setIsLoading(false)
    }
  }, [onZipStart, selectedPhotos])

  useEffect(() => {
    const handleZipRes = async () => {
      try {
        const res = pathOr(
          { status: 'failed', url: '' },
          ['value', 'payload'],
          await onZipResult(),
        )

        if (res.status === 'failed' || res.status === 'expired') {
          throw new Error('Something goes wrong. Please try again')
        }

        if (res.status === 'completed') {
          setIdent(undefined)
          setIsLoading(false)

          window.open(res.url)
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1500))

          handleZipRes()
        }
      } catch (error) {
        toast(error.message)
      }
    }

    if (ident) {
      handleZipRes()
    }
  }, [ident, onZipResult])

  return (
    <Button
      border='none'
      fontFamily='1'
      fontWeight={600}
      color='darkGray'
      disabled={isLoading}
      onClick={handleZipStart}
    >
      <Box mr={0}>
        <ExportIcon />
      </Box>
      Export
    </Button>
  )
}

export default ExportPhotosButton
