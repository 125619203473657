import React, { useCallback, useState, MouseEvent } from 'react'

import { Box, Text, Button } from 'components/ui'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import { ReactComponent as CloseIcon } from './close.svg'
import { Types, TypeLabels } from 'routes/Seating'
import { ReactComponent as CircleIcon } from './circle.svg'
import { ReactComponent as HalfCircleIcon } from './half-circle.svg'
import { ReactComponent as RectangleIcon } from './rectangle.svg'
import { ReactComponent as SquareIcon } from './square.svg'
import { ReactComponent as DanceFloorIcon } from './dance-floor.svg'
import { ReactComponent as LongIcon } from './long.svg'
import { ReactComponent as RoundIcon } from './round.svg'
import { ReactComponent as StageIcon } from './stage.svg'

type Props = {
  activeType: Types
  onSetValue: (field: string, value: any) => void
  onClose: VoidFunction
  onCancel: VoidFunction
}

const TypeModal = ({ activeType, onSetValue, onClose, onCancel }: Props) => {
  const [selectedType, setSelectedType] = useState<Types>(activeType)

  const renderIcon = useCallback((type: Types) => {
    switch (type) {
      case Types.CIRCLE:
        return <CircleIcon width="48px" height="48px" />
      case Types.HALF_CIRCLE:
        return <HalfCircleIcon width="48px" height="48px" />
      case Types.RECTANGLE:
        return <RectangleIcon width="48px" height="48px" />
      case Types.LONG:
        return <LongIcon />
      case Types.ROUND:
        return <RoundIcon />
      case Types.DANCE_FLOOR:
        return <DanceFloorIcon />
      case Types.STAGE:
        return <StageIcon />
      default:
        return <SquareIcon width="48px" height="48px" />
    }
  }, [])

  const onClickClose = useCallback(() => {
    onClose()
    onCancel()
  }, [onClose, onCancel])

  const onSelect = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const type = (e.currentTarget.value as unknown) as Types

    if (
      [
        Types.CIRCLE,
        Types.HALF_CIRCLE,
        Types.HALF_CIRCLE,
        Types.RECTANGLE,
        Types.SQUARE,
      ].includes(type)
    ) {
      setSelectedType(type)
    }
  }, [])

  const onSave = useCallback(() => {
    onSetValue('type', selectedType)

    onCancel()
  }, [onSetValue, selectedType, onCancel])

  return (
    <Box
      width="100%"
      bg="#F7F7FC"
      pb="24px"
      borderRadius="10px"
      boxShadow="0px 10px 45px rgba(0, 0, 0, 0.12)"
    >
      <Box
        width="100%"
        height="60px"
        bg="white"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="24px"
        boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
        borderRadius="10px"
      >
        <Button p="0px" width="24px" height="24px" onClick={onCancel}>
          <ArrowIcon />
        </Button>

        <Text fontWeight="bold" fontSize="20px" color="#353B60">
          Table Types
        </Text>

        <Button px="0px" onClick={onClickClose}>
          <CloseIcon />
        </Button>
      </Box>

      <Box px="16px" pt="20px" display="flex" flexWrap="wrap">
        {Object.values(Types).map((type) => (
          <Box
            key={type}
            width={1 / 4}
            px="8px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            pb="16px"
          >
            <Button
              as="button"
              width="100%"
              height="118px"
              flexDirection="column"
              justifyContent="space-between"
              border="2px solid"
              borderColor={type === selectedType ? '#5458F7' : '#E8E8ED'}
              borderRadius="8px"
              py="16px"
              value={type}
              onClick={onSelect}
            >
              {renderIcon(type)}

              <Text
                fontWeight={600}
                fontSize="16px"
                lineHeight="20px"
                color={type === selectedType ? '#5458F7' : '#414042'}
              >
                {TypeLabels[type]}
              </Text>
            </Button>
          </Box>
        ))}
      </Box>

      <Box display="flex" justifyContent="flex-end" px="24px" pt="52px">
        <Button
          bg="#5458F7"
          borderRadius="10px"
          height="40px"
          width="80px"
          fontWeight={600}
          fontSize="16px"
          color="white"
          fontFamily="0"
          onClick={onSave}
        >
          Select
        </Button>
      </Box>
    </Box>
  )
}

export default TypeModal
