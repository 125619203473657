import React, { FC } from 'react'
import { Modal, Box, Text, Button } from 'components/ui'

export type Props = {
  message: string,
  isOpen: boolean,
  onClose: () => void,
  onConfirm: () => void
}

const DeleteModal: FC<Props> = (props) => {
  const { isOpen, onClose, message, onConfirm } = props
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Box py={3} px={4} borderRadius={0} bg="white">
        <Text fontFamily="0" fontWeight="500" fontSize={2} color="">
          {message}
        </Text>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button onClick={onClose} variant="secondary" height="40px">cancel</Button>
          <Button onClick={onConfirm} variant="secondary" height="40px" ml={3} backgroundColor="#8991a0" borderColor="#8991a0">Delete</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteModal
