import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Box, Text, Button } from 'components/ui'
import Modal from 'components/ui/FontSelector/Modal'
import { loadFonts, setFontIsLoaded } from 'store/modules/fonts/actions'
import { update } from 'store/modules/wedding/actions'
import { getIsLoaded, getFonts } from 'store/modules/fonts/selectors'
import { injectFont } from 'utils/fonts'
import { ReactComponent as PlusIcon } from './plus.svg'

const Wrapper = styled(Box)`
  padding: 18px 22px;

  @media screen and (min-height: 840px) {
    padding: 22px 24px;
  }
`

const Font = styled(Box)`
  height: 80px;
  margin-top: 16px;

  @media screen and (min-height: 840px) {
    margin-top: 20px;
  }
`

const usePrevious = (value: any): any => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

type Props = {
  title: string
  font?: string
}

const FontSelector = ({ title, font }: Props) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const isLoaded = useSelector(getIsLoaded)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const loadedPage = usePrevious(page)
  const fonts = useSelector(getFonts(page * 8))

  const { text, fontSize } = useMemo(() => {
    const text = title.split('&', 2).map((v) => v.trim())

    if (text[1]) {
      text[1] = `& ${text[1]}`
    }

    const line = text[1] && text[1].length > text[0].length ? text[1] : text[0]
    const fontSize =
      line.length > 21
        ? '15px'
        : line.length > 18
        ? '17px'
        : line.length > 15
        ? '18px'
        : line.length > 13
        ? '24px'
        : '27px'

    return { text, fontSize }
  }, [title])

  const options = useMemo(
    () =>
      fonts.map((font) => ({ label: font.family, value: font.files?.regular })),
    [fonts]
  )

  const onOpen = useCallback(() => setIsOpen(true), [])
  const onClose = useCallback(() => setIsOpen(false), [])
  const onChange = useCallback(
    ({ label }) => {
      dispatch(update({ theme_font: label }))

      toast('The font has been updated successfully')
    },
    [dispatch]
  )

  const onLoadFonts = useCallback(async () => dispatch(loadFonts()), [dispatch])
  const onSetFontIsLoaded = useCallback(
    (fontFamily: string) => dispatch(setFontIsLoaded(fontFamily)),
    [dispatch]
  )

  const handleLoadMore = useCallback(() => setPage(page + 1), [page])
  const handleInjectFonts = useCallback(
    () =>
      fonts.forEach((font) => {
        if (!font.isLoaded) {
          onSetFontIsLoaded(font.family)
          injectFont(font.family, font.files.regular)
        }
      }),
    [fonts, onSetFontIsLoaded]
  )

  // Load fonts initially
  useEffect(() => {
    const handleLoadFonts = async () => {
      setIsLoading(true)

      try {
        await onLoadFonts()
      } catch (error) {
        toast(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    if (!isLoaded && !isLoading) {
      handleLoadFonts()
    }
  }, [isLoaded, isLoading, onLoadFonts])

  // Add scripts
  useEffect(() => {
    if ((page === 1 && fonts.length > 0) || loadedPage < page) {
      handleInjectFonts()
    }
  }, [fonts, page, loadedPage, handleInjectFonts])

  return (
    <>
      <Modal
        isSecondary
        isOpen={isOpen}
        options={options}
        weddingName={title}
        onClose={onClose}
        onChange={onChange}
        onScrollToBottom={handleLoadMore}
      />

      <Wrapper
        width="100%"
        bg="#FFFFFF"
        boxShadow="0px 12px 38px rgba(53, 59, 96, 0.19)"
        borderRadius="6px"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Text
            color="#353B60"
            fontWeight="bold"
            fontSize="20px"
            lineHeight="26px"
          >
            Selected Font
          </Text>

          <Button p="0px" width="20px" height="20px" onClick={onOpen}>
            <PlusIcon />
          </Button>
        </Box>

        <Font
          width="100%"
          border="1px solid #E8E8ED"
          boxShadow="0px 4px 13px rgba(0, 0, 0, 0.05)"
          borderRadius="6px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {text.map((value, key) => (
            <Text
              key={key}
              fontFamily={font}
              color="#000000"
              isTruncate
              maxWidth="210px"
              fontSize={fontSize}
            >
              {value}
            </Text>
          ))}
        </Font>
      </Wrapper>
    </>
  )
}

export default FontSelector
