import React, { FC, useCallback } from 'react'
import { Form } from 'react-final-form'
import validate from 'validate.js'

import { presenceFieldConstraint, zipConstraint } from 'constants/constraints'
import { ReactComponent as CloseIcon } from 'images/close.svg'
import Modal from '../Modal'
import Box from '../Box'
import Text from '../Text'
import Button from '../Button'
import LocationForm from './LocationForm'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  onChange: (v: any) => void
}

const AddLocationModal: FC<Props> = ({ isOpen, onClose, onChange }) => {
  const handleClose = () => onClose()
  const handleSubmit = useCallback(
    (values) => {
      onChange(values)

      onClose()
    },
    [onClose, onChange]
  )
  const handleValidate = (values: object) =>
    validate(values, {
      ...presenceFieldConstraint('street'),
      ...presenceFieldConstraint('city'),
      ...zipConstraint('postalCode'),
      ...presenceFieldConstraint('state'),
      ...presenceFieldConstraint('country'),
    })

  const modalStyle = {
    content: { maxWidth: 488, top: 205, overflow: 'none' },
    overlay: { background: 'rgba(115, 115, 123, 0.6' },
  }

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={handleClose}>
      <Box
        bg="#F6F7F9"
        borderRadius={0}
        boxShadow="0px 10px 45px rgba(0, 0, 0, 0.12)"
      >
        <Box
          px="24px"
          py="18px"
          bg="white"
          borderTopLeftRadius={0}
          borderTopRightRadius={0}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontFamily="1" color="#353B60" fontSize="20px" fontWeight={600}>
            Add Venue
          </Text>

          <Button height="24px" width="24px" p="0px" onClick={handleClose}>
            <CloseIcon fill="#99A2AD" />
          </Button>
        </Box>

        <Form
          render={(props) => <LocationForm {...props} onClose={handleClose} />}
          validate={handleValidate}
          onSubmit={handleSubmit}
        />
      </Box>
    </Modal>
  )
}

export default AddLocationModal
