import { Reducer } from 'redux'
import {
  path,
  pathOr,
  filter,
  map,
  assoc,
  append,
  uniqBy,
  prop,
  concat,
} from 'ramda'

import {
  CLEAR_AGENDA_ITEMS,
  LOAD_AGENDA_ITEMS,
  LOAD_AGENDA_ITEM,
  REMOVE_AGENDA_ITEM,
  EDIT_AGENDA_ITEM,
  ADD_AGENDA_ITEM,
  ADD_AGENDA_ITEM_GUESTS,
} from './actionTypes'
import { LOGOUT } from 'store/modules/auth/actionTypes'

export const initialState = { loadedWuids: [], agendaItems: [] }

export type AgendaItem = {
  agenda_item_id: string
  wuid: string
  start_date: string
  start_time: string
  end_time: string
  end_date: string
  title: string
  location_name?: string
  location_address?: string
  location_city?: string
  location_state?: string
  location_zip?: string
  location_lat?: string
  location_lon?: string
  location_country?: string
  location_street?: string
  phone_number?: string
  attire?: string
  url?: string
  notes?: string
  email?: string
  access_mode?: string
  guest_whitelist?: [] | undefined
}
export type State = {
  loadedWuids: string[]
  agendaItems: AgendaItem[]
}

const agendaReducer: Reducer = (state: State = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState
    case CLEAR_AGENDA_ITEMS:
      return initialState
    case LOAD_AGENDA_ITEMS.FULFILLED:
      return {
        ...state,
        loadedWuids: append(
          path(['data', 'wuid'], action.payload),
          state.loadedWuids,
        ),
        agendaItems: uniqBy(
          prop('agenda_item_id'),
          concat(
            state.agendaItems,
            map(
              assoc('wuid', path(['data', 'wuid'], action.payload)),
              pathOr(
                { agenda_item_id: null },
                ['payload', 'agenda_items'],
                action.payload,
              ),
            ),
          ),
        ),
      }
    case LOAD_AGENDA_ITEM.FULFILLED:
      return {
        ...state,
        agendaItems: uniqBy(prop('agenda_item_id'), [
          ...state.agendaItems,
          assoc(
            'wuid',
            path(['data', 'wuid'], action.payload),
            pathOr(
              { agenda_item_id: null },
              ['payload', 'agenda_item'],
              action.payload,
            ),
          ),
        ]),
      }
    case REMOVE_AGENDA_ITEM.FULFILLED:
      return {
        ...state,
        agendaItems: filter(
          (v) =>
            !action.payload.data.agenda_item_id.includes(Number(v.agenda_item_id)),
          state.agendaItems,
        ),
      }
    case EDIT_AGENDA_ITEM.FULFILLED:
      return {
        ...state,
        agendaItems: map(
          (v) =>
            v.agenda_item_id ===
            path(['data', 'agenda_item_id'], action.payload)
              ? {
                ...action.payload.data,
                start_time: `${action.payload.data.start_time}:00`,
                end_time: `${action.payload.data.end_time}:00`,
              }
              : v,
          state.agendaItems,
        ),
      }
    case ADD_AGENDA_ITEM.FULFILLED:
      return {
        ...state,
        agendaItems: uniqBy(prop('agenda_item_id'), [
          ...state.agendaItems,
          assoc(
            'wuid',
            path(['data', 'wuid'], action.payload),
            pathOr(
              { agenda_item_id: null },
              ['payload', 'agenda_item'],
              action.payload,
            ),
          ),
        ]),
      }
    case ADD_AGENDA_ITEM_GUESTS.FULFILLED:
      return {
        ...state,
        agendaItems: uniqBy(prop('agenda_item_id'), [
          ...state.agendaItems,
          assoc(
            'wuid',
            path(['data', 'wuid'], action.payload),
            pathOr(
              { agenda_item_id: null },
              ['payload', 'agenda_item'],
              action.payload,
            ),
          ),
        ]),
      }
    default:
      return state
  }
}

export default agendaReducer
