import React from 'react'

import { NavItem } from 'store/modules/nav/reducer'
import { Box, Text } from 'components/ui'
import NavIcon from '../NavIcon'
import { ReactComponent as DragIcon } from './drag.svg'
import { ReactComponent as LockIcon } from './lock.svg'

type Props = {
  icon?: NavItem['icon']
  caption?: NavItem['caption']
  isFixed?: boolean
  isActive?: boolean
  onClick?: VoidFunction
}

const Item = ({ icon, caption, isActive, isFixed, onClick }: Props) => (
  <Box
    pl="16px"
    height="40px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    cursor="pointer"
    bg={isActive ? 'white' : undefined}
    onClick={onClick}
  >
    <Box display="flex" alignItems="center" width="100%">
      <Box mr="20px">
        <NavIcon name={icon} />
      </Box>

      <Text
        fontWeight={600}
        fontSize="16px"
        color="#353B60"
        isSelectable={false}
      >
        {caption}
      </Text>
    </Box>

    {isFixed === undefined ? undefined : isFixed ? (
      <Box pr="6px">
        <LockIcon />
      </Box>
    ) : (
      <Box>
        <DragIcon />
      </Box>
    )}
  </Box>
)

export default Item
