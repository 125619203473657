import React, { FC, useCallback, useEffect, useState } from 'react'
import ModalHeader from './ModalHeader'
import { Box, Button, Modal, Text } from 'components/ui'

// Store tools
import { Guest } from 'store/modules/guests/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { getAllGuests } from 'store/modules/guests/selectors'
import { addAgendaItemsGuests } from 'store/modules/agenda/actions'

// Utils
import { ModalContainer, ModalContent } from './utils'

// Icons
import { ReactComponent as CheckboxChecked } from 'images/checkbox-checked.svg'
import { ReactComponent as CheckboxUnchecked } from 'images/checkbox-unchecked-modal.svg'
import { ReactComponent as Icon } from 'images/magnify.svg'

type Props = {
  isOpen: boolean,
  setIsOpen: Function,
  wuid: string,
  agenda_item_id: string,
  setIsOpenAddGuestModal: Function
  setGuestCount: Function
  whitelist?: [] | undefined
}

const AddGuestsModal: FC<Props> = ({
                                     isOpen,
                                     setIsOpenAddGuestModal,
                                     whitelist,
                                     wuid,
                                     agenda_item_id,
                                     setGuestCount,
                                   }) => {
  const dispatch = useDispatch()

  const guests = useSelector(getAllGuests)

  const addAgendaGuest = useCallback(async (data) => dispatch(addAgendaItemsGuests(data)), [
    dispatch,
  ])

  const [sortedGuests, setSortedGuests] = useState<any>({})
  const [selectedGuests, setSelectedGuests] = useState<number[]>([])
  const [selectedSortedGuests, setSelectedSortedGuests] = useState<any>({})
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    let selectedGuestsIds: any = []
    let initialSelectedSortedGuests = {}

    selectedGuestsIds = whitelist?.map((guest: any) => guest.guest_id)

    Object.keys(sortedGuests).forEach((key) => {
      initialSelectedSortedGuests = {
        ...initialSelectedSortedGuests,
        [key]: sortedGuests[key].filter((guest: Guest) => selectedGuestsIds?.includes(guest.guest_id) && ((guest.list_id !== '0' && key === guest.list?.name) || key === 'Unassigned')).map((guest: Guest) => guest.guest_id),
      }
    })

    setSelectedSortedGuests(initialSelectedSortedGuests)

  }, [sortedGuests, whitelist])

  useEffect(() => {
    if (Object.keys(selectedSortedGuests).length > 0) {
      let ids: any = []
      Object.keys(selectedSortedGuests).forEach((groupName) => {
        ids = [...ids, ...selectedSortedGuests[groupName]]
      })
      setSelectedGuests(ids)
    }
  }, [selectedSortedGuests])

  const sortFunction = useCallback((searchName = '') => {
    let sorted: any = {}
    const sortedGuests = guests.sort((a, b) => a.first_name.normalize().localeCompare(b.first_name.normalize()))
    const unassignedGuests = sortedGuests.filter((guest) => guest.list_id === '0')
    sortedGuests.map((guest) => {
      if (guest.list_id === '0') {
        const filtered = Object.keys(sorted).filter((item) => item === 'Unassigned')
        sorted['Unassigned'] = filtered[0]?.toLowerCase().includes(searchName.toLowerCase()) ? unassignedGuests : unassignedGuests.filter((guest: any) => (guest?.first_name?.toLowerCase().includes(searchName.toLowerCase()) || guest?.last_name?.toLowerCase().includes(searchName.toLowerCase())))
      } else {
        const currentItems = sorted[guest.list.name]
        if (sorted[guest?.list.name]) {
          currentItems.push(guest)
          sorted[guest.list.name] = guest?.list.name.toLowerCase().includes(searchName.toLowerCase()) ? currentItems : currentItems.filter((guest: any) => (guest?.first_name?.toLowerCase().includes(searchName.toLowerCase()) || guest?.last_name?.toLowerCase().includes(searchName.toLowerCase())))
        } else {
          sorted[guest.list.name] = [guest]
        }
      }
      return sorted
    })
    setSortedGuests(sorted)
  }, [guests])

  useEffect(() => {
    sortFunction()
  }, [sortFunction])

  const onClose = () => {
    setIsOpenAddGuestModal(false)
  }

  const handleSubmit = async () => {
    let data: any = {}
    data.wuid = wuid
    data.agenda_item_id = agenda_item_id
    data.guest_ids = selectedGuests.join(',')
    data.is_force_whitelist = 1

    try {
      await addAgendaGuest(data)
      setIsOpenAddGuestModal(false)
      setGuestCount(selectedGuests.length)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSelectGuest = (guestId: number, groupName: string) => {
    const selectedSortedGuestsCopy = { ...selectedSortedGuests }

    if (selectedSortedGuestsCopy[groupName]?.includes(guestId)) {
      const guestIndex = selectedSortedGuestsCopy[groupName].findIndex((selectedGuestId: number) => guestId === selectedGuestId)
      selectedSortedGuestsCopy[groupName].splice(guestIndex, 1)
    } else {
      const groupGuestIds = selectedSortedGuestsCopy[groupName]?.length > 0 ? selectedSortedGuestsCopy[groupName] : []
      groupGuestIds.push(guestId)
      selectedSortedGuestsCopy[groupName] = groupGuestIds
    }
    setSelectedSortedGuests(selectedSortedGuestsCopy)
  }

  const handleSelectAllGuests = (groupName: any) => {
    const sortedGuestsGroup = [...sortedGuests[groupName]]
    const selectedSortedGuestsGroup = selectedSortedGuests[groupName] ? [...selectedSortedGuests[groupName]] : []
    let selectedGuestsIds: any = {
      [groupName]: [],
    }

    if (sortedGuestsGroup.length !== selectedSortedGuestsGroup.length) {
      selectedGuestsIds[groupName] = sortedGuestsGroup.map((guest) => guest.guest_id)
    }


    setSelectedSortedGuests((prevState: any) => ({
      ...prevState,
      ...selectedGuestsIds,
    }))
  }

  const handleSearchGuest = (e: any) => {
    const { value } = e.target
    setSearchValue(value)
    sortFunction(value)
  }

  const modalTitle = () => {
    return `Add ${selectedGuests.length > 0 ? selectedGuests.length : ''} ${selectedGuests.length > 1 ? 'Guests' : 'Guest'}`
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalContainer>
        <ModalHeader
          title={modalTitle()}
          onClose={onClose} />
        <Box position='relative' width='100%' borderTop='1px solid #E8E8ED'>
          <input
            style={{
              backgroundColor: '#FFFFFF',
              border: '0 solid #CBCBD3',
              borderRadius: '8px',
              paddingLeft: '48px',
              paddingRight: 0,
              width: '100%',
              height: '56px',
              outline: 'none',
            }}
            placeholder='Search'
            value={searchValue}
            onChange={(e: any) => handleSearchGuest(e)}
          />

          <Box position='absolute' top='0' bottom='0' display='flex' alignItems='center' left='16px'>
            <Icon stroke='#6d7589' height={20} width={20} />
          </Box>
        </Box>
        <ModalContent>
          {Object.keys(sortedGuests).filter((groupName) => sortedGuests[groupName]?.length > 0).map((item: string) => (
            <Box key={item}>
              <Box padding='0 16px' marginBottom='8px' marginTop='8px'>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Box width='100%' onClick={() => handleSelectAllGuests(item)} cursor='pointer'>
                    <Text
                      fontFamily='Source Sans Pro'
                      lineHeight='20px'
                      fontWeight='600'
                      fontSize='16px'
                      color='#353B60'
                    >
                      {item}
                    </Text>
                  </Box>
                  <Box>
                    {selectedSortedGuests[item] && selectedSortedGuests[item].length === sortedGuests[item].length ? (
                      <CheckboxChecked cursor='pointer' onClick={() => handleSelectAllGuests(item)} />
                    ) : (
                      <CheckboxUnchecked stroke='#73737B' cursor='pointer'
                                         onClick={() => handleSelectAllGuests(item)} />
                    )}
                  </Box>
                </Box>
              </Box>
              {sortedGuests[item].map((guest: any) => {
                return (
                  <Box key={guest.guest_id}
                       bg={selectedSortedGuests[item] && selectedSortedGuests[item].includes(guest.guest_id) ? '#F7F7FC' : ''}>
                    <Box padding='8px 16px'>
                      <Box display='flex' justifyContent='space-between'>
                        <Box
                          display='flex'
                          width='100%'
                          onClick={() => handleSelectGuest(guest.guest_id, item)}
                          cursor='pointer'
                        >
                          <Box>
                            {guest.user.image ? (
                              <Box
                                width='24px'
                                height='24px'
                                backgroundColor='#353B60'
                                borderRadius='50%'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                marginRight='8px'
                              >
                                <Text
                                  fontWeight='700'
                                  fontSize='12px'
                                  lineHeight='15px'
                                  color='#FFFFFF'
                                >
                                  {guest?.first_name.slice(0, 1).toUpperCase()}{guest?.last_name.slice(0, 1).toUpperCase()}
                                </Text>
                              </Box>
                            ) : (
                              <Box
                                width='24px'
                                height='24px'
                                borderRadius='50%'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                marginRight='8px'
                                backgroundColor='#353B60'
                                backgroundPosition='center'
                                backgroundRepeat='no-repeat'
                                backgroundSize='cover'
                                backgroundImage={`url(${guest?.user?.image})`}
                              />
                            )}
                          </Box>
                          <Box>
                            <Text
                              fontFamily='Source Sans Pro'
                              fontSize='14px'
                              fontWeight={400}
                              lineHeight='20px'
                              color='#414042'
                            >
                              {guest.first_name} {guest.last_name}
                            </Text>
                          </Box>
                        </Box>
                        <Box>
                          {selectedSortedGuests[item] && selectedSortedGuests[item].includes(guest.guest_id) ? (
                            <CheckboxChecked onClick={() => handleSelectGuest(guest.guest_id, item)} />
                          ) : (
                            <CheckboxUnchecked
                              stroke='#73737B'
                              onClick={() => handleSelectGuest(guest.guest_id, item)}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          ))}
        </ModalContent>
        <Box padding='16px' borderRadius={0} bg='white'>
          <Box display='flex' justifyContent='flex-end'>
            <Box>
              <Button
                onClick={() => setIsOpenAddGuestModal(false)}
                height='40px'
                style={{
                  border: '1px solid #CBCBD3',
                  borderRadius: '8px',
                  color: '#5458F7',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '20px',
                  padding: '10px 16px',
                  fontFamily: 'Source Sans Pro',
                }}>
                Cancel
              </Button>
            </Box>
            <Box marginLeft='16px'>
              <Button
                onClick={handleSubmit}
                height='40px'
                style={{
                  backgroundColor: '#5458F7',
                  borderRadius: '8px',
                  padding: '10px 18px',
                  color: '#FFFFFF',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  fontFamily: 'Source Sans Pro',
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default AddGuestsModal