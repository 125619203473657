import React, { FC } from 'react'

import { Box, Text } from 'components/ui'

export enum Types {
  FEATURED = 'tabNavigation',
  MENU = 'features',
  ADMIN = 'admin',
  DISABLED = 'disabled',
}

const Labels = {
  [Types.FEATURED]: 'Featured',
  [Types.MENU]: 'Menu',
  [Types.ADMIN]: 'Admin Only',
  [Types.DISABLED]: 'Disabled',
}

type Props = {
  type: Types
}

const Block: FC<Props> = ({ type, children }) => (
  <Box pb="28px" display="flex">
    <Box width="4px" bg="#AAB2F2" borderRadius="5px" />

    <Box width="100%">
      <Box pl="16px" pt="10px" pb="10px">
        <Text
          fontWeight="bold"
          fontSize="16px"
          lineHeight="20px"
          color="#353B60"
        >
          {Labels[type]}
        </Text>
      </Box>

      <Box width="100%">{children}</Box>
    </Box>
  </Box>
)

export default Block
