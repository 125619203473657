import React, { useContext } from 'react'
import { Box, SearchInput } from 'components/ui'
import Tab from './Tab'
import { FaqOptions } from '../utils'
import { Context } from '../Context'

const Tabs = () => {
  const {
    activeTab,
    handleSearch,
    handleSelectTab,
  } = useContext<any>(Context)
  
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Box>
        <Tab
          options={FaqOptions}
          activeTab={activeTab}
          handleClick={handleSelectTab}
        />
      </Box>
      <Box width='320px'>
        <SearchInput onChange={handleSearch} />
      </Box>
    </Box>
  )
}

export default Tabs