import React, { useCallback, ChangeEvent } from 'react'

import { Box, Input } from 'components/ui'
import { Values } from './AppSections'

type Props = {
  type: Values['type']
  caption: Values['caption']
  link: Values['link']
  onChangeValues: (values: Partial<Values>) => void
}

const WEBSITE_TYPE = 101

const Form = ({ type, caption, link, onChangeValues }: Props) => {
  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChangeValues({
        [e.currentTarget.name]: e.currentTarget.value,
      })
    },
    [onChangeValues]
  )

  return (
    <Box py="20px" borderBottom="1px dashed rgba(53, 59, 96, 0.4)">
      <Input
        name="caption"
        placeholder="Title"
        value={caption}
        height="40px"
        borderRadius="8px"
        border="2px solid #E8E8ED"
        onChange={onChangeInput}
      />

      <Input
        name="link"
        placeholder="Website"
        value={link}
        height="40px"
        borderRadius="8px"
        border="2px solid #E8E8ED"
        mt="12px"
        display={type === WEBSITE_TYPE ? undefined : 'none'}
        onChange={onChangeInput}
      />
    </Box>
  )
}

export default Form
