import React, { FC } from 'react'
import { SubmissionErrors } from 'final-form'
import { ReactComponent as Alert } from 'images/alert.svg'
import { Box, Text } from 'components/ui'
import { fontSize, lineHeight } from 'styled-system'

type Props = {
  messages: string
}

const ErrorAlert: FC<Props> = ({ messages }) => (
  <Box
    mb={2}
    // border="1px solid"
    borderColor="#FF4747"
    py="11px"
    width="100%"
    borderRadius={0}
    paddingLeft="10px"
    background="#FDE1E6"
    style={{ fontFamily: 'Lato', fontSize: '14px', lineHeight: '20px' }}
  >
    <Text
      fontFamily="Lato"
      fontWeight={500}
      color="#414042"
      style={{ marginRight: '5px' }}
    >
      <Alert style={{ marginRight: '10px', marginBottom: '3px' }} />
      {messages}
    </Text>
  </Box>
)

export default ErrorAlert
