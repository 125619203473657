import React, { FC } from 'react'
import styled from 'styled-components'

import { Box, Button as ButtonBase, Text } from 'components/ui'

const Button = styled(ButtonBase).attrs({
  borderRadius: '6px',
  border: 'none',
  bg: 'darkened',
  py: '10px',
  px: 1,
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})``

type Props = { src: string }

const Step8Photo: FC<Props> = ({ src }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    backgroundImage={`url("${src}")`}
    backgroundPosition="center"
    backgroundSize="cover"
    backgroundRepeat="no-repeat"
    width="268px"
    height="140px"
    borderRadius="4px"
  >
    <Button>
      <Text color="white" fontFamily="1" fontWeight={600}>
        Show the Picture
      </Text>
    </Button>
  </Box>
)

export default Step8Photo
