import React, { FC } from 'react'

import { Modal, Box, Text, Button } from 'components/ui'

type Props = {
  roomName?: string
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

const DeleteChatModal: FC<Props> = ({
  roomName,
  isOpen,
  onClose,
  onDelete,
}) => {
  const modalStyle = { content: { maxWidth: 360, top: '30vh' } }

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={onClose}>
      <Box py={3} px={4} borderRadius={0} bg="white">
        <Box mb="6px">
          <Text fontFamily="1" fontSize={3} fontWeight={600}>
            Delete Chat
          </Text>
        </Box>

        <Text fontFamily="1">
          Are you sure you want to delete the chat, “{roomName}”?
        </Text>

        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Box mr={0}>
            <Button
              border="none"
              px={2}
              py={0}
              fontFamily="1"
              fontWeight={600}
              fontSize="18px"
              color="blue"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>

          <Button
            border="none"
            px={2}
            py={0}
            fontFamily="1"
            fontSize="18px"
            color="red"
            onClick={onDelete}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteChatModal
