import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import { pathOr, equals } from 'ramda'
import { parse } from 'query-string'

import { verify, info } from 'store/modules/auth/actions'
import { Text, Box, Button } from 'components/ui'
import { LOGIN, WEDDING_CREATE } from 'constants/paths'
import { Wrapper } from 'components/Auth'
import EmailConfirmed from './EmailConfirmed'

type Props = {
  uuid: string
  verificationCode: string
  isDevice: boolean
}

const Confirmation: FC<Props> = () => {
  const { search } = useLocation()
  const { verificationCode, uuid } = useParams<{
    verificationCode: string
    uuid: string
  }>()
  const isDevice = equals(pathOr('0', ['is_device'], parse(search)), '1')
  const isWedingWizard = localStorage.getItem(
    process.env.REACT_APP_WEDDING_WIZARD_PERSIST_KEY || 'hhWeddingWizard'
  )

  const [isDone, setIsDone] = useState(false)
  const [error, setError] = useState()
  const dispatch = useDispatch()
  const { push } = useHistory()

  const onVerify = useCallback(
    async () => dispatch(verify(uuid, verificationCode, isDevice)),
    [dispatch, uuid, verificationCode, isDevice]
  )
  const onInfo = useCallback(async (uuid) => dispatch(info(uuid)), [dispatch])

  const handleClickReturn = useCallback(
    () => push(isWedingWizard ? `${WEDDING_CREATE}/login` : LOGIN),
    [push, isWedingWizard]
  )

  useEffect(() => {
    const handleVerify = async () => {
      try {
        setIsDone(true)
        await onVerify()

        if (isWedingWizard) {
          push(`${WEDDING_CREATE}/step/8`)
        }
      } catch (error) {
        setError(error.message)
      }
    }

    if (!isDone) {
      handleVerify()
    }
  }, [onVerify, isDevice, onInfo, isDone, isWedingWizard, push])

  if (error) {
    return (
      <Wrapper>
        <Text fontSize="18px" color="red">
          {error}
        </Text>

        {isDevice ? (
          <Text fontSize="18px">Please return to the app to continue.</Text>
        ) : (
          <Box display="inline-flex" alignItems="baseline">
            <Text fontSize="18px">Please</Text>

            <Button
              border="none"
              px="0px"
              pb="0px"
              color="primary"
              fontFamily="0"
              fontSize="18px"
              mx={0}
              onClick={handleClickReturn}
            >
              return to the login
            </Button>

            <Text fontSize="18px">to continue.</Text>
          </Box>
        )}
      </Wrapper>
    )
  }

  if (isDevice) {
    return (
      <Wrapper>
        <EmailConfirmed />
      </Wrapper>
    )
  }

  return null
}

export default Confirmation
