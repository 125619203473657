import React, { FC } from 'react'
import styled from 'styled-components'

import { Box, Button as ButtonBase, Text } from 'components/ui'
import { ReactComponent as DeleteIcon } from 'images/trash-1.svg'

const Button = styled(ButtonBase).attrs({
  borderRadius: '6px',
  border: 'none',
  bg: 'rgba(53, 59, 96, 0.5)',
  py: '10px',
  px: '16px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})``

type Props = {
  image: string
  onClickZoomButton: VoidFunction
  onClickChange: VoidFunction
  onClickDeleteButton: VoidFunction
}

const Preview: FC<Props> = ({
  image,
  onClickZoomButton,
  onClickChange,
  onClickDeleteButton,
}) => (
  <Box
    backgroundImage={`url(${image})`}
    backgroundPosition="center"
    backgroundSize="cover"
    backgroundRepeat="no-repeat"
    width="100%"
    height="225px"
    borderRadius={0}
    position="relative"
  >
    <Box
      position="absolute"
      top="8px"
      left="8px"
      right="8px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Button onClick={onClickZoomButton}>
          <Text color="white" fontFamily="1" fontWeight={600} fontSize="14px">
            Zoom
          </Text>
        </Button>
      </Box>

      <Box display="flex">
        <Button onClick={onClickChange}>
          <Text color="white" fontFamily="1" fontWeight={600} fontSize="14px">
            New Image
          </Text>
        </Button>

        <Box ml="12px">
          <Button p="0px !important" width="40px" onClick={onClickDeleteButton}>
            <DeleteIcon stroke="white" />
          </Button>
        </Box>
      </Box>
    </Box>
  </Box>
)

export default Preview
