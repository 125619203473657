import React, { FC } from 'react'
import { parseISO } from 'date-fns'

import { Text, Box, Button } from 'components/ui'
import { DayPickerInput, CheckBox } from 'components/form'
import { ReactComponent as Icon } from 'images/ww-step5.svg'
import { MainBox, Title, Subtitle } from 'components/WeddingWizard'

type Props = {
  isNotSureAboutRSVP?: boolean
  weddingDate?: string
  isValid: boolean
  onBack: VoidFunction
  onContinue: VoidFunction
}

const Step5: FC<Props> = ({
  isNotSureAboutRSVP = false,
  weddingDate,
  isValid,
  onBack,
  onContinue,
}) => (
  <MainBox pt="70px" pb="32px">
    <Box mt="-98px" mb="30px">
      <Icon />
    </Box>

    <Title>When’s your deadline to RSVP?</Title>

    <Box mt="8px" />

    <Subtitle>
      Many vendors require a two week notice for final headcounts. 
      <br />
      We help remind your guests of this important deadline.
    </Subtitle>

    <Box
      width="100%"
      maxWidth="400px"
      display="flex"
      flexDirection="column"
      mt={['32px', '40px']}
    >
      <Text fontSize={2} fontWeight={600} lineHeight="25px" color="secondary">
        RSVP deadline:
      </Text>

      <Box mt="8px" />

      <DayPickerInput
        name="rsvpDeadline"
        placeholder="Select date"
        disabledDays={{
          after: weddingDate ? parseISO(weddingDate) : undefined,
          before: new Date(),
        }}
        overlayPosition="right"
        disabled={isNotSureAboutRSVP}
        hideValue={isNotSureAboutRSVP}
        height="40px"
      />

      <Box mt={['17px', '22px']} display="flex" alignItems="center">
        <CheckBox name="isNotSureAboutRSVP" />

        <Box ml="10px" mt="-4px">
          <Text fontFamily="1" fontSize="14px" color="#414042">
            We don’t have a deadline yet
          </Text>
        </Box>
      </Box>

      <Box mt="94px" display="flex" justifyContent="space-between">
        <Button variant="secondary" height="40px" onClick={onBack}>
          Back
        </Button>

        <Button
          variant="primary"
          disabled={!isValid}
          height="40px"
          onClick={onContinue}
        >
          Next
        </Button>
      </Box>
    </Box>
  </MainBox>
)

export default Step5
