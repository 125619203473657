import styled, { keyframes } from 'styled-components'
import { position, PositionProps } from 'styled-system'

import { ReactComponent as Icon } from 'images/loading.svg'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const defaultSize = 52

type Props = PositionProps & {
  theme: any
  size?: string
  m?: string
  fill?: string
}

const Loading = styled(Icon).attrs((props: Props) => ({
  fill: props.fill || props.theme.colors.primary,
  width: props.size || defaultSize,
  height: props.size || defaultSize,
}))<Props>`
  ${position}

  animation: ${rotate} 1s linear infinite;
  margin: ${(props) => props.m || '52px auto 0'};
`

Loading.defaultProps = {
  position: 'absolute',
  right: 0,
  left: 0,
}

export default Loading
