import React, { FC, useCallback } from 'react'
import { uniq, filter, is, isEmpty, trim, join } from 'ramda'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { Wedding } from 'store/modules/wedding/reducer'
import { Box, Text } from 'components/ui'
import { ItemMenu } from 'components/WeddingList'
import { N_GUESTS_LABELS } from 'constants/wedding'
import { WEDDING_LAYOUT } from 'constants/paths'
import { getDate, getImage } from 'utils/wedding'

const Container = styled(Box).attrs({
  display: 'flex',
  border: '1px solid',
  borderColor: 'gray',
  borderRadius: 1,
  height: '124px',
  position: 'relative',
  cursor: 'pointer',
})``

type Props = {
  zIndex: number
  isAdmin: boolean
  onOpenModal: (modalName: string, wuid: string) => void
}

const ListItem: FC<Wedding & Props> = ({
  zIndex,
  wuid,
  isAdmin,
  wedding_name,
  wedding_image,
  wedding_date,
  approx_date_month,
  approx_date_year,
  location_name,
  location_address,
  location_city,
  n_guests,
  wedding_role,
  onOpenModal,
}) => {
  const history = useHistory()

  const handleClickWrapper = useCallback(
    () => history.push(WEDDING_LAYOUT(wuid)),
    [history, wuid]
  )
  const handleOpenModal = useCallback(
    (modalName: string) => onOpenModal(modalName, wuid),
    [onOpenModal, wuid]
  )

  return (
    <Box position="relative">
      {isAdmin && (
        <Box
          zIndex={zIndex}
          position="absolute"
          left={1}
          top="11px"
          bg="darkened"
          px={1}
          py={0}
          borderRadius={1}
        >
          <Text fontSize="18px" fontWeight={600} color="white">
            Admin
          </Text>
        </Box>
      )}

      <Box position="absolute" zIndex={zIndex} top={1} right={1} display="flex">
        <ItemMenu onOpenModal={handleOpenModal} />
      </Box>

      <Container onClick={handleClickWrapper}>
        <Box
          width="224px"
          height="100%"
          backgroundImage={`url(${getImage(wedding_image)})`}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          borderTopLeftRadius={1}
          borderBottomLeftRadius={1}
        />

        <Box px={2} py={1} overflowY="hidden" width="calc(100% - 224px)">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            minHeight="2rem"
          >
            <Text fontSize={3} fontWeight="bold">
              {wedding_name}
            </Text>
          </Box>

          <Box display="flex" flexWrap="wrap" alignItems="flex-start" mt="17px">
            <Box width="200px" pr={0}>
              <Text color="darkGray">Wedding Date</Text>

              <Text isTruncate>
                {getDate({ wedding_date, approx_date_month, approx_date_year })}
              </Text>
            </Box>

            <Box width="160px" pr={0}>
              <Text color="darkGray">Location</Text>

              <Text isTruncate>
                {join(
                  ', ',
                  uniq(
                    filter((v) => is(String, v) && !isEmpty(trim(v)), [
                      location_name,
                      location_address,
                      location_city,
                    ])
                  )
                )}
              </Text>
            </Box>

            <Box width="150px" pr={0}>
              <Text color="darkGray">Guest Count</Text>

              <Text isTruncate>{N_GUESTS_LABELS[n_guests]}</Text>
            </Box>

            <Box width="130px" pr={0}>
              <Text color="darkGray">Permissions</Text>

              <Text isTruncate isCapitalize>
                {wedding_role}
              </Text>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ListItem
