import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
  filter,
  propEq,
  test,
  append,
  includes,
  reject,
  equals,
  isEmpty,
} from 'ramda'

import { Modal, Box, Text, Button, Loading } from 'components/ui'
import { ReactComponent as CloseIcon } from 'images/close.svg'
import {
  getIsLoadedByWuid,
  getGuestsByWuid,
} from 'store/modules/guests/selectors'
import { listAdmin, editGuest } from 'store/modules/guests/actions'
import { getFullName } from 'utils/guest'
import GuestsList from './GuestsList'
import AdminsList from './AdminsList'
import PendingList from './PendingList'

type Props = {
  wuid?: string
  isOpen: boolean
  onClose: VoidFunction
}

const ManageAdmins: FC<Props> = ({ isOpen, wuid, onClose }) => {
  const dispatch = useDispatch()
  const isLoaded = useSelector(getIsLoadedByWuid(wuid))
  const allGuests = useSelector(getGuestsByWuid(wuid))
  const [isLoading, setIsLoading] = useState(false)
  const [isInviting, setIsInviting] = useState(false)
  const [isSetGuestDisabled, setIsSetGuestDisabled] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [selectedGuests, setSelectedGuests] = useState<string[]>([])

  const guests = filter(
    (guest) =>
      propEq('is_admin', '0', guest) &&
      propEq('is_used', '1', guest) &&
      test(
        new RegExp(`${searchValue}`, 'gi'),
        getFullName([guest.first_name, guest.last_name])
      ),
    allGuests
  )
  const admins = filter(propEq('is_admin', '1'), allGuests)
  const pending = filter(propEq('is_used', '0'), allGuests)

  const onListAdmin = useCallback(async () => dispatch(listAdmin({ wuid })), [
    dispatch,
    wuid,
  ])

  const onSetAdmin = useCallback(
    async (guestId) =>
      dispatch(editGuest({ wuid, guest_id: guestId, is_admin: '1' })),
    [dispatch, wuid]
  )

  const onRemoveAdmin = useCallback(
    async (guestId) =>
      dispatch(editGuest({ wuid, guest_id: guestId, is_admin: '0' })),
    [dispatch, wuid]
  )

  const handleClose = useCallback(() => {
    setIsLoading(false)
    onClose()
  }, [setIsLoading, onClose])

  const handleSelectGuest = useCallback(
    (guestId) =>
      setSelectedGuests(
        includes(guestId, selectedGuests)
          ? reject(equals(guestId), selectedGuests)
          : append(guestId, selectedGuests)
      ),
    [selectedGuests]
  )

  const handleInvite = useCallback(async () => {
    setIsInviting(true)

    await Promise.all(
      selectedGuests.map(async (guestId) => {
        try {
          await onSetAdmin(guestId)
        } catch (error) {
          toast(error.message)
        }
      })
    )

    setSelectedGuests([])
    setIsInviting(false)
  }, [selectedGuests, onSetAdmin, setSelectedGuests])

  const handleRemoveAdmin = useCallback(
    async (guestId) => {
      setIsSetGuestDisabled(true)

      try {
        await onRemoveAdmin(guestId)
      } catch (error) {
        toast(error.message)
      } finally {
        setIsSetGuestDisabled(false)
      }
    },
    [onRemoveAdmin]
  )

  useEffect(() => {
    const handleLoad = async () => {
      setIsLoading(true)

      try {
        await onListAdmin()

        setIsLoading(false)
      } catch (error) {
        toast(error.message)
        handleClose()
      }
    }

    if (isOpen && !isLoaded && !isLoading) handleLoad()
  }, [isOpen, isLoaded, isLoading, setIsLoading, onListAdmin, handleClose])

  const modalStyle = {
    content: { maxWidth: 664, maxHeight: '80vh', top: '10vh' },
  }

  const renderContent = () => {
    if (isLoading) return <Loading />

    return (
      <>
        <Box display="flex">
          <Box width={3 / 5} borderRight="1px solid" borderRightColor="gray">
            <GuestsList
              guests={guests}
              isDisabled={isInviting}
              selectedGuests={selectedGuests}
              onSetSearchValue={setSearchValue}
              onSelectGuest={handleSelectGuest}
            />
          </Box>

          <Box
            width={2 / 5}
            px="10px"
            height="calc(80vh - 138px)"
            overflowY="auto"
          >
            <AdminsList
              admins={admins}
              isDisabled={isSetGuestDisabled}
              onRemoveAdmin={handleRemoveAdmin}
            />

            <PendingList pending={pending} />
          </Box>
        </Box>

        <Box
          height="64px"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          pr={2}
          borderTop="1px solid"
          borderTopColor="gray"
        >
          <Button variant="link" mr={3} onClick={handleClose}>
            Cancel
          </Button>

          <Button
            variant="primary"
            disabled={isEmpty(selectedGuests) || isInviting}
            onClick={handleInvite}
          >
            Invite
          </Button>
        </Box>
      </>
    )
  }

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={handleClose}>
      <Box borderRadius={1} bg="white" minHeight="200px">
        <Box
          p={2}
          borderBottom="1px solid"
          borderBottomColor="gray"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontFamily="1" fontSize={2} fontWeight={600}>
            Invite Administrators
          </Text>

          <Button
            border="none"
            width="24px"
            height="24px"
            padding="0px"
            onClick={handleClose}
          >
            <CloseIcon fill="#99a2ad" />
          </Button>
        </Box>

        {renderContent()}
      </Box>
    </Modal>
  )
}

export default ManageAdmins
