import React, { FC, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { Context } from './Context'
import { toast } from 'react-toastify'
import { Box } from 'components/ui'
import Header from './Header'
import Tabs from './Tabs'
import AllQuestions from './Tabs/AllQuestions'
import AddQuestionDrawer from './Header/AddQuestionDrawer'
import RemoveModal from './Questions/removeModal'

// Store
import {
  load,
  updateQuestion,
  // updateQuestionOrder,
  // setFaqItems,
  addQuestion,
  removeQuestion,
  faqLikes,
} from 'store/modules/faq/actions'
import { getFaqItems, getIsLoaded, getQuestionById } from 'store/modules/faq/selectors'
import { getWedding } from 'store/modules/wedding/selectors'
import { update } from 'store/modules/wedding/actions'
import { getMyId } from 'store/modules/auth/selectors'

// Utils
import { commonlyAskedQuestions } from './utils'
import { FaqItem } from '../../store/modules/faq/reducer'

const Faq: FC = () => {
  const dispatch = useDispatch()

  const [isOpenAddQuestionDrawer, setIsOpenAddQuestionDrawer] = useState(false)
  const [activeTab, setActiveTab] = useState('0')
  const [isLoading, setIsLoading] = useState(false)
  const [askedQuestions, setAskedQuestions] = useState()
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false)
  const [faqItemId, setFaqItemId] = useState()
  const [faqs, setFaqs] = useState<any[]>([])
  const [editableFaq, setEditableFaq] = useState<string>()
  const [isEditable, setIsEditable] = useState(false)
  const [wedding, setWedding] = useState<any>({})
  const [sortedFaqs, setSortedFaqs] = useState<FaqItem[]>([])

  const { wuid } = useParams<{ wuid: string }>()

  const uuid = useSelector(getMyId)
  const isLoaded = useSelector(getIsLoaded)
  const faqItems = useSelector(getFaqItems)
  const question = useSelector(getQuestionById(faqItemId))
  const weddingData = useSelector(getWedding(wuid))


  const onLoad = useCallback(async () => dispatch(load()), [dispatch])

  const onRemove = useCallback(
    async (data) => dispatch(removeQuestion(data)),
    [dispatch],
  )

  const onUpdateQuestion = useCallback(
    async (data) => dispatch(updateQuestion(data)),
    [dispatch],
  )
  // const onUpdateQuestionOrder = useCallback(
  //   async (data) => dispatch(updateQuestionOrder(data)),
  //   [dispatch],
  // )
  // const onSetFaqItems = useCallback(
  //   async (data) => dispatch(setFaqItems(data)),
  //   [dispatch],
  // )

  useEffect(() => {
    setWedding(weddingData)
  }, [weddingData])

  useEffect(() => {
    setFaqs(faqItems)
  }, [faqItems])

  useEffect(() => {
    const questions = faqItems.map(({ question }: any) => question)
    const filtered: any = commonlyAskedQuestions.filter((item: { question: string }) => !questions.includes(item.question))
    setAskedQuestions(filtered)
  }, [faqItems])

  const handleLoad = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoad()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onLoad])

  useEffect(() => {
    if (!isLoaded && !isLoading) handleLoad()
  }, [isLoaded, isLoading, handleLoad])

  const onUpdateFaq = useCallback(
    async (data) => {
      try {
        if (!data.answer) {
          data.answer = ''
        }

        await onUpdateQuestion(data)
        setEditableFaq('')
        setIsEditable(false)
        toast('The question has been updated successfully')
      } catch (error) {
        toast(error.message)
      }
    },
    [onUpdateQuestion],
  )

  const onAddQuestion = useCallback(
    async (data) => {
      try {
        await dispatch(addQuestion(data))
        setIsOpenAddQuestionDrawer(false)
      } catch (error) {
        toast(error.message)
      }
    },
    [dispatch],
  )

  const handleAddQuestion = async (values: object) => {
    try {
      await onAddQuestion(values)
      toast('The question has been added successfully')
    } catch (error) {
      toast(error.message)
    }
  }

  const onRemoveQuestion = useCallback(
    async (data) => {
      data.wuid = wuid
      try {
        await dispatch(onRemove(data))
      } catch (error) {
        toast(error.message)
      }
    },
    [dispatch, onRemove, wuid],
  )

  const handleRemoveQuestion = async (values: object) => {
    try {
      await onRemoveQuestion(values)
      toast('The question has been removed successfully')
    } catch (error) {
      toast(error.message)
    }
  }

  const handleOpenDrawer = () => {
    setIsOpenAddQuestionDrawer(true)
  }

  const handleCloseDrawer = () => {
    setIsOpenAddQuestionDrawer(false)
  }

  const handleSelectTab = (index: string) => {
    setActiveTab(`${index}`)
  }

  const handleClickRemoveModal = (action: string, faqId: any) => {
    if (action === 'open') {
      setFaqItemId(faqId)
      setIsOpenRemoveModal(true)
    } else {
      setIsOpenRemoveModal(false)
    }
  }

  const handleDelete = async () => {
    try {
      await onRemoveQuestion({ faq_item_id: question?.faq_item_id })
      setIsOpenRemoveModal(false)
      toast('The question has been removed successfully')
    } catch (error) {
      toast(error.message)
    }
  }

  const handleEditFaq = (faqId: string) => {
    setIsEditable(!isEditable)
    if (isEditable) {
      setEditableFaq('')

    } else {
      setEditableFaq(faqId)
    }
  }

  const onLike = useCallback(
    async (data) => dispatch(faqLikes(data)),
    [dispatch],
  )

  const handleLikeFaq = async (faqId: string) => {
    const faqItem = faqItems.find((item) => item.faq_item_id === faqId)
    const is_like = faqItem?.is_like

    const data = {
      wuid,
      uuid,
      faq_item_id: faqId,
      score: is_like === '0' ? 1 : 0,
    }

    await onLike(data)
  }

  const onUpdateWedding = useCallback(
    async (data) => dispatch(update(data)),
    [dispatch],
  )

  const handleSwitchUpVoting = async (e: any, name: string) => {
    let data: any

    if (name === 'ask-question') {
      let { checked } = e.target
      data = { is_faq_readonly: checked ? '1' : '0' }
      await onUpdateWedding(data)
    } else if (name === 'up-voting') {
      let { checked } = e.target
      data = { is_faq_likes: checked ? '1' : '0' }
      await onUpdateWedding(data)
    }
  }

  const handleSearch = (event: any) => {
    const query = event.target.value

    let data = [...faqItems]

    let filtered = data?.filter((item) => {
      return item.question?.toLowerCase().includes(query.toLowerCase()) || item.answer?.toLowerCase().includes(query.toLowerCase())
    })

    if (activeTab === '1') {
      filtered = filtered.filter((faq: { answer: string }) => faq.answer === '')
    }

    setSortedFaqs(filtered)
  }

  const Faq = {
    isOpenAddQuestionDrawer,
    setIsOpenAddQuestionDrawer,
    activeTab,
    faqItems,
    faqs,
    setFaqs,
    askedQuestions,
    isOpenRemoveModal,
    editableFaq,
    setEditableFaq,
    isEditable,
    setIsEditable,
    wedding,
    sortedFaqs,
    setSortedFaqs,
    onUpdateFaq,
    handleEditFaq,
    handleDelete,
    handleClickRemoveModal,
    handleRemoveQuestion,
    handleAddQuestion,
    handleCloseDrawer,
    handleOpenDrawer,
    handleSelectTab,
    handleSwitchUpVoting,
    handleLikeFaq,
    handleSearch,
  }

  return (
    <>
      <Context.Provider value={Faq}>
        <Box width='100%' backgroundColor='#FFFFFF' borderRadius='8px' padding='16px'>
          <Header />
          <Box marginTop='16px' marginBottom='24px'>
            <Tabs />
          </Box>
          <Box style={{ borderTop: '1px solid #CBCBD3', opacity: '0.4' }} />
          <Box display='flex' justifyContent='space-between' height='calc(100% - 110px)'>
            <AllQuestions />
          </Box>
        </Box>

        <AddQuestionDrawer />
        <RemoveModal />
      </Context.Provider>
    </>
  )
}

export default Faq
