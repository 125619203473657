import React, { useState } from 'react'
import { Box, Button } from 'components/ui'
import { CreateMenuPopup } from '../../utils'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { add } from '../../../../store/modules/foodMenu/actions/menu/add'

const AddMenuModal = ({ setModalIsOpen }: { setModalIsOpen: any }) => {
  const { wuid } = useParams()
  const dispatch = useDispatch()

  const [state, setState] = useState({
    title: '',
    comment: '',
  })
  const [error, setError] = useState('')

  const handleInputsChange = (e: any) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    })
  }


  const handleAddMenu = async () => {
    if (!state.title) {
      setError('Name is recuired')
    } else if (wuid) {
      await dispatch(add({ wuid, ...state }))
      error && (setError(''))
      setModalIsOpen(false)
    }
  }
  return (
    <CreateMenuPopup>
      <Box className='wrapper' width='100%'>
        <Box width='100%' className='title'>Create Menu</Box>
        <Box width='100%' mb={20}>
          <span style={{
            marginBottom: '4px !important',
            marginLeft: '4px',
            fontSize: '14px',
            lineHeight: '18px',
            fontFamily: 'Source Sans Pro',
            color: '#73737B',
          }}
          >
            Name
          </span>
          <input
            className='input'
            type='text'
            id='title'
            value={state.title}
            onChange={handleInputsChange}
            placeholder='Name'
            style={{ borderColor: error ? 'red' : '#E8E8ED' }}
          />
          {error && <Box style={{ color: 'red', marginLeft: '8px' }}> {error}</Box>}
        </Box>
        <Box width='100%'>
            <span style={{
              marginBottom: '4px !important',
              marginLeft: '4px',
              fontSize: '14px',
              lineHeight: '18px',
              fontFamily: 'Source Sans Pro',
              color: '#73737B',
            }}>Comment</span>
          <input className='input' type='text' id='comment' value={state.comment} onChange={handleInputsChange}
                 placeholder='Comment' />
        </Box>
        <Box className='btnWrapper'>
          <Box display='flex' justifyContent='flex-end'>
            <Box mr={0}>
              <Button
                border='none'
                px={2}
                py={0}
                fontFamily='1'
                fontSize='18px'
                color='blue'
                onClick={() => setModalIsOpen({ type: null, name: null })}
              >
                Cancel
              </Button>
            </Box>

            <Button type='submit' variant='primary' onClick={handleAddMenu}>
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    </CreateMenuPopup>
  )
}

export default AddMenuModal