import { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import {
  variant,
  typography,
  TypographyProps,
  color,
  ColorProps,
} from 'styled-system'

import Box from './Box'

const Button = styled(Box).attrs({
  as: 'button',
})<ButtonHTMLAttributes<HTMLButtonElement> &
  TypographyProps &
  ColorProps & {
  fullWidth?: boolean
  type?: string
  cursor?: string
  variant?:
    | 'primary'
    | 'primaryBig'
    | 'primaryOutline'
    | 'secondary'
    | 'link'
  value?: any
}>`
  appearance: none;

  &:focus {
    outline: 0;
  }

  ${typography}
  ${color}

  ${variant({
    variants: {
      primary: {
        color: 'white',
        bg: 'primary',
        border: 'none',
        borderRadius: 0,
        fontFamily: 1,
        fontSize: 1,
        fontWeight: '600',
        px: '28px',
      },
      primaryBig: {
        color: 'white',
        bg: 'primary',
        lineHeight: '1.11',
        border: 'none',
        borderRadius: 1,
        fontFamily: 1,
        fontSize: '18px',
        fontWeight: '600',
        px: 2,
      },
      primaryOutline: {
        color: 'primary',
        bg: 'white',
        border: '1px solid',
        borderColor: 'primary',
        borderRadius: 1,
        fontFamily: 1,
        fontWeight: '600',
        px: 2,
      },
      secondary: {
        color: 'black',
        bg: 'white',
        border: '1px solid',
        borderColor: 'gray',
        borderRadius: 0,
        fontFamily: 1,
        fontWeight: '600',
        px: '28px',
      },
      link: {
        color: 'primary',
        bg: 'transparent',
        border: 'none',
        fontFamily: 0,
        fontSize: 1,
      },
    },
  })}

  ${(props) =>
          props.fullWidth &&
          css`
            width: 100%;
          `}
`

Button.defaultProps = {
  type: 'button',
  fontFamily: '1',
  height: '38px',
  fontSize: 1,
  cursor: 'pointer',
  bg: 'white',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export default Button
