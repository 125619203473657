import { Reducer } from 'redux'
import { path, assoc } from 'ramda'

import {
  LOGOUT,
  LOGIN,
  LOGIN_OAUTH,
  VERIFY,
  INFO,
  UPDATE_PROFILE,
  CHANGE_WUID,
} from './actionTypes'

export const initialState = {}

export type User = {
  uuid: string
  first_name: string
  last_name: string
  image?: { slice: string; link: string }[]
  contact_email?: string
  contact_phone?: string
}

export type State = {
  authToken?: string
  user?: User
  wuid?: string
}

const authReducer: Reducer = (state: State = initialState, action) => {
  switch (action.type) {
    case LOGOUT: {
      localStorage.removeItem(
        process.env.REACT_APP_WEDDING_WIZARD_PERSIST_KEY || 'hhWeddingWizard'
      )

      return initialState
    }
    case LOGIN.FULFILLED:
    case VERIFY.FULFILLED:
    case LOGIN_OAUTH.FULFILLED:
      return !path(['data', 'isDevice'], action.payload)
        ? {
            authToken: path(['payload', 'auth_token'], action.payload),
            user: {
              uuid: path(['payload', 'uuid'], action.payload),
              first_name: path(['payload', 'first_name'], action.payload),
              last_name: path(['payload', 'last_name'], action.payload),
              image: path(['payload', 'image'], action.payload),
              contact_email: path(['payload', 'contact_email'], action.payload),
              contact_phone: path(['payload', 'contact_phone'], action.payload),
            },
          }
        : state
    case INFO.FULFILLED:
      return assoc('user', path(['payload', 'user'], action.payload), state)
    case UPDATE_PROFILE.FULFILLED:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.payload.user,
        },
      }
    case CHANGE_WUID:
      return { ...state, wuid: action.payload?.wuid }
    default:
      return state
  }
}

export default authReducer
