import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { Modal, Box, Text, Button } from 'components/ui'
import { readImage } from 'utils/images'
import {
  uploadFloorplan,
  removeFloorplan,
} from 'store/modules/seatings/actions'
import { Floorplan } from 'store/modules/seatings/reducer'
import { ReactComponent as CloseIcon } from '../AddTableModal/close.svg'
import { ReactComponent as UploadIcon } from './upload.svg'
import { ReactComponent as DeleteIcon } from './delete.svg'

const getTypeByExt = (ext?: Floorplan['ext']) => {
  if (!ext) return undefined

  switch (ext.toLowerCase()) {
    case 'pdf':
      return 'application/pdf'
    case 'jpeg':
    case 'jpg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    case 'tif':
      return 'image/tif'
    default:
      return undefined
  }
}

const style = {
  content: {
    maxWidth: 440,
  },
}

type Props = {
  chartId: string
  floorplan?: Floorplan
}

const UploadFloorplan = ({ chartId, floorplan }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteOnClickSave, setDeleteOnClickSave] = useState(false)
  const [file, setFile] = useState<File>()
  const [image, setImage] = useState<string>()
  const { wuid } = useParams()
  const dispatch = useDispatch()

  const onUploadFloorplan = useCallback(
    async (data: FormData) => dispatch(uploadFloorplan(data)),
    [dispatch]
  )

  const onRemoveFloorplan = useCallback(
    async () => dispatch(removeFloorplan(chartId)),
    [chartId, dispatch]
  )

  const onOpen = useCallback(() => setIsOpen(true), [])

  const onClose = useCallback(() => {
    setIsOpen(false)
    setFile(undefined)
    setImage(undefined)
    setDeleteOnClickSave(false)
  }, [])

  const onSave = useCallback(async () => {
    try {
      setIsLoading(true)

      if (deleteOnClickSave) {
        await onRemoveFloorplan()
      } else {
        if (!file || !wuid) return onClose()

        const data = new FormData()
        data.append('schart_id', chartId)
        data.append('file_ext', file.name.split('.').pop() || '')
        data.append('file', file)
        data.append('wuid', wuid)

        await onUploadFloorplan(data)
      }

      onClose()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [
    chartId,
    file,
    wuid,
    onUploadFloorplan,
    deleteOnClickSave,
    onClose,
    onRemoveFloorplan,
  ])

  const onDelete = useCallback(async () => {
    setFile(undefined)
    setImage(undefined)
    setDeleteOnClickSave(true)
  }, [])

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const [file] = acceptedFiles

    if (file) {
      setFile(file)
      setImage(await readImage(file))
    }
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: ['application/pdf', 'image/jpeg', 'image/png', 'image/tif'],
    multiple: false,
  })

  const renderContent = useCallback(() => {
    const type = file?.type || getTypeByExt(floorplan?.ext)
    const src = image || floorplan?.url

    if (!deleteOnClickSave && type && src) {
      return (
        <embed
          src={src}
          type={type}
          height="100%"
          width="100%"
          style={{ padding: 5, zIndex: 1, position: 'absolute' }}
        />
      )
    }

    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="absolute"
        zIndex={1}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <UploadIcon />

        <Text
          color="#5458F7"
          fontWeight={600}
          fontSize="16px"
          lineHeight="20px"
        >
          Drag & Drop
        </Text>

        <Box mt="4px" />

        <Text
          color="#73737B"
          fontWeight={400}
          fontSize="14px"
          lineHeight="18px"
        >
          or
        </Text>

        <Button
          mt="8px"
          mb="16px"
          bg="#5458F7"
          borderRadius="10px"
          height="40px"
          width="140px"
          onClick={open}
        >
          <Text color="#FFFFFF" fontWeight={600} fontSize="16px">
            Browse Files
          </Text>
        </Button>

        <Text
          fontWeight={400}
          fontSize="14px"
          lineHeight="18px"
          color="#73737B"
        >
          PDF, PNG, JPEG, or TIF (max 25MB)
        </Text>
      </Box>
    )
  }, [
    floorplan,
    image,
    file,
    getRootProps,
    getInputProps,
    open,
    deleteOnClickSave,
  ])

  return (
    <>
      <Button
        height="40px"
        width="160px"
        bg="white"
        border="1px solid #CBCBD3"
        borderRadius="10px"
        onClick={onOpen}
      >
        <Text fontWeight={600} fontSize="16px" color="#353B60">
          Upload Floorplan
        </Text>
      </Button>

      <Modal isOpen={isOpen} style={style} onRequestClose={onClose}>
        <Box
          bg="#F7F7FC"
          borderRadius="10px"
          boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
        >
          <Box
            bg="#FFFFFF"
            borderRadius="10px"
            boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
            height="60px"
            px="24px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontWeight={700} fontSize="20px" color="#353B60">
              Upload Floorplan
            </Text>

            <Button px="0px" width="24px" height="24px" onClick={onClose}>
              <CloseIcon />
            </Button>
          </Box>

          <Box px="24px" pt="20px" pb="24px">
            <Box height="229px" mb="6px" position="relative">
              {(floorplan?.id || file || !image) && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="352px"
                  height="229px"
                  viewBox="0 0 352 229"
                  style={{ position: 'absolute' }}
                >
                  <rect
                    rx="10"
                    ry="10"
                    width="100%"
                    height="100%"
                    fill="#fff"
                    stroke="#CBCBD3"
                    strokeWidth="2"
                    strokeDasharray="4,4"
                  />
                </svg>
              )}

              {renderContent()}

              {((floorplan?.id && !deleteOnClickSave) || file) && (
                <Button
                  width="40px"
                  height="40px"
                  bg="#fff"
                  borderRadius="20px"
                  px="0px"
                  position="absolute"
                  top="8px"
                  right="8px"
                  zIndex={2}
                  disabled={isLoading}
                  onClick={onDelete}
                >
                  <DeleteIcon />
                </Button>
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="end"
              mt="32px"
            >
              <Button
                fontWeight={600}
                fontSize="16px"
                color="#5458F7"
                px="0px"
                bg="transparent"
                mr="40px"
                fontFamily="0"
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                bg="#5458F7"
                borderRadius="10px"
                height="40px"
                width="80px"
                fontWeight={600}
                fontSize="16px"
                color="white"
                fontFamily="0"
                disabled={(!image && !deleteOnClickSave) || isLoading}
                onClick={onSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default UploadFloorplan
