import React, { FC } from 'react'
import { Route } from 'react-router'

import {
  LOGIN,
  SIGNUP,
  RESET_PASSWORD,
  VERIFY,
  OAUTH_SUCCESS,
} from 'constants/paths'
import ResetPassword from 'routes/ResetPassword'
import Verify from 'routes/Verify'
import Login from 'routes/Login'
import Signup from 'routes/Signup'
import OauthSuccess from 'routes/OauthSuccess'

const LoggedIn: FC = () => (
  <>
    <Route path={RESET_PASSWORD} component={ResetPassword} />
    <Route path={VERIFY} component={Verify} exact />
    <Route path={LOGIN} component={Login} />
    <Route path={SIGNUP} component={Signup} />
    <Route path={OAUTH_SUCCESS} component={OauthSuccess} />
    <Route path="*" component={Login} />
  </>
)

export default LoggedIn
