import { LOAD_ALBUM_PHOTOS } from '../actionTypes'

export const loadAlbumPhotos = (data: any) => ({
  type: LOAD_ALBUM_PHOTOS.name,
  api: {
    url: 'album_photos/list',
    data: {
      album_id: data.album_id,
      wuid: data.wuid,
      page_num: 1,
      per_page: 10,
      field_list: 'all_fields',
    },
  },
})
