import styled from 'styled-components'
import {
  system,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  color,
  ColorProps,
  border,
  BorderProps,
  position,
  PositionProps,
  background,
  BackgroundProps,
  shadow,
  ShadowProps,
} from 'styled-system'

type Props = SpaceProps &
  LayoutProps &
  FlexboxProps &
  ColorProps &
  BorderProps &
  PositionProps &
  BackgroundProps &
  ShadowProps & {
  cursor?: string
  pointerEvents?: string
}

const Box = styled.div<Props>`
  ${space}
  ${layout}
  ${flexbox}
  ${color}
  ${border}
  ${position}
  ${background}
  ${shadow}

  ${system({
  cursor: true,
})}

  ${system({
  lineHeight: true,
})}

  ${system({
  pointerEvents: true,
})}

  box-sizing: border-box;

  &:focus {
    outline: 0;
  }
`

export default Box
