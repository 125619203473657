import styled from 'styled-components'
import { path } from 'ramda'

import { Box } from 'components/ui'

const Item = styled(Box).attrs({
  pl: 1,
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})`
  &:hover {
    background: ${path(['theme', 'colors', 'lightGray'])};
  }
`

export default Item
