import React from 'react'
import { Box } from 'components/ui'
import styled from 'styled-components'

// Icons
import { ReactComponent as PrivateIcon } from 'images/private.svg'
import { ReactComponent as SharedIcon } from 'images/shared.svg'

export const types: { id: string, icon: any, name: string }[] = [
  {
    id: '0',
    name: 'Private',
    icon: <PrivateIcon />,
  },
  {
    id: '1',
    name: 'Shared',
    icon: <SharedIcon />,
  },
  {
    id: '2',
    name: 'Public read',
    icon: '',
  },
  {
    id: '3',
    name: 'Public write',
    icon: '',
  },
]

export const filterItems: { id: string, name: string }[] = [
  {
    id: '0',
    name: 'View all',
  },
]

export const AlbumCardStyle = styled(Box)`
  max-height: calc(100% - 86px);
  overflow-y: auto;
  padding-top: 16px;

  ::-webkit-scrollbar {
    width: 0;
  }
`

export const AccessType = [
  {
    value: 0,
    label: 'Remove access',
  },
  {
    value: 1,
    label: 'Can view',
  },
  {
    value: 2,
    label: 'Can edit',
  },
]

export const MediasFilter: { name: string, title: string }[] = [
  {
    title: 'Photos',
    name: '0',
  },
  {
    title: 'Videos',
    name: '1',
  },
  {
    title: 'All media',
    name: '2',
  },
]

export const AlbumsFilter: { name: string; title: string }[] = [
  {
    name: 'all albums',
    title: 'All albums',
  },
  {
    name: 'public',
    title: ' Public',
  },
  {
    name: 'shared',
    title: 'Shared',
  },
  {
    name: 'private',
    title: 'Private',
  },
]