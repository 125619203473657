import React, { FC, useState, useEffect, useCallback } from 'react'
import { DragDropContext, DropResult, Droppable, Draggable, DragStart } from 'react-beautiful-dnd' // prettier-ignore
import { useDispatch } from 'react-redux'

import { Box } from 'components/ui'
import { NavItem } from 'store/modules/nav/reducer'
import { swapNavItems, toggleAppSections } from 'store/modules/nav/actions'
import {
  reorderItems,
  replaceItems,
  moveItems,
  reorderSubitems,
  dropToFolder,
  dropFromFolder,
  dropFromFolderToFeatured,
} from 'utils/nav'
import AddNewSectionButton from './AddNewSectionButton'
import Block, { Types } from './Block'
import LayoutItem from './LayoutSidebarItem'

type Props = {
  isPlanner?: boolean
  initialItems: NavItem[]
}

type Item = NavItem & { subitems: NavItem[] }

type Items = {
  [Types.FEATURED]: Item[]
  [Types.MENU]: Item[]
  [Types.ADMIN]: Item[]
  [Types.DISABLED]: Item[]
}

const LayoutSidebar: FC<Props> = ({ isPlanner = false, initialItems }) => {
  const dispatch = useDispatch()
  const [items, setItems] = useState<Items>()

  const onDragStart = useCallback(
    ({ draggableId }: DragStart) => dispatch(toggleAppSections(draggableId)),
    [dispatch]
  )

  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination) {
        return
      }

      setItems((items) => {
        if (!items) {
          return undefined
        }

        const sourceParentId = !Object.values(Types).some(
          (type) => type === source.droppableId
        )
          ? parseInt(source.droppableId)
          : undefined
        const destParentId = !Object.values(Types).some(
          (type) => type === destination.droppableId
        )
          ? parseInt(destination.droppableId)
          : undefined

        // Nested items
        if (destParentId) {
          // Inside a folder
          if (sourceParentId === destParentId) {
            const type = Object.keys(items).find((type) =>
              items[type as Types].some(
                (item) => item.nav_item_id === source.droppableId
              )
            ) as Types

            const parent = items[type].find(
              (item) => item.nav_item_id === source.droppableId
            )

            if (!parent) {
              return items
            }

            dispatch(
              swapNavItems({
                nav_item_id: parent.subitems[source.index].nav_item_id,
                arrange:
                  parseInt(parent.subitems[destination.index].nav_item_id) *
                  (source.index > destination.index ? -1 : 1),
              })
            )

            return reorderSubitems(
              items,
              type,
              parent,
              source.index,
              destination.index
            ) as Items
          }

          // Drop to folder
          let sourceItem: Item | undefined
          let sourceType = source.droppableId as Types
          let sourceParent: Item | undefined

          if (!sourceParentId) {
            sourceItem = items[source.droppableId as Types][source.index]
          } else {
            sourceType = Object.keys(items).find((type) =>
              items[type as Types].some(
                (item) => item.nav_item_id === source.droppableId
              )
            ) as Types

            sourceParent = items[sourceType].find(
              (item) => item.nav_item_id === source.droppableId
            )

            sourceItem = sourceParent?.subitems[source.index]
          }

          if (!sourceItem) {
            return items
          }

          if (![101, 102].includes(sourceItem.type)) {
            return items
          }

          const destType = Object.keys(items).find((type) =>
            items[type as Types].some(
              (item) => item.nav_item_id === destination.droppableId
            )
          ) as Types

          const parent = items[destType].find(
            (item) => item.nav_item_id === destination.droppableId
          )

          if (parent) {
            const destItem = parent.subitems[destination.index]

            if (source.droppableId === Types.FEATURED) {
              if (!destItem) {
                return items
              }

              // Replace items
              dispatch(
                swapNavItems({
                  nav_item_id: sourceItem.nav_item_id,
                  replacewith: destItem.nav_item_id,
                })
              )

              return dropFromFolderToFeatured(
                items,
                destType,
                parent.nav_item_id,
                destination,
                source
              ) as Items
            }

            dispatch(
              swapNavItems({
                nav_item_id: sourceItem.nav_item_id,
                parent_nav_item_id: parent.nav_item_id,
                arrange: destItem
                  ? parseInt(destItem.nav_item_id, 10) * -1
                  : undefined,
                is_active: parent.is_active ? 1 : 0,
              })
            )

            return dropToFolder(
              items,
              sourceType,
              sourceItem,
              destType,
              destination.index,
              parent,
              source.index,
              sourceParentId ? source.droppableId : undefined
            ) as Items
          }
        }

        // Outside a folder
        if (sourceParentId) {
          const type = Object.keys(items).find((type) =>
            items[type as Types].some(
              (item) => item.nav_item_id === source.droppableId
            )
          ) as Types

          const parent = items[type].find(
            ({ nav_item_id }) => nav_item_id === source.droppableId
          )

          if (!parent) {
            return items
          }

          const sourceItem = parent.subitems[source.index]

          const destItem =
            items[destination.droppableId as Types][destination.index]

          if (destination.droppableId === Types.FEATURED) {
            if (destItem.type === 100) {
              return items
            }

            // Replace items
            dispatch(
              swapNavItems({
                nav_item_id: sourceItem.nav_item_id,
                replacewith: destItem.nav_item_id,
              })
            )

            return dropFromFolderToFeatured(
              items,
              type,
              parent.nav_item_id,
              source,
              destination
            ) as Items
          }

          dispatch(
            swapNavItems({
              nav_item_id: sourceItem.nav_item_id,
              arrange: parseInt(destItem.nav_item_id, 10) * -1,
              is_active: destItem.is_active ? 1 : 0,
            })
          )

          return dropFromFolder(items, source, destination) as Items
        }

        const sourceItem = items[source.droppableId as Types][source.index]
        const destinationItem =
          items[destination.droppableId as Types][destination.index]

        // Parent items
        if (
          destinationItem?.is_fixed ||
          (destination.droppableId === source.droppableId &&
            destination.index === source.index)
        ) {
          return items
        }

        if (source.droppableId === destination.droppableId) {
          dispatch(
            swapNavItems({
              nav_item_id: sourceItem.nav_item_id,
              arrange:
                parseInt(destinationItem.nav_item_id, 10) *
                (source.index > destination.index ? -1 : 1),
            })
          )

          return {
            ...items,
            [source.droppableId]: reorderItems(
              items[source.droppableId as Types],
              source.index,
              destination.index
            ),
          }
        }

        if (source.droppableId !== destination.droppableId) {
          if (
            (destination.droppableId === Types.FEATURED ||
              source.droppableId === Types.FEATURED) &&
            destinationItem
          ) {
            dispatch(
              swapNavItems({
                nav_item_id: sourceItem.nav_item_id,
                replacewith: destinationItem.nav_item_id,
              })
            )

            return {
              ...items,
              ...replaceItems(
                items[source.droppableId as Types],
                items[destination.droppableId as Types],
                source,
                destination
              ),
            }
          }

          dispatch(
            swapNavItems({
              nav_item_id: sourceItem.nav_item_id,
              arrange: destinationItem
                ? parseInt(destinationItem.nav_item_id, 10) * -1
                : 0,
              access_level: destination.droppableId === Types.ADMIN ? 1 : 0,
              is_active: destination.droppableId === Types.DISABLED ? 0 : 1,
            })
          )

          return {
            ...items,
            ...moveItems(
              items[source.droppableId as Types],
              items[destination.droppableId as Types],
              source,
              destination
            ),
          }
        }

        return items
      })
    },
    [dispatch]
  )

  useEffect(() => {
    const topLevelItems = initialItems
      .filter((item) => !item.parent_nav_item_id)
      .map((item) => ({
        ...item,
        subitems: initialItems.filter(
          ({ parent_nav_item_id }) => item.nav_item_id === parent_nav_item_id
        ),
      }))

    return setItems({
      [Types.FEATURED]: topLevelItems.filter(
        (item) => item.is_active && item.is_featured && item.access_level !== 1
      ),
      [Types.MENU]: topLevelItems.filter(
        (item) => item.is_active && !item.is_featured && item.access_level !== 1
      ),
      [Types.ADMIN]: topLevelItems.filter(
        (item) => item.is_active && item.access_level === 1
      ),
      [Types.DISABLED]: topLevelItems.filter((item) => !item.is_active),
    })
  }, [initialItems])

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Box pr="16px" pb="16px">
        <AddNewSectionButton />
      </Box>

      <Box width="100%" height="100%" overflowY="auto" pr="16px" mb="5px">
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {items &&
            Object.values(Types).map((type) => (
              <Block key={type} type={type}>
                <Droppable droppableId={type} isDropDisabled={isPlanner}>
                  {(provided) => (
                    <Box ref={provided.innerRef} minHeight="20px">
                      {items[type].map((item, index) => (
                        <Draggable
                          key={item.nav_item_id}
                          draggableId={item.nav_item_id}
                          index={index}
                          isDragDisabled={item.is_fixed || isPlanner}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <LayoutItem
                                isPlanner={isPlanner}
                                isDragging={snapshot.isDragging}
                                {...item}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Block>
            ))}
        </DragDropContext>
      </Box>
    </Box>
  )
}

export default LayoutSidebar
