import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import {
  path,
  pathOr,
  map,
  append,
  reject,
  equals,
  isEmpty,
  findIndex,
  propEq,
} from 'ramda'
import { toast } from 'react-toastify'

import { loadPhotos, loadAlbums } from 'store/modules/albums/actions'
import {
  // getIsLoaded,
  getIsAlbumLoaded,
  getAlbum,
  getPhotos,
} from 'store/modules/albums/selectors'
import { Photo as PhotoProps } from 'store/modules/albums/reducer'
import { Loading, Text, Box } from 'components/ui'
import {
  PhotosBackButton,
  SelectAllButton,
  Photo,
  AddPhotoButton,
  SelectedPhotosBar,
  ShareAlbumButton,
  PhotoViewer,
} from 'components/Albums'
// import { getUploadings } from 'store/modules/photos/selectors'
import { ALBUMS } from 'constants/paths'

const AlbumPhotos = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const { wuid, albumId } = useParams<{ wuid: string; albumId?: string }>()

  const [isLoading, setIsLoading] = useState(false)
  const [selectedPhotos, setSelectedPhotos] = useState<PhotoProps[]>([])
  const [selectedPhoto, selectPhoto] = useState<string>()

  // const isAlbumsLoaded = useSelector(getIsLoaded)
  const isPhotosLoaded = useSelector(getIsAlbumLoaded)
  const album = useSelector(getAlbum)
  const photos = useSelector(getPhotos)
  // const uploadings = useSelector(getUploadings)

  const isFavorites = equals(pathOr('', ['album_title'], album), 'Favorites')

  const onLoadAlbums = useCallback(async () => dispatch(loadAlbums()), [
    dispatch,
  ])
  const onLoadPhotos = useCallback(async () => dispatch(loadPhotos(Number(albumId))), [
    dispatch,
    albumId,
  ])

  const handleTogglePhoto = (photo: PhotoProps) => () =>
    setSelectedPhotos(
      equals(findIndex(propEq('photo_id', photo.photo_id), selectedPhotos), -1)
        ? append(photo, selectedPhotos)
        : reject(propEq('photo_id', photo.photo_id), selectedPhotos),
    )

  const handleSelectPhoto = (
    photoId: PhotoProps['photo_id'] | undefined,
  ) => () => selectPhoto(photoId)

  const handleClearSelectedPhotos = () => setSelectedPhotos([])

  const handleSelectAllPhotos = () => setSelectedPhotos(photos)

  const handleDeselectAllPhotos = () => setSelectedPhotos([])

  const handleClickBack = () => history.push(ALBUMS(wuid))

  const handleLoadPhotos = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoadPhotos()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onLoadPhotos])

  const handleLoadAlbums = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoadAlbums()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onLoadAlbums])

  useEffect(() => {
    if (!isLoading) {
      if (!album) handleLoadAlbums()
      if (!isPhotosLoaded) handleLoadPhotos()
    }
  }, [
    isLoading,
    isPhotosLoaded,
    album,
    handleLoadAlbums,
    handleLoadPhotos,
  ])

  const renderContent = () => {
    if (isLoading) return <Loading />

    return (
      <>
        <Box display='flex' flexWrap='wrap'>
          {/*!isEmpty(uploadings) &&
            map(
              (uploading) => (
                <PhotoLoadingIndicator
                  key={uploading.hash}
                  progress={uploading.progress}
                />
              ),
              uploadings
            )*/}

          {map(
            (photo) => (
              <Box key={photo.photo_id} width='256px' pr='4px' pb='4px'>
                <Photo
                  {...photo}
                  isSelected={
                    !equals(
                      findIndex(
                        propEq('photo_id', photo.photo_id),
                        selectedPhotos,
                      ),
                      -1,
                    )
                  }
                  onClickSelectButton={handleTogglePhoto(photo)}
                  onClickPhoto={handleSelectPhoto(photo.photo_id)}
                />
              </Box>
            ),
            photos,
          )}
        </Box>

        {selectedPhotos.length > 0 && (
          <SelectedPhotosBar
            albumId={albumId}
            isFavorites={isFavorites}
            selectedPhotos={selectedPhotos}
            onClickClear={handleClearSelectedPhotos}
          />
        )}
      </>
    )
  }

  return (
    <Box mt={0} ml='56px' maxWidth='1024px'>
      <PhotoViewer
        photos={[]}
        isFavorites={isFavorites}
        defaultPhotoId={selectedPhoto}
        isOpen={!!selectedPhoto}
        onClose={handleSelectPhoto(undefined)}
      />

      <PhotosBackButton onClickButton={handleClickBack} />

      <Box display='flex' mb={2}>
        <Text fontFamily='1' fontSize={4} fontWeight={600}>
          {path(['album_title'], album)}
        </Text>
      </Box>

      <Box display='flex' justifyContent='space-between' mb={3}>
        <Box display='flex'>
          <AddPhotoButton wuid={wuid} albumId={albumId} />

          <Box ml={0}>
            <ShareAlbumButton albumId={albumId} />
          </Box>
        </Box>

        <SelectAllButton
          isActive={!isEmpty(selectedPhotos)}
          onClick={
            isEmpty(selectedPhotos)
              ? handleSelectAllPhotos
              : handleDeselectAllPhotos
          }
        />
      </Box>

      {renderContent()}
    </Box>
  )
}

export default AlbumPhotos
