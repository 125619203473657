import React, { LabelHTMLAttributes, useEffect, useState } from 'react'
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
  getZipCode,
} from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'

// Components
import { Box, Input } from 'components/ui'
import Text from 'components/ui/Text'

// Utils
import { OptionBoxStyled, OptionStyled, TextStyled } from '../utils'
import styled from 'styled-components'
import { TypographyProps } from 'styled-system'


const Label = styled(Text).attrs({
  as: 'label',
})<LabelHTMLAttributes<HTMLLabelElement> & TypographyProps>`
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #73737B;
`

// @ts-ignore
const Autocomplete = ({ isLoaded, onSelect, locationValue, setLocationValue, selectedValue, name }) => {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    init,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    debounce: 300,
  })

  const [suggestionsData, setSuggestionsData] = useState([])
  const [selectedVal, setSelectedVal] = useState<string>()

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  const locationCopy = {
    location_address: '',
    location_name: '',
    location_state: '',
    location_zip: '',
    location_lat: 0,
    location_lon: 0,
    inputValue: '',
  }

  useEffect(() => {
    setSelectedVal(selectedValue)
  }, [selectedValue])

  useEffect(() => {
    if (status === 'OK') {
      const suggestionAddresses: any = []
      data.forEach(({ place_id: placeId, description }) => {
        suggestionAddresses.push(getDetails({
          placeId,
          fields: ['name', 'formatted_address', 'address_component', 'geometry', 'place_id', 'types'],
        }))
      })
      Promise.all(suggestionAddresses).then((results: any) => {
        setSuggestionsData(results.map((result: any) => result))
      })
    }
  }, [status, data])

  useEffect(() => {
    if (isLoaded) {
      init()
    }
  }, [isLoaded, init])

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    const inputVal = e.target.value
    setValue(e.target.value)
    setSelectedVal(e.target.value)
    locationCopy.inputValue = inputVal
    locationCopy.location_name = inputVal
    setLocationValue(locationCopy)
  }

  const handleSelect =
    (suggestion: any) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(`${suggestion.name}, ${suggestion?.formatted_address}`, false)
        setSelectedVal(`${suggestion.name}, ${suggestion?.formatted_address}`)
        clearSuggestions()

        let locationValueCopy = { ...locationValue }

        const parameter = {
          address: suggestion?.formatted_address,
        }

        getGeocode(parameter)
          .then((results) => {
            const zipCode = getZipCode(results[0], false)
            locationValueCopy.location_zip = zipCode ?? ''
          })


        let cities = suggestion.address_components.filter((address_component: { types: string | string[] }) =>
          ['locality', 'colloquial_area'].some(word => ~address_component.types.indexOf(word)))

        if (!cities || !cities.length) {
          console.log('Could not resolve city name.')
        } else {
          locationValueCopy.location_city = cities[0].long_name
        }

        let states = suggestion.address_components.filter((address_component: { types: string | string[] }) =>
          ['administrative_area_level_1', 'political'].some(word => ~address_component.types.indexOf(word)))

        if (!states || !states.length) {
          console.log('Could not resolve state name.')
        } else {
          locationValueCopy.location_state = states[0].long_name
        }

        locationValueCopy.location_address = `${suggestion.name}, ${suggestion?.formatted_address}`
        locationValueCopy.location_name = suggestion.name

        const { lat, lng } = getLatLng(suggestion)
        console.log('📍 Coordinates: ', { lat, lng })
        onSelect({ lat, lng })
        locationValueCopy.location_lat = lat
        locationValueCopy.location_lon = lng

        setLocationValue(locationValueCopy)
      }

  return (
    <Box
      ref={ref}
    >
      <Box style={{ marginBottom: '4px', marginLeft: '4px' }}>
        <Label htmlFor='venue'>
          Venue
        </Label>
      </Box>
      <Input
        id='venue'
        type='text'
        value={selectedVal && selectedVal?.length > 0 ? selectedVal : ''}
        onChange={handleInput}
        disabled={!ready}
        name={name}
        placeholder='The Old Post Office, Chicago, IL 60610'
      >

      </Input>
      {status === 'OK' &&
      <OptionBoxStyled>
        {suggestionsData.map((suggestion: any) => (
          <OptionStyled
            key={suggestion?.formatted_address}
            onClick={handleSelect(suggestion)}
          >
            <TextStyled>
              <Text
                fontFamily='Source Sans Pro'
                fontSize='14px'
                lineHeight='20px'
                color='#414042'
              >
                {`${suggestion.name}, ${suggestion?.formatted_address}`}
              </Text>
            </TextStyled>
          </OptionStyled>
        ))}
      </OptionBoxStyled>}
    </Box>
  )
}

export { Autocomplete }