import styled from 'styled-components'
import { Box } from 'components/ui'

export type tabTypes = '0' | '1' | '2';

export const FaqOptions: { name: tabTypes, title: string }[] = [
  {
    title: 'All Questions',
    name: '0',
  },
  {
    title: 'Not Answered',
    name: '1',
  },
]

export const ContentPopular = styled(Box)`
  width: 100%;
  max-height: calc(100vh - 262px);
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`
export const BorderSetting = styled(Box)`
  border-right: 1px solid #CBCBD3;
  opacity: 40%;
  transform: rotate(-180deg);
  margin: 0 16px;
`

export const commonlyAskedQuestions: any = [
  {
    id: '1',
    question: 'Are masks required? Will they be provided?',
  },
  {
    id: '2',
    question: 'How do I get to the venue?',
  },
  {
    id: '3',
    question: 'Will food be served?',
  },
  {
    id: '4',
    question: 'Do you have a gift registry?',
  },
  {
    id: '5',
    question: 'Can I bring a plus one?',
  },
  {
    id: '6',
    question: 'Who can I contact with any additional questions?',
  },
]

export const QuestionBoxParent = styled(Box)`
  width: 100%;
  position: relative;
  height: 123px;
  margin-bottom: 42px;
  margin-top: 12px;
`

export const QuestionBox = styled(Box).attrs(({
                                                fromTop,
                                                fromBottom,
                                                type,
                                              }: { fromTop: boolean, fromBottom: boolean, type: string }) => {
  let top = fromBottom ? 'unset' : fromTop ? '165px' : '0px !important'
  let bottom = fromTop ? 'unset' : fromBottom ? '165px' : '0px !important'

  if (type === 'down') {
    [top, bottom] = [bottom, top]
  }

  return {
    position: fromTop || fromBottom ? 'absolute' : 'relative',
    top,
    bottom,
  }
})`
  width: 100%;
  transition: 0.5s all ease;
  background: #fff;
`
