import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'

import Box from './Box'

const dotAnimation = keyframes`
  0% {
    opacity: .4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .4;
  }
`

const Dot = styled(Box)`
  animation: ${dotAnimation} 1.5s infinite ease-in-out;
  border-radius: 50%;
  display: inline-block;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;

  &:not(:last-child) {
    margin-right: 3px;
  }

  &:nth-child(2) {
    animation-delay: 0.5s;
  }

  &:nth-child(3) {
    animation-delay: 1s;
  }
`

type Props = {
  size?: number | string
  bg: string
}

const LoadingDots: FC<Props> = ({ size = 4, bg }) => (
  <Box display="flex">
    <Dot size={size} bg={bg} />
    <Dot size={size} bg={bg} />
    <Dot size={size} bg={bg} />
  </Box>
)

export default LoadingDots
