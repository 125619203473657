import React, { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'

import { loadPosts } from 'store/modules/posts/actions'
import { getIsLoaded, getPosts } from 'store/modules/posts/selectors'
import { Box, Loading } from 'components/ui'
import { WeddingDetails, Post, CreatePost } from 'components/Home'
import { sortByPinnedDate } from 'utils/posts'

const Home: FC = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setError] = useState(false)
  const posts = sortByPinnedDate(useSelector(getPosts))
  const isLoaded = useSelector(getIsLoaded)
  const page = pathOr(0, ['pageNum'], isLoaded)
  const perPage = pathOr(10, ['perPage'], isLoaded)
  const totalItems = pathOr(0, ['totalItems'], isLoaded)

  const onLoadPosts = useCallback(
    async (page) => dispatch(loadPosts({ page_num: page })),
    [dispatch]
  )
  const handleLoadPosts = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoadPosts(page + 1)
    } catch (error) {
      toast(error.message)
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }, [onLoadPosts, page])
  const handleScroll = useCallback(
    (e) => {
      if (
        e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight &&
        !isLoading &&
        totalItems > page * perPage
      ) {
        handleLoadPosts()
      }
    },
    [handleLoadPosts, isLoading, totalItems, page, perPage]
  )

  useEffect(() => {
    if (!isError && !isLoaded && !isLoading) {
      handleLoadPosts()
    }
  }, [isLoaded, isError, isLoading, handleLoadPosts])

  return (
    <Box overflowY="auto" height="100%" width="100%" onScroll={handleScroll}>
      <Box px={2} pt={3} pb={4} maxWidth="1066px">
        <Box display="flex" mb={1}>
          <Box width={1 / 2} pr="10px">
            <WeddingDetails />
          </Box>

          <Box width={1 / 2} pl="10px">
            <Box
              border="1px solid"
              borderColor="gray"
              borderRadius={0}
              height="100%"
              width="100%"
            />
          </Box>
        </Box>

        <Box display="flex">
          <Box width={1 / 2} pr="10px">
            {posts.map((post) => (
              <Post key={post.post_id} {...post} />
            ))}

            {isLoading && (
              <Box position="relative" height="120px">
                <Loading />
              </Box>
            )}
          </Box>

          <Box width={1 / 2} pl="10px">
            <CreatePost />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Home
