import { Button } from '../../components/ui'
import { ReceptionDinnerBtn } from './utils'
import React from 'react'

interface PropType {
  menuName: string;
  isSelected: boolean;
  setSelectMenu: any;
  menu_id: string
}

const SelectMenuBtn = ({ menu_id, menuName, isSelected, setSelectMenu }: PropType) => {

  const handleSelectMenu = () => {
    setSelectMenu({
      name: menuName,
      id: menu_id,
    })
  }

  return (
    <ReceptionDinnerBtn
      style={{
        borderBottom: isSelected ? '3px solid #5458F7' : '3px solid lightGray',
      }}
      onClick={handleSelectMenu}>
      <Button className='btnTitle'>{menuName}</Button>
    </ReceptionDinnerBtn>
  )
}

export default SelectMenuBtn