import React, { FC, ComponentType } from 'react'
import ReactGoogleMapLoader from 'react-google-maps-loader'

import { Loading } from 'components/ui'

export const withGoogleMaps = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props) => (
  <ReactGoogleMapLoader
    params={{
      key: process.env.REACT_APP_GMAP_KEY,
      libraries: 'places,geocode',
    }}
    render={(googleMaps: any) => {
      if (!googleMaps) return <Loading />

      return <Component googleMaps={googleMaps} {...props} />
    }}
  />
)

export const parseSuggestion = (suggest: any) => {
  const filterByType = (type: string, prop: string) => {
    const f = suggest.address_components.filter((a: any) =>
      a.types.reduce((m: boolean, t: string) => m || t === type, false)
    )
    return f.length ? f[0][prop] : ''
  }

  return {
    address: `${filterByType('route', 'long_name')} ${filterByType(
      'street_number',
      'long_name'
    )}`,
    city: filterByType('locality', 'long_name'),
    state: filterByType('administrative_area_level_1', 'short_name'),
    zip: filterByType('postal_code', 'long_name'),
  }
}
