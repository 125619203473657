import { CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import {
  typography,
  TypographyProps,
  color,
  ColorProps,
  system,
} from 'styled-system'

type Props = TypographyProps &
  ColorProps & {
    isFullWidth?: boolean
    isTruncate?: boolean
    isCapitalize?: boolean
    isUppercase?: boolean
    isUnderline?: boolean
    isSelectable?: boolean
    maxWidth?: CSSProperties['maxWidth']
    minWidth?: CSSProperties['minWidth']
  }

const Text = styled.span<Props>`
  ${typography}
  ${color}

  ${system({
    minWidth: true,
    maxWidth: true,
  })}

  ${(props) =>
    props.isFullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.isTruncate &&
    css`
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${(props) =>
    props.isCapitalize &&
    css`
      text-transform: capitalize;
    `}

    ${(props) =>
      props.isUppercase &&
      css`
        text-transform: uppercase;
      `}

  ${(props) =>
    props.isUnderline !== undefined &&
    css`
      text-decoration: ${props.isUnderline ? 'underline' : 'none'};
    `}

  ${(props) =>
    !props.isSelectable &&
    css`
      user-select: none;
    `}
`

Text.defaultProps = {
  fontFamily: '0',
  fontSize: 0,
  fontWeight: 400,
  color: 'black',
  isSelectable: true,
}

export default Text
