import React, { FC, useCallback, memo, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { pathOr } from 'ramda'

import { NavItem } from 'store/modules/nav/reducer'
import { toggleAppSections } from 'store/modules/nav/actions'
import { appSectionsExpanded } from 'store/modules/nav/selectors'
import { Box, Text } from 'components/ui'
import { WEDDING, GUESTS, AGENDA, ALBUMS, FAQ, MESSAGES, SEATING } from 'constants/paths' // prettier-ignore
import Item from './Item'
import NestedItem from './NestedItem'

export enum ItemTypes {
  CARD = 'card',
}

const PATHS = {
  activity: 1,
  guests: 3,
  agenda: 4,
  albums: 5,
  faq: 6,
  messages: 7,
  seating: 8,
}

type Props = {
  isPlanner: boolean
  isDragging: boolean
  subitems: NavItem[]
}

const LayoutSidebarItem: FC<Partial<NavItem> & Props> = memo(
  ({
    nav_item_id,
    isPlanner,
    type,
    caption,
    is_fixed,
    icon,
    isDragging,
    subitems,
  }) => {
    const dispatch = useDispatch()
    const { push, location } = useHistory()
    const { wuid } = useParams<{ wuid: string }>()
    const isAppSectionsExpanded = useSelector(appSectionsExpanded)

    const pageId = PATHS[pathOr('activity', [3], location.pathname.split('/'))]

    const isActive = useMemo(
      () =>
        isPlanner ? pageId === type : isAppSectionsExpanded === nav_item_id,
      [isPlanner, pageId, type, isAppSectionsExpanded, nav_item_id]
    )

    const onSelectItem = useCallback(
      (navItemId: string) => dispatch(toggleAppSections(navItemId)),
      [dispatch]
    )

    const handleClick = useCallback(() => {
      if (!isPlanner && nav_item_id) {
        return onSelectItem(nav_item_id)
      }

      switch (type) {
        case PATHS.activity:
          return push(WEDDING(wuid))
        case PATHS.guests:
          return push(GUESTS(wuid))
        case PATHS.agenda:
          return push(AGENDA(wuid))
        case PATHS.albums:
          return push(ALBUMS(wuid))
        case PATHS.faq:
          return push(FAQ(wuid))
        case PATHS.messages:
          return push(MESSAGES(wuid))
        case PATHS.seating:
          return push(SEATING(wuid))
        default:
          return null
      }
    }, [type, push, wuid, onSelectItem, isPlanner, nav_item_id])

    return (
      <Box
        pointerEvents={!isPlanner && is_fixed ? 'none' : undefined}
        bg={isActive || isDragging ? '#ffffff' : undefined}
        boxShadow={
          isActive || isDragging
            ? '0px 2px 2px rgba(53, 59, 96, 0.15)'
            : undefined
        }
        borderRadius={isActive || isDragging ? '10px' : undefined}
        width="247px"
      >
        <Item
          icon={icon}
          caption={caption}
          isFixed={isPlanner ? undefined : is_fixed}
          onClick={handleClick}
        />

        {type === 100 && nav_item_id && (
          <Droppable
            droppableId={nav_item_id}
            isDropDisabled={isActive}
            renderClone={(provided, snapshot, descriptor) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {snapshot.draggingOver &&
                isNaN(parseInt(snapshot.draggingOver)) ? (
                  <LayoutSidebarItem
                    {...subitems[descriptor.source.index]}
                    isPlanner={false}
                    isDragging
                  />
                ) : (
                  <NestedItem {...subitems[descriptor.source.index]} isActive />
                )}
              </div>
            )}
          >
            {(provided) => (
              <Box
                ref={provided.innerRef}
                position="relative"
                pl="33px"
                minHeight="40px"
              >
                {subitems.length === 0 && !isActive && !isDragging && (
                  <Box
                    display="flex"
                    alignItems="center"
                    height="40px"
                    pl="50px"
                    ml="-25px"
                    border="1px dashed #CBCBD3"
                    borderRadius="10px"
                  >
                    <Text fontWeight={600} fontSize="16px" color="#A3A3AC">
                      Drop Section
                    </Text>
                  </Box>
                )}

                {subitems.length > 0 && (
                  <>
                    <Box
                      width="4px"
                      bg="#CBCBD3"
                      borderRadius="4px"
                      position="absolute"
                      left="22px"
                      top="8px"
                      bottom="8px"
                    />

                    {subitems.map((subitem, index) => (
                      <Draggable
                        draggableId={subitem.nav_item_id}
                        key={subitem.nav_item_id}
                        index={index}
                        isDragDisabled={isActive || isDragging}
                      >
                        {(provided, snapshot) => (
                          <div
                            key={subitem.nav_item_id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <NestedItem
                              isActive={
                                isAppSectionsExpanded === subitem.nav_item_id ||
                                snapshot.isDragging
                              }
                              nav_item_id={subitem.nav_item_id}
                              caption={subitem.caption}
                              onSelectItem={onSelectItem}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </>
                )}

                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        )}
      </Box>
    )
  }
)

export default LayoutSidebarItem
