import React, { CSSProperties, FC } from 'react'
import { Box, Button, Text } from 'components/ui'

type Props = {
  options: { name: string; title: string }[];
  styles?: {
    container?: CSSProperties;
    tabBox?: CSSProperties;
    tabText?: CSSProperties;
  }
  setSelectedAccessMode?: Function;
  handleClick?: Function;
  activeTab?: string
}

const Tab: FC<Props> = ({
                          options, styles = {}, handleClick = () => {
  }, activeTab,
                        }) => {

  return (
    <Box
      display='flex'
      justifyContent='space-between'
    >
      <Box display='flex'>
        {options.map((option, index) => {
          return (
            <React.Fragment key={option.name}>
              <Button
                as='button'
                value=''
                height='40px'
                justifyContent='space-between'
                px='16px'
                bg='white'
                border='solid'
                borderWidth='1px'
                borderColor='#CBCBD3'
                borderRadius={index === 0 ? '8px 0px 0px 8px' : '0px 8px 8px 0px'}
                borderRight={index === 0 ? 'none' : ''}
                borderLeft={index === 1 ? 'none' : ''}
                backgroundColor={activeTab === option.name ? '#F3F3F6' : '#FFFFFF'}
                onClick={() => handleClick(option.name)}
              >
                <Text
                  fontWeight={600}
                  fontSize='16px'
                  lineHeight='20px'
                  color='#353B60'
                >
                  {option.title}
                </Text>
              </Button>

              {index === 0 && (
                <Box
                  height='40px'
                  width='1px'
                  bg='#CBCBD3'
                />
              )}
            </React.Fragment>
          )
        })
        }
      </Box>
    </Box>
  )
}

export default Tab