import React, { useMemo, CSSProperties } from 'react'

import { SChartItem } from 'store/modules/seatings/reducer'

type Props = {
  type: SChartItem['type']
  width: SChartItem['width']
  height: SChartItem['height']
  caption: SChartItem['caption']
  rotation: SChartItem['rotation']
}

const Caption = ({ type, width, height, caption, rotation }: Props) => {
  const style = useMemo<CSSProperties>(
    () => ({
      fontWeight: 600,
      fontSize: 14,
      fill: '#73737B',
      fontFamily: 'Source Sans Pro',
      textAnchor: 'middle',
      alignmentBaseline: 'central',
      dominantBaseline: 'middle',
      transform: `rotate(${-rotation}deg)`,
      transformOrigin: 'center center',
      transformBox: 'fill-box',
    }),
    [rotation]
  )

  const { x, y } = useMemo(() => {
    if (type === 'halfcircle') {
      const x = Math.round(width / 2)

      return { x, y: Math.round(x / 2) }
    }

    if (type === 'circle') {
      return { x: 0, y: 0 }
    }

    return { x: Math.round(width / 2), y: Math.round(height / 2) }
  }, [type, width, height])

  return (
    <text x={x} y={y} style={style}>
      {caption}
    </text>
  )
}

export default Caption
