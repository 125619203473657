import React from 'react'

import { Box } from 'components/ui'
import { ReactComponent as Background } from './pattern.svg'
import { ReactComponent as Background2 } from './pattern2.svg'

const Pattern = () => (
  <Box
    height="112px"
    bg="#DDDEFD"
    width="100%"
    overflow="hidden"
    position="relative"
    borderTopLeftRadius="6px"
    borderTopRightRadius="6px"
  >
    <Background />

    <Box position="absolute" top="0px" right="0px">
      <Background2 />
    </Box>
  </Box>
)

export default Pattern
