import React, { FC } from 'react'
import styled from 'styled-components'

import { Box, Button as BaseButton, Text } from 'components/ui'

type Props = {
  active: string,
  handleChangeType: (active: string) => void,
  labels: [string, string]
}
type ButtonProps = { active: boolean, isFirst?: boolean }

const Button = styled(BaseButton).attrs((props: ButtonProps) => ({
  border: 'solid',
  padding: '10px 16px !important',
  borderWidth: props.active ? 1 : 1,
  borderColor: '#CBCBD3',
  borderTopLeftRadius: props.isFirst ? 8 : '0 !important',
  borderBottomLeftRadius: props.isFirst ? 8 : '0 !important',
  borderTopRightRadius: props.isFirst ? '0 !important' : 8,
  borderBottomRightRadius: props.isFirst ? '0 !important' : 8,
  fontSize: 1,
  fontWeight: 600,
  px: 2,
  fontFamily: 0,
  backgroundColor: props.active ? '#F3F3F6' : '#FFFFFF',
  whiteSpace: 'nowrap',
}))<ButtonProps>``

const Font = styled(Text)`
  white-space: nowrap;
  font-family: 'Source Sans Pro';
`

const GroupButton: FC<Props> = ({ active, handleChangeType, labels }) => {
  return (
    <Box display='flex' height='40px'>
      <Button active={active === labels[0]} isFirst={true} onClick={() => handleChangeType(labels[0])} height='100%'
              borderRightWidth='0 !important'>
        <Font lineHeight='20px' fontWeight={600} fontSize='16px' color='#353B60'>
          {labels[0]}
        </Font>
      </Button>
      <Box height='100%' width='1px' backgroundColor='#CBCBD3' />
      <Button active={active === labels[1]} isFirst={false} onClick={() => handleChangeType(labels[1])} height='100%'
              borderLeftWidth='0 !important'>
        <Font lineHeight='20px' fontWeight={600} fontSize='16px' color='#353B60'>
          {labels[1]}
        </Font>
      </Button>
    </Box>
  )
}

export default GroupButton
