import { InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import Box from './Box'

const SIZE: {
  [key: string]: { width: number; mt: number; trackHeight: number }
} = {
  big: {
    width: 24,
    mt: -8,
    trackHeight: 8,
  },
  medium: {
    width: 20,
    mt: -7,
    trackHeight: 7,
  },
  small: {
    width: 16,
    mt: -4,
    trackHeight: 8,
  },
}

type Props = {
  variant: 'big' | 'medium' | 'small'
}

const thumbStyle = css<Props>`
  pointer-events: auto;
  width: ${(props) => SIZE[props.variant].width}px;
  height: ${(props) => SIZE[props.variant].width}px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  cursor: pointer;
  margin-top: ${(props) => SIZE[props.variant].mt}px;
`

const trackStyle = css<Props>`
  width: 100%;
  height: ${(props) => SIZE[props.variant].trackHeight}px;
  border-radius: 17px;
  background-color: #dddefd;
`

const Slider = styled(Box).attrs<Props>({
  as: 'input',
  type: 'range',
  width: '100%',
})<InputHTMLAttributes<HTMLInputElement> & Props>`
  -webkit-appearance: none;
  pointer-events: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${thumbStyle}
  }
  &::-moz-range-thumb {
    ${thumbStyle}
  }
  &::-ms-thumb {
    ${thumbStyle}
  }

  &::-webkit-slider-runnable-track {
    ${trackStyle}
  }
  &::-moz-range-track {
    ${trackStyle}
  }
  &::-ms-track {
    ${trackStyle}
  }
`

export default Slider
