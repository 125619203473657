import React, { FC, useState, useCallback, FormEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { map, filter, test, equals, compose, pathOr } from 'ramda'

import { Box, Button } from 'components/ui'
import { ReactComponent as NewMessageIcon } from 'images/edit.svg'
import { getRooms, getSelectedRoom } from 'store/modules/messages/selectors'
import { selectRoom } from 'store/modules/messages/actions'
import SearchInput from './SearchInput'
import Room from './Room'

type Props = {
  onCloseNewChat: () => void
  onClickNewChatButton: () => void
}

const MessagesSidebar: FC<Props> = ({
  onCloseNewChat,
  onClickNewChatButton,
}) => {
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const rooms = useSelector(getRooms)
  const selectedRoom = useSelector(getSelectedRoom)
  const filteredRooms = filter(
    compose(test(new RegExp(`${searchValue}`, 'gi')), pathOr('', ['name'])),
    rooms
  )

  const handleSelectRoom = useCallback(
    (roomId) => dispatch(selectRoom(roomId)),
    [dispatch]
  )
  const handleChangeSearch = (e: FormEvent<HTMLInputElement>) =>
    setSearchValue(e.currentTarget.value)

  return (
    <Box pl={0} borderRight="1px solid" borderRightColor="gray" height="100%">
      <Box
        display="flex"
        alignItems="center"
        pl="12px"
        pr={1}
        height="60px"
        pt={0}
      >
        <SearchInput onChange={handleChangeSearch} />

        <Button ml={1} border="none" px="0px" onClick={onClickNewChatButton}>
          <NewMessageIcon width="24px" height="24px" stroke="#5458f7" />
        </Button>
      </Box>

      <Box height="calc(100% - 60px)" overflowY="auto">
        {map((room) => {
          const isActive = !!selectedRoom && equals(selectedRoom.rid, room.rid)
          const onSelectRoom = () => {
            onCloseNewChat()
            handleSelectRoom(isActive ? null : room.rid)
          }

          return (
            <Room
              key={room.rid}
              {...room}
              isActive={isActive}
              onSelectRoom={onSelectRoom}
            />
          )
        }, filteredRooms)}
      </Box>
    </Box>
  )
}

export default MessagesSidebar
