import { createElement, useMemo, SVGAttributes } from 'react'

import icons from './icons'

type Props = {
  name?: string
}

const NavIcon = ({
  name,
  width = 24,
  height = 24,
  strokeWidth = 3, // 1.5, 3, 4
}: Props & SVGAttributes<HTMLOrSVGElement>) => {
  const icon = useMemo(
    () => icons.find((v) => v.name === name)?.icon || icons[0].icon,
    [name]
  )

  return createElement(icon, { width, height, strokeWidth })
}

export default NavIcon
