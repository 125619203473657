import React, { FC } from 'react'
import styled from 'styled-components'

import { Box, Text } from 'components/ui'
import { ReactComponent as InfoIcon } from 'images/info-circle.svg'

const Row = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
})`
  line-height: 1;
`

type Props = {
  email?: string
  phone?: string
}

const InfoBlock: FC<Props> = ({ email, phone }) => (
  <Box
    py={2}
    px="10px"
    borderBottom="1px solid"
    borderColor="gray"
    display="inline-flex"
    width="100%"
  >
    <InfoIcon stroke="#858ea2" />

    <Box ml={3}>
      <Row mb={2}>
        <Box mb="6px">
          <Text fontSize="15px" color="fullBlack">
            {email}
          </Text>
        </Box>

        <Text fontSize="13px" color="darkGray">
          Email
        </Text>
      </Row>

      <Row>
        <Box mb="6px">
          <Text fontSize="15px" color="fullBlack">
            {phone}
          </Text>
        </Box>

        <Text fontSize="13px" color="darkGray">
          Phone Number
        </Text>
      </Row>
    </Box>
  </Box>
)

export default InfoBlock
