import React, { FC, useState, useCallback } from 'react'
import DayPicker from 'react-day-picker/DayPickerInput'
import {
  DayPickerInputProps,
  DayPickerProps,
  DateUtils,
} from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { format as formatFns, parse, isDate, parseISO } from 'date-fns'
import { path } from 'ramda'

import Wrapper from './Wrapper'
import MobileCalendar from './MobileCalendar'
import Chevron from './Chevron'
import Box from '../Box'
import InputComponent from '../Input'

type Props = {
  overlayPosition?: 'right' | 'bottom'
  disabled?: boolean
  hideValue?: boolean
  disabledDays?: DayPickerProps['disabledDays']
  height?: string | number
  onDateChange: (value: Date) => void
}

const DayPickerInput: FC<DayPickerInputProps & Props> = ({
                                                           disabledDays,
                                                           placeholder,
                                                           disabled = false,
                                                           value,
                                                           overlayPosition = 'bottom',
                                                           hideValue = false,
                                                           format,
                                                           height,
                                                           onDateChange,
                                                         }) => {
  const [isMobile] = useState(window.innerWidth < 640)
  const FORMAT = 'EEEE, MMMM d, yyyy'
  const parseDate = useCallback((str: string, format: string) => {
    const parsed = parse(str, format, new Date())

    return isDate(parsed) ? parsed : undefined
  }, [])

  const formatDate = useCallback(
    (date: Date, formatValue: string) => formatFns(date, formatValue),
    [],
  )

  const getMin = useCallback(() => {
    const before = path<Date>(['before'], disabledDays)

    return before && DateUtils.isDate(before)
      ? formatFns(before, 'yyyy-MM-dd')
      : undefined
  }, [disabledDays])

  const handleChange = useCallback(
    (e) => {
      const date = parseDate(e.currentTarget.value, 'yyyy-MM-dd')

      if (date) {
        onDateChange(date)
      }
    },
    [parseDate, onDateChange],
  )

  const css = `
  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #F7F7FC;
  }

  .DayPicker-Caption > div {
    font-family: 'Source Sans Pro' !important;
    font-size: 16px !important;
  }

  DayPicker-Weekday > abbr {
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
  }

  .DayPicker-Day {
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
  }
 `

  return (
    <Wrapper overlayPosition={overlayPosition}>
      {isMobile && (
        <MobileCalendar
          as='input'
          value={
            !hideValue && value && typeof value !== 'string'
              ? formatFns(value, 'yyyy-MM-dd')
              : ''
          }
          min={getMin()}
          onChange={handleChange}
        />
      )}
      <style>{css}</style>
      <DayPicker
        component={InputComponent}
        dayPickerProps={{
          disabledDays,
          weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          firstDayOfWeek: 1,
          navbarElement: ({ onPreviousClick, onNextClick }) => (
            <Box display='flex' position='absolute' top='8px' right='22px'>
              <Chevron onClick={() => onPreviousClick()} />

              <Chevron onClick={() => onNextClick()} />
            </Box>
          ),
        }}
        inputProps={{
          readOnly: true,
          autoComplete: 'off',
          placeholder,
          disabled,
          height,
        }}
        formatDate={formatDate}
        format={format || FORMAT}
        parseDate={parseDate}
        value={
          hideValue
            ? ''
            : value && typeof value === 'string'
              ? parseISO(value)
              : value
        }
        onDayChange={onDateChange}
        showOverlay={isMobile ? false : undefined}
      />
    </Wrapper>
  )
}

export default DayPickerInput
