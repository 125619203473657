import { join } from 'ramda'

import { LOAD_AGENDA_ITEMS } from '../actionTypes'

export const loadAgendaItems = () => ({
  type: LOAD_AGENDA_ITEMS.name,
  api: {
    url: 'agenda_items/list',
    data: {
      field_list: join(',', [
        'agenda_item_id',
        'title',
        'start_date',
        'start_time',
        'end_date',
        'end_time',
        'attire',
        'location_name',
        'location_address',
        'location_city',
        'location_state',
        'location_zip',
        'location_lat',
        'location_lon',
        'notes',
        'url',
        'log',
        'access_mode',
        'guest_whitelist',
      ]),
    },
  },
})
