import { times, toString } from 'ramda'

export const PLUS_ONE_COUNT_OPTIONS = [
  { label: 'Primary Guest Only', value: '0' },
  ...times(
    (index) => ({
      label: `Primary Guest + ${index + 1}`,
      value: toString(index + 1),
    }),
    5
  ),
]

export const RSVP_STATUS_OPTIONS = [
  { label: 'Pending', value: '0', color: '#fbb64f' },
  { label: 'Accepted', value: '1', color: '#06d6a0' },
  { label: 'Declined', value: '2', color: '#f44343' },
]
