import React from 'react'

import { Box } from 'components/ui'
import SortSelect from './SortSelect'
import Switch from './Switch'
import SearchInput from './SearchInput'

const NavBar = () => (
  <Box
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
  >
    <Box display="flex">
      <Box width="142px" mr="12px">
        <SortSelect />
      </Box>

      <Switch />
    </Box>

    <SearchInput />
  </Box>
)

export default NavBar
