import { JOIN_TO_WEDDING } from '../actionTypes'

export const joinToWedding = (inviteCode?: string) => ({
  type: JOIN_TO_WEDDING.name,
  api: {
    url: 'wedding/join',
    method: 'post',
    data: { invite_code: inviteCode },
  },
})
