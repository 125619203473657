import React, { useState, useCallback, MouseEvent } from 'react'
import styled from 'styled-components'

import { Box, Text, Button } from 'components/ui'
import { ReactComponent as PlannerIcon } from './planner.svg'
import { ReactComponent as ChevronIcon } from './chevron.svg'
import { ReactComponent as GuestListIcon } from './guest-list.svg'
import { ReactComponent as MenuIcon } from './menu.svg'
import { ReactComponent as SeatingChartIcon } from './seating-chart.svg'
import { ReactComponent as ScheduleIcon } from './schedule.svg'
import { ReactComponent as MomentsIcon } from './moments.svg'
import { ReactComponent as QaIcon } from './qa.svg'
import { Wrapper } from './Tab'

const items = [
  [
    {
      name: 'guestList',
      title: 'Guest List',
      text:
        'Build your guest list, track RSVPs, food choices, gifts, and more.',
      icon: GuestListIcon,
    },
    {
      name: 'seatingChart',
      title: 'Seating Chart',
      text:
        'Upload or create a new floorplan and assign guests to their seats.',
      isNew: true,
      icon: SeatingChartIcon,
    },
    {
      name: 'menu',
      title: 'Menu',
      text: 'Track menu selections and dietary restrictions for your guests.',
      icon: MenuIcon,
    },
  ],
  [
    {
      name: 'schedule',
      title: 'Schedule',
      text:
        'Plan your schedule from arrival to departure to keep guests on time.',
      icon: ScheduleIcon,
    },
    {
      name: 'moments',
      title: 'Moments',
      text: 'Capture and share moments with everyone on your special day.',
      icon: MomentsIcon,
    },
    {
      name: 'faq',
      title: 'Q & A',
      text: 'Create a helpful FAQ or allow guests to ask their own questions.',
      icon: QaIcon,
    },
  ],
]

const Item = styled(Button)`
  &:hover {
    background: #f7f7fc;
  }
`

type Props = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const PlannerTab = ({ onClick }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = useCallback(() => setIsOpen(true), [])
  const onClose = useCallback(() => setIsOpen(false), [])

  const handleClickItem = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      console.log(e)
      onClick(e)

      setIsOpen(false)
    },
    [onClick],
  )

  const renderItem = useCallback(
    (item: typeof items[0][0]) => (
      <Item
        as='button'
        value={item.name}
        key={item.name}
        display='flex'
        mb='8px'
        py='12px'
        pl='12px'
        height='92px'
        width='100%'
        justifyContent='start'
        alignItems='start'
        textAlign='initial'
        bg='transparent'
        borderRadius='8px'
        onClick={handleClickItem}
      >
        <Box
          minWidth='48px'
          minHeight='48px'
          maxHeight='48px'
          mr='16px'
          bg='#DDDEFD'
          borderRadius='24px'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <item.icon />
        </Box>

        <Box>
          <Box mb='4px' display='flex' alignItems='center'>
            <Text
              fontWeight={600}
              fontSize='16px'
              lineHeight='24px'
              color='#353B60'
            >
              {item.title}
            </Text>

            {item.isNew && (
              <Box
                bg='#ECF8F8'
                borderRadius='16px'
                display='flex'
                justifyContent='center'
                alignItems='center'
                ml='8px'
                minHeight='24px'
                minWidth='55px'
              >
                <Text fontWeight={600} fontSize='14px' color='#63C7C9'>
                  New!
                </Text>
              </Box>
            )}
          </Box>

          <Text
            fontSize='14px'
            fontWeight={400}
            lineHeight='20px'
            color='#414042'
          >
            {item.text}
          </Text>
        </Box>
      </Item>
    ),
    [handleClickItem],
  )

  return (
    <>
      <Wrapper
        height='40px'
        px='12px'
        borderRadius='6px'
        mr='24px'
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        <PlannerIcon />

        <Box ml='12px' mr='8px'>
          <Text fontWeight={600} fontSize='18px' color='white'>
            Planner
          </Text>
        </Box>

        <ChevronIcon />
      </Wrapper>

      {isOpen && (
        <Box
          position='fixed'
          top='72px'
          left='112px'
          pt='24px'
          px='12px'
          pb='12px'
          zIndex={5}
          width='664px'
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          <Box
            bg='#FFFFFF'
            border='1px solid #E8E8ED'
            boxShadow='0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)'
            borderRadius='8px'
            px='20px'
            pt='24px'
            pb='14px'
            display='flex'
            flexWrap='wrap'
          >
            <Box width={1 / 2} pr='12px'>
              {items[0].map(renderItem)}
            </Box>

            <Box width={1 / 2} pl='12px'>
              {items[1].map(renderItem)}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default PlannerTab
