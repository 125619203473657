import { UPDATE_PROFILE } from '../actionTypes'

type Data = {
  photo_id?: string
  first_name?: string
  last_name?: string
  contact_email?: string
  contact_phone?: string
}

export const updateProfile = (data: Data) => ({
  type: UPDATE_PROFILE.name,
  api: {
    url: 'user/update',
    data,
  },
})
