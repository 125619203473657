import React, { FC, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Form } from 'react-final-form'
import validate from 'validate.js'
import { toast } from 'react-toastify'
import { path } from 'ramda'

import { getIsItemLoaded, getItem } from 'store/modules/agenda/selectors'
import { Box, Text, Button } from 'components/ui'
import { ReactComponent as BackIcon } from 'images/chevron-left.svg'
import {
  presenceFieldConstraint,
  timeConstraint,
  urlConstraint,
} from 'constants/constraints'
import {
  transformAgendaToForm,
  transformNewAgendaToRequest,
} from 'utils/agenda'
import {
  addAgendaItem,
  editAgendaItem,
  loadAgendaItem,
} from 'store/modules/agenda/actions'
import { AGENDA } from 'constants/paths'
import NewAgendaForm from './Form'

const AgendaDetails: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { wuid, agendaId } = useParams<{ wuid: string; agendaId?: string }>()
  const [error, setError] = useState(null)
  const isLoaded = useSelector(getIsItemLoaded(agendaId))
  const [isLoading, setIsLoading] = useState(false)
  const agendaItem = useSelector(getItem(agendaId))

  const onAdd = useCallback(async (data) => dispatch(addAgendaItem(data)), [
    dispatch,
  ])
  const onEdit = useCallback(async (data) => dispatch(editAgendaItem(data)), [
    dispatch,
  ])
  const onLoadAgendaItem = useCallback(
    async (data) => dispatch(loadAgendaItem(data)),
    [dispatch]
  )

  const handleValidate = (values: object) =>
    validate(values, {
      ...presenceFieldConstraint('name'),
      ...timeConstraint('start_time'),
      ...presenceFieldConstraint('start_date'),
      ...timeConstraint('end_time'),
      ...presenceFieldConstraint('end_date'),
      ...urlConstraint('url', false),
    })
  const handleSubmit = async (values: object) => {
    try {
      if (agendaId) {
        await onEdit(transformNewAgendaToRequest(wuid, values))
      } else {
        const res = await onAdd(transformNewAgendaToRequest(wuid, values))

        await onLoadAgendaItem(
          path(['value', 'payload', 'agenda_item_id'], res)
        )
      }

      history.push(`/wedding/${wuid}/agenda`)
    } catch (error) {
      setError(error.message)
    }
  }

  const handleClickBack = () => history.push(AGENDA(wuid))

  useEffect(() => {
    const handleLoadAgendaItem = async () => {
      setIsLoading(true)

      try {
        await onLoadAgendaItem(agendaId)
      } catch (error) {
        toast(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    if (agendaId && !isLoaded && !isLoading) {
      handleLoadAgendaItem()
    }
  }, [agendaId, isLoaded, isLoading, onLoadAgendaItem])

  return (
    <Box ml="56px" mb={1} mt={0} maxWidth="692px">
      <Button height="44px" ml="-36px" border="none" onClick={handleClickBack}>
        <BackIcon fill="#5458f7" width={30} height={30} />

        <Text fontFamily="1" color="blue" lineHeight="1.29">
          Back
        </Text>
      </Button>

      <Box mb={2}>
        <Text fontFamily="1" fontSize={4} fontWeight={600}>
          Schedule Item
        </Text>
      </Box>

      <Form
        render={(props) => (
          <NewAgendaForm {...props} onCancel={handleClickBack} />
        )}
        validate={handleValidate}
        initialValues={
          agendaItem ? transformAgendaToForm(agendaItem) : undefined
        }
        onSubmit={handleSubmit}
      />

      {error && <Text color="red">{error}</Text>}
    </Box>
  )
}

export default AgendaDetails
