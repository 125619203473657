import React, { useContext } from 'react'
import { Box, Button, Text } from 'components/ui'
import { ReactComponent as PlusIcon } from 'images/plus.svg'
import { Context } from '../Context'

const Header = () => {
  const {
    handleOpenDrawer,
  } = useContext<any>(Context)

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Text style={{
            fontFamily: 'Source Sans Pro',
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '40px',
            color: '#353B60',
          }}
          >
            Guest FAQs
          </Text>
        </Box>
        <Box>
          <Button
            variant='primary'
            height='40px'
            padding='0px 20px 0px 16px !important'
            borderRadius='8px !important'
            fontFamily='Source Sans Pro !important'
            fontSize='16px'
            fontWeight='600'
            lineHeight='18px'
            onClick={handleOpenDrawer}
          >
            <Box mr={8}>
              <PlusIcon fill='currentColor' />
            </Box>
            Add Question
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Header