import { Selector, createSelector } from 'reselect'
import {
  find,
  propEq,
  pathEq,
  pathOr,
  map,
  assoc,
  length,
  compose,
  filter,
  equals,
  findIndex,
  uniqBy,
  prop,
  prepend,
  has,
} from 'ramda'

import { getWuid as getWuidUtil } from 'utils/router'
import { State, List, Guest } from './reducer'

const getState: Selector<{ guests: State }, State> = (state) => state.guests
const getWuid = compose(
  getWuidUtil,
  pathOr('', ['router', 'location', 'pathname']),
)

export const getListsIsLoaded: Selector<{ guests: State },
  boolean> = createSelector(getState, getWuid, (state, wuid = '') =>
  pathOr(false, ['loadedWuids', wuid, 'lists'], state),
)

export const getGuestsIsLoaded: Selector<{ guests: State },
  boolean> = createSelector(getState, getWuid, (state, wuid = '') =>
  pathOr(false, ['loadedWuids', wuid, 'guests'], state),
)

export const getIsLoadedByWuid = (wuid?: string) =>
  createSelector(getState, (state) =>
    wuid ? has(wuid, pathOr({}, ['loadedWuids'], state)) : false,
  )

export const getListById = (
  listId: string | null,
): Selector<{ guests: State }, List | undefined> =>
  createSelector(
    getState,
    compose(
      (lists: Guest[]) => find(propEq('id', listId), lists),
      pathOr([], ['lists']),
    ),
  )

export const getAllLists: Selector<{ guests: State }, List[]> = createSelector(
  getState,
  getWuid,
  compose(
    prepend({ id: '0', name: '', color: '' }),
    uniqBy(prop('id')),
    (state: State, wuid: string | undefined) =>
      filter(
        propEq('wuid', wuid),
        pathOr([{ id: '', wuid: '', name: '', color: '' }], ['lists'], state),
      ),
  ),
)

export const getAdminListIdByWuid = (wuid: string) =>
  createSelector(getState, (state) =>
    pathOr(
      null,
      [0, 'id'],
      filter(propEq('wuid', wuid), pathOr([], ['lists'], state)),
    ),
  )

export const getAllGuests = createSelector(getState, (state) => state.guests)

export const getGuests: Selector<{ guests: State }, Guest[]> = createSelector(
  getState,
  getWuid,
  getAllLists,
  compose(
    uniqBy(prop('id')),
    (state: State, wuid: string | undefined, lists: List[]) =>
      filter(
        (guest: Guest) =>
          !equals(findIndex(propEq('id', guest.list_id), lists), -1) &&
          propEq('wuid', wuid, guest),
        pathOr([], ['guests'], state),
      ),
  ),
)

export const getGuestsByWuid = (wuid?: string) =>
  createSelector(getState, getAllLists, (state, lists) =>
    uniqBy<Guest, string>(prop('id'))(
      filter(
        (guest) =>
          !equals(findIndex(propEq('id', guest.list_id), lists), -1) &&
          propEq('wuid', wuid, guest),
        pathOr<Guest[]>([], ['guests'], state),
      ),
    ),
  )

export const getTableData = createSelector(
  getGuests,
  getAllLists,
  (guests, lists) =>
    map(
      (guest) => assoc('list', find(propEq('id', guest.list_id), lists), guest),
      guests,
    ),
)

export const getGuestsLength: Selector<{ guests: State },
  number> = createSelector(getState, compose(length, pathOr([], ['guests'])))

export const getGuest = (uuid?: string) =>
  createSelector(getGuests, find<Guest>(pathEq(['user', 'uuid'], uuid)))

export const getGuestById = (id?: string) =>
  createSelector(getGuests, find<Guest>(propEq('id', id)))

export const getListsByWuid = (wuid: string) =>
  createSelector(
    getState,
    compose(filter(propEq('wuid', wuid)), pathOr([], ['lists'])),
  )
