import React, { FC, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'

import { getUser } from 'store/modules/auth/selectors'
import { logout } from 'store/modules/auth/actions'
import { Box, Text, Avatar } from 'components/ui'
import { getImage, getFullName } from 'utils/guest'
import { ReactComponent as ChevronIcon } from 'images/chevron-down.svg'
import { WEDDING_LIST } from 'constants/paths'
import Item from './Item'

const Menu: FC = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const user = useSelector(getUser)
  const { push } = useHistory()

  const image = useMemo(() => getImage(user?.image, 'big_icon'), [user])
  const fullName = useMemo(
    () => getFullName([user?.first_name, user?.last_name]),
    [user]
  )

  const onLogout = useCallback(() => dispatch(logout()), [dispatch])

  const handleClickButton = useCallback(() => setIsOpen(!isOpen), [isOpen])
  const handleClickOutside = useCallback(() => setIsOpen(false), [])
  const handleGoToWeddings = useCallback(() => push(WEDDING_LIST), [push])

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Box position="relative" height="40px">
        <Box
          display="inline-flex"
          alignItems="center"
          cursor="pointer"
          onClick={handleClickButton}
        >
          <Avatar
            image={image}
            name={fullName}
            fontSize={16}
            bg="#353B60"
            color="#fff"
          />

          <Box mx="12px">
            <Text fontWeight={700} fontSize="16px" lineHeight="22px">
              {user?.first_name}
            </Text>
          </Box>

          <ChevronIcon />
        </Box>

        {isOpen && (
          <Box
            position="absolute"
            width="196px"
            bg="white"
            border="1px solid"
            borderColor="gray"
            borderRadius={0}
            boxShadow={0}
            mt={0}
            right="0px"
            zIndex={2147483647}
          >
            <Item>
              <Text>Profile</Text>
            </Item>

            <Item onClick={handleGoToWeddings}>
              <Text>Switch Event</Text>
            </Item>

            <Item>
              <Text>Manage Your Account</Text>
            </Item>

            <Box borderBottom="1px solid" borderColor="gray" />

            <Item onClick={onLogout}>
              <Text>Log Out</Text>
            </Item>
          </Box>
        )}
      </Box>
    </OutsideClickHandler>
  )
}

export default Menu
