import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { path } from 'ramda'

import { Box, Text } from 'components/ui'
import { ReactComponent as Icon } from 'images/user-plus.svg'
import Modal from './AddUserModal'

const Wrapper = styled(Box).attrs({
  display: 'inline-flex',
  alignItems: 'center',
  p: 0,
  borderRadius: 0,
  width: '100%',
  position: 'relative',
  cursor: 'pointer',
})`
  &:hover {
    background-color: ${path(['theme', 'colors', 'lightGray'])};
  }
`

type Props = {
  onAddUser: (x: any) => void
}

const AddUser: FC<Props> = ({ onAddUser }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClickItem = () => setIsModalOpen(!isModalOpen)
  const handleCloseModal = () => setIsModalOpen(false)
  const handleAddUser = ({ guest }: { guest: { value: string } }) => {
    onAddUser(guest.value)

    handleCloseModal()
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onAddUser={handleAddUser}
      />

      <Wrapper onClick={handleClickItem}>
        <Box
          width="40px"
          height="40px"
          mr="12px"
          borderRadius="50%"
          bg="#f2f3f5"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Icon stroke="#5458f7" width={18} height={18} />
        </Box>

        <Text fontSize="15px" color="blue">
          Add Member
        </Text>
      </Wrapper>
    </>
  )
}

export default AddUser
