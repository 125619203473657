import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { verifyResend } from 'store/modules/auth/actions'
import { Text, Box, Button } from 'components/ui'
import { ReactComponent as Icon } from 'images/email-verify.svg'

type Props = {
  uuid: string
  email: string
}

const EmailSent: FC<Props> = ({ uuid, email }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const onVerifyResend = useCallback(async () => dispatch(verifyResend(uuid)), [
    dispatch,
    uuid,
  ])

  const handleClickResend = useCallback(async () => {
    setIsLoading(true)

    try {
      await onVerifyResend()

      toast('New verification link has been sent successfully')
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onVerifyResend])

  return (
    <>
      <Box mb="15px">
        <Icon />
      </Box>

      <Text
        fontSize="28px"
        fontWeight={700}
        lineHeight="36px"
        color="#353B60"
        textAlign="center"
      >
        Please verify your email
      </Text>

      <Box my="24px" maxWidth="325px" display="flex" justifyContent="center">
        <Text fontFamily="1" textAlign="center" fontSize={1} lineHeight="20px">
          You’re almost there! We sent an email to{' '}
          <Text fontWeight={600} fontSize={1} fontFamily="1">
            {email}
          </Text>
        </Text>
      </Box>

      <Box mb="24px" maxWidth="475px" display="flex" justifyContent="center">
        <Text fontFamily="1" textAlign="center" fontSize={1} lineHeight="20px">
          Just click on the link in that email to complete your sign up. If you
          don’t see it, you may need to{' '}
          <Text fontWeight={600} fontSize={1} fontFamily="1">
            check your spam
          </Text>{' '}
          folder.
        </Text>
      </Box>

      <Box mb="20px">
        <Text fontFamily="1" textAlign="center" fontSize={1} lineHeight="20px">
          Still can’t find the email?
        </Text>
      </Box>

      <Button
        variant="primary"
        height="44px"
        px="20px"
        disabled={isLoading}
        onClick={handleClickResend}
      >
        Resend Email
      </Button>

      <Box mt="28px" display="flex" alignItems="center">
        <Text fontFamily="1" textAlign="center" fontSize={1} lineHeight="20px">
          Need help?
        </Text>

        <Button height="20px">
          <Text fontFamily="1" fontSize={1} color="primary" isUnderline>
            Contact Us
          </Text>
        </Button>
      </Box>
    </>
  )
}

export default EmailSent
