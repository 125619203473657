import { UPDATE } from '../actionTypes'

export const update = (data: object) => ({
  type: UPDATE.name,
  api: {
    url: 'wedding/update',
    method: 'post',
    data,
  },
})
