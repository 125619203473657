import { ActionCreator, AnyAction } from 'redux'

import create from 'utils/createReduxPromiseActionType'
import { SChartItem } from './reducer'

const NAME = 'seatings'

export const Type = {
  LOAD_CHARTS: create(`${NAME}/LOAD_CHARTS`),
  ADD_CHART: create(`${NAME}/ADD_CHART`),
  EDIT_CHART: create(`${NAME}/EDIT_CHART`),
  REMOVE_CHART: create(`${NAME}/REMOVE_CHART`),
  LOAD_ITEMS: create(`${NAME}/LOAD_ITEMS`),
  ADD_ITEM: create(`${NAME}/ADD_ITEM`),
  EDIT_ITEM: create(`${NAME}/EDIT_ITEM`),
  REMOVE_ITEM: create(`${NAME}/REMOVE_ITEM`),
  UPDATE_ITEMS: create(`${NAME}/UPDATE_ITEMS`),
  CHANGE_ITEM: `${NAME}/CHANGE_ITEM`,
  EXPAND_COLLAPSE: `${NAME}/EXPAND_COLLAPSE`,
  COLLAPSE: `${NAME}/COLLAPSE`,
  UPLOAD_FLOORPLAN: create(`${NAME}/UPLOAD_FLOORPLAN`),
  REMOVE_FLOORPLAN: create(`${NAME}/REMOVE_FLOORPLAN`),
  GET_PDF: create(`${NAME}/GET_PDF`),
}

export const loadCharts: ActionCreator<AnyAction> = () => ({
  type: Type.LOAD_CHARTS.name,
  api: {
    url: 'seatingcharts/list',
    data: {
      field_list: 'all_fields',
    },
  },
})

type ChartValues = {
  caption?: string
  width?: number
  height?: number
  zoom?: number
  x?: number
  y?: number
  floorplan_scale?: number
  svg_guests_image?: string
}

export const addChart = (values: ChartValues) => ({
  type: Type.ADD_CHART.name,
  api: {
    url: 'seatingcharts/add',
    data: {
      caption: values.caption || 'Default',
      width: values.width || 100,
      height: values.height || 60,
      x: 0,
      y: 0,
      zoom: 1,
      field_list: 'all_fields',
    },
  },
})

export const editChart = (schart_id: string, values: ChartValues) => ({
  type: Type.EDIT_CHART.name,
  api: {
    url: 'seatingcharts/update',
    data: {
      schart_id,
      ...values,
      field_list: 'all_fields',
    },
  },
})

export const removeChart = (schart_id: string) => ({
  type: Type.REMOVE_CHART.name,
  api: {
    url: 'seatingcharts/remove',
    data: { schart_id },
  },
})

export const loadItems: ActionCreator<AnyAction> = (schart_id: string) => ({
  type: Type.LOAD_ITEMS.name,
  api: {
    url: 'seatingcharts/list_items',
    data: { schart_id, field_list: 'all_fields' },
  },
})

export type AddItemPayload = {
  schart_id: string
  type: string
  caption?: string
  pos_x?: number
  pos_y?: number
  width?: number
  height?: number
  seats?: string
}

export const addItem = (data: AddItemPayload) => ({
  type: Type.ADD_ITEM.name,
  api: {
    url: 'seatingcharts/add_item',
    data: { ...data, field_list: 'all_fields' },
  },
})

export type EditItemPayload = {
  schart_id: string
  schart_item_id: string
  type?: string
  caption?: string
  pos_x?: number
  pos_y?: number
  width?: number
  height?: number
  seats?: string
  rotation?: number
}

export const editItem = (data: EditItemPayload) => ({
  type: Type.EDIT_ITEM.name,
  api: {
    url: 'seatingcharts/update_item',
    data: { ...data, field_list: 'all_fields' },
  },
})

export const removeItem = (schart_id: string, schart_item_id: string) => ({
  type: Type.REMOVE_ITEM.name,
  api: {
    url: 'seatingcharts/remove_item',
    data: { schart_id, schart_item_id },
  },
})

export type ModItem = {
  _action: 'f' | 'u' | 'd'
  _ref_id: string
  id?: string
  type?: string
  caption?: string
  pos_x?: number
  pos_y?: number
  width?: number
  height?: number
  seats?: string
  rotation?: number
}

export const updateItems = (schart_id: string, mod_list: ModItem[]) => ({
  type: Type.UPDATE_ITEMS.name,
  api: {
    url: 'seatingcharts/modify_items',
    data: {
      schart_id,
      mod_list: JSON.stringify(mod_list),
      remove_missed: 1,
      unsit_missed: 1,
      field_list: 'all_fields',
    },
  },
})

export const changeItem = (
  schart_id: SChartItem['schart_id'],
  schart_item_id: SChartItem['schart_item_id'],
  data: Partial<SChartItem>
) => ({
  type: Type.CHANGE_ITEM,
  payload: {
    schart_id,
    schart_item_id,
    data,
  },
})

export const expandCollapse = () => ({ type: Type.EXPAND_COLLAPSE })
export const collapse = () => ({ type: Type.COLLAPSE })

export const uploadFloorplan = (data: FormData) => ({
  type: Type.UPLOAD_FLOORPLAN.name,
  api: {
    url: 'seatingcharts/floorplan_upload',
    contentType: 'multipart/form-data',
    data,
  },
})

export const removeFloorplan = (chartId: string) => ({
  type: Type.REMOVE_FLOORPLAN.name,
  api: {
    url: 'seatingcharts/floorplan_remove',
    data: {
      schart_id: chartId,
    },
  },
})

export const getPdf = (
  schart_id: string,
  pdf_type: number = 1,
  is_rebuild: number = 0
) => ({
  type: Type.GET_PDF.name,
  api: {
    url: 'seatingcharts/get_pdf',
    data: {
      schart_id,
      pdf_type,
      is_rebuild,
    },
  },
})
