import React, { FC } from 'react'

import { Box } from 'components/ui'
import Features from './Features'

const AuthFormWrapper: FC = ({ children }) => (
  <Box display="flex" width="100vw" height="100vh" overflowY="auto">
    <Features />

    <Box
      width={[1, 1, 1, 1 / 2]}
      bg="bg"
      pl={1}
      pt="20px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      backgroundColor="#FFFFFF"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginRight="1.8rem"
      >
        <Box
          width="100%"
          bg="white"
          px={4}
          py={4}
          pb={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Box>
      </Box>
    </Box>
  </Box>
)

export default AuthFormWrapper
