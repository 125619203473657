import {
  LOAD_NAV,
  ADD_NAV,
  LOAD_NAV_ITEMS,
  TOGGLE_APP_SECTIONS,
  ADD_NAV_ITEM,
  EDIT_NAV_ITEM,
  DELETE_NAV_ITEM,
  SWAP_NAV_ITEMS,
} from './actionTypes'

export const loadNav = () => ({
  type: LOAD_NAV.name,
  api: {
    url: 'navs/list',
  },
})

export const addNav = (caption: string) => ({
  type: ADD_NAV.name,
  api: {
    url: 'navs/add',
    data: { set_active: 1, caption },
  },
})

export const loadNavItems = () => ({
  type: LOAD_NAV_ITEMS.name,
  api: {
    url: 'navs/list_items',
    data: { is_plain: 1 },
  },
})

type NavItemProps = {
  type: number
  caption?: string
  link?: string
  icon?: string
  is_active?: number
  access_level?: number
}

export const addNavItem = (data: NavItemProps) => ({
  type: ADD_NAV_ITEM.name,
  api: {
    url: 'navs/add_item',
    data: { ...data, is_plain: 1 },
  },
})

type EditNavProps = {
  nav_item_id: number | string
}

export const editNavItem = (data: Partial<NavItemProps> & EditNavProps) => ({
  type: EDIT_NAV_ITEM.name,
  api: {
    url: 'navs/update_item',
    data: { ...data, is_plain: 1 },
  },
})

export const deleteNavItem = (nav_item_id: string, nav_id: string) => ({
  type: DELETE_NAV_ITEM.name,
  api: {
    url: 'navs/remove_item',
    data: { nav_item_id, nav_id },
  },
})

type SwapNavItemsProps = {
  nav_item_id: string
  replacewith?: string
  parent_nav_item_id?: string
  arrange?: number
  is_active?: number
  is_featured?: number
  access_level?: number
}

export const swapNavItems = (data: SwapNavItemsProps) => ({
  type: SWAP_NAV_ITEMS.name,
  api: {
    url: 'navs/update_item',
    data: { ...data, is_plain: 1 },
  },
})

export const toggleAppSections = (nav_item_id?: string) => ({
  type: TOGGLE_APP_SECTIONS,
  payload: nav_item_id,
})
