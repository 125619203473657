import React from 'react'
import { FormRenderProps } from 'react-final-form'

import { Box, Text, Button } from 'components/ui'
import { Input } from 'components/form'
import { PHONE_MASK } from 'constants/masks'

type Props = {
  onCancel: VoidFunction
}

const Form = ({
  handleSubmit,
  submitting,
  onCancel,
}: FormRenderProps & Props) => (
  <Box
    as="form"
    onSubmit={handleSubmit}
    pt="26px"
    borderTop="1px solid #E8E8ED"
  >
    <Box display="flex" mb="26px">
      <Box width={1 / 2} pr="10px">
        <Text
          as="label"
          htmlFor="first_name"
          color="#000"
          fontWeight={600}
          fontSize="16px"
          lineHeight="18px"
        >
          First Name
        </Text>

        <Box mt="10px" />

        <Input id="first_name" name="first_name" border="1px solid #CBCBD3" />
      </Box>

      <Box width={1 / 2} pl="10px">
        <Text
          as="label"
          htmlFor="last_name"
          color="#000"
          fontWeight={600}
          fontSize="16px"
          lineHeight="18px"
        >
          Last Name
        </Text>

        <Box mt="10px" />

        <Input id="last_name" name="last_name" border="1px solid #CBCBD3" />
      </Box>
    </Box>

    <Text
      as="label"
      htmlFor="contact_email"
      color="#000"
      fontWeight={600}
      fontSize="16px"
      lineHeight="18px"
    >
      Email Address
    </Text>

    <Box mt="10px" />

    <Input
      id="contact_email"
      name="contact_email"
      type="email"
      border="1px solid #CBCBD3"
    />

    <Box mt="26px" />

    <Text
      as="label"
      htmlFor="contact_phone"
      color="#000"
      fontWeight={600}
      fontSize="16px"
      lineHeight="18px"
    >
      Phone Number
    </Text>

    <Box mt="10px" />

    <Input
      id="contact_phone"
      name="contact_phone"
      type="phone"
      border="1px solid #CBCBD3"
      mask={PHONE_MASK}
    />

    <Box mt="24px" mb="16px" borderBottom="1px solid #E8E8ED" />

    <Box display="flex" justifyContent="flex-end">
      <Button
        type="button"
        height="40px"
        px="16px"
        borderRadius="8px"
        border="1px solid #E1EAEF"
        mr="16px"
        onClick={onCancel}
      >
        <Text fontWeight={600} fontSize="16px" lineHeight="18px" color="#000">
          Cancel
        </Text>
      </Button>

      <Button
        type="submit"
        height="40px"
        px="16px"
        borderRadius="8px"
        bg="#5458F7"
        disabled={submitting}
      >
        <Text fontWeight={600} fontSize="16px" lineHeight="18px" color="white">
          Update
        </Text>
      </Button>
    </Box>
  </Box>
)

export default Form
