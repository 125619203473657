import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'

import { Box, Text, Button } from 'components/ui'
import {
  MainBox,
  Title,
  Subtitle,
  AppPreview,
  ColorPicker,
} from 'components/WeddingWizard'
import { getIsLoggedIn } from 'store/modules/auth/selectors'
import { WEDDING_CREATE } from 'constants/paths'
import { ReactComponent as Icon } from 'images/ww-step8.svg'

type Props = {
  isValid: boolean
  color?: { value: string; label: string }
  color2?: { value: string; label: string }
  weddingName?: string
  font?: { value: string; label: string }
  image?: string
  onBack: VoidFunction
  onContinue: VoidFunction
}

const Step8: FC<Props> = ({
  isValid,
  color,
  color2,
  weddingName,
  font,
  image,
  onBack,
  onContinue,
}) => {
  const isLoggedIn = useSelector(getIsLoggedIn)

  if (!isLoggedIn) {
    return <Redirect to={`${WEDDING_CREATE}/signup`} />
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems={['flex-start', 'center']}
      minHeight={['calc(100vh - 96px)', 'calc(100vh - 180px)']}
    >
      <MainBox py="71px" pb="27px">
        <Box mt="-95px" mb="30px">
          <Icon />
        </Box>

        <Title>It’s time to design your wedding theme!</Title>
        <Box mt="8px" />
        <Subtitle>
          Your wedding vision sets the overall tone and style for your day.
        </Subtitle>
        <Subtitle> Let’s start by choosing your primary color.</Subtitle>

        <Box maxWidth="400px" width="100%" mt="40px">
          <Text
            fontWeight={600}
            fontSize="20px"
            lineHeight="25px"
            color="secondary"
          >
            Primary color:
          </Text>

          <ColorPicker name="theme_color" />

          <Box display="flex" justifyContent="space-between" mt="36px">
            <Button variant="secondary" height="40px" onClick={onBack}>
              Back
            </Button>

            <Button
              bg="primary"
              borderRadius="6px"
              height="40px"
              disabled={!isValid}
              px="28px"
              onClick={onContinue}
            >
              <Text
                fontFamily="1"
                fontWeight={600}
                fontSize="16px"
                color="white"
              >
                Next
              </Text>
            </Button>
          </Box>
        </Box>
      </MainBox>

      <Box position="absolute" bottom="0px" right="0px" left="0px" zIndex={1}>
        <AppPreview
          color={color}
          color2={color2}
          title={weddingName}
          font={font}
          image={image}
        />
      </Box>
    </Box>
  )
}

export default Step8
