import React, { FC } from 'react'
import { map, filter, hasPath, mergeLeft } from 'ramda'
import { useTheme } from 'styled-components'

import { Guest } from 'store/modules/guests/reducer'
import { Select } from 'components/form'
import Option from './Option'

type Props = {
  isLoading: boolean
  guests: Guest[]
}

const GuestsSelector: FC<Props> = ({ isLoading, guests }) => {
  const theme: any = useTheme()
  const guestToOption = (guest: Guest) => ({
    label: guest.user.first_name,
    value: guest.user.uuid,
    user: guest.user,
  })

  const styles = {
    indicatorsContainer: () => ({ display: 'none' }),
    multiValue: mergeLeft({
      backgroundColor: theme.colors.white,
      borderRadius: 3,
      border: `1px solid ${theme.colors.gray}`,
      fontFamily: theme.fonts[0],
      fontSize: theme.fontSizes[0],
      height: 26,
    }),
    multiValueLabel: mergeLeft({
      color: theme.colors.darkGray,
      paddingLeft: 8,
    }),
    multiValueRemove: mergeLeft({
      color: theme.colors.darkGray,
      paddingRight: 6,
      cursor: 'pointer',
      ':hover': {
        color: theme.colors.black,
      },
    }),
  }

  return (
    <Select
      isClearable={false}
      name="guests"
      isLoading={isLoading}
      placeholder="Add guest"
      options={map(guestToOption, filter(hasPath(['user', 'uuid']), guests))}
      components={{ Option }}
      styles={styles}
    />
  )
}

export default GuestsSelector
