import React, { FC, Fragment, useMemo } from 'react'
import { useTable, useSortBy, Column } from 'react-table'
import styled from 'styled-components'
import { equals, path } from 'ramda'

import { ReactComponent as SortIcon } from 'images/chevron-down.svg'
import Box from './Box'
import Text from './Text'

const Wrapper = styled(Box).attrs({
  as: 'table',
  width: '100%',
  border: '1px solid',
  borderRadius: 1,
  borderColor: 'gray',
  bg: 'lightGray',
})`
  table-layout: fixed;
`

const Cell = styled(Box).attrs({
  as: 'td',
  py: 0,
  pl: 1,
  borderBottomColor: 'gray',
})`
  font-family: ${path(['theme', 'fonts', 0])};
  font-size: ${path(['theme', 'fontSizes', 0])}px;
  color: ${path(['theme', 'colors', 'darkGray'])};
`

type Props = {
  columns?: Column<any>[]
  data?: object[]
}

const Table: FC<Props> = ({ columns = [], data = [] }) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<any>(
    {
      columns: useMemo(() => columns, [columns]),
      data: useMemo(() => data, [data]),
    },
    useSortBy
  )

  return (
    <Wrapper {...getTableProps()}>
      <Box as="thead">
        {headerGroups.map((headerGroup) => (
          <Box as="tr" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, i: number) => (
              <Box
                as="th"
                py={0}
                pl={1}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <Box display="flex" alignItems="center" height="18px">
                  <Text fontFamily="1" fontWeight={600} color="darkGray">
                    {column.render('Header')}
                  </Text>

                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <SortIcon width="10px" height="5px" transform="rotate(180)" />
                    ) : (
                      <SortIcon width="10px" height="5px" />
                    )
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>

      <Box as="tbody" bg="white">
        {rows.map((row, i) => {
          prepareRow(row)

          return (
            <Fragment key={i}>
              <Box as="tr" {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  const isLast = equals(row.index + 1, rows.length)

                  return (
                    <Cell
                      borderBottom={isLast ? 'none' : '1px solid'}
                      borderBottomLeftRadius={
                        isLast && equals(cellIndex, 0) ? 1 : '0px'
                      }
                      borderBottomRightRadius={
                        isLast && equals(cellIndex + 1, row.cells.length)
                          ? 1
                          : '0px'
                      }
                      width={path(['column', 'width'], cell)}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </Cell>
                  )
                })}
              </Box>
            </Fragment>
          )
        })}
      </Box>
    </Wrapper>
  )
}

export default Table
