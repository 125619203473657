import React, { useMemo } from 'react'

import { Box, Avatar, Text } from 'components/ui'
import { getFullName, getImage } from 'utils/guest'

type Props = {
  first_name: string
  last_name: string
  image?: { slice: string; link: string }[]
}

const Guest = ({ first_name, last_name, image }: Props) => {
  const imageLink = useMemo(() => (image ? getImage(image) : undefined), [
    image,
  ])

  const name = useMemo(() => getFullName([first_name, last_name]), [
    first_name,
    last_name,
  ])

  return (
    <Box
      width="244px"
      px="10px"
      height="56px"
      display="flex"
      alignItems="center"
      bg="white"
      border="2px solid #E8E8ED"
      borderRadius="8px"
      mb="10px"
    >
      <Avatar
        size="36px"
        name={name}
        image={imageLink}
        fontSize={18}
        color="white"
        bg="#353B60"
      />

      <Box width="100%" maxHeight="40px" pl="12px" overflow="hidden">
        <Text
          fontWeight="600"
          fontSize="16px"
          lineHeight="20px"
          color="#353B60"
        >
          {name}
        </Text>
      </Box>
    </Box>
  )
}

export default Guest
