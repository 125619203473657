import React, { FC, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Text, Box, Button } from 'components/ui'
import { removeList } from 'store/modules/guests/actions'

type Props = {
  listId?: string
  name?: string
  onClose: () => void
}

const DeleteBlock: FC<Props> = ({ listId, name, onClose }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const onRemoveList = useCallback(
    async () => dispatch(removeList(listId, 0)),
    [dispatch, listId]
  )

  const handleRemoveList = async () => {
    setIsLoading(true)

    try {
      await onRemoveList()

      onClose()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Text fontFamily="1" lineHeight={1}>
        Are you sure you want to delete the group, “{name}”?
      </Text>

      <Box display="flex" justifyContent="flex-end" mt={1}>
        <Box mr={0}>
          <Button
            border="none"
            px={2}
            py={0}
            fontFamily="1"
            fontWeight={600}
            fontSize="18px"
            color="blue"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>

        <Button
          border="none"
          px={2}
          py={0}
          fontFamily="1"
          fontSize="18px"
          color="red"
          disabled={isLoading}
          onClick={handleRemoveList}
        >
          Delete
        </Button>
      </Box>
    </>
  )
}

export default DeleteBlock
