import { createSelector, Selector } from 'reselect'
import { pathOr, compose, filter, propEq } from 'ramda'

import { getWuid as getWuidUtil } from 'utils/router'
import { initialState, State, Post } from './reducer'

const getState: Selector<{ posts: State }, State> = pathOr(initialState, [
  'posts',
])

const getWuid = compose(
  getWuidUtil,
  pathOr('', ['router', 'location', 'pathname'])
)

export const getIsLoaded = createSelector(getState, getWuid, (state, wuid) =>
  pathOr(false, ['loadedWuids', wuid || ''], state)
)

export const getPosts = createSelector(getState, getWuid, (state, wuid) =>
  filter<Post>(propEq('wedding_wuid', wuid), pathOr([], ['posts'], state))
)
