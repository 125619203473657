import { FieldInputProps } from 'react-final-form'
import styled from 'styled-components'
import MaskedInput from 'react-text-mask'
import { path } from 'ramda'

import Box from './Box'
import { borderColor } from 'styled-system'

type Props = {
  mask?: string
  isTextarea?: boolean
  variant?: 'error'
  borderColor: string
  height?: string | number
}

const Input = styled(Box).attrs((props: Props) => ({
  as: props.mask ? MaskedInput : props.isTextarea ? 'textarea' : 'input',
  height: !props.isTextarea ? props.height || '40px' : undefined,
  rows: props.isTextarea ? 3 : undefined,
}))<FieldInputProps<HTMLInputElement> & Props>`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  font-family: ${path(['theme', 'fonts', 0])};
  font-size: 16px;

  border-color: ${borderColor};

  resize: none;

  &:read-only {
    background-color: #ffffff;
  }

  &:focus {
    border-color: #5458F7 !important;
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: #CBCBD3;
  }
`

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  autoComplete: 'off',
  border: '1px solid #E8E8ED',
  width: '100%',
  px: '14px',
  borderRadius: '8px',
}

export default Input
