import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { path, pathOr, forEach } from 'ramda'
import { toast } from 'react-toastify'
import md5 from 'md5'

import { addPhoto as uploadPhoto } from 'store/modules/photos/actions'
import { addAlbumPhoto, pushUploadedPhoto } from 'store/modules/albums/actions'
import { Button, Text } from 'components/ui'
import { readImage } from 'utils/images'
import styled from 'styled-components'

type Props = {
  wuid?: string
  albumId?: string
}

const MenuItem = styled(Button).attrs({
  as: 'button',
  width: '100%',
  height: '40px',
  bg: 'white',
  justifyContent: 'flex-start',
  pl: '16px',
})`
  &:hover {
    background: #f7f7fc;
  }

  border-bottom: 1px solid #e8e8ed;
  border-right: 1px solid #e8e8ed;
  border-left: 1px solid #e8e8ed;

  &:first-child {
    border-left: 1px solid #e8e8ed;
    border-top: 1px solid #e8e8ed;
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-right: 1px solid #e8e8ed;
    border-radius: 0 0 8px 8px;
  }

  &:only-child {
    border: 1px solid #e8e8ed;
    border-radius: 8px;
  }
`

const AddPhotoButton: FC<Props> = ({ wuid, albumId }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const onAddPhoto = useCallback(async (data) => dispatch(addAlbumPhoto(data)), [
    dispatch,
  ])

  const onUploadPhoto = useCallback(
    async (data) => dispatch(uploadPhoto(data)),
    [dispatch],
  )

  const onPushUploadedPhoto = useCallback(
    async (data) => dispatch(pushUploadedPhoto(data)),
    [dispatch],
  )

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLoading(true)

      forEach(async (file) => {
        try {
          const res: any = await onUploadPhoto({
            wuid,
            image_type: file.type.split('/')[1],
            hash: md5(await readImage(file)),
            src_file: file.name,
            total_length: file.size,
            mod_time: file.lastModified,
          })

          const photo = pathOr({}, ['value', 'payload', 'photo'], res)

          if (!path(['photo_id'], photo)) {
            throw new Error('Something goes wrong. Please try again')
          }

          await onAddPhoto({
            wuid,
            album_id: albumId,
            photo_id: path(['photo_id'], photo),
          })

          await onPushUploadedPhoto({ ...photo, album_id: albumId })
        } catch (error) {
          toast(error.message)
        }
      }, acceptedFiles)

      setIsLoading(false)
    },
    [wuid, albumId, onUploadPhoto, onAddPhoto, onPushUploadedPhoto],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
  })

  const { onClick } = getRootProps()

  return (
    <MenuItem onClick={onClick} disabled={isLoading}>
      <input {...getInputProps()} />
      <Text fontSize='14px' color='#353B60'>
        Add Photo
      </Text>
    </MenuItem>
  )
}

export default AddPhotoButton
