import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { presenceFieldConstraint, timeConstraint } from 'constants/constraints'
import { Autocomplete } from '../EditEventDrawer/autocomplete'
import { useJsApiLoader } from '@react-google-maps/api'
import TabsRound from '../EditEventDrawer/TabsRound'
// import { Map } from '../EditEventDrawer/ googleMap'
import { components } from 'react-select'
import { Form } from 'react-final-form'
import moment from 'moment-timezone'
import { Context } from '../Context'
import { validate } from 'validate.js'
import { format } from 'date-fns'
import { map } from 'ramda'
import { enUS } from 'date-fns/locale'

// Store tools
import { useSelector } from 'react-redux'
import { getItem } from 'store/modules/agenda/selectors'

// Components
import { DayPickerInput, Input, TimePickerInput } from 'components/form'
import { Box, FormLabel, Text, Select, Button } from 'components/ui'
import AddGuestsModal from 'components/Modal/addGuestsModal'

// Utils
import { EditContainerWrapper, MainContainer } from 'components/ResponsesModal/utils'
import {
  BorderLine,
  ButtonsStyled, CancelButtonStyled,
  DatesStyled,
  EditScheduleContainer,
  EventAccessOptions,
  EventDetailContentStyled, SubmitButtonStyled, SubmitContainerStyled,
} from '../utils'

// Icons
import { ReactComponent as CloseIcon } from 'images/closeIconGrey.svg'
import { ReactComponent as TimeIcon } from 'images/time.svg'
import { ReactComponent as LocationIcon } from 'images/location.svg'
import { ReactComponent as AccessModeIcon } from 'images/access-mode.svg'
import { ReactComponent as PlusIcon } from 'images/add-guest.svg'
import { ReactComponent as DressCodeIcon } from 'images/dress.svg'

const MAP_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

declare type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]

const libraries: Libraries = ['places']

const AddEventModal: FC = () => {
  const {
    wuid,
    handleSubmit,
    locationValue,
    setLocationValue,
    selectedEvent,
    isOpenAddEventModal,
    setSelectedAccessMode,
    handleCloseAddEventModal,
    // center,
    setCenter,
    defaultStartTime,
    defaultEndTime,
    defaultDate,
  } = useContext<any>(Context)

  const agendaItem = useSelector(getItem(selectedEvent.agenda_item_id))

  // const [isChecked, setIsChecked] = useState(false)
  const [timeZoneVal, setTimeZoneVal] = useState({ label: 'US/Central', value: 'US/Central' })
  const [activeTab, setActiveTab] = useState<string>(EventAccessOptions[0].name)
  const [agenda, setAgenda] = useState({ ...agendaItem })
  const [isOpenAddGuestModal, setIsOpenAddGuestModal] = useState(false)

  let maxLengthDesc = 140
  let inputMaxLength: number
  let valueLength: number

  const { isLoaded }: any = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${MAP_KEY}`,
    libraries,
  })

  const onPlaceSelect = useCallback((cordinates) => {
    setCenter(cordinates)
  }, [setCenter])

  const handleValidate = (values: object) => {
    let schema = {
      ...presenceFieldConstraint('title'),
      ...timeConstraint('start_time'),
      ...presenceFieldConstraint('start_date'),
      ...timeConstraint('end_time'),
      ...presenceFieldConstraint('end_date'),
    }

    return validate(values, schema)
  }

  const handleCountTextLength = (e: any, fieldName: string) => {
    valueLength = e.target.value.length
    if (fieldName === 'description' && valueLength !== 0 && valueLength <= 140) {
      inputMaxLength = 140
      maxLengthDesc = 140 - valueLength
    }
    if (selectedEvent.notes && valueLength === 0) {
      maxLengthDesc = 140
    }
    if (!selectedEvent.notes && valueLength === 0) {
      maxLengthDesc = 140
    }
  }

  const Option = (props: any) => {
    const ref = useRef()

    useEffect(() => {
      // @ts-ignore
      props.isSelected && ref.current.scrollIntoView()
    }, [props.isSelected])

    return (
      <components.Option {...props} innerRef={ref} />
    )
  }

  const handleClick = (index: number) => {
    setActiveTab(`${index}`)
    setAgenda({
      ...agenda,
      access_mode: `${index}`,
    })
    setSelectedAccessMode(`${index}`)
  }


  return (
    <>
      {isOpenAddEventModal ? (
          <EditContainerWrapper onClick={() => handleCloseAddEventModal()}>
            <EditScheduleContainer onClick={(e) => e.stopPropagation()}>
              <MainContainer>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <Box width='100%' height='100%'>
                    <Text
                      fontFamily='Source Sans Pro'
                      fontWeight='700'
                      fontSize='32px'
                      lineHeight='40px'
                      color='#353B60'
                    >
                      Add Event
                    </Text>
                  </Box>
                  <Box cursor='pointer' width='100%'>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='end'
                      onClick={() => handleCloseAddEventModal()}
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                </Box>
                <EventDetailContentStyled>
                  <Form onSubmit={handleSubmit} validate={(value: any) => handleValidate(value)}>
                    {(props: any) => {
                      const { agenda_item_id } = props.values
                      props.form.change('agenda_item_id', agenda_item_id)

                      // @ts-ignore
                      return (
                        <>
                          <Box height='100%'>
                            <Box marginBottom='20px' marginTop='24px'>
                              <Input
                                name='title'
                                label='Title'
                                placeholder='Rehearsal'
                                id='inputTitle'
                                style={{
                                  height: '40px',
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '18px',
                                  color: '#414042',
                                  padding: '11px 12px 11px 12px',
                                }}
                              />
                            </Box>
                            <Box marginBottom='24px'>
                              <Input
                                name='notes'
                                label='Description'
                                placeholder='We can’t wait to celebrate with you all!'
                                isTextarea={true}
                                showMaxLength={true}
                                inputMaxLength={inputMaxLength}
                                style={{
                                  minHeight: '122px',
                                  maxHeight: '122px',
                                  padding: '11px 12px 89px 12px',
                                }}
                                maxLength={maxLengthDesc}
                                onInput={(e) => handleCountTextLength(e, 'description')}
                              />
                            </Box>

                            <BorderLine />

                            <Box marginBottom='24px' marginTop='20px'>
                              <Box display='flex' alignItems='center' marginBottom='20px'>
                                <Box marginRight='8px'>
                                  <TimeIcon stroke='#353B60' />
                                </Box>
                                <Text
                                  fontFamily='Source Sans Pro'
                                  fontWeight='700'
                                  fontSize='20px'
                                  lineHeight='24px'
                                  color='#353B60'
                                >
                                  Date and Time
                                </Text>
                              </Box>
                              <Box>
                                <Box marginBottom='20px' width='100%'>

                                  <DatesStyled>
                                    <Box width='50%'>
                                      <FormLabel>Start Date</FormLabel>
                                      <DayPickerInput
                                        name='start_date'
                                        disabledDays={{ before: new Date() }}
                                        placeholder={format(new Date(), 'MMMM d, yyyy', { locale: enUS })}
                                        format='MMMM d, yyyy'
                                        value={defaultDate}
                                      />
                                    </Box>

                                    <Box width='50%'>
                                      <FormLabel>Start Time</FormLabel>
                                      <TimePickerInput
                                        name='start_time'
                                        value={defaultStartTime}
                                      />
                                    </Box>
                                  </DatesStyled>
                                </Box>

                                <Box width='100%'>

                                  <DatesStyled>
                                    <Box width='50%'>
                                      <FormLabel>End Date</FormLabel>
                                      <DayPickerInput
                                        name='end_date'
                                        placeholder={format(new Date(), 'MMMM d, yyyy', { locale: enUS })}
                                        format='MMMM d, yyyy'
                                        disabledDays={{ before: props.values.start_date ?? new Date() }}
                                        value={defaultDate}
                                      />
                                    </Box>
                                    <Box width='50%'>
                                      <FormLabel>End Time</FormLabel>
                                      <TimePickerInput
                                        name='end_time'
                                        value={defaultEndTime}
                                      />
                                    </Box>
                                  </DatesStyled>
                                </Box>
                              </Box>
                            </Box>

                            <BorderLine />

                            <Box marginBottom='24px' marginTop='20px'>
                              <Box display='flex' justifyContent='space-between'>
                                <Box display='flex' alignItems='center' marginBottom='20px'>
                                  <Box marginRight='8px'>
                                    <LocationIcon stroke='#353B60' />
                                  </Box>
                                  <Text
                                    fontFamily='Source Sans Pro'
                                    fontWeight='700'
                                    fontSize='20px'
                                    lineHeight='24px'
                                    color='#353B60'
                                  >
                                    Location
                                  </Text>
                                </Box>
                              </Box>
                              <Box marginBottom='20px'>
                                <Autocomplete
                                  locationValue={locationValue}
                                  setLocationValue={setLocationValue}
                                  isLoaded={isLoaded}
                                  onSelect={onPlaceSelect}
                                  selectedValue={''}
                                  name='location_address'
                                />
                                {/*{center && (*/}
                                {/*  <Box marginTop='8px'>*/}
                                {/*    <Box marginBottom='20px'>*/}
                                {/*      <Box display='flex' alignItems='center'>*/}
                                {/*        <Box marginRight='10px'>*/}
                                {/*          {isChecked ? (*/}
                                {/*            <CheckboxChecked onClick={() => setIsChecked(!isChecked)} />*/}
                                {/*          ) : (*/}
                                {/*            <CheckboxUnchecked*/}
                                {/*              stroke='#353B60'*/}
                                {/*              onClick={() => setIsChecked(!isChecked)}*/}
                                {/*            />*/}
                                {/*          )}*/}
                                {/*        </Box>*/}
                                {/*        <Box>*/}
                                {/*          <Text*/}
                                {/*            color='#353B60'*/}
                                {/*            fontWeight='400'*/}
                                {/*            fontSize='14px'*/}
                                {/*            lineHeight='18px'*/}
                                {/*          >*/}
                                {/*            Show map in mobile app*/}
                                {/*          </Text>*/}
                                {/*        </Box>*/}
                                {/*      </Box>*/}
                                {/*    </Box>*/}
                                {/*    {isChecked && isLoaded && <Map center={center} />}*/}
                                {/*  </Box>*/}
                                {/*)}*/}
                              </Box>
                              <Box>
                                <Select
                                  name='timezone'
                                  label='Time Zone'
                                  width='100%'
                                  placeholder='US/Central'
                                  onChange={(value: any) => setTimeZoneVal(value)}
                                  options={map((zone) => ({ value: zone, label: zone }), moment.tz.names())}
                                  value={timeZoneVal}
                                  maxMenuHeight={200}
                                  menuPlacement='auto'
                                  components={{
                                    IndicatorSeparator: () => null,
                                    Option,
                                  }}
                                />
                              </Box>
                            </Box>

                            <BorderLine />

                            <Box marginTop='20px' paddingBottom='24px'>
                              <Box display='flex' alignItems='center' marginBottom='20px'>
                                <Box marginRight='8px'>
                                  <AccessModeIcon stroke='#414042' />
                                </Box>
                                <Text
                                  fontFamily='Source Sans Pro'
                                  fontWeight='700'
                                  fontSize='20px'
                                  lineHeight='24px'
                                  color='#353B60'
                                >
                                  Event Access
                                </Text>
                              </Box>
                              <Box>
                                <TabsRound
                                  activeTab={activeTab}
                                  handleClick={handleClick}
                                  options={EventAccessOptions}
                                />
                                <Box>
                                  {activeTab === '0' ? (
                                    <Box marginTop={20}>
                                      <Text
                                        fontFamily='Source Sans Pro'
                                        fontWeight={400}
                                        fontSize='14px'
                                        lineHeight='20px'
                                        color='#73737B'
                                      >
                                        All guests can see this event.
                                      </Text>
                                    </Box>
                                  ) : activeTab === '1' ? (
                                    <Box marginTop={20}>
                                      <Text
                                        fontFamily='Source Sans Pro'
                                        fontWeight={400}
                                        fontSize='14px'
                                        lineHeight='20px'
                                        color='#73737B'
                                      >
                                        Only guests added to the white list can see this event.
                                      </Text>
                                      <Box
                                        border='1px solid #E8E8ED'
                                        borderRadius='8px'
                                        background='#FFFFFF'
                                        marginTop='20px'
                                        padding='8px 12px'
                                        onClick={() => setIsOpenAddGuestModal(true)}
                                      >
                                        <Button
                                          onClick={() => {
                                          }}
                                          fontFamily='Source Sans Pro !important'
                                          color='#5458F7'
                                          fontSize='16px'
                                          fontWeight={400}
                                          padding='0'
                                          style={{ height: '24px', padding: 0 }}
                                        >
                                          <Box mr={8}>
                                            <PlusIcon fill='currentColor' />
                                          </Box>
                                          Add Guests
                                        </Button>
                                      </Box>
                                      {isOpenAddGuestModal && (
                                        <AddGuestsModal
                                          setGuestCount={() => {
                                          }}
                                          setIsOpenAddGuestModal={setIsOpenAddGuestModal}
                                          wuid={wuid}
                                          agenda_item_id={agenda_item_id}
                                          isOpen={isOpenAddGuestModal}
                                          setIsOpen={setIsOpenAddGuestModal}
                                        />
                                      )}
                                    </Box>
                                  ) : (
                                    <Box>
                                      <Box marginTop={20}>
                                        <Text
                                          fontFamily='Source Sans Pro'
                                          fontWeight={400}
                                          fontSize='14px'
                                          lineHeight='20px'
                                          color='#73737B'
                                        >
                                          Only admins can see this event.
                                        </Text>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </Box>

                            <BorderLine />

                            <Box marginTop='20px' paddingBottom='24px'>
                              <Box display='flex' alignItems='center' marginBottom='20px'>
                                <Box marginRight='8px'>
                                  <DressCodeIcon stroke='#414042' />
                                </Box>
                                <Text
                                  fontFamily='Source Sans Pro'
                                  fontWeight='700'
                                  fontSize='20px'
                                  lineHeight='24px'
                                  color='#353B60'
                                >
                                  Dress Code
                                </Text>
                              </Box>
                              <Box>
                                <Input
                                  style={{
                                    padding: '11px 12px 11px 12px',
                                  }}
                                  name='attire'
                                  label='Dress Code'
                                  placeholder='Semi-formal'
                                />
                              </Box>
                            </Box>
                          </Box>
                          <ButtonsStyled>
                            <SubmitContainerStyled>
                              <CancelButtonStyled
                                onClick={() => handleCloseAddEventModal()}
                              >
                                Cancel
                              </CancelButtonStyled>

                              <SubmitButtonStyled
                                onClick={() => handleSubmit(props)}
                              >
                                Save
                              </SubmitButtonStyled>
                            </SubmitContainerStyled>
                          </ButtonsStyled>
                        </>
                      )
                    }}
                  </Form>
                </EventDetailContentStyled>
              </MainContainer>
            </EditScheduleContainer>
          </EditContainerWrapper>
        ) :
        null
      }
    </>
  )
}

export default AddEventModal