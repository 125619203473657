import React, { FC } from 'react'

import { Box, Text } from 'components/ui'

const UnreadMessages: FC<{ value: number }> = ({ value }) => (
  <Box
    position="absolute"
    display="flex"
    right="12px"
    bottom="11px"
    bg="primary"
    borderRadius="11px"
    px="7px"
    py="4px"
  >
    <Text fontSize="13px" fontWeight={600} color="white" lineHeight={1}>
      {value}
    </Text>
  </Box>
)

export default UnreadMessages
