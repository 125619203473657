import React, { FC, useCallback, KeyboardEvent } from 'react'
import { useDispatch } from 'react-redux'
import { Form, FormRenderProps } from 'react-final-form'
import { toast } from 'react-toastify'

import { Input } from 'components/form'
import { addComment } from 'store/modules/comments/actions'

const CommentForm: FC<{ postId: string }> = ({ postId }) => {
  const dispatch = useDispatch()

  const onAddComment = useCallback(async (data) => dispatch(addComment(data)), [
    dispatch,
  ])
  const handleSubmit = useCallback(
    async (values, { reset }) => {
      try {
        await onAddComment(values)

        setTimeout(() => reset(), 300)
      } catch (error) {
        toast(error.message)
      }
    },
    [onAddComment]
  )

  const renderForm = ({
    dirty,
    handleSubmit,
  }: FormRenderProps<{ post_id: string }>) => {
    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
      if (dirty && e.key === 'Enter') handleSubmit()
    }

    return (
      <Input
        name="caption"
        placeholder="Write a comment…"
        onKeyPress={handleKeyPress}
        border="none"
        px="0px"
      />
    )
  }

  return (
    <Form
      render={renderForm}
      initialValues={{ post_id: postId }}
      onSubmit={handleSubmit}
    />
  )
}

export default CommentForm
