import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AccessType } from '../utils'

// Icons
import { ReactComponent as Icon } from 'images/magnify.svg'
import { ReactComponent as CheckboxChecked } from 'images/checkbox-checked.svg'
import { ReactComponent as CheckboxUnchecked } from 'images/checkbox-unchecked-modal.svg'

// Store tools
import { shareAlbum } from 'store/modules/albums/actions'
import { getGuests, getGuestsIsLoaded } from 'store/modules/guests/selectors'
import { getWuid } from 'store/modules/auth/selectors'
import { listAdmin } from 'store/modules/guests/actions'

// Components
import { Box, Button, Modal, Select, Text } from 'components/ui'
import { ModalContainer, ModalContent } from 'components/Modal/utils'
import ModalHeader from 'components/Modal/ModalHeader'

type Props = {
  isOpen: boolean,
  onClose: () => void,
  albumId: string,
}

const ShareModal: FC<Props> = ({ isOpen, onClose, albumId }) => {
  const dispatch = useDispatch()

  const wuid = useSelector(getWuid)

  const [, setIsLoading] = useState(false)
  const [allGuests, setAllGuests] = useState<any[]>()
  const [selectedAccessType, setSelectedAccessType] = useState<{ value: string, label: string }>()
  const [selectedGuests, setSelectedGuests] = useState<string[]>([])

  const isLoaded = useSelector(getGuestsIsLoaded)
  const guests = useSelector(getGuests)

  const onLoad = useCallback(async () => dispatch(listAdmin({})), [dispatch])

  const onInvite = useCallback(async (data) => dispatch(shareAlbum(data)), [
    dispatch,
  ])

  const handleLoad = useCallback(async () => {
    setIsLoading(true)

    try {
      await onLoad()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading, onLoad])

  useEffect(() => {
    if (!isLoaded) handleLoad()
  }, [isLoaded, handleLoad])

  useEffect(() => {
    setAllGuests(guests)
  }, [guests])

  const handleSelectGuests = (guestId: string) => {
    let selectedGuestsCopy = [...selectedGuests]

    if (selectedGuestsCopy?.includes(guestId)) {
      const guestIndex = selectedGuestsCopy.findIndex((selectedGuestId: string) => guestId === selectedGuestId)
      selectedGuestsCopy.splice(guestIndex, 1)
    } else {
      const groupGuestIds = selectedGuestsCopy?.length > 0 ? selectedGuestsCopy : []
      groupGuestIds.push(guestId)
      selectedGuestsCopy = groupGuestIds
    }
    setSelectedGuests(selectedGuestsCopy)
  }

  const handleSearchGuest = (event: any) => {
    const query = event.target.value

    if (query !== '') {
      let filtered = guests?.filter((item) => {
        return item.first_name?.toLowerCase().includes(query.toLowerCase()) || item.last_name?.toLowerCase().includes(query.toLowerCase())
      })
      setAllGuests(filtered)
    } else if (query === '') {
      setAllGuests(guests)
    }
  }

  const handleSubmit = async () => {
    try {
      const data = {
        album_id: albumId,
        wuid: wuid,
        uuid: selectedGuests[0],
        access_type: selectedAccessType?.value,
      }

      await onInvite(data)

      onClose()
    } catch (error) {
      toast(error.message)
    }
  }


  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalContainer>
        <ModalHeader
          title='Share Album'
          onClose={onClose}
        />
        <Box position='relative' width='100%' borderTop='1px solid #E8E8ED'>
          <input
            style={{
              backgroundColor: '#FFFFFF',
              border: '0 solid #CBCBD3',
              borderRadius: '8px',
              paddingLeft: '48px',
              paddingRight: 0,
              width: '100%',
              height: '56px',
              outline: 'none',
            }}
            placeholder='Search'
            onChange={(e: any) => handleSearchGuest(e)}
          />

          <Box position='absolute' top='0' bottom='0' display='flex' alignItems='center' left='16px'>
            <Icon stroke='#6d7589' height={20} width={20} />
          </Box>
        </Box>
        <ModalContent>
          {allGuests?.map((guest) => {
            return (
              <Box key={guest.guest_id}>
                <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
                  <Box padding='8px 16px' minWidth='250px' maxWidth='250px' width='100%'>
                    <Box display='flex' justifyContent='space-between'>
                      <Box
                        display='flex'
                        width='100%'
                        cursor='pointer'
                      >
                        <Box>
                          {guest.user.image ? (
                            <Box
                              width='24px'
                              height='24px'
                              backgroundColor='#353B60'
                              borderRadius='50%'
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                              marginRight='8px'
                            >
                              <Text
                                fontWeight='700'
                                fontSize='12px'
                                lineHeight='15px'
                                color='#FFFFFF'
                              >
                                {guest?.first_name.slice(0, 1).toUpperCase()}{guest?.last_name.slice(0, 1).toUpperCase()}
                              </Text>
                            </Box>
                          ) : (
                            <Box
                              width='24px'
                              height='24px'
                              borderRadius='50%'
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                              marginRight='8px'
                              backgroundColor='#353B60'
                              backgroundPosition='center'
                              backgroundRepeat='no-repeat'
                              backgroundSize='cover'
                              backgroundImage={`url(${guest?.user?.image})`}
                            />
                          )}
                        </Box>
                        <Box>
                          <Text
                            fontFamily='Source Sans Pro'
                            fontSize='14px'
                            fontWeight={400}
                            lineHeight='20px'
                            color='#414042'
                          >
                            {guest.first_name} {guest.last_name}
                          </Text>
                        </Box>
                      </Box>
                      <Box marginLeft={20}>
                        {selectedGuests.length > 0 && selectedGuests.includes(guest.user.uuid) ? (
                          <CheckboxChecked onClick={() => handleSelectGuests(guest.user.uuid)} />
                        ) : (
                          <CheckboxUnchecked
                            stroke='#73737B'
                            onClick={() => handleSelectGuests(guest.user.uuid)}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box padding='8px 16px' width='190px'>
                    <Select
                      name='access_type'
                      width='100%'
                      onChange={(value: any) => setSelectedAccessType(value)}
                      options={AccessType}
                      value={selectedAccessType}
                      maxMenuHeight={200}
                      menuPlacement='auto'
                      menuPosition='fixed'
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )
          })}
        </ModalContent>
        <Box padding='16px' borderRadius={0} bg='white'>
          <Box display='flex' justifyContent='flex-end'>
            <Box>
              <Button
                onClick={() => onClose()}
                height='40px'
                style={{
                  border: '1px solid #CBCBD3',
                  borderRadius: '8px',
                  color: '#5458F7',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '20px',
                  padding: '10px 16px',
                  fontFamily: 'Source Sans Pro',
                }}>
                Cancel
              </Button>
            </Box>
            <Box marginLeft='16px'>
              <Button
                onClick={handleSubmit}
                height='40px'
                style={{
                  backgroundColor: '#5458F7',
                  borderRadius: '8px',
                  padding: '10px 18px',
                  color: '#FFFFFF',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  fontFamily: 'Source Sans Pro',
                }}
              >
                Invite
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default ShareModal