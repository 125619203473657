import React, { FC } from 'react'
import { FormRenderProps } from 'react-final-form'

import { Box, Avatar, FormLabel, Button } from 'components/ui'
import {
  Input,
  Radio,
  DayPickerInput,
  GeosuggestInput,
  ColorSelector,
  FontSelector,
} from 'components/form'
import { withGoogleMaps } from 'utils/gmaps'
import { getFullName } from 'utils/guest'
import { ReactComponent as RingsIcon } from 'images/rings.svg'
import NameInput from './NameInput'
import ImageUploader from './ImageUploader'

type Props = {
  googleMaps?: any
}

const EditWeddingForm: FC<FormRenderProps & Props> = ({
  googleMaps,
  handleSubmit,
  initialValues,
  submitting,
}) => (
  <Box as="form" onSubmit={handleSubmit}>
    <Box position="absolute" bottom={4} right="50px">
      <Button variant="primary" type="submit" disabled={submitting}>
        Save
      </Button>
    </Box>

    <Box px={4}>
      <Box
        pb={2}
        borderBottom="1px solid"
        borderColor="gray"
        width="100%"
        display="flex"
        justifyContent="center"
      >
        <NameInput />
      </Box>
    </Box>

    <Box px={4} maxHeight="calc(100vh - 150px)" width="100%" overflowY="auto">
      <Box
        display="flex"
        position="relative"
        width="100%"
        pt="28px"
        pb="21px"
        borderBottom="1px solid"
        borderColor="gray"
      >
        <Box
          position="absolute"
          top="50px"
          left="calc(50% - 22px)"
          margin="0 auto"
        >
          <RingsIcon width="44" height="42" />
        </Box>

        <Box
          width={1 / 2}
          pr={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Avatar
            size={96}
            image={initialValues.spouse1_image}
            name={getFullName([
              initialValues.spouse1_first_name,
              initialValues.spouse1_last_name,
            ])}
          />

          <Box width="100%" mt="18px" mb={1}>
            <Input label="My name" name="spouse1_first_name" disabled />
          </Box>

          <Box width="100%">
            <FormLabel>My role</FormLabel>

            <Box display="flex" alignItems="center">
              <Box mr={3}>
                <Radio
                  name="spouse1_role"
                  value="bride"
                  label="Bride"
                  disabled
                />
              </Box>

              <Radio name="spouse1_role" value="groom" label="Groom" disabled />
            </Box>
          </Box>
        </Box>

        <Box
          width={1 / 2}
          pl={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Avatar
            size={96}
            image={initialValues.spouse2_image}
            name={getFullName([
              initialValues.spouse2_first_name,
              initialValues.spouse2_last_name,
            ])}
          />

          <Box width="100%" mt="18px" mb={1}>
            <Input label="Partner’s name" name="spouse2_first_name" disabled />
          </Box>

          <Box width="100%">
            <FormLabel>Partner’s role</FormLabel>

            <Box display="flex" alignItems="center">
              <Box mr={3}>
                <Radio
                  name="spouse2_role"
                  value="bride"
                  label="Bride"
                  disabled
                />
              </Box>

              <Radio name="spouse2_role" value="groom" label="Groom" disabled />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box pt="21px" display="flex" width="100%">
        <Box
          width={1 / 2}
          pr={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box width="100%">
            <ColorSelector
              name="theme_color"
              label="Color"
              placeholder="Select color"
            />
          </Box>

          <Box width="100%" mt={1}>
            <FontSelector
              name="theme_font"
              label="Font"
              placeholder="Select font"
              menuPlacement="top"
            />
          </Box>

          <Box width="100%" mt={1}>
            <FormLabel>Wedding picture</FormLabel>

            <ImageUploader />
          </Box>
        </Box>

        <Box
          width={1 / 2}
          pl={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box width="100%">
            <DayPickerInput
              name="wedding_date"
              label="Wedding Date"
              format="MMMM d, yyyy"
              disabledDays={{ before: new Date() }}
            />
          </Box>

          <Box width="100%" mt={1}>
            <GeosuggestInput
              googleMaps={googleMaps}
              name="address"
              label="Venue"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
)

export default withGoogleMaps(EditWeddingForm)
