export const defaultAction = {
  type: null,
  name: null,
  action: null,
}

export const deleteMenu = {
  type: 'central',
  name: 'delete',
  action: 'menu',
}

export const deleteGroup = {
  type: 'central',
  name: 'delete',
  action: 'group',
}

export const addCourse = {
  type: 'fromTheLeftSide',
  name: 'Course',
  action: 'add',
}

export const editCourse = {
  type: 'fromTheLeftSide',
  name: 'Course',
  action: 'edit',
}