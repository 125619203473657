import React, { FC, useState, useCallback } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'

import { selectRoom } from 'store/modules/messages/actions'
import { Box, Text } from 'components/ui'
import { ReactComponent as ClearHistoryIcon } from 'images/trash-2.svg'
import Modal from './Modal'

type Props = {
  socket: WebSocket
  roomId?: string
  roomName?: string
}

const DeleteChat: FC<Props> = ({ socket, roomId, roomName }) => {
  const dispatch = useDispatch()
  const { wuid } = useParams<{ wuid: string }>()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSelectRoom = useCallback(
    (roomId) => dispatch(selectRoom(roomId)),
    [dispatch]
  )
  const handleDelete = () => {
    socket.send(
      JSON.stringify({
        c: 'r',
        wuid,
        room_id: roomId,
      })
    )

    handleSelectRoom(null)
  }
  const handleClickButton = () => setIsModalOpen(!isModalOpen)
  const handleCloseModal = () => setIsModalOpen(false)

  return (
    <>
      <Modal
        roomName={roomName}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onDelete={handleDelete}
      />

      <Box
        px="10px"
        py={1}
        cursor="pointer"
        display="flex"
        alignItems="center"
        onClick={handleClickButton}
      >
        <Box mr={3}>
          <ClearHistoryIcon stroke="#e64646" />
        </Box>

        <Text color="red" fontSize="15px">
          Delete Chat
        </Text>
      </Box>
    </>
  )
}

export default DeleteChat
