import React, { FC } from 'react'

import { Box, Text } from 'components/ui'

type Props = { progress: string | number }

const PhotoLoadingIndicator: FC<Props> = ({ progress }) => (
  <Box width="252px" height="252px" pr="4px" pb="4px">
    <Box
      width="100%"
      height="100%"
      border="2px solid"
      borderColor="gray"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={3}
    >
      <Text fontFamily="1" fontSize={1}>
        {progress} of 100%
      </Text>

      <Box position="relative" height="3px" width="100%" bg="gray" mt="12px">
        <Box
          position="absolute"
          left="0px"
          top="0px"
          height="3px"
          bg="primary"
          width={`${progress}%`}
        />
      </Box>
    </Box>
  </Box>
)

export default PhotoLoadingIndicator
