import styled from 'styled-components'

import { Box } from 'components/ui'

const Item = styled(Box).attrs({
  as: 'button',
  pl: '24px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  border: 'none',
  bg: 'white',
})`
  appearance: none;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: #f6f7f9;
  }

  & > svg {
    margin-right: 22px;
  }
`

export default Item
