import React, { FC } from 'react'

import { Box, Text, Button } from 'components/ui'
import { GeosuggestInput, CheckBox } from 'components/form'
import { withGoogleMaps } from 'utils/gmaps'
import { ReactComponent as Icon } from 'images/ww-step6.svg'

type Props = {
  googleMaps?: any
  isValid: boolean
  isNotSureAboutVenue: boolean
  onBack: VoidFunction
  onContinue: VoidFunction
}

const Step6: FC<Props> = ({
  googleMaps,
  isValid,
  isNotSureAboutVenue,
  onBack,
  onContinue,
}) => (
  <Box
    width="100%"
    maxWidth="740px"
    boxShadow="0px 8px 24px rgba(0, 0, 0, 0.15)"
    bg="white"
    display="flex"
    flexDirection="column"
    alignItems="center"
    pt="70px"
    pb="32px"
    px="26px"
    borderRadius="4px"
  >
    <Box mt="-98px" mb="30px">
      <Icon />
    </Box>

    <Text
      fontSize={['28px', 5]}
      fontWeight="bold"
      color="secondary"
      lineHeight={['normal', '45px']}
      textAlign="center"
    >
      Where will you say “I do”?
    </Text>

    <Box maxWidth="600px" width="100%" mt="8px" display="flex">
      <Text
        fontWeight={300}
        fontSize="20px"
        lineHeight="28px"
        color="secondary"
        textAlign="center"
      >
        Ahh! Choosing your venue is one of the biggest (and most exciting)
        decisions you’ll need to make!
      </Text>
    </Box>

    <Box
      width="100%"
      maxWidth="400px"
      display="flex"
      flexDirection="column"
      mt={['32px', '40px']}
    >
      <Text fontSize={2} fontWeight={600} color="secondary">
        Wedding venue:
      </Text>

      <Box mt="8px">
        <GeosuggestInput
          googleMaps={googleMaps}
          name="address"
          placeholder="Find venue"
          disabled={isNotSureAboutVenue}
          hideValue={isNotSureAboutVenue}
        />

        <Box mt="22px" display="flex" alignItems="center">
          <CheckBox name="isNotSureAboutVenue" />

          <Box ml="10px" mt="-4px">
            <Text fontFamily="1">We’re still looking for the right venue</Text>
          </Box>
        </Box>
      </Box>

      <Box mt="98px" display="flex" justifyContent="space-between">
        <Button variant="secondary" height="40px" onClick={onBack}>
          Back
        </Button>

        <Button
          variant="primary"
          height="40px"
          disabled={!isValid}
          onClick={onContinue}
        >
          Next
        </Button>
      </Box>
    </Box>
  </Box>
)

export default withGoogleMaps(Step6)
