import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { Button, Text } from 'components/ui'
import { WEDDING_CREATE } from 'constants/paths'

const CreateButton = () => {
  const { push } = useHistory()

  const onClick = useCallback(() => push(WEDDING_CREATE), [push])

  return (
    <Button
      fullWidth
      height="40px"
      borderRadius="6px"
      bg="#63C7C9"
      onClick={onClick}
    >
      <Text fontWeight={600} fontSize="16px" color="white">
        Create Wedding
      </Text>
    </Button>
  )
}

export default CreateButton
