import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { path, pathOr } from 'ramda'

// Components
import { Modal, Box, Text, Loading, Button } from 'components/ui'

// Store tools
import { zipStart, zipResult } from 'store/modules/photos/actions'

type Props = {
  isOpen: boolean
  photoIds: string[]
  onClose: () => void
}

const ExportModal: FC<Props> = ({ isOpen, photoIds, onClose }) => {
  const dispatch = useDispatch()

  const [ident, setIdent] = useState<string>()
  const [url, setUrl] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)

  const onZipStart = useCallback(
    async () => dispatch(zipStart({ photo_ids: photoIds.join(',') })),
    [dispatch, photoIds],
  )

  const onZipResult = useCallback(async () => dispatch(zipResult({ ident })), [
    dispatch,
    ident,
  ])

  const handleClose = useCallback(() => {
    setIdent(undefined)
    setUrl(undefined)
    setIsLoading(false)
    onClose()
  }, [onClose])

  const handleOpenUrl = useCallback(() => {
    window.open(url)
    setIsDownloaded(true)
  }, [url])

  useEffect(() => {
    if (isDownloaded) {
      handleClose()
    }
  }, [handleClose, isDownloaded])

  useEffect(() => {
    const handleZipStart = async () => {
      setIsLoading(true)

      try {
        setIdent(path(['value', 'payload', 'ident'], await onZipStart()))
      } catch (error) {
        toast(error.message)
        handleClose()
      }
    }

    if (isOpen) {
      handleZipStart()
    }
  }, [isOpen, onZipStart, handleClose])

  useEffect(() => {
    const handleZipRes = async () => {
      try {
        const res = pathOr(
          { status: 'failed', url: '' },
          ['value', 'payload'],
          await onZipResult(),
        )

        if (!res || res.status === 'failed' || res.status === 'expired') {
          throw new Error('Something goes wrong. Please try again')
        }

        if (res.status === 'completed') {
          setUrl(res.url)
          setIsLoading(false)
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1500))

          handleZipRes()
        }
      } catch (error) {
        toast(error.message)
        handleClose()
      }
    }

    if (ident) {
      handleZipRes()
    }
  }, [ident, onZipResult, handleClose])

  const modalStyle = { content: { maxWidth: 422, top: '40vh' } }

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={handleClose}>
      <Box py={3} px={4} borderRadius={0} bg='white'>
        <Box mb={1}>
          <Text fontFamily='1' fontSize={3} fontWeight={600}>
            Export Photos
          </Text>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          minHeight='150px'
        >
          {isLoading && <Loading m='0 auto' />}

          {url && (
            <Button variant='primary' onClick={handleOpenUrl}>
              Download Files
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default ExportModal
