import { VERIFY } from '../actionTypes'

export const verify = (
  uuid: string,
  verification_code: string,
  isDevice: boolean
) => ({
  type: VERIFY.name,
  api: {
    url: 'user/verify',
    data: {
      uuid,
      verification_code,
      isDevice,
      field_list: [
        'uuid',
        'email',
        'phone',
        'first_name',
        'last_name',
        'image',
        'contact_email',
        'contact_phone',
      ].join(','),
    },
  },
})
