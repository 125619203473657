import styled, { css } from 'styled-components'
import { path } from 'ramda'

import Box from '../Box'

export default styled(Box).attrs({
  position: 'relative',
})<{ overlayPosition?: 'right' | 'bottom' }>`
  .DayPickerInput {
    width: 100%;

    &-Overlay {
      ${(props) =>
        props.overlayPosition === 'right'
          ? css`
              left: 100% !important;
              margin-top: -57px;
              margin-left: 16px;
            `
          : css`
              margin-top: ${path(['theme', 'space', 0], props)}px;
            `}

      z-index: 3;
      border-radius: 6px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      border: 1px solid #e3e3e3;
    }
  }

  .DayPicker {
    &-Month {
      margin: 0 19px;
      margin-top: 19px;
    }

    &-Caption {
      padding: 0;
      margin-bottom: 13px;

      & > div {
        font-family: ${path(['theme', 'fonts', '1'])};
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #353b60;
      }
    }

    &-Weekday {
      padding-bottom: 10px;

      & > abbr {
        font-family: Work Sans;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #a3a3ac;
      }
    }

    &-Day {
      padding: 8px !important;
      font-family: Work Sans;
      font-size: 13px;
      line-height: 16px;
      color: #414042;

      &--selected {
        background-color: ${path(['theme', 'colors', 'primary'])} !important;
      }

      &--disabled {
        color: #b8c1cc;
      }
    }
  }
`
