import React, { useState, useCallback, MouseEvent } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'

import { Button, Text, Box } from 'components/ui'
import { getSCharts } from 'store/modules/seatings/selectors'
import { removeChart } from 'store/modules/seatings/actions'
import { ReactComponent as ChevronIcon } from './chevron.svg'
import { ReactComponent as EditIcon } from './edit.svg'
import { ReactComponent as DeleteIcon } from './delete-2.svg'
import { ReactComponent as PlusIcon } from './plus-circle.svg'

const Item = styled(Button)`
  background-color: #fff;

  &:hover {
    background-color: #f3f3f6;
  }
`

type Props = {
  caption?: string
  onOpenCreateModal: VoidFunction
  setActiveChartId: (chartId: string) => void
  onClickEditButton: (chartId: string) => void
}

const Title = ({
  caption,
  onOpenCreateModal,
  setActiveChartId,
  onClickEditButton,
}: Props) => {
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const { wuid } = useParams<{ wuid: string }>()
  const sCharts = useSelector(getSCharts(wuid))

  const onToggle = useCallback(() => setOpen((isOpen) => !isOpen), [])
  const onClose = useCallback(() => setOpen(false), [])

  const onRemoveChart = useCallback(
    async (chartId: string) => dispatch(removeChart(chartId)),
    [dispatch]
  )

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) =>
      setActiveChartId(e.currentTarget.value),
    [setActiveChartId]
  )

  const onClickEdit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) =>
      onClickEditButton(e.currentTarget.value),
    [onClickEditButton]
  )

  const onClickRemove = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => onRemoveChart(e.currentTarget.value),
    [onRemoveChart]
  )

  const renderItem = useCallback(
    (sChart: typeof sCharts[0]) => (
      <Box key={sChart.id} position="relative">
        <Item
          as="button"
          value={sChart.id}
          height="40px"
          width="100%"
          pl="16px"
          fontWeight={600}
          fontSize="16px"
          fontFamily="0"
          color="#353B60"
          justifyContent="start"
          onClick={onClick}
        >
          {sChart.caption}
        </Item>

        <Box position="absolute" right="16px" top="8px" display="flex">
          <Button
            as="button"
            value={sChart.id}
            p="0px"
            height="20px"
            width="20px"
            bg="transparent"
            mr="8px"
            onClick={onClickEdit}
          >
            <EditIcon />
          </Button>

          <Button
            as="button"
            value={sChart.id}
            p="0px"
            height="20px"
            width="20px"
            bg="transparent"
            onClick={onClickRemove}
          >
            <DeleteIcon />
          </Button>
        </Box>
      </Box>
    ),
    [onClick, onClickEdit, onClickRemove]
  )

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <Box position="relative">
        <Button px="0px" height="40px" onClick={onToggle}>
          <Text
            fontWeight="bold"
            fontSize="32px"
            lineHeight="40px"
            color="#353B60"
          >
            {caption}
          </Text>

          <Box mr="8px" />

          <ChevronIcon />
        </Button>

        {isOpen && (
          <Box
            position="absolute"
            top="54px"
            left="0px"
            zIndex={3}
            bg="white"
            border="2px solid #E8E8ED"
            boxShadow="0px 4px 13px rgba(0, 0, 0, 0.05)"
            borderRadius="8px"
            width="240px"
            py="8px"
          >
            {sCharts.map(renderItem)}

            <Button
              px="16px"
              height="40px"
              width="100%"
              justifyContent="start"
              onClick={onOpenCreateModal}
            >
              <PlusIcon />

              <Box mr="8px" />

              <Text fontWeight={600} fontSize="16px" color="#5458F7">
                Seating Chart
              </Text>
            </Button>
          </Box>
        )}
      </Box>
    </OutsideClickHandler>
  )
}

export default Title
