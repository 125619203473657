import React, { FC } from 'react'

import { Box, Text } from 'components/ui'

export enum CodeStatus {
  AVAILABLE,
  NOT_AVAILABLE,
  NOT_VALID,
}

const TITLES = {
  [CodeStatus.AVAILABLE]: 'This wedding code is available to use',
  [CodeStatus.NOT_AVAILABLE]: 'This wedding code is already in use',
  [CodeStatus.NOT_VALID]: 'This wedding code is too short',
}

const DESCRIPTIONS = {
  [CodeStatus.AVAILABLE]: 'Great! This code works well for your guests.',
  [CodeStatus.NOT_AVAILABLE]: 'Think of a different code for your guests.',
  [CodeStatus.NOT_VALID]: 'Think of at least 6 characters',
}

const COLORS = {
  [CodeStatus.AVAILABLE]: '#2BAF43',
  [CodeStatus.NOT_AVAILABLE]: '#FF021C',
  [CodeStatus.NOT_VALID]: '#FF021C',
}

type Props = {
  progress: number
  status: CodeStatus
}

export const CodeStatusIndicator: FC<Props> = ({ progress, status }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      position="absolute"
      right="-256px"
      top="-46px"
      width="220px"
      bg="white"
      boxShadow={0}
      borderRadius="6px"
      border="1px solid #E3E3E3"
      p="20px"
    >
      <Text fontFamily="1" fontWeight="600" fontSize="14px" lineHeight="16px">
        {TITLES[status]}
      </Text>

      <Box mt="12px" />

      <Box height="4px" borderRadius="2px" bg="#e6eaef">
        <Box
          height="100%"
          borderRadius="2px"
          width={`${Math.floor(progress * 100)}%`}
          bg={COLORS[status]}
        />
      </Box>

      <Box mt="12px" />

      <Text fontFamily="1" fontSize="14px" color="#6D7589" lineHeight="16px">
        {DESCRIPTIONS[status]}
      </Text>
    </Box>
  )
}
