import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { Button } from 'components/ui'
import { getPdf } from 'store/modules/seatings/actions'
import { ReactComponent as ExportIcon } from './export-floorplan.svg'

type Props = {
  chartId?: string
}

const ExportFloorplan = ({ chartId }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const onGetPdf = useCallback(
    async (chartId: string) => dispatch(getPdf(chartId, 1, 1)),
    [dispatch]
  )

  const onClick = useCallback(async () => {
    if (!chartId) {
      return
    }

    try {
      setIsLoading(true)

      const res = (await onGetPdf(chartId)) as any
      window.open(res?.value?.payload?.pdf_url)
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [chartId, onGetPdf])

  return (
    <Button
      width="40px"
      height="40px"
      border="1px solid #CBCBD3"
      borderRadius="10px"
      disabled={isLoading}
      onClick={onClick}
    >
      <ExportIcon />
    </Button>
  )
}

export default ExportFloorplan
