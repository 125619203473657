import create from 'utils/createReduxPromiseActionType'

export const PUSH_FILE = 'photos/PUSH_FILE'
export const ADD = create('photos/ADD')
export const CHANGE_FILE_OFFSET = 'photos/CHANGE_FILE_OFFSET'
export const CLEAR_FILES = 'photos/CLEAR_FILES'

export const SET_PROGRESS = 'photos/SET_PROGRESS'

export const ZIP_START = create('photos/ZIP_START')
export const ZIP_RESULT = create('photos/ZIP_RESULT')
