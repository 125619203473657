import { ADD_MENU_ITEM } from '../../../actionTypes'
import { AddMenuItemNeedData } from '../../../../../../routes/FoodMenu/tipes_interfaces'

export const add = (data: AddMenuItemNeedData) => {
  return {
    type: ADD_MENU_ITEM.name,
    api: {
      url: 'menu/add_item',
      data,
    },
  }
}