import styled from 'styled-components'
import { Box, Button, Text } from 'components/ui'

export const Title = styled.p`
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #63C7C9;
  margin-top: 0;
  margin-bottom: 0;
`

export const Table = styled(Box)`
  overflow-y: auto;
  max-height: calc(100vh - 120px - 16px - 40px - 16px - 40px - 24px);
  height: 100%;

  @media all and (max-width: 991px) {
    max-height: calc(100vh - 300px - 16px - 40px - 16px - 40px - 24px);
  }

  ::-webkit-scrollbar {
    width: 0;
  }
`

export const CalendarStyled = styled(Box)`
  overflow-y: auto;
  max-height: calc(100vh - 120px - 60px - 24px - 32px - 16px);
  height: 100%;

  .fc-direction-ltr {
    height: 100%;
  }

  .fc-toolbar-title {
    font-family: 'Source Sans Pro';
    color: #353B60;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
    height: 65px;
  }

  .fc-timegrid-col-events .fc-timegrid-event-harness {
    margin-left: 16px;
    margin-right: 2px;

    a {
      width: 100%;
    }
  }

  .fc-timegrid-col-bg {
    width: 100%;
  }

  .fc-timegrid-col-events {
    width: 100%;
  }

  .fc-timegrid-slot {
    background-color: #FFFFFF;
  }

  .fc-timegrid-now-indicator-container {
    background-color: #FFFFFF;
  }

  .fc .fc-timegrid-slot-label-cushion {
    color: #414042;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }

  .fc-theme-standard .fc-scrollgrid {
    border-left: unset !important;
    border-right: unset !important;
    border-color: #E5E5E5 !important;
  }

  col {
    width: 64px !important;
  }

  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
  }

  .fc .fc-scroller-liquid-absolute {
    ::-webkit-scrollbar {
      width: 0;
    }
  }

  .fc-theme-standard td, .fc-theme-standard th {
    border-right-width: 0 !important;
    border-color: #E5E5E5 !important;
  }

  .fc-timegrid-body {
    width: 100% !important;
  }

  .fc .fc-scrollgrid-section table {
    width: 100% !important;
  }

  .fc-event {
    right: 50px;
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    box-shadow: unset;
    border-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    position: sticky;
    z-index: 3;
    top: 0;
    background: #ffffff;
    margin-bottom: 0;
  }

  .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
    top: 34px;
  }
`

export const UrlBox = styled(Box)`
  height: 46px;
  overflow-y: auto;
  display: flex;
  align-items: center;

  ::-webkit-scrollbar {
    width: 0;
  }

  span {
    line-break: anywhere;
  }
`

export const TableRows = styled(Box)`
  position: relative;
  height: 70px;
  display: grid;
  border-radius: 8px;
  width: 100%;
`

export const TableRow = styled(Box)`
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const RowContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 14px 32px 14px 32px;
  align-items: center;
  border: 1px solid #E8E8ED;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const DottedText = styled(Text)`
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #414042;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const TitleText = styled(Text)`
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #414042;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const RowItems = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const ItemsStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const CalendarRow = styled(Box)`
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-table;
  border-radius: 8px;
  background-color: #F7F7FC;
`

export const CalendarContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 11px 32px 11px 24px;
  align-items: center;
  border: 1px solid #E8E8ED;
  border-radius: 8px;
  height: 100%;
`

export const SearchBox = styled(Box)`
  min-width: 320px;
  max-width: 320px;

  @media all and (max-width: 575px) {
    min-width: 100%;
    margin-top: 20px;
  }
`

export const BorderLine = styled(Box)`
  border-bottom: 1px solid #CBCBD3;
  opacity: 40%;
`

export const EditScheduleContainer = styled.div`
  background-color: #FFFFFF;
  position: relative;
  border-radius: 10px 10px 10px 0;
  max-width: 452px;
  margin-left: auto;
  height: calc(100vh - 120px);
  transition: 0.4s;
  animation-name: opening;
  animation-duration: .3s;
  animation-iteration-count: 1;
  @keyframes opening {
    0% {
      width: 0
    }
    100% {
      width: 452px
    }
  }
`

export const DatesStyled = styled.div`
  display: flex;
  gap: 12px;
`

export const EventDetailContentStyled = styled.div`
  max-height: calc(100vh - 250px);
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .DayPicker-wrapper {
    svg {
      color: #353B60;
    }
  }
`

export const ButtonsStyled = styled.div`
  padding: 16px;
  margin-left: -16px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-content: center;
  justify-content: end;
  width: calc(100%);
  border-bottom-left-radius: 10px;
  border-top: 1px solid #E8E8ED;
  background-color: #FFFFFF;
`

export const SubmitContainerStyled = styled.div`
  display: flex;
  align-content: center;
  justify-content: end;
`

export const CancelButtonStyled = styled(Button)`
  font-family: 'Source Sans Pro', normal, serif;
  color: #5458F7;
  line-height: 20px;
  font-style: normal;
  background-color: #FFFFFF;
  padding: 19px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  margin-right: 16px;
  border: 1px solid #CBCBD3;
`

export const SubmitButtonStyled = styled(Button)`
  font-family: 'Source Sans Pro', normal, serif;
  color: #FFFFFF;
  line-height: 20px;
  font-style: normal;
  background-color: #5458F7;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
`

export const OptionStyled = styled(Box)`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;

  &:hover {
    background-color: #F7F7FC;
  }
`

export const OptionBoxStyled = styled(Box)`
  max-height: 202px;
  overflow-y: auto;
  border: 1px solid #E8E8ED;
  border-radius: 8px;
  margin-top: 8px;

  ::-webkit-scrollbar {
    width: 0;
  }
`

export const TextStyled = styled(Box)`
  white-space: nowrap;,
width: 180 px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
`

export type tabTypes = '0' | '1' | '2';

export const EventAccessOptions: { name: tabTypes, title: string }[] = [
  {
    name: '0',
    title: 'All Guests',
  },
  {
    name: '1',
    title: 'Invite Only',
  },
  {
    name: '2',
    title: 'Private',
  },
]

export const eventAccessValues: { allGuests: number, whiteList: number, private: number } = {
  allGuests: 0,
  whiteList: 1,
  private: 2,
}

export const menuItems: { id: string, name: string }[] = [
  {
    id: '0',
    name: 'Edit Event',
  },
  {
    id: '1',
    name: 'Add to Calendar',
  },
  {
    id: '2',
    name: 'Delete',
  },
]

export const filterMenuItems: { id: string, name: string }[] = [
  {
    id: '3',
    name: 'All Events',
  },
  {
    id: '0',
    name: 'All Guests',
  },
  {
    id: '1',
    name: 'Invite Only',
  },
  {
    id: '2',
    name: 'Private',
  },
]