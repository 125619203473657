import React, { FC } from 'react'
import styled from 'styled-components'

import { Box } from 'components/ui'
import { AppPreview } from 'components/Layout'

const Wrapper = styled(Box)`
  padding-left: 40px;
  padding-top: 25px;

  @media screen and (min-height: 840px) {
    padding-left: 65px;
    padding-top: 55px;
  }
`

const Layout: FC = () => (
  <Wrapper display="flex">
    <AppPreview />
  </Wrapper>
)

export default Layout
