import React, { FC } from 'react'
import { path } from 'ramda'

import { Guest } from 'store/modules/guests/reducer'
import { Box, Text, Avatar } from 'components/ui'
import { getFullName, getImage } from 'utils/guest'

type Props = { pending: Guest[] }

const PendingList: FC<Props> = ({ pending = [] }) => (
  <>
    <Box height="46px" display="flex" alignItems="center" pl={0}>
      <Text fontSize="15px" color="darkGray" fontWeight={600}>
        Pending
      </Text>
    </Box>

    {pending.map((guest) => {
      const fullName = getFullName([guest.first_name, guest.last_name])

      return (
        <Box
          key={guest.id}
          display="flex"
          alignItems="center"
          height="48px"
          px={0}
        >
          <Box minWidth="32px" mr={0}>
            <Avatar
              image={getImage(path(['user', 'image'], guest))}
              name={fullName}
              fontSize={1}
              size={32}
            />
          </Box>

          <Text>{fullName}</Text>
        </Box>
      )
    })}
  </>
)

export default PendingList
