import React, { FC, useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import { removePhoto } from 'store/modules/albums/actions'
import { Photo } from 'store/modules/albums/reducer'
import { Modal, Box, Text, Button } from 'components/ui'

type Props = {
  photoIds: Photo['photo_id'][]
  albumId?: string
  isOpen: boolean
  onSuccess?: VoidFunction
  onClose: VoidFunction
  filteredAlbumPhotos: any[]
  setFilteredAlbumPhotos: Function
}

const RemovePhotosModal: FC<Props> = ({
                                        photoIds,
                                        onSuccess,
                                        isOpen,
                                        onClose,
                                        filteredAlbumPhotos,
                                        setFilteredAlbumPhotos,
                                      }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const onRemovePhoto = useCallback(
    async (data) => dispatch(removePhoto(data)),
    [dispatch],
  )

  const handleDelete = useCallback(async () => {
    setIsLoading(true)

    try {
      await Promise.all(
        photoIds.map(async (photo_id) => onRemovePhoto({ photo_id })),
      )

      toast('Selected photos have been deleted successfully')

      if (onSuccess) {
        onSuccess()

        const removedItem = filteredAlbumPhotos.filter((photo) => !photoIds.includes(photo.photo_id))

        setFilteredAlbumPhotos(removedItem)
      }

      onClose()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [photoIds, onClose, onSuccess, onRemovePhoto])

  const modalStyle = { content: { maxWidth: 360, top: '40vh' } }

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={onClose}>
      <Box py={3} px={4} borderRadius={0} bg='white'>
        <Box mb='6px'>
          <Text fontFamily='1' fontSize={3} fontWeight={600}>
            Delete {photoIds.length > 1 ? 'Images' : 'Image'}
          </Text>
        </Box>

        <Text fontFamily='1' lineHeight={1}>
          Are you sure you want to delete the{' '}
          {photoIds.length > 1
            ? `${photoIds.length} selected images`
            : 'selected image'}
          ?
        </Text>

        <Box display='flex' justifyContent='flex-end' mt={1}>
          <Box mr={0}>
            <Button
              border='none'
              px={2}
              py={0}
              fontFamily='1'
              fontWeight={600}
              fontSize='18px'
              color='blue'
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>

          <Button
            border='none'
            px={2}
            py={0}
            fontFamily='1'
            fontSize='18px'
            color='red'
            disabled={isLoading}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default RemovePhotosModal
