import React, { FC, useState, useCallback, FocusEvent, useEffect } from 'react'
import { FieldInputProps } from 'react-final-form'
import OutsideClickHandler from 'react-outside-click-handler'
import { parse, format, addHours, addMinutes } from 'date-fns'

import Box from '../Box'
import Input from './Input'
import Menu from './Menu'

const TimePickerInput: FC<FieldInputProps<string>> = ({
                                                        value,
                                                        onChange,
                                                        onBlur,
                                                        onFocus,
                                                        isShowMenu,
                                                        hourPlaceholder,
                                                        minutePlaceholder,
                                                      }) => {
  const [isActive, setActive] = useState(false)
  const date = parse(value.slice(0, 5) || '00:00', 'HH:mm', new Date())
  const handleKeyUp = (e) => {
    const x = e.target
    if (x?.value?.length === 2 && x.value.startsWith(0)) {
      e.target.value = e.target.value.slice(1)
      x.style.width = '8px'
    }
  }
  useEffect(() => {
    document.querySelectorAll('.time-picker').forEach(item => {
      item.addEventListener('keyup', handleKeyUp)
    })

    return () => {
      document.querySelectorAll('.time-picker').forEach(item => {
        item.removeEventListener('keyup', handleKeyUp)
      })
    }

  }, [])
  const handleClickOutside = () => setActive(false)
  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setActive(true)

      if (onFocus) onFocus(e)
    },
    [onFocus],
  )
  const handleClickChevron = useCallback(
    (isHours: boolean, isUp: boolean) => () => {
      if (onChange) {
        onChange(
          format(
            isHours
              ? addHours(date, isUp ? 1 : -1)
              : addMinutes(date, isUp ? 1 : -1),
            'HH:mm',
          ),
        )
      }
    },
    [onChange, date],
  )
  const handleClickAmPm = useCallback(
    (isAm: boolean) => () =>
      onChange(format(addHours(date, isAm ? 12 : -12), 'HH:mm')),
    [onChange, date],
  )

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Box position='relative'>
        <Input
          className='time-picker'
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={onBlur}
          locale='en-US'
          hourPlaceholder={hourPlaceholder}
          minutePlaceholder={minutePlaceholder}
        />

        {isActive && isShowMenu ? (
          <Menu
            date={date}
            onClickChevron={handleClickChevron}
            onClickAmPm={handleClickAmPm}
          />
        ) : null}
      </Box>
    </OutsideClickHandler>
  )
}

export default TimePickerInput
