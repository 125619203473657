import React, { FC } from 'react'
import { Box, Text } from 'components/ui'
import { ReactComponent as CloseIcon } from 'images/closeIconGrey.svg'

type Props = {
  onClick?: Function
}

const Header: FC<Props> = ({
                             onClick = () => {
                             },
                           }) => {
  return (
    <Box display='flex' justifyContent='space-between' width='100%' marginBottom='24px'>
      <Box width='100%' height='100%'>
        <Text
          fontFamily='Source Sans Pro'
          fontWeight='700'
          fontSize='32px'
          lineHeight='40px'
          color='#353B60'
        >
          Guest Details
        </Text>
      </Box>
      <Box cursor='pointer' width='100%'>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='end'
          onClick={() => onClick()}
        >
          <CloseIcon />
        </Box>
      </Box>
    </Box>
  )
}

export default Header