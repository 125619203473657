import { ModalBackground } from '../../utils'
import { Box } from '../../../../components/ui'
import React, { useEffect, useRef } from 'react'
import AddMenuModal from '../Childrens/addMenu'
import DeleteMenuModal from '../Childrens/deleteModal'

interface PropType {
  name: string;
  setIsModalOpen: any;
  menu_id: string;
  action: any;
  selectGroup: string
}

const Central = ({ name, setIsModalOpen, menu_id, action, selectGroup }: PropType) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const closeModal = (e: any) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsModalOpen({ type: null, name: null })
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeModal)
    return () => document.removeEventListener('click', closeModal)
  }, [])

  return (
    <ModalBackground>
      <Box ref={modalRef}>
        {
          name === 'addMenu' ?
            <AddMenuModal setModalIsOpen={setIsModalOpen} /> :
            name === 'delete' ?
              <DeleteMenuModal action={action} setModalIsOpen={setIsModalOpen} menu_id={menu_id}
                               menugroup_id={selectGroup} /> :
              <></>
        }
      </Box>
    </ModalBackground>
  )
}

export default Central