import { Reducer } from 'redux'
import { assoc, append, path, merge, uniq } from 'ramda'

import { ADD, LOAD } from './actionTypes'

export const initialState = { comments: [], loadedPostIds: [] }

export type Comment = {
  comment_id: string
  post_id: string
  owner: {
    first_name?: string
    last_name?: string
  }
  caption: string
}

export type State = {
  comments: Comment[]
  loadedPostIds: string[]
}

const commentsReducer: Reducer = (
  state: State = initialState,
  { type, payload }
) => {
  switch (type) {
    case ADD.FULFILLED:
      return assoc(
        'comments',
        append(path(['payload', 'comment'], payload), state.comments),
        state
      )
    case LOAD.FULFILLED:
      return merge(
        assoc(
          'comments',
          [...state.comments, path(['payload', 'comments'], payload)],
          state
        ),
        {
          loadedPostIds: uniq(
            append(path(['data', 'post_id'], payload), state.loadedPostIds)
          ),
        }
      )
    default:
      return state
  }
}

export default commentsReducer
