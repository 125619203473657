import React, { FC } from 'react'

import { Button, Box } from 'components/ui'
import { ReactComponent as CheckIcon } from 'images/check.svg'

type Props = {
  isActive: boolean
  onClick: () => void
}

const SelectAllButton: FC<Props> = ({ isActive, onClick }) => (
  <Button border="none" color="darkGray" px={2} onClick={onClick}>
    <Box
      mr={0}
      borderRadius="50%"
      border="2px solid"
      borderColor={isActive ? 'primary' : 'darkGray'}
      bg={isActive ? 'primary' : 'white'}
      width="20px"
      height="20px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CheckIcon
        fill={isActive ? '#ffffff' : '#858ea2'}
        stroke={isActive ? '#ffffff' : '#858ea2'}
        width="12px"
        height="12px"
      />
    </Box>

    {isActive ? 'Deselect all' : 'Select all'}
  </Button>
)

export default SelectAllButton
