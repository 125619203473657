import React, { FC, useContext } from 'react'
import { Box, Button, Text } from 'components/ui'
import { Context } from './Context'
import { Title } from './utils'

// Icons
import { ReactComponent as UploadIcon } from 'images/upload-dark.svg'
import { ReactComponent as PlusIcon } from 'images/plus.svg'

const AddEvent: FC = () => {
  const {
    agendaItems,
    handleOpenAddEventModal,
  } = useContext<any>(Context)

  return (
    <Box width='100%'>
      <Box display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <Box display='flex' alignItems='center'>
          <Box>
            <Text style={{
              fontFamily: 'Source Sans Pro',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '40px',
              color: '#353B60',
              marginRight: '12px',
            }}>
              Schedule
            </Text>
          </Box>
          {agendaItems.length > 0 && (
            <Box style={{ backgroundColor: '#ECF8F8', borderRadius: '16px', padding: '2px 10px', minWidth: '78px' }}>
              <Title>
                {agendaItems.length} {agendaItems.length > 1 ? 'Events' : 'Event'}
              </Title>
            </Box>
          )}
        </Box>
        <Box display='flex' alignItems='center'>
          <Box>
            <Box width='100%' mr='12px'>
              <Button
                border='1px solid'
                borderColor='#CBCBD3'
                borderRadius='8px !important'
                height='40px'
                bg='transparent'
                color='#353B60'
                fontWeight='600'
                fontSize='16px'
                fontFamily='Source Sans Pro !important'
                padding='8px 16px !important'
              >
                <Box mr={8}>
                  <UploadIcon />
                </Box>
                Upload
              </Button>
            </Box>
          </Box>
          <Box>
            <Button
              variant='primary'
              height='40px'
              padding='10px 20px 10px 16px !important'
              borderRadius='8px !important'
              fontFamily='Source Sans Pro !important'
              fontWeight='600'
              fontSize='16px'
              color='#FFFFFF'
              onClick={handleOpenAddEventModal}
            >
              <Box mr={8}>
                <PlusIcon fill='currentColor' />
              </Box>
              Add Event
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AddEvent