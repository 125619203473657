import { join } from 'ramda'

import { LOAD } from '../actionTypes'

export const load = () => ({
  type: LOAD.name,
  api: {
    url: 'faq/list',
    data: {
      field_list: join(',', [
        'wedding_id',
        'is_asked',
        'is_anon',
        'agenda_item_id',
        'question',
        'answer',
        'ask_user_id',
        'ans_user_id',
        'ask_dt',
        'ans_dt',
        'ord',
        'ask_user',
        'ans_user',
        'faq_item_id',
        'like_count',
        'is_like',
      ]),
    },
  },
})
