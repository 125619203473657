import React, { FC, useState } from 'react'

import { Button, Box } from 'components/ui'
import { ReactComponent as ShareIcon } from 'images/share.svg'

import Modal from '../ShareAlbumModal'

const ShareAlbumButton: FC<{ albumId?: string }> = ({ albumId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const handleOpenModal = () => setModalIsOpen(true)
  const handleCloseModal = () => setModalIsOpen(false)

  return (
    <>
      <Modal
        albumId={albumId}
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
      />

      <Button variant="primaryOutline" onClick={handleOpenModal}>
        <Box ml="-6px" mr="6px">
          <ShareIcon width="20px" height="20px" stroke="#5458f7" />
        </Box>
        Share
      </Button>
    </>
  )
}

export default ShareAlbumButton
