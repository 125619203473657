import React, { FC } from 'react'
import styled from 'styled-components'

import { Box, Button, Text } from 'components/ui'
import { ReactComponent as ArrowLeftIcon } from 'images/arrow-left.svg'
import { ReactComponent as FavoriteIcon } from './favorite.svg'
import { ReactComponent as NoFavoriteIcon } from './no-favorite.svg'
import { ReactComponent as DeleteIcon } from './delete.svg'
import { ReactComponent as AlbumsIcon } from './albums.svg'
// import { ReactComponent as CoverIcon } from './cover.svg'
import { ReactComponent as ExportIcon } from './export.svg'

const NavButton = styled(Button)`
  &:disabled {
    background: transparent;
    cursor: not-allowed;
  }
`

type Props = {
  isFavorites: boolean
  isFavorite: boolean
  isLoading: boolean
  onClickAddToFavorites: VoidFunction
  onClickExport: VoidFunction
  onMakeCoverImage: VoidFunction
  onClickRemove: VoidFunction
  onClickBack: VoidFunction
}

const NavBar: FC<Props> = ({
  isFavorites,
  isFavorite,
  isLoading,
  onClickAddToFavorites,
  onClickExport,
  // onMakeCoverImage,
  onClickRemove,
  onClickBack,
}) => (
  <Box
    width="100%"
    height="76px"
    px="20px"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    bg="darkened"
  >
    <Button color="white" bg="transparent" border="none" onClick={onClickBack}>
      <Box mr={0}>
        <ArrowLeftIcon fill="white" />
      </Box>
      Back
    </Button>

    <Box display="flex">
      {!isFavorites && (
        <NavButton
          bg="transparent"
          mr="24px"
          p="0px"
          disabled={isLoading}
          onClick={onClickAddToFavorites}
        >
          <Box mr="8px">
            {isFavorite ? <FavoriteIcon /> : <NoFavoriteIcon />}
          </Box>

          <Text fontWeight={600} fontSize="16px" color="#FFFFFF">
            {isFavorite ? 'Favorite' : 'Add to Favorites'}
          </Text>
        </NavButton>
      )}

      <NavButton
        bg="transparent"
        mr="24px"
        p="0px"
        disabled={isLoading}
        onClick={onClickRemove}
      >
        <Box mr="8px">
          <DeleteIcon stroke="white" />
        </Box>

        <Text fontWeight={600} fontSize="16px" color="#FFFFFF">
          Delete
        </Text>
      </NavButton>

      <NavButton
        bg="transparent"
        mr="24px"
        p="0px"
        disabled={isLoading}
        onClick={onClickExport}
      >
        <Box mr="8px">
          <ExportIcon stroke="white" />
        </Box>

        <Text fontWeight={600} fontSize="16px" color="#FFFFFF">
          Export
        </Text>
      </NavButton>

      <NavButton bg="transparent" p="0px">
        <Box mr="8px">
          <AlbumsIcon stroke="white" />
        </Box>

        <Text fontWeight={600} fontSize="16px" color="#FFFFFF">
          Add to Album
        </Text>
      </NavButton>
    </Box>
  </Box>
)

export default NavBar
