import { merge, pick } from 'ramda'

import { LOAD } from '../actionTypes'

export const loadPosts = (data: object) => ({
  type: LOAD.name,
  api: {
    url: 'posts/list',
    data: merge(
      pick(
        [
          'wuid',
          'uuid',
          'order',
          'orderby',
          'hashtag',
          'query',
          'in_posts',
          'in_comments',
          'max_comments',
          'max_likes',
          'per_page',
          'page_num',
        ],
        data
      ),
      {
        field_list: [
          'wedding_wuid',
          'title',
          'caption',
          'media',
          'owner',
          'insert_date',
          'pinned_date',
          'likes',
          'comments',
        ].join(','),
      }
    ),
  },
})
