import React, { FC } from 'react'
import { FormRenderProps } from 'react-final-form'

import { Box, Button } from 'components/ui'
import { Input } from 'components/form'

const UpdateAlbumForm: FC<
  FormRenderProps<{ name?: string }> & { onClose: () => void }
> = ({ handleSubmit, onClose, submitting }) => {
  return (
    <Box as="form" autoComplete="off" onSubmit={handleSubmit}>
      <Box width="100%" mb={3}>
        <Input name="name" label="Name" placeholder="Name" />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Box mr={0}>
          <Button
            border="none"
            px={2}
            py={0}
            fontFamily="1"
            fontSize="18px"
            color="blue"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>

        <Button type="submit" variant="primary" disabled={submitting}>
          Save
        </Button>
      </Box>
    </Box>
  )
}

export default UpdateAlbumForm
