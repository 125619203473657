import React, { FC } from 'react'

import { Box } from 'components/ui'

const Step8Block: FC = ({ children }) => (
  <Box pb={3} mb={3} borderBottom="1px solid" borderBottomColor="gray">
    {children}
  </Box>
)

export default Step8Block
