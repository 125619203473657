import React, { FC, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { path, forEach, assoc } from 'ramda'
import { toast } from 'react-toastify'

import {
  addAlbumPhoto,
  pushUploadedPhoto,
  updateAlbum,
} from 'store/modules/albums/actions'
import { getFavoritesAlbumId } from 'store/modules/albums/selectors'
import { Photo } from 'store/modules/albums/reducer'
import { Box, Text, Button } from 'components/ui'
import { ReactComponent as ClearIcon } from 'images/close.svg'
import { ReactComponent as FavoritesIcon } from 'images/heart.svg'
import { ReactComponent as DeleteIcon } from 'images/trash-2.svg'
import { ReactComponent as ImageIcon } from 'images/albums.svg'
import RemovePhotosModal from '../RemovePhotosModal'
import ExportPhotosButton from './ExportPhotosButton'

type Props = {
  isFavorites: boolean
  albumId?: string
  selectedPhotos: Photo[]
  onClickClear: () => void
}

const SelectedPhotosBar: FC<Props> = ({
                                        isFavorites,
                                        albumId,
                                        selectedPhotos,
                                        onClickClear,
                                      }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false)
  const favoritesAlbumId = useSelector(getFavoritesAlbumId)

  const onAddPhoto = useCallback(async (data) => dispatch(addAlbumPhoto(data)), [
    dispatch,
  ])

  const onPushUploadedPhoto = useCallback(
    async (data) => dispatch(pushUploadedPhoto(data)),
    [dispatch],
  )

  const onUpdateAlbum = useCallback(
    async (albumId, data) => dispatch(updateAlbum(albumId, data)),
    [dispatch],
  )

  const handleOpenRemoveModal = () => setIsOpenRemoveModal(true)
  const handleCloseModal = () => setIsOpenRemoveModal(false)
  const handleMakeCoverImage = async () => {
    setIsLoading(true)

    try {
      await onUpdateAlbum(albumId, {
        cover_image_id: path([0, 'photo_id'], selectedPhotos),
      })

      toast('Album cover image has been updated successfully')
      onClickClear()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }
  const handleAddToFavorites = useCallback(async () => {
    if (!favoritesAlbumId) {
      return toast(
        `The favourite album has not been found. You can't add the photo to favourites.`,
      )
    }

    setIsLoading(true)

    forEach(async (photo) => {
      try {
        await onAddPhoto({
          album_id: favoritesAlbumId,
          photo_id: photo.photo_id,
        })

        onPushUploadedPhoto(assoc('album_id', favoritesAlbumId, photo))
        toast('The photo has been added to favorites successfully')
      } catch (error) {
        toast(error.message)
      }
    }, selectedPhotos)

    setIsLoading(false)

    return onClickClear()
  }, [
    favoritesAlbumId,
    setIsLoading,
    onAddPhoto,
    selectedPhotos,
    onPushUploadedPhoto,
    onClickClear,
  ])

  return (
    <>
      <RemovePhotosModal
        isOpen={isOpenRemoveModal}
        onClose={handleCloseModal}
        onSuccess={onClickClear}
        photoIds={selectedPhotos.map((photo) => photo.photo_id)}
        albumId={albumId}
      />

      <Box
        height='64px'
        maxWidth='1020px'
        width='100%'
        bg='white'
        position='fixed'
        bottom='0px'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        px={1}
      >
        <Box display='flex' alignItems='center'>
          <Box mr={0}>
            <Button border='none' onClick={onClickClear}>
              <ClearIcon fill='#7c8494' width='28px' height='28px' />
            </Button>
          </Box>

          <Text fontFamily='1' fontSize={2} fontWeight={600}>
            {selectedPhotos.length} Selected
          </Text>
        </Box>

        <Box display='flex' alignItems='center'>
          {!isFavorites && (
            <Button
              border='none'
              fontFamily='1'
              fontWeight={600}
              color='darkGray'
              disabled={isLoading}
              mr={0}
              onClick={handleAddToFavorites}
            >
              <Box mr={0}>
                <FavoritesIcon />
              </Box>
              Add to Favorites
            </Button>
          )}

          <Button
            border='none'
            fontFamily='1'
            fontWeight={600}
            color='darkGray'
            disabled={isLoading}
            mr={0}
            onClick={handleOpenRemoveModal}
          >
            <Box mr={0}>
              <DeleteIcon />
            </Box>
            Delete
          </Button>

          <Box mr={0}>
            <ExportPhotosButton selectedPhotos={selectedPhotos} />
          </Box>

          <Button
            border='none'
            fontFamily='1'
            fontWeight={600}
            color='darkGray'
            disabled={isLoading || selectedPhotos.length > 1}
            onClick={handleMakeCoverImage}
          >
            <Box mr={0}>
              <ImageIcon />
            </Box>
            Make Cover Image
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default SelectedPhotosBar
