import React, { InputHTMLAttributes, useMemo } from 'react'
import styled from 'styled-components'
import { path } from 'ramda'

import { Box, Text } from 'components/ui'

export const Label = styled(Text).attrs({ as: 'label' })<{
  top: string
  left: string
  width: string
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid #aeb7c2;
  background: ${path(['theme', 'colors', 'white'])};
  border-radius: 4px;
  margin: 0;

  &:after {
    content: '';
    width: ${(props) => props.width};
    height: 6px;
    position: absolute;
    top: ${(props) => props.top};
    left: ${(props) => props.left};
    border: 2px solid ${path(['theme', 'colors', 'white'])};
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-48deg);
  }

  &:hover::after {
    opacity: 0.5;
    border-color: ${path(['theme', 'colors', 'black'])};
  }
`

export const Input = styled(Box).attrs({ as: 'input', type: 'checkbox' })<
  InputHTMLAttributes<HTMLInputElement> & { bg: string }
>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  margin: 0;
  cursor: pointer;

  &:checked + ${Label} {
    border: 2px solid ${(props) => props.bg};
    background: ${(props) => props.bg};

    &:after {
      opacity: 1;
    }
  }

  &:disabled + ${Label} {
    opacity: 0.5;
  }
`

type Props = {
  variant?: 'medium' | 'small'
}

const CheckBox = ({
  variant = 'medium',
  ...props
}: InputHTMLAttributes<HTMLInputElement> & Props) => {
  const { size, bg, top, left, width } = useMemo(() => {
    if (variant === 'small') {
      return {
        size: '16px',
        bg: '#353B60',
        top: '2px',
        left: '1px',
        width: '10px',
      }
    }

    return {
      size: '20px',
      bg: '#5458f7',
      top: '4px',
      left: '2px',
      width: '12px',
    }
  }, [variant])

  return (
    <Box width={size} height={size} position="relative">
      <Input type="checkbox" bg={bg} {...props} />

      <Label top={top} left={left} width={width} />
    </Box>
  )
}

export default CheckBox
