import React, { FC, useState, useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'

import { getWedding } from 'store/modules/wedding/selectors'
import { logout } from 'store/modules/auth/actions'
import { Box, Text, Avatar } from 'components/ui'
import { getImage, getFullName } from 'utils/guest'
import { getDefaultWeddingName } from 'utils/wedding'
import { ReactComponent as ChevronIcon } from './chevron.svg'
import { ReactComponent as SmileIcon } from './smile.svg'
import { ReactComponent as RepeatIcon } from './repeat.svg'
import { ReactComponent as UserIcon } from './user.svg'
import { ReactComponent as LogOutIcon } from './log-out.svg'
import { WEDDING_LIST } from 'constants/paths'
import Item from './Item'

const Menu: FC = () => {
  const { push } = useHistory()
  const { wuid } = useParams()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const wedding = useSelector(getWedding(wuid))

  const [image, name, initials] = useMemo(
    () => [
      getImage(wedding?.wedding_image, 'big_icon'),
      getDefaultWeddingName({
        spouse1FirstName: wedding?.spouse1_first_name,
        yourSpouseFirstName: wedding?.spouse2_first_name,
      }),
      getFullName([wedding?.spouse1_first_name, wedding?.spouse2_first_name]),
    ],
    [wedding]
  )

  const onLogout = useCallback(() => dispatch(logout()), [dispatch])

  const handleClickButton = useCallback(() => setIsOpen(!isOpen), [isOpen])
  const handleClickOutside = useCallback(() => setIsOpen(false), [])
  const handleGoToWeddings = useCallback(() => push(WEDDING_LIST), [push])

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Box position="relative" height="40px">
        <Box
          display="inline-flex"
          alignItems="center"
          cursor="pointer"
          color="white"
          onClick={handleClickButton}
        >
          <Avatar
            image={image}
            name={initials}
            fontSize={16}
            bg="#DDDEFD"
            color="#fff"
          />

          <Box pl="12px" pr="8px">
            <Text
              fontWeight={600}
              fontSize="18px"
              lineHeight="23px"
              color="white"
            >
              {name}
            </Text>
          </Box>

          <ChevronIcon />
        </Box>

        {isOpen && (
          <Box
            position="absolute"
            width="250px"
            bg="white"
            border="1px solid #CBCBD3"
            borderRadius="6px"
            boxShadow="0px 4px 13px rgba(0, 0, 0, 0.05)"
            mt="5px"
            py="9px"
            right="0px"
            zIndex={2147483647}
          >
            <Item>
              <SmileIcon />

              <Text fontWeight={600} fontSize="16px" color="#353B60">
                Profile
              </Text>
            </Item>

            <Item onClick={handleGoToWeddings}>
              <RepeatIcon />

              <Text fontWeight={600} fontSize="16px" color="#353B60">
                Switch Event
              </Text>
            </Item>

            <Item>
              <UserIcon />

              <Text fontWeight={600} fontSize="16px" color="#353B60">
                Manage Your Account
              </Text>
            </Item>

            <Item onClick={onLogout}>
              <LogOutIcon />

              <Text fontWeight={600} fontSize="16px" color="#FF3347">
                Log Out
              </Text>
            </Item>
          </Box>
        )}
      </Box>
    </OutsideClickHandler>
  )
}

export default Menu
