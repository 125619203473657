import { INFO } from '../actionTypes'

export const info = (uuid: string) => ({
  type: INFO.name,
  api: {
    url: 'user/info',
    data: {
      uuid,
      field_list: [
        'uuid',
        'email',
        'phone',
        'first_name',
        'last_name',
        'image',
        'contact_email',
        'contact_phone',
      ].join(','),
    },
  },
})
