import { pick } from 'ramda'

import { ZIP_RESULT } from '../actionTypes'

export const zipResult = (data: object) => ({
  type: ZIP_RESULT.name,
  api: {
    url: 'albums/zip_result',
    data: pick(['wuid', 'ident'], data),
  },
})
