import React, { useMemo } from 'react'

import { SChart, SChartItem } from 'store/modules/seatings/reducer'
import Item from '../Item'

const PADDING = 50

export type Image = {
  id: string
  base64: string
  width: number
  height: number
}

type Props = {
  items: SChartItem[]
  images: Image[]
  floorplan: SChart['floorplan']
}

const SvgChart = ({ items, floorplan }: Props) => {
  const { offsetX, offsetY, width, height } = useMemo(() => {
    const floorplanWidth = floorplan
      ? Math.round(floorplan.width * floorplan.scale)
      : 0
    const floorplanHeight = floorplan
      ? Math.round(floorplan.height * floorplan.scale)
      : 0
    const xValues = items.map((item) => item.pos_x)
    const yValues = items.map((item) => item.pos_y)

    const minX = Math.min(...xValues)
    const leftItem = items[xValues.findIndex((value) => value === minX)]
    const offsetX = leftItem
      ? leftItem.pos_x < 0
        ? leftItem.pos_x * -1 + PADDING
        : PADDING
      : 0

    const minY = Math.min(...yValues)
    const topItem = items[yValues.findIndex((value) => value === minY)]
    const offsetY = topItem
      ? topItem.pos_y < 0
        ? topItem.pos_y * -1 + PADDING
        : PADDING
      : 0

    const width = Math.max(
      ...[
        ...items.map((item) => item.pos_x + item.width + offsetX),
        floorplanWidth + offsetX,
      ]
    )

    const height = Math.max(
      ...[
        ...items.map((item) => item.pos_y + item.height + offsetY),
        floorplanHeight + offsetY,
      ]
    )

    return {
      floorplanWidth,
      floorplanHeight,
      offsetX,
      offsetY,
      width: width < floorplanWidth ? floorplanWidth : width + PADDING,
      height: height < floorplanHeight ? floorplanHeight : height + PADDING,
    }
  }, [items, floorplan])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <defs>
        <style type="text/css">
          @import
          url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600');
        </style>
      </defs>

      {floorplan && (
        <image
          href={floorplan.url}
          width={floorplan.width}
          height={floorplan.height}
          x={offsetX}
          y={offsetY}
          style={{
            transform: `scale(${floorplan.scale})`,
            transformOrigin: 'center',
          }}
        />
      )}

      {items.map((item) => {
        return (
          <Item
            key={item.schart_item_id}
            {...item}
            pos_x={item.pos_x + offsetX}
            pos_y={item.pos_y + offsetY}
          />
        )
      })}
    </svg>
  )
}

export default SvgChart
