import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'

import { Box, Avatar, Text } from 'components/ui'
import { isDark } from 'utils/colors'
import { getUser } from 'store/modules/auth/selectors'
import { getFullName, getImage } from 'utils/guest'
import { ReactComponent as Indicators } from './indicators.svg'

const Wrapper = styled(Box)`
  width: 218px;

  & > div:first-child {
    padding-right: 5px;
  }

  @media screen and (min-height: 840px) {
    width: 270px;

    & > div:first-child {
      padding-right: 10px;
    }
  }
`

type Props = {
  title?: string
  font?: string
  bg?: string
}

const Header = ({ title = '', bg = '#18CBC1', font }: Props) => {
  const user = useSelector(getUser)

  const { image, name } = useMemo(() => {
    const image = getImage(user?.image)
    const name = getFullName([user?.first_name, user?.last_name])

    return { image, name }
  }, [user])

  const color = useMemo(() => (isDark(bg) ? 'white' : 'black'), [bg])
  const time = useMemo(() => format(new Date(), 'h:mm'), [])
  const fontSize = useMemo(
    () =>
      title.length < 15
        ? '1.4em'
        : title.length < 19
        ? '1.2em'
        : title.length < 21
        ? '0.9em'
        : title.length < 25
        ? '0.8em'
        : '0.7em',
    [title]
  )

  return (
    <Wrapper height="57px" bg={bg} pl="15px" pt="10px" position="relative">
      <Box display="flex" justifyContent="space-between" pb="9px" color={color}>
        <Text fontSize="10px" fontWeight={900} color="inherit">
          {time}
        </Text>

        <Indicators />
      </Box>

      <Avatar size="19px" image={image} name={name} />

      <Box
        position="absolute"
        height="32px"
        bottom="0px"
        left="0px"
        right="0px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          fontFamily={font}
          fontSize={fontSize}
          color="#ffffff"
          textAlign="center"
          isTruncate
          maxWidth="200px"
        >
          {title}
        </Text>
      </Box>
    </Wrapper>
  )
}

export default Header
