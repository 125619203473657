import { createSelector, Selector } from 'reselect'
import { pathOr, propEq, compose, slice, find } from 'ramda'

import { State, initialState, Font } from './reducer'

const getState: Selector<{ fonts: State }, State> = pathOr(initialState, [
  'fonts',
])

export const getIsLoaded = createSelector(getState, propEq('isLoaded', true))

export const getFonts = (toIndex?: number) =>
  createSelector(
    getState,
    compose(
      (fonts) => (toIndex ? slice(0, toIndex, fonts) : fonts),
      pathOr<Font[]>([], ['fonts'])
    )
  )

export const getFont = (fontFamily?: string) =>
  createSelector(
    getState,
    compose(
      find<Font>(propEq('family', fontFamily)),
      pathOr<Font[]>([], ['fonts'])
    )
  )
