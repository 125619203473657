import { UPDATE_MENU_ITEM } from '../../../actionTypes'
import { UpdateMenuItemNeedData } from '../../../../../../routes/FoodMenu/tipes_interfaces'

export const update = (data: UpdateMenuItemNeedData) => {
  return {
    type: UPDATE_MENU_ITEM.name,
    api: {
      url: 'menu/update_item',
      data,
    },
  }
}