import React, { FC } from 'react'
import { inc } from 'ramda'

import { Box } from 'components/ui'

type Props = {
  level: number
}

const Indicator: FC<Props> = ({ level }) => (
  <Box height="4px" borderRadius="2px" bg="#e6eaef" mb="12px">
    <Box
      height="100%"
      borderRadius="2px"
      width={`${(inc(level) / 3) * 100}%`}
      bg={['#ff021c', '#ffa502', '#63C7C9'][level]}
    />
  </Box>
)

export default Indicator
