import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Box, Button } from 'components/ui'
import { ReactComponent as ListIcon } from './list.svg'
import { ReactComponent as GridIcon } from './grid.svg'
import { setIsGrid } from 'store/modules/wedding/actions'
import { getIsGrid } from 'store/modules/wedding/selectors'

const Switch = () => {
  const dispatch = useDispatch()
  const isGrid = useSelector(getIsGrid)

  const onSetIsGrid = useCallback(
    (isGrid: boolean) => dispatch(setIsGrid(isGrid)),
    [dispatch]
  )
  const onClickList = useCallback(() => onSetIsGrid(false), [onSetIsGrid])
  const onClickGrid = useCallback(() => onSetIsGrid(true), [onSetIsGrid])

  return (
    <Box mr={0} display="flex">
      <Button
        width="40px"
        height="40px"
        border="1px solid"
        borderColor="#CBCBD3"
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        borderRight="none"
        color={isGrid ? '#73737B' : 'primary'}
        onClick={onClickList}
      >
        <ListIcon />
      </Button>

      <Button
        width="40px"
        height="40px"
        border="1px solid"
        borderColor="#CBCBD3"
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        color={!isGrid ? '#73737B' : 'primary'}
        onClick={onClickGrid}
      >
        <GridIcon />
      </Button>
    </Box>
  )
}

export default Switch
