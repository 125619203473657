import { pick } from 'ramda'

import { LOAD } from '../actionTypes'

export const loadComments = (data: object) => ({
  type: LOAD.name,
  api: {
    url: 'comments/list',
    data: pick(
      [
        'wuid',
        'field_list',
        'uuid',
        'post_id',
        'order',
        'orderby',
        'hashtag',
        'query',
        'per_page',
        'page_num',
      ],
      data
    ),
  },
})
