import React, { FC } from 'react'
import styled from 'styled-components'
import { Button } from 'components/ui'

const ButtonsStyled = styled.div`
  padding: 16px;
  margin-left: -16px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-content: center;
  justify-content: end;
  width: calc(100% + 16px);
  border-bottom-left-radius: 10px;
  border-top: 1px solid #E8E8ED;
  background-color: #FFFFFF;
`

const SubmitContainerStyled = styled.div`
  display: flex;
  align-content: center;
  justify-content: end;
`

const CancelButtonStyled = styled(Button)`
  font-family: 'Source Sans Pro', normal, serif;
  color: #5458F7;
  line-height: 20px;
  font-style: normal;
  background-color: #FFFFFF;
  padding: 19px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  margin-right: 20px;
  border: 1px solid #CBCBD3;
`

const SubmitButtonStyled = styled(Button)`
  font-family: 'Source Sans Pro', normal, serif;
  color: #FFFFFF;
  line-height: 20px;
  font-style: normal;
  background-color: #5458F7;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px;
`

type Props = {
  handleSubmit: Function,
  handleClose: Function,
  isInvalid: boolean
}

const Submit: FC<Props> = ({ handleSubmit, handleClose, isInvalid }) => {

  return (
    <ButtonsStyled>
      <SubmitContainerStyled>
        <CancelButtonStyled
          onClick={() => handleClose()}
        >
          Cancel
        </CancelButtonStyled>

        <SubmitButtonStyled
          onClick={() => handleSubmit()}
        >
          Update
        </SubmitButtonStyled>
      </SubmitContainerStyled>
    </ButtonsStyled>
  )
}

export default Submit