import React, { useEffect, useMemo, useState } from 'react'

import editIcon from '../../images/edit-icon.svg'
import deleteIcon from '../../images/delete-icon.svg'
import PlusIcon from '../../images/plus.svg'
import ReceptionPlusIcon from '../../images/receptionPlusIcon.svg'

import { GetMenuNeedData, Menu as MenuRequestPayload, Modal as ModalType } from './tipes_interfaces'
import { load as loadMenu } from '../../store/modules/foodMenu/actions/menu/load'
import { load as loadGroups } from '../../store/modules/foodMenu/actions/menuGroup/load'
import { add as addGroupItems } from '../../store/modules/foodMenu/actions/groupItems/add'
import { update as updateGroup } from '../../store/modules/foodMenu/actions/menuGroup/update'

import {
  Menus,
  Menu,
  MenuItems,
  MenuGroups,
  MenuGroupItem,
  Groups,
  Group,
} from './types_interfaces/storObjects'

import { FC } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getMenuGroups, getMenuSelector } from '../../store/modules/foodMenu/selector'

import Modal from './modals'
import ListItem from './listItem'
import SelectMenuBtn from './selectMenuBtn'

import { deleteMenu as removeMenu } from './modals/actions'

import { Box } from 'components/ui'
import { FoodMenuWrapper, TitleAndAddButton, AddButton, Selections, FoodList } from './utils'
import { addChart } from '../../store/modules/seatings/actions'

const FoodMenu: FC = () => {
  const { wuid } = useParams()
  const dispatch = useDispatch()

  const menus: Menus = useSelector(getMenuSelector)
  const menuGroups: MenuGroups = useSelector(getMenuGroups)
  const [selectMenu, setSelectMenu] = useState({
    name: '',
    id: '',
  })

  const Groups: Groups = useMemo(() =>
      (function() {
        if (menus.length && selectMenu.id && menuGroups.length) {
          const selectedMenu = menus.find((item: Menu) => item.menu_id === selectMenu.id) || menus[0]
          return menuGroups.map((item: MenuGroupItem) => {
            const groupItems = selectedMenu.items.filter((dish: MenuItems) => dish?.group?.menugroup_id === item.menugroup_id)
            return {
              group_id: item.menugroup_id,
              groupName: item.title,
              groupItems,
              wuid: item.wuid,
              ord: item.ord,
            }
          }).filter((item: Group) => item.groupItems.length).sort((next: Group, prev: Group) => +next.ord - +prev.ord)
        }

        return []
      })()
    , [menus, menuGroups, selectMenu])


  const [selectGroup, setSelectGroup] = useState<string>('')

  const [isMultipleModalOpen, setIsMultipleModalOpen] = useState<ModalType>({
    type: '',
    name: '',
    action: '',
  })

  const handleGetMenu = (data: GetMenuNeedData) => {
    dispatch(loadMenu(data))
  }

  useEffect(() => {
    if (wuid) {
      handleGetMenu({ wuid })
      dispatch(loadGroups({ wuid }))
    }

  }, [wuid])

  useEffect(() => {
    if (!!Groups.length) {
      dispatch(addGroupItems(Groups))
    }

  }, [Groups])

  useEffect(() => {
    if (menus.length) {
      setSelectMenu({
        name: menus[0].title,
        id: menus[0].menu_id,
      })
    }


  }, [menus])

  const updateGroupsByOrder = async (menugroup_id: string, upOrDown: string) => {
    try {
      const help = upOrDown === 'up' ? -1 : 1
      const groupIndex = Groups.findIndex((item: Group) => item.group_id === menugroup_id)

      if (groupIndex === 0 && upOrDown === 'up' || groupIndex === menuGroups.length - 1 && upOrDown === 'down') return
      const nextOrPrevGroup = Groups[groupIndex + help]

      if (!nextOrPrevGroup?.wuid || !Groups?.[groupIndex].wuid) return

      if (wuid) {
        dispatch(updateGroup({ wuid, menugroup_id, ord: nextOrPrevGroup.ord }))
        dispatch(updateGroup({ wuid, menugroup_id: nextOrPrevGroup.group_id, ord: Groups[groupIndex].ord }))
      }
    } catch (e) {
      console.log('updateGroup:::error::', e)
    }
  }

  return (
    <FoodMenuWrapper>
      <FoodList>
        <TitleAndAddButton>
          <Box className='title'>Food Menu</Box>
          <AddButton>
            <img src={PlusIcon} alt='...Loading' />
            <Box
              className='addBtn'
              onClick={() => setIsMultipleModalOpen({ type: 'fromTheLeftSide', name: 'Course', action: 'add' })}
            >
              Add Course
            </Box>
          </AddButton>
        </TitleAndAddButton>
        <Box display='flex' justifyContent='space-between' alignItems='center' padding='7px 16px 8px'>
          <Box className='buttonsWrapper'>
            {
              !!menus.length &&
              menus.map((item: Menu) =>
                <SelectMenuBtn
                  key={item.menu_id}
                  menuName={item.title}
                  isSelected={selectMenu.name === item.title}
                  setSelectMenu={setSelectMenu}
                  menu_id={item.menu_id}
                />)
            }
            <Box ml='6px' className='plus-btn-box'
                 onClick={() => setIsMultipleModalOpen({ type: 'central', name: 'addMenu' })}>
              <img src={ReceptionPlusIcon} alt='...Loading' />
            </Box>
          </Box>
          <Box display='flex' justifyContent='space-between' alignItems='center'
               style={{ columnGap: '12px' }}>
            {
              selectMenu.name && <Box style={{
                fontFamily: 'Source Sans Pro',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#5458F7',
                backgroundColor: '#F7F7FC',
                padding: '8px 16px 11px 16px',
              }}>{selectMenu.name}</Box>
            }
            <Box
              // onClick={action ? handleOpenModal(action) : () => updateGroupsByOrder(menugroup_id, name)}
              height='40px'
              style={{ cursor: 'pointer' }}
            >
              <img src={editIcon} alt='...Loading' />
            </Box>
            <Box
              onClick={() => setIsMultipleModalOpen(removeMenu)}
              height='40px'
              style={{ cursor: 'pointer' }}
            >
              <img src={deleteIcon} alt='...Loading' />
            </Box>
          </Box>
        </Box>
        <Box className='groupsWrapper'>
          {
            selectMenu.id && !!Groups.length &&
            Groups.filter((item: Group) => item.groupItems.length)
              .map((item: Group) =>
                <ListItem key={item.group_id}
                          updateGroupsByOrder={updateGroupsByOrder}
                          group={item}
                          setModalIsOpen={setIsMultipleModalOpen}
                          setSelectGroup={setSelectGroup}
                />)
          }
        </Box>
      </FoodList>
      <Selections>
        <Box className='heading'>Selections</Box>
      </Selections>
      {
        isMultipleModalOpen.type &&
        <Modal setIsModalOpen={setIsMultipleModalOpen} modalType={isMultipleModalOpen} menu_id={selectMenu.id}
               selectGroup={selectGroup} />
      }
    </FoodMenuWrapper>
  )
}

export default FoodMenu