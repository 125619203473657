import React, { FC, useCallback, useRef, useEffect, KeyboardEvent } from 'react'
import { FormRenderProps } from 'react-final-form'
import { equals, isNil } from 'ramda'
import throttle from 'lodash.throttle'

import Input from '../ChatInput'

const usePrevious = (value: any) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

type Props = { setIsTyping: (x: boolean) => void }

const ChatForm: FC<FormRenderProps<{ message: string }> & Props> = ({
  setIsTyping,
  form,
  dirty,
  values,
}) => {
  const prevValue = usePrevious(values.message)
  const { message } = values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSetIsTyping = useCallback(throttle(setIsTyping, 5000), [
    setIsTyping,
  ])

  const handleSubmit = useCallback(async () => {
    await form.submit()

    form.reset()
  }, [form])

  const handlePressKey = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (dirty && equals(e.key, 'Enter')) {
        e.preventDefault()
        handleSubmit()
      }

      throttledSetIsTyping(true)
    },
    [handleSubmit, throttledSetIsTyping, dirty]
  )

  useEffect(() => {
    if (isNil(message) && !isNil(prevValue)) setIsTyping(false)
  }, [message, prevValue, setIsTyping])

  return <Input name="message" onPressKey={handlePressKey} />
}

export default ChatForm
