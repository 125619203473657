import { Reducer } from 'redux'
import { append, assoc, concat, findIndex, map, path, pathOr, propEq, reject, update } from 'ramda'

import {
  LIST_ADMIN,
  LIST,
  ADD_GUEST,
  ADD_GUEST_BULK,
  EDIT_GUEST,
  REMOVE_GUEST,
  ADD_LIST,
  UPDATE_LIST,
  REMOVE_LIST,
} from './actionTypes'

const initialState = {
  loadedWuids: {},
  guests: [],
  lists: [],
}

export type Guest = {
  gid?: any
  wuid: string
  id: string
  list_id: string
  list: {
    color: string
    insert_dt: string
    list_id: string
    name: string
  }
  first_name: string
  last_name: string
  fake_name?: string
  set_invite_code?: number
  max_subguests?: number
  subguests?: Guest[]
  is_admin: string
  is_used: string
  isExpanded?: boolean
  wedding_role: string
  state: string
  phone: string | number
  is_joined: string
  vaccine_status: string
  notes: string
  rsvp_status: string
  email: string
  street: string
  city: string
  zip: string | number | boolean
  country: string
  age_range: string
  is_need_hotel: string
  seat_info: object
  honorific_id: string,
  parent_guest_id: string | number
  guest_id: string | number
  sub_guest_list: []
  user: {
    uuid: string
    first_name: string
    last_name: string
    image: object
    status: string
    email: string
  }
}

export type List = {
  wuid?: string
  id: string
  name?: string
  color?: string
}

export type State = {
  loadedWuids: {
    [key: string]: {
      guests: boolean
      lists: boolean
    }
  }
  guests: Guest[]
  lists: List[]
}

const guestsReducer: Reducer = (
  state: State = initialState,
  { type, payload },
) => {
  switch (type) {
    case LIST_ADMIN.FULFILLED:
      return {
        ...state,
        loadedWuids: assoc(
          pathOr('', ['data', 'wuid'], payload),
          { guests: true, lists: true },
          state.loadedWuids,
        ),
        guests: map(
          assoc('wuid', path(['data', 'wuid'], payload)),
          path(['payload', 'guests'], payload) as Guest[],
        ),
        lists: concat(
          state.lists,
          map(
            assoc('wuid', path(['data', 'wuid'], payload)),
            path(['payload', 'lists'], payload) as List[],
          ),
        ),
      }
    case LIST.FULFILLED:
      return {
        ...state,
        loadedWuids: assoc(
          pathOr('', ['data', 'wuid'], payload),
          { guests: true, lists: false },
          state.loadedWuids,
        ),
        guests: concat(
          state.guests,
          map(
            assoc('wuid', path(['data', 'wuid'], payload)),
            path(['payload', 'guests'], payload) as Guest[],
          ),
        ),
      }
    case ADD_GUEST.FULFILLED:
      const stateCopy = assoc(
        'guests',
        append(
          assoc(
            'wuid',
            pathOr('', ['data', 'wuid'], payload),
            path(['payload', 'guest'], payload),
          ),
          state.guests,
        ),
        state,
      )
      const guestIndex = state.guests.findIndex((guest) => guest.guest_id === payload.payload.guest.parent_guest_id)
      if (guestIndex !== -1) {
        // @ts-ignore
        stateCopy.guests[guestIndex].sub_guest_list.push(payload.payload.guest)
      }
      return stateCopy
    case ADD_GUEST_BULK.FULFILLED:
      const guests: any = path(['payload', 'guests'], payload)
      let subGuests: Guest[] = []
      const guestsArray = map((guest) => {
        if (guest.subguests?.length > 0) {
          guest.guest.sub_guest_list = guest.subguests.map((subGuest: any) => subGuest.guest) || []
        }
        subGuests = [...subGuests, ...guest.guest.sub_guest_list]
        return guest.guest
      }, guests)

      return {
        ...state,
        guests: [...state.guests, ...guestsArray, ...subGuests],
      }

    case EDIT_GUEST.FULFILLED:
      if (!payload.payload.guest.parent_guest_id) {
        return assoc(
          'guests',
          update(
            state.guests.findIndex((guest) => guest.guest_id === payload.payload.guest.guest_id),
            assoc(
              'wuid',
              pathOr('', ['data', 'wuid'], payload),
              path(['payload', 'guest'], payload),
            ),
            state.guests,
          ),
          state,
        )
      }

      return assoc(
        'guests',
        state.guests.map((guest: any) => {
          if (guest.guest_id === payload.payload.guest.parent_guest_id) {
            return assoc(
              'sub_guest_list',
              guest.sub_guest_list.map((guest: any) => {
                if (Number(guest.guest_id) === Number(payload.payload.guest.guest_id)) {
                  return payload.payload.guest
                }
                return guest
              }),
              guest,
            )
          } else if (guest.guest_id === payload.payload.guest.guest_id) {
            return {
              ...guest,
              ...payload.payload.guest,
            }
          }

          return guest
        }),
        state,
      )
    case REMOVE_GUEST.FULFILLED:
      const ids = payload.data.guest_id.split(',')
      return {
        ...state,
        guests: state.guests
          .filter((guest: Guest) => !ids.includes(String(guest.guest_id)))
          .map((guest: Guest) => {
            if (guest.sub_guest_list.length > 0) {
              // @ts-ignore
              guest.sub_guest_list = guest.sub_guest_list.filter((guest: Guest) => !ids.includes(String(guest.guest_id)))
            }
            return guest
          }),
      }
    case ADD_LIST.FULFILLED:
      return assoc(
        'lists',
        append(
          assoc(
            'wuid',
            pathOr('', ['data', 'wuid'], payload),
            pathOr(
              { id: '', name: '', color: '' },
              ['payload', 'list'],
              payload,
            ),
          ),
          state.lists,
        ),
        state,
      )
    case UPDATE_LIST.FULFILLED:
      return assoc(
        'lists',
        update(
          findIndex(
            propEq('id', path(['payload', 'list', 'id'], payload)),
            state.lists,
          ),
          assoc(
            'wuid',
            pathOr('', ['data', 'wuid'], payload),
            pathOr(
              { id: '', name: '', color: '' },
              ['payload', 'list'],
              payload,
            ),
          ),
          state.lists,
        ),
        state,
      )
    case REMOVE_LIST.FULFILLED:
      return {
        ...state,
        lists: reject(
          propEq('id', path(['data', 'list_id'], payload)),
          state.lists,
        ),
        guests: map(
          (guest) =>
            propEq('list_id', pathOr('', ['data', 'list_id'], payload), guest)
              ? assoc('list_id', '0', guest)
              : guest,
          state.guests,
        ),
      }
    default:
      return state
  }
}

export default guestsReducer
