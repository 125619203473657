import React, { FC } from 'react'
import { useField } from 'react-final-form'

import { Input } from 'components/ui'

type Props = {
  height?: string | number
}

const WeddingNameInput: FC<Props> = ({ height }) => {
  const { input } = useField('weddingName')

  return <Input {...input} height={height} placeholder="Wedding name" />
}

export default WeddingNameInput
