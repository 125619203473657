import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Button, Text } from 'components/ui'
import { toggleAppSections } from 'store/modules/nav/actions'
import { ReactComponent as Icon } from './smartphone.svg'

const AddNewSectionButton = () => {
  const dispatch = useDispatch()

  const onClick = useCallback(() => dispatch(toggleAppSections()), [dispatch])

  return (
    <Button
      height="60px"
      fullWidth
      bg="#353B60"
      pl="22px"
      borderRadius="10px"
      justifyContent="flex-start"
      boxShadow="0px 2px 2px rgba(53, 59, 96, 0.15)"
      onClick={onClick}
    >
      <Box
        bg="white"
        width="34px"
        height="34px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="16px"
        mr="15px"
      >
        <Icon />
      </Box>

      <Text isCapitalize fontWeight="bold" fontSize="16px" color="white">
        Add new section
      </Text>
    </Button>
  )
}

export default AddNewSectionButton
