import React, { FC, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Form, FormRenderProps } from 'react-final-form'
import validate from 'validate.js'
import { mergeAll } from 'ramda'

import {
  passwordConstraint,
  passwordConfirmationConstraint,
} from 'constants/constraints'
import { resetPassword } from 'store/modules/auth/actions'
import { PasswordStrengthIndicator } from 'components/Auth'
import { Box, Button, Text } from 'components/ui'
import { Input } from 'components/form'
import Success from './ResetPasswordSuccess'

type Props = {
  resetCode: string
}

const ResetPasswordForm: FC<Props> = ({ resetCode }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isSuccessVisible, setIsSuccessVisible] = useState(false)
  const dispatch = useDispatch()

  const onResetPassword = useCallback(
    async (password) => dispatch(resetPassword(resetCode, password)),
    [dispatch, resetCode]
  )

  const handleOnFocusPassword = () => setIsPasswordVisible(true)
  const handleOnBlurPassword = () => setIsPasswordVisible(false)
  const handleValidate = (values: object) =>
    validate(
      values,
      mergeAll([passwordConstraint(), passwordConfirmationConstraint()])
    )
  const handleSubmit = async (
    { password }: { password: string },
    _: any,
    complete?: (x: any) => void
  ) => {
    try {
      await onResetPassword(password)

      setIsSuccessVisible(true)
    } catch (error) {
      if (complete) {
        complete([error.message])
      }
    }
  }

  const renderForm = ({
    submitting,
    handleSubmit,
    submitErrors,
    values,
  }: FormRenderProps<{ password: string }>) => (
    <Box as="form" autoComplete="off" onSubmit={handleSubmit}>
      {submitErrors && (
        <Box mb={1}>
          {submitErrors.map((error: string, i: number) => (
            <Text key={i} color="red">
              {error}
            </Text>
          ))}
        </Box>
      )}

      <Box mb={1} position="relative">
        {isPasswordVisible && (
          <PasswordStrengthIndicator password={values.password} />
        )}

        <Input
          label="Password"
          placeholder="Password"
          name="password"
          type="password"
          onFocus={handleOnFocusPassword}
          onBlur={handleOnBlurPassword}
        />
      </Box>

      <Box mb={3}>
        <Input
          label="Re-Enter Password"
          placeholder="Re-Enter Password"
          name="passwordConfirmation"
          type="password"
        />
      </Box>

      <Button type="submit" variant="primary" disabled={submitting} fullWidth>
        Reset Password
      </Button>
    </Box>
  )

  return isSuccessVisible ? (
    <Success />
  ) : (
    <>
      <Box mb={1}>
        <Text fontSize={5} fontWeight={600}>
          Reset your password
        </Text>
      </Box>

      <Form
        validate={handleValidate}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    </>
  )
}

export default ResetPasswordForm
