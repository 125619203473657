import React, { FC, useEffect } from 'react'

import { Box, Text, Button } from 'components/ui'
import { DayPickerInput, CheckBox } from 'components/form'
import { ReactComponent as Icon } from 'images/ww-step3.svg'
import { getDefaultWeddingName } from 'utils/wedding'
import { MainBox, Title, Subtitle } from 'components/WeddingWizard'

type Props = {
  spouse1FirstName: string
  yourSpouseFirstName: string
  isNotSureAboutDate: boolean
  isValid: boolean
  onChange: (filed: string, value: string) => void
  onBack: VoidFunction
  onContinue: VoidFunction
}

const Step3: FC<Props> = ({
  spouse1FirstName,
  yourSpouseFirstName,
  isNotSureAboutDate,
  isValid,
  onChange,
  onBack,
  onContinue,
}) => {
  useEffect(() => {
    const weddingName = getDefaultWeddingName({
      spouse1FirstName,
      yourSpouseFirstName,
    })

    onChange('weddingName', weddingName)
  }, [onChange, spouse1FirstName, yourSpouseFirstName])

  return (
    <MainBox pt="70px" pb="32px">
      <Box mt="-98px" mb="28px">
        <Icon />
      </Box>

      <Title>When’s the big day?</Title>

      <Box mt="8px" maxWidth="462px" width="100%" display="flex">
        <Subtitle>
          Don’t fret if you haven’t decided yet or your plans change. You can
          update your wedding date at any time.
        </Subtitle>
      </Box>

      <Box mt="40px" maxWidth="400px" width="100%">
        <Text
          fontSize="20px"
          fontWeight={600}
          color="secondary"
          lineHeight="25px"
        >
          Wedding date:
        </Text>

        <Box mt="8px" />

        <DayPickerInput
          placeholder="Select date"
          name="weddingDate"
          disabledDays={{ before: new Date() }}
          disabled={isNotSureAboutDate}
          overlayPosition="right"
          hideValue={isNotSureAboutDate}
          height="40px"
        />

        <Box mt="22px" pl="2px" display="flex" alignItems="center">
          <CheckBox name="isNotSureAboutDate" />

          <Box ml="10px" mt="-4px">
            <Text color="#414042" fontFamily="1">
              We’re still deciding
            </Text>
          </Box>
        </Box>

        <Box
          width="100%"
          mt="94px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="secondary" height="40px" onClick={onBack}>
            Back
          </Button>

          <Button
            variant="primary"
            disabled={!isValid}
            height="40px"
            onClick={onContinue}
          >
            Next
          </Button>
        </Box>
      </Box>
    </MainBox>
  )
}

export default Step3
