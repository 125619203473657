import { join } from 'ramda'

import { LOAD_ALBUMS } from '../actionTypes'

export const loadAlbums = () => ({
  type: LOAD_ALBUMS.name,
  api: {
    url: 'albums/list',
    data: {
      field_list: join(',', [
        'album_id',
        'album_title',
        'number_of_photos',
        'cover_image',
        'album_type',
        'system_type',
        'album_owner_uid',
        'album_owner_name',
        'album_owner_image',
        'shares',
        'insert_date',
      ]),
    },
  },
})
