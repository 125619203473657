import { ADD_MENU } from '../../actionTypes'
import { AddMenuNeedData } from '../../../../../routes/FoodMenu/tipes_interfaces'

export const add = (data: AddMenuNeedData) => {
  return {
    type: ADD_MENU.name,
    api: {
      url: 'menu/add',
      data,
    },
  }
}