import React, { MouseEvent, useMemo } from 'react'
import styled from 'styled-components'

import { Button, Box, Text } from 'components/ui'
import { PAGE_TYPES } from 'constants/wedding'

import { ReactComponent as HomeIcon } from './home.svg'
import { ReactComponent as ThemeIcon } from './theme.svg'
import { ReactComponent as MessagingIcon } from './messaging.svg'

export const Wrapper = styled(Button)`
  background: transparent;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

type Props = {
  value: string
  label: string
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const Tab = ({ value, label, onClick }: Props) => {
  const Icon = useMemo(() => {
    switch (value) {
      case 'home':
        return HomeIcon
      case PAGE_TYPES[0]:
        return ThemeIcon
      default:
        return MessagingIcon
    }
  }, [value])

  return (
    <Wrapper
      as="button"
      value={value}
      height="40px"
      px="12px"
      borderRadius="6px"
      mr="24px"
      onClick={onClick}
    >
      <Icon />

      <Box mr="12px" />

      <Text fontWeight={600} fontSize="18px" color="white">
        {label}
      </Text>
    </Wrapper>
  )
}

export default Tab
