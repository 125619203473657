import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router'
import { Form, FormRenderProps } from 'react-final-form'
// import { createPersistDecorator } from 'final-form-persist'
import { path, last, split } from 'ramda'
import validate from 'validate.js'

import { getUser } from 'store/modules/auth/selectors'
import {
  add as addWedding,
  update as updateWedding,
  list as loadWeddings,
} from 'store/modules/wedding/actions'
import { addGuest, sendInvite, addUser } from 'store/modules/guests/actions'
// import { loadDefaultQuestions, addQuestion } from 'store/modules/faq/actions'
// import { addPhoto as addImage } from 'store/modules/photos/actions'
import { loadDefaultImages } from 'store/modules/wedding/actions'
import { Box } from 'components/ui'
import { Wrapper, Transitions } from 'components/WeddingWizard'
import { initialValues } from 'utils/wedding'
import { presenceFieldConstraint, emailConstraint } from 'constants/constraints'
import useUploader from 'utils/useUploader'
import Intro from './Intro'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import Step8 from './Step8'
import Step9 from './Step9'
import Step10 from './Step10'
import Step11 from './Step11'
import Signup from './Signup'
import Login from './Login'

const WeddingWizard: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [spouseNumber, setSpouseNumber] = useState(1)
  const userInfo = useSelector(getUser)
  const { onUpload } = useUploader()

  const onAddWedding = useCallback(async (data) => dispatch(addWedding(data)), [
    dispatch,
  ])
  const onAddGuest = useCallback(async (data) => dispatch(addGuest(data)), [
    dispatch,
  ])
  const onSendInvite = useCallback(async (data) => dispatch(sendInvite(data)), [
    dispatch,
  ])
  // const onAddImage = useCallback(async (data) => dispatch(addImage(data)), [
  //   dispatch,
  // ])
  const onUpdateWedding = useCallback(
    async (data) => dispatch(updateWedding(data)),
    [dispatch]
  )
  const onLoadWeddings = useCallback(async () => dispatch(loadWeddings()), [
    dispatch,
  ])
  const onAddUser = useCallback(async (data) => dispatch(addUser(data)), [
    dispatch,
  ])
  // const onLoadDefaultQuestions = useCallback(
  //   async (data) => dispatch(loadDefaultQuestions(data)),
  //   [dispatch]
  // )
  // const onAddQuestion = useCallback(
  //   async (data) => dispatch(addQuestion(data)),
  //   [dispatch]
  // )

  const onLoadDefaultImages = useCallback(
    async (wuid: string) => dispatch(loadDefaultImages(wuid)),
    [dispatch]
  )

  const pageNumber = parseInt(last(split('/', location.pathname)) || '0', 10)
  const handleClickContinue = useCallback(
    (page: number, isWeddingPlanner: boolean = false) => () => {
      if (isWeddingPlanner && spouseNumber === 1) {
        return setSpouseNumber(2)
      }

      return history.push({
        pathname:
          !userInfo && page === 7
            ? '/wedding/create/signup'
            : `/wedding/create/step/${page + 1}`,
        state: { transition: 'wizard-content-forward' },
      })
    },
    [history, spouseNumber, userInfo]
  )
  const handleClickBack = useCallback(
    (page: number, isWeddingPlanner: boolean = false) => () => {
      if (isWeddingPlanner && spouseNumber === 2) {
        return setSpouseNumber(1)
      }

      return history.push({
        pathname: `/wedding/create/step/${page - 1}`,
        state: { transition: 'wizard-content-backward' },
      })
    },
    [spouseNumber, history]
  )

  const handleValidate = useCallback(
    (values: any) => {
      const isWeddingPlanner = values.yourRole === 'weddingPlanner'

      switch (pageNumber) {
        case 1:
          return validate(values, {
            ...presenceFieldConstraint('spouse1FirstName'),
            ...presenceFieldConstraint('spouse1LastName'),
            ...presenceFieldConstraint('yourRole'),
          })
        case 2:
          return validate(
            values,
            isWeddingPlanner
              ? {
                  ...presenceFieldConstraint(`spouse${spouseNumber}Role`),
                  ...presenceFieldConstraint(`spouse${spouseNumber}FirstName`),
                  ...presenceFieldConstraint(`spouse${spouseNumber}LastName`),
                  ...emailConstraint(`spouse${spouseNumber}Email`),
                }
              : {
                  ...presenceFieldConstraint('yourSpouseRole'),
                  ...presenceFieldConstraint('yourSpouseFirstName'),
                  ...presenceFieldConstraint('yourSpouseLastName'),
                  ...(values.inviteYourSpouse
                    ? emailConstraint('yourSpouseEmail')
                    : undefined),
                }
          )
        case 3:
          return validate(
            values,
            values.isNotSureAboutDate
              ? {
                  // ...presenceFieldConstraint('year'),
                  // ...presenceFieldConstraint('season'),
                }
              : presenceFieldConstraint('weddingDate')
          )
        case 4:
          return validate(values, presenceFieldConstraint('nGuests'))
        case 5:
          return validate(
            values,
            values.isNotSureAboutRSVP
              ? {}
              : presenceFieldConstraint('rsvpDeadline')
          )
        case 6:
          return validate(
            values,
            values.isNotSureAboutVenue ? {} : presenceFieldConstraint('address')
          )
        case 7:
          return validate(values, {
            ...presenceFieldConstraint(
              'croppedImageSrc',
              !values.imageSrc && !values.temporaryPhotoId
            ),
          })
        case 8:
          return validate(values, {
            ...presenceFieldConstraint('theme_color'),
          })
        case 9:
          return validate(values, {
            ...presenceFieldConstraint('weddingName'),
            ...presenceFieldConstraint('font'),
          })
        case 10:
          return validate(values, {
            common_code: {
              presence: {
                message: '^The wedding code must be at least 6 characters',
              },
              format: {
                pattern: '[a-z0-9]+',
                flags: 'i',
                message: `^The wedding code can't contain spaces or special characters`,
              },
              length: {
                minimum: 6,
                tooShort: '^The wedding code must be at least 6 characters',
                maximum: 10,
                tooLong: '^The wedding code must be at least 10 characters',
              },
            },
          })
        case 11:
          return validate(
            values,
            !values.skipInviteSpouse ? emailConstraint('yourSpouseEmail') : {}
          )
        default:
          return {}
      }
    },
    [pageNumber, spouseNumber]
  )

  // const { persistDecorator, clear } = useMemo(
  //   () =>
  //     createPersistDecorator({
  //       name:
  //         process.env.REACT_APP_WEDDING_WIZARD_PERSIST_KEY || 'hhWeddingWizard',
  //       debounceTime: 500,
  //     }),
  //   []
  // )
  const clear = useCallback(() => null, [])

  const onSubmit = async (values: any) => {
    const isWeddingPlanner = values.yourRole === 'weddingPlanner'

    try {
      // Create wedding
      const wuid = path<string>(
        ['value', 'payload', 'wedding', 'wuid'],
        await onAddWedding(values)
      )

      if (!wuid) {
        throw new Error('Something goes wrong with creating the wedding')
      }

      // Add myself
      if (userInfo) {
        await onAddUser({
          wuid,
          // list_id: adminsListId,
          uuid: userInfo.uuid,
          role: values.yourRole,
          is_spouse: 1,
          is_admin: 1,
        })
      }

      // Add wedding photo
      const imageSrc = values.croppedImageSrc || values.imageSrc

      if (imageSrc) {
        const { photoUid } = (await onUpload(imageSrc, wuid)) || {}

        if (photoUid) {
          await onUpdateWedding({
            wuid,
            photo_uid: photoUid,
          })
        }
      } else {
        // Add default image
        const defaultImages = path<{ photo_uid: string }[]>(
          ['value', 'payload', 'photos'],
          await onLoadDefaultImages(wuid || '')
        )

        if (defaultImages && defaultImages.length > 0) {
          await onUpdateWedding({
            wuid,
            photo_uid: path(
              [Math.floor(Math.random() * defaultImages.length), 'photo_uid'],
              defaultImages
            ),
          })
        }
      }

      // Add the Spouse to the List of Admins (if email is present in Step 2)
      if (isWeddingPlanner) {
        // Guest 1
        const guest1Id = path(
          ['value', 'payload', 'guest', 'id'],
          await onAddGuest({
            wuid,
            first_name: values.spouse1FirstName,
            last_name: values.spouse1LastName,
            email: values.spouse1Email,
            role: values.spouse1Role,
            is_spouse: 2,
          })
        )

        await onSendInvite({
          wuid,
          guest_id: guest1Id,
          via_email: 1,
        })

        // Guest 2
        const guest2Id = path(
          ['value', 'payload', 'guest', 'id'],
          await onAddGuest({
            wuid,
            first_name: values.spouse2FirstName,
            last_name: values.spouse2LastName,
            email: values.spouse2Email,
            role: values.spouse2Role,
            is_spouse: 2,
          })
        )

        await onSendInvite({
          wuid,
          guest_id: guest2Id,
          via_email: 1,
        })
      } else {
        const addSpousePayload = path(
          ['value', 'payload'],
          await onAddGuest({
            wuid,
            first_name: values.yourSpouseFirstName,
            last_name: values.yourSpouseLastName,
            email: values.yourSpouseEmail,
            role: values.yourSpouseRole,
            is_spouse: 2,
          })
        )

        // Invite the Spouse to the CMS (if email is present in Step 2)
        if (!values.skipInviteSpouse) {
          await onSendInvite({
            wuid,
            guest_id: path(['guest', 'guest_id'], addSpousePayload),
            via_email: 1,
          })
        }
      }

      // Set default faq questions
      // forEach(async (question) => {
      //   await onAddQuestion({ wuid, question })
      // }, pathOr([], ['value', 'payload', 'questions'], await onLoadDefaultQuestions(wuid)))

      await onLoadWeddings()

      clear()
      history.push('/list')
    } catch (error) {
      toast(error.message)
    }
  }

  const renderWeddingWizard = ({
    handleSubmit,
    values,
    valid,
    form,
    submitting,
  }: FormRenderProps) => {
    const isWeddingPlanner = values.yourRole === 'weddingPlanner'

    return (
      <Box as="form" onSubmit={handleSubmit}>
        <Wrapper isWeddingPlanner={isWeddingPlanner}>
          <Transitions pageKey={location.key} {...location.state}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={[null, 'center']}
            >
              <Switch location={location}>
                <Route exact path="/wedding/create" component={Intro} />
                <Route
                  path="/wedding/create/step/1"
                  render={() => (
                    <Step1
                      isValid={valid}
                      onContinue={handleClickContinue(1)}
                    />
                  )}
                />
                <Route
                  path="/wedding/create/step/2"
                  render={() => (
                    <Step2
                      spouse1FirstName={values.spouse1FirstName}
                      inviteYourSpouse={values.inviteYourSpouse}
                      yourSpouseEmail={values.yourSpouseEmail}
                      isValid={valid}
                      onChange={form.change}
                      onBack={handleClickBack(2, isWeddingPlanner)}
                      onContinue={handleClickContinue(2, isWeddingPlanner)}
                    />
                  )}
                />
                <Route
                  path="/wedding/create/step/3"
                  render={() => (
                    <Step3
                      spouse1FirstName={values.spouse1FirstName}
                      yourSpouseFirstName={values.yourSpouseFirstName}
                      isNotSureAboutDate={values.isNotSureAboutDate}
                      isValid={valid}
                      onBack={handleClickBack(3)}
                      onChange={form.change}
                      onContinue={handleClickContinue(3)}
                    />
                  )}
                />
                <Route
                  path="/wedding/create/step/4"
                  render={() => (
                    <Step4
                      isValid={valid}
                      onBack={handleClickBack(4)}
                      onContinue={handleClickContinue(4)}
                    />
                  )}
                />
                <Route
                  path="/wedding/create/step/5"
                  render={() => (
                    <Step5
                      isNotSureAboutRSVP={values.isNotSureAboutRSVP}
                      weddingDate={
                        !values.isNotSureAboutDate
                          ? values.weddingDate
                          : undefined
                      }
                      isValid={valid}
                      onBack={handleClickBack(5)}
                      onContinue={handleClickContinue(5)}
                    />
                  )}
                />
                <Route
                  path="/wedding/create/step/6"
                  render={() => (
                    <Step6
                      isValid={valid}
                      isNotSureAboutVenue={values.isNotSureAboutVenue}
                      onBack={handleClickBack(6)}
                      onContinue={handleClickContinue(6, false)}
                    />
                  )}
                />
                <Route
                  path="/wedding/create/step/7"
                  render={() => (
                    <Step7
                      croppedImageSrc={values.croppedImageSrc}
                      imageSrc={values.imageSrc}
                      onChange={form.change}
                      isValid={valid}
                      onBack={handleClickBack(7)}
                      onContinue={handleClickContinue(7)}
                    />
                  )}
                />

                <Route
                  path="/wedding/create/step/8"
                  render={() => {
                    return (
                      <Step8
                        color={values.theme_color}
                        color2={values.theme_color2}
                        weddingName={values.weddingName}
                        font={values.font}
                        image={values.croppedImageSrc || values.imageSrc}
                        isValid={valid}
                        onBack={handleClickBack(8)}
                        onContinue={handleClickContinue(8)}
                      />
                    )
                  }}
                />
                <Route
                  path="/wedding/create/step/9"
                  render={() => (
                    <Step9
                      color={values.theme_color}
                      color2={values.theme_color2}
                      weddingName={values.weddingName}
                      font={values.font}
                      image={values.croppedImageSrc || values.imageSrc}
                      isValid={valid}
                      onBack={handleClickBack(9)}
                      onContinue={handleClickContinue(9)}
                    />
                  )}
                />

                <Route
                  path="/wedding/create/step/10"
                  render={() => (
                    <Step10
                      isValid={valid}
                      codes={values.codes}
                      spouseFirstName={values.yourSpouseFirstName}
                      spouseLastName={values.yourSpouseLastName}
                      weddingDate={values.weddingDate}
                      onSetCodes={(codes: any) => form.change('codes', codes)}
                      onSetCode={(code?: string) =>
                        form.change('common_code', code)
                      }
                      inviteType={values.invite_type || 0}
                      onSetInviteType={(inviteType: number) =>
                        form.change('invite_type', inviteType)
                      }
                      onBack={handleClickBack(10)}
                      onContinue={handleClickContinue(10)}
                    />
                  )}
                />

                <Route
                  path="/wedding/create/step/11"
                  render={() => (
                    <Step11
                      isValid={valid}
                      isSubmitting={submitting}
                      onBack={handleClickBack(11)}
                      onContinue={form.submit}
                    />
                  )}
                />

                <Route
                  path="/wedding/create/login"
                  render={() => (
                    <Login
                      color={values.theme_color}
                      color2={values.theme_color2}
                      title={values.weddingName}
                      font={values.font}
                      image={values.croppedImageSrc || values.imageSrc}
                    />
                  )}
                />
                <Route
                  path="/wedding/create/signup"
                  render={() => (
                    <Signup
                      firstName={values.spouse1FirstName}
                      lastName={values.spouse1LastName}
                      color={values.theme_color}
                      color2={values.theme_color2}
                      title={values.weddingName}
                      font={values.font}
                      image={values.croppedImageSrc || values.imageSrc}
                    />
                  )}
                />

                <Redirect
                  to={
                    userInfo
                      ? '/wedding/create/step/8'
                      : '/wedding/create/step/1'
                  }
                />
              </Switch>
            </Box>
          </Transitions>
        </Wrapper>
      </Box>
    )
  }

  return (
    <Form
      // decorators={[persistDecorator]}
      validate={handleValidate}
      render={renderWeddingWizard}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  )
}

export default WeddingWizard
