import React, { forwardRef } from 'react'
import { Box } from 'components/ui'
import { ReactComponent as CheckboxChecked } from 'images/checkbox-checked.svg'
import { ReactComponent as CheckboxUnchecked } from 'images/checkbox-unchecked.svg'

// Components
export const BootyCheckbox = forwardRef(((props: any, ref: any) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' style={{ position: 'unset' }} ref={ref}>
      <Box
        height='38px'
        width='2px'
        backgroundColor='#73737B'
        position='absolute'
        bottom='37px'
      />
      {props['checked'] ? (
        <CheckboxChecked {...props} />
      ) : (
        <CheckboxUnchecked stroke='#73737B' {...props} />
      )}
    </Box>
  )
}))

// Types
export type RowProps = {
  tableProps: any,
}

// Styles
export const customStyles: object = {
  cells: {
    style: {
      '&:nth-of-type(2)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#E8E8ED',
        backgroundColor: 'inherit',
        zIndex: 1,
      },
      '&:nth-of-type(1)': {
        zIndex: 1,
        position: 'sticky',
        left: '0',
        minHeight: '56px',
        backgroundColor: 'inherit',
      },
    },
  },
  rows: {
    style: {
      height: '56px',
    },
  },
  table: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px !important',
      borderBottomColor: '#E8E8ED',
      marginTop: '-1px',
    },
  },
}

export const getConditionalRowStyles: (props: any) => [{ style: { borderBottomWidth: string }; when: () => boolean }, { style: { backgroundColor: string }; when: (row: any) => any }] = (props) => {
  return [
    {
      when: () => true,
      style: {
        borderBottomWidth: '0',
      },
    },
    {
      when: (row: any) => props.rowSelectCriteria(row),
      style: {
        backgroundColor: '#F7F7FC',
      },
    },
  ]
}