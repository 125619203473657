import React, { FC, useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { isNil, equals } from 'ramda'

import { Box, Loading } from 'components/ui'
import { Sidebar, Chat, RoomMenu, NewChat } from 'components/Messages'
import { pushData, pushHistory } from 'store/modules/messages/actions'
import { getChatIsOpen } from 'store/modules/messages/selectors'
import { getAuthToken } from 'store/modules/auth/selectors'

const Messages: FC = () => {
  const dispatch = useDispatch()
  const [socket, setSocket] = useState<WebSocket>()
  const at = useSelector(getAuthToken)
  const chatIsOpen = useSelector(getChatIsOpen)
  const [isNewChatOpen, setIsNewChatOpen] = useState(false)
  const { wuid } = useParams<{ wuid: string }>()
  const rid = Math.random().toString(36).substring(2, 15)

  const handleOpenNewChat = () => setIsNewChatOpen(!isNewChatOpen)
  const handleCloseNewChat = () => setIsNewChatOpen(false)
  const handlePushData = useCallback((data) => dispatch(pushData(data)), [
    dispatch,
  ])
  const handlePushHistory = useCallback((data) => dispatch(pushHistory(data)), [
    dispatch,
  ])

  useEffect(() => {
    if (isNil(socket) && process.env.REACT_APP_API_URL_WS) {
      const ws = new WebSocket(process.env.REACT_APP_API_URL_WS)

      ws.onopen = () => {
        setSocket(ws)

        ws.send(
          JSON.stringify({
            c: 'i',
            s: rid,
            at,
            wuid,
            rid,
          })
        )
      }

      ws.onmessage = (message) => {
        const data = JSON.parse(message.data)

        if (equals(data.z, 'u')) return handlePushData(data)
        if (equals(data.code, 0) && data.rid) return handlePushHistory(data)
      }

      ws.onerror = (error) => console.log(error)
      ws.onclose = () => console.log('closed')
    }
  }, [socket, at, wuid, rid, handlePushData, handlePushHistory])

  if (!socket) return <Loading />

  return (
    <Box display="flex" height="100%">
      <Box width="320px">
        <Sidebar
          onCloseNewChat={handleCloseNewChat}
          onClickNewChatButton={handleOpenNewChat}
        />
      </Box>

      {(chatIsOpen || isNewChatOpen) && (
        <Box width="575px">
          {isNewChatOpen ? (
            <NewChat socket={socket} onSuccess={handleCloseNewChat} />
          ) : (
            <Chat socket={socket} />
          )}
        </Box>
      )}

      {chatIsOpen && (
        <Box width="calc(100% - 895px)">
          <RoomMenu socket={socket} />
        </Box>
      )}
    </Box>
  )
}

export default Messages
