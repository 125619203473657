import React, { FC, useCallback, useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { isDate, getYear } from 'date-fns'
import { toast } from 'react-toastify'
import { path } from 'ramda'

import { WEDDING_CREATE } from 'constants/paths'
import { getUser } from 'store/modules/auth/selectors'
import { Text, Box, Button, Switch } from 'components/ui'
import { loadCodes } from 'store/modules/wedding/actions'
import { CodeInput } from 'components/WeddingWizard'
import { getIsLoggedIn } from 'store/modules/auth/selectors'
import { ReactComponent as Icon } from 'images/ww-step10.svg'
import { MainBox, Title, Subtitle } from 'components/WeddingWizard'

type Props = {
  isValid: boolean
  codes?: string[]
  spouseFirstName?: string
  spouseLastName?: string
  weddingDate?: Date
  inviteType: number
  onSetCodes: (codes: any) => void
  onSetCode: (code?: string) => void
  onSetInviteType: (inviteType: number) => void
  onBack: VoidFunction
  onContinue: VoidFunction
}

const Step10: FC<Props> = ({
  codes,
  spouseFirstName = '',
  spouseLastName = '',
  weddingDate,
  inviteType,
  onSetCodes,
  onSetCode,
  onSetInviteType,
  onBack,
  onContinue,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadCodesError, setLoadCodesError] = useState(false)
  const [isCodesLoaded, setIsCodesLoaded] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const year = isDate(weddingDate) ? getYear(weddingDate as Date) : undefined
  const isLoggedIn = useSelector(getIsLoggedIn)

  const onLoadCodes = useCallback(
    async () =>
      dispatch(
        loadCodes({
          first_name1: user?.first_name || '',
          last_name1: user?.last_name || '',
          first_name2: spouseFirstName,
          last_name2: spouseLastName,
          year,
        })
      ),
    [dispatch, user, spouseFirstName, spouseLastName, year]
  )

  const handleClickRefresh = useCallback(() => {
    if (codes) {
      onSetCode(
        path([Math.round(0.5 + Math.random() * codes.length) - 1], codes)
      )
    }
  }, [codes, onSetCode])

  const handleChangeInviteType = useCallback(
    (type: 0 | 1) => () => {
      onSetInviteType(
        type !== inviteType ? type : [0, 1].find((v) => v !== inviteType) || 0
      )
    },
    [onSetInviteType, inviteType]
  )

  useEffect(() => {
    const handleLoadCodes = async () => {
      setIsLoading(true)

      try {
        const res = path<string[]>(
          ['value', 'payload', 'common_codes'],
          await onLoadCodes()
        )

        if (res) {
          onSetCodes(res)

          onSetCode(
            path([Math.round(0.5 + Math.random() * res.length) - 1], res)
          )

          setIsCodesLoaded(true)
        }
      } catch (error) {
        toast(error.message)
        setLoadCodesError(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (!isLoadCodesError && user && !isCodesLoaded && !isLoading) {
      handleLoadCodes()
    }
  }, [
    isCodesLoaded,
    isLoading,
    onSetCodes,
    onSetCode,
    onLoadCodes,
    handleClickRefresh,
    user,
    isLoadCodesError,
  ])

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{ pathname: `${WEDDING_CREATE}/signup`, state: { step: 10 } }}
      />
    )
  }

  return (
    <MainBox pt="70px" pb="28px">
      <Box mt="-93px" mb="30px">
        <Icon />
      </Box>

      <Title>Your wedding’s privacy settings</Title>

      <Box mt="8px" />

      <Subtitle>Select how guests should access your wedding.</Subtitle>

      <Box
        width="100%"
        maxWidth="400px"
        display="flex"
        flexDirection="column"
        mt={['32px', '40px']}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text fontSize="20px" fontWeight={600} color="secondary">
            Common code:
          </Text>

          <Box display="flex" alignItems="center">
            <Box
              bg="#DDDEFD"
              height="26px"
              width="111px"
              mr="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text
                fontFamily="1"
                fontWeight={600}
                fontSize="11px"
                lineHeight="13px"
                color="#414042"
                isUppercase
              >
                Convenient
              </Text>
            </Box>

            <Switch
              checked={inviteType === 0}
              onChange={handleChangeInviteType(0)}
            />
          </Box>
        </Box>

        <Box mt="12px">
          <Text
            fontWeight={300}
            fontSize="16px"
            lineHeight="20px"
            color="secondary"
          >
            Guests are also matched by their name in the guest list.
          </Text>
        </Box>

        {inviteType === 0 && (
          <Box mt="8px">
            <CodeInput
              isLoading={isLoading}
              errorMessage={errorMessage}
              onSetErrorMessage={setErrorMessage}
              onClickRefresh={handleClickRefresh}
            />
          </Box>
        )}

        <Box my="16px" borderBottom="1px solid #E8E8ED" />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text fontSize="20px" fontWeight={600} color="secondary">
            Unique codes:
          </Text>

          <Box display="flex" alignItems="center">
            <Box
              bg="#DDDEFD"
              height="26px"
              width="111px"
              mr="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text
                fontFamily="1"
                fontWeight={600}
                fontSize="11px"
                lineHeight="13px"
                color="#414042"
                isUppercase
              >
                Most secure
              </Text>
            </Box>

            <Switch
              checked={inviteType === 1}
              onChange={handleChangeInviteType(1)}
            />
          </Box>
        </Box>

        <Box mt="12px">
          <Text
            fontWeight={300}
            fontSize="16px"
            lineHeight="20px"
            color="secondary"
          >
            Codes are generated when guests are added to the guest list.
          </Text>
        </Box>

        <Box
          mt={inviteType === 0 ? '40px' : '88px'}
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="secondary" height="40px" onClick={onBack}>
            Back
          </Button>

          <Button
            type="submit"
            variant="primary"
            height="40px"
            onClick={onContinue}
          >
            Next
          </Button>
        </Box>
      </Box>
    </MainBox>
  )
}

export default Step10
