import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormRenderProps } from 'react-final-form'
import { SubmissionErrors } from 'final-form'
import validate from 'validate.js'
import { toast } from 'react-toastify'

import { getSChart } from 'store/modules/seatings/selectors'
import {
  addChart,
  editChart,
  removeChart,
} from 'store/modules/seatings/actions'
import { presenceFieldConstraint } from 'constants/constraints'
import { Box, Button, Text } from 'components/ui'
import { Input } from 'components/form'

type Values = {
  caption?: string
  width?: number
  height?: number
}

type Props = {
  chartId?: string
  onSuccess: (values: Values) => void
  onClose: VoidFunction
}

const ChartForm = ({ chartId, onSuccess, onClose }: Props) => {
  const dispatch = useDispatch()
  const schart = useSelector(getSChart(chartId))
  const [isRemoving, setIsRemoving] = useState(false)

  const initialValues = useMemo<Values>(
    () => ({
      caption: schart?.caption,
      width: schart?.width,
      height: schart?.height,
    }),
    [schart]
  )

  const onAddChart = useCallback(
    async (values: Values) =>
      dispatch(
        addChart({
          caption: values.caption,
          height: values.height,
          width: values.width,
        })
      ),
    [dispatch]
  )

  const onEditChart = useCallback(
    async (chartId: string, values: Values) =>
      dispatch(
        editChart(chartId, {
          caption: values.caption,
          height: values.height,
          width: values.width,
        })
      ),
    [dispatch]
  )

  const onRemoveChart = useCallback(
    async (chartId: string) => dispatch(removeChart(chartId)),
    [dispatch]
  )

  const onValidate = useCallback(
    (values: Values) => validate(values, presenceFieldConstraint('caption')),
    []
  )

  const onSubmit = useCallback(
    async (
      values: Values,
      _,
      complete?: (errors?: SubmissionErrors) => void
    ) => {
      try {
        chartId ? await onEditChart(chartId, values) : await onAddChart(values)

        return onSuccess(values)
      } catch (error) {
        if (complete) {
          complete([error.message])
        } else {
          toast(error.message)
        }
      }
    },
    [chartId, onAddChart, onEditChart, onSuccess]
  )

  const onClickRemoveButton = useCallback(async () => {
    if (!chartId) {
      return
    }

    try {
      setIsRemoving(true)

      await onRemoveChart(chartId)
    } catch (error) {
      toast(error.message)
    } finally {
      setIsRemoving(false)

      onClose()
    }
  }, [chartId, onRemoveChart, onClose])

  const renderForm = ({
    handleSubmit,
    submitErrors,
    submitting,
  }: FormRenderProps<Values>) => (
    <Box as="form" p="24px" onSubmit={handleSubmit}>
      <Input
        height="40px"
        name="caption"
        label="Title"
        isRequired
        placeholder="Seating chart"
        hideErrorMessages
        borderRadius="8px"
      />

      {submitErrors?.map((message: string, key: number) => (
        <Box mt="6px" key={key}>
          <Text color="red">{message}</Text>
        </Box>
      ))}

      <Box mt="30px" display="flex" justifyContent="flex-end">
        {schart ? (
          <Button
            border="2px solid #F44343"
            borderRadius="10px"
            height="40px"
            width="112px"
            mr="24px"
            fontWeight="600"
            fontFamily="0"
            fontSize="16px"
            color="#F44343"
            bg="#FFFFFF"
            disabled={submitting || isRemoving}
            onClick={onClickRemoveButton}
          >
            Delete
          </Button>
        ) : (
          <Button
            fontWeight={600}
            fontSize="16px"
            color="#5458F7"
            px="0px"
            mr="40px"
            bg="transparent"
            fontFamily="0"
            onClick={onClose}
          >
            Cancel
          </Button>
        )}

        <Button
          as="button"
          type="submit"
          height="40px"
          bg="#5458F7"
          borderRadius="10px"
          width="85px"
          fontSize="16px"
          color="white"
          fontWeight="600"
          fontFamily="0"
          disabled={submitting || isRemoving}
        >
          {schart ? 'Update' : 'Create'}
        </Button>
      </Box>
    </Box>
  )

  return (
    <Form
      render={renderForm}
      validate={onValidate}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  )
}

export default ChartForm
