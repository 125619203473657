import React, { useCallback } from 'react'

import { NavItem } from 'store/modules/nav/reducer'
import { Box, Text } from 'components/ui'
import { ReactComponent as DragIcon } from './drag.svg'

type Props = {
  isActive: boolean
  onSelectItem?: (id: NavItem['nav_item_id']) => void
}

const NestedItem = ({
  isActive,
  nav_item_id,
  caption,
  onSelectItem,
}: Partial<NavItem> & Props) => {
  const onClick = useCallback(() => {
    if (nav_item_id && onSelectItem) {
      onSelectItem(nav_item_id)
    }
  }, [nav_item_id, onSelectItem])

  return (
    <Box
      height="40px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bg={isActive ? 'white' : 'transparent'}
      boxShadow={isActive ? '0px 2px 2px rgba(0, 0, 0, 0.15)' : 'unset'}
      borderRadius={isActive ? '10px' : 'unset'}
      pl="26px"
      minWidth="208px"
      cursor="pointer"
      onClick={onClick}
    >
      <Text fontWeight={600} fontSize="16px" color="#353B60">
        {caption}
      </Text>

      <Box>
        <DragIcon />
      </Box>
    </Box>
  )
}

export default NestedItem
