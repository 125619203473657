import React, { FC } from 'react'
import { useHistory } from 'react-router'

import { Box, Text, Button } from 'components/ui'
import { Input } from 'components/form'
import { Radio } from 'components/WeddingWizard'
import { ReactComponent as Icon } from 'images/ww-step1.svg'
import { ROOT_PATH } from 'constants/paths'

type Props = {
  isValid: boolean
  onContinue: VoidFunction
}

const Step1: FC<Props> = ({ isValid, onContinue }) => {
  const { push } = useHistory()

  const onClickBack = () => push(ROOT_PATH)

  return (
    <Box
      width="100%"
      maxWidth="740px"
      boxShadow="0px 8px 24px rgba(0, 0, 0, 0.15)"
      bg="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      pt="75px"
      pb="28px"
      px={1}
    >
      <Box mt="-94px" mb="31px">
        <Icon />
      </Box>

      <Text
        fontSize={['28px', 5]}
        fontWeight="bold"
        color="secondary"
        lineHeight={['normal', '45px']}
        textAlign="center"
      >
        First, tell us about yourself.
      </Text>

      <Box mt="8px" />

      <Text
        fontSize="20px"
        fontWeight={300}
        color="secondary"
        lineHeight={['normal', '25px']}
        textAlign="center"
      >
        What’s your role in the wedding?
      </Text>

      <Box width="100%" maxWidth="402px" mt="40px">
        <Text
          fontWeight="600"
          fontSize="20px"
          lineHeight="25px"
          color="secondary"
        >
          My name:
        </Text>

        <Box
          mt="8px"
          width="100%"
          display="flex"
          flexDirection={['column', 'row']}
          justifyContent="spaspace-between"
        >
          <Box pr={['unset', '10px']} pb={['16px', 'unset']} width="100%">
            <Input
              name="spouse1FirstName"
              placeholder="First name"
              height="40px"
            />
          </Box>

          <Box pl={['unset', '10px']} width="100%">
            <Input
              name="spouse1LastName"
              placeholder="Last name"
              height="40px"
            />
          </Box>
        </Box>
      </Box>

      <Box width="100%" maxWidth="402px" mt="20px">
        <Text
          fontWeight="600"
          fontSize="20px"
          lineHeight="25px"
          color="secondary"
        >
          I am the:
        </Text>

        <Box
          mt="8px"
          width="100%"
          display="flex"
          flexDirection={['column', 'row']}
          justifyContent="spaspace-between"
        >
          <Box pr={['unset', '10px']} pb={['16px', 'unset']} width="100%">
            <Radio name="yourRole" value="bride" label="Bride" height="40px" />
          </Box>

          <Box pl={['unset', '10px']} width="100%">
            <Radio name="yourRole" value="groom" label="Groom" height="40px" />
          </Box>
        </Box>
      </Box>

      <Box
        width="100%"
        maxWidth="402px"
        mt="72px"
        display="flex"
        justifyContent="space-between"
      >
        <Button variant="secondary" height="40px" onClick={onClickBack}>
          Back
        </Button>

        <Button
          variant="primary"
          disabled={!isValid}
          height="40px"
          onClick={onContinue}
        >
          Next
        </Button>
      </Box>
    </Box>
  )
}

export default Step1
