import React from 'react'
import styled from 'styled-components'

import { Box, Text } from 'components/ui'
import NavIcon from 'components/Wedding/NavIcon'
import { ReactComponent as Chevron } from './chevron.svg'

const Wrapper = styled(Box)`
  &:not(:last-child) {
    border-bottom: 0.5px solid #e9e9e9;
  }
`

type Props = {
  caption: string
  icon: string
  bg: string
  color: string
}

const MenuItem = ({ caption, icon, bg, color }: Props) => (
  <Wrapper
    py="8px"
    pl="13px"
    pr="8px"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
  >
    <Box display="flex" alignItems="center">
      <Box
        width="19.5px"
        height="19.5px"
        bg={bg}
        borderRadius="5px"
        mr="8px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={color}
      >
        <NavIcon name={icon} width={16} height={16} strokeWidth={3} />
      </Box>

      <Text fontSize="10px" color="#495971">
        {caption}
      </Text>
    </Box>

    <Chevron />
  </Wrapper>
)

export default MenuItem
