import { toString } from 'ramda'

export const readImage = (file: Blob) =>
  new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () =>
        resolve(
          typeof reader.result === 'string'
            ? reader.result
            : toString(reader.result)
        ),
      false
    )
    reader.readAsDataURL(file)
  })

export const fileToArrayBody = (file: Blob) =>
  new Promise<ArrayBuffer>((resolve) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => resolve(reader.result as ArrayBuffer),
      false
    )
    reader.readAsArrayBuffer(file)
  })

export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */

export type PixelCropProps = {
  x: number
  y: number
  width: number
  height: number
}

export const cropImage = async (
  imageSrc: string,
  pixelCrop: PixelCropProps
) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  const ctx = canvas.getContext('2d')

  if (ctx) {
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    )
  }

  // As Base64 string
  return canvas.toDataURL('image/jpeg')

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob(file => {
  //     resolve(URL.createObjectURL(file));
  //   }, 'image/jpeg');
  // });
}

export const getFileFromUrl = (src: string, fileName: string) => {
  const type = src.split(';')[0].split('/')[1]

  return fetch(src)
    .then((res) => res.blob())
    .then((blob) => new File([blob], `${fileName}.${type}`, { type }))
}
