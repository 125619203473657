import React, { FC } from 'react'
import { Box, Text } from '../ui'
import OutsideClickHandler from 'react-outside-click-handler'

// Icons
import { ReactComponent as CloseIcon } from 'images/close-drawer.svg'
import { ReactComponent as MenuIcon } from 'images/dotsMenu.svg'

// Utils
import {
  EditContainerWrapper,
  EditContainer,
  MainContainer,
  CircleProgressBarStyled,
  SpeedBox,
  SpeedBoxScore,
  SpeedBoxGroove,
  SpeedBoxDown,
  DrawerContent,
  Dot,
  HeaderContainer,
  CloseText,
  ProgressBar,
  CircleBar,
  Props,
  ageData,
  hotelNeedsData,
  rsvpStatusData, SpeedometerBar,
} from './utils'

const RsvpTracking: FC<Props> = ({ isOpen, onClose, tableData }) => {
  const age_range_data = ageData(tableData).sort(function(a, b) {
    return b.ageRangeCount - a.ageRangeCount
  })
  const hotel_needs_data = hotelNeedsData(tableData)
  const data = rsvpStatusData(tableData).sort(function(a, b) {
    return b.guestCount - a.guestCount
  })

  const onCloseModal = () => {
    onClose()
  }

  return (
    <>
      {isOpen ? (
        <EditContainerWrapper onClick={() => onCloseModal()}>
          <OutsideClickHandler onOutsideClick={() => onCloseModal()}>
            <EditContainer onClick={(e) => e.stopPropagation()}>
              <MainContainer>
                <HeaderContainer>
                  <Box cursor='pointer' width='100%'>
                    <Box marginBottom={24} display='flex' alignItems='center' onClick={() => onCloseModal()}
                         paddingBottom='1px' paddingTop='1px'>
                      <CloseIcon stroke='#5458F7' fill='#5458F7' height='16px' width='16px' />
                      <CloseText> Close </CloseText>
                    </Box>
                    <Box>
                      <Box marginBottom={24}>
                        <Text
                          fontFamily={'Source Sans Pro'}
                          fontWeight={700}
                          fontSize={32}
                          lineHeight={'38px'}
                          color={'#353B60'}
                        >
                          Responses
                        </Text>
                        <Box marginTop='4px'>
                          <Text fontFamily={'Source Sans Pro'} fontWeight={400} fontSize={16} lineHeight={'24px'}
                                color={'#667085'}>
                            Your summary of all invited wedding guests.
                          </Text>
                        </Box>
                      </Box>
                      <DrawerContent>
                        <Box backgroundColor='#FFFFFF' border='1px solid #EAECF0' borderRadius='8px'>
                          <Box padding='16px' display='flex' justifyContent='space-between' alignItems='center'
                               borderBottom='1px solid #EAECF0'>
                            <Box>
                              <Text fontFamily={'Source Sans Pro'} fontWeight={600} fontSize={20} lineHeight={'28px'}
                                    color={'#414042'}>RSVP Count</Text>
                            </Box>
                            <Box>
                              <MenuIcon />
                            </Box>
                          </Box>
                          {data.map((item: any) => {
                            return (
                              <Box padding='16px' key={item.status}>
                                <Box display='flex'>
                                  <Box minWidth='40px' height='40px' backgroundColor={item.background}
                                       borderRadius='50%'
                                       display='flex' justifyContent='center' alignItems='center' marginRight='18px'>
                                    {item.icon}
                                  </Box>
                                  <Box width='100%'>
                                    <Box display='flex' justifyContent='space-between'>
                                      <Box>
                                        <Text fontFamily={'Source Sans Pro'} fontWeight={400} fontSize={16}
                                              lineHeight={'20px'}
                                              color={'#353B60'}>
                                          {item.status}
                                        </Text>
                                      </Box>
                                      <Box>
                                        <Text fontFamily={'Source Sans Pro'} fontWeight={400} fontSize={16}
                                              lineHeight={'20px'}
                                              color={'#353B60'}>
                                          {item.guestCount} {item.guestCount > 1 ? 'Guests' : 'Guest'}
                                        </Text>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Box position='relative' height='8px' width='100%' bg='#E8E8ED' mt='8px'
                                           borderRadius={4}>
                                        <ProgressBar>
                                          <Box
                                            position='absolute'
                                            left='0px'
                                            top='0px'
                                            height='8px'
                                            bg={item.color}
                                            width={`${item.guestCount * 100 / tableData.length}%`}
                                            borderRadius={4}
                                            style={{
                                              animation: 'progressBar 1s ease-in-out',
                                              animationFillMode: 'both',
                                            }}
                                          />
                                        </ProgressBar>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            )
                          })}
                        </Box>
                        <Box marginTop={16} backgroundColor='#FFFFFF' border='1px solid #EAECF0' borderRadius='8px'>
                          <Box padding='16px' display='flex' justifyContent='space-between'
                               borderBottom='1px solid #EAECF0' alignItems='center'>
                            <Box>
                              <Text fontFamily={'Source Sans Pro'} fontWeight={600} fontSize={20} lineHeight={'28px'}
                                    color={'#414042'}>Age Range</Text>
                            </Box>
                            <Box>
                              <MenuIcon />
                            </Box>
                          </Box>
                          <Box position='relative' padding={16}>
                            <Box display='flex' justifyContent='space-between'>
                              <CircleBar>
                                <CircleProgressBarStyled style={{
                                  background: `conic-gradient(${age_range_data[0].color} 0, ${age_range_data[0].color} ${age_range_data[0].ageRangePercentage}%,
                                  ${age_range_data[1].color} ${age_range_data[0].ageRangePercentage}%, ${age_range_data[1].color} ${age_range_data[1].ageRangePercentage + age_range_data[0].ageRangePercentage}%,
                                  ${age_range_data[2].color} ${age_range_data[1].ageRangePercentage}%, ${age_range_data[2].color} ${age_range_data[2].ageRangePercentage + age_range_data[1].ageRangePercentage + age_range_data[0].ageRangePercentage}%,
                                  ${age_range_data[3].color} ${age_range_data[2].ageRangePercentage}%, ${age_range_data[3].color} ${age_range_data[3].ageRangePercentage}%)`,
                                  animation: 'circleBar 1s ease-in-out',
                                  animationFillMode: 'both',
                                }} />
                              </CircleBar>
                              <Box>
                                {age_range_data.map((range: any) => {
                                  return (
                                    <Box display='flex' alignItems='center' justifyContent='space-between'
                                         marginBottom={8} key={range.rangeName}>
                                      <Box marginLeft='auto' marginRight='8px'>
                                        <Text
                                          fontFamily={'Source Sans Pro'}
                                          fontWeight={400}
                                          fontSize={16}
                                          lineHeight={'20px'}
                                          color={'#414042'}
                                          textAlign='right'
                                          style={{ width: '100%' }}
                                        >
                                          {range.ageRangeCount} {range.rangeName}
                                        </Text>
                                      </Box>
                                      <Box>
                                        <Dot style={{ backgroundColor: range.color }} />
                                      </Box>
                                    </Box>
                                  )
                                })}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box marginTop={16} backgroundColor='#FFFFFF' border='1px solid #EAECF0' borderRadius='8px'>
                          <Box padding='16px' display='flex' justifyContent='space-between' alignItems='center'
                               borderBottom='1px solid #EAECF0'>
                            <Box>
                              <Text fontFamily={'Source Sans Pro'} fontWeight={600} fontSize={20} lineHeight={'28px'}
                                    color={'#414042'}>Hotel Needs</Text>
                            </Box>
                            <Box>
                              <MenuIcon />
                            </Box>
                          </Box>
                          <Box padding='16px 16px 0px 16px'>
                            <Box display='flex' justifyContent='space-between'>
                              <Box height='130px'>
                                <SpeedBox>
                                  <SpeedometerBar>
                                    <SpeedBoxScore style={{
                                      backgroundImage: `radial-gradient(#fff 0, #fff 54%, transparent 55%),
                                      conic-gradient(#63C7C9 0, #63C7C9 ${hotel_needs_data[0].hotelNeedsPercentage * 1.8}deg, #E8E8ED 0deg, #E8E8ED 180deg, #fff 180deg, #fff 360deg)`,
                                      animation: 'speedometerBar 3s ease-in-out',
                                      animationFillMode: 'both',
                                    }} />
                                  </SpeedometerBar>
                                  <SpeedBoxGroove />
                                  <SpeedBoxDown>
                                    {hotel_needs_data[0].isNeedCount}
                                  </SpeedBoxDown>
                                </SpeedBox>
                              </Box>
                              <Box>
                                {hotel_needs_data.map((isNeed: any) => {
                                  return (
                                    <Box display='flex' alignItems='center' justifyContent='space-between'
                                         marginBottom={8} key={isNeed.isNeed}>
                                      <Box marginLeft='auto' marginRight='8px'>
                                        <Text
                                          fontFamily={'Source Sans Pro'}
                                          fontWeight={400}
                                          fontSize={16}
                                          lineHeight={'20px'}
                                          color={'#414042'}
                                          textAlign='right'
                                          style={{ width: '100%' }}
                                        >
                                          {isNeed.isNeedCount} {isNeed.isNeed}
                                        </Text>
                                      </Box>
                                      <Box>
                                        <Dot style={{ backgroundColor: isNeed.color }} />
                                      </Box>
                                    </Box>
                                  )
                                })}
                              </Box>
                            </Box>
                            <Box>
                              <Text
                                fontFamily={'Source Sans Pro'}
                                fontWeight={400}
                                fontSize={16}
                                lineHeight={'20px'}
                                color={'#73737B'}
                                style={{ position: 'relative', zIndex: 999, top: '-16px' }}
                              >
                                This number represents guests who answered “Yes” to needing a hotel room but not actual
                                reservations.
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </DrawerContent>
                    </Box>
                  </Box>
                </HeaderContainer>
              </MainContainer>
            </EditContainer>
          </OutsideClickHandler>
        </EditContainerWrapper>
      ) : null}
    </>
  )
}

export default RsvpTracking