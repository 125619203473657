import { LOAD_DEFAULT_IMAGES } from '../actionTypes'

export const loadDefaultImages = (wuid: string) => ({
  type: LOAD_DEFAULT_IMAGES.name,
  api: {
    url: 'wedding/default_images',
    data: {
      wuid,
      field_list: 'image_big_icon,image_medium,photo_uid',
    },
  },
})
