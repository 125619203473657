import React, { FC, useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { format } from 'date-fns'

import { Box, Text } from '../ui'
import { isDark } from 'utils/colors'
import { ReactComponent as Phone } from 'images/iphone.svg'
import phoneBgSrc from 'images/phone-bg.jpg'
import { ReactComponent as Avatar } from 'images/bride-avatar.svg'
import { ReactComponent as MobileSignalIcon } from 'images/iphone-mobile-signal.svg'
import { ReactComponent as WifiIcon } from 'images/iphone-wifi.svg'
import { ReactComponent as BatteryIcon } from 'images/iphone-battery.svg'
import { ReactComponent as CameraIcon } from 'images/camera.svg'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const AppPreviewWrapper = styled(Box).attrs({
  position: 'relative',
  overflowY: 'hidden',
  width: '431px',
  mx: 'auto',
  backgroundPosition: '27px 114px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '375px',
})`
  opacity: 0;
  animation: ${fadeIn} 1s 0.7s;
  animation-fill-mode: forwards;
`

type Props = {
  color?: { value: string; label: string }
  color2?: { value: string; label: string }
  title?: string
  font?: { value: string; label: string }
  image?: string
}

const Preview: FC<Props> = ({
  color = { value: '#7e52ec', label: '' },
  color2,
  title,
  font,
  image,
}) => {
  const colorIsDark = useMemo(() => isDark(color.value), [color])
  const time = useMemo(() => format(new Date(), 'h:mm'), [])

  return (
    <AppPreviewWrapper
      backgroundImage={`url(${image || phoneBgSrc})`}
      height={window.innerHeight > 800 ? '172px' : '152px'}
      display={window.innerHeight < 750 ? 'none' : 'block'}
      className="hh-app-preview"
    >
      <Box
        position="absolute"
        top="10px"
        right="10px"
        left="10px"
        height="105px"
        borderRadius="40px 40px 0 0"
        bg={color.value}
        zIndex={-1}
      />

      <Box position="absolute" left="60px" top="38px">
        <Text
          fontFamily="-apple-system, BlinkMacSystemFont, sans-serif"
          fontSize="15px"
          color="white"
        >
          {time}
        </Text>
      </Box>

      <Box
        position="absolute"
        top="42px"
        right="40px"
        zIndex={1}
        width="70px"
        display="flex"
        justifyContent="space-between"
      >
        <MobileSignalIcon fill={colorIsDark ? '#ffffff' : '#1e2027'} />

        <WifiIcon fill={colorIsDark ? '#ffffff' : '#1e2027'} />

        <BatteryIcon
          fill={colorIsDark ? '#ffffff' : '#1e2027'}
          stroke={colorIsDark ? '#ffffff' : '#1e2027'}
        />
      </Box>

      <Box position="absolute" left="42px" top="78px">
        <Avatar />
      </Box>

      {title && (
        <Box position="absolute" top="79px" left="70px" right="70px">
          <Text
            fontFamily={font?.label || '2'}
            fontSize="20px"
            lineHeight="24px"
            color={colorIsDark ? '#ffffff' : '#1e2027'}
            textAlign="center"
            isTruncate
          >
            {title}
          </Text>
        </Box>
      )}

      <Box position="absolute" right="42px" top="80px">
        <CameraIcon stroke={color2?.value || 'white'} />
      </Box>

      <Phone />
    </AppPreviewWrapper>
  )
}

export default Preview
