import React, { useState, useCallback, FormEvent, useMemo, useEffect } from 'react' // prettier-ignore
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Box, Text, Button } from 'components/ui'
import {
  addNavItem,
  editNavItem,
  deleteNavItem,
} from 'store/modules/nav/actions'
import { getNavItem } from 'store/modules/nav/selectors'
import { NAV_ITEM_TYPES } from 'constants/nav'
import MinimizeButton from './MinimizeButton'
import CustomTypes, { CUSTOM_TYPES } from './CustomTypes'
import Form from './Form'
import IconSelector from './IconSelector'
import AdminVisibleSwitch from './AdminVisibleSwitch'

export type Values = {
  type: number
  caption?: string
  link?: string
  access_level: number
  icon: string
  is_featured?: boolean
}

const DEFAULT_TYPE = CUSTOM_TYPES[0]

type Props = {
  navItemId?: string
}

const AppSections = ({ navItemId }: Props) => {
  const dispatch = useDispatch()
  const navItem = useSelector(getNavItem(navItemId))

  const [isUploading, setUploading] = useState(false)
  const [values, setValues] = useState<Values>({
    type: DEFAULT_TYPE.type,
    access_level: 0,
    icon: DEFAULT_TYPE.icon,
  })

  const isDisabled = useMemo(
    () =>
      isUploading || !values.caption || (values.type === 101 && !values.link),
    [values, isUploading]
  )

  const onChangeValues = useCallback(
    (data: Partial<Values>) => setValues((v) => ({ ...v, ...data })),
    []
  )

  const onSelectType = useCallback(
    (type: Values['type']) => () => {
      const typeValues = CUSTOM_TYPES.find((v) => v.type === type)

      setValues((v) => ({
        ...v,
        ...(typeValues ? typeValues : DEFAULT_TYPE),
      }))
    },
    []
  )

  const onAddNavItem = useCallback(
    async () => dispatch(addNavItem({ ...values, is_active: 1 })),
    [dispatch, values]
  )

  const onEditNavItem = useCallback(
    async (nav_item_id: string) =>
      dispatch(editNavItem({ ...values, nav_item_id, is_active: 1 })),
    [dispatch, values]
  )

  const onDeleteNavItem = useCallback(async () => {
    if (navItem) {
      dispatch(deleteNavItem(navItem.nav_item_id, navItem.nav_id))
    }
  }, [dispatch, navItem])

  const onSave = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      try {
        setUploading(true)

        if (navItemId) {
          await onEditNavItem(navItemId)
        } else {
          await onAddNavItem()
        }

        onChangeValues({ caption: '', link: '' })

        toast(`The section ${navItemId ? 'updated' : 'saved'} successfully`)
        e.currentTarget?.reset()
      } catch (error) {
        toast(error.message)
      } finally {
        setUploading(false)
      }
    },
    [navItemId, onAddNavItem, onEditNavItem, onChangeValues]
  )

  const onDelete = useCallback(async () => {
    try {
      setUploading(true)

      await onDeleteNavItem()

      toast(`The section deleted successfully`)
    } catch (error) {
      toast(error.message)
    } finally {
      setUploading(false)
    }
  }, [onDeleteNavItem])

  useEffect(() => {
    if (navItem) {
      setValues({
        type: navItem.type,
        caption: navItem.caption,
        link: navItem.link,
        access_level: navItem.access_level,
        icon: navItem.icon,
        is_featured: navItem.is_featured,
      })
    }
  }, [navItem])

  return (
    <Box
      as="form"
      bg="#FFFFFF"
      boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
      borderRadius="10px"
      width="300px"
      minWidth="300px"
      height="100%"
      position="relative"
      py="16px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      onSubmit={onSave}
    >
      <MinimizeButton />

      <Box overflowY="auto" px="16px">
        {navItem ? (
          <Box
            borderBottom="1px dashed rgba(53, 59, 96, 0.4)"
            pt="4px"
            pb="20px"
          >
            <Text
              fontWeight={600}
              fontSize="16px"
              lineHeight="20px"
              color="#A3A3AC"
            >
              Type:
            </Text>

            <Box mb="2px" />

            <Text
              fontWeight="bold"
              fontSize="20px"
              lineHeight="25px"
              color="#353B60"
            >
              {NAV_ITEM_TYPES[values.type] || values.caption}
            </Text>
          </Box>
        ) : (
          <Text
            fontWeight="bold"
            fontSize="20px"
            lineHeight="25px"
            color="#353B60"
          >
            App Sections
          </Text>
        )}

        {!navItem && (
          <CustomTypes activeType={values.type} onSelectType={onSelectType} />
        )}

        <Form
          type={values.type}
          caption={values.caption}
          link={values.link}
          onChangeValues={onChangeValues}
        />

        <IconSelector icon={values.icon} onChangeValues={onChangeValues} />

        <AdminVisibleSwitch
          isChecked={values.access_level === 1}
          isPublic={values.is_featured}
          onChangeValues={onChangeValues}
        />
      </Box>

      <Box pt="20px" px="16px">
        <Button
          type="submit"
          bg="#5458F7"
          height="48px"
          boxShadow="0px 4px 10px rgba(53, 59, 96, 0.1)"
          borderRadius="10px"
          fullWidth
          fontFamily="0"
          fontWeight={600}
          fontSize="16px"
          color="#FFFFFF"
          disabled={isDisabled}
        >
          {navItem ? 'Update' : 'Save'} Section
        </Button>

        {navItem && [100, 101, 102].includes(values.type) && (
          <Button
            border="2px solid #F44343"
            borderRadius="10px"
            fullWidth
            height="48px"
            color="#F44343"
            fontFamily="0"
            fontWeight={600}
            fontSize="16px"
            mt="16px"
            disabled={isUploading}
            onClick={onDelete}
          >
            Delete Section
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default AppSections
