import React, { FC, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useDropzone } from 'react-dropzone'
import OutsideClickHandler from 'react-outside-click-handler'
import md5 from 'md5'
import { head, last, split, path } from 'ramda'
import { toast } from 'react-toastify'

import { addPhoto } from 'store/modules/photos/actions'
import { readImage } from 'utils/images'
import { Box, Button, Text, LoadingDots } from 'components/ui'
import { ReactComponent as PlusIcon } from 'images/plus.svg'
import { ReactComponent as ChevronIcon } from 'images/chevron-down.svg'
import { ReactComponent as AddImageIcon } from 'images/albums.svg'

type Props = { onPushPhoto: (x: any) => void }

const OptionsButton: FC<Props> = ({ onPushPhoto }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { wuid } = useParams<{ wuid: string }>()
  const dispatch = useDispatch()

  const handleClickButton = useCallback(() => setMenuIsOpen(!menuIsOpen), [
    menuIsOpen,
  ])
  const handleClickOutside = useCallback(() => setMenuIsOpen(false), [])

  const onAddPhoto = useCallback(async (data) => dispatch(addPhoto(data)), [
    dispatch,
  ])
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = head(acceptedFiles)

      if (file) {
        const bodyFormData = new FormData()
        bodyFormData.set('wuid', wuid || '')
        bodyFormData.set('image_type', last(split('/', file.type)) || '')
        bodyFormData.set('hash', md5(await readImage(file)))
        bodyFormData.append('image', file)

        try {
          setIsLoading(true)

          const res = await onAddPhoto(bodyFormData)

          onPushPhoto(path(['value', 'payload', 'photo'], res))
        } catch (error) {
          toast(error.message)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [onAddPhoto, wuid, onPushPhoto, setIsLoading]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
    disabled: isLoading,
  })

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Box position="relative">
        <Button
          border="1px solid"
          borderColor="gray"
          borderRadius={0}
          p="6px"
          pl={0}
          display="flex"
          alignItems="center"
          onClick={handleClickButton}
        >
          <PlusIcon fill="#7c8494" />

          <Box ml="6px" display="flex" justifyContent="center">
            <ChevronIcon fill="#8991a0" width={20} height={20} />
          </Box>
        </Button>

        {menuIsOpen && (
          <Box
            position="absolute"
            display="flex"
            flexDirection="column"
            width="196px"
            bg="white"
            border="1px solid"
            borderColor="gray"
            borderRadius={0}
            boxShadow={0}
            top="46px"
            py={1}
            zIndex={2}
          >
            <Box
              px={2}
              cursor="pointer"
              display="inline-flex"
              alignItems="center"
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              <Box mr={1}>
                <AddImageIcon stroke="#8991a0" />
              </Box>

              <Text fontFamily="1">Add Image</Text>

              {isLoading && (
                <Box ml={0}>
                  <LoadingDots bg="primary" />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </OutsideClickHandler>
  )
}

export default OptionsButton
