import React, { FC } from 'react'
import { path } from 'ramda'

import { Post } from 'store/modules/posts/reducer'
import { Box, Text, Img } from 'components/ui'

type Props = {
  caption: Post['caption']
  media: Post['media']
}

const PostContent: FC<Props> = ({ caption = '', media = [] }) => (
  <>
    {media.length > 0 && (
      <Box
        display="flex"
        flexDirection="column"
        mb={caption.length > 0 ? 1 : '0px'}
      >
        {media.map((item) => (
          <Img
            key={item.id}
            alt={path(['title'], item)}
            src={
              path(['links', 'image_fw_480'], item) ||
              path(['links', 'image_original'], item)
            }
            width="100%"
          />
        ))}
      </Box>
    )}

    {caption.length > 0 && (
      <Box px={2}>
        <Box borderBottom="1px solid" borderColor="gray" pb={1}>
          <Text fontSize={1} lineHeight={1.5}>
            {caption}
          </Text>
        </Box>
      </Box>
    )}
  </>
)
export default PostContent
