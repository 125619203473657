import React, { FC } from 'react'
import { useLocation, Redirect } from 'react-router'
import { path } from 'ramda'

import { ROOT_PATH } from 'constants/paths'
import { Wrapper } from 'components/Auth'
import EmailSent from './EmailSent'

const Verify: FC = () => {
  const { state } = useLocation()
  const user = path<{ uuid: string; email: string }>(['user'], state)

  return (
    <Wrapper>
      {user ? <EmailSent {...user} /> : <Redirect to={ROOT_PATH} />}
    </Wrapper>
  )
}

export default Verify
