import { PHOTO_SET_FAVORITE } from '../actionTypes'
import { Photo } from '../reducer'

export const setFavorite = (
  photo_id: Photo['photo_id'],
  is_favorite: '0' | '1'
) => ({
  type: PHOTO_SET_FAVORITE.name,
  api: {
    url: 'photos/set_favorite',
    data: {
      photo_id,
      is_favorite,
      field_list: 'all_fields',
    },
  },
})
