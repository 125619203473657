import { OFFER_CODE } from '../actionTypes'

type Data = {
  first_name1: string
  last_name1: string
  first_name2: string
  last_name2: string
  year?: number
  min_length?: number
  max_length?: number
}

export const loadCodes = (data: Data) => ({
  type: OFFER_CODE.name,
  api: {
    url: 'wedding/offer_code',
    data,
  },
})
