import React, { FC, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { path } from 'ramda'

import { remove } from 'store/modules/wedding/actions'
import { getWedding } from 'store/modules/wedding/selectors'
import { Modal, Box, Button, Text } from 'components/ui'

type Props = {
  wuid?: string
  isOpen: boolean
  onClose: VoidFunction
}

const RemoveWeddingModal: FC<Props> = ({ wuid, isOpen, onClose }) => {
  const dispatch = useDispatch()
  const wedding = useSelector(getWedding(wuid))
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const onRemove = useCallback(async () => dispatch(remove(wuid)), [
    dispatch,
    wuid,
  ])

  const handleClickDelete = async () => {
    setIsLoading(true)

    try {
      const res = await onRemove()

      if (path(['value', 'status'], res) !== 'success') {
        throw new Error(path(['action', 'payload', 'error'], res))
      }

      handleClose()
    } catch (error) {
      setError(error.message)
      setIsLoading(false)
    }
  }
  const handleClose = () => {
    setError(null)
    setIsLoading(false)

    onClose()
  }

  const modalStyle = { content: { maxWidth: 360, top: '42%' } }

  if (!wedding) return null

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={handleClose}>
      <Box
        px={3}
        pt={2}
        pb={1}
        borderRadius={0}
        bg="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {error && (
          <Box mb={0}>
            <Text color="red">{error}</Text>
          </Box>
        )}

        <Box mb="6px" display="flex" width="100%">
          <Text fontFamily="1" fontSize={3} fontWeight={600}>
            Delete wedding
          </Text>
        </Box>

        <Text fontFamily="1">
          Are you sure you want to delete the wedding, “
          {path(['wedding_name'], wedding)}”? You can't undo this action.
        </Text>

        <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
          <Box mr={0}>
            <Button
              px={2}
              py={0}
              border="none"
              color="blue"
              fontFamily="1"
              fontSize="18px"
              fontWeight={600}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>

          <Button
            px={2}
            py={0}
            border="none"
            color="red"
            fontFamily="1"
            fontSize="18px"
            disabled={isLoading}
            onClick={handleClickDelete}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default RemoveWeddingModal
