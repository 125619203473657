import { path } from 'ramda'
import { format, isDate, parseISO } from 'date-fns'

import { ADD } from '../actionTypes'

export const add = (values: any) => {
  const weddingDate =
    typeof values.weddingDate === 'string'
      ? parseISO(values.weddingDate)
      : values.weddingDate
  const rsvpDeadline =
    typeof values.rsvpDeadline === 'string'
      ? parseISO(values.rsvpDeadline)
      : values.rsvpDeadline

  return {
    type: ADD.name,
    api: {
      url: 'wedding/add',
      method: 'post',
      data: {
        name: path(['weddingName'], values),
        n_guests: path(['nGuests', 'value'], values),
        photo_uid: path(['temporaryPhotoId'], values),
        // date
        ...(!path(['isNotSureAboutDate'], values)
          ? {
              wedding_date: isDate(weddingDate)
                ? format(weddingDate, 'yyyy-MM-dd')
                : '',
            }
          : {
              approx_date_year: path(['year', 'value'], values),
              approx_date_month: path(['season', 'value'], values),
            }),
        rsvp_deadline: isDate(rsvpDeadline)
          ? format(rsvpDeadline, 'yyyy-MM-dd')
          : undefined,
        // address
        ...(!path(['isNotSureAboutValue'], values) &&
        path(['address', 'place'], values)
          ? {
              location_name: path(['address', 'place'], values),
              location_address: path(['address', 'address', 'address'], values),
              location_city: path(['address', 'address', 'city'], values),
              location_state: path(['address', 'address', 'state'], values),
              location_zip: path(['address', 'address', 'zip'], values),
              location_lat: path(['address', 'location', 'lat'], values),
              location_lon: path(['address', 'location', 'lng'], values),
            }
          : undefined),
        // app
        theme_color: path(['theme_color', 'value'], values),
        theme_color2: path(['theme_color', 'value'], values),
        theme_font: path(['font', 'label'], values),
        theme_font_url: path(['font', 'value'], values),
        // code
        common_code: path(['common_code'], values),
        invite_type: path(['invite_type'], values),
        // nav_preset
        nav_preset: path(['nav_preset'], values),
      },
    },
  }
}
