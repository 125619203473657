import { ReactComponent as QuestionsIcon } from './navigation-questions.svg'
import { ReactComponent as AirplaineIcon } from './navigation-airplane.svg'
import { ReactComponent as ArchIcon } from './navigation-arch.svg'
import { ReactComponent as ArcheryIcon } from './navigation-archery.svg'
import { ReactComponent as CakeIcon } from './navigation-cake.svg'
import { ReactComponent as CalendarIcon } from './navigation-calendar.svg'
import { ReactComponent as CameraIcon } from './navigation-camera.svg'
import { ReactComponent as ChampaignIcon } from './navigation-champaign.svg'
import { ReactComponent as DressIcon } from './navigation-dress.svg'
import { ReactComponent as EnvelopeIcon } from './navigation-envelope.svg'
import { ReactComponent as FolderIcon } from './navigation-folder.svg'
import { ReactComponent as FoodIcon } from './navigation-food.svg'
import { ReactComponent as Food2Icon } from './navigation-food2.svg'
import { ReactComponent as GlassesIcon } from './navigation-glasses.svg'
import { ReactComponent as GuestsIcon } from './navigation-guests.svg'
import { ReactComponent as HeartIcon } from './navigation-heart.svg'
import { ReactComponent as HeartsIcon } from './navigation-hearts.svg'
import { ReactComponent as InfoIcon } from './navigation-info.svg'
import { ReactComponent as InternetIcon } from './navigation-internet.svg'
import { ReactComponent as InvitationIcon } from './navigation-invitation.svg'
import { ReactComponent as Invitation2Icon } from './navigation-invitation2.svg'
import { ReactComponent as Invitation3Icon } from './navigation-invitation3.svg'
import { ReactComponent as LeaderboardIcon } from './navigation-leaderboard.svg'
import { ReactComponent as ListIcon } from './navigation-list.svg'
import { ReactComponent as MenuIcon } from './navigation-menu.svg'
import { ReactComponent as MessagesIcon } from './navigation-messages.svg'
import { ReactComponent as Messages2Icon } from './navigation-messages2.svg'
import { ReactComponent as MomentsIcon } from './navigation-moments.svg'
import { ReactComponent as NotificationsIcon } from './navigation-notifications.svg'
import { ReactComponent as RegistryIcon } from './navigation-registry.svg'
import { ReactComponent as Registry2Icon } from './navigation-registry2.svg'
import { ReactComponent as RingIcon } from './navigation-ring.svg'
import { ReactComponent as RingsIcon } from './navigation-rings.svg'
import { ReactComponent as RoseIcon } from './navigation-rose.svg'
import { ReactComponent as SearchIcon } from './navigation-search.svg'
import { ReactComponent as SeatingIcon } from './navigation-seating.svg'
import { ReactComponent as SuitcaseIcon } from './navigation-suitcase.svg'

const icons = [
  { name: 'navigation-questions', icon: QuestionsIcon },
  { name: 'navigation-airplane', icon: AirplaineIcon },
  { name: 'navigation-arch', icon: ArchIcon },
  { name: 'navigation-archery', icon: ArcheryIcon },
  { name: 'navigation-cake', icon: CakeIcon },
  { name: 'navigation-calendar', icon: CalendarIcon },
  { name: 'navigation-camera', icon: CameraIcon },
  { name: 'navigation-champaign', icon: ChampaignIcon },
  { name: 'navigation-dress', icon: DressIcon },
  { name: 'navigation-envelope', icon: EnvelopeIcon },
  { name: 'navigation-folder', icon: FolderIcon },
  { name: 'navigation-food', icon: FoodIcon },
  { name: 'navigation-food2', icon: Food2Icon },
  { name: 'navigation-glasses', icon: GlassesIcon },
  { name: 'navigation-guests', icon: GuestsIcon },
  { name: 'navigation-heart', icon: HeartIcon },
  { name: 'navigation-hearts', icon: HeartsIcon },
  { name: 'navigation-info', icon: InfoIcon },
  { name: 'navigation-internet', icon: InternetIcon },
  { name: 'navigation-invitation', icon: InvitationIcon },
  { name: 'navigation-invitation2', icon: Invitation2Icon },
  { name: 'navigation-invitation3', icon: Invitation3Icon },
  { name: 'navigation-leaderboard', icon: LeaderboardIcon },
  { name: 'navigation-list', icon: ListIcon },
  { name: 'navigation-menu', icon: MenuIcon },
  { name: 'navigation-messages', icon: MessagesIcon },
  { name: 'navigation-messages2', icon: Messages2Icon },
  { name: 'navigation-moments', icon: MomentsIcon },
  { name: 'navigation-notifications', icon: NotificationsIcon },
  { name: 'navigation-registry', icon: RegistryIcon },
  { name: 'navigation-registry2', icon: Registry2Icon },
  { name: 'navigation-ring', icon: RingIcon },
  { name: 'navigation-rings', icon: RingsIcon },
  { name: 'navigation-rose', icon: RoseIcon },
  { name: 'navigation-search', icon: SearchIcon },
  { name: 'navigation-seating', icon: SeatingIcon },
  { name: 'navigation-suitcase', icon: SuitcaseIcon },
]

export default icons
