import React, { useCallback } from 'react'

import { SChart } from 'store/modules/seatings/reducer'
import { Box, Modal, Text, Button } from 'components/ui'
import { ReactComponent as CloseIcon } from './close.svg'
import Form from './ChartForm'

const modalStyles = {
  content: { maxWidth: 440, top: 249 },
}

type Props = {
  isOpen: boolean
  isActiveChart: boolean
  chartId?: string
  onCloseModal: VoidFunction
  onChangeActiveChart: (data: Partial<SChart>) => void
}

const ChartModal = ({
  isOpen,
  isActiveChart,
  chartId,
  onCloseModal,
  onChangeActiveChart,
}: Props) => {
  const onSuccess = useCallback(
    (values: { caption?: string }) => {
      if (isActiveChart) {
        onChangeActiveChart(values)
      }

      onCloseModal()
    },
    [isActiveChart, onCloseModal, onChangeActiveChart]
  )

  return (
    <Modal isOpen={isOpen} style={modalStyles} onRequestClose={onCloseModal}>
      <Box
        borderRadius="10px"
        bg="#F7F7FC"
        boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
      >
        <Box
          height="60px"
          width="100%"
          borderRadius="10px"
          bg="white"
          px="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="0px 4px 15px rgba(53, 59, 96, 0.05)"
        >
          <Text fontWeight="bold" fontSize="20px" color="#353B60">
            {chartId ? 'Edit' : 'New'} Chart
          </Text>

          <Button height="24px" width="24px" p="0px" onClick={onCloseModal}>
            <CloseIcon />
          </Button>
        </Box>

        <Form chartId={chartId} onSuccess={onSuccess} onClose={onCloseModal} />
      </Box>
    </Modal>
  )
}

export default ChartModal
