import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { Box, Loading } from 'components/ui'
import { getWedding } from 'store/modules/wedding/selectors'
import { getImage } from 'utils/guest'
import bgSrc from './background.png'
import Header from './Header'
import Countdown from './Countdown'
import Menu from './Menu'
import FontSelector from './FontSelector'
import ColorSelector from './ColorSelector'

const Wrapper = styled(Box)`
  width: 315px;
  height: 563px;
  padding-left: 65px;
  padding-top: 26px;

  @media screen and (min-height: 840px) {
    width: 394px;
    height: 704px;
    padding-left: 82px;
    padding-top: 36px;
  }
`

const WeddingImage = styled(Box)`
  height: 120px;
  width: 218px;

  @media screen and (min-height: 840px) {
    height: 160px;
    width: 270px;
  }
`

const Settings = styled(Box)`
  width: 210px;
  margin-left: 35px;

  @media screen and (min-height: 840px) {
    width: 263px;
    margin-left: 70px;
  }
`

const AppPreview = () => {
  const { wuid } = useParams<{ wuid: string }>()

  const wedding = useSelector(getWedding(wuid))

  const weddingImage = useMemo(
    () => getImage(wedding?.wedding_image, 'medium'),
    [wedding]
  )

  if (!wedding) {
    return <Loading />
  }

  return (
    <>
      <Wrapper position="relative">
        <Box
          backgroundImage={`url(${bgSrc})`}
          backgroundSize="cover"
          position="absolute"
          top="0px"
          left="0px"
          right="0px"
          bottom="0px"
          zIndex={1}
        />

        <Header
          title={wedding.wedding_name}
          font={wedding.theme_font || undefined}
          bg={wedding.theme_color || undefined}
        />

        {weddingImage && (
          <WeddingImage
            backgroundImage={`url(${weddingImage})`}
            backgroundSize="cover"
          />
        )}

        <Countdown
          date={wedding.wedding_date}
          color={wedding.theme_color || undefined}
        />

        <Menu bg={wedding.theme_color2 || wedding.theme_color || undefined} />
      </Wrapper>

      <Settings pt="25px">
        <FontSelector
          title={wedding.wedding_name}
          font={wedding.theme_font || undefined}
        />

        <ColorSelector type="primary" color={wedding.theme_color} />

        <ColorSelector type="secondary" color={wedding.theme_color2} />
      </Settings>
    </>
  )
}

export default AppPreview
