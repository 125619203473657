import React, { FC, useState, useCallback, useEffect } from 'react'

import { Box, Text, Button, Modal } from 'components/ui'
import { Radio } from 'components/WeddingWizard'
import { Input } from 'components/form'
import { ReactComponent as Icon } from 'images/ww-step2.svg'

type Props = {
  spouse1FirstName: string
  inviteYourSpouse: boolean
  yourSpouseEmail?: string
  isValid: boolean
  onChange: (name: string, value: any) => void
  onBack: VoidFunction
  onContinue: VoidFunction
}

const Step2: FC<Props> = ({
  spouse1FirstName,
  inviteYourSpouse,
  yourSpouseEmail,
  isValid,
  onChange,
  onBack,
  onContinue,
}) => {
  const [isModalOpen, setModalState] = useState(false)

  const onRequestClose = () => setModalState(false)

  const modalStyle = {
    content: { maxWidth: 660, top: 255, overflow: 'none' },
  }

  const handleInviteCancel = useCallback(() => {
    onChange('yourSpouseEmail', '')
    onChange('inviteYourSpouse', false)

    return setModalState(false)
  }, [onChange])

  useEffect(() => {
    if (!inviteYourSpouse) {
      handleInviteCancel()
    }

    if (inviteYourSpouse && !yourSpouseEmail) {
      setModalState(true)
    }
  }, [inviteYourSpouse, yourSpouseEmail, handleInviteCancel])

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        style={modalStyle}
        onRequestClose={onRequestClose}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          py="28px"
          px={[0, '20px']}
          borderRadius="6px"
          bg="white"
        >
          <Text
            color="secondary"
            fontFamily="1"
            fontWeight={600}
            fontSize="22px"
            lineHeight="26px"
            textAlign="center"
          >
            Invite your special someone
          </Text>

          <Box mb="20px" />

          <Text
            color="secondary"
            fontSize="20px"
            lineHeight="28px"
            textAlign="center"
          >
            By inviting your fiancé or wedding coordinator to HitchHero, you’ll
            be able to collaborate and share wedding details instantly.
          </Text>

          <Box mt="20px" maxWidth="400px" width="100%">
            <Input
              name="yourSpouseEmail"
              placeholder="Email Address"
              height="40px"
            />
          </Box>

          <Box
            mt="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="primary"
              height="40px"
              disabled={!isValid}
              onClick={onRequestClose}
            >
              Invite
            </Button>

            <Box mr="16px" />

            <Button
              fontSize="18px"
              color="primary"
              onClick={handleInviteCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box
        width="100%"
        maxWidth="740px"
        boxShadow="0px 8px 24px rgba(0, 0, 0, 0.15)"
        bg="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt="73px"
        pb="26px"
        px={1}
      >
        <Box mt="-108px" mb="20px">
          <Icon />
        </Box>

        <Text
          fontSize={['28px', 5]}
          fontWeight="bold"
          color="secondary"
          lineHeight={['normal', '45px']}
          textAlign="center"
        >
          Lovely to meet you, {spouse1FirstName || '...'}! 
        </Text>

        <Box mt="8px" />

        <Text
          fontSize="20px"
          fontWeight={300}
          color="secondary"
          lineHeight={['normal', '25px']}
          textAlign="center"
        >
          Now, introduce us to your lucky spouse-to-be.
        </Text>

        <Box width="100%" maxWidth="402px" mt="40px">
          <Text
            fontWeight="600"
            fontSize="20px"
            lineHeight="25px"
            color="secondary"
          >
            Their name:
          </Text>

          <Box
            mt="8px"
            width="100%"
            display="flex"
            flexDirection={['column', 'row']}
            justifyContent="spaspace-between"
          >
            <Box pr={['unset', '10px']} pb={['16px', 'unset']} width="100%">
              <Input
                name="yourSpouseFirstName"
                placeholder="First name"
                height="40px"
              />
            </Box>

            <Box pl={['unset', '10px']} width="100%">
              <Input
                name="yourSpouseLastName"
                placeholder="Last name"
                height="40px"
              />
            </Box>
          </Box>

          <Box mt="20px" />

          <Text
            fontWeight="600"
            fontSize="20px"
            lineHeight="25px"
            color="secondary"
          >
            Their role:
          </Text>

          <Box
            mt="8px"
            mb="16px"
            width="100%"
            display="flex"
            flexDirection={['column', 'row']}
            justifyContent="spaspace-between"
          >
            <Box pr={['unset', '10px']} pb={['16px', 'unset']} width="100%">
              <Radio
                name="yourSpouseRole"
                value="bride"
                label="Bride"
                height="40px"
              />
            </Box>

            <Box pl={['unset', '10px']} width="100%">
              <Radio
                name="yourSpouseRole"
                value="groom"
                label="Groom"
                height="40px"
              />
            </Box>
          </Box>

          {/* <Switch
            name="inviteYourSpouse"
            label="Send an invite to your fiancé"
            disabled={!isValid}
          /> */}

          <Box
            width="100%"
            mt="69px"
            display="flex"
            justifyContent="space-between"
          >
            <Button variant="secondary" height="40px" onClick={onBack}>
              Back
            </Button>

            <Button
              variant="primary"
              disabled={!isValid}
              height="40px"
              onClick={onContinue}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Step2
