import React, { FC } from 'react'

import { Box, Text, Button } from 'components/ui'

type Props = { onEditClick: () => void }

const WeddingDetailsHeader: FC<Props> = ({ onEditClick }) => (
  <Box
    px="17px"
    py="11px"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
  >
    <Text fontFamily="1" fontWeight={600} fontSize={2}>
      Your Wedding Details
    </Text>

    <Button variant="secondary" onClick={onEditClick}>
      Edit
    </Button>
  </Box>
)

export default WeddingDetailsHeader
