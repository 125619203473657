import React, { FC, memo, useCallback } from 'react'
import DataTable from 'react-data-table-component'
import { equals } from 'ramda'

import {
  BootyCheckbox,
  RowProps,
  getConditionalRowStyles,
  customStyles,
} from './utils/rowComponent'

const DataTableRowComponent: FC<RowProps> = ({ tableProps }) => {
  const {
    data,
    columnsWithFunctions,
    toggleClearRows,
    guestId,
    selectedExpandableTableData,
    setSelectedExpandableTableData,
    guest,
    onRowClicked,
  } = tableProps

  const rowSelectCriteria = useCallback((row: any) => {
    return !!selectedExpandableTableData.find((selectedSubGuest: any) => selectedSubGuest.guest_id === row.guest_id)
  }, [selectedExpandableTableData])

  const handleRowChange = ({ selectedRows }: any) => {
    const isEqual = equals(selectedExpandableTableData, selectedRows)
    if (!isEqual) {
      setSelectedExpandableTableData({
        selectedRows,
        guestId,
        guest,
      })
    }
  }

  return (
    <DataTable
      noTableHead
      data={data}
      responsive
      expandableRowsHideExpander
      selectableRows
      columns={columnsWithFunctions()}
      onSelectedRowsChange={handleRowChange}
      clearSelectedRows={toggleClearRows}
      selectableRowsComponent={BootyCheckbox}
      conditionalRowStyles={getConditionalRowStyles({ rowSelectCriteria })}
      customStyles={customStyles}
      progressPending={!selectedExpandableTableData}
      progressComponent={
        <div className='d-flex justify-content-center my-1'>loading...</div>
      }
      selectableRowSelected={rowSelectCriteria}
      onRowClicked={onRowClicked}
    />
  )
}

export default memo(DataTableRowComponent)