import { pick } from 'ramda'

import { SIGNUP } from '../actionTypes'

export type SignupData = {
  first_name: string
  last_name: string
  email: string
  phone?: string
  password: string
}

export const signup = (data: SignupData) => ({
  type: SIGNUP.name,
  api: {
    url: 'user/create',
    data: pick(['first_name', 'last_name', 'email', 'phone', 'password'], data),
  },
})
