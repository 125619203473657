import React, { FC } from 'react'

import { Text, Box, Button } from 'components/ui'
import { ReactComponent as Icon } from 'images/emailBox.svg'

type Props = {
  isLoading: boolean
  email: string
  onClickResend: () => void
  returnToLogin: () => void
}

const EmailSent: FC<Props> = ({ isLoading, email, onClickResend, returnToLogin }) => (
  <>
    <Icon />

    <Box mt="18px" mb="24px">
      <Text fontFamily="Lora" fontWeight="600" fontSize="32px" lineHeight="40px" color="#353B60">
        Check your email
      </Text>
    </Box>

    <Box
      mb="24px"
      width="100%"
      maxWidth="475px"
      display="flex"
      justifyContent="center"
    >
      <Text fontFamily="Lato" fontWeight="400" fontSize="16px" lineHeight="24px" textAlign="center" color="#73737B">
        A password reset link was sent to the email: {' '}
        <Text fontFamily="Lato" fontWeight="400" fontSize="16px" lineHeight="24px" color="#5458F7">
          {email}
        </Text>
      </Text>
    </Box>

    <Box mb="24px" display="flex" flexDirection="column" width="352px" alignItems="center">
      <Text fontFamily="Lato" fontWeight="400" fontSize="16px" lineHeight="24px" textAlign="center" color="#73737B">
        Open the email to reset your password. If you didn’t receive an email press the “Resend email” button below.
      </Text>
    </Box>

    <Button
      variant="primary"
      style={{
        background: "linear-gradient(180deg, #A9A2FF 0%, #8379F2 90.62%)",
        borderRadius: "12px",
        width: "352px",
        height: "48px"
      }}
      disabled={isLoading}
      onClick={returnToLogin}
    >
      Return to login
    </Button>

    <Box mt="28px" display="inline-flex" alignItems="baseline">
      <Box mr="0.5rem">
        <Text fontFamily="Lato" fontWeight="400" fontSize="16px" lineHeight="24px" color="#73737B">
          Didn’t get an email?
        </Text>
      </Box>

      <Button
        color="primary"
        fontFamily="Lato"
        fontSize="16px"
        fontWeight="700"
        height="24px"
        disabled={isLoading}
        onClick={onClickResend}
      >
        Resend Email
      </Button>
    </Box>
  </>
)

export default EmailSent
