import { path, pathOr, slice } from 'ramda'
import { format, setMinutes, addHours, parseISO } from 'date-fns'

import { AgendaItem } from 'store/modules/agenda/reducer'

const DATE_FORMAT = 'yyyy-LL-dd'

export const transformNewAgendaToRequest = (
  wuid: string = '',
  values: any,
) => ({
  wuid,
  agenda_item_id: path(['agenda_item_id'], values),
  title: path(['title'], values),
  start_date: format(pathOr(new Date(), ['start_date'], values), DATE_FORMAT),
  start_time: path(['start_time'], values),
  end_date: format(pathOr(new Date(), ['end_date'], values), DATE_FORMAT),
  end_time: path(['end_time'], values),
  attire: path(['attire'], values) || '',
  location_name: path(['location_name'], values) || null,
  location_address: path(['location_address'], values) || null,
  location_city: path(['location_city'], values) || null,
  location_state: path(['location_state'], values) || null,
  location_zip: path(['location_zip'], values) || null,
  location_lat: path(['location_lat'], values) || null,
  location_lon: path(['location_lon'], values) || null,
  phone_number: path(['phone_number'], values) || '',
  email: path(['email'], values) || '',
  notes: path(['notes'], values) || '',
  url: path(['url'], values) || '',
  timezone: path(['timezone'], values) || '',
  access_mode: path(['access_mode'], values) || '',
})

export const transformAgendaToForm = (agenda: AgendaItem) => ({
  wuid: agenda.wuid,
  agenda_item_id: agenda.agenda_item_id,
  name: agenda.title,
  dress: agenda.attire,
  url: agenda.url && agenda.url.length > 0 ? agenda.url : undefined,
  ...(agenda.location_name
    ? {
      location: {
        place: agenda.location_name,
        address: {
          address: agenda.location_address,
          city: agenda.location_city,
          state: agenda.location_state,
          zip: agenda.location_zip,
        },
        ...(agenda.location_lat && agenda.location_lon
          ? {
            location: {
              lat: parseFloat(agenda.location_lat),
              lng: parseFloat(agenda.location_lon),
            },
          }
          : undefined),
      },
    }
    : undefined),
  start_time: agenda.start_time
    ? slice(0, 5, agenda.start_time)
    : format(addHours(setMinutes(new Date(), 0), 1), 'HH:mm'),
  start_date: agenda.start_date ? parseISO(agenda.start_date) : new Date(),
  end_time: agenda.end_time
    ? slice(0, 5, agenda.end_time)
    : format(addHours(setMinutes(new Date(), 0), 2), 'HH:mm'),
  end_date: agenda.end_date ? parseISO(agenda.end_date) : new Date(),
  notes: agenda.notes,
})
