import React, { FC, useRef, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { map, head, path, pathOr, equals, last, not } from 'ramda'
import { toShort } from 'emoji-toolkit'

import { Box, Text, LoadingDots } from 'components/ui'
import {
  getSelectedRoom,
  getChatMessages,
} from 'store/modules/messages/selectors'
import { getMyId } from 'store/modules/auth/selectors'
import Info from './Info'
import Message from './Message'
import ChatForm from './Form'

const usePrevious = (value: any): any => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const Chat: FC<{ socket: WebSocket }> = ({ socket }) => {
  const messages = useSelector(getChatMessages)
  const prevMessages = usePrevious(messages)
  const room = useSelector(getSelectedRoom)
  const messagesEndRef = useRef<any>(null)
  const messagesRef = useRef<any>()
  const myId = useSelector(getMyId)
  const isTyping =
    path(['typingUser'], room) &&
    not(equals(pathOr('', ['typingUser', 'uid'], room), myId))

  const handleScrollToBottom = () =>
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  const handleSubmit = (values: { message: string }) =>
    socket.send(
      JSON.stringify({
        c: 'z',
        msg: toShort(values.message),
        room_id: room ? room.rid : undefined,
      })
    )
  const handleLoadMore = useCallback(
    () =>
      socket.send(
        JSON.stringify({
          c: 's',
          room_id: room ? room.rid : undefined,
          n_ord: path(['n_ord'], head(messages)),
        })
      ),
    [socket, room, messages]
  )
  const handleSetLastReadMessage = useCallback(
    (gid) =>
      socket.send(
        JSON.stringify({
          c: 'y',
          room_id: room ? room.rid : undefined,
          gid,
        })
      ),
    [socket, room]
  )
  const handleSetIsTyping = useCallback(
    (isTyping) =>
      socket.send(
        JSON.stringify({
          c: 't',
          room_id: room ? room.rid : undefined,
          is_typing: isTyping ? 1 : 0,
        })
      ),
    [socket, room]
  )
  const handleScroll = useCallback(
    (e) => {
      if (equals(e.target.scrollTop, 0)) handleLoadMore()
    },
    [handleLoadMore]
  )

  useEffect(() => {
    const chatContainer = document.getElementById('chat-container')
    if (
      chatContainer &&
      chatContainer.scrollHeight -
        chatContainer.clientHeight -
        chatContainer.scrollTop <
        150
    ) {
      handleScrollToBottom()
    }

    if (!prevMessages || prevMessages.length < messages.length) {
      handleSetLastReadMessage(path(['gid'], last(messages)))

      if (
        chatContainer &&
        messagesRef.current.clientHeight < chatContainer.clientHeight
      )
        handleLoadMore()
    }
  }, [prevMessages, messages, handleLoadMore, handleSetLastReadMessage])

  return (
    <Box
      height="100%"
      position="relative"
      borderRight="1px solid"
      borderColor="gray"
    >
      {room && (
        <Box height="60px">
          <Info
            name={room.name}
            isOnline={room.isOnline}
            room_type={room.room_type}
            userlist={room.userlist}
          />
        </Box>
      )}

      <Box
        id="chat-container"
        height={`calc(100% - ${isTyping ? '140' : '116'}px)`}
        overflowY="auto"
        display="flex"
        flexDirection="column-reverse"
        onScroll={handleScroll}
      >
        <div ref={messagesRef}>
          {map(
            (message) => (
              <Message
                key={message.gid}
                author={message.author}
                txt={message.txt}
                insert_dt={message.insert_dt}
              />
            ),
            messages
          )}

          <div ref={messagesEndRef} />
        </div>
      </Box>

      {isTyping && (
        <Box height="24px" display="flex" alignItems="center" px="42px">
          <Box mr={2}>
            <LoadingDots size="5px" bg="darkGray" />
          </Box>

          <Text color="darkGray" fontSize="13px">
            {pathOr('User', ['typingUser', 'first_name'], room)} is typing
          </Text>
        </Box>
      )}

      <Box height="54px" as="form" autoComplete="off">
        <Form
          render={(props) => (
            <ChatForm {...props} setIsTyping={handleSetIsTyping} />
          )}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  )
}

export default Chat
