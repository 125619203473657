import React, { FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { Box, FormLabel, ColorSelector } from '../ui'

type Props = {
  name: string
  label?: string
  placeholder?: string
}

const FormColorSelector: FC<Props> = ({ name, label, placeholder }) => {
  const renderColorSelector = ({
    input,
    placeholder,
  }: FieldRenderProps<{ label: string; value: string }>) => {
    return (
      <Box>
        {label && <FormLabel>{label}</FormLabel>}

        <ColorSelector
          value={input.value}
          onSelectChange={input.onChange}
          placeholder={placeholder}
          name={name}
        />
      </Box>
    )
  }

  return (
    <Field
      id={name}
      name={name}
      placeholder={placeholder}
      render={renderColorSelector}
    />
  )
}

export default FormColorSelector
