import React, { FC } from 'react'

import { Box, Text } from 'components/ui'
import { getFullName } from 'utils/guest'

type Props = {
  firstName?: string
  lastName?: string
  caption?: string
}

const PostComment: FC<Props> = ({ firstName, lastName, caption }) => (
  <Box mb={0}>
    <Text fontSize={1} fontWeight={600}>
      {getFullName([firstName, lastName])}
    </Text>{' '}
    <Text fontSize={1} lineHeight={1.38}>
      {caption}
    </Text>
  </Box>
)

export default PostComment
