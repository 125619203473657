import { join } from 'ramda'

import { LOAD_WEDDING } from '../actionTypes'

export const loadWedding = (wuid?: string) => ({
  type: LOAD_WEDDING.name,
  api: {
    url: 'wedding/info',
    data: {
      wuid,
      field_list: join(',', [
        'wuid',
        'wedding_name',
        'wedding_image',
        'spouse1_first_name',
        'spouse1_last_name',
        'spouse1_role',
        'spouse2_first_name',
        'spouse2_last_name',
        'spouse2_role',
        'wedding_date',
        'approx_date_year',
        'approx_date_month',
        'engagement_date',
        'location_name',
        'location_address',
        'location_city',
        'n_guests',
        'rsvp_status',
        'wedding_role',
        'theme_color',
        'theme_color2',
        'theme_font',
        'admins',
        'is_active',
        'notifications',
        'is_faq_readonly',
        'is_faq_likes',
      ]),
    },
  },
})
