import React, { FC, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { slice, map, reverse, equals } from 'ramda'
import { toast } from 'react-toastify'

import { shareAlbum, loadAlbum } from 'store/modules/albums/actions'
import { Button, Box } from 'components/ui'
import { ACCESS_TYPE_VAlUES, ACCESS_TYPE_LABELS } from 'constants/albums'
import { ReactComponent as ChevronIcon } from 'images/chevron-down.svg'
import MenuItem from './MenuItem'

type Props = {
  albumId: string
  uuid: string
  value: string
}

const AccessTypeSelector: FC<Props> = ({ albumId, uuid, value }) => {
  const dispatch = useDispatch()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onInvite = useCallback(async (data) => dispatch(shareAlbum(data)), [
    dispatch,
  ])
  const onLoadAlbum = useCallback(
    async (albumId) => dispatch(loadAlbum(albumId)),
    [dispatch]
  )

  const handleClickButton = () => setMenuIsOpen(!menuIsOpen)
  const handleClickOutside = () => setMenuIsOpen(false)
  const handleSelect = (accessType: number) => async () => {
    handleClickOutside()
    setIsLoading(true)

    try {
      await onInvite({ album_id: albumId, uuid, access_type: accessType })
      await onLoadAlbum(albumId)
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const parsedValue = parseInt(value, 10)

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Box position="relative">
        <Button
          border="none"
          fontSize={0}
          color="darkGray"
          disabled={isLoading}
          px="0px"
          onClick={handleClickButton}
        >
          {ACCESS_TYPE_LABELS[parsedValue]}
          <Box ml="4px">
            <ChevronIcon fill={menuIsOpen ? '#8991a0' : '#aab1c2'} />
          </Box>
        </Button>

        {menuIsOpen && (
          <Box
            position="absolute"
            right="0px"
            bg="white"
            borderRadius="4px"
            border="1px solid"
            borderColor="gray"
            boxShadow={0}
            mt={0}
            py={0}
            width="196px"
          >
            {map(
              (accessType) => (
                <MenuItem
                  key={accessType}
                  value={accessType}
                  isSelected={equals(parsedValue, accessType)}
                  onSelect={handleSelect}
                />
              ),
              reverse(slice(1, 3, ACCESS_TYPE_VAlUES))
            )}
          </Box>
        )}
      </Box>
    </OutsideClickHandler>
  )
}

export default AccessTypeSelector
