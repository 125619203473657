import React, { useMemo } from 'react'

import { Box, Text } from 'components/ui'
import { ReactComponent as Chevron } from 'images/chevron-right.svg'

const Links = () => {
  const links = useMemo(
    () => [
      { id: 1, label: 'Watch the Tutorial', isComingSoon: true },
      { id: 2, label: 'Refer a Friend', isComingSoon: true },
      { id: 3, label: 'Read the FAQs' },
      { id: 4, label: 'Contact Us' },
    ],
    []
  )

  return (
    <>
      {links.map(({ id, label, isComingSoon }) => (
        <Box
          key={id}
          ml="20px"
          py="13px"
          pr="23px"
          borderBottom="1px solid #E8E8ED"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontSize="16px" lineHeight="16px">
            {label}
          </Text>

          {isComingSoon ? (
            <Box
              height="26px"
              borderRadius="3px"
              bg="#CBCBD3"
              px="6px"
              display="flex"
              alignItems="center"
              mr="-7px"
            >
              <Text color="white" fontWeight={600} fontSize="14px">
                Coming Soon
              </Text>
            </Box>
          ) : (
            <Chevron />
          )}
        </Box>
      ))}
    </>
  )
}

export default Links
