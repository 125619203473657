import React, { useMemo, CSSProperties } from 'react'

import { Box, Text, Avatar } from 'components/ui'

type Props = {
  isEmpty: boolean
  style?: CSSProperties
  name?: string
  image?: string
  left?: string | number
  top?: string | number
  right?: string | number
  bottom?: string | number
}

const Guest = ({ isEmpty, name = '', image, style, ...props }: Props) => {
  const wrapperStyle = useMemo<CSSProperties>(
    () => ({
      position:
        props.left || props.top || props.right || props.bottom
          ? 'absolute'
          : 'initial',
      ...props,
      ...style,
    }),
    [props, style]
  )

  return (
    <Box style={wrapperStyle}>
      {isEmpty ? (
        <Box
          bg="#FFFFFF"
          width="36px"
          height="36px"
          border="1px solid #A3A3AC"
          borderRadius="75px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontWeight={400}
            fontSize="18px"
            color="#A3A3AC"
            lineHeight="18px"
          >
            {name}
          </Text>
        </Box>
      ) : (
        <Avatar
          name={name}
          image={image}
          size={36}
          bg="#353B60"
          color="#FFFFFF"
          fontSize={18}
        />
      )}
    </Box>
  )
}

export default Guest
