import React, { FC, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { pickBy, not, equals, path, pathOr } from 'ramda'
import { format } from 'date-fns'

import { update } from 'store/modules/wedding/actions'
import { getWedding } from 'store/modules/wedding/selectors'
import { Modal, Box, Text, Button } from 'components/ui'
import { ReactComponent as CloseIcon } from 'images/close.svg'
import { transformToForm } from 'utils/wedding'
import useUploader from 'utils/useUploader'
import EditWeddingForm from './Form'

type Props = {
  wuid?: string
  isOpen: boolean
  onClose: () => void
}

const EditWeddingModal: FC<Props> = ({ wuid, isOpen, onClose }) => {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const wedding = useSelector(getWedding(wuid))
  const { onUpload } = useUploader()

  const onUpdate = useCallback(async (data) => dispatch(update(data)), [
    dispatch,
  ])

  if (!wedding) return null

  const transformedWedding = transformToForm(wedding)

  const handleClose = () => {
    setError(null)

    onClose()
  }

  const handleSubmit = async (values: object) => {
    const newValues: object = pickBy(
      (val, key) => not(equals(val, path([key], transformedWedding))),
      values
    )

    try {
      const imageSrc = path<string>(['wedding_image'], newValues)
      const image = imageSrc
        ? await onUpload(imageSrc, wedding.wuid)
        : undefined

      await onUpdate({
        wuid: wedding.wuid,
        photo_uid: image?.photoUid,
        name: path(['wedding_name'], newValues),
        ...(path(['theme_color'], newValues)
          ? { theme_color: path(['theme_color', 'value'], newValues) }
          : undefined),
        ...(path(['theme_font'], newValues)
          ? {
              theme_font: path(['theme_font', 'label'], newValues),
              theme_font_url: path(['theme_font', 'value'], newValues),
            }
          : undefined),
        ...(path(['wedding_date'], newValues)
          ? {
              wedding_date: format(
                pathOr(new Date(), ['wedding_date'], newValues),
                'yyyy-MM-dd'
              ),
            }
          : undefined),
        ...(path(['address', 'place'], newValues)
          ? {
              location_name: path(['address', 'place'], newValues),
              location_address: path(
                ['address', 'address', 'address'],
                newValues
              ),
              location_city: path(['address', 'address', 'city'], newValues),
              location_state: path(['address', 'address', 'state'], newValues),
              location_zip: path(['address', 'address', 'zip'], newValues),
              location_lat: path(['address', 'location', 'lat'], newValues),
              location_lon: path(['address', 'location', 'lng'], newValues),
            }
          : undefined),
      })

      handleClose()
    } catch (error) {
      setError(error.message)
    }
  }

  const modalStyle = {
    content: { top: 20, bottom: 20, maxWidth: 668, overflow: 'none' },
  }

  return (
    <Modal style={modalStyle} isOpen={isOpen} onRequestClose={handleClose}>
      <Box pt="26px" pb={4} borderRadius={1} bg="white" minHeight="50vh">
        <Button
          position="absolute"
          left="52px"
          top="22px"
          border="none"
          p="0px"
          zIndex={1}
          onClick={handleClose}
        >
          <CloseIcon fill="#7c8494" width={24} height={24} />
        </Button>

        <Form
          component={EditWeddingForm}
          initialValues={transformedWedding}
          onSubmit={handleSubmit}
        />

        {error && (
          <Box mt={0} pr="100px">
            <Text>{error}</Text>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default EditWeddingModal
