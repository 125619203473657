import React, { FC, useMemo } from 'react'
import { OptionProps, OptionTypeBase } from 'react-select'

import { Box, Text } from 'components/ui'
import { ReactComponent as CheckIcon } from './check.svg'

const Option: FC<OptionProps<OptionTypeBase, false>> = ({
  innerProps,
  label,
  isSelected,
  options,
  data,
}) => {
  const index = useMemo(
    () =>
      data?.value
        ? options.findIndex((option) => option.value === data.value)
        : -1,
    [data, options]
  )

  const isFirst = useMemo(() => index === 0, [index])
  const isLast = useMemo(() => options.length - 1 === index, [options, index])

  return (
    <Box
      {...innerProps}
      bg={isSelected ? '#F7F7FC' : 'white'}
      height="39px"
      display="flex"
      cursor="pointer"
      borderTopLeftRadius={isFirst ? '6px' : undefined}
      borderTopRightRadius={isFirst ? '6px' : undefined}
      borderBottomLeftRadius={isLast ? '6px' : undefined}
      borderBottomRightRadius={isLast ? '6px' : undefined}
    >
      <Box
        width="53px"
        color="#5458F7"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {isSelected && <CheckIcon />}
      </Box>

      <Box
        width="100%"
        borderBottom={!isLast ? '0.5px solid #E1EAEF' : undefined}
        display="flex"
        alignItems="center"
      >
        <Text
          fontWeight={isSelected ? 600 : 400}
          fontSize="16px"
          color={isSelected ? '#5458F7' : '#73737B'}
        >
          {label}
        </Text>
      </Box>
    </Box>
  )
}

export default Option
