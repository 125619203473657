import React, { FC, useState, InputHTMLAttributes } from 'react'
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
} from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import styled from 'styled-components'
import 'draft-js/dist/Draft.css'

import { ReactComponent as BoldIcon } from 'images/format-bold.svg'
import { ReactComponent as ItalicIcon } from 'images/format-italic.svg'
import { ReactComponent as UnderlineIcon } from 'images/format-underline.svg'
import { ReactComponent as ListNumberedIcon } from 'images/format-list-numbered.svg'
import { ReactComponent as ListBulletedIcon } from 'images/format-list-bulleted.svg'
import { ReactComponent as LinkOffIcon } from 'images/link-off.svg'
import { linkPlugin } from 'utils/textEditor'
import Box from '../Box'
import Text from '../Text'
import AddLinkButton from './AddLinkButton'
import FormatButton from './FormatButton'

const Wrapper = styled(Box)`
  .DraftEditor-root {
    padding-left: 14px;
    padding-right: ${props => props.theme.space[0]}px;
  }

  .DraftEditor-editorContainer {
    font-family: ${props => props.theme.fonts[1]};
    font-size: ${props => props.theme.fontSizes[1]}px;

    & > div {
      height: 120px;
      overflow-y: auto;
    }
  }

  .public-DraftEditorPlaceholder-inner {
    color: ${props => props.theme.colors.placeholder};
    font-family: ${props => props.theme.fonts[1]};
    font-size: ${props => props.theme.fontSizes[1]}px;
  }
`

const NoteInput: FC<
  InputHTMLAttributes<HTMLInputElement> & { onChange: (x: any) => void }
> = ({ value, onChange, placeholder }) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      convertFromRaw(markdownToDraft(value)),
      new CompositeDecorator([linkPlugin])
    )
  )

  const handleKeyCommand = (command: any, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      setEditorState(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  const handleChange = (newState: EditorState) => {
    setEditorState(newState)

    onChange(draftToMarkdown(convertToRaw(newState.getCurrentContent())))
  }

  const handleClickBold = () =>
    handleChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  const handleClickItalic = () =>
    handleChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
  const handleClickUnderline = () =>
    handleChange(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
  const handleClickListNumbered = () =>
    handleChange(RichUtils.toggleBlockType(editorState, 'ordered-list-item'))
  const handleClickListBulleted = () =>
    handleChange(RichUtils.toggleBlockType(editorState, 'unordered-list-item'))
  const handleAddLink = (url: any) => {
    const selection = editorState.getSelection()
    const content = editorState.getCurrentContent()
    const contentWithEntity = content.createEntity('LINK', 'MUTABLE', { url })
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      'apply-entity'
    )
    const entityKey = contentWithEntity.getLastCreatedEntityKey()

    return handleChange(
      RichUtils.toggleLink(newEditorState, selection, entityKey)
    )
  }
  const handleClickRemoveLink = () => {
    const selection = editorState.getSelection()

    if (selection.isCollapsed()) return null

    return handleChange(RichUtils.toggleLink(editorState, selection, null))
  }

  const plainText = editorState
    .getCurrentContent()
    .getPlainText('')
    .replace(/\s+/g, '')

  return (
    <Wrapper border="2px solid" borderColor="gray" borderRadius={1} pb={0}>
      <Box
        display="flex"
        alignItems="center"
        pl="10px"
        py="10px"
        mb="14px"
        borderBottom="1px solid"
        borderBottomColor="gray"
      >
        <Box mr="14px">
          <FormatButton
            isActive={editorState.getCurrentInlineStyle().has('BOLD')}
            onClick={handleClickBold}
          >
            <BoldIcon height={18} width={18} fill="currentColor" />
          </FormatButton>
        </Box>

        <Box mr="14px">
          <FormatButton
            isActive={editorState.getCurrentInlineStyle().has('ITALIC')}
            onClick={handleClickItalic}
          >
            <ItalicIcon height={18} width={18} fill="currentColor" />
          </FormatButton>
        </Box>

        <Box pr={1} borderRight="1px solid" borderRightColor="gray">
          <FormatButton
            isActive={editorState.getCurrentInlineStyle().has('UNDERLINE')}
            onClick={handleClickUnderline}
          >
            <UnderlineIcon height={18} width={18} fill="currentColor" />
          </FormatButton>
        </Box>

        <Box ml={1} mr="14px">
          <FormatButton onClick={handleClickListNumbered}>
            <ListNumberedIcon height={18} width={18} fill="currentColor" />
          </FormatButton>
        </Box>

        <Box pr={1} borderRight="1px solid" borderRightColor="gray">
          <FormatButton onClick={handleClickListBulleted}>
            <ListBulletedIcon height={18} width={18} fill="currentColor" />
          </FormatButton>
        </Box>

        <Box ml={1}>
          <AddLinkButton onAddLink={handleAddLink} />
        </Box>

        <Box ml="14px">
          <FormatButton onClick={handleClickRemoveLink}>
            <LinkOffIcon height={18} width={18} fill="currentColor" />
          </FormatButton>
        </Box>
      </Box>

      <Editor
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        onChange={handleChange}
        placeholder={placeholder}
      />

      <Box display="flex" justifyContent="flex-end" pr={0}>
        <Text fontFamily="1" fontSize="13px" color="darkGray">
          {plainText.length}
        </Text>
      </Box>
    </Wrapper>
  )
}

export default NoteInput
