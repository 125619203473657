import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  CSSProperties,
} from 'react'
import { useDispatch } from 'react-redux'
import { findIndex, propEq, path, pathOr, equals } from 'ramda'
import { toast } from 'react-toastify'
import RemovePhotosModal from '../RemovePhotosModal'
import NavBar from './NavBar'
import NavButton from './NavButton'


// Store tools
import { updateAlbum, setFavorite } from 'store/modules/albums/actions'
import { Photo } from 'store/modules/albums/reducer'

// Components
import { Modal, Box } from 'components/ui'

const usePrevious = (value: any) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

type Props = {
  isFavorites: boolean
  defaultPhotoId?: string
  isOpen: boolean
  photos: Photo[]
  onClose: () => void
  setFilteredAlbumPhotos: Function
}

const PhotoViewer: FC<Props> = ({
                                  photos,
                                  isFavorites,
                                  defaultPhotoId,
                                  isOpen,
                                  onClose,
                                  setFilteredAlbumPhotos,
                                }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [index, setIndex] = useState<number>(0)

  const prevDefaultPhotoId = usePrevious(defaultPhotoId)

  const dispatch = useDispatch()

  const onUpdateAlbum = useCallback(
    async (albumId, data) => dispatch(updateAlbum(albumId, data)),
    [dispatch],
  )

  const photo = path<Photo>([index], photos)

  const handleClickExportButton = () =>
    window.open(path(['image_original'], photo))
  const handleClickRemoveButton = () => setIsRemoveModalOpen(true)
  const handleClickNavButton = useCallback(
    (isPrev: boolean) => () => {
      setIndex(isPrev ? index - 1 : index + 1)
    },
    [index],
  )

  const handleSuccessRemoving = useCallback(() => {
    if (index && photos.length - 1 <= index) setIndex(index - 1)
  }, [photos, index])

  const handleCloseModal = () => setIsRemoveModalOpen(false)

  const handleMakeCoverImage = async () => {
    setIsLoading(true)

    try {
      await onUpdateAlbum(pathOr('', ['album_id'], photo), {
        cover_image_id: pathOr('', ['photo_id'], photo),
      })

      toast('Album cover image has been updated successfully')
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onSetFavorite = useCallback(async () => {
    if (photo?.photo_id && photo?.is_favorite) {
      return dispatch(
        setFavorite(photo.photo_id, photo.is_favorite === '0' ? '1' : '0'),
      )
    }
  }, [dispatch, photo?.photo_id, photo?.is_favorite])

  const onClickFavoriteButton = useCallback(async () => {
    try {
      setIsLoading(true)

      await onSetFavorite()
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onSetFavorite])

  useEffect(() => {
    if (!equals(prevDefaultPhotoId, defaultPhotoId)) {
      const nextIndex = findIndex(propEq('photo_id', defaultPhotoId), photos)

      if (!equals(nextIndex, -1)) setIndex(nextIndex)
    }
  }, [defaultPhotoId, prevDefaultPhotoId, photos, onClose])

  const style: { content: CSSProperties } = {
    content: {
      background: '#000000',
      top: 0,
      bottom: 0,
      maxWidth: '100%',
      height: '100%',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  }

  if (!photo) {
    return null
  }

  return (
    <Modal style={style} isOpen={isOpen} onRequestClose={onClose}>
      <RemovePhotosModal
        isOpen={isRemoveModalOpen}
        onClose={handleCloseModal}
        onSuccess={handleSuccessRemoving}
        photoIds={photo ? [photo.photo_id] : []}
        albumId={path(['album_id'], photo)}
        filteredAlbumPhotos={photos}
        setFilteredAlbumPhotos={setFilteredAlbumPhotos}
      />

      <NavBar
        isFavorites={isFavorites}
        isFavorite={photo.is_favorite === '1'}
        isLoading={isLoading}
        onMakeCoverImage={handleMakeCoverImage}
        onClickAddToFavorites={onClickFavoriteButton}
        onClickExport={handleClickExportButton}
        onClickRemove={handleClickRemoveButton}
        onClickBack={onClose}
      />

      {!equals(index, 0) && (
        <NavButton isPrev onClickButton={handleClickNavButton(true)} />
      )}

      {photo && (
        <Box
          as='img'
          src={photo.image_original ? photo.image_original : photo.original}
          alt='Photo'
          maxWidth='100vw'
          maxHeight='calc(100vh - 152px)'
          height='auto'
          position='absolute'
          top='0px'
          right='0px'
          bottom='0px'
          left='0px'
          m='auto'
          style={{ objectFit: 'cover' }}
        />
      )}

      {!equals(index, photos.length - 1) && (
        <NavButton onClickButton={handleClickNavButton(false)} />
      )}
    </Modal>
  )
}

export default PhotoViewer
