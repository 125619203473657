import { Selector, createSelector } from 'reselect'
import { pathOr } from 'ramda'

import { State, initialState } from './reducer'

const getState: Selector<{ photos: State }, State> = pathOr(initialState, [
  'photos',
])

export const selectFiles = createSelector(getState, (state) => state.files)
