import React, { FC } from 'react'
import { format, parseISO } from 'date-fns'
import styled from 'styled-components'
import { shortnameToUnicode } from 'emoji-toolkit'
import { path } from 'ramda'

import { Message as MessageProps } from 'store/modules/messages/reducer'
import { Box, Avatar, Text } from 'components/ui'
import { getFullName, getImageLink } from 'utils/guest'

const Title = styled(Box)`
  line-height: 1;
`

type Props = {
  author: MessageProps['author']
  txt: MessageProps['txt']
  insert_dt: MessageProps['insert_dt']
}

const Message: FC<Props> = ({ author, txt, insert_dt }) => {
  const fullName = author
    ? getFullName([author.first_name, author.last_name])
    : 'User'
  const image = getImageLink(path(['image'], author))

  return (
    <Box px={4} py={0} display="flex">
      <Box height="40px">
        <Avatar image={image} name={fullName} fontSize={2} />
      </Box>

      <Box ml="12px" width="100%">
        <Title display="flex" alignItems="baseline">
          <Text fontWeight={600} lineHeight={1}>
            {fullName}
          </Text>

          <Box ml="4px">
            <Text color="#99a2ad" fontSize="12px" lineHeight={1}>
              {format(parseISO(insert_dt), 'h:mm a')}
            </Text>
          </Box>
        </Title>

        <Text fontSize={15} lineHeight={1.6} color="fullBlack">
          {shortnameToUnicode(txt)}
        </Text>
      </Box>
    </Box>
  )
}

export default Message
