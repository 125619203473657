import React, { FC } from 'react'
import { useLocation } from 'react-router'
import { parse } from 'query-string'

import { Box, Text } from 'components/ui'
import { ReactComponent as Logo } from 'images/logo.svg'
import downloadIphoneSrc from 'images/download-iphone.png'
import downloadGoogleSrc from 'images/download-google.png'

const JoinToWeddingGuest: FC = () => {
  const { search } = useLocation()
  const { code } = parse(search)

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        px={[1, '28px']}
        height="80px"
        borderBottom="1px solid"
        borderBottomColor="gray"
      >
        <Box ml="-7px">
          <Logo width="140px" />
        </Box>
      </Box>

      <Box
        bg="#f6f7ff"
        height="calc(100vh - 80px)"
        width="100vw"
        overflowY="auto"
        pt={[1, '100px']}
        pb={[1, 'unset']}
        px="28px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box mb="4px">
          <Text color="secondary" fontSize={['28px', 5]} fontWeight="bold">
            You’re invited&nbsp;&nbsp;
            <span role="img" aria-label="Party Popper">
              🎉
            </span>
          </Text>
        </Box>

        <Text color="secondary" fontFamily="1" fontSize={1}>
          Come celebrate with us on HitchHero
        </Text>

        <Box
          bg="gray"
          borderRadius={0}
          p={1}
          maxWidth="400px"
          width="100%"
          mt="20px"
          mb="28px"
          height={['140px', '207px']}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Text
            fontSize={['20px', '28px']}
            color="white"
            fontWeight="bold"
            lineHeight={['normal', 1.29]}
          >
            Patrick & Mary Beth
          </Text>
          <Text
            fontSize={['16px', '20px']}
            color="white"
            lineHeight={['normal', 1.8]}
          >
            June 24, 2019
          </Text>
        </Box>

        <Text
          color="fullBlack"
          fontFamily="1"
          fontSize={1}
          fontWeight={600}
          lineHeight={1.38}
        >
          Step 1: Download the app
        </Text>

        <Box display="flex" mt="20px" mb="28px">
          <Box as="img" src={downloadIphoneSrc} width="120px" mr={0} />

          <Box as="img" src={downloadGoogleSrc} width="120px" />
        </Box>

        <Text
          color="fullBlack"
          fontFamily="1"
          fontSize={1}
          fontWeight={600}
          lineHeight={1.38}
        >
          Step 2: Enter the code
        </Text>

        <Box
          px="24px"
          py="16px"
          border="1px solid"
          borderColor="darkGray"
          borderRadius="10px"
          mt="20px"
          bg="#eef1f5"
        >
          <Text fontSize="32px" color="fullBlack">
            {code}
          </Text>
        </Box>
      </Box>
    </>
  )
}

export default JoinToWeddingGuest
