import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Context } from '../Context'

// Icons
import { ReactComponent as DeleteIcon } from 'images/delete-icon.svg'
import { ReactComponent as TopArrowIcon } from 'images/top-arrow.svg'
import { ReactComponent as BottomArrowIcon } from 'images/bottom-arrow.svg'
import { ReactComponent as EditQuestionIcon } from 'images/edit-question.svg'
import { ReactComponent as UnLikeIcon } from 'images/unlike-icon.svg'
import { ReactComponent as LikeIcon } from 'images/like-icon.svg'

// Components
import { Box, Text, Button } from 'components/ui'
import { Input } from 'components/form'

// Utils
import { ContentPopular, QuestionBox, QuestionBoxParent } from '../utils'
import { useParams } from 'react-router'

const Questions = () => {
  const {
    faqItems,
    faqs,
    handleClickRemoveModal,
    activeTab,
    editableFaq,
    setEditableFaq,
    onUpdateFaq,
    handleEditFaq,
    handleLikeFaq,
    sortedFaqs,
    setSortedFaqs,
  } = useContext<any>(Context)

  const [changeOrder, setChangeOrder] = useState<any>({})

  const { wuid } = useParams()

  let maxLengthAnswer = 1000
  let inputMaxLength: number

  useEffect(() => {
    let sortedFaqs
    if (activeTab === '0') {
      if (faqs.length > 0) {
        sortedFaqs = faqs.sort(function(a: any, b: any) {
          return a.ord - b.ord
        })
      }
    } else if (activeTab === '1') {
      if (faqs.length) {
        sortedFaqs = faqs.filter((faq: { answer: string, question: string, faq_item_id: string }) => faq.answer === '')
      }
    }
    setSortedFaqs(sortedFaqs)
  }, [activeTab, faqItems, faqs, setSortedFaqs])

  useEffect(() => {
    sortedFaqs?.map((faq: any) => {
      return (
        faq.answerLength = maxLengthAnswer - faq?.answer?.length
      )
    })

    setSortedFaqs(sortedFaqs)
  }, [maxLengthAnswer, setSortedFaqs, sortedFaqs])

  const handleCountTextLength = (e: any, fieldName: string, faq: any) => {
    let valueLength = e.target.value.length

    if (valueLength >= 0) {
      setEditableFaq(faq?.faq_item_id)
    }

    if (fieldName === 'answer' && valueLength !== 0 && valueLength <= 1000) {
      inputMaxLength = 1000
      faq.answerLength = 1000 - valueLength
    } else if (fieldName === 'answer' && valueLength === 0 && valueLength <= 1000) {
      inputMaxLength = 1000
      faq.answerLength = 1000
    }
  }

  const handleChangeFaqOrder = (from: number, to: number, type: string) => {
    setChangeOrder({
      from,
      to,
      type,
    })

    setTimeout(() => {
      const sortedArr: any = [...sortedFaqs]
      sortedArr[from] = sortedFaqs[to]
      sortedArr[to] = sortedFaqs[from]
      setChangeOrder({})
      setSortedFaqs(sortedArr)
    }, 1000)

  }

  return (
    <Box width='69%'>
      <ContentPopular>
        {sortedFaqs?.length > 0 && sortedFaqs?.map((faq: {
          is_asked: string,
          ask_user: { first_name: string, last_name: string },
          answer: string,
          question: string,
          faq_item_id: string,
          is_anon: string,
          like_count: string,
          is_like: string,
          answerLength: string,
        }, index: number) => {

          return (
            <Form
              onSubmit={onUpdateFaq}
              initialValues={{
                answer: faq.answer,
                faq_item_id: faq.faq_item_id,
                wuid: wuid,
                question: faq.question,
              }}
              key={faq.faq_item_id}
            >
              {(props: any) => {
                return (
                  <QuestionBoxParent key={faq.faq_item_id}>
                    <QuestionBox
                      fromBottom={changeOrder.from === index}
                      fromTop={changeOrder.to === index}
                      type={changeOrder.type}
                    >
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        width='100%'
                        marginBottom='13px'
                      >
                        <Box display='flex' alignItems='center'>
                          <Box>
                            {editableFaq !== faq.faq_item_id ? (
                              <Text
                                style={{
                                  fontFamily: 'Source Sans Pro',
                                  fontStyle: 'normal',
                                  fontWeight: 700,
                                  fontSize: '20px',
                                  lineHeight: '25px',
                                  color: '#353B60',
                                }}
                              >
                                {faq?.question}
                              </Text>
                            ) : (
                              <Input
                                name='question'
                                style={{
                                  minWidth: `${faq?.question.length}ch`,
                                  maxWidth: '700px',
                                  border: 'none',
                                  fontFamily: 'Source Sans Pro',
                                  fontWeight: 700,
                                  fontSize: '20px',
                                  lineHeight: '25px',
                                  color: 'rgb(53, 59, 96)',
                                  padding: 0,
                                }}
                              />
                            )}
                          </Box>
                          {editableFaq !== faq.faq_item_id &&
                          <Box marginLeft='8px'>
                            <Button
                              style={{ padding: 0, height: '24px' }}
                              onClick={() => handleEditFaq(faq.faq_item_id)}
                            >
                              <EditQuestionIcon />
                            </Button>
                          </Box>
                          }
                        </Box>
                        <Box>
                          <Box display='flex' alignItems='center'>
                            <Box>
                              <Box
                                display='flex'
                                alignItems='center'
                                border='1px solid #CBCBD3'
                                borderRadius='8px'
                                padding='0 8px'
                                height='40px'
                                marginRight='12px'
                                cursor='pointer'
                                onClick={() => handleLikeFaq(faq.faq_item_id)}
                              >
                                <Box marginRight='6px'>
                                  {faq.is_like === '1' ? (
                                    <LikeIcon />
                                  ) : (
                                    <UnLikeIcon />
                                  )}

                                </Box>
                                <Box>
                                  <Text
                                    fontFamily='Source Sans Pro'
                                    fontWeight={600}
                                    fontSize='16px'
                                    lineHeight='18px'
                                    color='#5458F7'
                                  >
                                    {faq.like_count}
                                  </Text>
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <Button style={{ height: '20px', padding: 0 }}
                                      disabled={index === 0}
                                      onClick={() => handleChangeFaqOrder(index, index - 1, 'up')}>
                                <TopArrowIcon />
                              </Button>
                            </Box>
                            <Box ml='12px'>
                              <Button style={{ height: '20px', padding: 0 }}
                                      disabled={index === sortedFaqs.length - 1}
                                      onClick={() => handleChangeFaqOrder(index, index + 1, 'down')}>
                                <BottomArrowIcon />
                              </Button>
                            </Box>
                            <Box ml='12px'>
                              <Button
                                style={{ padding: '0', height: '40px' }}
                                onClick={() => handleClickRemoveModal('open', faq.faq_item_id)}
                              >
                                <DeleteIcon />
                              </Button>
                            </Box>
                            <Box ml='12px'>
                              <Button
                                style={{
                                  fontFamily: 'Source Sans Pro',
                                  backgroundColor: editableFaq === faq.faq_item_id ? '#5458f7' : '#E8E8ED',
                                  color: '#ffffff',
                                  borderRadius: '8px',
                                  padding: '10px 20px',
                                }}
                                disabled={editableFaq !== faq.faq_item_id}
                                onClick={props.handleSubmit}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box display='flex' width='100%'>
                        <Box width='100%'>
                          <Input
                            name='answer'
                            placeholder='Add an answer to your question.'
                            isTextarea={true}
                            showMaxLength={true}
                            inputMaxLength={inputMaxLength}
                            style={{
                              minHeight: '70px',
                              maxHeight: '70px',
                              padding: '12px 12px 40px 12px',
                            }}
                            maxLength={Number(faq.answerLength)}
                            onInput={(e) => handleCountTextLength(e, 'answer', faq)}
                          />
                          {faq.is_asked === '1' || faq.is_anon === '1' ? (
                            <Box style={{
                              marginTop: '8px',
                              color: '#73737B',
                              fontFamily: 'Source Sans Pro',
                              fontWeight: 400,
                              fontSize: '14px',
                              lineHeight: '18px',
                            }}>
                              Asked by
                              <Text style={{ color: '#5458F7', marginLeft: '5px' }}>
                                {faq.is_asked === '1' ? `${faq.ask_user.first_name} ${faq.ask_user.last_name}` :
                                  faq.is_anon === '1' ? <Text
                                    style={{
                                      marginTop: '8px',
                                      color: '#73737B',
                                      fontFamily: 'Source Sans Pro',
                                      fontWeight: 400,
                                      fontSize: '14px',
                                      lineHeight: '18px',
                                    }}
                                  >
                                    Anonymous
                                  </Text> : null}
                              </Text>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    </QuestionBox>
                  </QuestionBoxParent>
                )
              }}
            </Form>
          )
        })}
      </ContentPopular>
    </Box>
  )
}

export default Questions
